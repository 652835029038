import React, { useEffect } from 'react';
import TrabajoDataService from '../services/trabajo.services';
import { useState } from 'react';
import Header from "../components/Header";
import { Outlet, Link } from "react-router-dom";
import {createTheme, ThemeProvider} from "@mui/material";
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import JobCard from "../components/JobCard";
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Slider from '@mui/material/Slider';
import JobList from "./JobList";
import { useUserAuth } from "../context/UserAuthContext";
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import FavoriteIcon from '@mui/icons-material/Favorite';
import listareas from './listareas';
import { CardActionArea } from '@mui/material';
import { useParams } from "react-router-dom";
import { getFirestore, collection, onSnapshot, addDoc, deleteDoc, doc, query, where, orderBy, getDoc, getDocs  } from 'firebase/firestore';
import AuthProvider from '../components/authProvider';
import { useNavigate } from 'react-router';
import { db, getPerfil, insertNewPerfil, updatePerfil } from '../firebase-config';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import getPaymentsByUID from '../components/getPaymentsByUID';
import Pagination from '@mui/material/Pagination';
import { Link as RouterLink } from 'react-router-dom';
import ListItemButton from '@mui/material/ListItemButton';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { LoadingButton } from "@mui/lab";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import Image from 'mui-image';
import Fab from '@mui/material/Fab';


const anosExperiencia = [
    {
      value: 0,
      label: '0',
    },
    {
      value: 1,
      label: '1',
    },
    {
      value: 2,
      label: '2',
    },
    {
      value: 3,
      label: '3',
    },
    {
      value: 4,
      label: '4',
    },
    {
      value: 5,
      label: '5',
    },
    {
      value: 6,
      label: '6',
    },
    {
      value: 7,
      label: '7',
    },
    {
      value: 8,
      label: '8',
    },
    {
      value: 9,
      label: '9',
    },
    {
      value: 10,
      label: '10',
    },
    {
      value: 11,
      label: '11',
    },
    {
      value: 12,
      label: '12',
    },
    {
      value: 13,
      label: '13',
    },
    {
      value: 14,
      label: '14',
    },
    {
      value: 15,
      label: '15',
    },
    {
      value: 16,
      label: '16',
    },
    {
      value: 17,
      label: '17',
    },
    {
      value: 18,
      label: '18',
    },
    {
      value: 19,
      label: '19',
    },
    {
      value: 20,
      label: '+20',
    },
  ];

  const anosExperienciaMovil = [
    {
      value: 0,
      label: '0',
    },
    {
      value: 5,
      label: '5',
    },
    {
      value: 10,
      label: '10',
    },
    {
      value: 15,
      label: '15',
    },
    {
      value: 20,
      label: '+20',
    },
  ];
  
  function valuetext(value: number) {
    return `${value}k`;
  }


const BuscadorCandidatos = ( {getTrabajoId} ) => {
    

    
    
    
    
    
    
    
    
    const [trabajos, setTrabajos] = useState([]);
    useEffect(() => {
    getTrabajos();
  }, [])
  







  const getTrabajos = async () => {
    const data = await TrabajoDataService.getAllTrabajos();
    setTrabajos(data.docs.map((doc) => ({...doc.data(), id: doc.id})))
  }

  const [currentUser, setCurrentUser] = useState({});
  const [perfiles, setPerfiles] = useState([ ]);
  const [payments, setPayments] = useState([]);
  const [payment1, setPayment1] = useState([]);
  const [isPremium, setIsPremium] = useState(false);
  const [isOldPremium, setIsOldPremium] = useState(false);
     
   const [cvs, setCvs] = useState([]);
  const navigate = useNavigate();

  async function handleUserLoggedIn(user) {
    setCurrentUser(user);
    console.log("Estás registrado");
    console.log(user.displayName);
    console.log(perfiles.length);
    const resPerfil = await getPerfil(user.uid);
    setPerfiles([...resPerfil]);
    const payments = await getPaymentsByUID(user.uid);
    setPayments([...payments])
    console.log(perfiles);
    console.log()
    setIsOldPremium(user.premiumUser)
    if (!isOldPremium && currentUser.premiumUser !== true && perfiles.length === 0) {
      setTimeout(() => setOpen(true),5000)
    }

       
   payments.map((payment) => {
    payment1.push(payment.status)

  })

console.log(payment1)

if (currentUser.premiumUser !== true) { 
const premium = (element) => (element === "active" || element === "trialing")
setIsPremium(payment1.some(premium))
console.log(isPremium)
   }
  
  
  
  }
console.log(currentUser.premiumUser)
   console.log(isOldPremium)

   useEffect(() => {
    async function getPayments() {
      if (!currentUser) return;
      console.log("user por usar", currentUser);
      const payments = await getPaymentsByUID(currentUser);
      setPayments(payments);
    }
    getPayments();
    console.log(payments)
    
  }, []);



    console.log(payments.length)
  console.log(cvs)

   console.log(perfiles.length)
console.log(currentUser)



   function handleUserNotRegistered(user) {
    

   }
   
   function handleUserNotLoggedIn() {
    
   }
    

   const fetchPost = async () => {
      
       await getDocs(collection(db, "perfiles"))
           .then((querySnapshot)=>{              
               const newData = querySnapshot.docs
                   .map((doc) => ({...doc.data(), id:doc.id }));
               setCvs(newData);                
               console.log(cvs, newData);
               console.log("Funcionó")
           })
      
   }
  
   useEffect(()=>{
       fetchPost();

   }, [])


    const areas = ["Administrativo", "Banking&Finance", "Competencia", "Compliance","Concursal", "Consumo","Contract Management","Energía", "Farma","Fiscal","Generalista", "Inmobiliario", "Laboral" , "Medio ambiente" , "Mercantil", "Nuevas tecnologías","Procesal civil", "Propiedad industrial", "Propiedad intelectual", "Protección de datos", "Seguros", "Urbanismo",         ];
  const experiencias = ["1 a 4 años", "5 a 8 años"];
  const ciudades = ["Madrid", 
  "Barcelona",
  "Valencia", 
  "Sevilla",
    "Zaragoza",
    "Málaga",
  "Murcia",
"Palma",
"Las Palmas",
"Bilbao",
"Alicante",
"Córdoba",
"Valladolid",
"Vigo",
"Gijón"];
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [experiencia, setExperiencia] = React.useState(null);
  const [inputExperiencia, setInputExperiencia] = React.useState('');
  const [ciudad, setCiudad] = React.useState(null);
  const [inputCiudad, setInputCiudad] = React.useState();
  const [area, setArea] = React.useState(null);
  const [inputArea, setInputArea] = React.useState();
  let {user} = useUserAuth();
  const [experiencia1, setExperiencia1] = useState([0, 20]);

  const handleChangeExperiencia = (event, newExperiencia) => {
    setExperiencia1(newExperiencia);
  };

  const [open, setOpen] = React.useState(false);

 
  
console.log(cvs)
  const handleClose = () => {
    setOpen(false);
  };



  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [page]);

  const ordered = [...trabajos].sort((a,b) => b.timestamp - a.timestamp);
  const matches = []
  const matchesOpen = []
  const matchesPremium = []
  const totalJobs = ordered.length



  const eachCv = cvs.map((doc, index) => {
 
    const numeroAreasPractica = Object.keys(doc.area);

      matches.push(doc)
    
  })
  const [page, setPage] = useState(1);
  const itemsPerPage = 15;
  const noOfPages = Math.ceil(matches.length / itemsPerPage);
  const handleChange = (event, value) => {
    setPage(value);
  };
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [page]);
  const numberLimitedJobs = matches.length
  const numberMatchesOpen = matchesOpen.length
  const numberMatchesPremium = matchesPremium.length
  const eachjob2 = cvs.map((doc, index) => {
 
    const numeroAreasPractica = Object.keys(doc.area);
    const date = new Date();

    return (
        <div>
        
        

       <Box sx={{display: { xl: 'block', l: "block", m: "none", s:"none", xs: 'none' }}} >
        <Card variant="outlined" sx={{display: "flex", width: "800px"}}>
       
        <Box sx={{ display: 'flex', flexDirection: 'row', width: "600px"}}>
        <CardContent sx={{paddingTop:"20px"}}>
        <Typography variant="subtitle1"  color="text.secondary">{doc.despacho}</Typography>
                <Typography variant="h6" color="text.secondary">
               
                     
                </Typography>
                <Typography variant="subtitle2" color="text.secondary">
                    Puesto actual: {doc.trabajo1}
                </Typography>
                <Typography variant="subtitle2" color="text.secondary">
                    Empresa actual: {doc.empresa1}
                </Typography>
        <Box sx={{paddingTop: "15px"}}>
        {numeroAreasPractica.length >= 1 && <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px"}}>
            {doc.area[0].title}
        </Typography>}
        {numeroAreasPractica.length > 1 && <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px", marginLeft: "5px"}}>
            {doc.area[1].title}
        </Typography>}
        {numeroAreasPractica.length > 2 && <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px", marginLeft: "5px"}}>
            {doc.area[2].title}
        </Typography>}
        
        
        </Box>
        <Box sx={{paddingTop: "8px"}}>
        <Typography variant="caption" sx={{backgroundColor: "#03b1fc", borderRadius: "10px", color:"white", padding: "6px"}}>
            {`${doc.anos} años de experiencia`}
        </Typography>
       
       
        </Box>
        </CardContent>
        </Box>
        
        <Box sx={{ display: 'flex', flexDirection: 'column', width: "400px", alignItems: 'flex-end'}}>
            <Box sx={{display: "flex", alignItems: "flex-end"}}>
            </Box>
            <Box sx={{paddingTop: "62px", paddingRight: "15px"}}>
       
           
       
        </Box>
        </Box> 
 
    </Card>
    </Box>

    {(doc.visibilidad === false && doc.requiereregistro === true && doc.requiereperfil !== true && (user && perfiles.length === 0)) && <Box sx={{display: { xl: 'block', l: "block", m: "none", s:"none", xs: 'none' }}} >
        <Card variant="outlined" sx={{display: "flex", width: "800px"}}>
       
        <CardMedia
            component="img"
            sx={{maxWidth: "20%", padding:"20px", objectFit: "contain"}}
            image={doc.logoOferta}
            alt="Logo"
          />
        <Box sx={{ display: 'flex', flexDirection: 'row', width: "600px"}}>
        <CardContent sx={{paddingTop:"20px"}}>
        <Typography variant="subtitle1"  color="text.secondary">{doc.despacho}</Typography>
                <Typography variant="h6" color="text.secondary">
                <Link
            style={{ display: "block", textDecoration: "none", color: "#474643"}}
            to={`/Jobs/${doc.id}`}
            target="_blank"
            key={doc.id} onClick ={(e) => getTrabajoId(doc.id) }
          >
            {doc.titulo}
          </Link>
                     
                </Typography>
                <Typography variant="subtitle2" color="text.secondary">
                     {doc.ciudad}
                </Typography>
        <Box sx={{paddingTop: "15px"}}>
        
        <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px"}}>
            {doc.area[0].title}
        </Typography>
        {numeroAreasPractica.length > 1 && <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px", marginLeft: "5px"}}>
            {doc.area[1].title}
        </Typography>}
        {numeroAreasPractica.length > 2 && <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px", marginLeft: "5px"}}>
            {doc.area[2].title}
        </Typography>}
        
        </Box>
        <Box sx={{paddingTop: "8px"}}>
        
        <Typography variant="caption" sx={{backgroundColor: "#03b1fc", borderRadius: "10px", color:"white", padding: "6px"}}>
            {`${doc.experiencia[0]} a ${doc.experiencia[1]} años`}
        </Typography>
  
        </Box>
        </CardContent>
        </Box>
        
        <Box sx={{ display: 'flex', flexDirection: 'column', width: "400px", alignItems: 'flex-end'}}>
            <Box sx={{display: "flex", alignItems: "flex-end"}}>
            </Box>
            <Box sx={{paddingTop: "62px", paddingRight: "15px"}}>
       
            {(((doc.timestamp.toDate().getMonth() < date.getMonth()) && (doc.timestamp.toDate().getDate() < date.getDate())) || (doc.timestamp.toDate().getMonth() + 1 < date.getMonth())) &&  
            
            <Typography variant="caption" 
            sx={{border: "1px solid #b32217", 
            borderRadius: "10px", 
            color:"white", 
            bgcolor: "#b32217",
            padding: "6px", 
            marginLeft: "5px"}}>
              Podría no estar ya disponible</Typography>}
       
        </Box>
        </Box> 
 
    </Card>
    </Box> }
        
        
        {(doc.visibilidad === false && (doc.requiereperfil === true || doc.requiereregistro === true) && !user) && <Box sx={{display: { xl: 'block', l: "block", m: "none", s:"none", xs: 'none' }}} >
        <Card variant="outlined" sx={{display: "flex", width: "800px"}}>
       
      
           <CardContent sx={{maxWidth: "20%", padding:"20px", bgcolor: "#7b8cb0", opacity: "0.7", backgroundImage: `url(${"https://firebasestorage.googleapis.com/v0/b/jobsandlaw-5e0ba.appspot.com/o/images%2Ftorres-1670879.jpg?alt=media&token=c0654ab3-441b-4b37-8af3-d60d7101854a"})`  }}>
          <Typography component="div" color= "whitesmoke" sx={{alignContent: "center", fontSize: "16px"}}>
            Regístrate gratis para desbloquear esta vacante
          </Typography>
  
        </CardContent>
        <Box sx={{ display: 'flex', flexDirection: 'row', width: "600px"}}>
        <CardContent sx={{paddingTop:"20px"}}>
        <Typography variant="subtitle1"  color="text.secondary">{doc.tipoDespacho.title} </Typography>
                <Typography variant="h6" color="text.secondary">
                <Link
            style={{ display: "block", textDecoration: "none", color: "#474643"}}
            to={`../`}
            key={doc.id} onClick ={(e) => getTrabajoId(doc.id) }
          >
            {doc.titulo}
          </Link>
                     
                </Typography>
                <Typography variant="subtitle2" color="text.secondary">
                     {doc.ciudad}
                </Typography>
        <Box sx={{paddingTop: "15px"}}>
        
        <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px"}}>
            {doc.area[0].title}
        </Typography>
        {numeroAreasPractica.length > 1 && <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px", marginLeft: "5px"}}>
            {doc.area[1].title}
        </Typography>}
        {numeroAreasPractica.length > 2 && <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px", marginLeft: "5px"}}>
            {doc.area[2].title}
        </Typography>}
        </Box>
        <Box sx={{paddingTop: "8px"}}>
        
        <Typography variant="caption" sx={{backgroundColor: "#03b1fc", borderRadius: "10px", color:"white", padding: "6px"}}>
            {`${doc.experiencia[0]} a ${doc.experiencia[1]} años`}
        </Typography>

        </Box>
        </CardContent>
        </Box>
        
        <Box sx={{ display: 'flex', flexDirection: 'column', width: "400px", alignItems: 'flex-end'}}>
            <Box sx={{display: "flex", alignItems: "flex-end"}}>
            </Box>
            <Box sx={{paddingTop: "62px", paddingRight: "15px"}}>
       
            {doc.recomendado !== false &&  
            
            <Typography variant="caption" 
            sx={{border: "1px solid #037bfc", 
            borderRadius: "10px", 
            color:"white", 
            bgcolor: "#037bfc",
            padding: "6px", 
            marginLeft: "5px"}}>
              Mejores despachos para trabajar 2023</Typography>}
       
        </Box>
        </Box> 
 
    </Card>
    </Box> }
        
       

    {(doc.visibilidad === false && user && (doc.requiereperfil === true || doc.requiereregistro === true) && (perfiles.length !== 0)) && <Box sx={{display: { xl: 'block', l: "block", m: "none", s:"none", xs: 'none' }}} >
        <Card variant="outlined" sx={{display: "flex", width: "800px"}}>
       
        <CardMedia
            component="img"
            sx={{maxWidth: "20%", padding:"20px", objectFit: "contain"}}
            image={doc.logoOferta}
            alt="Logo"
          />
        <Box sx={{ display: 'flex', flexDirection: 'row', width: "600px"}}>
        <CardContent sx={{paddingTop:"20px"}}>
        <Typography variant="subtitle1"  color="text.secondary">{doc.despacho}</Typography>
                <Typography variant="h6" color="text.secondary">
                <Link
            style={{ display: "block", textDecoration: "none", color: "#474643"}}
            to={`/Jobs/${doc.id}`}
            target="_blank"
            key={doc.id} onClick ={(e) => getTrabajoId(doc.id) }
          >
            {doc.titulo}
          </Link>
                     
                </Typography>
                <Typography variant="subtitle2" color="text.secondary">
                     {doc.ciudad}
                </Typography>
        <Box sx={{paddingTop: "15px"}}>
        
        <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px"}}>
            {doc.area[0].title}
        </Typography>
        {numeroAreasPractica.length > 1 && <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px", marginLeft: "5px"}}>
            {doc.area[1].title}
        </Typography>}
        {numeroAreasPractica.length > 2 && <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px", marginLeft: "5px"}}>
            {doc.area[2].title}
        </Typography>}
        </Box>
        <Box sx={{paddingTop: "8px"}}>
        
        <Typography variant="caption" sx={{backgroundColor: "#03b1fc", borderRadius: "10px", color:"white", padding: "6px"}}>
            {`${doc.experiencia[0]} a ${doc.experiencia[1]} años`}
        </Typography>

        </Box>
        </CardContent>
        </Box>
        
        <Box sx={{ display: 'flex', flexDirection: 'column', width: "400px", alignItems: 'flex-end'}}>
            <Box sx={{display: "flex", alignItems: "flex-end"}}>
            </Box>
            <Box sx={{paddingTop: "62px", paddingRight: "15px"}}>
       
            {(((doc.timestamp.toDate().getMonth() < date.getMonth()) && (doc.timestamp.toDate().getDate() < date.getDate())) || (doc.timestamp.toDate().getMonth() + 1 < date.getMonth())) &&  
            
            <Typography variant="caption" 
            sx={{border: "1px solid #b32217", 
            borderRadius: "10px", 
            color:"white", 
            bgcolor: "#b32217",
            padding: "6px", 
            marginLeft: "5px"}}>
              Podría no estar ya disponible</Typography>}
       
        </Box>
        </Box> 
 
    </Card>
    </Box> }

    
    
    {(doc.visibilidad === false && doc.requiereperfil === true && (perfiles.length === 0) && user) && <Box sx={{display: { xl: 'block', l: "block", m: "none", s:"none", xs: 'none' }}} >
        <Card variant="outlined" sx={{display: "flex", width: "800px"}}>
       
      
           <CardContent sx={{maxWidth: "20%", padding:"20px", bgcolor: "#7b8cb0", opacity: "0.7", backgroundImage: `url(${"https://firebasestorage.googleapis.com/v0/b/jobsandlaw-5e0ba.appspot.com/o/images%2Ftorres-1670879.jpg?alt=media&token=c0654ab3-441b-4b37-8af3-d60d7101854a"})`  }}>
          <Typography component="div" color= "whitesmoke" sx={{alignContent: "center", fontSize: "16px"}}>
            Crea un perfil profesional para desbloquear esta vacante
          </Typography>
  
        </CardContent>
        <Box sx={{ display: 'flex', flexDirection: 'row', width: "600px"}}>
        <CardContent sx={{paddingTop:"20px"}}>
        <Typography variant="subtitle1"  color="text.secondary">{doc.tipoDespacho.title} </Typography>
                <Typography variant="h6" color="text.secondary">
                <Link
            style={{ display: "block", textDecoration: "none", color: "#474643"}}
            to={`../perfilprofesional`}
            key={doc.id} onClick ={(e) => getTrabajoId(doc.id) }
          >
            {doc.titulo}
          </Link>
                     
                </Typography>
                <Typography variant="subtitle2" color="text.secondary">
                     {doc.ciudad}
                </Typography>
        <Box sx={{paddingTop: "15px"}}>
        
        <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px"}}>
            {doc.area[0].title}
        </Typography>
        {numeroAreasPractica.length > 1 && <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px", marginLeft: "5px"}}>
            {doc.area[1].title}
        </Typography>}
        {numeroAreasPractica.length > 2 && <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px", marginLeft: "5px"}}>
            {doc.area[2].title}
        </Typography>}
        </Box>
        <Box sx={{paddingTop: "8px"}}>
        
        <Typography variant="caption" sx={{backgroundColor: "#03b1fc", borderRadius: "10px", color:"white", padding: "6px"}}>
            {`${doc.experiencia[0]} a ${doc.experiencia[1]} años`}
        </Typography>
   
        </Box>
        </CardContent>
        </Box>
        
        <Box sx={{ display: 'flex', flexDirection: 'column', width: "400px", alignItems: 'flex-end'}}>
            <Box sx={{display: "flex", alignItems: "flex-end"}}>
            </Box>
            <Box sx={{paddingTop: "62px", paddingRight: "15px"}}>
       
            {doc.recomendado !== false &&  
            
            <Typography variant="caption" 
            sx={{border: "1px solid #037bfc", 
            borderRadius: "10px", 
            color:"white", 
            bgcolor: "#037bfc",
            padding: "6px", 
            marginLeft: "5px"}}>
              Mejores despachos para trabajar 2023</Typography>}
       
        </Box>
        </Box> 
 
    </Card>
    </Box> }


  

    {(doc.visibilidad === false && doc.requiereperfil !== true && doc.requiereregistro !== true) &&  <Box sx={{display: { xl: 'none', l: "none", m: "block", s:"block", xs: 'block', justifyContent: "center", alignItems: "center", marginTop: "20px" }}} >
        <Card variant="outlined" sx={{alignContent: "center", alignItems: "center", width: "300px"}}>
        <CardActionArea component={Link} to={`/Jobs/${doc.id}`} target="_blank">
        <CardMedia
            component="img"
            sx={{paddingLeft: "15px", width: "80px", objectFit: "contain", justifyContent: "center", alignItems: "center"}}
            image={doc.logoOferta}
            alt="Logo"
          />
         <CardContent sx={{paddingTop:"20px", justifyContent: "center", alignItems: "center"}}>
        <Typography variant="subtitle1"  color="text.secondary">{doc.despacho}</Typography>
                <Typography variant="h6" color="text.secondary">
                <Link
            style={{ display: "block", textDecoration: "none", color: "#474643"}}
            to={`/Jobs/${doc.id}`}
            target="_blank"
            key={doc.id} onClick ={(e) => getTrabajoId(doc.id) }
          >
            {doc.titulo}
          </Link>
                     
                </Typography>
                <Typography variant="subtitle2" color="text.secondary">
                     {doc.ciudad}
                </Typography>
        <Box sx={{paddingTop: "15px", display: 'flex', flexDirection: 'row'}}>
        
        <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px"}}>
            {doc.area[0].title}
        </Typography>
        {numeroAreasPractica.length > 1 && <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px", marginLeft: "5px"}}>
            {doc.area[1].title}
        </Typography>}
        </Box>
        {numeroAreasPractica.length > 2 && <Box sx={{paddingTop: "15px", display: 'flex', flexDirection: 'row'}}>
         <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px", marginLeft: "5px"}}>
            {doc.area[2].title}
        </Typography>
        </Box>}
        <Box sx={{paddingTop: "8px"}}>
        
        <Typography variant="caption" sx={{backgroundColor: "#03b1fc", borderRadius: "10px", color:"white", padding: "6px"}}>
            {`${doc.experiencia[0]} a ${doc.experiencia[1]} años`}
        </Typography>

        </Box>
        <Box sx={{paddingTop: "10px"}}>
        {(((doc.timestamp.toDate().getMonth() < date.getMonth()) && (doc.timestamp.toDate().getDate() < date.getDate())) || (doc.timestamp.toDate().getMonth() + 1 < date.getMonth())) &&  
            
            <Typography variant="caption" 
            sx={{border: "1px solid #b32217", 
            borderRadius: "10px", 
            color:"white", 
            bgcolor: "#b32217",
            padding: "6px"}}>
              Podría no estar ya disponible</Typography>}
        </Box>
        </CardContent>
        </CardActionArea>
    </Card>
    </Box> }

    {(doc.visibilidad === false && doc.requiereregistro === true && doc.requiereperfil !== true && (user && perfiles.length === 0)) &&  <Box sx={{display: { xl: 'none', l: "none", m: "block", s:"block", xs: 'block', justifyContent: "center", alignItems: "center", marginTop: "20px" }}} >
        <Card variant="outlined" sx={{alignContent: "center", alignItems: "center", width: "300px"}}>
        <CardActionArea component={Link} to={`/Jobs/${doc.id}`} target="_blank">
        <CardMedia
            component="img"
            sx={{paddingLeft: "15px", width: "80px", objectFit: "contain", justifyContent: "center", alignItems: "center"}}
            image={doc.logoOferta}
            alt="Logo"
          />
         <CardContent sx={{paddingTop:"20px", justifyContent: "center", alignItems: "center"}}>
        <Typography variant="subtitle1"  color="text.secondary">{doc.despacho}</Typography>
                <Typography variant="h6" color="text.secondary">
                <Link
            style={{ display: "block", textDecoration: "none", color: "#474643"}}
            to={`/Jobs/${doc.id}`}
            target="_blank"
            key={doc.id} onClick ={(e) => getTrabajoId(doc.id) }
          >
            {doc.titulo}
          </Link>
                     
                </Typography>
                <Typography variant="subtitle2" color="text.secondary">
                     {doc.ciudad}
                </Typography>
        <Box sx={{paddingTop: "15px", display: 'flex', flexDirection: 'row'}}>
        
        <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px"}}>
            {doc.area[0].title}
        </Typography>
        {numeroAreasPractica.length > 1 && <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px", marginLeft: "5px"}}>
            {doc.area[1].title}
        </Typography>}
        </Box>
        {numeroAreasPractica.length > 2 && <Box sx={{paddingTop: "15px", display: 'flex', flexDirection: 'row'}}>
         <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px", marginLeft: "5px"}}>
            {doc.area[2].title}
        </Typography>
        </Box>}
        <Box sx={{paddingTop: "8px"}}>
        
        <Typography variant="caption" sx={{backgroundColor: "#03b1fc", borderRadius: "10px", color:"white", padding: "6px"}}>
            {`${doc.experiencia[0]} a ${doc.experiencia[1]} años`}
        </Typography>

        </Box>
        <Box sx={{paddingTop: "10px"}}>
        {(((doc.timestamp.toDate().getMonth() < date.getMonth()) && (doc.timestamp.toDate().getDate() < date.getDate())) || (doc.timestamp.toDate().getMonth() + 1 < date.getMonth())) &&  
            
            <Typography variant="caption" 
            sx={{border: "1px solid #b32217", 
            borderRadius: "10px", 
            color:"white", 
            bgcolor: "#b32217",
            padding: "6px"}}>
              Podría no estar ya disponible</Typography>}
        </Box>
        </CardContent>
        </CardActionArea>
    </Card>
    </Box> }

    {(doc.visibilidad === false && (doc.requiereperfil === true || doc.requiereregistro === true) && !user) &&  <Box sx={{display: { xl: 'none', l: "none", m: "block", s:"block", xs: 'block', justifyContent: "center", alignItems: "center", marginTop: "20px" }}} >
        <Card variant="outlined" sx={{alignContent: "center", alignItems: "center", width: "300px"}}>
        <CardActionArea component={Link} to={`../`} target="_blank">
        <CardContent sx={{maxWidth: "100%", textAlign: "center", padding:"20px", bgcolor: "#7b8cb0", opacity: "0.7", backgroundImage: `url(${"https://firebasestorage.googleapis.com/v0/b/jobsandlaw-5e0ba.appspot.com/o/images%2Ftorres-1670879.jpg?alt=media&token=c0654ab3-441b-4b37-8af3-d60d7101854a"})`  }}>
          <Typography component="div" color= "whitesmoke" sx={{alignContent: "center", fontSize: "16px"}}>
            Regístrate gratis para desbloquear esta vacante
          </Typography>
  
        </CardContent>
         <CardContent sx={{paddingTop:"20px", justifyContent: "center", alignItems: "center"}}>
        <Typography variant="subtitle1"  color="text.secondary">{doc.tipoDespacho.title}</Typography>
                <Typography variant="h6" color="text.secondary">
                <Link
            style={{ display: "block", textDecoration: "none", color: "#474643"}}
            to={`/`}
            target="_blank"
            key={doc.id} onClick ={(e) => getTrabajoId(doc.id) }
          >
            {doc.titulo}
          </Link>
                     
                </Typography>
                <Typography variant="subtitle2" color="text.secondary">
                     {doc.ciudad}
                </Typography>
        <Box sx={{paddingTop: "15px", display: 'flex', flexDirection: 'row'}}>
        
        <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px"}}>
            {doc.area[0].title}
        </Typography>
        {numeroAreasPractica.length > 1 && <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px", marginLeft: "5px"}}>
            {doc.area[1].title}
        </Typography>}
        </Box>
        {numeroAreasPractica.length > 2 && <Box sx={{paddingTop: "15px", display: 'flex', flexDirection: 'row'}}>
         <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px", marginLeft: "5px"}}>
            {doc.area[2].title}
        </Typography>
        </Box>}
        <Box sx={{paddingTop: "8px"}}>
        
        <Typography variant="caption" sx={{backgroundColor: "#03b1fc", borderRadius: "10px", color:"white", padding: "6px"}}>
            {`${doc.experiencia[0]} a ${doc.experiencia[1]} años`}
        </Typography>

        </Box>
        </CardContent>
        </CardActionArea>
    </Card>
  
    </Box> }

    {(doc.visibilidad === false && user && (doc.requiereperfil === true || doc.requiereregistro === true) && (perfiles.length !== 0)) &&  <Box sx={{display: { xl: 'none', l: "none", m: "block", s:"block", xs: 'block', justifyContent: "center", alignItems: "center", marginTop: "20px" }}} >
        <Card variant="outlined" sx={{alignContent: "center", alignItems: "center", width: "300px"}}>
        <CardActionArea component={Link} to={`/Jobs/${doc.id}`} target="_blank">
        <CardMedia
            component="img"
            sx={{paddingLeft: "15px", width: "80px", objectFit: "contain", justifyContent: "center", alignItems: "center"}}
            image={doc.logoOferta}
            alt="Logo"
          />
         <CardContent sx={{paddingTop:"20px", justifyContent: "center", alignItems: "center"}}>
        <Typography variant="subtitle1"  color="text.secondary">{doc.despacho}</Typography>
                <Typography variant="h6" color="text.secondary">
                <Link
            style={{ display: "block", textDecoration: "none", color: "#474643"}}
            to={`/Jobs/${doc.id}`}
            target="_blank"
            key={doc.id} onClick ={(e) => getTrabajoId(doc.id) }
          >
            {doc.titulo}
          </Link>
                     
                </Typography>
                <Typography variant="subtitle2" color="text.secondary">
                     {doc.ciudad}
                </Typography>
        <Box sx={{paddingTop: "15px", display: 'flex', flexDirection: 'row'}}>
        
        <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px"}}>
            {doc.area[0].title}
        </Typography>
        {numeroAreasPractica.length > 1 && <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px", marginLeft: "5px"}}>
            {doc.area[1].title}
        </Typography>}
        </Box>
        {numeroAreasPractica.length > 2 && <Box sx={{paddingTop: "15px", display: 'flex', flexDirection: 'row'}}>
         <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px", marginLeft: "5px"}}>
            {doc.area[2].title}
        </Typography>
        </Box>}
        <Box sx={{paddingTop: "8px"}}>
        
        <Typography variant="caption" sx={{backgroundColor: "#03b1fc", borderRadius: "10px", color:"white", padding: "6px"}}>
            {`${doc.experiencia[0]} a ${doc.experiencia[1]} años`}
        </Typography>

        </Box>
        <Box sx={{paddingTop: "10px"}}>
        {(((doc.timestamp.toDate().getMonth() < date.getMonth()) && (doc.timestamp.toDate().getDate() < date.getDate())) || (doc.timestamp.toDate().getMonth() + 1 < date.getMonth())) &&  
            
            <Typography variant="caption" 
            sx={{border: "1px solid #b32217", 
            borderRadius: "10px", 
            color:"white", 
            bgcolor: "#b32217",
            padding: "6px"}}>
              Podría no estar ya disponible</Typography>}
        </Box>
        </CardContent>
        </CardActionArea>
    </Card>
    </Box> }

    {(doc.visibilidad === false && doc.requiereperfil === true && (perfiles.length === 0) && user) &&  <Box sx={{display: { xl: 'none', l: "none", m: "block", s:"block", xs: 'block', justifyContent: "center", alignItems: "center", marginTop: "20px" }}} >
        <Card variant="outlined" sx={{alignContent: "center", alignItems: "center", width: "300px"}}>
        <CardActionArea component={Link} to={`../perfilprofesional`} target="_blank">
        <CardContent sx={{maxWidth: "100%", textAlign: "center", padding:"20px", bgcolor: "#7b8cb0", opacity: "0.7", backgroundImage: `url(${"https://firebasestorage.googleapis.com/v0/b/jobsandlaw-5e0ba.appspot.com/o/images%2Ftorres-1670879.jpg?alt=media&token=c0654ab3-441b-4b37-8af3-d60d7101854a"})`  }}>
          <Typography component="div" color= "whitesmoke" sx={{alignContent: "center", fontSize: "16px"}}>
          Crea un perfil profesional para desbloquear esta vacante 
          </Typography>
  
        </CardContent>
         <CardContent sx={{paddingTop:"20px", justifyContent: "center", alignItems: "center"}}>
        <Typography variant="subtitle1"  color="text.secondary">{doc.tipoDespacho.title}</Typography>
                <Typography variant="h6" color="text.secondary">
                <Link
            style={{ display: "block", textDecoration: "none", color: "#474643"}}
            to={`/perfilprofesional`}
            target="_blank"
            key={doc.id} onClick ={(e) => getTrabajoId(doc.id) }
          >
            {doc.titulo}
         
          </Link>
                     
                </Typography>
                <Typography variant="subtitle2" color="text.secondary">
                     {doc.ciudad}
                </Typography>
        <Box sx={{paddingTop: "15px", display: 'flex', flexDirection: 'row'}}>
        
        <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px"}}>
            {doc.area[0].title}
        </Typography>
        {numeroAreasPractica.length > 1 && <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px", marginLeft: "5px"}}>
            {doc.area[1].title}
        </Typography>}
        </Box>
        {numeroAreasPractica.length > 2 && <Box sx={{paddingTop: "15px", display: 'flex', flexDirection: 'row'}}>
         <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px", marginLeft: "5px"}}>
            {doc.area[2].title}
        </Typography>
        </Box>}
        <Box sx={{paddingTop: "8px"}}>
        
        <Typography variant="caption" sx={{backgroundColor: "#03b1fc", borderRadius: "10px", color:"white", padding: "6px"}}>
            {`${doc.experiencia[0]} a ${doc.experiencia[1]} años`}
        </Typography>
  
        </Box>
        </CardContent>
        </CardActionArea>
    </Card>
  
    </Box> }

    
    </div>
    ) }
    
  )




  console.log(payments.length)
  console.log(isPremium)




  const [open1, setOpen1] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose1 = () => {
    setOpen(false);
  };



    return (
    
      <AuthProvider 
      onUserLoggedIn={handleUserLoggedIn}
      onUserNotRegistered={handleUserNotRegistered}
      onUserNotLoggedIn={handleUserNotLoggedIn}
      > 
    <div>

          

    <Header />
    {/*
<Dialog
        open={open}
        onClose={handleClose}
        sx={{
       
          display: {
            xl: "block",
            l: "block",
            m: "none",
            s: "none",
            xs: "none",
          },
        }}>
          <DialogContent sx={{bgcolor: "#1f387d"}}>

<div>
        <Box
    
          sx={{
       
            display: {
              xl: "block",
              l: "block",
              m: "none",
              s: "none",
              xs: "none",
            },
          }}
        >
          <Stack
            display="flex"
            justifyContent="center"
            sx={{
              alignContent: "center",
              alignItems: "center",
              justifyContent: "center",
            }}
          >

        <Stack sx={{paddingTop: "20px", paddingBottom: "20px" }} spacing={2}>
        

         <Stack direction="column" sx={{alignContent: "center", alignItems: "center", paddingTop: "10px"}}>
           <Stack direction="column" sx={{alignContent: "center", alignItems: "center", paddingTop: "10px"}}>
           <Image src="https://firebasestorage.googleapis.com/v0/b/jobsandlaw-5e0ba.appspot.com/o/images%2FRankings%20(1).png?alt=media&token=d8a704e7-9d2d-4b38-ac38-ddd13213c1c6" style={{width: "120px"}} />
           <Typography variant="h5" component="div" color = "whitesmoke" sx={{fontWeight: "bold", textAlign: "center", marginBottom: "0px"}} >
           Oh oh... ¡Todavía no has creado tu perfil profesional!
         </Typography>
         <Typography sx={{ fontSize: 14, textAlign: "center", paddingTop: "10px" }} color="whitesmoke" gutterBottom>
           Los principales despachos, empresas y headhunters están buscando en Jobs&Law candidatos como tú, pero... eres invisible para ellos.
         </Typography>
        
         <Typography variant="h5" component="div" color = "whitesmoke" sx={{fontWeight: "bold", textAlign: "center", marginBottom: "0px", paddingTop: "30px"}} >
           Crea tu perfil completamente gratis y empieza a recibir ofertas de manera discreta y confidencial.
         </Typography>
         <Typography variant="h6" component="div" color = "whitesmoke" sx={{fontWeight: "bold", textAlign: "center", marginBottom: "0px", paddingTop: "30px"}} >
           Además, si creas tu perfil profesional hoy...
         </Typography>
         <Typography variant="h5" component="div" color = "whitesmoke" sx={{fontWeight: "bold", textAlign: "center", marginBottom: "0px"}} >
           ¡Te damos acceso gratis a Premium durante 30 días!
         </Typography>
         <List sx={{color: "white", padding: "20px", lineHeight: '10px'}}>
          <ListItem disablePadding sx={{lineHeight: '10px'}}>
            <ListItemButton>
              <ListItemIcon>
                <ArrowForwardIosIcon sx={{color: "white"}} />
              </ListItemIcon>
              <ListItemText disableTypography
        primary={<Typography >Acceso a <u>todas</u> las vacantes del mercado</Typography>} sx={{color: "white", lineHeight: 0, margin: 0 }} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <ArrowForwardIosIcon sx={{color: "white"}} />
              </ListItemIcon>
              <ListItemText primary="Información interna sobre los principales despachos" sx={{color: "white"}} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <ArrowForwardIosIcon sx={{color: "white"}} />
              </ListItemIcon>
              <ListItemText primary="Perfil profesional preferente" sx={{color: "white"}} />
            </ListItemButton>
          </ListItem>
        </List>
         </Stack>
         <Button variant="outlined" sx={{width: "200px", marginTop: "20px", alignContent: "center", textAlign: "center", marginBottom: "20px", paddingTop: "10px", paddingLeft: "10px", paddingRight: "10px", paddingBottom: "10px", color: "white", bgcolor: "#1f387d", borderColor: "white", fontSize: "15px", ':hover': {
      bgcolor: 'white', // theme.palette.primary.main
      color: '#1f387d',
    }}}

     color="inherit"
     href="./perfilprofesional"
   >
     <span>Crear perfil profesional</span>
   </Button>
         </Stack>
         <Button component="button" onClick={handleClose1} >
         <Typography sx={{color: "white", textAlign: "center", fontSize: "14px", paddingTop: "10px"}}>
            
            Prefiero no disfrutar de mi prueba gratuita</Typography></Button>

   
        
                 </Stack> 
                 </Stack>
                 </Box>



                
                 </div>
                 </DialogContent>
</Dialog>


<Dialog
        open={open}
        onClose={handleClose}
        sx={{
       
          display: {
            xl: "none",
            l: "none",
            m: "block",
            s: "block",
            xs: "block",
          },
        }}>
          <DialogContent sx={{bgcolor: "#1f387d"}}>

<div>
        <Box
    
       
        >
          <Stack
            display="flex"
            justifyContent="center"
            sx={{
              alignContent: "center",
              alignItems: "center",
              justifyContent: "center",
            }}
          >

        <Stack sx={{paddingTop: "0px", paddingBottom: "20px" }} spacing={2}>
        

         <Stack direction="column" sx={{alignContent: "center", alignItems: "center"}}>
           <Stack direction="column" sx={{alignContent: "center", alignItems: "center"}}>
           <Image src="https://firebasestorage.googleapis.com/v0/b/jobsandlaw-5e0ba.appspot.com/o/images%2FRankings%20(1).png?alt=media&token=d8a704e7-9d2d-4b38-ac38-ddd13213c1c6" style={{width: "120px"}} />
           <Typography component="div" color = "whitesmoke" sx={{fontSize: 18, fontWeight: "bold", textAlign: "center", marginBottom: "0px"}} >
           Oh oh... ¡Todavía no has creado tu perfil profesional!
         </Typography>
         <Typography sx={{ fontSize: 14, textAlign: "center", paddingTop: "10px" }} color="whitesmoke" gutterBottom>
           Los principales despachos, empresas y headhunters están buscando en Jobs&Law candidatos como tú, pero... eres invisible para ellos.
         </Typography>
        
         <Typography component="div" color = "whitesmoke" sx={{fontSize: 16, fontWeight: "bold", textAlign: "center", marginBottom: "0px", paddingTop: "30px"}} >
           Crea tu perfil completamente gratis y empieza a recibir ofertas de manera discreta y confidencial.
         </Typography>
         <Typography component="div" color = "whitesmoke" sx={{fontSize: 16, fontWeight: "bold", textAlign: "center", marginBottom: "0px", paddingTop: "30px"}} >
           Además, si creas tu perfil profesional hoy...
         </Typography>
         <Typography component="div" color = "whitesmoke" sx={{fontSize: 16, fontWeight: "bold", textAlign: "center", marginBottom: "0px"}} >
           ¡Te damos acceso gratis a Premium durante 30 días!
         </Typography>
         
         </Stack>
         <Button variant="outlined" sx={{width: "200px", marginTop: "20px", alignContent: "center", textAlign: "center", marginBottom: "20px", paddingTop: "10px", paddingLeft: "10px", paddingRight: "10px", paddingBottom: "10px", color: "white", bgcolor: "#1f387d", borderColor: "white", fontSize: "15px", ':hover': {
      bgcolor: 'white', // theme.palette.primary.main
      color: '#1f387d',
    }}}

     color="inherit"
     href="./perfilprofesional"
   >
     <span>Crear perfil profesional</span>
   </Button>
         </Stack>
         <Button component="button" onClick={handleClose1} >
         <Typography sx={{color: "white", textAlign: "center", fontSize: "14px", paddingTop: "10px"}}>
            
            Prefiero no disfrutar de mi prueba gratuita</Typography></Button>

   
        
                 </Stack> 
                 </Stack>
                 </Box>



                
                 </div>
                 </DialogContent>
</Dialog> */}

{/*<Fab variant="extended" style={{justifyContent: "center",
      alignItems: "center"}} sx={{width: "500px", "& .MuiDialog-container": {
      justifyContent: "center",
      alignItems: "center",
      color: "green",
      bgcolor: "blue",
      marginTop: "400px"
    }
  }}
  PaperProps={{
    sx: {
      m: 0,
      top: 10,
      left: 10
    }}}>
     
     <Typography component="div" color = "whitesmoke" sx={{fontSize: 16, fontWeight: "bold", textAlign: "center", marginBottom: "0px"}} >
           ¡Te damos acceso gratis a Premium durante 30 días!
         </Typography>
  </Fab>*/}



    <Grid container componente="main" spacing={0} sx={{justifyContent: "center", alignItems: "center", paddingTop: "40px"}}>
      <Grid item xs={0} sm={1} md={1} square sx={{display: { xl: 'inline', l: "inline", m: "none", s:"none", xs: 'none' }}}>
      </Grid>
      <Grid item xs={12} sm={3} md={3} square sx={{display: { xl: 'inline', l: "inline", m: "none", s:"none", xs: 'none' }}}>
      <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: "flex-end",
                justifyContent: "center",
                marginTop: "20px",

             }}
            >
       
       <Autocomplete
        value={area}
        onChange={(event, newArea) => {
          setArea(newArea);
        }}
        inputValue={inputArea}
        onInputChange={(event, newInputArea) => {
          setInputArea(newInputArea);
          if(page !== 1) {
            setPage(1)
          }
        }}
        id="controllable-states-demo"
        options={areas}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label="Área de práctica" />}
      />


      </Box>
      </Grid>
      
      <Grid item xs={1} sm={1} md={1} square sx={{display: { xl: 'inline', l: "inline", m: "none", s:"none", xs: 'none' }}}>
      <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: "center",
                justifyContent: "center",
                marginTop: "10px",

             }}
            >
      
      </Box>
      </Grid>

      

      <Grid item xs={12} sm={3} md={3} square sx={{display: { xl: 'inline', l: "inline", m: "none", s:"none", xs: 'none' }}}>
      <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: "flex-start",
                justifyContent: "center",
                marginTop: "20px",

             }}
            >
              
      <Autocomplete
        value={ciudad}
        onChange={(event, newCiudad) => {
          setCiudad(newCiudad);
        }}
        inputValue={inputCiudad}
        onInputChange={(event, newInputCiudad) => {
          setInputCiudad(newInputCiudad);
          if(page !== 1) {
            setPage(1)
          }
        }}
        id="controllable-states-demo"
        options={ciudades}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label="Ciudad" />}
      />
      
      </Box>
      </Grid>

      <Grid item xs={1} sm={1} md={1} square sx={{display: { xl: 'inline', l: "inline", m: "none", s:"none", xs: 'none' }}}>
      </Grid>

    </Grid>


    <Grid container componente="main" spacing={0} sx={{justifyContent: "center", paddingTop: "20px",  display: { xl: 'none', l: "none", m: "inline", s:"inline", xs: 'inline' }}}>
      <Grid item xs={0} sm={1} md={1} square>
      </Grid>
      <Grid item xs={12} sm={12} md={12} square>
      <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: "center",
                justifyContent: "center",
                marginTop: "0px",

             }}
            >
       
       <Autocomplete
        value={area}
        onChange={(event, newArea) => {
          setArea(newArea);
        }}
        inputValue={inputArea}
        onInputChange={(event, newInputArea) => {
          setInputArea(newInputArea);
          if(page !== 1) {
            setPage(1)
          }
        }}
        id="controllable-states-demo"
        options={areas}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label="Área de práctica" />}
      />


      </Box>
      </Grid>
      
      <Grid item xs={1} sm={1} md={1} square>
      <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: "center",
                justifyContent: "center",
                marginTop: "10px",

             }}
            >
      
      </Box>
      </Grid>

      

      <Grid item xs={12} sm={12} md={12} square>
      <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: "center",
                justifyContent: "center",
                marginTop: "20px",

             }}
            >
              
      <Autocomplete
        value={ciudad}
        onChange={(event, newCiudad) => {
          setCiudad(newCiudad);
        }}
        inputValue={inputCiudad}
        onInputChange={(event, newInputCiudad) => {
          setInputCiudad(newInputCiudad);
          if(page !== 1) {
            setPage(1)
          }
        }}
        id="controllable-states-demo"
        options={ciudades}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label="Ciudad" />}
      />
      
      </Box>
      </Grid>

      <Grid item xs={1} sm={1} md={1} square>
      </Grid>

    </Grid>




    <Grid container component="main" 
        sx={{ 
          marginTop: "20px",
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat', }}>
          <Grid item xs={12} sm={12} md={12} square>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: "center",
                justifyContent: "center",
                bgcolor: 'white',
                backgroundSize: 'cover',
                backgroundPosition: 'right',
                marginTop: "20px",

             }}
             
            >
            <Typography gutterBottom sx={{paddingBottom: "10px"}}>Años de experiencia</Typography>
            <Slider
        sx={{maxWidth: "90%", width: "750px", display: { xl: 'block', l: "block", m: "block", s:"none", xs: 'none' }}}
        getAriaLabel={() => 'Años de experiencia'}
        value={experiencia1}
        onChange={handleChangeExperiencia}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
        step={1}
        min={0}
        max={20}
        marks={anosExperiencia}
      />
      <Slider
        sx={{maxWidth: "90%", width: "750px", display: { xl: 'none', l: "none", m: "none", s:"block", xs: 'block' }}}
        getAriaLabel={() => 'Años de experiencia'}
        value={experiencia1}
        onChange={handleChangeExperiencia}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
        step={1}
        min={0}
        max={20}
        marks={anosExperienciaMovil}
      />
            </Box>
          </Grid>
      </Grid>

      {user && <Grid container component="main" 
            sx={{ 
              marginTop: "40px",
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat', }}>
              <Grid item xs={12} sm={12} md={12} square>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: "center",
                    justifyContent: "center",
                    bgcolor: 'white',
                    backgroundSize: 'cover',
                    backgroundPosition: 'right',
                    marginTop: "20px"
    
                 }}
                 
                >
        <Card elevation={10} sx={{ marginRight: "20px", width: "600px", display: { xl: 'block', l: "block", m: "none", s:"none", xs: 'none'}}}>
        <CardActionArea component={RouterLink} to={"/alljobs"} >
        <CardContent>
        <Typography sx={{textAlign: "center", fontSize: "16px", fontWeight: "500", paddingBottom: "10px" }} color="text.secondary" gutterBottom>
        Hoy hay {totalJobs} vacantes para abogados aceptando candidaturas. En el Buscador Básico puedes acceder a {numberMatchesOpen} de manera gratuita.
        </Typography>
        <Typography sx={{ fontSize: 14,textAlign: "center" }} color="text.secondary" gutterBottom>
          Estás en el Buscador Básico de Empleo para Abogados. Tienes acceso gratuito a una selección de empleos realizada por Jobs&Law que representa aproximadamente el 20% de las vacantes de calidad para abogados que están aceptando candidaturas hoy.
        </Typography>
        <Typography sx={{ fontSize: 14,textAlign: "center",paddingTop: "20px", paddingBottom: "20px" }} color="text.secondary" gutterBottom>
          Recuerda que, si no quieres perderte ninguna oportunidad, puedes ver el 100% de las vacantes abiertas en el Buscador Premium de Empleo para Abogados.
        </Typography>
        <Stack sx={{alignContent: "center"}}>
        <Button href="/alljobs" sx={{alignContent: "center"}}>Ir al Buscador Premium</Button>
        </Stack>
        </CardContent>
        </CardActionArea>
        </Card>
        <Card elevation={10} sx={{ marginRight: "20px", width: "300px", display: {xl: 'none', l: "none", m: "block", s:"block", xs: 'block'}}}>
        <CardActionArea component={RouterLink} to={"/alljobs"} >
        <CardContent>
        <Typography sx={{textAlign: "center", fontSize: "16px", fontWeight: "500", paddingBottom: "10px" }} color="text.secondary" gutterBottom>
        Hoy hay {totalJobs} vacantes para abogados aceptando candidaturas. En el Buscador Básico puedes acceder a {numberMatchesOpen} de manera gratuita.
        </Typography>
        <Typography sx={{ fontSize: 14,textAlign: "center" }} color="text.secondary" gutterBottom>
          Estás en el Buscador Básico de Empleo para Abogados. Tienes acceso gratuito a una selección de empleos realizada por Jobs&Law que representa aproximadamente el 20% de las vacantes de calidad para abogados que están aceptando candidaturas hoy.
        </Typography>
        <Typography sx={{ fontSize: 14,textAlign: "center",paddingTop: "20px", paddingBottom: "20px" }} color="text.secondary" gutterBottom>
          Recuerda que, si no quieres perderte ninguna oportunidad, puedes ver el 100% de las vacantes abiertas en el Buscador Premium de Empleo para Abogados.
        </Typography>
        <Stack sx={{alignContent: "center"}}>
        <Button href="/alljobs" sx={{alignContent: "center"}}>Ir al Buscador Premium</Button>
        </Stack>
        </CardContent>
        </CardActionArea>
        </Card>
                </Box>
                </Grid>
                </Grid>}


                {!user && <Grid container component="main" 
            sx={{ 
              marginTop: "40px",
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat', }}>
              <Grid item xs={12} sm={12} md={12} square>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: "center",
                    justifyContent: "center",
                    bgcolor: 'white',
                    backgroundSize: 'cover',
                    backgroundPosition: 'right',
                    marginTop: "20px"
    
                 }}
                 
                >
        <Card elevation={10} sx={{ marginRight: "20px", width: "600px", display: { xl: 'block', l: "block", m: "none", s:"none", xs: 'none'}}}>
        <CardActionArea component={RouterLink} to={"/"} >
        <CardContent>
        <Typography sx={{ fontSize: 14,textAlign: "center" }} color="text.secondary" gutterBottom>
        Como usuario no registrado solo tienes acceso a una selección limitada de empleos. Puedes acceder a más empleos regístrandote gratis en Jobs&Law.
        </Typography>
        <Stack sx={{alignContent: "center"}}>
        <Button href="/" sx={{alignContent: "center"}}>Registrarse</Button>
        </Stack>
        </CardContent>
        </CardActionArea>
        </Card>
        <Card elevation={10} sx={{ marginRight: "20px", width: "300px", display: {xl: 'none', l: "none", m: "block", s:"block", xs: 'block'}}}>
        <CardActionArea component={RouterLink} to={"/"} >
        <CardContent>
        <Typography sx={{ fontSize: 14,textAlign: "center" }} color="text.secondary" gutterBottom>
        Como usuario no registrado solo tienes acceso a una selección limitada de empleos. Puedes acceder a más empleos regístrandote gratis en Jobs&Law.
        </Typography>
        <Stack sx={{alignContent: "center"}}>
        <Button href="/" sx={{alignContent: "center"}}>Registrarse</Button>
        </Stack>
        </CardContent>
        </CardActionArea>
        </Card>
                </Box>
                </Grid>
                </Grid>}

                 

            <Grid container component="main" 
            sx={{ 
              marginTop: "40px",
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat', }}>
              <Grid item xs={12} sm={12} md={12} square>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: "center",
                    justifyContent: "center",
                    bgcolor: 'white',
                    backgroundSize: 'cover',
                    backgroundPosition: 'right',
                    marginTop: "20px"
    
                 }}
                 
                >
                {eachjob2}
                
                {(numberLimitedJobs <= 5 && numberLimitedJobs !== 0 && numberLimitedJobs !== 1) && 
                 
                 <Grid container component="main" 
            sx={{ 
              marginTop: "40px",
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat', }}>
              <Grid item xs={12} sm={12} md={12} square>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: "center",
                    justifyContent: "center",
                    bgcolor: 'white',
                    backgroundSize: 'cover',
                    backgroundPosition: 'right',
                    marginTop: "20px",
                    marginBottom: "80px"
                 }}
                 
                >
        <Card elevation={10} sx={{ marginRight: "20px", width: "600px", bgcolor: "#367bb3", display: { xl: 'block', l: "block", m: "none", s:"none", xs: 'none'}}}>
        <CardActionArea component={RouterLink} to={"/alljobs"} >
        <CardContent>
        <Typography sx={{textAlign: "center", fontSize: "16px", fontWeight: "500", paddingBottom: "10px" }} color="white" gutterBottom>
        En el Buscador Básico hay solo {numberLimitedJobs} empleos que cumplen tus criterios de búsqueda. En el Buscador Premium tienes acceso a {numberMatchesPremium - 1} empleos adicionales que cumplen esos criterios.
        </Typography>
        <Stack sx={{alignContent: "center"}}>
        <Button variant="outlined" href="/alljobs" sx={{alignContent: "center", color: "white"}}>Ir al Buscador Premium</Button>
        </Stack>
        </CardContent>
        </CardActionArea>
        </Card>
        <Card elevation={10} sx={{ marginRight: "20px", width: "300px", bgcolor: "#367bb3", display: {xl: 'none', l: "none", m: "block", s:"block", xs: 'block'}}}>
        <CardActionArea component={RouterLink} to={"/alljobs"} >
        <CardContent>
        <Typography sx={{textAlign: "center", fontSize: "16px", fontWeight: "500", paddingBottom: "10px" }} color="white" gutterBottom>
        En el Buscador Básico hay solo {numberLimitedJobs} empleos que cumplen tus criterios de búsqueda. En el Buscador Premium tienes acceso a {numberMatchesPremium - 1} empleos adicionales que cumplen esos criterios.
        </Typography>
        <Stack sx={{alignContent: "center"}}>
        <Button variant="outlined" href="/alljobs" sx={{alignContent: "center", color: "white"}}>Ir al Buscador Premium</Button>
        </Stack>
        </CardContent>
        </CardActionArea>
        </Card>
                </Box>
                </Grid>
                </Grid>
                 
                 }

{(numberLimitedJobs === 1) && 
                 
                 <Grid container component="main" 
            sx={{ 
              marginTop: "40px",
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat', }}>
              <Grid item xs={12} sm={12} md={12} square>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: "center",
                    justifyContent: "center",
                    bgcolor: 'white',
                    backgroundSize: 'cover',
                    backgroundPosition: 'right',
                    marginTop: "20px",
                    marginBottom: "80px"
                 }}
                 
                >
        <Card elevation={10} sx={{ marginRight: "20px", width: "600px", bgcolor: "#367bb3", display: { xl: 'block', l: "block", m: "none", s:"none", xs: 'none'}}}>
        <CardActionArea component={RouterLink} to={"/alljobs"} >
        <CardContent>
        <Typography sx={{textAlign: "center", fontSize: "16px", fontWeight: "500", paddingBottom: "10px" }} color="white" gutterBottom>
        En el Buscador Básico hay solo {numberLimitedJobs} empleo que cumple tus criterios de búsqueda. En el Buscador Premium tienes acceso a {numberMatchesPremium - 1} empleos adicionales que cumplen esos criterios.
        </Typography>
        <Stack sx={{alignContent: "center"}}>
        <Button variant="outlined" href="/alljobs" sx={{alignContent: "center", color: "white"}}>Ir al Buscador Premium</Button>
        </Stack>
        </CardContent>
        </CardActionArea>
        </Card>
        <Card elevation={10} sx={{ marginRight: "20px", width: "300px", bgcolor: "#367bb3", display: {xl: 'none', l: "none", m: "block", s:"block", xs: 'block'}}}>
        <CardActionArea component={RouterLink} to={"/alljobs"} >
        <CardContent>
        <Typography sx={{textAlign: "center", fontSize: "16px", fontWeight: "500", paddingBottom: "10px" }} color="white" gutterBottom>
        En el Buscador Básico hay solo {numberLimitedJobs} empleo que cumple tus criterios de búsqueda. En el Buscador Premium tienes acceso a {numberMatchesPremium - 1} empleos adicionales que cumplen esos criterios.
        </Typography>
        <Stack sx={{alignContent: "center"}}>
        <Button variant="outlined" href="/alljobs" sx={{alignContent: "center", color: "white"}}>Ir al Buscador Premium</Button>
        </Stack>
        </CardContent>
        </CardActionArea>
        </Card>
                </Box>
                </Grid>
                </Grid>
                 
                 }


{(numberLimitedJobs === 0 && numberMatchesPremium !== 0) && 
                 
                 <Grid container component="main" 
            sx={{ 
              marginTop: "40px",
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat', }}>
              <Grid item xs={12} sm={12} md={12} square>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: "center",
                    justifyContent: "center",
                    bgcolor: 'white',
                    backgroundSize: 'cover',
                    backgroundPosition: 'right',
                    marginTop: "20px",
                    marginBottom: "80px"
                 }}
                 
                >
        <Card elevation={10} sx={{ marginRight: "20px", width: "600px", bgcolor: "#367bb3", display: { xl: 'block', l: "block", m: "none", s:"none", xs: 'none'}}}>
        <CardActionArea component={RouterLink} to={"/alljobs"} >
        <CardContent>
        <Typography sx={{textAlign: "center", fontSize: "16px", fontWeight: "500", paddingBottom: "10px" }} color="white" gutterBottom>
        En el Buscador Básico no hay empleos que cumplan tus criterios de búsqueda. En el Buscador Premium tienes acceso a {numberMatchesPremium } empleos que cumplen tus criterios de búsqueda.
        </Typography>
        <Stack sx={{alignContent: "center"}}>
        <Button variant="outlined" href="/alljobs" sx={{alignContent: "center", color: "white"}}>Ir al Buscador Premium</Button>
        </Stack>
        </CardContent>
        </CardActionArea>
        </Card>
        <Card elevation={10} sx={{ marginRight: "20px", width: "300px", bgcolor: "#367bb3", display: {xl: 'none', l: "none", m: "block", s:"block", xs: 'block'}}}>
        <CardActionArea component={RouterLink} to={"/alljobs"} >
        <CardContent>
        <Typography sx={{textAlign: "center", fontSize: "16px", fontWeight: "500", paddingBottom: "10px" }} color="white" gutterBottom>
        En el Buscador Básico hay solo {numberLimitedJobs} empleos que cumplen tus criterios de búsqueda. En el Buscador Premium tienes acceso a {numberMatchesPremium} empleos que cumplen tus criterios de búsqueda.
        </Typography>
        <Stack sx={{alignContent: "center"}}>
        <Button variant="outlined" href="/alljobs" sx={{alignContent: "center", color: "white"}}>Ir al Buscador Premium</Button>
        </Stack>
        </CardContent>
        </CardActionArea>
        </Card>
                </Box>
                </Grid>
                </Grid>
                 
                 }

{(numberLimitedJobs === 0 && numberMatchesPremium === 0) && 
                 
                 <Grid container component="main" 
            sx={{ 
              marginTop: "40px",
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat', }}>
              <Grid item xs={12} sm={12} md={12} square>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: "center",
                    justifyContent: "center",
                    bgcolor: 'white',
                    backgroundSize: 'cover',
                    backgroundPosition: 'right',
                    marginTop: "20px",
                    marginBottom: "80px"
                 }}
                 
                >
        <Card elevation={10} sx={{ marginRight: "20px", width: "600px", bgcolor: "#367bb3", display: { xl: 'block', l: "block", m: "none", s:"none", xs: 'none'}}}>
        <CardActionArea component={RouterLink} to={"/alljobs"} >
        <CardContent>
        <Typography sx={{textAlign: "center", fontSize: "16px", fontWeight: "500", paddingBottom: "10px" }} color="white" gutterBottom>
        No hay empleos que cumplan tus criterios de búsqueda. Prueba con una búsqueda más amplia.
        </Typography>
        <Stack sx={{alignContent: "center"}}>
        
        </Stack>
        </CardContent>
        </CardActionArea>
        </Card>
        <Card elevation={10} sx={{ marginRight: "20px", width: "300px", bgcolor: "#367bb3", display: {xl: 'none', l: "none", m: "block", s:"block", xs: 'block'}}}>
        <CardActionArea component={RouterLink} to={"/alljobs"} >
        <CardContent>
        <Typography sx={{textAlign: "center", fontSize: "16px", fontWeight: "500", paddingBottom: "10px" }} color="white" gutterBottom>
        No hay empleos que cumplan tus criterios de búsqueda. Prueba con una búsqueda más amplia.
        </Typography>
        <Stack sx={{alignContent: "center"}}>
        
        </Stack>
        </CardContent>
        </CardActionArea>
        </Card>
                </Box>
                </Grid>
                </Grid>
                 
                 }

                <Pagination count={noOfPages} page={page} defaultPage={1} onChange={handleChange} variant="outlined" shape="rounded" sx={{paddingTop:"20px", paddingBottom: "20px"}} />
                
                


                {(numberLimitedJobs > 5) && 
                 
                 <Grid container component="main" 
            sx={{ 
              marginTop: "40px",
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat', }}>
              <Grid item xs={12} sm={12} md={12} square>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: "center",
                    justifyContent: "center",
                    bgcolor: 'white',
                    backgroundSize: 'cover',
                    backgroundPosition: 'right',
                    marginTop: "20px",
                    marginBottom: "80px"
                 }}
                 
                >
        <Card elevation={10} sx={{ marginRight: "20px", width: "600px", bgcolor: "#367bb3", display: { xl: 'block', l: "block", m: "none", s:"none", xs: 'none'}}}>
        <CardActionArea component={RouterLink} to={"/alljobs"} >
        <CardContent>
        <Typography sx={{textAlign: "center", fontSize: "16px", fontWeight: "500", paddingBottom: "10px" }} color="white" gutterBottom>
        En el Buscador Básico hay {numberLimitedJobs} empleos que cumplen tus criterios de búsqueda. En el Buscador Premium tienes acceso a {numberMatchesPremium - 1} empleos adicionales que cumplen esos criterios.
        </Typography>
        <Stack sx={{alignContent: "center"}}>
        <Button variant="outlined" href="/alljobs" sx={{alignContent: "center", color: "white"}}>Ir al Buscador Premium</Button>
        </Stack>
        </CardContent>
        </CardActionArea>
        </Card>
        <Card elevation={10} sx={{ marginRight: "20px", width: "300px", bgcolor: "#367bb3", display: {xl: 'none', l: "none", m: "block", s:"block", xs: 'block'}}}>
        <CardActionArea component={RouterLink} to={"/alljobs"} >
        <CardContent>
        <Typography sx={{textAlign: "center", fontSize: "16px", fontWeight: "500", paddingBottom: "10px" }} color="white" gutterBottom>
        En el Buscador Básico hay {numberLimitedJobs} empleos que cumplen tus criterios de búsqueda. En el Buscador Premium tienes acceso a {numberMatchesPremium - 1} empleos adicionales que cumplen esos criterios.
        </Typography>
        <Stack sx={{alignContent: "center"}}>
        <Button variant="outlined" href="/alljobs" sx={{alignContent: "center", color: "white"}}>Ir al Buscador Premium</Button>
        </Stack>
        </CardContent>
        </CardActionArea>
        </Card>
                </Box>
                </Grid>
                </Grid>
                 
                 }
               
    <div>
 
    </div>
                </Box>
              </Grid>
          </Grid>
      <Outlet/>
        </div>
      </AuthProvider>

  )
}

export default BuscadorCandidatos