import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import CssBaseline from '@mui/material/CssBaseline';

import { Container } from '@mui/system'
import { Typography } from '@mui/material';
import SignInSide from '../components/Signin';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import StarIcon from '@mui/icons-material/Star';
import { Hidden } from '@mui/material';
import { makeStyles } from '@mui/material';
import { blue } from '@mui/material/colors';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { CardActionArea } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import TrabajoDataService from '../services/trabajo.services';
import { useUserAuth } from "../context/UserAuthContext";
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import FavoriteIcon from '@mui/icons-material/Favorite';
import AuthProvider from '../components/authProvider';
import { useNavigate } from 'react-router';
import { getPerfil, insertNewPerfil, updatePerfil } from '../firebase-config';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import getPaymentsByUID from '../components/getPaymentsByUID';
import Footer from '../components/Footer';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';
import { getNota } from '../firebase-config';
import Image from 'mui-image';
import Fab from '@mui/material/Fab';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Welcome2 from './Welcome2';

const Welcome = ( {getTrabajoId} ) => {
  
  const [trabajos, setTrabajos] = useState([]);
  useEffect(() => {
  getTrabajos();
}, [])

const getTrabajos = async () => {
  const data = await TrabajoDataService.getAllTrabajos();
  setTrabajos(data.docs.map((doc) => ({...doc.data(), id: doc.id})))
}

  const areas = ['Fiscal', 'Procesal', "Compliance"];
  const experiencias = ["1 a 4 años", "5 a 8 años"];
  const ciudades = ["Madrid", "Barcelona", "Sevilla"];
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [experiencia, setExperiencia] = React.useState(null);
  const [inputExperiencia, setInputExperiencia] = React.useState('');
  const [ciudad, setCiudad] = React.useState(null);
  const [inputCiudad, setInputCiudad] = React.useState('');
  const [area, setArea] = React.useState(null);
  const [inputArea, setInputArea] = React.useState('');
  let {user} = useUserAuth();
  const [experiencia1, setExperiencia1] = useState([0, 20]);
  const [currentUser, setCurrentUser] = useState({});
  const [perfiles, setPerfiles] = useState([ ]);
  const [nombre, setNombre] = useState("");
  const [payments, setPayments] = useState([]);
  const [payment1, setPayment1] = useState([]);
  const [payment2, setPayment2] = useState([]);
  const [isPremium, setIsPremium] = useState(false);
  const [isOldPremium, setIsOldPremium] = useState(false);
  const [nota, setNota] = useState([ ]);

  const handleChangeExperiencia = (event, newExperiencia) => {
    setExperiencia1(newExperiencia);
  };

  const navigate = useNavigate();

  async function handleUserLoggedIn(user) {
    setCurrentUser(user);
    console.log("Estás registrado");
    console.log(user.displayName);
    const resPerfil = await getPerfil(user.uid);
      setPerfiles([...resPerfil]);
      const payments = await getPaymentsByUID(user.uid);
      setPayments([...payments])
      console.log(perfiles);
      console.log()
      setIsOldPremium(user.premiumUser)
      const notaUsuario = await getNota(user.uid);
      setNota([...notaUsuario])
      console.log(nota);

      console.log(perfiles.nombre);
      payments.map((payment) => {
        payment1.push(payment.status)
        payment2.push(payment.current_period_end)
    
      })
    
    console.log(payment1)
    const premium = (element) => (element === "active" || element === "trialing")
    setIsPremium(payment1.some(premium))
    console.log(isPremium)
   }

   useEffect(() => {
    async function getPayments() {
      if (!currentUser) return;
      console.log("user por usar", currentUser);
      const payments = await getPaymentsByUID(currentUser);
      setPayments(payments);
    }
    getPayments();
    console.log(payments)
    
  }, []);

   function handleUserNotRegistered(user) {
    

   }
   
   function handleUserNotLoggedIn() {
    
   }

   

   const [open, setOpen] = React.useState(true);

  


  
 
  return (


    <AuthProvider 
    onUserLoggedIn={handleUserLoggedIn}
    onUserNotRegistered={handleUserNotRegistered}
    onUserNotLoggedIn={handleUserNotLoggedIn}
    > 
    <CssBaseline>

      
      <Box sx={{display: { xl: 'block', l: "block", m: "none", s:"none", xs: 'none'}}} >
   
      <Grid container component="main" sx={{ height: '100vh'}}>

      <Grid item xs={12} sm={12} md={12} component={Paper} elevation={0} square sx={{paddingTop: "10px",  bgcolor: '#367bb3', backgroundImage: 'url(https://firebasestorage.googleapis.com/v0/b/jobsandlaw-5e0ba.appspot.com/o/images%2FDise%C3%B1o%20sin%20t%C3%ADtulo.png?alt=media&token=232398ba-9873-4c9b-a61e-cd0e280c2be5)',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat' }}>
       
      
        
             
              <Dialog
        open={open}
        
        sx={{
       
          display: {
            xl: "block",
            l: "block",
            m: "none",
            s: "none",
            xs: "none",
          },
        }}>
          <DialogContent sx={{bgcolor: "whitesmoke"}}>

<div>
        <Box
    
          sx={{
       
            display: {
              xl: "block",
              l: "block",
              m: "none",
              s: "none",
              xs: "none",
            },
          }}
        >
          <Stack
            display="flex"
            justifyContent="center"
            sx={{
              alignContent: "center",
              alignItems: "center",
              justifyContent: "center",
            }}
          >

        <Stack sx={{paddingTop: "20px", paddingBottom: "20px" }} spacing={2}>
        

         <Stack direction="column" sx={{alignContent: "center", alignItems: "center", paddingTop: "10px"}}>
           <Stack direction="column" sx={{alignContent: "center", alignItems: "center", paddingTop: "10px"}}>
           <Image src="https://firebasestorage.googleapis.com/v0/b/jobsandlaw-5e0ba.appspot.com/o/images%2Flogo.png?alt=media&token=61cba90f-7cc3-4dc7-a3cd-4d7460febd3b&_gl=1*1gel8n4*_ga*NDY4NjMyMzY1LjE2ODE1NTA0MjM.*_ga_CW55HF8NVT*MTY4NjM5MDY2MC4yNi4xLjE2ODYzOTA3MzUuMC4wLjA." style={{width: "120px"}} />
           <Typography variant="h5" component="div" color = "rgba(43, 117, 177, 0.9)" sx={{fontWeight: "bold", textAlign: "center", marginTop: "20px", marginBottom: "10px"}} >
           Bienvenido al portal de empleo para abogados líder en España
         </Typography>
         
         <Typography variant="h6" component="div" color = "rgba(43, 117, 177, 0.9)" sx={{fontWeight: "bold", textAlign: "center", marginBottom: "0px", paddingTop: "30px"}} >
           En Jobs&Law encontrarás <u>todas</u> las vacantes de calidad para abogados abiertas en el mercado. Nunca más volverás a perder una oportunidad simplemente por no haberte enterado.
         </Typography>
         
         
         </Stack>
         
         <Button
                href="/welcome2" 
                fullWidth
                variant="contained"
                sx={{width: "200px", mt: 3, mb: 2, backgroundColor: "rgba(43, 117, 177, 0.9)", color: "white", marginTop: "50px"}}

              >
                Siguiente
              </Button>
         
         </Stack>
         

   
        
                 </Stack> 
                 </Stack>
                 </Box>



                
                 </div>
                 </DialogContent>
</Dialog>


<Dialog
        open={open}
        
        sx={{
       
          display: {
            xl: "none",
            l: "none",
            m: "block",
            s: "block",
            xs: "block",
          },
        }}>
          <DialogContent sx={{bgcolor: "whitesmoke"}}>

<div>
        <Box
    
          sx={{
       
            display: {
              xl: "none",
              l: "none",
              m: "block",
              s: "block",
              xs: "block",
            },
          }}
        >
          <Stack
            display="flex"
            justifyContent="center"
            sx={{
              alignContent: "center",
              alignItems: "center",
              justifyContent: "center",
            }}
          >

        <Stack sx={{paddingTop: "10px", paddingBottom: "10px" }} spacing={2}>
        

         <Stack direction="column" sx={{alignContent: "center", alignItems: "center", paddingTop: "10px"}}>
           <Stack direction="column" sx={{alignContent: "center", alignItems: "center", paddingTop: "10px"}}>
           <Image src="https://firebasestorage.googleapis.com/v0/b/jobsandlaw-5e0ba.appspot.com/o/images%2Flogo.png?alt=media&token=61cba90f-7cc3-4dc7-a3cd-4d7460febd3b&_gl=1*1gel8n4*_ga*NDY4NjMyMzY1LjE2ODE1NTA0MjM.*_ga_CW55HF8NVT*MTY4NjM5MDY2MC4yNi4xLjE2ODYzOTA3MzUuMC4wLjA." style={{width: "120px"}} />
           <Typography variant="h6" component="div" color = "rgba(43, 117, 177, 0.9)" sx={{fontWeight: "bold", textAlign: "center", marginTop: "20px", marginBottom: "10px"}} >
           Bienvenido al portal de empleo para abogados líder en España
         </Typography>
         
         <Typography component="div" color = "rgba(43, 117, 177, 0.9)" sx={{fontWeight: "bold", textAlign: "center", marginBottom: "0px", paddingTop: "30px", fontSize: "16px"}} >
           En Jobs&Law encontrarás <u>todas</u> las vacantes de calidad para abogados abiertas en el mercado. Nunca más volverás a perder una oportunidad simplemente por no haberte enterado.
         </Typography>
         
         
         </Stack>
         
         <Button
                href="/welcome2" 
                fullWidth
                variant="contained"
                sx={{width: "200px", mt: 3, mb: 2, backgroundColor: "rgba(43, 117, 177, 0.9)", color: "white", marginTop: "50px"}}

              >
                Siguiente
              </Button>
         
         </Stack>
         

   
        
                 </Stack> 
                 </Stack>
                 </Box>



                
                 </div>
                 </DialogContent>
</Dialog>
    
        
      </Grid>
   
 
     </Grid>

     

     

         
         






    
     </Box>



     

   

    </CssBaseline>
    </AuthProvider>
  )
}

export default Welcome
