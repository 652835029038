import { loadStripe } from '@stripe/stripe-js';

let stripePromise;
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe("pk_live_51MYA5HEkjW0mzz5HLqDY7Yhkt2IcUN12uUFUEIcLAyjizu1n34pgQfhbU5G1et0khrlS8Bh82GDn4DaihhUOP9Dh00bVMkHLrX");
  }
  return stripePromise;
};

export default getStripe;