import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import StarIcon from '@mui/icons-material/Star';
import { Hidden } from '@mui/material';
import { makeStyles } from '@mui/material';
import { blue } from '@mui/material/colors';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import { useState } from 'react';
import { useUserAuth } from '../context/UserAuthContext';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { useNavigate } from 'react-router';
import GoogleIcon from '@mui/icons-material/Google';
import { onAuthStateChanged } from 'firebase/auth';
import { useEffect } from 'react';
import { auth, userExists } from '../firebase-config';
import AuthProvider from './authProvider';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Card from '@mui/material/Card';

function OnlyRegister() {
  
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const {logIn, googleSignIn} = useUserAuth();
    const [error, setError] = useState("");
    const navigate = useNavigate();
    const [currentUser, setCurrentUser] = useState(null);
    const [currentState, setCurrentState] = useState({});
    const {signUp, resetPassword} = useUserAuth();
    const [checked, setChecked] = useState(false)
    const [acepta, setAcepta] = useState(false);


    
    /* 0: iniciando 
      2: login complete
      3 : login pero no registrado */ 
  
    const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    if (checked) {

    
    try {
        await signUp(email, password);
        navigate("/dashboard");
    } catch (err) {
        setError(err.message);
    }
  } else {
    setAcepta(true)
    console.log(acepta)
  }
  };

    const handleGoogleSignIn = async (e) => {
      e.preventDefault();
      try{
        const res = await googleSignIn();
        navigate("/dashboard");
        console.log(res)
      } catch (err) {
        setError(err.message);
      }
    }

    /*useEffect (() => {
      setCurrentState(1);
      onAuthStateChanged(auth, async (user) => {
      if(user){
        console.log(currentState)
        const isRegistered = await userExists(user.uid);
        if (isRegistered) {
          setCurrentState(2);
          console.log("cree que 2")
          navigate("/alljobs");
        } else {
          setCurrentState(3);
          console.log("cree que 3")
          navigate("/dashboard");
        }
       }  
        else{
        setCurrentState(4);
        console.log("No hay nadie autenticado");
      }
      
   
    });
    } , []);*/

   function handleUserLoggedIn(user) {
    console.log("Estás registrado");
    console.log(user.displayName);
    
   }

   function handleUserNotRegistered(user) {
    console.log("No estás registrado, pero te está registrando");
    setCurrentUser(user);
    console.log(user.displayName);
    const tmp = {...currentUser};
    tmp.processCompleted = true;
    tmp.email = email; 

   }
   
   function handleUserNotLoggedIn() {
    console.log("No estás logeado");
   }

   const handleResetPassword = async () => {
    if (!email) return setError("Por favor introduce tu e-mail");

    try {
      await resetPassword(email)
      setError("te hemos enviado")
    } catch (err) {
      setError(err.message)
    }
    
   }

   function handleChangeAcepta(event) {
    setChecked(event.target.checked);
    console.log(checked);
   }

   return (
    <AuthProvider 
    onUserLoggedIn={handleUserLoggedIn}
    onUserNotRegistered={handleUserNotRegistered}
    onUserNotLoggedIn={handleUserNotLoggedIn}
    >
    <CssBaseline>     
 <Box sx={{display: { xl: 'block', l: "block", m: "none", s:"none", xs: 'none' }}}> 
 
 
       <Box component="form" noValidate onSubmit={handleSubmit} sx={{mt: 1, color: "white"}}>
       <Card
       sx={{
         display: 'flex',
         flexDirection: 'column',
         justifyContent: "center",
         alignItems: "center",
        marginTop: "60px", marginBottom: "60px", borderRadius: "25px", padding: "20px", 
        bgcolor: "#f0f4f5",
        opacity: "0.8",
         backgroundSize: 'cover',
         backgroundPosition: 'center',
         boxShadow: 2,
       }}
     >
       <Typography component="h4" variant="p" sx={{color: "rgba(43, 117, 177, 0.9)", textAlign: "center", paddingTop: "10px"}}>
         Hoy hay más de 200 vacantes aceptando candidaturas en Jobs&Law
       </Typography>
       <Typography component="h1" variant="h6" sx={{color: "rgba(43, 117, 177, 0.9)", textAlign: "center", paddingTop: "10px"}}>
         Regístrate gratis
       </Typography>
       <Divider sx={{paddingTop: "20px"}}>
       <Chip label="Con tus redes sociales" sx={{color: "white", bgcolor: "rgba(43, 117, 177, 0.9)", opacity: "0.7"}} />
       </Divider>
       {error &&     <Stack sx={{ width: '100%' }} spacing={2}>
       <Alert severity="error">Datos incorrectos</Alert>
       </Stack>} 
       {acepta &&     <Stack sx={{ width: '100%' }} spacing={2}>
       <Alert severity="error">Debes aceptar las condiciones de uso</Alert>
       </Stack>} 
         <Stack sx={{ width: '100%', paddingTop: "10px" }} spacing={2}>
         <Button variant="contained" startIcon={<GoogleIcon />} onClick={handleGoogleSignIn} sx={{backgroundColor: "#4285F4"}} >Continuar con Google</Button>
         </Stack>
         <Divider sx={{paddingTop: "20px"}}>
       <Chip label="O con tu correo electrónico" sx={{color: "white", bgcolor: "rgba(43, 117, 177, 0.9)", opacity: "0.7"}} />
       </Divider>
         <TextField   
           sx={{input: {color: "black", backgroundColor: "whitesmoke", borderRadius: "5px"}, border: "green", color: "green"}}
           margin="normal"
           required
           fullWidth
           id="email"
           label="E-mail"
           color="info"
           name="email"
           autoComplete="email"
           autoFocus
           onChange={(e) => setEmail(e.target.value)}
         />
         <TextField
           sx={{input: {color: "black", backgroundColor: "whitesmoke", borderRadius: "5px"}, border: {color: "green"}}}
           margin="normal"
           required
           fullWidth
           name="password"
           label="Contraseña"
           type="password"
           id="password"
           autoComplete="current-password"
           onChange={(e) => setPassword(e.target.value)}
         />
        <Stack direction="row" spacing={0}>
              <FormControl
   required
   error={error}
   component="fieldset"
   sx={{  }}
   variant="standard"
 >
   <FormGroup>
     
     <FormControlLabel
       control={
         <Checkbox 
         checked={checked}
   
         color="default" onChange={handleChangeAcepta} />
       }

       
     />
     
   </FormGroup>
 
 </FormControl>
 <Typography variant= "caption" sx={{margingLeft: "-80px", paddingTop: "11px", color: "rgba(43, 117, 177, 0.9)"}}>Acepto las <Link href="/avisolegal" sx={{color: "rgba(43, 117, 177, 0.9)", textDecoration: "underline"}}>Condiciones de Uso</Link> y <Link href="/avisolegal" sx={{color: "rgba(43, 117, 177, 0.9)", textDecoration: "underline"}}>la Política de Privacidad</Link></Typography>
 </Stack>
         <Button
           type="submit"
           fullWidth
           variant="contained"
           sx={{ mt: 3, mb: 2, backgroundColor: "rgba(43, 117, 177, 0.9)", color: "white"}}

         >
           Registrarse
         </Button>
         <Grid container>
           <Grid item xs>
          
           </Grid>
           <Grid item>
             <Link href="https://jobsandlaw.com/onlysignin" variant="body2" sx={{color: "rgba(43, 117, 177, 0.9)"}}>
               {"¿Ya estás registrado? Inicia sesión"}
             </Link>
           </Grid>
         </Grid>
         </Card>
       </Box>

  
 </Box> 

 <Box sx={{display: { xl: 'none', l: "none", m: "inline", s:"inline", xs: 'inline' }}}> 
 
 
 <Box component="form" noValidate onSubmit={handleSubmit} sx={{mt: 1, color: "white"}}>
 <Card
 sx={{
   display: 'flex',
   flexDirection: 'column',
   justifyContent: "center",
   alignItems: "center",
  marginTop: "60px", marginBottom: "60px", borderRadius: "25px", padding: "20px", 
  bgcolor: "#f0f4f5",
  opacity: "0.8",
   backgroundSize: 'cover',
   backgroundPosition: 'center',
   boxShadow: 2,
 }}
>
 <Typography component="h4" variant="p" sx={{color: "rgba(43, 117, 177, 0.9)", textAlign: "center", paddingTop: "10px"}}>
   Hoy hay más de 200 vacantes aceptando candidaturas en Jobs&Law
 </Typography>
 <Typography component="h1" variant="h6" sx={{color: "rgba(43, 117, 177, 0.9)", textAlign: "center", paddingTop: "10px"}}>
   Regístrate gratis
 </Typography>
 <Divider sx={{paddingTop: "20px"}}>
 <Chip label="Con tus redes sociales" sx={{color: "white", bgcolor: "rgba(43, 117, 177, 0.9)", opacity: "0.7"}} />
 </Divider>
 {error &&     <Stack sx={{ width: '100%' }} spacing={2}>
 <Alert severity="error">Datos incorrectos</Alert>
 </Stack>} 
 {acepta &&     <Stack sx={{ width: '100%' }} spacing={2}>
 <Alert severity="error">Debes aceptar las condiciones de uso</Alert>
 </Stack>} 
   <Stack sx={{ width: '100%', paddingTop: "10px" }} spacing={2}>
   <Button variant="contained" startIcon={<GoogleIcon />} onClick={handleGoogleSignIn} sx={{backgroundColor: "#4285F4"}} >Continuar con Google</Button>
   </Stack>
   <Divider sx={{paddingTop: "20px"}}>
 <Chip label="O con tu correo electrónico" sx={{color: "white", bgcolor: "rgba(43, 117, 177, 0.9)", opacity: "0.7"}} />
 </Divider>
   <TextField   
     sx={{input: {color: "black", backgroundColor: "whitesmoke", borderRadius: "5px"}, border: "green", color: "green"}}
     margin="normal"
     required
     fullWidth
     id="email"
     label="E-mail"
     color="info"
     name="email"
     autoComplete="email"
     autoFocus
     onChange={(e) => setEmail(e.target.value)}
   />
   <TextField
     sx={{input: {color: "black", backgroundColor: "whitesmoke", borderRadius: "5px"}, border: {color: "green"}}}
     margin="normal"
     required
     fullWidth
     name="password"
     label="Contraseña"
     type="password"
     id="password"
     autoComplete="current-password"
     onChange={(e) => setPassword(e.target.value)}
   />
  <Stack direction="row" spacing={0}>
        <FormControl
required
error={error}
component="fieldset"
sx={{  }}
variant="standard"
>
<FormGroup>

<FormControlLabel
 control={
   <Checkbox 
   checked={checked}

   color="default" onChange={handleChangeAcepta} />
 }

 
/>

</FormGroup>

</FormControl>
<Typography variant= "caption" sx={{margingLeft: "-80px", paddingTop: "11px", color: "rgba(43, 117, 177, 0.9)"}}>Acepto las <Link href="/avisolegal" sx={{color: "rgba(43, 117, 177, 0.9)", textDecoration: "underline"}}>Condiciones de Uso</Link> y <Link href="/avisolegal" sx={{color: "rgba(43, 117, 177, 0.9)", textDecoration: "underline"}}>la Política de Privacidad</Link></Typography>
</Stack>
   <Button
     type="submit"
     fullWidth
     variant="contained"
     sx={{ mt: 3, mb: 2, backgroundColor: "rgba(43, 117, 177, 0.9)", color: "white"}}

   >
     Registrarse
   </Button>
   <Grid container>
     <Grid item xs>
    
     </Grid>
     <Grid item>
       <Link href="https://jobsandlaw.com/onlysignin" variant="body2" sx={{color: "rgba(43, 117, 177, 0.9)"}}>
         {"¿Ya estás registrado? Inicia sesión"}
       </Link>
     </Grid>
   </Grid>
   </Card>
 </Box>
 </Box> 
</CssBaseline>
</AuthProvider>   
);
}

export default OnlyRegister


