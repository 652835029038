import React, { useState } from "react";
import { Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import ListItemIcon from "@mui/material/ListItemIcon";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/material/styles";
import getStripe from "../lib/getStripe";
import { loadStripe } from "@stripe/stripe-js";
import AuthProvider from "../components/authProvider";
import { useNavigate } from "react-router";
import { getPerfil, insertNewPerfil, updatePerfil, db} from "../firebase-config";
import { doc, addDoc, onSnapshot, collection } from 'firebase/firestore';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import SendIcon from '@mui/icons-material/Send';
import { LoadingButton } from "@mui/lab";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { CardActionArea } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import Image from 'mui-image';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Outlet, Link } from "react-router-dom";
import ListItemButton from '@mui/material/ListItemButton';

import InboxIcon from '@mui/icons-material/Inbox';
import DraftsIcon from '@mui/icons-material/Drafts';

function PruebaGratis() {
  const [currentUser, setCurrentUser] = useState({});
  const [perfiles, setPerfiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingAnual, setLoadingAnual] = useState(false);
  const [loadingMensual, setLoadingMensual] = useState(false);

  const navigate = useNavigate();

  async function stripePaymentHandler(priceId, uid){
    setLoading(true)
    const stripe = await getStripe();
    
      const docRef = await addDoc(collection(db, 'customers',uid, 'checkout_sessions'), {
        price: priceId,
        success_url: window.location.origin,
        cancel_url: window.location.origin,
      });
      
      onSnapshot(doc(db, 'customers',uid, 'checkout_sessions', docRef.id), (snap) => {
        const { error, sessionId } = snap.data();
      
        if (error) {
          console.log(error)
          setLoading(false);
          alert('An error occurred', error.message);
        }
      
        if (sessionId) {
          stripe.redirectToCheckout({ sessionId });
          setLoading(false)
        }
      });
  }

  async function handleUserLoggedIn(user) {
    setCurrentUser(user);
    console.log("Estás registrado");
    console.log(user.displayName);
    const resPerfil = await getPerfil(user.uid);
    setPerfiles([...resPerfil]);
    console.log(perfiles.nombre);
    console.log(perfiles);
  }

  function handleUserNotRegistered(user) {
    navigate("../signin");
  }

  function handleUserNotLoggedIn() {
    navigate("../signin");
  }

  // Monthly subscription handler and priceId
    function handleCheckout() {
    stripePaymentHandler('price_1Mtp2zEkjW0mzz5H3Pqj57zi', currentUser.uid);
    setLoadingMensual(true);
  }

  // Annual subscription handler and PriceId
   function handleCheckoutAnual() {
    stripePaymentHandler('price_1Mtp2PEkjW0mzz5HSLY61NyO', currentUser.uid);
    setLoadingAnual(true);
  }

    // Annual subscription handler and PriceId
    function handleCheckoutFreeTrial() {
      stripePaymentHandler('price_1N9mQHEkjW0mzz5HONDxAI8I', currentUser.uid);
      setLoadingAnual(true);
    }
  
  return (
    <AuthProvider
      onUserLoggedIn={handleUserLoggedIn}
      onUserNotRegistered={handleUserNotRegistered}
      onUserNotLoggedIn={handleUserNotLoggedIn}
    >
      <div>
        <Box
          height="100vh"
          sx={{
            paddingTop: "5%",
            display: {
              xl: "block",
              l: "block",
              m: "none",
              s: "none",
              xs: "none",
            },
          }}
        >
          <Stack
            display="flex"
            justifyContent="center"
            sx={{
              alignContent: "center",
              alignItems: "center",
              justifyContent: "center",
            }}
          >

        <Stack sx={{paddingTop: "20px", paddingBottom: "20px" }} spacing={2}>
        
        <Card elevation={10} sx={{ marginTop: "20px", bgcolor: "#1f387d", maxWidth: "800px"}}>
           
       <CardContent>
         <Stack direction="column" sx={{alignContent: "center", alignItems: "center", paddingTop: "10px"}}>
           <Stack direction="column" sx={{alignContent: "center", alignItems: "center", paddingTop: "10px"}}>
           <Image src="https://firebasestorage.googleapis.com/v0/b/jobsandlaw-5e0ba.appspot.com/o/images%2FRankings%20(1).png?alt=media&token=d8a704e7-9d2d-4b38-ac38-ddd13213c1c6" style={{width: "120px"}} />
           <Typography variant="h5" component="div" color = "whitesmoke" sx={{fontWeight: "bold", textAlign: "center", marginBottom: "0px"}} >
           Gracias por crear tu perfil profesional en Jobs&Law.
         </Typography>
         <Typography sx={{ fontSize: 14, textAlign: "center", paddingTop: "10px" }} color="whitesmoke" gutterBottom>
           A partir de ahora podrás recibir ofertas de los principales despachos y empresas de manera discreta y confidencial.
         </Typography>
         <Typography variant="h5" component="div" color = "whitesmoke" sx={{fontWeight: "bold", textAlign: "center", marginBottom: "0px", paddingTop: "30px"}} >
           Ahora, disfruta de tu recompensa...
         </Typography>
         <Typography variant="h5" component="div" color = "whitesmoke" sx={{fontWeight: "bold", textAlign: "center", marginBottom: "0px"}} >
           ¡Accede gratis a Premium durante 30 días!
         </Typography>
         <List sx={{color: "white", padding: "20px", lineHeight: '10px'}}>
          <ListItem disablePadding sx={{lineHeight: '10px'}}>
            <ListItemButton>
              <ListItemIcon>
                <ArrowForwardIosIcon sx={{color: "white"}} />
              </ListItemIcon>
              <ListItemText disableTypography
        primary={<Typography >Acceso a <u>todas</u> las vacantes del mercado</Typography>} sx={{color: "white", lineHeight: 0, margin: 0 }} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <ArrowForwardIosIcon sx={{color: "white"}} />
              </ListItemIcon>
              <ListItemText primary="Información interna sobre los principales despachos" sx={{color: "white"}} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <ArrowForwardIosIcon sx={{color: "white"}} />
              </ListItemIcon>
              <ListItemText primary="Perfil profesional preferente" sx={{color: "white"}} />
            </ListItemButton>
          </ListItem>
        </List>
         </Stack>
         <LoadingButton variant="outlined" sx={{width: "200px", marginTop: "20px", marginBottom: "20px", paddingTop: "10px", paddingLeft: "10px", paddingRight: "10px", paddingBottom: "10px", color: "white", bgcolor: "#1f387d", borderColor: "white", fontSize: "15px", ':hover': {
      bgcolor: 'white', // theme.palette.primary.main
      color: '#1f387d',
    }}}
     onClick={handleCheckoutFreeTrial}
  
     loading={loading}
     loadingIndicator="Cargando…"
     color="inherit"
   >
     <span>Activar prueba gratuita</span>
   </LoadingButton>
         </Stack>
         
            <Link to={"/dashboard"}><Typography sx={{color: "white", textAlign: "center", fontSize: "14px", paddingTop: "10px"}}>
            <u>Prefiero no disfrutar de mi prueba gratuita</u></Typography></Link>
       </CardContent>
       <CardContent>
       <Divider variant="middle" sx={{marginTop: "0px", bgcolor: "white", marginBottom: "20px"}} />
       <Typography sx={{ fontSize: 14, textAlign: "center", paddingLeft: "10px", paddingRight: "10px" }} color="whitesmoke" gutterBottom>
           Con la prueba gratuita tendrás acceso ilimitado a Jobs&Law Premium durante 30 días sin ningún coste. Al final de tu prueba
           gratuita, si no cancelas la renovación, tu suscripción se renovará automáticamente (suscripción anual con precio reducido de 12,95 euros/mes). Podrás cancelar la 
           renovación de tu suscripción en cualquier momento, y de forma muy sencilla, en la sección "Mi cuenta".
         </Typography>
         
         </CardContent>
     </Card>
        
                 </Stack> 
                 </Stack>
                 </Box>



                 <Box
          height="auto"
          sx={{
            display: {
              xl: "none",
              l: "none",
              m: "inline",
              s: "inline",
              xs: "inline",
            },
          }}
        >
                  <Stack
            display="flex"
            justifyContent="center"
            sx={{
              alignContent: "center",
              alignItems: "center",
              justifyContent: "center",
            }}
          >

        <Stack sx={{}} spacing={2}>
        
        <Card sx={{bgcolor: "#1f387d", maxWidth: "800px", height: "auto"}}>
           
       <CardContent>
         <Stack direction="column" sx={{alignContent: "center", alignItems: "center", paddingTop: "10px"}}>
           <Stack direction="column" sx={{alignContent: "center", alignItems: "center", paddingTop: "10px"}}>
           <Image src="https://firebasestorage.googleapis.com/v0/b/jobsandlaw-5e0ba.appspot.com/o/images%2FRankings%20(1).png?alt=media&token=d8a704e7-9d2d-4b38-ac38-ddd13213c1c6" style={{width: "120px"}} />
           <Typography variant="h5" component="div" color = "whitesmoke" sx={{fontWeight: "bold", textAlign: "center", marginBottom: "0px"}} >
           Gracias por crear tu perfil profesional en Jobs&Law.
         </Typography>
         <Typography sx={{ fontSize: 14, textAlign: "center", paddingTop: "10px" }} color="whitesmoke" gutterBottom>
           A partir de ahora podrás recibir ofertas de los principales despachos y empresas de manera discreta y confidencial.
         </Typography>
         <Typography variant="h5" component="div" color = "whitesmoke" sx={{fontWeight: "bold", textAlign: "center", marginBottom: "0px", paddingTop: "30px"}} >
           Ahora, disfruta de tu recompensa...
         </Typography>
         <Typography variant="h5" component="div" color = "whitesmoke" sx={{fontWeight: "bold", textAlign: "center", marginBottom: "0px"}} >
           ¡Accede gratis a Premium durante 30 días!
         </Typography>
         <List sx={{color: "white", padding: "20px", lineHeight: '10px'}}>
          <ListItem disablePadding sx={{lineHeight: '10px'}}>
            <ListItemButton>
              <ListItemIcon>
                <ArrowForwardIosIcon sx={{color: "white"}} />
              </ListItemIcon>
              <ListItemText disableTypography
        primary={<Typography >Acceso a <u>todas</u> las vacantes del mercado</Typography>} sx={{color: "white", lineHeight: 0, margin: 0 }} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <ArrowForwardIosIcon sx={{color: "white"}} />
              </ListItemIcon>
              <ListItemText primary="Información interna sobre los principales despachos" sx={{color: "white"}} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <ArrowForwardIosIcon sx={{color: "white"}} />
              </ListItemIcon>
              <ListItemText primary="Perfil profesional preferente" sx={{color: "white"}} />
            </ListItemButton>
          </ListItem>
        </List>
         </Stack>
         <LoadingButton variant="outlined" sx={{width: "200px", marginTop: "20px", marginBottom: "20px", paddingTop: "10px", paddingLeft: "10px", paddingRight: "10px", paddingBottom: "10px", color: "white", bgcolor: "#1f387d", borderColor: "white", fontSize: "15px", ':hover': {
      bgcolor: 'white', // theme.palette.primary.main
      color: '#1f387d',
    }}}
     onClick={handleCheckoutFreeTrial}
  
     loading={loading}
     loadingIndicator="Cargando…"
     color="inherit"
   >
     <span>Activar prueba gratuita</span>
   </LoadingButton>
         </Stack>
         
            <Link to={"/dashboard"}><Typography sx={{color: "white", textAlign: "center", fontSize: "14px", paddingTop: "10px"}}>
            <u>Prefiero no disfrutar de mi prueba gratuita</u></Typography></Link>
       </CardContent>
       <CardContent>
       <Divider variant="middle" sx={{marginTop: "0px", bgcolor: "white", marginBottom: "20px"}} />
       <Typography sx={{ fontSize: 14, textAlign: "center", paddingLeft: "10px", paddingRight: "10px" }} color="whitesmoke" gutterBottom>
           Con la prueba gratuita tendrás acceso ilimitado a Jobs&Law Premium durante 30 días sin ningún coste. Al final de tu prueba
           gratuita, si no cancelas la renovación, tu suscripción se renovará automáticamente (suscripción anual con precio reducido de 12,95 euros/mes). Podrás cancelar la 
           renovación de tu suscripción en cualquier momento, y de forma muy sencilla, en la sección "Mi cuenta".
         </Typography>
         
         </CardContent>
     </Card>
        
                 </Stack> 
                 </Stack>
                 </Box>
                 </div>


               
          
     
     
    </AuthProvider>
  );
}

export default PruebaGratis;
