import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import CssBaseline from '@mui/material/CssBaseline';

import { Container } from '@mui/system'
import { Typography } from '@mui/material';
import SignInSide from '../components/Signin';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import StarIcon from '@mui/icons-material/Star';
import { Hidden } from '@mui/material';
import { makeStyles } from '@mui/material';
import { blue } from '@mui/material/colors';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { CardActionArea } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import TrabajoDataService from '../services/trabajo.services';
import { useUserAuth } from "../context/UserAuthContext";
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import FavoriteIcon from '@mui/icons-material/Favorite';
import AuthProvider from '../components/authProvider';
import { useNavigate } from 'react-router';
import { getPerfil, insertNewPerfil, updatePerfil } from '../firebase-config';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import getPaymentsByUID from '../components/getPaymentsByUID';
import Footer from '../components/Footer';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';
import { getNota } from '../firebase-config';

const MyJobs = ( {getTrabajoId} ) => {
  
  const [trabajos, setTrabajos] = useState([]);
  useEffect(() => {
  getTrabajos();
}, [])

const getTrabajos = async () => {
  const data = await TrabajoDataService.getAllTrabajos();
  setTrabajos(data.docs.map((doc) => ({...doc.data(), id: doc.id})))
}

  const areas = ['Fiscal', 'Procesal', "Compliance"];
  const experiencias = ["1 a 4 años", "5 a 8 años"];
  const ciudades = ["Madrid", "Barcelona", "Sevilla"];
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [experiencia, setExperiencia] = React.useState(null);
  const [inputExperiencia, setInputExperiencia] = React.useState('');
  const [ciudad, setCiudad] = React.useState(null);
  const [inputCiudad, setInputCiudad] = React.useState('');
  const [area, setArea] = React.useState(null);
  const [inputArea, setInputArea] = React.useState('');
  let {user} = useUserAuth();
  const [experiencia1, setExperiencia1] = useState([0, 20]);
  const [currentUser, setCurrentUser] = useState({});
  const [perfiles, setPerfiles] = useState([ ]);
  const [nombre, setNombre] = useState("");
  const [payments, setPayments] = useState([]);
  const [payment1, setPayment1] = useState([]);
  const [payment2, setPayment2] = useState([]);
  const [isPremium, setIsPremium] = useState(false);
  const [isOldPremium, setIsOldPremium] = useState(false);
  const [nota, setNota] = useState([ ]);
  const [despacho, setDespacho] = useState("");

  const handleChangeExperiencia = (event, newExperiencia) => {
    setExperiencia1(newExperiencia);
  };

  const navigate = useNavigate();

  async function handleUserLoggedIn(user) {
    setCurrentUser(user);
    console.log("Estás registrado");
    console.log(user.displayName);
    console.log(user.uid);
    const resPerfil = await getPerfil(user.uid);
      setPerfiles([...resPerfil]);
      const payments = await getPaymentsByUID(user.uid);
      setPayments([...payments])
      console.log(perfiles);
      console.log()
      setIsOldPremium(user.premiumUser)
      setDespacho(user.despacho)
      const notaUsuario = await getNota(user.uid);
      setNota([...notaUsuario])
      console.log(nota);

      console.log(perfiles.nombre);
      payments.map((payment) => {
        payment1.push(payment.status)
        payment2.push(payment.current_period_end)
    
      })
    
    console.log(payment1)
    const premium = (element) => (element === "active" || element === "trialing")
    setIsPremium(payment1.some(premium))
    console.log(isPremium)
   }

   useEffect(() => {
    async function getPayments() {
      if (!currentUser) return;
      console.log("user por usar", currentUser);
      const payments = await getPaymentsByUID(currentUser);
      setPayments(payments);
    }
    getPayments();
    console.log(payments)
    
  }, []);

   function handleUserNotRegistered(user) {
    navigate("../signin");

   }
   
   function handleUserNotLoggedIn() {
    navigate("../signin");
   }

   



useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [page]);

  const ordered = [...trabajos].sort((a,b) => b.timestamp - a.timestamp);
  const matches = []
  const matchesOpen = []
  const matchesPremium = []
  const totalJobs = ordered.length
  const offersLawFirm = []
  const offersLawFirm2 = []



  const eachjob = ordered.map((doc, index) => {
 
    const numeroAreasPractica = Object.keys(doc.area);

    
    if (doc.visibilidad === false) {
      matchesOpen.push(doc)
    }

    if ((doc.despacho === despacho && doc.visibilidad === false)) {
        offersLawFirm.push(doc)
      }

      if ((doc.despacho === despacho && doc.visibilidad === true)) {
        offersLawFirm2.push(doc)
      }
    
    
    
    if ((ciudad === doc.ciudad || ciudad === null) && 
    ((area === doc.area[0].title || numeroAreasPractica.length > 1 && area === doc.area[1].title || numeroAreasPractica.length > 2 && area === doc.area[2].title || area === null)) &&
    (doc.experiencia[0] >= experiencia1[0] && doc.experiencia[1] <= experiencia1[1]) && doc.visibilidad === false
    
    ) {
      matches.push(doc)
    }


    if ((ciudad === doc.ciudad || ciudad === null) && 
    ((area === doc.area[0].title || numeroAreasPractica.length > 1 && area === doc.area[1].title || numeroAreasPractica.length > 2 && area === doc.area[2].title || area === null)) &&
    (doc.experiencia[0] >= experiencia1[0] && doc.experiencia[1] <= experiencia1[1]) && doc.visibilidad === true
    
    ) {
      matchesPremium.push(doc)
    }
    
  })
  const [page, setPage] = useState(1);
  const itemsPerPage = 15;
  const noOfPages = Math.ceil(matches.length / itemsPerPage);
  const handleChange = (event, value) => {
    setPage(value);
  };
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [page]);
  const numberLimitedJobs = offersLawFirm.length
  const numberLimitedJobs2 = offersLawFirm2.length
  const numberMatchesOpen = matchesOpen.length
  const numberMatchesPremium = matchesPremium.length
  const eachjob2 = offersLawFirm.slice((page - 1) * itemsPerPage, page * itemsPerPage).map((doc, index) => {
 
    const numeroAreasPractica = Object.keys(doc.area);
    const date = new Date();

    return (
        <div>
        
        

        <Box sx={{display: { xl: 'block', l: "block", m: "none", s:"none", xs: 'none' }, marginBottom: "20px"}} >
        <Card variant="outlined" sx={{display: "flex", width: "800px"}}>
       
        <CardMedia
            component="img"
            sx={{maxWidth: "20%", padding:"20px", objectFit: "contain"}}
            image={doc.logoOferta}
            alt="Logo"
          />
        <Box sx={{ display: 'flex', flexDirection: 'row', width: "600px"}}>
        <CardContent sx={{paddingTop:"20px"}}>
        <Typography variant="subtitle1"  color="text.secondary">{doc.despacho}</Typography>
                <Typography variant="h6" color="text.secondary">
                <Link
            style={{ display: "block", textDecoration: "none", color: "#474643"}}
            to={`/Jobs/${doc.id}`}
            target="_blank"
            key={doc.id} onClick ={(e) => getTrabajoId(doc.id) }
          >
            {doc.titulo}


          </Link>
                     
                </Typography>
                <Typography variant="subtitle2" color="text.secondary">
                     {doc.ciudad}
                </Typography>
        <Box sx={{paddingTop: "15px"}}>
        
        <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px"}}>
            {doc.area[0].title}
        </Typography>
        {numeroAreasPractica.length > 1 && <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px", marginLeft: "5px"}}>
            {doc.area[1].title}
        </Typography>}
        {numeroAreasPractica.length > 2 && <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px", marginLeft: "5px"}}>
            {doc.area[2].title}
        </Typography>}
        
        </Box>
        <Box sx={{paddingTop: "8px"}}>
        
        <Typography variant="caption" sx={{backgroundColor: "#03b1fc", borderRadius: "10px", color:"white", padding: "6px"}}>
            {`${doc.experiencia[0]} a ${doc.experiencia[1]} años`}
        </Typography>
       
        </Box>
        </CardContent>
        </Box>
        
        <Box sx={{ display: 'flex', flexDirection: 'column', width: "400px", alignItems: 'flex-end'}}>
            <Box sx={{display: "flex", alignItems: "flex-end"}}>
            </Box>
            <Box sx={{paddingTop: "62px", paddingRight: "15px"}}>

              
       
            {(((doc.timestamp.toDate().getMonth() < date.getMonth()) && (doc.timestamp.toDate().getDate() < date.getDate())) || (doc.timestamp.toDate().getMonth() + 1 < date.getMonth())) &&  
            
            <Typography variant="caption" 
            sx={{border: "1px solid #b32217", 
            borderRadius: "10px", 
            color:"white", 
            bgcolor: "#b32217",
            padding: "6px", 
            marginLeft: "5px"}}>
              Podría no estar ya disponible</Typography>}
       
        </Box>
        </Box> 
 
    </Card>
    </Box>

    


  

    <Box sx={{display: { xl: 'none', l: "none", m: "block", s:"block", xs: 'block', justifyContent: "center", alignItems: "center", marginTop: "20px" }}} >
        <Card variant="outlined" sx={{alignContent: "center", alignItems: "center", width: "300px"}}>
        <CardActionArea component={Link} to={`/Jobs/${doc.id}`} target="_blank">
        <CardMedia
            component="img"
            sx={{paddingLeft: "15px", width: "80px", objectFit: "contain", justifyContent: "center", alignItems: "center"}}
            image={doc.logoOferta}
            alt="Logo"
          />
         <CardContent sx={{paddingTop:"20px", justifyContent: "center", alignItems: "center"}}>
        <Typography variant="subtitle1"  color="text.secondary">{doc.despacho}</Typography>
                <Typography variant="h6" color="text.secondary">
                <Link
            style={{ display: "block", textDecoration: "none", color: "#474643"}}
            to={`/Jobs/${doc.id}`}
            target="_blank"
            key={doc.id} onClick ={(e) => getTrabajoId(doc.id) }
          >
            {doc.titulo}
          </Link>
                     
                </Typography>
                <Typography variant="subtitle2" color="text.secondary">
                     {doc.ciudad}
                </Typography>
        <Box sx={{paddingTop: "15px", display: 'flex', flexDirection: 'row'}}>
        
        <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px"}}>
            {doc.area[0].title}
        </Typography>
        {numeroAreasPractica.length > 1 && <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px", marginLeft: "5px"}}>
            {doc.area[1].title}
        </Typography>}
        </Box>
        {numeroAreasPractica.length > 2 && <Box sx={{paddingTop: "15px", display: 'flex', flexDirection: 'row'}}>
         <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px", marginLeft: "5px"}}>
            {doc.area[2].title}
        </Typography>
        </Box>}
        <Box sx={{paddingTop: "8px"}}>
        
        <Typography variant="caption" sx={{backgroundColor: "#03b1fc", borderRadius: "10px", color:"white", padding: "6px"}}>
            {`${doc.experiencia[0]} a ${doc.experiencia[1]} años`}
        </Typography>

        </Box>
        <Box sx={{paddingTop: "10px"}}>

          
        {(((doc.timestamp.toDate().getMonth() < date.getMonth()) && (doc.timestamp.toDate().getDate() < date.getDate())) || (doc.timestamp.toDate().getMonth() + 1 < date.getMonth())) &&  
            
            <Typography variant="caption" 
            sx={{border: "1px solid #b32217", 
            borderRadius: "10px", 
            color:"white", 
            bgcolor: "#b32217",
            padding: "6px"}}>
              Podría no estar ya disponible</Typography>}
        </Box>
        </CardContent>
        </CardActionArea>
    </Card>
    </Box> 

    

    
    </div>
    ) }
    
  )



  const eachjob4 = offersLawFirm2.slice((page - 1) * itemsPerPage, page * itemsPerPage).map((doc, index) => {
 
    const numeroAreasPractica = Object.keys(doc.area);
    const date = new Date();

    return (
        <div>
        
        

        <Box sx={{display: { xl: 'block', l: "block", m: "none", s:"none", xs: 'none' }, marginBottom: "20px"}} >
        <Card variant="outlined" sx={{display: "flex", width: "800px"}}>
       
        <CardMedia
            component="img"
            sx={{maxWidth: "20%", padding:"20px", objectFit: "contain"}}
            image={doc.logoOferta}
            alt="Logo"
          />
        <Box sx={{ display: 'flex', flexDirection: 'row', width: "600px"}}>
        <CardContent sx={{paddingTop:"20px"}}>
        <Typography variant="subtitle1"  color="text.secondary">{doc.despacho}</Typography>
                <Typography variant="h6" color="text.secondary">
                <Link
            style={{ display: "block", textDecoration: "none", color: "#474643"}}
            to={`/Jobs/${doc.id}`}
            target="_blank"
            key={doc.id} onClick ={(e) => getTrabajoId(doc.id) }
          >
            {doc.titulo}


          </Link>
                     
                </Typography>
                <Typography variant="subtitle2" color="text.secondary">
                     {doc.ciudad}
                </Typography>
        <Box sx={{paddingTop: "15px"}}>
        
        <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px"}}>
            {doc.area[0].title}
        </Typography>
        {numeroAreasPractica.length > 1 && <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px", marginLeft: "5px"}}>
            {doc.area[1].title}
        </Typography>}
        {numeroAreasPractica.length > 2 && <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px", marginLeft: "5px"}}>
            {doc.area[2].title}
        </Typography>}
        
        </Box>
        <Box sx={{paddingTop: "8px"}}>
        
        <Typography variant="caption" sx={{backgroundColor: "#03b1fc", borderRadius: "10px", color:"white", padding: "6px"}}>
            {`${doc.experiencia[0]} a ${doc.experiencia[1]} años`}
        </Typography>
       
        </Box>
        </CardContent>
        </Box>
        
        <Box sx={{ display: 'flex', flexDirection: 'column', width: "400px", alignItems: 'flex-end'}}>
            <Box sx={{display: "flex", alignItems: "flex-end"}}>
            </Box>
            <Box sx={{paddingTop: "30px", paddingRight: "15px"}}>
<Link href={`/Jobs/${doc.id}`} target="_blank" style={{ display: "block", textDecoration: "none"}}>
<Typography variant="caption" 
sx={{border: "1px solid #7290c4", 
borderRadius: "10px", 
color:"white", 
bgcolor: "#7290c4",
padding: "6px", 
marginLeft: "5px"}}>
  Ver oferta</Typography></Link></Box>
  <Box sx={{paddingTop: "10px", paddingRight: "15px"}}>

<Typography variant="caption" 
sx={{border: "1px solid #7290c4", 
borderRadius: "10px", 
color:"white", 
bgcolor: "#7290c4",
padding: "6px", 
marginLeft: "5px"}}>
  Editar</Typography></Box>
  <Box sx={{paddingTop: "10px", paddingRight: "15px"}}>

<Typography variant="caption" 
sx={{border: "1px solid #7290c4", 
borderRadius: "10px", 
color:"white", 
bgcolor: "#7290c4",
padding: "6px", 
marginLeft: "5px"}}>
  Eliminar</Typography></Box>
            <Box sx={{paddingTop: "10px", paddingRight: "15px"}}>
       
            {(((doc.timestamp.toDate().getMonth() < date.getMonth()) && (doc.timestamp.toDate().getDate() < date.getDate())) || (doc.timestamp.toDate().getMonth() + 1 < date.getMonth())) &&  
            
            <Typography variant="caption" 
            sx={{border: "1px solid #b32217", 
            borderRadius: "10px", 
            color:"white", 
            bgcolor: "#b32217",
            padding: "6px", 
            marginLeft: "5px"}}>
              Podría no estar ya disponible</Typography>}
       
        </Box>
        </Box> 
 
    </Card>
    </Box>

    


  

    <Box sx={{display: { xl: 'none', l: "none", m: "block", s:"block", xs: 'block', justifyContent: "center", alignItems: "center", marginTop: "20px" }}} >
        <Card variant="outlined" sx={{alignContent: "center", alignItems: "center", width: "300px"}}>
        <CardActionArea component={Link} to={`/Jobs/${doc.id}`} target="_blank">
        <CardMedia
            component="img"
            sx={{paddingLeft: "15px", width: "80px", objectFit: "contain", justifyContent: "center", alignItems: "center"}}
            image={doc.logoOferta}
            alt="Logo"
          />
         <CardContent sx={{paddingTop:"20px", justifyContent: "center", alignItems: "center"}}>
        <Typography variant="subtitle1"  color="text.secondary">{doc.despacho}</Typography>
                <Typography variant="h6" color="text.secondary">
                <Link
            style={{ display: "block", textDecoration: "none", color: "#474643"}}
            to={`/Jobs/${doc.id}`}
            target="_blank"
            key={doc.id} onClick ={(e) => getTrabajoId(doc.id) }
          >
            {doc.titulo}
          </Link>
                     
                </Typography>
                <Typography variant="subtitle2" color="text.secondary">
                     {doc.ciudad}
                </Typography>
        <Box sx={{paddingTop: "15px", display: 'flex', flexDirection: 'row'}}>
        
        <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px"}}>
            {doc.area[0].title}
        </Typography>
        {numeroAreasPractica.length > 1 && <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px", marginLeft: "5px"}}>
            {doc.area[1].title}
        </Typography>}
        </Box>
        {numeroAreasPractica.length > 2 && <Box sx={{paddingTop: "15px", display: 'flex', flexDirection: 'row'}}>
         <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px", marginLeft: "5px"}}>
            {doc.area[2].title}
        </Typography>
        </Box>}
        <Box sx={{paddingTop: "8px"}}>
        
        <Typography variant="caption" sx={{backgroundColor: "#03b1fc", borderRadius: "10px", color:"white", padding: "6px"}}>
            {`${doc.experiencia[0]} a ${doc.experiencia[1]} años`}
        </Typography>

        </Box>
        <Box sx={{paddingTop: "10px"}}>

          
        {(((doc.timestamp.toDate().getMonth() < date.getMonth()) && (doc.timestamp.toDate().getDate() < date.getDate())) || (doc.timestamp.toDate().getMonth() + 1 < date.getMonth())) &&  
            
            <Typography variant="caption" 
            sx={{border: "1px solid #b32217", 
            borderRadius: "10px", 
            color:"white", 
            bgcolor: "#b32217",
            padding: "6px"}}>
              Podría no estar ya disponible</Typography>}
        </Box>
        </CardContent>
        </CardActionArea>
    </Card>
    </Box> 

    

    
    </div>
    ) }
    
  )



 
  return (


    <AuthProvider 
    onUserLoggedIn={handleUserLoggedIn}
    onUserNotRegistered={handleUserNotRegistered}
    onUserNotLoggedIn={handleUserNotLoggedIn}
    > 
    <CssBaseline>
      <Header />
      
      <Box sx={{display: { xl: 'block', l: "block", m: "none", s:"none", xs: 'none'}}} >
   
      <Grid container component="main" sx={{ minHeight: '100vh'}}>
      <Grid item xs={2} sm={2} md={2} component={Paper} elevation={0} square sx={{paddingTop: "10px",  bgcolor: '#367bb3' }}>
        </Grid>
      <Grid item xs={10} sm={10} md={10} component={Paper} elevation={0} square sx={{paddingTop: "10px",  bgcolor: '#367bb3', backgroundImage: 'url(https://firebasestorage.googleapis.com/v0/b/jobsandlaw-5e0ba.appspot.com/o/images%2FDise%C3%B1o%20sin%20t%C3%ADtulo.png?alt=media&token=232398ba-9873-4c9b-a61e-cd0e280c2be5)',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat' }}>
       
      <Stack sx={{ width: '100%', paddingTop: "20px" }} spacing={2}>
      <Typography variant="h3" 
              sx={{paddingTop: "20px", paddingBottom: "30px", color: "white", textAlign: "flex-start", fontWeight: "bold"}}
              >
              Gestiona tus vacantes
            </Typography>
              </Stack>
        
              
              <Stack sx={{ width: '100%', paddingTop: "0px" }} spacing={2}>
      <Typography variant="h5" 
              sx={{paddingTop: "0px", color: "white", textAlign: "flex-start", fontWeight: "bold"}}
              >
              Vacantes visibles para todos los candidatos
            </Typography>
              </Stack>

    
    <Stack sx={{marginTop: "30px"}}>
    {eachjob2}
    </Stack>    
    {offersLawFirm.length === 0 && <Typography variant="h6" color = "whitesmoke"
          sx={{paddingBottom: "20px", textAlign: "flex-start", fontWeight: "bold"}}
          >Tu despacho no tiene ninguna vacante visible para todos los candidatos
          
        </Typography>}
        {offersLawFirm.length !== 0 &&  <Button variant="outlined" href="/myjobs" size="large" sx={{alignContent: "center", marginTop: "30px"}}>Gestionar mis vacantes</Button>}
         {offersLawFirm.length === 0 &&  <Button variant="outlined" href="/createjoblawfirm" size="large" sx={{alignContent: "center", marginTop: "30px"}}>Abrir una vacante</Button>}



         <Stack sx={{ width: '100%', paddingTop: "0px" }} spacing={2}>
      <Typography variant="h5" 
              sx={{paddingTop: "0px", color: "white", textAlign: "flex-start", fontWeight: "bold"}}
              >
              Vacantes con visibilidad limitada
            </Typography>
              </Stack>

    
    <Stack sx={{marginTop: "30px"}}>
    {eachjob4}
    </Stack>    
    {offersLawFirm2.length === 0 && <Typography variant="h6" color = "whitesmoke"
          sx={{paddingBottom: "20px", textAlign: "flex-start", fontWeight: "bold"}}
          >Tu despacho no tiene ninguna vacante con visibilidad limitada
          
        </Typography>}
        {offersLawFirm2.length !== 0 &&  <Button variant="outlined" href="/myjobs" size="large" sx={{alignContent: "center", marginTop: "30px"}}>Gestionar mis vacantes</Button>}
         {offersLawFirm2.length === 0 &&  <Button variant="outlined" href="/createjoblawfirm" size="large" sx={{alignContent: "center", marginTop: "30px"}}>Abrir una vacante</Button>}
      </Grid>
   
 
     </Grid>



    



 

    
     </Box>

   
<Footer/>
    </CssBaseline>
    </AuthProvider>
  )
}

export default MyJobs