import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import { Link } from '@mui/material';
import { useUserAuth } from "../context/UserAuthContext";
import { useNavigate } from 'react-router';
import Image from 'mui-image';
import Grid from '@mui/material/Grid';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';


const pages = ['Home', 'Jobs', 'SingleJob'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const Footer = ({isAuth}) => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [state, setState] = React.useState({
    top: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
      
          <ListItem  disablePadding>
            <ListItemButton  href="./" >
          
              <ListItemText primary="Home" />
            </ListItemButton>
          </ListItem>
          <ListItem  disablePadding>
            <ListItemButton  href="./alljobs" >
          
              <ListItemText primary="Empleos" />
            </ListItemButton>
          </ListItem>
          <ListItem  disablePadding>
            <ListItemButton  href="./perfilprofesional" >
          
              <ListItemText primary="Perfil Profesional" />
            </ListItemButton>
          </ListItem>
          {user &&<ListItem  disablePadding>
            <ListItemButton  href="./micuentapremium" >
          
              <ListItemText primary="Mi cuenta" />
            </ListItemButton>
          </ListItem> }
      </List>
      <Divider />
      
    </Box>
  );

  let {user, logOut} = useUserAuth();
  const navigate = useNavigate();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleClickMenu = () => {

  };

  const handleLogOut = async () => {
    try {
      await logOut();
      navigate("/Jobs");
  } catch (err) {
    console.log(err.message);
  }  
  }

  return (
    <AppBar position="static" style={{ background: 'white'}} sx={{ top: 'auto', bottom: 0}}>

        

  
      <Stack   direction="row"
  justifyContent="center"
  alignItems="center"
  spacing={2}
  sx={{display: { xl: 'none', l: "none", m: "block", s:"block", xs: 'block' }}}
            >
               
      <Stack   direction="row"
  justifyContent="center"
  alignItems="center"
  spacing={2}
  sx={{color: "#004AAD"}}
            >
      <Button
                onClick={handleCloseNavMenu}
                href="./avisolegal"
                sx={{ my: 2, color: '#004AAD', display: 'block'  }}
                size= "small"
              >
                Aviso Legal
              </Button>
    
              <Button
                onClick={handleCloseNavMenu}
                href="./politicadecookies"
                sx={{ my: 2, color: '#004AAD', display: 'block', marginLeft: "5px"  }}
                size= "small"
              >
                Política de Cookies
              </Button>
              </Stack>  
              <Stack   direction="row"
  justifyContent="center"
  alignItems="center"
  spacing={2}
  
            >
      <Typography variant="caption" sx={{color: "#004AAD", paddingBottom: "20px"}}>
        (c) Jobs&Law 2023. Todos los derechos reservados.
      </Typography>
      </Stack>
      </Stack>

      <Box sx={{display: { xl: 'inline', l: "inline", m: "none", s:"none", xs: 'none' }}}>
      <Stack   direction="row"
  justifyContent="center"
  alignItems="center"
  spacing={2}
  
            >
      <Typography variant="caption" sx={{color: "#004AAD"}}>
        (c) Jobs&Law 2023. Todos los derechos reservados.
      </Typography>

 
   
               
    
      <Button
                onClick={handleCloseNavMenu}
                href="./avisolegal"
                sx={{ my: 2, color: '#004AAD', display: 'block'  }}
                size= "small"
              >
                Aviso Legal
              </Button>
    
              <Button
                onClick={handleCloseNavMenu}
                href="./politicadecookies"
                sx={{ my: 2, color: '#004AAD', display: 'block', marginLeft: "5px"  }}
                size= "small"
              >
                Política de Cookies
              </Button>

      </Stack>
      </Box>
    


      

    </AppBar>
  );
};
export default Footer;