import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import CssBaseline from '@mui/material/CssBaseline';

import { Container } from '@mui/system'
import { Typography } from '@mui/material';
import SignInSide from '../components/Signin';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import StarIcon from '@mui/icons-material/Star';
import { Hidden } from '@mui/material';
import { makeStyles } from '@mui/material';
import { blue } from '@mui/material/colors';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { CardActionArea } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import TrabajoDataService from '../services/trabajo.services';
import { useUserAuth } from "../context/UserAuthContext";
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import FavoriteIcon from '@mui/icons-material/Favorite';
import AuthProvider from '../components/authProvider';
import { useNavigate } from 'react-router';
import { getPerfil, insertNewPerfil, updatePerfil } from '../firebase-config';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import getPaymentsByUID from '../components/getPaymentsByUID';
import Footer from '../components/Footer';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';
import { getNota } from '../firebase-config';

const DashboardLawFirms = ( {getTrabajoId} ) => {
  
  const [trabajos, setTrabajos] = useState([]);
  useEffect(() => {
  getTrabajos();
}, [])

const getTrabajos = async () => {
  const data = await TrabajoDataService.getAllTrabajos();
  setTrabajos(data.docs.map((doc) => ({...doc.data(), id: doc.id})))
}

  const areas = ['Fiscal', 'Procesal', "Compliance"];
  const experiencias = ["1 a 4 años", "5 a 8 años"];
  const ciudades = ["Madrid", "Barcelona", "Sevilla"];
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [experiencia, setExperiencia] = React.useState(null);
  const [inputExperiencia, setInputExperiencia] = React.useState('');
  const [ciudad, setCiudad] = React.useState(null);
  const [inputCiudad, setInputCiudad] = React.useState('');
  const [area, setArea] = React.useState(null);
  const [inputArea, setInputArea] = React.useState('');
  let {user} = useUserAuth();
  const [experiencia1, setExperiencia1] = useState([0, 20]);
  const [currentUser, setCurrentUser] = useState({});
  const [perfiles, setPerfiles] = useState([ ]);
  const [nombre, setNombre] = useState("");
  const [payments, setPayments] = useState([]);
  const [payment1, setPayment1] = useState([]);
  const [payment2, setPayment2] = useState([]);
  const [isPremium, setIsPremium] = useState(false);
  const [isOldPremium, setIsOldPremium] = useState(false);
  const [nota, setNota] = useState([ ]);
  const [despacho, setDespacho] = useState("");

  const handleChangeExperiencia = (event, newExperiencia) => {
    setExperiencia1(newExperiencia);
  };

  const navigate = useNavigate();

  async function handleUserLoggedIn(user) {
    setCurrentUser(user);
    console.log("Estás registrado");
    console.log(user.displayName);
    console.log(user.uid);
    const resPerfil = await getPerfil(user.uid);
      setPerfiles([...resPerfil]);
      const payments = await getPaymentsByUID(user.uid);
      setPayments([...payments])
      console.log(perfiles);
      console.log()
      setIsOldPremium(user.premiumUser)
      setDespacho(user.despacho)
      const notaUsuario = await getNota(user.uid);
      setNota([...notaUsuario])
      console.log(nota);

      console.log(perfiles.nombre);
      payments.map((payment) => {
        payment1.push(payment.status)
        payment2.push(payment.current_period_end)
    
      })
    
    console.log(payment1)
    const premium = (element) => (element === "active" || element === "trialing")
    setIsPremium(payment1.some(premium))
    console.log(isPremium)
   }

   useEffect(() => {
    async function getPayments() {
      if (!currentUser) return;
      console.log("user por usar", currentUser);
      const payments = await getPaymentsByUID(currentUser);
      setPayments(payments);
    }
    getPayments();
    console.log(payments)
    
  }, []);

   function handleUserNotRegistered(user) {
    navigate("../signin");

   }
   
   function handleUserNotLoggedIn() {
    navigate("../signin");
   }

   const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "rgba(43, 117, 177, 0.9)",
      color: "whitesmoke",
      fontSize: 16,
      justifyContent: "center"
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      color: "whitesmoke",
      fontWeight: "500",
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: "#98c3e6",
    },
  
      '&:nth-of-type(even)': {
        backgroundColor: "#84b7e1",
      },
    // hide last border
    '&:last-child td, &:last-child th': {

      border: 0,
    },
  }));
  
  function createData(name, basico, premium) {
    return { name, basico, premium };
  }
  
  const rows = [
    createData('Vacantes en despachos', "Limitado", "Completo"),
    createData('Posiciones In-House', "Limitado", "Completo"),
    createData('Vacantes de headhunters', "Limitado", "Completo"),
    createData('Información sobre despachos', "Limitado", "Completo"),
    createData('Perfil profesional', "Normal", "Preferente"),
  ];

  const ordered = [...trabajos].sort((a,b) => b.timestamp - a.timestamp);
  const matches = []
  const matches2 = []
  const matchesRequiereRegistro = []
  const matchesRequierePerfil = []
  const offersLawFirm = []
  const offersLawFirm2 = []
  const totalJobs = ordered.length
  console.log(nota)

  const steps = [
    'Select master blaster campaign settings',
    'Create an ad group',
    'Create an ad',
  ];


  const eachjob = ordered.map((doc, index) => {
 
    const numeroAreasPractica = Object.keys(doc.area);

    if (doc.visibilidad === false
    
    ) {
      matches.push(doc)
      if (doc.despacho === despacho
    
        ) {
          matchesRequiereRegistro.push(doc)
        } 
        
      if (doc.requiereperfil === true
    
          ) {
            matchesRequierePerfil.push(doc)
          }
    } 
    
    if ((doc.despacho === despacho && doc.visibilidad === false)) {
      offersLawFirm.push(doc)
    }

    if ((doc.despacho === despacho && doc.visibilidad === true)) {
      offersLawFirm2.push(doc)
    }

  })

  const numberLimitedJobs = matches.length
  const numberRequiereRegistro = matchesRequiereRegistro.length
  const numberRequierePerfil = matchesRequierePerfil.length
  const numberRegistradoAcceso = numberLimitedJobs - numberRequierePerfil
  console.log(numberRequiereRegistro)

  console.log(despacho)
  console.log(offersLawFirm)

  const selection2 = offersLawFirm.slice(0,3)
  
  const eachjob2 = selection2.map((doc, index) => {
 
    const numeroAreasPractica = Object.keys(doc.area);


    return (
        <div>
        
      
      
      
  
         <Box sx={{justifyContent: "center", alignItems: "center", marginTop: "20px" }} >
        <Card variant="outlined" sx={{alignContent: "center", alignItems: "center", width: "300px"}}>
        <CardActionArea component={RouterLink}  key={doc.id} onClick ={(e) => getTrabajoId(doc.id) } to={`/Jobs/${doc.id}`} target="_blank">
        <CardMedia
            component="img"
            sx={{paddingLeft: "15px", width: "80px", objectFit: "contain", justifyContent: "center", alignItems: "center"}}
            image={doc.logoOferta}
            alt="Logo"
          />
         <CardContent sx={{paddingTop:"20px", justifyContent: "center", alignItems: "center"}}>
        <Typography variant="subtitle1"  color="text.secondary">{doc.despacho}</Typography>
                <Typography variant="h6" color="#2c2e30" sx={{}}>
                 
            {doc.titulo}
      
                     
                </Typography>
                <Typography variant="subtitle2" color="text.secondary">
                     {doc.ciudad}
                </Typography>
        <Box sx={{paddingTop: "15px", display: 'flex', flexDirection: 'row'}}>
        
        <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px"}}>
            {doc.area[0].title}
        </Typography>
        {numeroAreasPractica.length > 1 && <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px", marginLeft: "5px"}}>
            {doc.area[1].title}
        </Typography>}
        </Box>
        {numeroAreasPractica.length > 2 && <Box sx={{paddingTop: "15px", display: 'flex', flexDirection: 'row'}}>
         <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px", marginLeft: "5px"}}>
            {doc.area[2].title}
        </Typography>
        </Box>}
        <Box sx={{paddingTop: "8px"}}>
        
        <Typography variant="caption" sx={{backgroundColor: "#03b1fc", borderRadius: "10px", color:"white", padding: "6px"}}>
            {`${doc.experiencia[0]} a ${doc.experiencia[1]} años`}
        </Typography>
  
        </Box>
        </CardContent>
        </CardActionArea>
    </Card>
    </Box>

    

    
    </div>
    ) }
    
  )



  const eachjob3 = ordered.map((doc, index) => {
 
    const numeroAreasPractica = Object.keys(doc.area);

    if (doc.visibilidad === true
    
    ) {
      matches2.push(doc)
    }

    if ((doc.despacho === despacho && doc.visibilidad === true)) {
      offersLawFirm2.push(doc)
    }
    
  })

  const numberLimitedJobs2 = matches.length
  console.log(matches2)
  const selection = offersLawFirm2.slice(0,3)

  const eachjob4 = selection.map((doc, index) => {
 
    const numeroAreasPractica = Object.keys(doc.area);


    return (
        <div>
        
      


  
        <Box sx={{justifyContent: "center", alignItems: "center", marginTop: "20px" }} >
        <Card variant="outlined" sx={{alignContent: "center", alignItems: "center", width: "300px"}}>
        <CardActionArea component={RouterLink}  key={doc.id} onClick ={(e) => getTrabajoId(doc.id) } to={`/Jobs/${doc.id}`} target="_blank">
        <CardMedia
            component="img"
            sx={{paddingLeft: "15px", width: "80px", objectFit: "contain", justifyContent: "center", alignItems: "center"}}
            image={doc.logoOferta}
            alt="Logo"
          />
         <CardContent sx={{paddingTop:"20px", justifyContent: "center", alignItems: "center"}}>
        <Typography variant="subtitle1"  color="text.secondary">{doc.despacho}</Typography>
                <Typography variant="h6" color="#2c2e30" sx={{}}>
                 
            {doc.titulo}
      
                     
                </Typography>
                <Typography variant="subtitle2" color="text.secondary">
                     {doc.ciudad}
                </Typography>
        <Box sx={{paddingTop: "15px", display: 'flex', flexDirection: 'row'}}>
        
        <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px"}}>
            {doc.area[0].title}
        </Typography>
        {numeroAreasPractica.length > 1 && <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px", marginLeft: "5px"}}>
            {doc.area[1].title}
        </Typography>}
        </Box>
        {numeroAreasPractica.length > 2 && <Box sx={{paddingTop: "15px", display: 'flex', flexDirection: 'row'}}>
         <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px", marginLeft: "5px"}}>
            {doc.area[2].title}
        </Typography>
        </Box>}
        <Box sx={{paddingTop: "8px"}}>
        
        <Typography variant="caption" sx={{backgroundColor: "#03b1fc", borderRadius: "10px", color:"white", padding: "6px"}}>
            {`${doc.experiencia[0]} a ${doc.experiencia[1]} años`}
        </Typography>
  
        </Box>
        </CardContent>
        </CardActionArea>
    </Card>
    </Box>

    

    

    

    
    </div>
    ) }
    
  )


  console.log(despacho)
 
  return (


    <AuthProvider 
    onUserLoggedIn={handleUserLoggedIn}
    onUserNotRegistered={handleUserNotRegistered}
    onUserNotLoggedIn={handleUserNotLoggedIn}
    > 
    <CssBaseline>
      <Header />
      
      <Box sx={{display: { xl: 'block', l: "block", m: "none", s:"none", xs: 'none'}}} >
   
      <Grid container component="main" sx={{ height: '50vh'}}>
      <Grid item xs={2} sm={2} md={2} component={Paper} elevation={0} square sx={{paddingTop: "10px",  bgcolor: '#367bb3' }}>
        </Grid>
      <Grid item xs={10} sm={10} md={10} component={Paper} elevation={0} square sx={{paddingTop: "10px",  bgcolor: '#367bb3', backgroundImage: 'url(https://firebasestorage.googleapis.com/v0/b/jobsandlaw-5e0ba.appspot.com/o/images%2FDise%C3%B1o%20sin%20t%C3%ADtulo.png?alt=media&token=232398ba-9873-4c9b-a61e-cd0e280c2be5)',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat' }}>
       
      <Stack sx={{ width: '100%', paddingTop: "20px" }} spacing={2}>
      <Typography variant="h4" 
              sx={{paddingTop: "10px", paddingBottom: "40px", color: "white", textAlign: "flex-start", fontWeight: "bold"}}
              >
              ¡Hola!
            </Typography>
              </Stack>
        
              <Stack sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
            }}>      
       {(!isPremium && !isOldPremium) && <Card elevation={10} sx={{ marginRight: "20px"}}>
        <CardActionArea component={RouterLink} to={"/createjoblawfirm"} >
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Recibe candidaturas de calidad
        </Typography>
        <Typography variant="h5" component="div" color = "rgba(43, 117, 177, 0.9)" sx={{fontWeight: "bold"}} >
          Publica una vacante
        </Typography>
      </CardContent>
      </CardActionArea>
    </Card>}

    <Card elevation={10} sx={{ marginRight: "20px"}}>
        <CardActionArea component={RouterLink} to={"/createjoblawfirm"} >
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
        Sondea el mercado de forma discreta
        </Typography>
        <Typography variant="h5" component="div" color = "rgba(43, 117, 177, 0.9)" sx={{fontWeight: "bold"}} >
        Vacante anónima
        </Typography>
      </CardContent>
      </CardActionArea>
    </Card>

    <Card elevation={10} sx={{ marginRight: "20px"}}>
        <CardActionArea component={RouterLink} to={"/nuevanota"} >
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Gestiona todas tus vacantes
        </Typography>
        <Typography variant="h5" component="div" color = "rgba(43, 117, 177, 0.9)" sx={{fontWeight: "bold"}} >
          Mis vacantes
        </Typography>
      </CardContent>
      </CardActionArea>
    </Card>




    </Stack>

    <Stack sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              paddingTop: "20px"
            }}>      
 

    <Card elevation={10} sx={{ marginRight: "20px" }}>
        <CardActionArea component={RouterLink} to={"/perfilprofesional"} >
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Encuentra al candidato perfecto 
        </Typography>
        <Typography variant="h5" component="div" color = "rgba(43, 117, 177, 0.9)" sx={{fontWeight: "bold"}} >
          Buscador de candidatos
        </Typography>
      </CardContent>
      </CardActionArea>
    </Card>

    <Card elevation={10} sx={{ marginRight: "20px" }}>
        <CardActionArea component={RouterLink} to={"/rankings"} >
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Deja que nosotros hagamos todo el trabajo
        </Typography>
        <Typography variant="h5" component="div" color = "rgba(43, 117, 177, 0.9)" sx={{fontWeight: "bold"}} >
          Headhunter
        </Typography>
      </CardContent>
      </CardActionArea>
    </Card>

    <Card elevation={10} sx={{ marginRight: "20px" }}>
        <CardActionArea component={RouterLink} to={"/micuentapremium"} >
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Gestiona tu suscripción
        </Typography>
        <Typography variant="h5" component="div" color = "rgba(43, 117, 177, 0.9)" sx={{fontWeight: "bold"}} >
          Mi cuenta
        </Typography>
      </CardContent>
      </CardActionArea>
    </Card>

    </Stack>
        
      </Grid>
   
 
     </Grid>

     

     <Grid container component="main" >
     <Grid item xs={2} sm={2} md={2} component={Paper} elevation={0} square sx={{paddingTop: "10px"}}>
        </Grid>
      <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square >

      
      
                <Stack sx={{ width: '100%', paddingTop: "20px" }} spacing={2}>



<Stack sx={{ width: '100%', paddingTop: "20px" }} spacing={2}>
  
  <Typography variant="h5" color = "rgba(43, 117, 177, 0.9)"
          sx={{paddingTop: "20px", textAlign: "flex-start", fontWeight: "bold"}}
          >Tus últimas vacantes visibles para todos los candidatos:
          
        </Typography>
        
          </Stack>

          {offersLawFirm.length === 0 && <Typography variant="h6" color = "grey"
          sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "flex-start", fontWeight: "bold"}}
          >Tu despacho no tiene ninguna vacante visible para todos los candidatos
          
        </Typography>}
          </Stack>
      
          <Stack direction="row" 
    spacing={2}
    >
              {eachjob2}
          </Stack>
      
          {offersLawFirm.length !== 0 &&  <Button variant="outlined" href="/myjobs" size="large" sx={{alignContent: "center", marginTop: "30px"}}>Gestionar mis vacantes</Button>}
         {offersLawFirm.length === 0 &&  <Button variant="outlined" href="/createjoblawfirm" size="large" sx={{alignContent: "center", marginTop: "30px"}}>Abrir una vacante</Button>}
              <Divider variant="middle" sx={{marginTop: "40px"}} />



           
 
    <Grid item xs={4} sm={4} md={4} component={Paper} elevation={0} square sx={{paddingTop: "10px"}}>
        </Grid>

      
    
               
     
            </Grid>
          </Grid>


         
         <Grid container component="main" >
     <Grid item xs={2} sm={2} md={2} component={Paper} elevation={0} square sx={{paddingTop: "10px"}}>
        </Grid>
      <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square >


      <Stack sx={{ width: '100%', paddingTop: "20px" }} spacing={2}>
        
        <Typography variant="h5" color = "rgba(43, 117, 177, 0.9)"
                sx={{paddingTop: "20px", textAlign: "flex-start", fontWeight: "bold"}}
                >Tus últimas vacantes con visibilidad limitada:
                
              </Typography>
              
                </Stack>
  
                {offersLawFirm2.length === 0 && <Typography variant="h6" color = "grey"
                sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "flex-start", fontWeight: "bold"}}
                >Tu despacho no tiene ninguna vacante con visibilidad limitada
                
              </Typography>}

      <Stack direction="row" 
    spacing={2}
    >
              {eachjob4}
              
              </Stack>
  
     
  
    

         {offersLawFirm2.length !== 0 &&  <Button variant="outlined" href="/myjobs" size="large" sx={{alignContent: "center", marginTop: "30px"}}>Gestionar mis vacantes</Button>}
         {offersLawFirm2.length === 0 &&  <Button variant="outlined" href="/createjoblawfirm" size="large" sx={{alignContent: "center", marginTop: "30px"}}>Abrir una vacante</Button>}

           <Divider variant="middle" sx={{marginTop: "40px"}} />


      <Stack sx={{ width: '100%', paddingTop: "20px" }} spacing={2}>
        
        
        {(!isPremium && !isOldPremium) && <Typography variant="h5" color = "rgba(43, 117, 177, 0.9)"
                sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "flex-start", fontWeight: "bold"}}
                >Mejora tu cuenta para acceder a más ofertas:
                
              </Typography>}
              {(isPremium || isOldPremium) && <Typography variant="h5" color = "rgba(43, 117, 177, 0.9)"
                sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "flex-start", fontWeight: "bold"}}
                >Tu cuenta:
                
              </Typography>}
              
                </Stack>

            <Timeline sx={{
        [`& .${timelineOppositeContentClasses.root}`]: {
          flex: 0.2,
        },
      }}>
             {(perfiles.length === 0 && (!isPremium && !isOldPremium)) && 
       <div>
      <TimelineItem>
       <TimelineOppositeContent sx={{fontSize: "16px", fontWeight: "600"}}>
          Usuario registrado
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot sx={{ bgcolor: 'rgba(43, 117, 177, 0.9)' }}/>
          <TimelineConnector sx={{ bgcolor: 'rgba(43, 117, 177, 0.9)' }}/>
        </TimelineSeparator>
        <TimelineContent sx={{}}>
          <Typography component="span" sx={{fontWeight: "600"}}>
            Tienes acceso a {numberRegistradoAcceso} ofertas, y eres invisible para despachos, empresas y headhunters.
          </Typography>
          <Typography><Button href="./perfilprofesional" sx={{textAlign: "start", fontSize: "16px", fontWeight: "800"}}>Desbloquea {numberLimitedJobs - numberRegistradoAcceso} ofertas adicionales creando tu perfil profesional</Button></Typography>
        </TimelineContent>
      </TimelineItem></div>}

      {(perfiles.length !== 0 && (!isPremium && !isOldPremium)) && 
       <div>
      <TimelineItem>
       <TimelineOppositeContent sx={{fontSize: "16px"}}>
          Usuario registrado
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot variant="outlined" />
          <TimelineConnector sx={{ bgcolor: 'rgba(43, 117, 177, 0.9)' }}/>
        </TimelineSeparator>
        <TimelineContent sx={{}}>
          <Typography component="span">
            Tienes acceso a {numberRegistradoAcceso} ofertas, y eres invisible para despachos, empresas y headhunters.
          </Typography>
          <Typography><Button href="./perfilprofesional" sx={{textAlign: "start"}}>Desbloquea {numberLimitedJobs - numberRegistradoAcceso} ofertas adicionales creando tu perfil profesional</Button></Typography>
        </TimelineContent>
      </TimelineItem></div>}

      
      {(perfiles.length === 0 && (!isPremium && !isOldPremium)) && 
       <div>
      <TimelineItem>
        <TimelineOppositeContent color="text.secondary">
          Candidato
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot variant="outlined" />
          <TimelineConnector sx={{ bgcolor: 'rgba(43, 117, 177, 0.9)' }}/>
        </TimelineSeparator>
        <TimelineContent>Tienes acceso a {numberLimitedJobs} ofertas, y despachos, empresas y headhunters pueden ver tu perfil (si quieres).
        <Typography><Button href="./checkout" sx={{textAlign: "start"}}>Desbloquea {totalJobs - numberLimitedJobs} ofertas adicionales haciéndote Usuario Premium</Button></Typography>
          </TimelineContent>
      </TimelineItem></div>}

      {(perfiles.length !== 0 && (!isPremium && !isOldPremium)) && 
       <div>
      <TimelineItem>
        <TimelineOppositeContent sx={{fontSize: "16px", fontWeight: "600"}}>
          Candidato
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot color="primary" />
          <TimelineConnector sx={{ bgcolor: 'rgba(43, 117, 177, 0.9)' }}/>
        </TimelineSeparator>
        <TimelineContent sx={{fontWeight: "600"}}>Tienes acceso a {numberLimitedJobs} ofertas, y despachos, empresas y headhunters pueden ver tu perfil (si quieres).
        <Typography><Button href="./checkout" sx={{textAlign: "start", fontSize: "16px", fontWeight: "800"}}>Desbloquea {totalJobs - numberLimitedJobs} ofertas adicionales haciéndote Usuario Premium</Button></Typography>
          </TimelineContent>
      </TimelineItem></div>}

      {(!isPremium && !isOldPremium) && 
       <div>
      <TimelineItem>
        <TimelineOppositeContent color="text.secondary">
          Usuario premium
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot variant="outlined" />
          <TimelineConnector sx={{ bgcolor: 'rgba(43, 117, 177, 0.9)' }}/>
        </TimelineSeparator>
        <TimelineContent>Tienes acceso a todas las ofertas que están aceptando candidaturas hoy, 
          y a toda la información interna sobre los despachos. Tu perfil profesional es destacado discretamente 
          cuando despachos, empresas y headhunters buscan perfiles como el tuyo.
          </TimelineContent>
      </TimelineItem></div>}

      {(isPremium || isOldPremium) && 
       <div>
      <TimelineItem>
        <TimelineOppositeContent sx={{fontSize: "16px", fontWeight: "600"}}>
          Usuario premium
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot sx={{ bgcolor: 'rgba(43, 117, 177, 0.9)' }}/>
          <TimelineConnector sx={{ bgcolor: 'rgba(43, 117, 177, 0.9)' }}/>
        </TimelineSeparator>
        <TimelineContent sx={{fontWeight: "600"}}>Tienes acceso a todas las ofertas que están aceptando candidaturas hoy, 
          y a toda la información interna sobre los despachos. Tu perfil profesional es destacado discretamente 
          cuando despachos, empresas y headhunters buscan perfiles como el tuyo.
          </TimelineContent>
      </TimelineItem></div>}

    </Timeline>
 
    <Grid item xs={4} sm={4} md={4} component={Paper} elevation={0} square sx={{paddingTop: "10px"}}>
        </Grid>
      <Divider variant="middle" />
      
      
      
   
    
               
     
            </Grid>
          </Grid> 






     {(!isPremium && !isOldPremium) && <div>
     <Grid container component="main" sx={{ }}>
      <Grid item xs={2} sm={2} md={2} component={Paper} elevation={0} square sx={{}}>
        </Grid>
      <Grid item xs={10} sm={10} md={10} component={Paper} elevation={0} square sx={{}}>
       
      <Stack sx={{ width: '100%', paddingTop: "20px" }} spacing={2}>
      <Typography variant="h5" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "flex-start", fontWeight: "bold"}}
              >Maximiza tus oportunidades y no te pierdas ninguna oferta
              
            </Typography>
            
            <Typography variant="h6" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "0px", paddingBottom: "40px", textAlign: "flex-start", fontWeight: "bold"}}
              >Activa tu cuenta Premium para contar con múltiples ventajas:
            </Typography>
              </Stack>
              <TableContainer sx={{ width: 700 }} component={Paper}>
      <Table sx={{ width: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Ventajas</StyledTableCell>
            <StyledTableCell align="right">Usuario Básico</StyledTableCell>
            <StyledTableCell align="right">Usuario Premium</StyledTableCell>

          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell component="th" scope="row">
                {row.name}
              </StyledTableCell>
              <StyledTableCell align="right">{row.basico}</StyledTableCell>
              <StyledTableCell align="right">{row.premium}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
              <Stack sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              height: '20vh',
            }}>      

<Stack  sx={{ width: '100%', paddingTop: "20px", alignItems: "flex-start"}} spacing={2}>
<Button variant="contained" href="/checkout" size="large" sx={{alignContent: "center", marginTop: "30px"}}>Activar premium</Button>
    </Stack>
    </Stack>
        
      </Grid>
 
     </Grid>

            


     <Divider variant="middle"/>

     <Grid container component="main" sx={{ }}>
      <Grid item xs={2} sm={2} md={2} component={Paper} elevation={0} square sx={{}}>
        </Grid>
      <Grid item xs={8} sm={8} md={8} component={Paper} elevation={0} square sx={{}}>
       
     
        
      </Grid>
      <Grid item xs={2} sm={2} md={2} component={Paper} elevation={0} square sx={{}}>
        </Grid>
 
     </Grid>
         </div>}    
     </Box>



      <Box sx={{display: { xl: 'none', l: "none", m: "block", s:"block", xs: 'block'}}} >
      {perfiles.length === 0 && <Grid container component="main" sx={{ height: '90vh'}}>
      <Grid item xs={1} sm={1} md={2} component={Paper} elevation={0} square sx={{paddingTop: "10px", bgcolor: '#367bb3' }}>
        </Grid>
      <Grid item xs={11} sm={11} md={10} component={Paper} elevation={0} square sx={{paddingTop: "10px", bgcolor: '#367bb3'}}>
       
      <Stack sx={{ width: '100%', paddingTop: "20px"}} spacing={2}>
      <Typography variant="h4" 
              sx={{paddingTop: "10px", paddingBottom: "40px", color: "white", textAlign: "flex-start", fontWeight: "bold"}}
              >
              ¡Hola!
            </Typography>
              </Stack>
        
              <Stack sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              marginBottom: "20px",
            }}>      
       {(!isPremium && !isOldPremium) && <Card elevation={10} sx={{ marginRight: "20px", marginBottom: "20px"}}>
        <CardActionArea component={RouterLink} to={"/limitedjobs"} >
      <CardContent sx={{paddingBottom: "5px"}}>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Accede gratis a una selección de ofertas
        </Typography>
        <Typography variant="h5" component="div" color = "rgba(43, 117, 177, 0.9)" sx={{fontWeight: "bold", marginBottom: "10px"}} >
          Buscador básico
        </Typography>
      </CardContent>
      </CardActionArea>
    </Card>}

    <Card elevation={10} sx={{ marginRight: "20px", marginBottom: "20px", bgcolor: "#1f387d"}}>
        <CardActionArea component={RouterLink} to={"/alljobs"} >
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="whitesmoke" gutterBottom>
          Accede a <u>todas</u> las ofertas del mercado
        </Typography>
        <Typography variant="h5" component="div" color = "whitesmoke" sx={{fontWeight: "bold"}} >
          Buscador premium
        </Typography>
      </CardContent>
      </CardActionArea>
    </Card>

    <Card elevation={10} sx={{ marginRight: "20px", marginBottom: "20px" }}>
        <CardActionArea component={RouterLink} to={"/perfilprofesional"} >
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
        Recibe ofertas con total confidencialidad
        </Typography>
        <Typography variant="h5" component="div" color = "rgba(43, 117, 177, 0.9)" sx={{fontWeight: "bold"}} >
          Perfil profesional
        </Typography>
      </CardContent>
      </CardActionArea>
    </Card>

    <Card elevation={10} sx={{ marginRight: "20px", marginBottom: "20px" }}>
        <CardActionArea component={RouterLink} to={"/rankings"} >
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Toma decisiones informadas
        </Typography>
        <Typography variant="h5" component="div" color = "rgba(43, 117, 177, 0.9)" sx={{fontWeight: "bold"}} >
          Rankings Jobs&Law
        </Typography>
      </CardContent>
      </CardActionArea>
    </Card>

    {perfiles.length !== 0 && <Card elevation={10} sx={{ marginRight: "20px", marginBottom: "20px"}}>
        <CardActionArea component={RouterLink} to={"/nuevanota"} >
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Da tu opinión
        </Typography>
        <Typography variant="h5" component="div" color = "rgba(43, 117, 177, 0.9)" sx={{fontWeight: "bold"}} >
          Califica a tu despacho
        </Typography>
      </CardContent>
      </CardActionArea>
    </Card>}

    </Stack>
        
      </Grid>

 
     </Grid>
          }


{perfiles.length !== 0 && <Grid container component="main" sx={{ height: '110vh'}}>
      <Grid item xs={1} sm={1} md={2} component={Paper} elevation={0} square sx={{paddingTop: "10px", bgcolor: '#367bb3' }}>
        </Grid>
      <Grid item xs={11} sm={11} md={10} component={Paper} elevation={0} square sx={{paddingTop: "10px", bgcolor: '#367bb3'}}>
       
      <Stack sx={{ width: '100%', paddingTop: "20px"}} spacing={2}>
      <Typography variant="h4" 
              sx={{paddingTop: "10px", paddingBottom: "40px", color: "white", textAlign: "flex-start", fontWeight: "bold"}}
              >
              ¡Hola!
            </Typography>
              </Stack>
        
              <Stack sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              
              
            }}>      
       {(!isPremium && !isOldPremium) && <Card elevation={10} sx={{ marginRight: "20px", marginBottom: "20px"}}>
        <CardActionArea component={RouterLink} to={"/limitedjobs"} >
      <CardContent sx={{paddingBottom: "5px"}}>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Accede gratis a una selección de ofertas
        </Typography>
        <Typography variant="h5" component="div" color = "rgba(43, 117, 177, 0.9)" sx={{fontWeight: "bold", marginBottom: "10px"}} >
          Buscador básico
        </Typography>
      </CardContent>
      </CardActionArea>
    </Card>}

    <Card elevation={10} sx={{ marginRight: "20px", marginBottom: "20px", bgcolor: "#1f387d"}}>
        <CardActionArea component={RouterLink} to={"/alljobs"} >
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="whitesmoke" gutterBottom>
          Accede a <u>todas</u> las ofertas del mercado
        </Typography>
        <Typography variant="h5" component="div" color = "whitesmoke" sx={{fontWeight: "bold"}} >
          Buscador premium
        </Typography>
      </CardContent>
      </CardActionArea>
    </Card>

    <Card elevation={10} sx={{ marginRight: "20px", marginBottom: "20px" }}>
        <CardActionArea component={RouterLink} to={"/perfilprofesional"} >
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
        Recibe ofertas con total confidencialidad
        </Typography>
        <Typography variant="h5" component="div" color = "rgba(43, 117, 177, 0.9)" sx={{fontWeight: "bold"}} >
          Perfil profesional
        </Typography>
      </CardContent>
      </CardActionArea>
    </Card>

    <Card elevation={10} sx={{ marginRight: "20px", marginBottom: "20px" }}>
        <CardActionArea component={RouterLink} to={"/rankings"} >
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Toma decisiones informadas
        </Typography>
        <Typography variant="h5" component="div" color = "rgba(43, 117, 177, 0.9)" sx={{fontWeight: "bold"}} >
          Rankings Jobs&Law
        </Typography>
      </CardContent>
      </CardActionArea>
    </Card>

    {perfiles.length !== 0 && <Card elevation={10} sx={{ marginRight: "20px", marginBottom: "20px"}}>
        <CardActionArea component={RouterLink} to={"/nuevanota"} >
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Da tu opinión
        </Typography>
        <Typography variant="h5" component="div" color = "rgba(43, 117, 177, 0.9)" sx={{fontWeight: "bold"}} >
          Califica a tu despacho
        </Typography>
      </CardContent>
      </CardActionArea>
    </Card>}

    </Stack>
        
      </Grid>

 
     </Grid>
          }


      



      <Stack  sx={{ width: '100%', paddingTop: "20px", alignItems: "center", alignContent: "center", paddingLeft: "20px", paddingRight: "20px" }} spacing={2}>
        
        <Typography variant="h5" color = "rgba(43, 117, 177, 0.9)"
                sx={{paddingTop: "20px", textAlign: "center", fontWeight: "bold"}}
                >Últimas vacantes publicadas en el buscador básico:
                
              </Typography>
              <Typography variant="h6" color = "grey"
                sx={{ paddingBottom: "20px", textAlign: "center", fontWeight: "bold"}}
                >{numberLimitedJobs} vacantes aceptando candidaturas hoy
                
              </Typography>
                </Stack>
                
      <Stack direction="column" sx={{alignItems: "center", alignContent: "center" }}
    spacing={2}
    >
              {eachjob4}
              <Button variant="outlined" href="/limitedjobs" size="large" sx={{alignContent: "center", marginTop: "40px"}}>Ir al buscador básico</Button>
              </Stack>

              <Divider variant="middle" sx={{marginTop: "40px"}} />



 


          <Grid container component="main" >

<Grid item xs={12} sm={12} md={12} component={Paper} elevation={0} square >
   
<Stack  sx={{ width: '100%', paddingTop: "20px", alignItems: "center", alignContent: "center", paddingLeft: "20px", paddingRight: "20px" }} spacing={2}>
  
  <Typography variant="h5" color = "rgba(43, 117, 177, 0.9)"
          sx={{paddingTop: "20px", textAlign: "center", fontWeight: "bold"}}
          >Últimas vacantes publicadas en el buscador premium:
          
        </Typography>
        <Typography variant="h6" color = "grey"
          sx={{ paddingBottom: "20px", textAlign: "center", fontWeight: "bold"}}
          >{totalJobs} vacantes aceptando candidaturas hoy
          
        </Typography>
          </Stack>

<Stack direction="column" sx={{alignItems: "center", alignContent: "center" }}
spacing={2}
>
        {eachjob2}
        <Button variant="outlined" href="/alljobs" size="large" sx={{alignContent: "center", marginTop: "40px"}}>Ir al buscador premium</Button>
        </Stack>

        <Divider variant="middle" sx={{marginTop: "40px"}} />



     







         

      </Grid>
    </Grid> 







         
         <Grid container component="main" >

      <Grid item xs={12} sm={12} md={12} component={Paper} elevation={0} square >
  
      

            <Timeline sx={{
        [`& .${timelineOppositeContentClasses.root}`]: {
          flex: 0.2,
        },
      }}>
             {(perfiles.length === 0 && (!isPremium && !isOldPremium)) && 
       <div>
      <TimelineItem>
       <TimelineOppositeContent sx={{fontSize: "16px", fontWeight: "600"}}>
          Usuario registrado
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot sx={{ bgcolor: 'rgba(43, 117, 177, 0.9)' }}/>
          <TimelineConnector sx={{ bgcolor: 'rgba(43, 117, 177, 0.9)' }}/>
        </TimelineSeparator>
        <TimelineContent sx={{}}>
          <Typography component="span" sx={{fontWeight: "600"}}>
            Tienes acceso a {numberRegistradoAcceso} ofertas, y eres invisible para despachos, empresas y headhunters.
          </Typography>
          <Typography><Button href="./perfilprofesional" sx={{textAlign: "start", fontSize: "16px", fontWeight: "800"}}>Desbloquea {numberLimitedJobs - numberRegistradoAcceso} ofertas adicionales creando tu perfil profesional</Button></Typography>
        </TimelineContent>
      </TimelineItem></div>}

      {(perfiles.length !== 0 && (!isPremium && !isOldPremium)) && 
       <div>
      <TimelineItem>
       <TimelineOppositeContent sx={{fontSize: "16px"}}>
          Usuario registrado
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot variant="outlined" />
          <TimelineConnector sx={{ bgcolor: 'rgba(43, 117, 177, 0.9)' }}/>
        </TimelineSeparator>
        <TimelineContent sx={{}}>
          <Typography component="span" >
            Tienes acceso a {numberRegistradoAcceso} ofertas, y eres invisible para despachos, empresas y headhunters.
          </Typography>
          <Typography><Button href="./perfilprofesional" sx={{textAlign: "start"}}>Desbloquea {numberLimitedJobs - numberRegistradoAcceso} ofertas adicionales creando tu perfil profesional</Button></Typography>
        </TimelineContent>
      </TimelineItem></div>}

      
      {(perfiles.length === 0 && (!isPremium && !isOldPremium)) && 
       <div>
      <TimelineItem>
        <TimelineOppositeContent color="text.secondary">
          Candidato
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot variant="outlined" />
          <TimelineConnector sx={{ bgcolor: 'rgba(43, 117, 177, 0.9)' }}/>
        </TimelineSeparator>
        <TimelineContent>Tienes acceso a {numberLimitedJobs} ofertas, y despachos, empresas y headhunters pueden ver tu perfil (si quieres).
        <Typography><Button href="./checkout" sx={{textAlign: "start"}}>Desbloquea {totalJobs - numberLimitedJobs} ofertas adicionales haciéndote Usuario Premium</Button></Typography>
          </TimelineContent>
      </TimelineItem></div>}

      {(perfiles.length !== 0 && (!isPremium && !isOldPremium)) && 
       <div>
      <TimelineItem>
        <TimelineOppositeContent sx={{fontSize: "16px", fontWeight: "600"}}>
          Candidato
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot sx={{ bgcolor: 'rgba(43, 117, 177, 0.9)' }}/>
          <TimelineConnector sx={{ bgcolor: 'rgba(43, 117, 177, 0.9)' }}/>
        </TimelineSeparator>
        <TimelineContent sx={{fontWeight: "600"}}>Tienes acceso a {numberLimitedJobs} ofertas, y despachos, empresas y headhunters pueden ver tu perfil (si quieres).
        <Typography><Button href="./checkout" sx={{textAlign: "start", fontSize: "16px", fontWeight: "800"}}>Desbloquea {totalJobs - numberLimitedJobs} ofertas adicionales haciéndote Usuario Premium</Button></Typography>
          </TimelineContent>
      </TimelineItem></div>}

      {(!isPremium && !isOldPremium) && 
       <div>
      <TimelineItem>
        <TimelineOppositeContent color="text.secondary">
          Usuario premium
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot variant="outlined" />
          <TimelineConnector sx={{ bgcolor: 'rgba(43, 117, 177, 0.9)' }}/>
        </TimelineSeparator>
        <TimelineContent>Tienes acceso a todas las ofertas que están aceptando candidaturas hoy, 
          y a toda la información interna sobre los despachos. Tu perfil profesional es destacado discretamente 
          cuando despachos, empresas y headhunters buscan perfiles como el tuyo.
          
          </TimelineContent>
      </TimelineItem></div>}

      {(isPremium || isOldPremium) && 
       <div>
      <TimelineItem>
        <TimelineOppositeContent sx={{fontSize: "16px", fontWeight: "600"}}>
          Usuario premium
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot sx={{ bgcolor: 'rgba(43, 117, 177, 0.9)' }}/>
          <TimelineConnector sx={{ bgcolor: 'rgba(43, 117, 177, 0.9)' }}/>
        </TimelineSeparator>
        <TimelineContent sx={{fontWeight: "600"}}>Tienes acceso a todas las ofertas que están aceptando candidaturas hoy, 
          y a toda la información interna sobre los despachos. Tu perfil profesional es destacado discretamente 
          cuando despachos, empresas y headhunters buscan perfiles como el tuyo.
          </TimelineContent>
      </TimelineItem></div>}

    </Timeline>
 
  
      <Divider variant="middle" />
      
      
      
   
    
               
     
            </Grid>
          </Grid> 






     {(!isPremium && !isOldPremium) && <div><Stack direction="column"
  justifyContent="center"
  alignItems="center"
  spacing={2} sx={{ paddingTop: "70px", paddingLeft: "10px", paddingRight: "10px", paddingBottom: "100px"}} spacing={2}>
      <Typography variant="h5" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", paddingLeft: "20px", paddingRight: "20px", textAlign: "center", fontWeight: "bold"}}
              >Maximiza tus oportunidades y no te pierdas ninguna oferta
            </Typography>
            <Typography variant="h6" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "0px", paddingBottom: "40px", textAlign: "center", fontWeight: "bold"}}
              >Activa tu cuenta Premium para<br></br> contar con múltiples ventajas:
            </Typography>
              <TableContainer sx={{ maxWidth: 400}} component={Paper}>
      <Table sx={{ maxWidth: 400}} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Ventajas</StyledTableCell>
            <StyledTableCell align="right">Usuario Básico</StyledTableCell>
            <StyledTableCell align="right">Usuario Premium</StyledTableCell>

          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell component="th" scope="row">
                {row.name}
              </StyledTableCell>
              <StyledTableCell align="right">{row.basico}</StyledTableCell>
              <StyledTableCell align="right">{row.premium}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    <Stack sx={{marginTop: "20px", marginBottom: "20px"}}>
    <Button variant="contained" href="/checkout" size="large" sx={{alignContent: "center", marginTop: "30px"}}>Activar premium</Button>
    </Stack>
   </Stack>
   </div>}



 

    
     </Box>

   
<Footer/>
    </CssBaseline>
    </AuthProvider>
  )
}

export default DashboardLawFirms