import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import StarIcon from '@mui/icons-material/Star';
import { Hidden } from '@mui/material';
import { makeStyles } from '@mui/material';
import { blue } from '@mui/material/colors';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import { useState } from 'react';
import { useUserAuth } from '../context/UserAuthContext';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { useNavigate } from 'react-router';
import GoogleIcon from '@mui/icons-material/Google';
import { onAuthStateChanged } from 'firebase/auth';
import { useEffect } from 'react';
import { auth, userExists } from '../firebase-config';
import AuthProvider from '../components/authProvider';

export default function IniciarSesion() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const {logIn, googleSignIn} = useUserAuth();
    const [error, setError] = useState("");
    const navigate = useNavigate();
    const [currentUser, setCurrentUser] = useState(null);
    const [currentState, setCurrentState] = useState({});
    const {signUp} = useUserAuth();
    /* 0: iniciando 
      2: login complete
      3 : login pero no registrado */ 
  
    const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
        await logIn(email, password);
        navigate("/dashboard");
    } catch (err) {
        setError(err.message);
    }

  };

    const handleGoogleSignIn = async (e) => {
      e.preventDefault();
      try{
        const res = await googleSignIn();
        console.log(res)
      } catch (err) {
        setError(err.message);
      }
    }

    /*useEffect (() => {
      setCurrentState(1);
      onAuthStateChanged(auth, async (user) => {
      if(user){
        console.log(currentState)
        const isRegistered = await userExists(user.uid);
        if (isRegistered) {
          setCurrentState(2);
          console.log("cree que 2")
          navigate("/alljobs");
        } else {
          setCurrentState(3);
          console.log("cree que 3")
          navigate("/dashboard");
        }
       }  
        else{
        setCurrentState(4);
        console.log("No hay nadie autenticado");
      }
      
   
    });
    } , []);*/

   function handleUserLoggedIn(user) {
    console.log("Estás registrado");
    console.log(user.displayName);
    navigate("../dashboard");
   }

   function handleUserNotRegistered(user) {
    console.log("No estás registrado, pero te está registrando");
    setCurrentUser(user);
    console.log(user.displayName);
    const tmp = {...currentUser};
    tmp.processCompleted = true;

   }
   
   function handleUserNotLoggedIn() {
    console.log("No estás logeado");
   }

  return (
         <AuthProvider 
         onUserLoggedIn={handleUserLoggedIn}
         onUserNotRegistered={handleUserNotRegistered}
         onUserNotLoggedIn={handleUserNotLoggedIn}
         >
         <CssBaseline>     
      <Grid container component="main" 
        sx={{ 
          height: '100vh', 
          bgcolor: "green", 
          backgroundImage: 'url(https://jobsandlaw.com/wp-content/uploads/2018/11/torres-1670879_1920.jpg)',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat', }}>
            
      <Grid item xs={6} sm={6} md={6} square>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: "flex-end",
              justifyContent: "center",
              bgcolor: 'rgba(43, 117, 177, 0.9)',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              height: '100vh',
              paddingRight: "10%",

            }}
          >
              <Typography variant="h2" 
              sx={{color: "white", fontWeight: "bold", textAlign: "right"}}
              >Empleo de calidad
            </Typography>
            <Typography variant="h2" 
              sx={{color: "white", fontWeight: "bold", textAlign: "right" }}
              >para abogados
            </Typography>
            <Typography variant="h4" 
              sx={{paddingTop: "50px", color: "white", fontWeight: "bold", textAlign: "right" }}
              >Accede a todas las vacantes
            </Typography>
            <Typography variant="h4" 
              sx={{color: "white", fontWeight: "bold", textAlign: "right" }}
              >abiertas por despachos,
            </Typography>
            <Typography variant="h4" 
              sx={{color: "white", fontWeight: "bold", textAlign: "right" }}
              >empresas y headhunters
            </Typography>
            </Box>
            </Grid>

        <Grid item xs={6} sm={6} md={6} square>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: "center",
              alignItems: "flex-start",
              paddingLeft: "10%",
              bgcolor: 'rgba(43, 117, 177, 0.9)',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              height: '100vh',
            }}
          >
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{mt: 1, color: "white", bgcolor: "rgba(228, 237, 246, 0.17)", marginTop: "60px", marginBottom: "60px", borderRadius: "25px", padding: "20px", maxWidth: "450px" }}>

            <Typography component="h1" variant="h6" sx={{color: "whitesmoke", textAlign: "center", paddingTop: "10px"}}>
              Inicia sesión
            </Typography>
            <Divider sx={{paddingTop: "20px"}}>
            <Chip label="Con tus redes sociales" sx={{color: "white"}} />
            </Divider>
            {error &&     <Stack sx={{ width: '100%' }} spacing={2}>
            <Alert severity="error">Datos incorrectos</Alert>
            </Stack>}   
              <Stack sx={{ width: '100%', paddingTop: "10px" }} spacing={2}>
              <Button variant="contained" startIcon={<GoogleIcon />} onClick={handleGoogleSignIn} sx={{backgroundColor: "#4285F4"}} >Continuar con Google</Button>
              </Stack>
              <Divider sx={{paddingTop: "20px"}}>
            <Chip label="O con tu correo electrónico" sx={{color: "white"}} />
            </Divider>
              <TextField   
                sx={{input: {color: "black", backgroundColor: "whitesmoke", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
                required
                fullWidth
                id="email"
                label="E-mail"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                sx={{input: {color: "whitesmoke", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: {color: "green"}}}
                margin="normal"
                required
                fullWidth
                name="password"
                label="Contraseña"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={(e) => setPassword(e.target.value)}
              />
              <FormControlLabel
                sx={{color: "white"}}
                control={<Checkbox value="remember" color="primary"/>}
                label="Recuérdame"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, backgroundColor: "rgba(43, 117, 177, 0.9)", color: "white"}}

              >
                Iniciar sesión
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="./forgotpasswordpage" variant="body2" sx={{color: "white"}} >
                    ¿Olvidaste tu contraseña?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="../" variant="body2" sx={{color: "white"}}>
                    {"¿Todavía no estás registrado?"}
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </CssBaseline>
    </AuthProvider>   
  );
}