import React from 'react'
import { useState } from 'react';
import { useUserAuth } from '../context/UserAuthContext';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { useNavigate } from 'react-router';
import GoogleIcon from '@mui/icons-material/Google';
import { onAuthStateChanged } from 'firebase/auth';
import { useEffect } from 'react';
import { auth, getUserInfo, registerNewUser, userExists } from '../firebase-config';

export default function AuthProvider( {children, onUserLoggedIn, onUserNotLoggedIn, onUserNotRegistered} ) {
    const navigate = useNavigate();
    useEffect (() => {
      onAuthStateChanged(auth, async (user) => {
      if(user){
        const isRegistered = await userExists(user.uid);
        if (isRegistered) {
          const userInfo = await getUserInfo(user.uid);
          if(userInfo.processCompleted) {
             onUserLoggedIn(userInfo)
          } else {
            onUserNotRegistered(userInfo);
          }
        } else {
          await registerNewUser({
            uid: user.uid,
            displayName: user.displayName,
            profilePicture: "",
            processCompleted: true,
            premiumUser: false,

          })
          onUserNotRegistered(user);
       }
      }  
        else{
        onUserNotLoggedIn();
      }
      
   
    });
    } , []);

    return (
    <div>
      {children}
    </div>
  )
}

