import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import FavoriteIcon from '@mui/icons-material/Favorite';
import Box from '@mui/material/Box';
import { Link, Outlet } from "react-router-dom";
import { CardActionArea } from '@mui/material';


export default function JobCard(props) {
  const [expanded, setExpanded] = React.useState(false);

  let isBest = props.isBestLawFirm;
  let highRange = 20+props.salary;

  return (
    <Card variant="outlined" sx={{display: "flex", width: "800px"}}>
        <CardActionArea component={Link} to="https://as.com">
        <CardMedia
            component="img"
            sx={{width: "160px", padding:"20px" }}
            image="https://distritodigitalcv.es/wp-content/uploads/2020/07/logo-garrigues.jpg"
            alt="Garrigues"
          />
        <Box sx={{ display: 'flex', flexDirection: 'row', width: "400px"}}>
        <CardContent sx={{paddingTop:"20px"}}>
        <Typography variant="subtitle1"  color="text.secondary">{props.employer}</Typography>
                <Typography variant="h6" color="text.secondary">
                <Link
            style={{ display: "block", margin: "1rem 0" }}
            to={`/Jobs/${props.id}`}
            key={props.id}
          >
            {props.title}
          </Link>
                     
                </Typography>
                <Typography variant="subtitle2" color="text.secondary">
                     {props.city}
                </Typography>
        <Box sx={{paddingTop: "15px"}}>
        <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px"}}>
            {props.area}
        </Typography>
        <Typography variant="caption" sx={{backgroundColor: "#03b1fc", borderRadius: "10px", color:"white", padding: "6px", marginLeft: "5px"}}>
            {props.experience}
        </Typography>
        <Typography variant="caption" sx={{backgroundColor: "#037bfc", borderRadius: "10px", color:"white", padding: "6px", marginLeft: "5px"}}>
            {`${props.salary}k`} - {highRange}k
        </Typography>
        </Box>
        </CardContent>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', width: "400px", alignItems: 'flex-end'}}>
            <Box sx={{display: "flex", alignItems: "flex-end"}}>
            <CardActions disableSpacing>
            <IconButton aria-label="add to favorites">
            <FavoriteIcon />
            </IconButton>
            </CardActions>
            </Box>
            <Box sx={{paddingTop: "62px", paddingRight: "15px"}}>
       
            {isBest &&  <Typography variant="caption" 
            sx={{border: "1px solid #037bfc", 
            borderRadius: "10px", 
            color:"#037bfc", 
            padding: "6px", 
            marginLeft: "5px"}}>
              Mejores despachos para trabajar 2022: {props.ranking} </Typography>}
       
        </Box>
        </Box> 
        </CardActionArea>
    </Card>
  );
}