import React, { useEffect } from 'react'
import Header from '../components/Header'
import CssBaseline from '@mui/material/CssBaseline';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { CardActionArea } from '@mui/material';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import { Link as RouterLink } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import AuthProvider from '../components/authProvider';
import { useNavigate } from 'react-router';
import { useState } from 'react';
import Button from '@mui/material/Button';
import {v4 as uuidv4} from "uuid";
import { getPerfil, insertNewPerfil, updatePerfil } from '../firebase-config';
import IconButton from '@mui/material/IconButton';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import listareas from '../pages/listareas';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Container from '@mui/material/Container';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import parse from "html-react-parser";
import Slider from '@mui/material/Slider';


function Perfil({docId, 
  nombre, 
  telefono,
  ciudad, 
  visibilidad, 
  email, 
  carrera1, 
  uni1, 
  fechaInicio1, 
  fechaFin1,
  carrera2, 
  uni2, 
  fechaInicio2, 
  fechaFin2, 
  carrera3, 
  uni3, 
  fechaInicio3, 
  fechaFin3, 
  carrera4, 
  uni4, 
  fechaInicio4, 
  fechaFin4, 
  carrera5, 
  uni5, 
  fechaInicio5, 
  fechaFin5, 
  carrera6, 
  uni6, 
  fechaInicio6, 
  fechaFin6, 
  carrera7, 
  uni7, 
  fechaInicio7, 
  fechaFin7, 
  carrera8, 
  uni8, 
  fechaInicio8, 
  fechaFin8, 
  carrera9, 
  uni9, 
  fechaInicio9, 
  fechaFin9,
  numGrados,
  numPosGrados,
  numTrabajos,
  area,
  castellano,
  ingles,
  notaMedia1,
  notaMedia2,
  notaMedia3,
  notaMedia4,
  notaMedia5,
  notaMedia6,
  notaMedia7,
  notaMedia8,
  trabajo1,
  empresa1,
  fechaInicioT1,
  fechaFinT1,
  descripcion1,
  trabajo2,
  empresa2,
  fechaInicioT2,
  fechaFinT2,
  descripcion2,
  trabajo3,
  empresa3,
  fechaInicioT3,
  fechaFinT3,
  descripcion3,
  trabajo4,
  empresa4,
  fechaInicioT4,
  fechaFinT4,
  descripcion4,
  trabajo5,
  empresa5,
  fechaInicioT5,
  fechaFinT5,
  descripcion5,
  trabajo6,
  empresa6,
  fechaInicioT6,
  fechaFinT6,
  descripcion6,
  trabajo7,
  empresa7,
  fechaInicioT7,
  fechaFinT7,
  descripcion7,
  trabajo8,
  empresa8,
  fechaInicioT8,
  fechaFinT8,
  descripcion8, 
  presentacion,
  anos,
  idioma1: idioma1,
  nivelIdioma1: nivelIdioma1,
  idioma2: idioma2,
  nivelIdioma2: nivelIdioma2, 
  onDelete, 
  onUpdate}) {
    const options = ['Perfil abierto (datos visibles para despachos, empresas y headhunters)', 'Perfil anónimo (datos identificativos ocultos)', "Perfil oculto"];
    const [value, setValue] = React.useState(options[0]);
    const [inputValue, setInputValue] = React.useState('');
    const [currentUser, setCurrentUser] = useState({});
    const [perfiles, setPerfiles] = useState([ ]);
    const [currentNombre, setCurrentNombre] = useState(nombre);
  const [currentTelefono, setCurrentTelefono] = useState(telefono);
  const [currentCiudad, setCurrentCiudad] = useState(ciudad);
  const [currentVisibilidad, setCurrentVisibilidad] = useState(visibilidad);
  const [currentEmail, setCurrentEmail] = useState(email);
  const [currentCarrera1, setCurrentCarrera1] = useState(carrera1);
  const [currentUni1, setCurrentUni1] = useState(uni1);
  const [currentFechaInicio1, setCurrentFechaInicio1] = useState(fechaInicio1);
  const [currentFechaFin1, setCurrentFechaFin1] = useState(fechaFin1);
  const [currentNotaMedia1, setCurrentNotaMedia1] = useState(notaMedia1);
  const [currentCarrera2, setCurrentCarrera2] = useState(carrera2);
  const [currentUni2, setCurrentUni2] = useState(uni2);
  const [currentFechaInicio2, setCurrentFechaInicio2] = useState(fechaInicio2);
  const [currentFechaFin2, setCurrentFechaFin2] = useState(fechaFin2);
  const [currentNotaMedia2, setCurrentNotaMedia2] = useState(notaMedia2);
  const [currentNotaMedia3, setCurrentNotaMedia3] = useState(notaMedia3);
  const [currentNotaMedia4, setCurrentNotaMedia4] = useState(notaMedia4);
  const [currentNotaMedia5, setCurrentNotaMedia5] = useState(notaMedia5);
  const [currentNotaMedia6, setCurrentNotaMedia6] = useState(notaMedia6);
  const [currentNotaMedia7, setCurrentNotaMedia7] = useState(notaMedia7);
  const [currentNotaMedia8, setCurrentNotaMedia8] = useState(notaMedia8);
  const [currentCarrera3, setCurrentCarrera3] = useState(carrera3);
  const [currentUni3, setCurrentUni3] = useState(uni3);
  const [currentFechaInicio3, setCurrentFechaInicio3] = useState(fechaInicio3);
  const [currentFechaFin3, setCurrentFechaFin3] = useState(fechaFin3);
  const [currentCarrera4, setCurrentCarrera4] = useState(carrera4);
  const [currentUni4, setCurrentUni4] = useState(uni4);
  const [currentFechaInicio4, setCurrentFechaInicio4] = useState(fechaInicio4);
  const [currentFechaFin4, setCurrentFechaFin4] = useState(fechaFin4);
  const [currentCarrera5, setCurrentCarrera5] = useState(carrera5);
  const [currentUni5, setCurrentUni5] = useState(uni5);
  const [currentFechaInicio5, setCurrentFechaInicio5] = useState(fechaInicio5);
  const [currentFechaFin5, setCurrentFechaFin5] = useState(fechaFin5);
  const [currentCarrera6, setCurrentCarrera6] = useState(carrera6);
  const [currentUni6, setCurrentUni6] = useState(uni6);
  const [currentFechaInicio6, setCurrentFechaInicio6] = useState(fechaInicio6);
  const [currentFechaFin6, setCurrentFechaFin6] = useState(fechaFin6);
  const [currentCarrera7, setCurrentCarrera7] = useState(carrera7);
  const [currentUni7, setCurrentUni7] = useState(uni7);
  const [currentFechaInicio7, setCurrentFechaInicio7] = useState(fechaInicio7);
  const [currentFechaFin7, setCurrentFechaFin7] = useState(fechaFin7);
  const [currentCarrera8, setCurrentCarrera8] = useState(carrera8);
  const [currentUni8, setCurrentUni8] = useState(uni8);
  const [currentFechaInicio8, setCurrentFechaInicio8] = useState(fechaInicio8);
  const [currentFechaFin8, setCurrentFechaFin8] = useState(fechaFin8);
  const [currentCarrera9, setCurrentCarrera9] = useState(carrera9);
  const [currentUni9, setCurrentUni9] = useState(uni9);
  const [currentFechaInicio9, setCurrentFechaInicio9] = useState(fechaInicio9);
  const [currentFechaFin9, setCurrentFechaFin9] = useState(fechaFin9);
  const [currentNumGrados, setcurrentNumGrados] = useState(numGrados);
  const [currentNumPosGrados, setcurrentNumPosGrados] = useState(numPosGrados);
  const [currentNumTrabajos, setcurrentNumTrabajos] = useState(numTrabajos);
  const [currentArea, setCurrentArea] = useState(area);
  const [currentCastellano, setCurrentCastellano] = useState(castellano);
  const [currentIngles, setCurrentIngles] = useState(ingles);
  const [currentTrabajo1, setCurrentTrabajo1] = useState(trabajo1);
    const [currentEmpresa1, setCurrentEmpresa1] = useState(empresa1);
    const [currentFechaInicioT1, setCurrentFechaInicioT1] = useState(fechaInicioT1);
    const [currentFechaFinT1, setCurrentFechaFinT1] = useState(fechaFinT1);
    const [currentTrabajo2, setCurrentTrabajo2] = useState(trabajo2);
    const [currentEmpresa2, setCurrentEmpresa2] = useState(empresa2);
    const [currentFechaInicioT2, setCurrentFechaInicioT2] = useState(fechaInicioT1);
    const [currentFechaFinT2, setCurrentFechaFinT2] = useState(fechaFinT2);
    const [currentTrabajo3, setCurrentTrabajo3] = useState(trabajo3);
    const [currentEmpresa3, setCurrentEmpresa3] = useState(empresa3);
    const [currentFechaInicioT3, setCurrentFechaInicioT3] = useState(fechaInicioT3);
    const [currentFechaFinT3, setCurrentFechaFinT3] = useState(fechaFinT3);
    const [currentTrabajo4, setCurrentTrabajo4] = useState(trabajo4);
    const [currentEmpresa4, setCurrentEmpresa4] = useState(empresa4);
    const [currentFechaInicioT4, setCurrentFechaInicioT4] = useState(fechaInicioT4);
    const [currentFechaFinT4, setCurrentFechaFinT4] = useState(fechaFinT4);
    const [currentTrabajo5, setCurrentTrabajo5] = useState(trabajo5);
    const [currentEmpresa5, setCurrentEmpresa5] = useState(empresa5);
    const [currentFechaInicioT5, setCurrentFechaInicioT5] = useState(fechaInicioT5);
    const [currentFechaFinT5, setCurrentFechaFinT5] = useState(fechaFinT5);
    const [currentTrabajo6, setCurrentTrabajo6] = useState(trabajo6);
    const [currentEmpresa6, setCurrentEmpresa6] = useState(empresa6);
    const [currentFechaInicioT6, setCurrentFechaInicioT6] = useState(fechaInicioT6);
    const [currentFechaFinT6, setCurrentFechaFinT6] = useState(fechaFinT6);
    const [currentTrabajo7, setCurrentTrabajo7] = useState(trabajo7);
    const [currentEmpresa7, setCurrentEmpresa7] = useState(empresa7);
    const [currentFechaInicioT7, setCurrentFechaInicioT7] = useState(fechaInicioT7);
    const [currentFechaFinT7, setCurrentFechaFinT7] = useState(fechaFinT7);
    const [currentTrabajo8, setCurrentTrabajo8] = useState(trabajo8);
    const [currentEmpresa8, setCurrentEmpresa8] = useState(empresa8);
    const [currentFechaInicioT8, setCurrentFechaInicioT8] = useState(fechaInicioT8);
    const [currentFechaFinT8, setCurrentFechaFinT8] = useState(fechaFinT8);
    const [currentDescripcion1, setCurrentDescripcion1] = useState(descripcion1);
    const [currentDescripcion2, setCurrentDescripcion2] = useState(descripcion2);
    const [currentDescripcion3, setCurrentDescripcion3] = useState(descripcion3);
    const [currentDescripcion4, setCurrentDescripcion4] = useState(descripcion4);
    const [currentDescripcion5, setCurrentDescripcion5] = useState(descripcion5);
    const [currentDescripcion6, setCurrentDescripcion6] = useState(descripcion6);
    const [currentDescripcion7, setCurrentDescripcion7] = useState(descripcion7);
    const [currentDescripcion8, setCurrentDescripcion8] = useState(descripcion8);
    const [currentPresentacion, setCurrentPresentacion] = useState(presentacion);
    const [currentIdioma1, setCurrentIdioma1] = useState(idioma1);
    const [currentNivelIdioma1, setCurrentNivelIdioma1] = useState(nivelIdioma1);
    const [currentIdioma2, setCurrentIdioma2] = useState(idioma2);
    const [currentNivelIdioma2, setCurrentNivelIdioma2] = useState(nivelIdioma2);
    const [currentAnos, setCurrentAnos] = useState(anos);
    const initialText = 'Guardar cambios';
    const [buttonText, setButtonText] = useState(initialText);

    const anosExperiencia = [
      {
        value: 0,
        label: '0',
      },
      {
        value: 1,
        label: '1',
      },
      {
        value: 2,
        label: '2',
      },
      {
        value: 3,
        label: '3',
      },
      {
        value: 4,
        label: '4',
      },
      {
        value: 5,
        label: '5',
      },
      {
        value: 6,
        label: '6',
      },
      {
        value: 7,
        label: '7',
      },
      {
        value: 8,
        label: '8',
      },
      {
        value: 9,
        label: '9',
      },
      {
        value: 10,
        label: '10',
      },
      {
        value: 11,
        label: '11',
      },
      {
        value: 12,
        label: '12',
      },
      {
        value: 13,
        label: '13',
      },
      {
        value: 14,
        label: '14',
      },
      {
        value: 15,
        label: '15',
      },
      {
        value: 16,
        label: '16',
      },
      {
        value: 17,
        label: '17',
      },
      {
        value: 18,
        label: '18',
      },
      {
        value: 19,
        label: '19',
      },
      {
        value: 20,
        label: '+20',
      },
    ];

    const anosExperienciaMovil = [
      {
        value: 0,
        label: '0',
      },
      {
        value: 5,
        label: '5',
      },
      {
        value: 10,
        label: '10',
      },
      {
        value: 15,
        label: '15',
      },
      {
        value: 20,
        label: '+20',
      },
    ];

    function valuetext(value: number) {
      return `${value}k`;
    }
  
    function handleChangeNombre(e) {
    setCurrentNombre(e.target.value)
  }

  function handleChangeTelefono(e) {
    setCurrentTelefono(e.target.value)
  }

  function handleChangeCiudad(e) {
    setCurrentCiudad(e.target.value)
  }

  function handleChangeEmail(e) {
    setCurrentEmail(e.target.value)
  }

  function handleChangeCarrera1(e) {
    setCurrentCarrera1(e.target.value)
  }

  function handleChangeUni1(e) {
    setCurrentUni1(e.target.value)
  }

  function handleChangeFechaInicio1(e) {
    setCurrentFechaInicio1(e.target.value)
  }

  function handleChangeFechaFin1(e) {
    setCurrentFechaFin1(e.target.value)
  }

  function handleChangeNotaMedia1(e) {
    setCurrentNotaMedia1(e.target.value)
  }

  function handleChangeCarrera2(e) {
    setCurrentCarrera2(e.target.value)
  }

  function handleChangeUni2(e) {
    setCurrentUni2(e.target.value)
  }

  function handleChangeFechaInicio2(e) {
    setCurrentFechaInicio2(e.target.value)
  }

  function handleChangeFechaFin2(e) {
    setCurrentFechaFin2(e.target.value)
  }
  function handleChangeNotaMedia2(e) {
    setCurrentNotaMedia2(e.target.value)
  }
  function handleChangeNotaMedia3(e) {
    setCurrentNotaMedia3(e.target.value)
  }
  function handleChangeNotaMedia4(e) {
    setCurrentNotaMedia4(e.target.value)
  }
  function handleChangeNotaMedia5(e) {
    setCurrentNotaMedia5(e.target.value)
  }
  function handleChangeNotaMedia6(e) {
    setCurrentNotaMedia6(e.target.value)
  }
  function handleChangeNotaMedia7(e) {
    setCurrentNotaMedia7(e.target.value)
  }
  function handleChangeNotaMedia8(e) {
    setCurrentNotaMedia8(e.target.value)
  }
  function handleChangeCarrera3(e) {
    setCurrentCarrera3(e.target.value)
  }

  function handleChangeUni3(e) {
    setCurrentUni3(e.target.value)
  }

  function handleChangeFechaInicio3(e) {
    setCurrentFechaInicio3(e.target.value)
  }

  function handleChangeFechaFin3(e) {
    setCurrentFechaFin3(e.target.value)
  }
  function handleChangeCarrera4(e) {
    setCurrentCarrera4(e.target.value)
  }

  function handleChangeUni4(e) {
    setCurrentUni4(e.target.value)
  }

  function handleChangeFechaInicio4(e) {
    setCurrentFechaInicio4(e.target.value)
  }

  function handleChangeFechaFin4(e) {
    setCurrentFechaFin4(e.target.value)
  }
  function handleChangeCarrera5(e) {
    setCurrentCarrera5(e.target.value)
  }

  function handleChangeUni5(e) {
    setCurrentUni5(e.target.value)
  }

  function handleChangeFechaInicio5(e) {
    setCurrentFechaInicio5(e.target.value)
  }

  function handleChangeFechaFin5(e) {
    setCurrentFechaFin5(e.target.value)
  }
  function handleChangeCarrera6(e) {
    setCurrentCarrera6(e.target.value)
  }

  function handleChangeUni6(e) {
    setCurrentUni6(e.target.value)
  }

  function handleChangeFechaInicio6(e) {
    setCurrentFechaInicio6(e.target.value)
  }

  function handleChangeFechaFin6(e) {
    setCurrentFechaFin6(e.target.value)
  }
  function handleChangeCarrera7(e) {
    setCurrentCarrera7(e.target.value)
  }

  function handleChangeUni7(e) {
    setCurrentUni7(e.target.value)
  }

  function handleChangeFechaInicio7(e) {
    setCurrentFechaInicio7(e.target.value)
  }

  function handleChangeFechaFin7(e) {
    setCurrentFechaFin7(e.target.value)
  }
  function handleChangeCarrera8(e) {
    setCurrentCarrera8(e.target.value)
  }

  function handleChangeUni8(e) {
    setCurrentUni8(e.target.value)
  }

  function handleChangeFechaInicio8(e) {
    setCurrentFechaInicio8(e.target.value)
  }

  function handleChangeFechaFin8(e) {
    setCurrentFechaFin8(e.target.value)
  }
  function handleChangeCarrera9(e) {
    setCurrentCarrera9(e.target.value)
  }

  function handleChangeUni9(e) {
    setCurrentUni9(e.target.value)
  }

  function handleChangeFechaInicio9(e) {
    setCurrentFechaInicio9(e.target.value)
  }

  function handleChangeFechaFin9(e) {
    setCurrentFechaFin9(e.target.value)
  }

  const handleChangeCastellano = (event) => {
    setCurrentCastellano(event.target.value);
 
   }

   const handleChangeIngles = (event) => {
    setCurrentIngles(event.target.value);
 
   }

   const handleChangeIdioma1 = (event) => {
    setCurrentIdioma1(event.target.value);
    console.log(currentIdioma1)
 
   }

   const handleChangeIdioma2 = (event) => {
    setCurrentIdioma2(event.target.value);
 
   }

   const handleChangeNivelIdioma1 = (event) => {
    setCurrentNivelIdioma1(event.target.value);
    console.log(currentNivelIdioma1)
 
   }

   const handleChangeNivelIdioma2 = (event) => {
    setCurrentNivelIdioma2(event.target.value);
 
   }

   function handleChangeTrabajo1(e) {
    setCurrentTrabajo1(e.target.value)
  }

  function handleChangeEmpresa1(e) {
    setCurrentEmpresa1(e.target.value)
  }

  function handleChangeFechaInicioT1(e) {
    setCurrentFechaInicioT1(e.target.value)
  }

  function handleChangeFechaFinT1(e) {
    setCurrentFechaFinT1(e.target.value)
  }
  function handleChangeDescripcion1(e) {
    setCurrentDescripcion1(e.target.value)
  }

  function handleChangeTrabajo2(e) {
    setCurrentTrabajo2(e.target.value)
  }

  function handleChangeEmpresa2(e) {
    setCurrentEmpresa2(e.target.value)
  }

  function handleChangeFechaInicioT2(e) {
    setCurrentFechaInicioT2(e.target.value)
  }

  function handleChangeFechaFinT2(e) {
    setCurrentFechaFinT2(e.target.value)
  }
  function handleChangeDescripcion2(e) {
    setCurrentDescripcion2(e.target.value)
  }

  function handleChangeTrabajo3(e) {
    setCurrentTrabajo3(e.target.value)
  }

  function handleChangeEmpresa3(e) {
    setCurrentEmpresa3(e.target.value)
  }

  function handleChangeFechaInicioT3(e) {
    setCurrentFechaInicioT3(e.target.value)
  }

  function handleChangeFechaFinT3(e) {
    setCurrentFechaFinT3(e.target.value)
  }
  function handleChangeDescripcion3(e) {
    setCurrentDescripcion3(e.target.value)
  }

  function handleChangeTrabajo4(e) {
    setCurrentTrabajo4(e.target.value)
  }

  function handleChangeEmpresa4(e) {
    setCurrentEmpresa4(e.target.value)
  }

  function handleChangeFechaInicioT4(e) {
    setCurrentFechaInicioT4(e.target.value)
  }

  function handleChangeFechaFinT4(e) {
    setCurrentFechaFinT4(e.target.value)
  }
  function handleChangeDescripcion4(e) {
    setCurrentDescripcion4(e.target.value)
  }
  function handleChangeTrabajo5(e) {
    setCurrentTrabajo5(e.target.value)
  }

  function handleChangeEmpresa5(e) {
    setCurrentEmpresa5(e.target.value)
  }

  function handleChangeFechaInicioT5(e) {
    setCurrentFechaInicioT5(e.target.value)
  }

  function handleChangeFechaFinT5(e) {
    setCurrentFechaFinT5(e.target.value)
  }
  function handleChangeDescripcion5(e) {
    setCurrentDescripcion5(e.target.value)
  }
  function handleChangeTrabajo6(e) {
    setCurrentTrabajo6(e.target.value)
  }

  function handleChangeEmpresa6(e) {
    setCurrentEmpresa6(e.target.value)
  }

  function handleChangeFechaInicioT6(e) {
    setCurrentFechaInicioT6(e.target.value)
  }

  function handleChangeFechaFinT6(e) {
    setCurrentFechaFinT6(e.target.value)
  }
  function handleChangeDescripcion6(e) {
    setCurrentDescripcion6(e.target.value)
  }
  function handleChangeTrabajo7(e) {
    setCurrentTrabajo7(e.target.value)
  }

  function handleChangeEmpresa7(e) {
    setCurrentEmpresa7(e.target.value)
  }

  function handleChangeFechaInicioT7(e) {
    setCurrentFechaInicioT7(e.target.value)
  }

  function handleChangeFechaFinT7(e) {
    setCurrentFechaFinT7(e.target.value)
  }
  function handleChangeDescripcion7(e) {
    setCurrentDescripcion7(e.target.value)
  }
  function handleChangeTrabajo8(e) {
    setCurrentTrabajo8(e.target.value)
  }

  function handleChangeEmpresa8(e) {
    setCurrentEmpresa8(e.target.value)
  }

  function handleChangeFechaInicioT8(e) {
    setCurrentFechaInicioT8(e.target.value)
  }

  function handleChangeFechaFinT8(e) {
    setCurrentFechaFinT8(e.target.value)
  }
  function handleChangeDescripcion8(e) {
    setCurrentDescripcion8(e.target.value)
  }

  const handleChangeExperiencia = (event, newCurrentAnos) => {
    setCurrentAnos(newCurrentAnos);
  };


  function handleSubmit2(e) {
    e.preventDefault();
    console.log("click");
    setButtonText("¡Guardado!");
    setTimeout(() => {
      setButtonText(initialText);
    }, 2000);
    onUpdate(docId, 
      currentNombre, 
      currentTelefono,
      currentCiudad, 
      currentVisibilidad, 
      currentEmail, 
      currentCarrera1, 
      currentUni1, 
      currentFechaInicio1, 
      currentFechaFin1,
      currentCarrera2, 
      currentUni2, 
      currentFechaInicio2, 
      currentFechaFin2,
      currentCarrera3, 
      currentUni3, 
      currentFechaInicio3, 
      currentFechaFin3,
      currentCarrera4, 
      currentUni4, 
      currentFechaInicio4, 
      currentFechaFin4,
      currentCarrera5, 
      currentUni5, 
      currentFechaInicio5, 
      currentFechaFin5,
      currentCarrera6, 
      currentUni6, 
      currentFechaInicio6, 
      currentFechaFin6,
      currentCarrera7, 
      currentUni7, 
      currentFechaInicio7, 
      currentFechaFin7,
      currentCarrera8, 
      currentUni8, 
      currentFechaInicio8, 
      currentFechaFin8,
      currentCarrera9, 
      currentUni9, 
      currentFechaInicio9, 
      currentFechaFin9,
      currentNumGrados,
      currentNumPosGrados,
      currentNumTrabajos,
      currentArea,
      currentCastellano,
      currentIngles,
      currentNotaMedia1,
      currentNotaMedia2,
      currentNotaMedia3,
      currentNotaMedia4,
      currentNotaMedia5,
      currentNotaMedia6,
      currentNotaMedia7,
      currentNotaMedia8,
      currentTrabajo1, 
      currentEmpresa1, 
      currentFechaInicioT1, 
      currentFechaFinT1,
      currentDescripcion1,
      currentTrabajo2, 
      currentEmpresa2, 
      currentFechaInicioT2, 
      currentFechaFinT2,
      currentDescripcion2,
      currentTrabajo3, 
      currentEmpresa3, 
      currentFechaInicioT3, 
      currentFechaFinT3,
      currentDescripcion3,
      currentTrabajo4, 
      currentEmpresa4, 
      currentFechaInicioT4, 
      currentFechaFinT4,
      currentDescripcion4,
      currentTrabajo5, 
      currentEmpresa5, 
      currentFechaInicioT5, 
      currentFechaFinT5,
      currentDescripcion5,
      currentTrabajo6, 
      currentEmpresa6, 
      currentFechaInicioT6, 
      currentFechaFinT6,
      currentDescripcion6,
      currentTrabajo7, 
      currentEmpresa7, 
      currentFechaInicioT7, 
      currentFechaFinT7,
      currentDescripcion7,
      currentTrabajo8, 
      currentEmpresa8, 
      currentFechaInicioT8, 
      currentFechaFinT8,
      currentDescripcion8,
      currentPresentacion,
      currentAnos,
      currentIdioma1,
      currentNivelIdioma1,
      currentIdioma2,
      currentNivelIdioma2,
      );
  } 

  function handleButtonAddGrado1 () {
    const newcurrentNumGrados = currentNumGrados + 1;
    setcurrentNumGrados(newcurrentNumGrados);
    console.log(newcurrentNumGrados);
   }


   function handleButtonRemoveGrado1 () {
    const newcurrentNumGrados = currentNumGrados - 1;
    setcurrentNumGrados(newcurrentNumGrados);
    setCurrentCarrera1(""); 
      setCurrentUni1("");
      setCurrentFechaInicio1(""); 
      setCurrentFechaFin1("");
      setCurrentNotaMedia1("");
   }

   function handleButtonRemoveGrado2 () {
    const newcurrentNumGrados = currentNumGrados - 1;
    setcurrentNumGrados(newcurrentNumGrados);
    setCurrentCarrera2(""); 
      setCurrentUni2("");
      setCurrentFechaInicio2(""); 
      setCurrentFechaFin2("");
      setCurrentNotaMedia2("");
   }

   function handleButtonRemoveGrado3 () {
    const newcurrentNumGrados = currentNumGrados - 1;
    setcurrentNumGrados(newcurrentNumGrados);
    setCurrentCarrera3(""); 
      setCurrentUni3("");
      setCurrentFechaInicio3(""); 
      setCurrentFechaFin3("");
      setCurrentNotaMedia3("");
   }

   function handleButtonRemoveGrado4 () {
    const newcurrentNumGrados = currentNumGrados - 1;
    setcurrentNumGrados(newcurrentNumGrados);
    setCurrentCarrera4(""); 
      setCurrentUni4("");
      setCurrentFechaInicio4(""); 
      setCurrentFechaFin4("");
      setCurrentNotaMedia4("");
   }

   function handleButtonAddPosGrado1 () {
    const newcurrentNumPosGrados = currentNumPosGrados + 1;
    setcurrentNumPosGrados(newcurrentNumPosGrados);
    console.log(newcurrentNumPosGrados);
   }

   function handleButtonRemovePosGrado1 () {
    const newcurrentNumPosGrados = currentNumPosGrados - 1;
    setcurrentNumPosGrados(newcurrentNumPosGrados);
    console.log(newcurrentNumPosGrados);
   }

   function handleButtonRemovePosGrado2 () {
    const newcurrentNumPosGrados = currentNumPosGrados - 1;
    setcurrentNumPosGrados(newcurrentNumPosGrados);
    setCurrentCarrera6(""); 
      setCurrentUni6("");
      setCurrentFechaInicio6(""); 
      setCurrentFechaFin6("");
      setCurrentNotaMedia6("");
   }

   function handleButtonRemovePosGrado3 () {
    const newcurrentNumPosGrados = currentNumPosGrados - 1;
    setcurrentNumPosGrados(newcurrentNumPosGrados);
    setCurrentCarrera7(""); 
      setCurrentUni7("");
      setCurrentFechaInicio7(""); 
      setCurrentFechaFin7("");
      setCurrentNotaMedia7("");
   }

   function handleButtonRemovePosGrado4 () {
    const newcurrentNumPosGrados = currentNumPosGrados - 1;
    setcurrentNumPosGrados(newcurrentNumPosGrados);
    setCurrentCarrera8(""); 
      setCurrentUni8("");
      setCurrentFechaInicio8(""); 
      setCurrentFechaFin8("");
      setCurrentNotaMedia8("");
   }

   
   function handleButtonAddTrabajo () {
    const newcurrentNumTrabajos = currentNumTrabajos + 1;
    setcurrentNumTrabajos(newcurrentNumTrabajos);
   }

   console.log(currentNumTrabajos);
   console.log(area)
   console.log(perfiles.length)

   function handleButtonRemoveTrabajo1 () {
    const newcurrentNumTrabajos = currentNumTrabajos - 1;
    setcurrentNumTrabajos(newcurrentNumTrabajos);
    setCurrentTrabajo1(""); 
      setCurrentEmpresa1("");
      setCurrentFechaInicioT1(""); 
      setCurrentFechaFinT1("");
      setCurrentDescripcion1("");
   }

   function handleButtonRemoveTrabajo2 () {
    const newcurrentNumTrabajos = currentNumTrabajos - 1;
    setcurrentNumTrabajos(newcurrentNumTrabajos);
    setCurrentTrabajo2(""); 
      setCurrentEmpresa2("");
      setCurrentFechaInicioT2(""); 
      setCurrentFechaFinT2("");
      setCurrentDescripcion2("");
   }
   function handleButtonRemoveTrabajo3 () {
    const newcurrentNumTrabajos = currentNumTrabajos - 1;
    setcurrentNumTrabajos(newcurrentNumTrabajos);
    setCurrentTrabajo3(""); 
      setCurrentEmpresa3("");
      setCurrentFechaInicioT3(""); 
      setCurrentFechaFinT3("");
      setCurrentDescripcion3("");
   }
   function handleButtonRemoveTrabajo4 () {
    const newcurrentNumTrabajos = currentNumTrabajos - 1;
    setcurrentNumTrabajos(newcurrentNumTrabajos);
    setCurrentTrabajo4(""); 
      setCurrentEmpresa4("");
      setCurrentFechaInicioT4(""); 
      setCurrentFechaFinT4("");
      setCurrentDescripcion4("");
   }
   function handleButtonRemoveTrabajo5 () {
    const newcurrentNumTrabajos = currentNumTrabajos - 1;
    setcurrentNumTrabajos(newcurrentNumTrabajos);
    setCurrentTrabajo5(""); 
      setCurrentEmpresa5("");
      setCurrentFechaInicioT5(""); 
      setCurrentFechaFinT5("");
      setCurrentDescripcion5("");
   }
   function handleButtonRemoveTrabajo6 () {
    const newcurrentNumTrabajos = currentNumTrabajos - 1;
    setcurrentNumTrabajos(newcurrentNumTrabajos);
    setCurrentTrabajo6(""); 
      setCurrentEmpresa6("");
      setCurrentFechaInicioT6(""); 
      setCurrentFechaFinT6("");
      setCurrentDescripcion6("");
   }
   function handleButtonRemoveTrabajo7 () {
    const newcurrentNumTrabajos = currentNumTrabajos - 1;
    setcurrentNumTrabajos(newcurrentNumTrabajos);
    setCurrentTrabajo7(""); 
      setCurrentEmpresa7("");
      setCurrentFechaInicioT7(""); 
      setCurrentFechaFinT7("");
      setCurrentDescripcion7("");
   }
   function handleButtonRemoveTrabajo8 () {
    const newcurrentNumTrabajos = currentNumTrabajos - 1;
    setcurrentNumTrabajos(newcurrentNumTrabajos);
    setCurrentTrabajo8(""); 
      setCurrentEmpresa8("");
      setCurrentFechaInicioT8(""); 
      setCurrentFechaFinT8("");
      setCurrentDescripcion8("");
   }
console.log(currentNumTrabajos);
   

  /* const arr = [];
   for (var n in currentArea) {
    if (area.hasOwnProperty(n)){
      console.log(area[n])
      arr.push(area[n])
      console.log(arr)
    }
  }

  const eachArea = arr.map((each) => {
    return ( 

          <Typography variant="body2" color = "white" bgcolor={"rgba(43, 117, 177, 0.9)"}
              sx={{paddingLeft: "20px", paddingRight: "20px", paddingTop: "5px", paddingBottom: "5px", borderRadius: "10px"}}>
            {each.title}
          </Typography>
      )
  }) */

    return (
      <div>
      <Box sx={{display: { xl: 'block', l: "block", m: "block", s:"none", xs: 'none'}}} >
      <Grid container component="main" sx={{}}>
      <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
       
      <Stack sx={{ paddingTop: "20px" }} spacing={2} >
      <Typography variant="h5" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center"}}
              >Introduce tus datos profesionales
            </Typography>
            
              </Stack>


        <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: "center",
              alignItems: "center",
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          >
            <Box component="form" noValidate onSubmit={handleSubmit2} sx={{mt: 1, marginBottom: "60px", borderRadius: "25px", padding: "20px", width: "650px" }}>
        
            <Divider sx={{marginBottom: "20px"}} >
            <Chip label="Visibilidad de tu perfil profesional" sx={{color: "white", bgcolor: "rgba(43, 117, 177, 0.9)"}} />
            </Divider>

<Autocomplete
        sx={{paddingTop: "20px"}}
        id="tags-standard"
        options={options}
            name="visibilidad"
        onChange={(e, value)=> setCurrentVisibilidad(value)}
        defaultValue={currentVisibilidad}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Visibilidad de tu perfil"
            placeholder="Visibilidad de tu perfil"
          />
        )}
      />


                          <Divider sx={{marginTop: "30px"}} >
            <Chip label="Información general" sx={{color: "white", bgcolor: "rgba(43, 117, 177, 0.9)"}} />
            </Divider>
            <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
     
                fullWidth
                id="Nombre completo"
                label="Nombre completo"
                name="nombre"
                autoComplete="nombre"
              
                onChange={handleChangeNombre}
                defaultValue={nombre}
              />

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
   
                fullWidth
                id="Número de teléfono"
                label="Número de teléfono"
                name="telefono"
                autoComplete="telefono"
                
                onChange={handleChangeTelefono}
                defaultValue={telefono}
              />

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="email"
                label="email"
                name="email"
                autoComplete="email"
             
                onChange={handleChangeEmail}
                defaultValue={email}
              />

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
   
                fullWidth
                id="Ciudad"
                label="Ciudad"
                name="ciudad"
                autoComplete="ciudad"
                
                onChange={handleChangeCiudad}
                defaultValue={ciudad}
              />

<Divider sx={{marginTop: "30px"}} >
            <Chip label="Formación" sx={{color: "white", bgcolor: "rgba(43, 117, 177, 0.9)"}} />
            </Divider>

            <Stack sx={{ }} spacing={2}>
      <Typography variant="body1" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "10px", textAlign: "center"}}
              >Introduce tus estudios de grado o licenciatura
            </Typography>
            
              </Stack>

            <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
        
                fullWidth
                id="carrera1"
                label="Estudios"
                name="carrera1"
                autoComplete="carrera1"
              
                onChange={handleChangeCarrera1}
                defaultValue={carrera1}
              />

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="uni1"
                label="Universidad"
                name="uni1"
                autoComplete="uni1"
            
                onChange={handleChangeUni1}
                defaultValue={uni1}
              />

<Grid container component="main" columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
       
                fullWidth
                id="fechaInicio1"
                label="Fecha de inicio"
                name="fechaInicio1"
                autoComplete="fechaInicio1"
            
                onChange={handleChangeFechaInicio1}
                defaultValue={fechaInicio1}
              />
        </Grid>

        <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
      
                fullWidth
                id="fechaFin1"
                label="Fecha de fin"
                name="fechaFin1"
                autoComplete="fechaFin1"
             
                onChange={handleChangeFechaFin1}
                defaultValue={fechaFin1}
              />
        </Grid>
        </Grid>
        <TextField   
                sx={{width: '28ch', input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
        
                id="notaMedia1"
                label="Nota media en la carrera"
                name="notaMedia1"
                autoComplete="notaMedia1"
           
                onChange={handleChangeNotaMedia1}
                defaultValue={notaMedia1}
              />

        {currentNumGrados <= 1 ? <Stack direction="row" spacing={0}>
          <IconButton aria-label="delete" color="primary"  onClick={handleButtonAddGrado1}>
          <AddCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center", paddingRight: "40px"}}
              >Añadir nueva formación
            </Typography>
            <IconButton aria-label="delete" color="primary"  onClick={handleButtonRemoveGrado1}>
          <RemoveCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center"}}
              >Eliminar formación
            </Typography>
            
        </Stack>:
        <Divider sx={{marginTop: "20px", marginBottom: "10px"}} ></Divider>}


        {currentNumGrados > 1 &&
          <div>
          <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
             
                fullWidth
                id="carrera2"
                label="Estudios"
                name="carrera2"
                autoComplete="carrera2"
            
                onChange={handleChangeCarrera2}
                defaultValue={carrera2}
              />

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
           
                fullWidth
                id="uni2"
                label="Universidad"
                name="uni2"
                autoComplete="uni2"
            
                onChange={handleChangeUni2}
                defaultValue={uni2}
              />

<Grid container component="main" columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
           
                fullWidth
                id="fechaInicio2"
                label="Fecha de inicio"
                name="fechaInicio2"
                autoComplete="fechaInicio2"
             
                onChange={handleChangeFechaInicio2}
                defaultValue={fechaInicio2}
              />
        </Grid>

        <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
          
                fullWidth
                id="fechaFin2"
                label="Fecha de fin"
                name="fechaFin2"
                autoComplete="fechaFin2"
            
                onChange={handleChangeFechaFin2}
                defaultValue={fechaFin2}
              />
        </Grid>
        </Grid>
        <TextField   
                sx={{width: '28ch', input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
            
                id="notaMedia2"
                label="Nota media en la carrera"
                name="notaMedia2"
                autoComplete="notaMedia2"
            
                onChange={handleChangeNotaMedia2}
                defaultValue={notaMedia2}
              />
        
        {currentNumGrados === 2 ? <Stack direction="row" spacing={0}>
          <IconButton aria-label="delete" color="primary"  onClick={handleButtonAddGrado1}>
          <AddCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center", paddingRight: "40px"}}
              >Añadir nueva formación
            </Typography>
            <IconButton aria-label="delete" color="primary"  onClick={handleButtonRemoveGrado2}>
          <RemoveCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center"}}
              >Eliminar formación
            </Typography>
            
        </Stack>:
        <Divider sx={{marginTop: "20px", marginBottom: "10px"}} ></Divider>}

        </div>
          
          }

{currentNumGrados >= 3 &&
          <div>
          <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
        
                fullWidth
                id="carrera3"
                label="Estudios"
                name="carrera3"
                autoComplete="carrera3"
          
                onChange={handleChangeCarrera3}
                defaultValue={carrera3}
              />

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
            
                fullWidth
                id="uni3"
                label="Universidad"
                name="uni3"
                autoComplete="uni3"
          
                onChange={handleChangeUni3}
                defaultValue={uni3}
              />

<Grid container component="main" columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
       
                fullWidth
                id="fechaInicio3"
                label="Fecha de inicio"
                name="fechaInicio3"
                autoComplete="fechaInicio3"
              
                onChange={handleChangeFechaInicio3}
                defaultValue={fechaInicio3}
              />
        </Grid>

        <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
        
                fullWidth
                id="fechaFin3"
                label="Fecha de fin"
                name="fechaFin3"
                autoComplete="fechaFin3"
          
                onChange={handleChangeFechaFin3}
                defaultValue={fechaFin3}
              />
        </Grid>
        </Grid>
        <TextField   
                sx={{width: '28ch', input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
         
                id="notaMedia3"
                label="Nota media en la carrera"
                name="notaMedia3"
                autoComplete="notaMedia3"
           
                onChange={handleChangeNotaMedia3}
                defaultValue={notaMedia3}
              />
        
            {currentNumGrados === 3 ? <Stack direction="row" spacing={0}>
          <IconButton aria-label="delete" color="primary"  onClick={handleButtonAddGrado1}>
          <AddCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center", paddingRight: "40px"}}
              >Añadir nueva formación
            </Typography>
            <IconButton aria-label="delete" color="primary"  onClick={handleButtonRemoveGrado3}>
          <RemoveCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center"}}
              >Eliminar formación
            </Typography>
            
        </Stack>:
        <Divider sx={{marginTop: "20px", marginBottom: "10px"}} ></Divider>}
       </div>
          
          }

{currentNumGrados >= 4 &&
          <div>
          <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
           
                fullWidth
                id="carrera4"
                label="Estudios"
                name="carrera4"
                autoComplete="carrera4"
            
                onChange={handleChangeCarrera4}
                defaultValue={carrera4}
              />

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
           
                fullWidth
                id="uni4"
                label="Universidad"
                name="uni4"
                autoComplete="uni4"
          
                onChange={handleChangeUni4}
                defaultValue={uni4}
              />

<Grid container component="main" columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
        
                fullWidth
                id="fechaInicio4"
                label="Fecha de inicio"
                name="fechaInicio4"
                autoComplete="fechaInicio4"
             
                onChange={handleChangeFechaInicio4}
                defaultValue={fechaInicio4}
              />
        </Grid>

        <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
         
                fullWidth
                id="fechaFin4"
                label="Fecha de fin"
                name="fechaFin4"
                autoComplete="fechaFin4"
            
                onChange={handleChangeFechaFin4}
                defaultValue={fechaFin4}
              />
        </Grid>
        </Grid>
        <TextField   
                sx={{width: '28ch', input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
       
                id="notaMedia4"
                label="Nota media en la carrera"
                name="notaMedia4"
                autoComplete="notaMedia4"
           
                onChange={handleChangeNotaMedia4}
                defaultValue={notaMedia4}
              />
        
            {currentNumGrados === 4 ? <Stack direction="row" spacing={0}>
          <IconButton aria-label="delete" color="primary"  onClick={handleButtonAddGrado1}>
          <AddCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center", paddingRight: "40px"}}
              >Añadir nueva formación
            </Typography>
            <IconButton aria-label="delete" color="primary"  onClick={handleButtonRemoveGrado4}>
          <RemoveCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center"}}
              >Eliminar formación
            </Typography>
            
        </Stack>:
        <Divider sx={{marginTop: "20px", marginBottom: "10px"}} ></Divider>}
       </div>
          
          }

<Divider sx={{marginTop: "20px", marginBottom: "10px"}} ></Divider>
<Stack sx={{ }} spacing={2}>
      <Typography variant="1" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "30px", textAlign: "center"}}
              >Introduce tus estudios de posgrado
            </Typography>
            
              </Stack>

              <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
     
                fullWidth
                id="carrera5"
                label="Estudios"
                name="carrera5"
                autoComplete="carrera5"
              
                onChange={handleChangeCarrera5}
                defaultValue={carrera5}
              />

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
   
                fullWidth
                id="uni5"
                label="Universidad"
                name="uni5"
                autoComplete="uni5"
            
                onChange={handleChangeUni5}
                defaultValue={uni5}
              />

<Grid container component="main" columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
     
                fullWidth
                id="fechaInicio5"
                label="Fecha de inicio"
                name="fechaInicio5"
                autoComplete="fechaInicio5"
            
                onChange={handleChangeFechaInicio5}
                defaultValue={fechaInicio5}
              />
        </Grid>

        <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
      
                fullWidth
                id="fechaFin5"
                label="Fecha de fin"
                name="fechaFin5"
                autoComplete="fechaFin5"
             
                onChange={handleChangeFechaFin5}
                defaultValue={fechaFin5}
              />
        </Grid>
        </Grid>
        <TextField   
                sx={{width: '28ch', input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
     
                id="notaMedia5"
                label="Nota media en la carrera"
                name="notaMedia5"
                autoComplete="notaMedia5"
           
                onChange={handleChangeNotaMedia5}
                defaultValue={notaMedia5}
              />

        {currentNumPosGrados <= 1 ? <Stack direction="row" spacing={0}>
          <IconButton aria-label="delete" color="primary"  onClick={handleButtonAddPosGrado1}>
          <AddCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center", paddingRight: "40px"}}
              >Añadir nueva formación
            </Typography>
            <IconButton aria-label="delete" color="primary"  onClick={handleButtonRemovePosGrado1}>
          <RemoveCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center"}}
              >Eliminar formación
            </Typography>
            
        </Stack>:
        <Divider sx={{marginTop: "20px", marginBottom: "10px"}} ></Divider>}


        {currentNumPosGrados > 1 &&
          <div>
          <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
          
                fullWidth
                id="carrera6"
                label="Estudios"
                name="carrera6"
                autoComplete="carrera6"
            
                onChange={handleChangeCarrera6}
                defaultValue={carrera6}
              />

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
          
                fullWidth
                id="uni6"
                label="Universidad"
                name="uni6"
                autoComplete="uni6"
            
                onChange={handleChangeUni6}
                defaultValue={uni6}
              />

<Grid container component="main" columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
         
                fullWidth
                id="fechaInicio6"
                label="Fecha de inicio"
                name="fechaInicio6"
                autoComplete="fechaInicio6"
             
                onChange={handleChangeFechaInicio6}
                defaultValue={fechaInicio6}
              />
        </Grid>

        <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
         
                fullWidth
                id="fechaFin6"
                label="Fecha de fin"
                name="fechaFin6"
                autoComplete="fechaFin6"
            
                onChange={handleChangeFechaFin6}
                defaultValue={fechaFin6}
              />
        </Grid>
        </Grid>
        <TextField   
                sx={{width: '28ch', input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
          
                id="notaMedia6"
                label="Nota media"
                name="notaMedia6"
                autoComplete="notaMedia6"
            
                onChange={handleChangeNotaMedia6}
                defaultValue={notaMedia6}
              />
        
        {currentNumPosGrados === 2 ? <Stack direction="row" spacing={0}>
          <IconButton aria-label="delete" color="primary"  onClick={handleButtonAddPosGrado1}>
          <AddCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center", paddingRight: "40px"}}
              >Añadir nueva formación
            </Typography>
            <IconButton aria-label="delete" color="primary"  onClick={handleButtonRemovePosGrado2}>
          <RemoveCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center"}}
              >Eliminar formación
            </Typography>
            
        </Stack>:
        <Divider sx={{marginTop: "20px", marginBottom: "10px"}} ></Divider>}

        </div>
          
          }

{currentNumPosGrados >= 3 &&
          <div>
          <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
         
                fullWidth
                id="carrera7"
                label="Estudios"
                name="carrera7"
                autoComplete="carrera7"
          
                onChange={handleChangeCarrera7}
                defaultValue={carrera7}
              />

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
  
                fullWidth
                id="uni7"
                label="Universidad"
                name="uni7"
                autoComplete="uni7"
          
                onChange={handleChangeUni7}
                defaultValue={uni7}
              />

<Grid container component="main" columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
        
                fullWidth
                id="fechaInicio7"
                label="Fecha de inicio"
                name="fechaInicio7"
                autoComplete="fechaInicio7"
              
                onChange={handleChangeFechaInicio7}
                defaultValue={fechaInicio7}
              />
        </Grid>

        <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
   
                fullWidth
                id="fechaFin7"
                label="Fecha de fin"
                name="fechaFin7"
                autoComplete="fechaFin7"
          
                onChange={handleChangeFechaFin7}
                defaultValue={fechaFin7}
              />
        </Grid>
        </Grid>
        <TextField   
                sx={{width: '28ch', input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
         
                id="notaMedia7"
                label="Nota media"
                name="notaMedia7"
                autoComplete="notaMedia7"
           
                onChange={handleChangeNotaMedia7}
                defaultValue={notaMedia7}
              />
        
            {currentNumPosGrados === 3 ? <Stack direction="row" spacing={0}>
          <IconButton aria-label="delete" color="primary"  onClick={handleButtonAddPosGrado1}>
          <AddCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center", paddingRight: "40px"}}
              >Añadir nueva formación
            </Typography>
            <IconButton aria-label="delete" color="primary"  onClick={handleButtonRemovePosGrado3}>
          <RemoveCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center"}}
              >Eliminar formación
            </Typography>
            
        </Stack>:
        <Divider sx={{marginTop: "20px", marginBottom: "10px"}} ></Divider>}
       </div>
          
          }

{currentNumPosGrados >= 4 &&
          <div>
          <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
      
                fullWidth
                id="carrera8"
                label="Estudios"
                name="carrera8"
                autoComplete="carrera8"
            
                onChange={handleChangeCarrera8}
                defaultValue={carrera8}
              />

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
  
                fullWidth
                id="uni8"
                label="Universidad"
                name="uni8"
                autoComplete="uni8"
          
                onChange={handleChangeUni8}
                defaultValue={uni8}
              />

<Grid container component="main" columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
             
                fullWidth
                id="fechaInicio8"
                label="Fecha de inicio"
                name="fechaInicio8"
                autoComplete="fechaInicio8"
             
                onChange={handleChangeFechaInicio8}
                defaultValue={fechaInicio8}
              />
        </Grid>

        <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
         
                fullWidth
                id="fechaFin8"
                label="Fecha de fin"
                name="fechaFin8"
                autoComplete="fechaFin8"
            
                onChange={handleChangeFechaFin8}
                defaultValue={fechaFin8}
              />
        </Grid>
        </Grid>
        <TextField   
                sx={{width: '28ch', input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
     
                id="notaMedia8"
                label="Nota media en la carrera"
                name="notaMedia8"
                autoComplete="notaMedia8"
           
                onChange={handleChangeNotaMedia8}
                defaultValue={notaMedia8}
              />
        
            {currentNumPosGrados === 4 ? <Stack direction="row" spacing={0}>
          <IconButton aria-label="delete" color="primary"  onClick={handleButtonAddPosGrado1}>
          <AddCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center", paddingRight: "40px"}}
              >Añadir nueva formación
            </Typography>
            <IconButton aria-label="delete" color="primary"  onClick={handleButtonRemovePosGrado4}>
          <RemoveCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center"}}
              >Eliminar formación
            </Typography>
            
        </Stack>:
        <Divider sx={{marginTop: "20px", marginBottom: "10px"}} ></Divider>}
       </div>
          
          }


<Divider sx={{marginTop: "20px"}} >
            <Chip label="Experiencia" sx={{color: "white", bgcolor: "rgba(43, 117, 177, 0.9)"}} />
            </Divider>

<Stack sx={{ }} spacing={2}>
      <Typography variant="body1" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "40px", textAlign: "center", lineHeight: "0px"}}
              >Introduce tus experiencias laborales
            </Typography>
            <Typography variant="body2" color = "rgba(43, 117, 177, 0.9)"
              sx={{textAlign: "center", paddingBottom: "30px"}}
              >(comenzando por la más reciente / actual)
            </Typography>
            
              </Stack>

              <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
      
                fullWidth
                id="trabajo1"
                label="Posición"
                name="trabajo1"
                autoComplete="trabajo1"
              
                onChange={handleChangeTrabajo1}
                defaultValue={trabajo1}
              />

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="empresa1"
                label="Despacho / Empresa"
                name="empresa1"
                autoComplete="empresa1"
            
                onChange={handleChangeEmpresa1}
                defaultValue={empresa1}
              />

<Grid container component="main" columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
       
                fullWidth
                id="fechaInicioT1"
                label="Fecha de inicio"
                name="fechaInicioT1"
                autoComplete="fechaInicioT1"
            
                onChange={handleChangeFechaInicioT1}
                defaultValue={fechaInicioT1}
              />
        </Grid>

        <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{marginBottom: "20px"}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
   
                fullWidth
                id="fechaFinT1"
                label="Fecha de fin"
                name="fechaFinT1"
                autoComplete="fechaFinT1"
             
                onChange={handleChangeFechaFinT1}
                defaultValue={fechaFinT1}
              />
        </Grid>
        </Grid>
        <CKEditor  editor={ClassicEditor} data={currentDescripcion1} onChange={(event, editor) => {
  const data = editor.getData()
  setCurrentDescripcion1(data)
}}
/> 

        {currentNumTrabajos <= 1 ? <Stack direction="row" spacing={0}>
          <IconButton aria-label="delete" color="primary"  onClick={handleButtonAddTrabajo}>
          <AddCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center", paddingRight: "40px"}}
              >Añadir nuevo trabajo
            </Typography>
            <IconButton aria-label="delete" color="primary"  onClick={handleButtonRemoveTrabajo1}>
          <RemoveCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center"}}
              >Eliminar trabajo
            </Typography>
            
        </Stack>:
        <Divider sx={{marginTop: "20px", marginBottom: "10px"}} ></Divider>}


{currentNumTrabajos > 1 &&
          <div>

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
            
                fullWidth
                id="trabajo2"
                label="Posición"
                name="trabajo2"
                autoComplete="trabajo2"
              
                onChange={handleChangeTrabajo2}
                defaultValue={trabajo2}
              />

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
        
                fullWidth
                id="empresa2"
                label="Despacho / Empresa"
                name="empresa2"
                autoComplete="empresa2"
            
                onChange={handleChangeEmpresa2}
                defaultValue={empresa2}
              />

<Grid container component="main" columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
   
                fullWidth
                id="fechaInicioT2"
                label="Fecha de inicio"
                name="fechaInicioT2"
                autoComplete="fechaInicioT2"
            
                onChange={handleChangeFechaInicioT2}
                defaultValue={fechaInicioT2}
              />
        </Grid>

        <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{marginBottom: "20px"}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
         
                fullWidth
                id="fechaFinT2"
                label="Fecha de fin"
                name="fechaFinT2"
                autoComplete="fechaFinT2"
             
                onChange={handleChangeFechaFinT2}
                defaultValue={fechaFinT2}
              />
        </Grid>
        </Grid>
        <CKEditor  editor={ClassicEditor} data={currentDescripcion2} onChange={(event, editor) => {
  const data = editor.getData()
  setCurrentDescripcion2(data)
}}
/> 

        {currentNumTrabajos === 2 ? <Stack direction="row" spacing={0}>
          <IconButton aria-label="delete" color="primary"  onClick={handleButtonAddTrabajo}>
          <AddCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center", paddingRight: "40px"}}
              >Añadir nuevo trabajo
            </Typography>
            <IconButton aria-label="delete" color="primary"  onClick={handleButtonRemoveTrabajo2}>
          <RemoveCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center"}}
              >Eliminar trabajo
            </Typography>
            
        </Stack>:
        <Divider sx={{marginTop: "20px", marginBottom: "10px"}} ></Divider>}
        </div>
          }  

{currentNumTrabajos > 2 &&
          <div>

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
        
                fullWidth
                id="trabajo3"
                label="Posición"
                name="trabajo3"
                autoComplete="trabajo3"
              
                onChange={handleChangeTrabajo3}
                defaultValue={trabajo3}
              />

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
          
                fullWidth
                id="empresa3"
                label="Despacho / Empresa"
                name="empresa3"
                autoComplete="empresa3"
            
                onChange={handleChangeEmpresa3}
                defaultValue={empresa3}
              />

<Grid container component="main" columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
        
                fullWidth
                id="fechaInicioT3"
                label="Fecha de inicio"
                name="fechaInicioT3"
                autoComplete="fechaInicioT3"
            
                onChange={handleChangeFechaInicioT3}
                defaultValue={fechaInicioT3}
              />
        </Grid>

        <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{marginBottom: "20px"}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
           
                fullWidth
                id="fechaFinT3"
                label="Fecha de fin"
                name="fechaFinT3"
                autoComplete="fechaFinT3"
             
                onChange={handleChangeFechaFinT3}
                defaultValue={fechaFinT3}
              />
        </Grid>
        </Grid>
        <CKEditor  editor={ClassicEditor} data={currentDescripcion3} onChange={(event, editor) => {
  const data = editor.getData()
  setCurrentDescripcion3(data)
}}
/> 

        {currentNumTrabajos === 3 ? <Stack direction="row" spacing={0}>
          <IconButton aria-label="delete" color="primary"  onClick={handleButtonAddTrabajo}>
          <AddCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center", paddingRight: "40px"}}
              >Añadir nuevo trabajo
            </Typography>
            <IconButton aria-label="delete" color="primary"  onClick={handleButtonRemoveTrabajo3}>
          <RemoveCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center"}}
              >Eliminar trabajo
            </Typography>
            
        </Stack>:
        <Divider sx={{marginTop: "20px", marginBottom: "10px"}} ></Divider>}
        </div>
          }  



{currentNumTrabajos > 3 &&
          <div>

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
            
                fullWidth
                id="trabajo4"
                label="Posición"
                name="trabajo4"
                autoComplete="trabajo4"
              
                onChange={handleChangeTrabajo4}
                defaultValue={trabajo4}
              />

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
        
                fullWidth
                id="empresa4"
                label="Despacho / Empresa"
                name="empresa4"
                autoComplete="empresa4"
            
                onChange={handleChangeEmpresa4}
                defaultValue={empresa4}
              />

<Grid container component="main" columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
        
                fullWidth
                id="fechaInicioT4"
                label="Fecha de inicio"
                name="fechaInicioT4"
                autoComplete="fechaInicioT4"
            
                onChange={handleChangeFechaInicioT4}
                defaultValue={fechaInicioT4}
              />
        </Grid>

        <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{marginBottom: "20px"}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
          
                fullWidth
                id="fechaFinT4"
                label="Fecha de fin"
                name="fechaFinT4"
                autoComplete="fechaFinT4"
             
                onChange={handleChangeFechaFinT4}
                defaultValue={fechaFinT4}
              />
        </Grid>
        </Grid>
        <CKEditor  editor={ClassicEditor} data={currentDescripcion4} onChange={(event, editor) => {
  const data = editor.getData()
  setCurrentDescripcion4(data)
}}
/> 

        {currentNumTrabajos === 4 ? <Stack direction="row" spacing={0}>
          <IconButton aria-label="delete" color="primary"  onClick={handleButtonAddTrabajo}>
          <AddCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center", paddingRight: "40px"}}
              >Añadir nuevo trabajo
            </Typography>
            <IconButton aria-label="delete" color="primary"  onClick={handleButtonRemoveTrabajo4}>
          <RemoveCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center"}}
              >Eliminar trabajo
            </Typography>
            
        </Stack>:
        <Divider sx={{marginTop: "20px", marginBottom: "10px"}} ></Divider>}
        </div>
          }  

{currentNumTrabajos > 4 &&
          <div>

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
            
                fullWidth
                id="trabajo5"
                label="Posición"
                name="trabajo5"
                autoComplete="trabajo5"
              
                onChange={handleChangeTrabajo5}
                defaultValue={trabajo5}
              />

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
          
                fullWidth
                id="empresa5"
                label="Despacho / Empresa"
                name="empresa5"
                autoComplete="empresa5"
            
                onChange={handleChangeEmpresa5}
                defaultValue={empresa5}
              />

<Grid container component="main" columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
          
                fullWidth
                id="fechaInicioT5"
                label="Fecha de inicio"
                name="fechaInicioT5"
                autoComplete="fechaInicioT5"
            
                onChange={handleChangeFechaInicioT5}
                defaultValue={fechaInicioT5}
              />
        </Grid>

        <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{marginBottom: "20px"}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
         
                fullWidth
                id="fechaFinT5"
                label="Fecha de fin"
                name="fechaFinT5"
                autoComplete="fechaFinT5"
             
                onChange={handleChangeFechaFinT5}
                defaultValue={fechaFinT5}
              />
        </Grid>
        </Grid>
        <CKEditor  editor={ClassicEditor} data={currentDescripcion5} onChange={(event, editor) => {
  const data = editor.getData()
  setCurrentDescripcion5(data)
}}
/> 

        {currentNumTrabajos === 5 ? <Stack direction="row" spacing={0}>
          <IconButton aria-label="delete" color="primary"  onClick={handleButtonAddTrabajo}>
          <AddCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center", paddingRight: "40px"}}
              >Añadir nuevo trabajo
            </Typography>
            <IconButton aria-label="delete" color="primary"  onClick={handleButtonRemoveTrabajo5}>
          <RemoveCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center"}}
              >Eliminar trabajo
            </Typography>
            
        </Stack>:
        <Divider sx={{marginTop: "20px", marginBottom: "10px"}} ></Divider>}
        </div>
          }  

{currentNumTrabajos > 5 &&
          <div>

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
             
                fullWidth
                id="trabajo6"
                label="Posición"
                name="trabajo6"
                autoComplete="trabajo6"
              
                onChange={handleChangeTrabajo6}
                defaultValue={trabajo6}
              />

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
           
                fullWidth
                id="empresa6"
                label="Despacho / Empresa"
                name="empresa6"
                autoComplete="empresa6"
            
                onChange={handleChangeEmpresa6}
                defaultValue={empresa6}
              />

<Grid container component="main" columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
           
                fullWidth
                id="fechaInicioT6"
                label="Fecha de inicio"
                name="fechaInicioT6"
                autoComplete="fechaInicioT6"
            
                onChange={handleChangeFechaInicioT6}
                defaultValue={fechaInicioT6}
              />
        </Grid>

        <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{marginBottom: "20px"}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
         
                fullWidth
                id="fechaFinT6"
                label="Fecha de fin"
                name="fechaFinT6"
                autoComplete="fechaFinT6"
             
                onChange={handleChangeFechaFinT6}
                defaultValue={fechaFinT6}
              />
        </Grid>
        </Grid>
        <CKEditor  editor={ClassicEditor} data={currentDescripcion6} onChange={(event, editor) => {
  const data = editor.getData()
  setCurrentDescripcion6(data)
}}
/> 

        {currentNumTrabajos === 6 ? <Stack direction="row" spacing={0}>
          <IconButton aria-label="delete" color="primary"  onClick={handleButtonAddTrabajo}>
          <AddCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center", paddingRight: "40px"}}
              >Añadir nuevo trabajo
            </Typography>
            <IconButton aria-label="delete" color="primary"  onClick={handleButtonRemoveTrabajo5}>
          <RemoveCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center"}}
              >Eliminar trabajo
            </Typography>
            
        </Stack>:
        <Divider sx={{marginTop: "20px", marginBottom: "10px"}} ></Divider>}
        </div>
          }  

{currentNumTrabajos > 6 &&
          <div>

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
            
                fullWidth
                id="trabajo7"
                label="Posición"
                name="trabajo7"
                autoComplete="trabajo7"
              
                onChange={handleChangeTrabajo7}
                defaultValue={trabajo7}
              />

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
      
                fullWidth
                id="empresa7"
                label="Despacho / Empresa"
                name="empresa7"
                autoComplete="empresa7"
            
                onChange={handleChangeEmpresa7}
                defaultValue={empresa7}
              />

<Grid container component="main" columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
        
                fullWidth
                id="fechaInicioT7"
                label="Fecha de inicio"
                name="fechaInicioT7"
                autoComplete="fechaInicioT7"
            
                onChange={handleChangeFechaInicioT7}
                defaultValue={fechaInicioT7}
              />
        </Grid>

        <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{marginBottom: "20px"}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
        
                fullWidth
                id="fechaFinT7"
                label="Fecha de fin"
                name="fechaFinT7"
                autoComplete="fechaFinT7"
             
                onChange={handleChangeFechaFinT7}
                defaultValue={fechaFinT7}
              />
        </Grid>
        </Grid>
        <CKEditor  editor={ClassicEditor} data={currentDescripcion7} onChange={(event, editor) => {
  const data = editor.getData()
  setCurrentDescripcion7(data)
}}
/> 

        {currentNumTrabajos === 6 ? <Stack direction="row" spacing={0}>
          <IconButton aria-label="delete" color="primary"  onClick={handleButtonAddTrabajo}>
          <AddCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center", paddingRight: "40px"}}
              >Añadir nuevo trabajo
            </Typography>
            <IconButton aria-label="delete" color="primary"  onClick={handleButtonRemoveTrabajo7}>
          <RemoveCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center"}}
              >Eliminar trabajo
            </Typography>
            
        </Stack>:
        <Divider sx={{marginTop: "20px", marginBottom: "10px"}} ></Divider>}
        </div>
          }  

{currentNumTrabajos > 7 &&
          <div>

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
       
                fullWidth
                id="trabajo8"
                label="Posición"
                name="trabajo8"
                autoComplete="trabajo8"
              
                onChange={handleChangeTrabajo8}
                defaultValue={trabajo8}
              />

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
        
                fullWidth
                id="empresa8"
                label="Despacho / Empresa"
                name="empresa8"
                autoComplete="empresa8"
            
                onChange={handleChangeEmpresa8}
                defaultValue={empresa8}
              />

<Grid container component="main" columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
       
                fullWidth
                id="fechaInicioT8"
                label="Fecha de inicio"
                name="fechaInicioT8"
                autoComplete="fechaInicioT8"
            
                onChange={handleChangeFechaInicioT8}
                defaultValue={fechaInicioT8}
              />
        </Grid>

        <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{marginBottom: "20px"}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
     
                fullWidth
                id="fechaFinT8"
                label="Fecha de fin"
                name="fechaFinT8"
                autoComplete="fechaFinT8"
             
                onChange={handleChangeFechaFinT8}
                defaultValue={fechaFinT8}
              />
        </Grid>
        </Grid>
        <CKEditor  editor={ClassicEditor} data={currentDescripcion8} onChange={(event, editor) => {
  const data = editor.getData()
  setCurrentDescripcion8(data)
}}
/> 

        {currentNumTrabajos === 7 ? <Stack direction="row" spacing={0}>
          <IconButton aria-label="delete" color="primary"  onClick={handleButtonAddTrabajo}>
          <AddCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center", paddingRight: "40px"}}
              >Añadir nuevo trabajo
            </Typography>
            <IconButton aria-label="delete" color="primary"  onClick={handleButtonRemoveTrabajo8}>
          <RemoveCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center"}}
              >Eliminar trabajo
            </Typography>
            
        </Stack>:
        <Divider sx={{marginTop: "20px", marginBottom: "10px"}} ></Divider>}
        </div>
          }  
      <Typography variant="body1" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "50px", textAlign: "center"}}
              >Indica tus áreas de práctica
            </Typography>
<Autocomplete
          value={currentArea}
          multiple
          sx={{paddingTop: "20px", paddingBottom: "50px"}}
          id="tags-standard"
          options={listareas}
          getOptionLabel={(option) => {
            if (option.hasOwnProperty('title')) {
              return option.title;
            }
            return option;
          }}
          onChange={(e, value)=> setCurrentArea(value)}
          //defaultValue={currentArea}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Áreas de práctica"
              placeholder="Áreas de práctica"
  

            />
          )}
        />
      <Typography variant="body1" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "30px", textAlign: "center"}}
              >Indica tus años de experiencia
            </Typography>
<Slider
        getAriaLabel={() => 'Años de experiencia'}
        value={currentAnos}
        onChange={handleChangeExperiencia}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
        step={1}
        min={0}
        max={20}
        marks={anosExperiencia}
      />


<Divider sx={{marginTop: "40px", marginBottom: "15px"}} >
            <Chip label="Idiomas" sx={{color: "white", bgcolor: "rgba(43, 117, 177, 0.9)"}} />
            </Divider>

            <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">Castellano</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={currentCastellano}
        defaultValue={castellano}
        onChange={handleChangeCastellano}
      >
        <FormControlLabel value="NA" control={<Radio />} label="N/A" />
        <FormControlLabel value="Básico" control={<Radio />} label="Básico" />
        <FormControlLabel value="Intermedio" control={<Radio />} label="Intermedio" />
        <FormControlLabel value="Avanzado" control={<Radio />} label="Avanzado" />
        <FormControlLabel value="Nativo" control={<Radio />} label="Nativo" />
      </RadioGroup>
    </FormControl>


    <FormControl>
      <FormLabel id="demo-row-radio-buttons-group-label" sx={{marginTop: "20px"}}>Inglés</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={currentIngles}
        onChange={handleChangeIngles}
        defaultValue={ingles}
      >
        <FormControlLabel value="NA" control={<Radio />} label="N/A" />
        <FormControlLabel value="Básico" control={<Radio />} label="Básico" />
        <FormControlLabel value="Intermedio" control={<Radio />} label="Intermedio" />
        <FormControlLabel value="Avanzado" control={<Radio />} label="Avanzado" />
        <FormControlLabel value="Nativo" control={<Radio />} label="Nativo" />
      </RadioGroup>
    </FormControl>

    <Stack direction="column" sx={{marginTop: "20px" }}>
    <TextField   
                sx={{width: '28ch', input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
          
                id="idioma1"
                label="Idioma adicional"
                name="idioma1"
                autoComplete="idioma1"
                defaultValue={idioma1}
             
                onChange={handleChangeIdioma1}
              />
       <FormControl>
      <FormLabel id="demo-row-radio-buttons-group-label" sx={{marginTop: "20px"}}></FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={currentNivelIdioma1}
        onChange={handleChangeNivelIdioma1}
        defaultValue={nivelIdioma1}
      >
        <FormControlLabel value="NA" control={<Radio />} label="N/A" />
        <FormControlLabel value="Básico" control={<Radio />} label="Básico" />
        <FormControlLabel value="Intermedio" control={<Radio />} label="Intermedio" />
        <FormControlLabel value="Avanzado" control={<Radio />} label="Avanzado" />
        <FormControlLabel value="Nativo" control={<Radio />} label="Nativo" />
      </RadioGroup>
    </FormControl>
    </Stack>

    <Stack direction="column" sx={{marginTop: "20px" }}>
    <TextField   
                sx={{width: '28ch', input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
          
                id="idioma2"
                label="Idioma adicional"
                name="idioma2"
                autoComplete="idioma2"
                defaultValue={idioma2}
             
                onChange={handleChangeIdioma2}
              />
       <FormControl>
      <FormLabel id="demo-row-radio-buttons-group-label" sx={{marginTop: "20px"}}></FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={currentNivelIdioma2}
        onChange={handleChangeNivelIdioma2}
        defaultValue={nivelIdioma2}
      >
        <FormControlLabel value="NA" control={<Radio />} label="N/A" />
        <FormControlLabel value="Básico" control={<Radio />} label="Básico" />
        <FormControlLabel value="Intermedio" control={<Radio />} label="Intermedio" />
        <FormControlLabel value="Avanzado" control={<Radio />} label="Avanzado" />
        <FormControlLabel value="Nativo" control={<Radio />} label="Nativo" />
      </RadioGroup>
    </FormControl>
    </Stack>

    <Divider sx={{marginTop: "40px"}} >
            <Chip label="Extras" sx={{color: "white", bgcolor: "rgba(43, 117, 177, 0.9)"}} />
            </Divider>
            <Stack sx={{ paddingTop: "20px" }} spacing={2}>
      <Typography variant="body1" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center"}}
              >Incluye una breve presentación
            </Typography>
            
              </Stack>
    <CKEditor  editor={ClassicEditor} data={currentPresentacion} onChange={(event, editor) => {
  const data = editor.getData()
  setCurrentPresentacion(data)
}}
/> 

<Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, backgroundColor: "rgba(43, 117, 177, 0.9)", color: "white"}}

              >
                {buttonText}
              </Button>
              <Button href="./dashboard"
                
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, backgroundColor: "rgba(43, 117, 177, 0.9)", color: "white"}}

              >
                Volver al escritorio (guarda antes los cambios)
              </Button>
            </Box>
      
          </Box>

</Grid>
 {currentVisibilidad === "Perfil oculto" && 
   <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{paddingTop: "30px", paddingLeft: "5%", paddingRight: "5%"}}>
   <Stack sx={{ paddingTop: "20px" }} spacing={2}>
   <Typography variant="h5" color = "rgba(43, 117, 177, 0.9)"
               sx={{paddingTop: "20px", textAlign: "center"}}
               >Tu perfil está oculto y no pueden verlo ni empresas ni headhunters. Usa este modo solo si no quieres recibir ofertas.
             </Typography>
    </Stack>
    </Grid>
 }
       {currentVisibilidad !== "Perfil oculto" &&
      <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{borderLeft: "1px solid rgba(43, 117, 177, 0.9)"}}>
      <Stack sx={{ paddingTop: "20px" }} spacing={2}>
        
       <Typography variant="h5" color = "rgba(43, 117, 177, 0.9)"
               sx={{paddingTop: "20px", textAlign: "center"}}
               >Así te ven empresas, despachos y headhunters
             </Typography>
             <Typography variant="p" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingBottom: "40px", textAlign: "center"}}
              >¡Tranquil@! Solo headhunters y profesionales de RRHH podrán ver tu perfil. Tu actual empleador no podrá ver tu perfil
            </Typography>
               </Stack>
               <Divider >
            
            </Divider>
               <Stack sx={{ paddingTop: "20px" }} spacing={2}>
        
       {currentVisibilidad === "Perfil abierto (datos visibles para despachos, empresas y headhunters)" && <div> <Typography variant="h5" color = "grey"
                sx={{paddingTop: "20px", textAlign: "center"}}
                >{currentNombre}
              </Typography>
              <Grid container component="main">
      <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
       
      <Stack sx={{ width: '100%'}} spacing={2}>
      {currentTelefono && <Typography variant="subtitle1" 
              sx={{paddingTop: "10px", paddingRight: "50px", color: "grey", textAlign: "right"}}
              >Teléfono: {currentTelefono}
            </Typography>}
              </Stack>
      </Grid>
      <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
       
       <Stack sx={{ width: '100%', paddingBottom: "20px"}} spacing={2}>
       {currentEmail && <Typography variant="subtitle1" 
               sx={{paddingTop: "10px", paddingLeft: "10px", color: "grey", textAlign: "left"}}
               >E-mail: {currentEmail}
             </Typography>}
               </Stack>
       </Grid>
     </Grid></div>
              }
              {currentVisibilidad === "Perfil anónimo (datos identificativos ocultos)" && <div><Typography variant="h5" color = "grey"
                sx={{paddingTop: "20px", textAlign: "center"}}
                >Perfil anónimo
              </Typography>
              <Typography variant="subtitle1" color = "grey"
              sx={{paddingTop: "20px", textAlign: "center"}}
              >Contacta de forma privada con el candidato a través de Jobs&Law
            </Typography>
              
              </div>}

     {currentPresentacion && 
     <Stack sx={{alignItems: "center", textAlign: "center", paddingBottom: "30px", paddingLeft: "40px", paddingRight:"40px"}} spacing={2}>
      <Typography variant="paragraph" color = "rgba(43, 117, 177, 0.9)"
              sx={{ textAlign: "center", lineHeight:"22px", fontSize: "14px"}}>
                {parse(currentPresentacion)}
            </Typography>
              </Stack>}

     <Divider sx={{marginTop: "30px"}} >
            <Chip label="Formación" sx={{color: "white", bgcolor: "rgba(43, 117, 177, 0.9)"}} />
            </Divider>
            <Grid container component="main">
      
      <Grid item xs={1} sm={1} md={1} component={Paper} elevation={0} square sx={{}}>
      </Grid>
      <Grid item xs={10} sm={10} md={10} component={Paper} elevation={0} square sx={{}}>
       
      <Stack sx={{ width: '100%'}} spacing={2}>
      {currentCarrera1 && <Typography variant="p" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", textAlign: "center", fontWeight: "bold"}}
              >Estudios de grado/licenciatura:
            </Typography>}
              </Stack>
      </Grid>
     </Grid>
          

     <Grid container component="main">
      
      <Grid item xs={1} sm={1} md={1} component={Paper} elevation={0} square sx={{}}>
      </Grid>
      <Grid item xs={10} sm={10} md={10} component={Paper} elevation={0} square sx={{marginBottom: "20px"}}>
       
      <Stack sx={{textAlign: "center", width: '100%'}} spacing={2}>
      {currentFechaInicio1 && <Typography variant="paragraph" color = "grey"
              sx={{textAlign: "center", lineHeight:"5px", fontSize: "14px"}}
              >{currentFechaInicio1} / {currentFechaFin1}
            </Typography>}
     {currentCarrera1 && <Typography variant="paragraph" color = "grey"
              sx={{textAlign: "center", lineHeight:"10px", fontWeight: "bold"}}
              >{currentCarrera1}
            </Typography>}
      {currentUni1 && <Typography variant="paragraph" color = "grey"
              sx={{ textAlign: "center", lineHeight:"5px"}}
              >{currentUni1}
            </Typography>}
            {currentNotaMedia1 && <Typography variant="paragraph" color = "grey"
              sx={{ textAlign: "center", lineHeight:"5px", fontSize: "14px"}}
              >Nota media: {currentNotaMedia1}
            </Typography>}
              </Stack>

              {currentCarrera2 && <Stack sx={{textAlign: "center", width: '100%', paddingTop: "30px"}} spacing={2}>
      {currentFechaInicio2 && <Typography variant="paragraph" color = "grey"
              sx={{paddingTop: "0px", textAlign: "center", lineHeight:"5px", fontSize: "14px"}}
              >{currentFechaInicio2} / {currentFechaFin2}
            </Typography>}
     {currentCarrera2 && <Typography variant="paragraph" color = "grey"
              sx={{textAlign: "center", lineHeight:"10px", fontWeight: "bold"}}
              >{currentCarrera2}
            </Typography>}
      {currentUni2 && <Typography variant="paragraph" color = "grey"
              sx={{ textAlign: "center", lineHeight:"5px"}}
              >{currentUni2}
            </Typography>}
            {currentNotaMedia2 && <Typography variant="paragraph" color = "grey"
              sx={{ textAlign: "center", lineHeight:"5px", fontSize: "14px"}}
              >Nota media: {currentNotaMedia2}
            </Typography>}
              </Stack>}

              {currentCarrera3 &&<Stack sx={{textAlign: "center", width: '100%', paddingTop: "30px"}} spacing={2}>
      {currentFechaInicio3 && <Typography variant="paragraph" color = "grey"
              sx={{paddingTop: "0px", textAlign: "center", lineHeight:"5px", fontSize: "14px"}}
              >{currentFechaInicio3} / {currentFechaFin3}
            </Typography>}
     {currentCarrera3 && <Typography variant="paragraph" color = "grey"
              sx={{textAlign: "center", lineHeight:"10px", fontWeight: "bold"}}
              >{currentCarrera3}
            </Typography>}
      {currentUni3 && <Typography variant="paragraph" color = "grey"
              sx={{ textAlign: "center", lineHeight:"5px"}}
              >{currentUni3}
            </Typography>}
            {currentNotaMedia3 && <Typography variant="paragraph" color = "grey"
              sx={{ textAlign: "center", lineHeight:"5px", fontSize: "14px"}}
              >Nota media: {currentNotaMedia3}
            </Typography>}
              </Stack>}

              {currentCarrera4 && <Stack sx={{textAlign: "center", width: '100%', paddingTop: "30px", paddingBottom: "30px"}} spacing={2}>
      {currentFechaInicio4 && <Typography variant="paragraph" color = "grey"
              sx={{paddingTop: "0px", textAlign: "center", lineHeight:"5px", fontSize: "14px"}}
              >{currentFechaInicio4} / {currentFechaFin4}
            </Typography>}
     {currentCarrera4 && <Typography variant="paragraph" color = "grey"
              sx={{textAlign: "center", lineHeight:"10px", fontWeight: "bold"}}
              >{currentCarrera4}
            </Typography>}
      {currentUni4 && <Typography variant="paragraph" color = "grey"
              sx={{ textAlign: "center", lineHeight:"5px"}}
              >{currentUni4}
            </Typography>}
            {currentNotaMedia4 && <Typography variant="paragraph" color = "grey"
              sx={{ textAlign: "center", lineHeight:"5px", fontSize: "14px"}}
              >Nota media: {currentNotaMedia4}
            </Typography>}
              </Stack>}
      </Grid>

      
     </Grid>
     {currentCarrera5 && <div> <Grid container component="main">
      
      <Grid item xs={1} sm={1} md={1} component={Paper} elevation={0} square sx={{}}>
      </Grid>
      <Grid item xs={10} sm={10} md={10} component={Paper} elevation={0} square sx={{}}>
       
     <Stack sx={{ width: '100%', paddingTop: "30px"}} spacing={2}>
      <Typography variant="p" color = "rgba(43, 117, 177, 0.9)"
              sx={{textAlign: "center", fontWeight: "bold"}}
              >Estudios de posgrado:
            </Typography>
              </Stack>
      </Grid>
     </Grid>
          

     <Grid container component="main">
      
      <Grid item xs={1} sm={1} md={1} component={Paper} elevation={0} square sx={{}}>
      </Grid>
      <Grid item xs={10} sm={10} md={10} component={Paper} elevation={0} square sx={{}}>
       
      {currentCarrera5 && <Stack sx={{textAlign: "center", width: '100%', paddingTop: "30px", paddingBottom: "30px"}} spacing={2}>
      {currentFechaInicio5 && <Typography variant="paragraph" color = "grey"
              sx={{paddingTop: "0px", textAlign: "center", lineHeight:"5px", fontSize: "14px"}}
              >{currentFechaInicio5} / {currentFechaFin5}
            </Typography>}
     {currentCarrera5 && <Typography variant="paragraph" color = "grey"
              sx={{textAlign: "center", lineHeight:"10px", fontWeight: "bold"}}
              >{currentCarrera5}
            </Typography>}
      {currentUni5 && <Typography variant="paragraph" color = "grey"
              sx={{ textAlign: "center", lineHeight:"5px"}}
              >{currentUni5}
            </Typography>}
            {currentNotaMedia5 && <Typography variant="paragraph" color = "grey"
              sx={{ textAlign: "center", lineHeight:"5px", fontSize: "14px"}}
              >Nota media: {currentNotaMedia5}
            </Typography>}
              </Stack>}

              {currentCarrera6 && <Stack sx={{textAlign: "center", width: '100%', paddingTop: "30px", paddingBottom: "30px"}} spacing={2}>
      {currentFechaInicio6 && <Typography variant="paragraph" color = "grey"
              sx={{paddingTop: "0px", textAlign: "center", lineHeight:"5px", fontSize: "14px"}}
              >{currentFechaInicio6} / {currentFechaFin6}
            </Typography>}
     {currentCarrera6 && <Typography variant="paragraph" color = "grey"
              sx={{textAlign: "center", lineHeight:"10px", fontWeight: "bold"}}
              >{currentCarrera6}
            </Typography>}
      {currentUni6 && <Typography variant="paragraph" color = "grey"
              sx={{ textAlign: "center", lineHeight:"5px"}}
              >{currentUni6}
            </Typography>}
            {currentNotaMedia6 && <Typography variant="paragraph" color = "grey"
              sx={{ textAlign: "center", lineHeight:"5px", fontSize: "14px"}}
              >Nota media: {currentNotaMedia6}
            </Typography>}
              </Stack>}

              {currentCarrera7 && <Stack sx={{ textAlign: "center", width: '100%', paddingTop: "30px", paddingBottom: "30px"}} spacing={2}>
      {currentFechaInicio7 && <Typography variant="paragraph" color = "grey"
              sx={{paddingTop: "0px", textAlign: "center", lineHeight:"5px", fontSize: "14px"}}
              >{currentFechaInicio7} / {currentFechaFin7}
            </Typography>}
     {currentCarrera7 && <Typography variant="paragraph" color = "grey"
              sx={{textAlign: "center", lineHeight:"10px", fontWeight: "bold"}}
              >{currentCarrera7}
            </Typography>}
      {currentUni7 && <Typography variant="paragraph" color = "grey"
              sx={{ textAlign: "center", lineHeight:"5px"}}
              >{currentUni7}
            </Typography>}
            {currentNotaMedia7 && <Typography variant="paragraph" color = "grey"
              sx={{ textAlign: "center", lineHeight:"5px", fontSize: "14px"}}
              >Nota media: {currentNotaMedia7}
            </Typography>}
              </Stack>}

             {currentCarrera8 && <Stack sx={{textAlign: "center", width: '100%', paddingTop: "30px", paddingBottom: "30px"}} spacing={2}>
      {currentFechaInicio8 && <Typography variant="paragraph" color = "grey"
              sx={{paddingTop: "0px", textAlign: "center", lineHeight:"5px", fontSize: "14px"}}
              >{currentFechaInicio8} / {currentFechaFin8}
            </Typography>}
     {currentCarrera8 && <Typography variant="paragraph" color = "grey"
              sx={{textAlign: "center", lineHeight:"10px", fontWeight: "bold"}}
              >{currentCarrera8}
            </Typography>}
      {currentUni8 && <Typography variant="paragraph" color = "grey"
              sx={{ textAlign: "center", lineHeight:"5px"}}
              >{currentUni8}
            </Typography>}
            {currentNotaMedia8 && <Typography variant="paragraph" color = "grey"
              sx={{ textAlign: "center", lineHeight:"5px", fontSize: "14px"}}
              >Nota media: {currentNotaMedia8}
            </Typography>}
              </Stack>}
      </Grid>

      
     </Grid></div>}

     <Divider sx={{marginTop: "50px"}} >
            <Chip label="Experiencia profesional" sx={{color: "white", bgcolor: "rgba(43, 117, 177, 0.9)"}} />
            </Divider>
            
          

            {currentTrabajo1 && <div> <Grid container component="main">
      
      <Grid item xs={1} sm={1} md={1} component={Paper} elevation={0} square sx={{}}>
      </Grid>
      <Grid item xs={10} sm={10} md={10} component={Paper} elevation={0} square sx={{}}>
       
      {currentTrabajo1 && <Stack sx={{textAlign: "center", width: '100%', paddingTop: "30px", paddingBottom: "30px"}} spacing={2}>
      {currentFechaInicioT1 && <Typography variant="paragraph" color = "grey"
              sx={{paddingTop: "0px", textAlign: "center", lineHeight:"5px", fontSize: "14px"}}
              >{currentFechaInicioT1} / {currentFechaFinT1}
            </Typography>}
     {currentTrabajo1 && <Typography variant="paragraph" color = "grey"
              sx={{textAlign: "center", lineHeight:"10px", fontWeight: "bold"}}
              >{currentTrabajo1}
            </Typography>}
      {currentEmpresa1 && <Typography variant="paragraph" color = "grey"
              sx={{ textAlign: "center", lineHeight:"5px"}}
              >{currentVisibilidad === "Perfil anónimo (datos identificativos ocultos)" && "Despacho/Empresa confidencial"}
              {currentVisibilidad === "Perfil abierto (datos visibles para despachos, empresas y headhunters)" && currentEmpresa1}
            </Typography>}
            {currentDescripcion1 && <Typography variant="paragraph" color = "grey"
              sx={{ textAlign: "center", lineHeight:"22px", fontSize: "14px"}}
              >{parse(currentDescripcion1)}
            </Typography>}
              </Stack>}

              {currentTrabajo2 && <Stack sx={{textAlign: "center", width: '100%', paddingBottom: "30px"}} spacing={2}>
      {currentFechaInicioT2 && <Typography variant="paragraph" color = "grey"
              sx={{paddingTop: "0px", textAlign: "center", lineHeight:"5px", fontSize: "14px"}}
              >{currentFechaInicioT2} / {currentFechaFinT2}
            </Typography>}
     {currentTrabajo2 && <Typography variant="paragraph" color = "grey"
              sx={{textAlign: "center", lineHeight:"10px", fontWeight: "bold"}}
              >{currentTrabajo2}
            </Typography>}
      {currentEmpresa2 && <Typography variant="paragraph" color = "grey"
              sx={{ textAlign: "center", lineHeight:"5px"}}
              >{currentVisibilidad === "Perfil anónimo (datos identificativos ocultos)" && "Despacho/Empresa confidencial"}
              {currentVisibilidad === "Perfil abierto (datos visibles para despachos, empresas y headhunters)" && currentEmpresa2}
            </Typography>}
            {currentDescripcion2 && <Typography variant="paragraph" color = "grey"
              sx={{ textAlign: "center", lineHeight:"22px", fontSize: "14px"}}
              >{parse(currentDescripcion2)}
            </Typography>}
              </Stack>}

              {currentTrabajo3 && <Stack sx={{textAlign: "center", width: '100%', paddingBottom: "30px"}} spacing={2}>
      {currentFechaInicioT3  && <Typography variant="paragraph" color = "grey"
              sx={{paddingTop: "0px", textAlign: "center", lineHeight:"5px", fontSize: "14px"}}
              >{currentFechaInicioT3} / {currentFechaFinT3}
            </Typography>}
     {currentTrabajo3 && <Typography variant="paragraph" color = "grey"
              sx={{textAlign: "center", lineHeight:"10px", fontWeight: "bold"}}
              >{currentTrabajo3}
            </Typography>}
      {currentEmpresa3 && <Typography variant="paragraph" color = "grey"
              sx={{ textAlign: "center", lineHeight:"5px"}}
              >{currentVisibilidad === "Perfil anónimo (datos identificativos ocultos)" && "Despacho/Empresa confidencial"}
              {currentVisibilidad === "Perfil abierto (datos visibles para despachos, empresas y headhunters)" && currentEmpresa3}
            </Typography>}
            {currentDescripcion3 && <Typography variant="paragraph" color = "grey"
              sx={{ textAlign: "center", lineHeight:"22px", fontSize: "14px"}}
              >{parse(currentDescripcion3)}
            </Typography>}
              </Stack>}

              {currentTrabajo4 && <Stack sx={{textAlign: "center", width: '100%', paddingBottom: "30px"}} spacing={2}>
      {currentFechaInicioT4 && <Typography variant="paragraph" color = "grey"
              sx={{paddingTop: "0px", textAlign: "center", lineHeight:"5px", fontSize: "14px"}}
              >{currentFechaInicioT4} / {currentFechaFinT4}
            </Typography>}
     {currentTrabajo4 && <Typography variant="paragraph" color = "grey"
              sx={{textAlign: "center", lineHeight:"10px", fontWeight: "bold"}}
              >{currentTrabajo4}
            </Typography>}
      {currentEmpresa4 && <Typography variant="paragraph" color = "grey"
              sx={{ textAlign: "center", lineHeight:"5px"}}
              >{currentVisibilidad === "Perfil anónimo (datos identificativos ocultos)" && "Despacho/Empresa confidencial"}
              {currentVisibilidad === "Perfil abierto (datos visibles para despachos, empresas y headhunters)" && currentEmpresa4}
            </Typography>}
            {currentDescripcion4 && <Typography variant="paragraph" color = "grey"
              sx={{ textAlign: "center", lineHeight:"22px", fontSize: "14px"}}
              >{parse(currentDescripcion4)}
            </Typography>}
              </Stack>}

              {currentTrabajo5 && <Stack sx={{textAlign: "center", width: '100%', paddingBottom: "30px"}} spacing={2}>
      {currentFechaInicioT5 && <Typography variant="paragraph" color = "grey"
              sx={{paddingTop: "0px", textAlign: "center", lineHeight:"5px", fontSize: "14px"}}
              >{currentFechaInicioT5} / {currentFechaFinT5}
            </Typography>}
     {currentTrabajo5 && <Typography variant="paragraph" color = "grey"
              sx={{textAlign: "center", lineHeight:"10px", fontWeight: "bold"}}
              >{currentTrabajo5}
            </Typography>}
      {currentEmpresa5 && <Typography variant="paragraph" color = "grey"
              sx={{ textAlign: "center", lineHeight:"5px"}}
              >{currentVisibilidad === "Perfil anónimo (datos identificativos ocultos)" && "Despacho/Empresa confidencial"}
              {currentVisibilidad === "Perfil abierto (datos visibles para despachos, empresas y headhunters)" && currentEmpresa5}
            </Typography>}
            {currentDescripcion5 && <Typography variant="paragraph" color = "grey"
              sx={{ textAlign: "center", lineHeight:"22px", fontSize: "14px"}}
              >{parse(currentDescripcion5)}
            </Typography>}
              </Stack>}

              {currentTrabajo6 && <Stack sx={{textAlign: "center", width: '100%', paddingBottom: "30px"}} spacing={2}>
      {currentFechaInicioT6 && <Typography variant="paragraph" color = "grey"
              sx={{paddingTop: "0px", textAlign: "center", lineHeight:"5px", fontSize: "14px"}}
              >{currentFechaInicioT6} / {currentFechaFinT6}
            </Typography>}
     {currentTrabajo6 && <Typography variant="paragraph" color = "grey"
              sx={{textAlign: "center", lineHeight:"10px", fontWeight: "bold"}}
              >{currentTrabajo6}
            </Typography>}
      {currentEmpresa6 && <Typography variant="paragraph" color = "grey"
              sx={{ textAlign: "center", lineHeight:"5px"}}
              >{currentVisibilidad === "Perfil anónimo (datos identificativos ocultos)" && "Despacho/Empresa confidencial"}
              {currentVisibilidad === "Perfil abierto (datos visibles para despachos, empresas y headhunters)" && currentEmpresa6}
            </Typography>}
            {currentDescripcion6 && <Typography variant="paragraph" color = "grey"
              sx={{ textAlign: "center", lineHeight:"22px", fontSize: "14px"}}
              >{parse(currentDescripcion6)}
            </Typography>}
              </Stack>}

              {currentTrabajo7 && <Stack sx={{textAlign: "center", width: '100%', paddingBottom: "30px"}} spacing={2}>
      {currentFechaInicioT7 && <Typography variant="paragraph" color = "grey"
              sx={{textAlign: "center", lineHeight:"5px", fontSize: "14px"}}
              >{currentFechaInicioT7} / {currentFechaFinT7}
            </Typography>}
     {currentTrabajo7 && <Typography variant="paragraph" color = "grey"
              sx={{textAlign: "center", lineHeight:"10px", fontWeight: "bold"}}
              >{currentTrabajo7}
            </Typography>}
      {currentEmpresa7 && <Typography variant="paragraph" color = "grey"
              sx={{ textAlign: "center", lineHeight:"5px"}}
              >{currentVisibilidad === "Perfil anónimo (datos identificativos ocultos)" && "Despacho/Empresa confidencial"}
              {currentVisibilidad === "Perfil abierto (datos visibles para despachos, empresas y headhunters)" && currentEmpresa7}
            </Typography>}
            {currentDescripcion7 && <Typography variant="paragraph" color = "grey"
              sx={{ textAlign: "center", lineHeight:"22px", fontSize: "14px"}}
              >{parse(currentDescripcion7)}
            </Typography>}
              </Stack>}

              {currentTrabajo8 && <Stack sx={{textAlign: "center", width: '100%', paddingBottom: "30px"}} spacing={2}>
      {currentFechaInicioT8 && <Typography variant="paragraph" color = "grey"
              sx={{paddingTop: "0px", textAlign: "center", lineHeight:"5px", fontSize: "14px"}}
              >{currentFechaInicioT8} / {currentFechaFinT8}
            </Typography>}
     {currentTrabajo8 && <Typography variant="paragraph" color = "grey"
              sx={{textAlign: "center", lineHeight:"10px", fontWeight: "bold"}}
              >{currentTrabajo8}
            </Typography>}
      {currentEmpresa8 && <Typography variant="paragraph" color = "grey"
              sx={{ textAlign: "center", lineHeight:"5px"}}
              >{currentVisibilidad === "Perfil anónimo (datos identificativos ocultos)" && "Despacho/Empresa confidencial"}
              {currentVisibilidad === "Perfil abierto (datos visibles para despachos, empresas y headhunters)" && currentEmpresa8}
            </Typography>}
            {currentDescripcion8 && <Typography variant="paragraph" color = "grey"
              sx={{ textAlign: "center", lineHeight:"22px", fontSize: "14px"}}
              >{parse(currentDescripcion8)}
            </Typography>}
              </Stack>}

             
      </Grid>

      
     </Grid></div>}
          



     <Stack sx={{ width: '100%'}} spacing={1} direction="row" justifyContent="center">  
     <Box sx={{maxWidth: "70%", display: "flex", justifyContent: "center", gap: "10px", flexWrap: "wrap", paddingBottom: "30px"}}> 

      {/*eachArea*/} 

    
            </Box>
            </Stack>
            {currentCastellano &&
            <div>
            <Divider sx={{marginTop: "50px"}} >
            <Chip label="Idiomas" sx={{color: "white", bgcolor: "rgba(43, 117, 177, 0.9)"}} />
            </Divider>
            <Grid container component="main">
      
      <Grid item xs={1} sm={1} md={1} component={Paper} elevation={0} square sx={{}}>
      </Grid>
      <Grid item xs={10} sm={10} md={10} component={Paper} elevation={0} square sx={{}}>
       
      {currentCastellano &&
      <Stack sx={{ width: '100%'}} spacing={1} direction="row" justifyContent="center">
      <Typography variant="p" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", textAlign: "center", fontWeight: "bold"}}
              >Castellano:
            </Typography>
            <Typography variant="p" color = "grey"
              sx={{paddingTop: "20px", textAlign: "center"}}
              >{currentCastellano}
            </Typography>
              </Stack>}
              {currentIngles &&
              <Stack sx={{ width: '100%'}} spacing={1} direction="row" justifyContent="center">
      <Typography variant="p" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", textAlign: "center", fontWeight: "bold"}}
              >
                Inglés:
            </Typography>
            <Typography variant="p" color = "grey"
              sx={{paddingTop: "20px", textAlign: "center"}}
              >{currentIngles}
            </Typography>
              </Stack>}
              {currentIdioma1 &&
      <Stack sx={{ width: '100%'}} spacing={1} direction="row" justifyContent="center">
      <Typography variant="p" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", textAlign: "center", fontWeight: "bold"}}
              >{currentIdioma1}:
            </Typography>
            <Typography variant="p" color = "grey"
              sx={{paddingTop: "20px", textAlign: "center"}}
              >{currentNivelIdioma1}
            </Typography>
              </Stack>}
              {currentIdioma2 &&
      <Stack sx={{ width: '100%'}} spacing={1} direction="row" justifyContent="center">
      <Typography variant="p" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", textAlign: "center", fontWeight: "bold"}}
              >{currentIdioma2}:
            </Typography>
            <Typography variant="p" color = "grey"
              sx={{paddingTop: "20px", textAlign: "center"}}
              >{currentNivelIdioma2}
            </Typography>
              </Stack>}
      </Grid>
     </Grid>
     </div>     
}






                </Stack>

             
         
       </Grid>
   }
     </Grid>
     </Box>




     <Box sx={{display: { xl: 'none', l: "none", m: "none", s:"inline", xs: 'inline'}, width: "100%"}} >
      <Grid container component="main" sx={{}}>
      <Grid item xs={12} sm={12} md={12} component={Paper} elevation={0} square sx={{}}>
       
      <Stack sx={{ paddingTop: "20px" }} spacing={2} >
      <Typography variant="h5" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center"}}
              >Introduce tus datos profesionales
            </Typography>
            
              </Stack>


        <Box
            sx={{
  
              flexDirection: 'column',
              justifyContent: "center",
              alignItems: "center",
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          >
            <Box component="form" noValidate onSubmit={handleSubmit2} sx={{mt: 1, marginBottom: "60px", borderRadius: "25px", padding: "20px" }}>
        
            <Divider sx={{marginBottom: "20px"}} >
            <Chip label="Visibilidad de tu perfil profesional" sx={{color: "white", bgcolor: "rgba(43, 117, 177, 0.9)"}} />
            </Divider>

<Autocomplete
        sx={{paddingTop: "20px"}}
        id="tags-standard"
        options={options}
            name="visibilidad"
        onChange={(e, value)=> setCurrentVisibilidad(value)}
        defaultValue={currentVisibilidad}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Visibilidad de tu perfil"
            placeholder="Visibilidad de tu perfil"
          />
        )}
      />


                          <Divider sx={{marginTop: "30px"}} >
            <Chip label="Información general" sx={{color: "white", bgcolor: "rgba(43, 117, 177, 0.9)"}} />
            </Divider>
            <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
           
                fullWidth
                id="Nombre completo"
                label="Nombre completo"
                name="nombre"
                autoComplete="nombre"
              
                onChange={handleChangeNombre}
                defaultValue={nombre}
              />

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
       
                fullWidth
                id="Número de teléfono"
                label="Número de teléfono"
                name="telefono"
                autoComplete="telefono"
                
                onChange={handleChangeTelefono}
                defaultValue={telefono}
              />

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
         
                fullWidth
                id="email"
                label="email"
                name="email"
                autoComplete="email"
             
                onChange={handleChangeEmail}
                defaultValue={email}
              />

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
       
                fullWidth
                id="Ciudad"
                label="Ciudad"
                name="ciudad"
                autoComplete="ciudad"
                
                onChange={handleChangeCiudad}
                defaultValue={ciudad}
              />


<Divider sx={{marginTop: "30px"}} >
            <Chip label="Formación" sx={{color: "white", bgcolor: "rgba(43, 117, 177, 0.9)"}} />
            </Divider>

            <Stack sx={{ }} spacing={2}>
      <Typography variant="body1" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "10px", textAlign: "center"}}
              >Introduce tus estudios de grado o licenciatura
            </Typography>
            
              </Stack>

            <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
       
                fullWidth
                id="carrera1"
                label="Estudios"
                name="carrera1"
                autoComplete="carrera1"
              
                onChange={handleChangeCarrera1}
                defaultValue={carrera1}
              />

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
  
                fullWidth
                id="uni1"
                label="Universidad"
                name="uni1"
                autoComplete="uni1"
            
                onChange={handleChangeUni1}
                defaultValue={uni1}
              />

<Grid container component="main" columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
     
                fullWidth
                id="fechaInicio1"
                label="Fecha de inicio"
                name="fechaInicio1"
                autoComplete="fechaInicio1"
            
                onChange={handleChangeFechaInicio1}
                defaultValue={fechaInicio1}
              />
        </Grid>

        <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="fechaFin1"
                label="Fecha de fin"
                name="fechaFin1"
                autoComplete="fechaFin1"
             
                onChange={handleChangeFechaFin1}
                defaultValue={fechaFin1}
              />
        </Grid>
        </Grid>
        <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
     
                id="notaMedia1"
                label="Nota media en la carrera"
                name="notaMedia1"
                autoComplete="notaMedia1"
           
                onChange={handleChangeNotaMedia1}
                defaultValue={notaMedia1}
              />

        {currentNumGrados <= 1 ? <Stack direction="row" spacing={0}>
          <IconButton aria-label="delete" color="primary"  onClick={handleButtonAddGrado1}>
          <AddCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center", paddingRight: "40px"}}
              >Añadir nueva formación
            </Typography>
            <IconButton aria-label="delete" color="primary"  onClick={handleButtonRemoveGrado1}>
          <RemoveCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center"}}
              >Eliminar formación
            </Typography>
            
        </Stack>:
        <Divider sx={{marginTop: "20px", marginBottom: "10px"}} ></Divider>}


        {currentNumGrados > 1 &&
          <div>
          <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
        
                fullWidth
                id="carrera2"
                label="Estudios"
                name="carrera2"
                autoComplete="carrera2"
            
                onChange={handleChangeCarrera2}
                defaultValue={carrera2}
              />

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
      
                fullWidth
                id="uni2"
                label="Universidad"
                name="uni2"
                autoComplete="uni2"
            
                onChange={handleChangeUni2}
                defaultValue={uni2}
              />

<Grid container component="main" columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="fechaInicio2"
                label="Fecha de inicio"
                name="fechaInicio2"
                autoComplete="fechaInicio2"
             
                onChange={handleChangeFechaInicio2}
                defaultValue={fechaInicio2}
              />
        </Grid>

        <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
    
                fullWidth
                id="fechaFin2"
                label="Fecha de fin"
                name="fechaFin2"
                autoComplete="fechaFin2"
            
                onChange={handleChangeFechaFin2}
                defaultValue={fechaFin2}
              />
        </Grid>
        </Grid>
        <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
     
                id="notaMedia2"
                label="Nota media en la carrera"
                name="notaMedia2"
                autoComplete="notaMedia2"
            
                onChange={handleChangeNotaMedia2}
                defaultValue={notaMedia2}
              />
        
        {currentNumGrados === 2 ? <Stack direction="row" spacing={0}>
          <IconButton aria-label="delete" color="primary"  onClick={handleButtonAddGrado1}>
          <AddCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center", paddingRight: "40px"}}
              >Añadir nueva formación
            </Typography>
            <IconButton aria-label="delete" color="primary"  onClick={handleButtonRemoveGrado2}>
          <RemoveCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center"}}
              >Eliminar formación
            </Typography>
            
        </Stack>:
        <Divider sx={{marginTop: "20px", marginBottom: "10px"}} ></Divider>}

        </div>
          
          }

{currentNumGrados >= 3 &&
          <div>
          <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
    
                fullWidth
                id="carrera3"
                label="Estudios"
                name="carrera3"
                autoComplete="carrera3"
          
                onChange={handleChangeCarrera3}
                defaultValue={carrera3}
              />

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
      
                fullWidth
                id="uni3"
                label="Universidad"
                name="uni3"
                autoComplete="uni3"
          
                onChange={handleChangeUni3}
                defaultValue={uni3}
              />

<Grid container component="main" columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
      
                fullWidth
                id="fechaInicio3"
                label="Fecha de inicio"
                name="fechaInicio3"
                autoComplete="fechaInicio3"
              
                onChange={handleChangeFechaInicio3}
                defaultValue={fechaInicio3}
              />
        </Grid>

        <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
         
                fullWidth
                id="fechaFin3"
                label="Fecha de fin"
                name="fechaFin3"
                autoComplete="fechaFin3"
          
                onChange={handleChangeFechaFin3}
                defaultValue={fechaFin3}
              />
        </Grid>
        </Grid>
        <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
     
                id="notaMedia3"
                label="Nota media en la carrera"
                name="notaMedia3"
                autoComplete="notaMedia3"
           
                onChange={handleChangeNotaMedia3}
                defaultValue={notaMedia3}
              />
        
            {currentNumGrados === 3 ? <Stack direction="row" spacing={0}>
          <IconButton aria-label="delete" color="primary"  onClick={handleButtonAddGrado1}>
          <AddCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center", paddingRight: "40px"}}
              >Añadir nueva formación
            </Typography>
            <IconButton aria-label="delete" color="primary"  onClick={handleButtonRemoveGrado3}>
          <RemoveCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center"}}
              >Eliminar formación
            </Typography>
            
        </Stack>:
        <Divider sx={{marginTop: "20px", marginBottom: "10px"}} ></Divider>}
       </div>
          
          }

{currentNumGrados >= 4 &&
          <div>
          <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
    
                fullWidth
                id="carrera4"
                label="Estudios"
                name="carrera4"
                autoComplete="carrera4"
            
                onChange={handleChangeCarrera4}
                defaultValue={carrera4}
              />

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
         
                fullWidth
                id="uni4"
                label="Universidad"
                name="uni4"
                autoComplete="uni4"
          
                onChange={handleChangeUni4}
                defaultValue={uni4}
              />

<Grid container component="main" columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
    
                fullWidth
                id="fechaInicio4"
                label="Fecha de inicio"
                name="fechaInicio4"
                autoComplete="fechaInicio4"
             
                onChange={handleChangeFechaInicio4}
                defaultValue={fechaInicio4}
              />
        </Grid>

        <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
    
                fullWidth
                id="fechaFin4"
                label="Fecha de fin"
                name="fechaFin4"
                autoComplete="fechaFin4"
            
                onChange={handleChangeFechaFin4}
                defaultValue={fechaFin4}
              />
        </Grid>
        </Grid>
        <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
      
                id="notaMedia4"
                label="Nota media en la carrera"
                name="notaMedia4"
                autoComplete="notaMedia4"
           
                onChange={handleChangeNotaMedia4}
                defaultValue={notaMedia4}
              />
        
            {currentNumGrados === 4 ? <Stack direction="row" spacing={0}>
          <IconButton aria-label="delete" color="primary"  onClick={handleButtonAddGrado1}>
          <AddCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center", paddingRight: "40px"}}
              >Añadir nueva formación
            </Typography>
            <IconButton aria-label="delete" color="primary"  onClick={handleButtonRemoveGrado4}>
          <RemoveCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center"}}
              >Eliminar formación
            </Typography>
            
        </Stack>:
        <Divider sx={{marginTop: "20px", marginBottom: "10px"}} ></Divider>}
       </div>
          
          }

<Divider sx={{marginTop: "20px", marginBottom: "10px"}} ></Divider>
<Stack sx={{ }} spacing={2}>
      <Typography variant="1" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "30px", textAlign: "center"}}
              >Introduce tus estudios de posgrado
            </Typography>
            
              </Stack>

              <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
     
                fullWidth
                id="carrera5"
                label="Estudios"
                name="carrera5"
                autoComplete="carrera5"
              
                onChange={handleChangeCarrera5}
                defaultValue={carrera5}
              />

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
    
                fullWidth
                id="uni5"
                label="Universidad"
                name="uni5"
                autoComplete="uni5"
            
                onChange={handleChangeUni5}
                defaultValue={uni5}
              />

<Grid container component="main" columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
     
                fullWidth
                id="fechaInicio5"
                label="Fecha de inicio"
                name="fechaInicio5"
                autoComplete="fechaInicio5"
            
                onChange={handleChangeFechaInicio5}
                defaultValue={fechaInicio5}
              />
        </Grid>

        <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="fechaFin5"
                label="Fecha de fin"
                name="fechaFin5"
                autoComplete="fechaFin5"
             
                onChange={handleChangeFechaFin5}
                defaultValue={fechaFin5}
              />
        </Grid>
        </Grid>
        <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
  
                id="notaMedia5"
                label="Nota media en la carrera"
                name="notaMedia5"
                autoComplete="notaMedia5"
           
                onChange={handleChangeNotaMedia5}
                defaultValue={notaMedia5}
              />

        {currentNumPosGrados <= 1 ? <Stack direction="row" spacing={0}>
          <IconButton aria-label="delete" color="primary"  onClick={handleButtonAddPosGrado1}>
          <AddCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center", paddingRight: "40px"}}
              >Añadir nueva formación
            </Typography>
            <IconButton aria-label="delete" color="primary"  onClick={handleButtonRemovePosGrado1}>
          <RemoveCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center"}}
              >Eliminar formación
            </Typography>
            
        </Stack>:
        <Divider sx={{marginTop: "20px", marginBottom: "10px"}} ></Divider>}


        {currentNumPosGrados > 1 &&
          <div>
          <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
       
                fullWidth
                id="carrera6"
                label="Estudios"
                name="carrera6"
                autoComplete="carrera6"
            
                onChange={handleChangeCarrera6}
                defaultValue={carrera6}
              />

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
       
                fullWidth
                id="uni6"
                label="Universidad"
                name="uni6"
                autoComplete="uni6"
            
                onChange={handleChangeUni6}
                defaultValue={uni6}
              />

<Grid container component="main" columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
   
                fullWidth
                id="fechaInicio6"
                label="Fecha de inicio"
                name="fechaInicio6"
                autoComplete="fechaInicio6"
             
                onChange={handleChangeFechaInicio6}
                defaultValue={fechaInicio6}
              />
        </Grid>

        <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
    
                fullWidth
                id="fechaFin6"
                label="Fecha de fin"
                name="fechaFin6"
                autoComplete="fechaFin6"
            
                onChange={handleChangeFechaFin6}
                defaultValue={fechaFin6}
              />
        </Grid>
        </Grid>
        <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
      
                id="notaMedia6"
                label="Nota media"
                name="notaMedia6"
                autoComplete="notaMedia6"
            
                onChange={handleChangeNotaMedia6}
                defaultValue={notaMedia6}
              />
        
        {currentNumPosGrados === 2 ? <Stack direction="row" spacing={0}>
          <IconButton aria-label="delete" color="primary"  onClick={handleButtonAddPosGrado1}>
          <AddCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center", paddingRight: "40px"}}
              >Añadir nueva formación
            </Typography>
            <IconButton aria-label="delete" color="primary"  onClick={handleButtonRemovePosGrado2}>
          <RemoveCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center"}}
              >Eliminar formación
            </Typography>
            
        </Stack>:
        <Divider sx={{marginTop: "20px", marginBottom: "10px"}} ></Divider>}

        </div>
          
          }

{currentNumPosGrados >= 3 &&
          <div>
          <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
    
                fullWidth
                id="carrera7"
                label="Estudios"
                name="carrera7"
                autoComplete="carrera7"
          
                onChange={handleChangeCarrera7}
                defaultValue={carrera7}
              />

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
        
                fullWidth
                id="uni7"
                label="Universidad"
                name="uni7"
                autoComplete="uni7"
          
                onChange={handleChangeUni7}
                defaultValue={uni7}
              />

<Grid container component="main" columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
   
                fullWidth
                id="fechaInicio7"
                label="Fecha de inicio"
                name="fechaInicio7"
                autoComplete="fechaInicio7"
              
                onChange={handleChangeFechaInicio7}
                defaultValue={fechaInicio7}
              />
        </Grid>

        <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
     
                fullWidth
                id="fechaFin7"
                label="Fecha de fin"
                name="fechaFin7"
                autoComplete="fechaFin7"
          
                onChange={handleChangeFechaFin7}
                defaultValue={fechaFin7}
              />
        </Grid>
        </Grid>
        <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
        
                id="notaMedia7"
                label="Nota media"
                name="notaMedia7"
                autoComplete="notaMedia7"
           
                onChange={handleChangeNotaMedia7}
                defaultValue={notaMedia7}
              />
        
            {currentNumPosGrados === 3 ? <Stack direction="row" spacing={0}>
          <IconButton aria-label="delete" color="primary"  onClick={handleButtonAddPosGrado1}>
          <AddCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center", paddingRight: "40px"}}
              >Añadir nueva formación
            </Typography>
            <IconButton aria-label="delete" color="primary"  onClick={handleButtonRemovePosGrado3}>
          <RemoveCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center"}}
              >Eliminar formación
            </Typography>
            
        </Stack>:
        <Divider sx={{marginTop: "20px", marginBottom: "10px"}} ></Divider>}
       </div>
          
          }

{currentNumPosGrados >= 4 &&
          <div>
          <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
  
                fullWidth
                id="carrera8"
                label="Estudios"
                name="carrera8"
                autoComplete="carrera8"
            
                onChange={handleChangeCarrera8}
                defaultValue={carrera8}
              />

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
     
                fullWidth
                id="uni8"
                label="Universidad"
                name="uni8"
                autoComplete="uni8"
          
                onChange={handleChangeUni8}
                defaultValue={uni8}
              />

<Grid container component="main" columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
    
                fullWidth
                id="fechaInicio8"
                label="Fecha de inicio"
                name="fechaInicio8"
                autoComplete="fechaInicio8"
             
                onChange={handleChangeFechaInicio8}
                defaultValue={fechaInicio8}
              />
        </Grid>

        <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
     
                fullWidth
                id="fechaFin8"
                label="Fecha de fin"
                name="fechaFin8"
                autoComplete="fechaFin8"
            
                onChange={handleChangeFechaFin8}
                defaultValue={fechaFin8}
              />
        </Grid>
        </Grid>
        <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
 
                id="notaMedia8"
                label="Nota media en la carrera"
                name="notaMedia8"
                autoComplete="notaMedia8"
           
                onChange={handleChangeNotaMedia8}
                defaultValue={notaMedia8}
              />
        
            {currentNumPosGrados === 4 ? <Stack direction="row" spacing={0}>
          <IconButton aria-label="delete" color="primary"  onClick={handleButtonAddPosGrado1}>
          <AddCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center", paddingRight: "40px"}}
              >Añadir nueva formación
            </Typography>
            <IconButton aria-label="delete" color="primary"  onClick={handleButtonRemovePosGrado4}>
          <RemoveCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center"}}
              >Eliminar formación
            </Typography>
            
        </Stack>:
        <Divider sx={{marginTop: "20px", marginBottom: "10px"}} ></Divider>}
       </div>
          
          }


<Divider sx={{marginTop: "20px"}} >
            <Chip label="Experiencia" sx={{color: "white", bgcolor: "rgba(43, 117, 177, 0.9)"}} />
            </Divider>

<Stack sx={{ }} spacing={2}>
      <Typography variant="body1" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "40px", textAlign: "center", lineHeight: "0px"}}
              >Introduce tus experiencias laborales
            </Typography>
            <Typography variant="body2" color = "rgba(43, 117, 177, 0.9)"
              sx={{textAlign: "center", paddingBottom: "30px"}}
              >(comenzando por la más reciente / actual)
            </Typography>
            
              </Stack>

              <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
     
                fullWidth
                id="trabajo1"
                label="Posición"
                name="trabajo1"
                autoComplete="trabajo1"
              
                onChange={handleChangeTrabajo1}
                defaultValue={trabajo1}
              />

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
    
                fullWidth
                id="empresa1"
                label="Despacho / Empresa"
                name="empresa1"
                autoComplete="empresa1"
            
                onChange={handleChangeEmpresa1}
                defaultValue={empresa1}
              />

<Grid container component="main" columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
    
                fullWidth
                id="fechaInicioT1"
                label="Fecha de inicio"
                name="fechaInicioT1"
                autoComplete="fechaInicioT1"
            
                onChange={handleChangeFechaInicioT1}
                defaultValue={fechaInicioT1}
              />
        </Grid>

        <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{marginBottom: "20px"}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
       
                fullWidth
                id="fechaFinT1"
                label="Fecha de fin"
                name="fechaFinT1"
                autoComplete="fechaFinT1"
             
                onChange={handleChangeFechaFinT1}
                defaultValue={fechaFinT1}
              />
        </Grid>
        </Grid>
        <CKEditor  editor={ClassicEditor} data={currentDescripcion1} onChange={(event, editor) => {
  const data = editor.getData()
  setCurrentDescripcion1(data)
}}
/> 

        {currentNumTrabajos <= 1 ? <Stack direction="row" spacing={0}>
          <IconButton aria-label="delete" color="primary"  onClick={handleButtonAddTrabajo}>
          <AddCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center", paddingRight: "40px"}}
              >Añadir nuevo trabajo
            </Typography>
            <IconButton aria-label="delete" color="primary"  onClick={handleButtonRemoveTrabajo1}>
          <RemoveCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center"}}
              >Eliminar trabajo
            </Typography>
            
        </Stack>:
        <Divider sx={{marginTop: "20px", marginBottom: "10px"}} ></Divider>}


{currentNumTrabajos > 1 &&
          <div>

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
     
                fullWidth
                id="trabajo2"
                label="Posición"
                name="trabajo2"
                autoComplete="trabajo2"
              
                onChange={handleChangeTrabajo2}
                defaultValue={trabajo2}
              />

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
        
                fullWidth
                id="empresa2"
                label="Despacho / Empresa"
                name="empresa2"
                autoComplete="empresa2"
            
                onChange={handleChangeEmpresa2}
                defaultValue={empresa2}
              />

<Grid container component="main" columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
          
                fullWidth
                id="fechaInicioT2"
                label="Fecha de inicio"
                name="fechaInicioT2"
                autoComplete="fechaInicioT2"
            
                onChange={handleChangeFechaInicioT2}
                defaultValue={fechaInicioT2}
              />
        </Grid>

        <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{marginBottom: "20px"}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
      
                fullWidth
                id="fechaFinT2"
                label="Fecha de fin"
                name="fechaFinT2"
                autoComplete="fechaFinT2"
             
                onChange={handleChangeFechaFinT2}
                defaultValue={fechaFinT2}
              />
        </Grid>
        </Grid>
        <CKEditor  editor={ClassicEditor} data={currentDescripcion2} onChange={(event, editor) => {
  const data = editor.getData()
  setCurrentDescripcion2(data)
}}
/> 

        {currentNumTrabajos === 2 ? <Stack direction="row" spacing={0}>
          <IconButton aria-label="delete" color="primary"  onClick={handleButtonAddTrabajo}>
          <AddCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center", paddingRight: "40px"}}
              >Añadir nuevo trabajo
            </Typography>
            <IconButton aria-label="delete" color="primary"  onClick={handleButtonRemoveTrabajo2}>
          <RemoveCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center"}}
              >Eliminar trabajo
            </Typography>
            
        </Stack>:
        <Divider sx={{marginTop: "20px", marginBottom: "10px"}} ></Divider>}
        </div>
          }  

{currentNumTrabajos > 2 &&
          <div>

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
          
                fullWidth
                id="trabajo3"
                label="Posición"
                name="trabajo3"
                autoComplete="trabajo3"
              
                onChange={handleChangeTrabajo3}
                defaultValue={trabajo3}
              />

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
      
                fullWidth
                id="empresa3"
                label="Despacho / Empresa"
                name="empresa3"
                autoComplete="empresa3"
            
                onChange={handleChangeEmpresa3}
                defaultValue={empresa3}
              />

<Grid container component="main" columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
        
                fullWidth
                id="fechaInicioT3"
                label="Fecha de inicio"
                name="fechaInicioT3"
                autoComplete="fechaInicioT3"
            
                onChange={handleChangeFechaInicioT3}
                defaultValue={fechaInicioT3}
              />
        </Grid>

        <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{marginBottom: "20px"}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
           
                fullWidth
                id="fechaFinT3"
                label="Fecha de fin"
                name="fechaFinT3"
                autoComplete="fechaFinT3"
             
                onChange={handleChangeFechaFinT3}
                defaultValue={fechaFinT3}
              />
        </Grid>
        </Grid>
        <CKEditor  editor={ClassicEditor} data={currentDescripcion3} onChange={(event, editor) => {
  const data = editor.getData()
  setCurrentDescripcion3(data)
}}
/> 

        {currentNumTrabajos === 3 ? <Stack direction="row" spacing={0}>
          <IconButton aria-label="delete" color="primary"  onClick={handleButtonAddTrabajo}>
          <AddCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center", paddingRight: "40px"}}
              >Añadir nuevo trabajo
            </Typography>
            <IconButton aria-label="delete" color="primary"  onClick={handleButtonRemoveTrabajo3}>
          <RemoveCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center"}}
              >Eliminar trabajo
            </Typography>
            
        </Stack>:
        <Divider sx={{marginTop: "20px", marginBottom: "10px"}} ></Divider>}
        </div>
          }  



{currentNumTrabajos > 3 &&
          <div>

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
      
                fullWidth
                id="trabajo4"
                label="Posición"
                name="trabajo4"
                autoComplete="trabajo4"
              
                onChange={handleChangeTrabajo4}
                defaultValue={trabajo4}
              />

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
       
                fullWidth
                id="empresa4"
                label="Despacho / Empresa"
                name="empresa4"
                autoComplete="empresa4"
            
                onChange={handleChangeEmpresa4}
                defaultValue={empresa4}
              />

<Grid container component="main" columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
          
                fullWidth
                id="fechaInicioT4"
                label="Fecha de inicio"
                name="fechaInicioT4"
                autoComplete="fechaInicioT4"
            
                onChange={handleChangeFechaInicioT4}
                defaultValue={fechaInicioT4}
              />
        </Grid>

        <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{marginBottom: "20px"}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
         
                fullWidth
                id="fechaFinT4"
                label="Fecha de fin"
                name="fechaFinT4"
                autoComplete="fechaFinT4"
             
                onChange={handleChangeFechaFinT4}
                defaultValue={fechaFinT4}
              />
        </Grid>
        </Grid>
        <CKEditor  editor={ClassicEditor} data={currentDescripcion4} onChange={(event, editor) => {
  const data = editor.getData()
  setCurrentDescripcion4(data)
}}
/> 

        {currentNumTrabajos === 4 ? <Stack direction="row" spacing={0}>
          <IconButton aria-label="delete" color="primary"  onClick={handleButtonAddTrabajo}>
          <AddCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center", paddingRight: "40px"}}
              >Añadir nuevo trabajo
            </Typography>
            <IconButton aria-label="delete" color="primary"  onClick={handleButtonRemoveTrabajo4}>
          <RemoveCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center"}}
              >Eliminar trabajo
            </Typography>
            
        </Stack>:
        <Divider sx={{marginTop: "20px", marginBottom: "10px"}} ></Divider>}
        </div>
          }  

{currentNumTrabajos > 4 &&
          <div>

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
     
                fullWidth
                id="trabajo5"
                label="Posición"
                name="trabajo5"
                autoComplete="trabajo5"
              
                onChange={handleChangeTrabajo5}
                defaultValue={trabajo5}
              />

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
       
                fullWidth
                id="empresa5"
                label="Despacho / Empresa"
                name="empresa5"
                autoComplete="empresa5"
            
                onChange={handleChangeEmpresa5}
                defaultValue={empresa5}
              />

<Grid container component="main" columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
       
                fullWidth
                id="fechaInicioT5"
                label="Fecha de inicio"
                name="fechaInicioT5"
                autoComplete="fechaInicioT5"
            
                onChange={handleChangeFechaInicioT5}
                defaultValue={fechaInicioT5}
              />
        </Grid>

        <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{marginBottom: "20px"}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
   
                fullWidth
                id="fechaFinT5"
                label="Fecha de fin"
                name="fechaFinT5"
                autoComplete="fechaFinT5"
             
                onChange={handleChangeFechaFinT5}
                defaultValue={fechaFinT5}
              />
        </Grid>
        </Grid>
        <CKEditor  editor={ClassicEditor} data={currentDescripcion5} onChange={(event, editor) => {
  const data = editor.getData()
  setCurrentDescripcion5(data)
}}
/> 

        {currentNumTrabajos === 5 ? <Stack direction="row" spacing={0}>
          <IconButton aria-label="delete" color="primary"  onClick={handleButtonAddTrabajo}>
          <AddCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center", paddingRight: "40px"}}
              >Añadir nuevo trabajo
            </Typography>
            <IconButton aria-label="delete" color="primary"  onClick={handleButtonRemoveTrabajo5}>
          <RemoveCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center"}}
              >Eliminar trabajo
            </Typography>
            
        </Stack>:
        <Divider sx={{marginTop: "20px", marginBottom: "10px"}} ></Divider>}
        </div>
          }  

{currentNumTrabajos > 5 &&
          <div>

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
        
                fullWidth
                id="trabajo6"
                label="Posición"
                name="trabajo6"
                autoComplete="trabajo6"
              
                onChange={handleChangeTrabajo6}
                defaultValue={trabajo6}
              />

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
      
                fullWidth
                id="empresa6"
                label="Despacho / Empresa"
                name="empresa6"
                autoComplete="empresa6"
            
                onChange={handleChangeEmpresa6}
                defaultValue={empresa6}
              />

<Grid container component="main" columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
     
                fullWidth
                id="fechaInicioT6"
                label="Fecha de inicio"
                name="fechaInicioT6"
                autoComplete="fechaInicioT6"
            
                onChange={handleChangeFechaInicioT6}
                defaultValue={fechaInicioT6}
              />
        </Grid>

        <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{marginBottom: "20px"}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
    
                fullWidth
                id="fechaFinT6"
                label="Fecha de fin"
                name="fechaFinT6"
                autoComplete="fechaFinT6"
             
                onChange={handleChangeFechaFinT6}
                defaultValue={fechaFinT6}
              />
        </Grid>
        </Grid>
        <CKEditor  editor={ClassicEditor} data={currentDescripcion6} onChange={(event, editor) => {
  const data = editor.getData()
  setCurrentDescripcion6(data)
}}
/> 

        {currentNumTrabajos === 6 ? <Stack direction="row" spacing={0}>
          <IconButton aria-label="delete" color="primary"  onClick={handleButtonAddTrabajo}>
          <AddCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center", paddingRight: "40px"}}
              >Añadir nuevo trabajo
            </Typography>
            <IconButton aria-label="delete" color="primary"  onClick={handleButtonRemoveTrabajo5}>
          <RemoveCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center"}}
              >Eliminar trabajo
            </Typography>
            
        </Stack>:
        <Divider sx={{marginTop: "20px", marginBottom: "10px"}} ></Divider>}
        </div>
          }  

{currentNumTrabajos > 6 &&
          <div>

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
 
                fullWidth
                id="trabajo7"
                label="Posición"
                name="trabajo7"
                autoComplete="trabajo7"
              
                onChange={handleChangeTrabajo7}
                defaultValue={trabajo7}
              />

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
 
                fullWidth
                id="empresa7"
                label="Despacho / Empresa"
                name="empresa7"
                autoComplete="empresa7"
            
                onChange={handleChangeEmpresa7}
                defaultValue={empresa7}
              />

<Grid container component="main" columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
   
                fullWidth
                id="fechaInicioT7"
                label="Fecha de inicio"
                name="fechaInicioT7"
                autoComplete="fechaInicioT7"
            
                onChange={handleChangeFechaInicioT7}
                defaultValue={fechaInicioT7}
              />
        </Grid>

        <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{marginBottom: "20px"}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
   
                fullWidth
                id="fechaFinT7"
                label="Fecha de fin"
                name="fechaFinT7"
                autoComplete="fechaFinT7"
             
                onChange={handleChangeFechaFinT7}
                defaultValue={fechaFinT7}
              />
        </Grid>
        </Grid>
        <CKEditor  editor={ClassicEditor} data={currentDescripcion7} onChange={(event, editor) => {
  const data = editor.getData()
  setCurrentDescripcion7(data)
}}
/> 

        {currentNumTrabajos === 6 ? <Stack direction="row" spacing={0}>
          <IconButton aria-label="delete" color="primary"  onClick={handleButtonAddTrabajo}>
          <AddCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center", paddingRight: "40px"}}
              >Añadir nuevo trabajo
            </Typography>
            <IconButton aria-label="delete" color="primary"  onClick={handleButtonRemoveTrabajo7}>
          <RemoveCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center"}}
              >Eliminar trabajo
            </Typography>
            
        </Stack>:
        <Divider sx={{marginTop: "20px", marginBottom: "10px"}} ></Divider>}
        </div>
          }  

{currentNumTrabajos > 7 &&
          <div>

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
  
                fullWidth
                id="trabajo8"
                label="Posición"
                name="trabajo8"
                autoComplete="trabajo8"
              
                onChange={handleChangeTrabajo8}
                defaultValue={trabajo8}
              />

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="empresa8"
                label="Despacho / Empresa"
                name="empresa8"
                autoComplete="empresa8"
            
                onChange={handleChangeEmpresa8}
                defaultValue={empresa8}
              />

<Grid container component="main" columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
 
                fullWidth
                id="fechaInicioT8"
                label="Fecha de inicio"
                name="fechaInicioT8"
                autoComplete="fechaInicioT8"
            
                onChange={handleChangeFechaInicioT8}
                defaultValue={fechaInicioT8}
              />
        </Grid>

        <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{marginBottom: "20px"}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
  
                fullWidth
                id="fechaFinT8"
                label="Fecha de fin"
                name="fechaFinT8"
                autoComplete="fechaFinT8"
             
                onChange={handleChangeFechaFinT8}
                defaultValue={fechaFinT8}
              />
        </Grid>
        </Grid>
        <CKEditor  editor={ClassicEditor} data={currentDescripcion8} onChange={(event, editor) => {
  const data = editor.getData()
  setCurrentDescripcion8(data)
}}
/> 

        {currentNumTrabajos === 7 ? <Stack direction="row" spacing={0}>
          <IconButton aria-label="delete" color="primary"  onClick={handleButtonAddTrabajo}>
          <AddCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center", paddingRight: "40px"}}
              >Añadir nuevo trabajo
            </Typography>
            <IconButton aria-label="delete" color="primary"  onClick={handleButtonRemoveTrabajo8}>
          <RemoveCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center"}}
              >Eliminar trabajo
            </Typography>
            
        </Stack>:
        <Divider sx={{marginTop: "20px", marginBottom: "10px"}} ></Divider>}
        </div>
          }  
      <Typography variant="body1" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "50px", textAlign: "center"}}
              >Indica tus áreas de práctica
            </Typography>
<Autocomplete
          multiple
          sx={{paddingTop: "20px", paddingBottom: "50px"}}
          id="tags-standard"
          options={listareas}
          getOptionLabel={(option) => option.title}
          onChange={(e, value)=> setCurrentArea(value)}
          //defaultValue={currentArea}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Áreas de práctica"
              placeholder="Áreas de práctica"
  

            />
          )}
        />
      <Typography variant="body1" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "30px", textAlign: "center"}}
              >Indica tus años de experiencia
            </Typography>
<Slider
        getAriaLabel={() => 'Años de experiencia'}
        value={currentAnos}
        onChange={handleChangeExperiencia}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
        step={1}
        min={0}
        max={20}
        marks={anosExperienciaMovil}
      />


<Divider sx={{marginTop: "40px", marginBottom: "15px"}} >
            <Chip label="Idiomas" sx={{color: "white", bgcolor: "rgba(43, 117, 177, 0.9)"}} />
            </Divider>

            <Stack>
            <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">Castellano</FormLabel>
      <RadioGroup
        column
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={currentCastellano}
        defaultValue={castellano}
        onChange={handleChangeCastellano}
      >
        <FormControlLabel value="NA" control={<Radio />} label="N/A" />
        <FormControlLabel value="Básico" control={<Radio />} label="Básico" />
        <FormControlLabel value="Intermedio" control={<Radio />} label="Intermedio" />
        <FormControlLabel value="Avanzado" control={<Radio />} label="Avanzado" />
        <FormControlLabel value="Nativo" control={<Radio />} label="Nativo" />
      </RadioGroup>
    </FormControl>
    </Stack>

    <Stack>
    <FormControl>
      <FormLabel id="demo-row-radio-buttons-group-label" sx={{marginTop: "20px"}}>Inglés</FormLabel>
      <RadioGroup
        column
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={currentIngles}
        onChange={handleChangeIngles}
        defaultValue={ingles}
      >
        <FormControlLabel value="NA" control={<Radio />} label="N/A" />
        <FormControlLabel value="Básico" control={<Radio />} label="Básico" />
        <FormControlLabel value="Intermedio" control={<Radio />} label="Intermedio" />
        <FormControlLabel value="Avanzado" control={<Radio />} label="Avanzado" />
        <FormControlLabel value="Nativo" control={<Radio />} label="Nativo" />
      </RadioGroup>
    </FormControl>
    </Stack>

    <Stack direction="column"   sx={{marginTop: "20px"}}>
    <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
          
                id="idioma1"
                label="Idioma adicional"
                name="idioma1"
                autoComplete="idioma1"
                defaultValue={idioma1}
             
                onChange={handleChangeIdioma1}
              />
              <Stack direction="column"   sx={{marginTop: "20px"}}>
          <FormControl>
      <FormLabel id="demo-row-radio-buttons-group-label" sx={{marginTop: "20px"}}></FormLabel>
      <RadioGroup
        column
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={currentNivelIdioma1}
        onChange={handleChangeNivelIdioma1}
        defaultValue={nivelIdioma1}
      >
        <FormControlLabel value="NA" control={<Radio />} label="N/A" />
        <FormControlLabel value="Básico" control={<Radio />} label="Básico" />
        <FormControlLabel value="Intermedio" control={<Radio />} label="Intermedio" />
        <FormControlLabel value="Avanzado" control={<Radio />} label="Avanzado" />
        <FormControlLabel value="Nativo" control={<Radio />} label="Nativo" />
      </RadioGroup>
    </FormControl>
    </Stack>
    </Stack>
    <Stack sx={{marginTop: "20px"}}>
    <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
          
                id="idioma2"
                label="Idioma adicional"
                name="idioma2"
                autoComplete="idioma2"
                defaultValue={idioma2}
             
                onChange={handleChangeIdioma2}
              />
        <FormControl>
      <RadioGroup
        column
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={currentNivelIdioma2}
        defaultValue={nivelIdioma2}
        onChange={handleChangeNivelIdioma2}
      >
        <FormControlLabel value="NA" control={<Radio />} label="N/A" />
        <FormControlLabel value="Básico" control={<Radio />} label="Básico" />
        <FormControlLabel value="Intermedio" control={<Radio />} label="Intermedio" />
        <FormControlLabel value="Avanzado" control={<Radio />} label="Avanzado" />
        <FormControlLabel value="Nativo" control={<Radio />} label="Nativo" />
      </RadioGroup>
    </FormControl>
    </Stack>

    <Divider sx={{marginTop: "40px"}} >
            <Chip label="Extras" sx={{color: "white", bgcolor: "rgba(43, 117, 177, 0.9)"}} />
            </Divider>
            <Stack sx={{ paddingTop: "20px" }} spacing={2}>
      <Typography variant="body1" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center"}}
              >Incluye una breve presentación
            </Typography>
            
              </Stack>
    <CKEditor  editor={ClassicEditor} data={currentPresentacion} onChange={(event, editor) => {
  const data = editor.getData()
  setCurrentPresentacion(data)
}}
/> 

<Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, backgroundColor: "rgba(43, 117, 177, 0.9)", color: "white"}}

              >
                {buttonText}
              </Button>
              <Button href="./dashboard"
                
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, backgroundColor: "rgba(43, 117, 177, 0.9)", color: "white"}}

              >
                Volver al escritorio (guarda antes los cambios)
              </Button>
            </Box>
          </Box>
</Grid>
</Grid>
     </Box>
     </div>
  )
}

export default Perfil;
