import React from 'react'
import { useState, useEffect } from 'react';
import { collection, getDocs, addDoc } from "firebase/firestore";
import {db} from '../firebase-config.js';
import AuthProvider from '../components/authProvider.js';
import Header from '../components/Header';
import CssBaseline from '@mui/material/CssBaseline';
import { getPerfil, insertNewPerfil, updatePerfil } from '../firebase-config';
import { useNavigate } from 'react-router';
import { useUserAuth } from "../context/UserAuthContext";
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardActionArea } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import CardMedia from '@mui/material/CardMedia';
import Footer from '../components/Footer';


const MejoresDespachosConciliacion = ( {getFirmId} ) => {
    const [notas, setNotas] = useState([]);
    const [perfiles, setPerfiles] = useState([ ]);
    const [currentUser, setCurrentUser] = useState({});
    let {user} = useUserAuth();
    const navigate = useNavigate();
    async function handleUserLoggedIn(user) {
        setCurrentUser(user);
        console.log("Estás registrado");
        console.log(user.displayName);
        const resPerfil = await getPerfil(user.uid);
          setPerfiles([...resPerfil]);
          console.log(perfiles.nombre); 
       }
    
       function handleUserNotRegistered(user) {
        
    
       }
       
       function handleUserNotLoggedIn() {
        
       }
    
 
    const fetchPost = async () => {
       
        await getDocs(collection(db, "notas"))
            .then((querySnapshot)=>{              
                const newData = querySnapshot.docs
                    .map((doc) => ({...doc.data(), id:doc.id }));
                setNotas(newData);                
                console.log(notas, newData);
            })
       
    }
   
    useEffect(()=>{
        fetchPost();
    }, [])

    const ordered = [...notas].sort((a,b) => b.conciliacion - a.conciliacion);
    console.log(ordered);

    const eachnota = ordered.slice(0,20).map((doc, index) => {
 
        
    
        
        return (
            <div>
            <Stack sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              alignContent: "center",
              justifyContent: "center",
              paddingTop: "20px",
          
            }}>      

    <Box sx={{display: { xl: 'block', l: "block", m: "none", s:"none", xs: 'none' }}} >
        <Card variant="outlined" sx={{display: "flex", width: "800px"}}>
        
        <CardContent
            
            sx={{width: "20%", padding:"20px", textAlign: "center", justifyContent: "center", alignContent: "center"}}
            
          >
          <Typography variant="subtitle1"  color="text.secondary">Valoración</Typography>
          <Typography variant="h3"  color="text.secondary">{doc.conciliacion}</Typography>
          <Typography variant="h5"  color="text.secondary">/100</Typography>
          </CardContent>  
          
        <Box sx={{ display: 'flex', flexDirection: 'row', width: "600px", alignItems: 'center',
              alignContent: "center",
              justifyContent: "flex-start",}}>
        <CardActionArea component={RouterLink} to={`/Rankings/${doc.id}`}>
        <CardContent sx={{width: "60%", paddingTop:"20px"}}>
        
                <Typography variant="h3" color="text.secondary">
                
            {doc.inputValue}
          
                     
                </Typography>

                <Box sx={{ display: 'flex', flexDirection: 'row', width: "500px", alignItems: 'center',
              alignContent: "center",
              justifyContent: "flex-start",}}>   
        <Box sx={{paddingTop: "14px"}}>
        
        <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px"}}>
            {doc.tipodespacho}
        </Typography>
        
        </Box>
        <Box sx={{paddingTop: "14px", paddingLeft: "8px"}}>
        
        <Typography variant="caption" sx={{backgroundColor: "#03b1fc", borderRadius: "10px", color:"white", padding: "6px"}}>
           {doc.tipoderecho}
        </Typography>
        </Box>
        </Box>

                
        </CardContent>
        </CardActionArea>
       
        </Box> 
        
        <Box sx={{ display: 'flex', flexDirection: 'row', maxWidth: "15%", alignItems: 'center', textAlign: "center", backgroundColor: "rgba(43, 117, 177, 0.9)"}}>
        <CardActionArea component={RouterLink} to={`/Rankings/${doc.id}`}>   
       
            <Typography 
            sx={{
            
              color:"white", 
            padding: "6px", 
            marginLeft: "5px",
           fontSize: "15px"}}>
              Haz clic para acceder a toda la información sobre este despacho </Typography>
</CardActionArea>
        </Box> 
        
 
    </Card>
    </Box>

    <Box sx={{display: { xl: 'none', l: "none", m: "block", s:"block", xs: 'block' }}} >
        <Card variant="outlined" sx={{alignContent: "center", alignItems: "center", width: "300px"}}>
        <CardActionArea href={`/Rankings/${doc.id}`}>
        <CardContent
            
            sx={{padding:"20px", justifyContent: "center", alignItems: "center", textAlign: "center"}}
            
          >
          <Typography variant="subtitle1"  color="text.secondary">Valoración</Typography>
          <Typography variant="h5"  color="text.secondary">{doc.conciliacion}</Typography>
          <Typography variant="subtitle1"  color="text.secondary">/100</Typography>
          </CardContent>  
          
        <Box sx={{ display: 'flex', flexDirection: 'row', width: "300px", alignItems: 'center',
              alignContent: "center",
              justifyContent: "center",
              textAlign: "center"}}>

        <CardContent sx={{paddingTop:"0px", paddingBottom: "20px"}}>
        
                <Typography variant="h4" color="text.secondary" sx={{paddingTop:"0px", paddingBottom: "10px"}}>
                <Link
            style={{ display: "block", textDecoration: "none", color: "#636b70"}}
            to={`/Rankings/${doc.id}`}
            target="_blank"
            key={doc.id} onClick ={(e) => getFirmId(doc.id) }
          >
            {doc.inputValue}
          </Link>
                     
                </Typography>

                <Box sx={{}}>   
        <Box sx={{paddingTop: "14px"}}>
        
        <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px"}}>
            {doc.tipodespacho}
        </Typography>
        
        </Box>
        <Box sx={{paddingTop: "14px"}}>
        
        <Typography variant="caption" sx={{backgroundColor: "#03b1fc", borderRadius: "10px", color:"white", padding: "6px"}}>
           {doc.tipoderecho}
        </Typography>
        </Box>
        </Box>

                
        </CardContent>
   
       
        </Box> 
        
        <Box sx={{ display: 'flex', flexDirection: 'row', width: "300px", alignItems: 'center', textAlign: "center", backgroundColor: "rgba(43, 117, 177, 0.9)", margingTop: "10px"}}>
 
       
            <Typography 
            sx={{
            color:"white", 
            padding: "6px", 
            marginLeft: "5px",
           fontSize: "13px"}}>
              Haz clic para acceder a toda la información sobre este despacho </Typography>

        </Box> 
        
 </CardActionArea>
    </Card>
    </Box>

    </Stack>
        </div>
        ) 
      })
 
 
    return (
    
            <AuthProvider    
            onUserLoggedIn={handleUserLoggedIn}
            onUserNotRegistered={handleUserNotRegistered}
            onUserNotLoggedIn={handleUserNotLoggedIn}
            > 
                <CssBaseline>
        <Header />
        <Box sx={{display: { xl: 'block', l: "block", m: "none", s:"none", xs: 'none'}}} >
      <Grid container component="main" sx={{}}>
      <Grid item xs={2} sm={2} md={2} component={Paper} elevation={0} square sx={{paddingTop: "10px",  bgcolor: 'rgba(43, 117, 177, 0.9)' }}>
        </Grid>
      <Grid item xs={8} sm={8} md={8} component={Paper} elevation={0} square sx={{paddingBottom: "50px", paddingTop: "10px",  bgcolor: 'rgba(43, 117, 177, 0.9)' }}>
       
      <Stack sx={{ width: '100%', paddingTop: "20px" }} spacing={2}>
      <Typography variant="h4" 
              sx={{paddingTop: "10px", color: "white", textAlign: "center", fontWeight: "bold"}}
              >
              Los mejores despachos para trabajar en España
            </Typography>
              </Stack>

              <Stack sx={{ width: '100%'}} spacing={2}>
      <Typography variant="h6" 
              sx={{paddingTop: "10px", paddingBottom: "40px", color: "white", textAlign: "center", fontWeight: "bold"}}
              >
              La opinión de los abogados de los principales despachos
            </Typography>
              </Stack>
        
              <Stack sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              alignContent: "center",
              justifyContent: "center",
          
            }}>      
        <Card elevation={10} sx={{ marginRight: "20px", alignContent: "center"}}>
        <CardActionArea component={RouterLink} to={"/mejoresdespachosambientelaboral"} >
      <CardContent>
   
        <Typography variant="h5" component="div" color = "rgba(43, 117, 177, 0.9)" sx={{fontWeight: "bold"}} >
          Ambiente laboral
        </Typography>
      </CardContent>
      </CardActionArea>
    </Card>

    <Card elevation={10} sx={{ marginRight: "20px" }}>
        <CardActionArea component={RouterLink} to={"/mejoresdespachoscarrera"} >
      <CardContent>
      
        <Typography variant="h5" component="div" color = "rgba(43, 117, 177, 0.9)" sx={{fontWeight: "bold"}} >
          Carrera
        </Typography>
      </CardContent>
      </CardActionArea>
    </Card>

    <Card elevation={10} sx={{ marginRight: "20px" }}>
        <CardActionArea component={RouterLink} to={"/mejoresdespachosconciliacion"} >
      <CardContent>
 
        <Typography variant="h5" component="div" color = "rgba(43, 117, 177, 0.9)" sx={{fontWeight: "bold"}} >
          Conciliación
        </Typography>
        
      </CardContent>
      </CardActionArea>
    </Card>

    </Stack>
    <Stack sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              alignContent: "center",
              justifyContent: "center",
              paddingTop: "20px",
 
            }}>      
        <Card elevation={10} sx={{ marginRight: "20px", alignContent: "center"}}>
        <CardActionArea component={RouterLink} to={"/mejoresdespachosculturadefirma"} >
      <CardContent>
   
        <Typography variant="h5" component="div" color = "rgba(43, 117, 177, 0.9)" sx={{fontWeight: "bold"}} >
          Cultura de firma
        </Typography>
      </CardContent>
      </CardActionArea>
    </Card>

    <Card elevation={10} sx={{ marginRight: "20px" }}>
        <CardActionArea component={RouterLink} to={"/mejoresdespachosdiversidad"} >
      <CardContent>
      
        <Typography variant="h5" component="div" color = "rgba(43, 117, 177, 0.9)" sx={{fontWeight: "bold"}} >
          Diversidad
        </Typography>
      </CardContent>
      </CardActionArea>
    </Card>

    <Card elevation={10} sx={{ marginRight: "20px" }}>
        <CardActionArea component={RouterLink} to={"/mejoresdespachosformacion"} >
      <CardContent>
 
        <Typography variant="h5" component="div" color = "rgba(43, 117, 177, 0.9)" sx={{fontWeight: "bold"}} >
          Formación
        </Typography>
        
      </CardContent>
      </CardActionArea>
    </Card>

    </Stack>
    <Stack sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              alignContent: "center",
              justifyContent: "center",
              paddingTop: "20px",
            }}>      
        <Card elevation={10} sx={{ marginRight: "20px", alignContent: "center"}}>
        <CardActionArea component={RouterLink} to={"/mejoresdespachosigualdad"} >
      <CardContent>
   
        <Typography variant="h5" component="div" color = "rgba(43, 117, 177, 0.9)" sx={{fontWeight: "bold"}} >
          Igualdad
        </Typography>
      </CardContent>
      </CardActionArea>
    </Card>

    <Card elevation={10} sx={{ marginRight: "20px" }}>
        <CardActionArea component={RouterLink} to={"/mejoresdespachosprobono"} >
      <CardContent>
      
        <Typography variant="h5" component="div" color = "rgba(43, 117, 177, 0.9)" sx={{fontWeight: "bold"}} >
          Pro Bono
        </Typography>
      </CardContent>
      </CardActionArea>
    </Card>

    <Card elevation={10} sx={{ marginRight: "20px" }}>
        <CardActionArea component={RouterLink} to={"/mejoresdespachostipodetrabajo"} >
      <CardContent>
 
        <Typography variant="h5" component="div" color = "rgba(43, 117, 177, 0.9)" sx={{fontWeight: "bold"}} >
          Tipo de Trabajo
        </Typography>
        
      </CardContent>
      </CardActionArea>
    </Card>

    </Stack>
        
      </Grid>
      <Grid item xs={2} sm={2} md={2} component={Paper} elevation={0} square sx={{paddingTop: "10px",  bgcolor: 'rgba(43, 117, 177, 0.9)' }}>
        </Grid>
 
     </Grid>
    

     <Grid container component="main" sx={{ }}>
      <Grid item xs={2} sm={2} md={2} component={Paper} elevation={0} square sx={{}}>
        </Grid>
      <Grid item xs={8} sm={8} md={8} component={Paper} elevation={0} square sx={{}}>
       
      <Stack sx={{ width: '100%', paddingTop: "60px", display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              alignContent: "center",
              justifyContent: "center",
              paddingBottom: "40px"
             }} spacing={2}>
      <Card elevation={10} sx={{ marginRight: "20px", backgroundColor: "rgba(43, 117, 177, 0.9)"  }}>
        
      <CardContent>
      
        <Typography variant="h5" component="div" color = "white" sx={{fontWeight: "bold"}} >
          Los despachos mejor valorados en... conciliación
        </Typography>
      </CardContent>
     
    </Card>
   
    
      

    </Stack>

    

                {eachnota}


      </Grid>
      <Grid item xs={2} sm={2} md={2} component={Paper} elevation={0} square sx={{}}>
        </Grid>
 
     </Grid>
     

     <Grid container component="main" sx={{ }}>
      <Grid item xs={2} sm={2} md={2} component={Paper} elevation={0} square sx={{}}>
        </Grid>
      <Grid item xs={8} sm={8} md={8} component={Paper} elevation={0} square sx={{}}>
       
     
        
      </Grid>
      <Grid item xs={2} sm={2} md={2} component={Paper} elevation={0} square sx={{}}>
        </Grid>
 
     </Grid>
     </Box>



     <Box sx={{display: { xl: 'none', l: "none", m: "block", s:"block", xs: 'block'}}} >
      
    

     <Grid container component="main" sx={{ }}>
      <Grid item xs={1} sm={1} md={1} component={Paper} elevation={0} square sx={{}}>
        </Grid>
      <Grid item xs={10} sm={10} md={10} component={Paper} elevation={0} square sx={{}}>
       
      <Stack sx={{ width: '100%', paddingTop: "60px", display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              alignContent: "center",
              justifyContent: "center",
              paddingBottom: "40px",
              marginLeft: "14px"
             }} spacing={2}>
      <Card elevation={10} sx={{ marginRight: "20px", backgroundColor: "rgba(43, 117, 177, 0.9)"  }}>
        
      <CardContent>
      
        <Typography variant="h5" component="div" color = "white" sx={{fontWeight: "bold", textAlign: "center"}} >
          Los despachos mejor valorados en... conciliación
        </Typography>
      </CardContent>
     
    </Card>
   
    
      

    </Stack>

    

                {eachnota}


      </Grid>
      <Grid item xs={1} sm={1} md={1} component={Paper} elevation={0} square sx={{}}>
        </Grid>
 
     </Grid>


     <Grid container component="main" sx={{ }}>
      <Grid item xs={2} sm={2} md={2} component={Paper} elevation={0} square sx={{}}>
        </Grid>
      <Grid item xs={8} sm={8} md={8} component={Paper} elevation={0} square sx={{}}>
       
     
        
      </Grid>
      <Grid item xs={2} sm={2} md={2} component={Paper} elevation={0} square sx={{}}>
        </Grid>
 
     </Grid>
     </Box>
 


      <Footer />
            </CssBaseline>
    </AuthProvider>
            
            
           
    )
}
 
export default MejoresDespachosConciliacion