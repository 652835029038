// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { 
  getFirestore,
  collection,
  addDoc,
  getDocs,
  doc,
  getDoc,
  query,
  where,
  setDoc,
  deleteDoc,
 } from "firebase/firestore"
import { getAuth,  GoogleAuthProvider} from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import {
  getStorage, 
  ref, 
  uploadBytes, getDownloadURL,
  getBytes,
} from "firebase/storage";
import Rankings from "./pages/Rankings";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId:  process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();
export const storage = getStorage(app);
export default app;
export async function userExists(uid){
  const docRef = doc(db, "users", uid);
  const res = await getDoc(docRef);
  console.log(res);
  return res.exists();
}

export async function registerNewUser(user){
  try {
    const collectionRef = collection(db, "users");
    const docRef = doc(collectionRef, user.uid);
    await setDoc(docRef, user);
  } catch (error) {

  }
}

export async function updateUser(user){
  try{
    const collectionRef = collection(db, "users");
    const docRef = doc(collectionRef, user.uid);
    await setDoc(docRef, user);
  } catch (error) {

  }
}

export async function getUserInfo(uid){
  try{
    const docRef = doc(db, "users", uid);
    const document = await getDoc(docRef);
    return document.data();
  } catch (error) {

  }
}

export async function insertNewPerfil(perfil){
  try{
    const docRef = collection(db, "perfiles");
    const res = await addDoc(docRef, perfil);
    return res;
  } catch (error) {

  }
} 

export async function getPerfil(uid){
  const perfiles = [];
  try{
    const collectionRef = collection(db, "perfiles");
    const q = query(collectionRef, where ("uid", "==", uid));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach(doc => {
      const perfil = {...doc.data()};
      perfil.docId = doc.id;
      perfiles.push(perfil);
    })
    return perfiles;
  } catch (error) {

  }
}

export async function updatePerfil(docId, perfil) {
  try{
    const docRef = doc(db, "perfiles", docId);
    const res = await setDoc(docRef, perfil);
    return res;
  } catch (error) {

  }
}

export async function setLogo(despacho, file){
  try {
    const logoRef = ref(storage, `images/${despacho}`);
    await uploadBytes(logoRef, file);
    const url = await getDownloadURL(logoRef)
  return url;
  } catch (error){
    console.error(error);
  }
  
}

export async function getNotas(despacho) {
  const rankings = [];
  try {
    const collectionRef = collection(db, "rankings");
    const q= query(collectionRef, where("despacho", "==", despacho));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach(doc=> {
      const ranking = {...doc.data()};
      ranking.docId = doc.id;
      rankings.push(ranking);
    });
    return rankings;
  } catch (error) {
    console.error(error);
  }

}



export async function insertNewNota(nota){
  try{
    const docRef = collection(db, "rankings");
    const res = await addDoc(docRef, nota);
    return res;
  } catch (error) {

  }
} 

export async function getNota(uid){
  const notas = [];
  try{
    const collectionRef = collection(db, "rankings");
    const q = query(collectionRef, where ("uid", "==", uid));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach(doc => {
      const nota = {...doc.data()};
      nota.docId = doc.id;
      notas.push(nota);
    })
    return notas;
  } catch (error) {

  }
}

export async function getRanking(inputValue){
  const ranks = [];
  try{
    const collectionRef = collection(db, "notas");
    const q = query(collectionRef, where ("inputValue", "==", inputValue));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach(doc => {
      const rank = {...doc.data()};
      rank.docId = doc.id;
      ranks.push(rank);
    })
    return ranks;
  } catch (error) {

  }
}

export async function cancelarPremium(a){
  try{
    const docRef = collection(db, "cancelaciones");
    const res = await addDoc(docRef, a);
    return res;
  } catch (error) {

  }
} 

export async function sendCancellationEmail(email) {
  const collectionRef = collection(db, "mail");
  const emailContent = {
      to: ['contacto@esderecho.org'],
      message: {
        subject: 'Solicitud de cancelación',
        text: email,
      },
  };

  return await addDoc(collectionRef, emailContent);
}

export async function getJob(id){
  const jobToEdit = [];
  try{
    const collectionRef = collection(db, "trabajos");
    const q = query(collectionRef, where ("id", "==", id));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach(doc => {
      const job = {...doc.data()};
      job.docId = doc.id;
      jobToEdit.push(job);
    })
    return jobToEdit;
  } catch (error) {

  }
}


export async function updateTrabajo(docId, trabajo) {
  try{
    const docRef = doc(db, "trabajos", docId);
    const res = await setDoc(docRef, trabajo);
    return res;
  } catch (error) {

  }
}


const analytics = getAnalytics(app);