import React, { useEffect} from 'react';
import Header from '../components/Header'
import CssBaseline from '@mui/material/CssBaseline';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { CardActionArea } from '@mui/material';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import { Link as RouterLink } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import AuthProvider from '../components/authProvider';
import { useNavigate } from 'react-router';
import { useState } from 'react';
import Button from '@mui/material/Button';
import {v4 as uuidv4} from "uuid";
import { getPerfil, insertNewPerfil, updatePerfil } from '../firebase-config';
import Perfil from '../components/perfil';
import IconButton from '@mui/material/IconButton';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import listareas from './listareas';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Slider from '@mui/material/Slider';
import parse from "html-react-parser";
import getPaymentsByUID from '../components/getPaymentsByUID';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';


const Welcome2 = ( {getTrabajoId} ) => {
  
  
  const options = ['Perfil abierto (datos visibles para despachos, empresas y headhunters)', 'Perfil anónimo (datos identificativos ocultos)', "Perfil oculto"];
  const [value, setValue] = React.useState(options[0]);
  const [inputValue, setInputValue] = React.useState('');
  const [currentUser, setCurrentUser] = useState({});
  const [nombre, setNombre] = useState("");
  const [email, setEmail] = useState("");
  const [telefono, setTelefono] = useState("");
  const [ciudad, setCiudad] = useState("");
  const [perfiles, setPerfiles] = useState([ ]);
  const [visibilidad, setVisibilidad] = useState("Perfil abierto (datos visibles para despachos, empresas y headhunters)");
  const [carrera1, setCarrera1] = useState("");
  const [uni1, setUni1] = useState("");
  const [fechaInicio1, setFechaInicio1] = useState("");
  const [fechaFin1, setFechaFin1] = useState("");
  const [notaMedia1, setNotaMedia1] = useState("");
  const [carrera2, setCarrera2] = useState("");
  const [uni2, setUni2] = useState("");
  const [fechaInicio2, setFechaInicio2] = useState("");
  const [fechaFin2, setFechaFin2] = useState("");
  const [notaMedia2, setNotaMedia2] = useState("");
  const [carrera3, setCarrera3] = useState("");
  const [uni3, setUni3] = useState("");
  const [fechaInicio3, setFechaInicio3] = useState("");
  const [fechaFin3, setFechaFin3] = useState("");
  const [notaMedia3, setNotaMedia3] = useState("");
  const [carrera4, setCarrera4] = useState("");
  const [uni4, setUni4] = useState("");
  const [fechaInicio4, setFechaInicio4] = useState("");
  const [fechaFin4, setFechaFin4] = useState("");
  const [notaMedia4, setNotaMedia4] = useState("");
  const [notaMedia5, setNotaMedia5] = useState("");
  const [notaMedia6, setNotaMedia6] = useState("");
  const [notaMedia7, setNotaMedia7] = useState("");
  const [notaMedia8, setNotaMedia8] = useState("");
  const [carrera5, setCarrera5] = useState("");
  const [uni5, setUni5] = useState("");
  const [fechaInicio5, setFechaInicio5] = useState("");
  const [fechaFin5, setFechaFin5] = useState("");
  const [carrera6, setCarrera6] = useState("");
  const [uni6, setUni6] = useState("");
  const [fechaInicio6, setFechaInicio6] = useState("");
  const [fechaFin6, setFechaFin6] = useState("");
  const [carrera7, setCarrera7] = useState("");
  const [uni7, setUni7] = useState("");
  const [fechaInicio7, setFechaInicio7] = useState("");
  const [fechaFin7, setFechaFin7] = useState("");
  const [carrera8, setCarrera8] = useState("");
  const [uni8, setUni8] = useState("");
  const [fechaInicio8, setFechaInicio8] = useState("");
  const [fechaFin8, setFechaFin8] = useState("");
  const [carrera9, setCarrera9] = useState("");
  const [uni9, setUni9] = useState("");
  const [fechaInicio9, setFechaInicio9] = useState("");
  const [fechaFin9, setFechaFin9] = useState("");
  const [numGrados, setnumGrados] = useState(1);
  const [numPosGrados, setnumPosGrados] = useState(1);
  const [trabajo1, setTrabajo1] = useState("");
  const [empresa1, setEmpresa1] = useState("");
  const [fechaInicioT1, setFechaInicioT1] = useState("");
  const [fechaFinT1, setFechaFinT1] = useState("");
  const [trabajo2, setTrabajo2] = useState("");
  const [empresa2, setEmpresa2] = useState("");
  const [fechaInicioT2, setFechaInicioT2] = useState("");
  const [fechaFinT2, setFechaFinT2] = useState("");
  const [trabajo3, setTrabajo3] = useState("");
  const [empresa3, setEmpresa3] = useState("");
  const [fechaInicioT3, setFechaInicioT3] = useState("");
  const [fechaFinT3, setFechaFinT3] = useState("");
  const [trabajo4, setTrabajo4] = useState("");
  const [empresa4, setEmpresa4] = useState("");
  const [fechaInicioT4, setFechaInicioT4] = useState("");
  const [fechaFinT4, setFechaFinT4] = useState("");
  const [trabajo5, setTrabajo5] = useState("");
  const [empresa5, setEmpresa5] = useState("");
  const [fechaInicioT5, setFechaInicioT5] = useState("");
  const [fechaFinT5, setFechaFinT5] = useState("");
  const [trabajo6, setTrabajo6] = useState("");
  const [empresa6, setEmpresa6] = useState("");
  const [fechaInicioT6, setFechaInicioT6] = useState("");
  const [fechaFinT6, setFechaFinT6] = useState("");
  const [trabajo7, setTrabajo7] = useState("");
  const [empresa7, setEmpresa7] = useState("");
  const [fechaInicioT7, setFechaInicioT7] = useState("");
  const [fechaFinT7, setFechaFinT7] = useState("");
  const [trabajo8, setTrabajo8] = useState("");
  const [empresa8, setEmpresa8] = useState("");
  const [fechaInicioT8, setFechaInicioT8] = useState("");
  const [fechaFinT8, setFechaFinT8] = useState("");
  const [descripcion1, setDescripcion1] = useState("");
  const [descripcion2, setDescripcion2] = useState("");
  const [descripcion3, setDescripcion3] = useState("");
  const [descripcion4, setDescripcion4] = useState("");
  const [descripcion5, setDescripcion5] = useState("");
  const [descripcion6, setDescripcion6] = useState("");
  const [descripcion7, setDescripcion7] = useState("");
  const [descripcion8, setDescripcion8] = useState("");
  const [trabajo9, setTrabajo9] = useState("");
  const [empresa9, setEmpresa9] = useState("");
  const [fechaInicioT9, setFechaInicioT9] = useState("");
  const [fechaFinT9, setFechaFinT9] = useState("");
  const [numTrabajos, setnumTrabajos] = useState(1);
  const [area, setArea] = useState("");
  const [castellano, setCastellano] = useState("");
  const [ingles, setIngles] = useState("");
  const [presentacion, setPresentacion] = useState("");
  const [anos, setAnos] = useState("");
  const [idioma1, setIdioma1] = useState("");
  const [nivelIdioma1, setNivelIdioma1] = useState("");
  const [idioma2, setIdioma2] = useState("");
  const [nivelIdioma2, setNivelIdioma2] = useState("");
  const navigate = useNavigate();
  const initialText = 'Guardar cambios';
  const [buttonText, setButtonText] = useState(initialText);
  const [payments, setPayments] = useState([]);
  const [payment1, setPayment1] = useState([]);
  const [payment2, setPayment2] = useState([]);
  const [isPremium, setIsPremium] = useState(false);
  const [isOldPremium, setIsOldPremium] = useState(false);
  const [open, setOpen] = React.useState(true);

  const anosExperiencia = [
    {
      value: 0,
      label: '0',
    },
    {
      value: 2,
      label: '2',
    },
    {
      value: 4,
      label: '4',
    },
    {
      value: 6,
      label: '6',
    },
    {
      value: 8,
      label: '8',
    },
    {
      value: 10,
      label: '10',
    },
    {
      value: 12,
      label: '12',
    },
    {
      value: 14,
      label: '14',
    },
    {
      value: 16,
      label: '16',
    },
    {
      value: 18,
      label: '18',
    },
    {
      value: 20,
      label: '+20',
    },
  ];

  const anosExperienciaMovil = [
    {
      value: 0,
      label: '0',
    },
    {
      value: 5,
      label: '5',
    },
    {
      value: 10,
      label: '10',
    },
    {
      value: 15,
      label: '15',
    },
    {
      value: 20,
      label: '+20',
    },
  ];

  function valuetext(value: number) {
    return `${value}k`;
  }

  async function handleUserLoggedIn(user) {
    setCurrentUser(user);
    console.log("Estás registrado");
    console.log(user.displayName);
    console.log(perfiles.length);
    const resPerfil = await getPerfil(user.uid);
    setPerfiles([...resPerfil]);
    console.log(perfiles); 
    console.log(perfiles[0]);
    const payments = await getPaymentsByUID(user.uid);
    setPayments([...payments])
    console.log(perfiles);
    console.log()
    setIsOldPremium(user.premiumUser)


    console.log(perfiles.nombre);
    payments.map((payment) => {
      payment1.push(payment.status)
      payment2.push(payment.current_period_end)
  
    })
  
  console.log(payment1)
  const premium = (element) => (element === "active" || element === "trialing")
  setIsPremium(payment1.some(premium))
  console.log(isPremium)
   }

   useEffect(() => {
    async function getPayments() {
      if (!currentUser) return;
      console.log("user por usar", currentUser);
      const payments = await getPaymentsByUID(currentUser);
      setPayments(payments);
    }
    getPayments();
    console.log(payments)
    
  }, []);

   function handleUserNotRegistered(user) {
    navigate("../signin");

   }
   
   function handleUserNotLoggedIn() {
    navigate("../");
   }


   function handleChange(e) {
    const value = e.target.value;
    if(e.target.name === "nombre"){
      setNombre(value)
      console.log(nombre);
    }
    if(e.target.name === "telefono"){
      setTelefono(value)
      console.log(telefono)
    }

    if(e.target.name === "ciudad"){
      setCiudad(value)
      console.log(ciudad)
    }

    if(e.target.name === "email"){
      setEmail(value)
    }
    if(e.target.name === "carrera1"){
      setCarrera1(value)
    }
    if(e.target.name === "uni1"){
      setUni1(value)
    }
    if(e.target.name === "fechaInicio1"){
      setFechaInicio1(value)
    }
    if(e.target.name === "fechaFin1"){
      setFechaFin1(value)
    }
    if(e.target.name === "notaMedia1"){
      setNotaMedia1(value)
    }
    if(e.target.name === "carrera2"){
      setCarrera2(value)
    }
    if(e.target.name === "uni2"){
      setUni2(value)
    }
    if(e.target.name === "fechaInicio2"){
      setFechaInicio2(value)
    }
    if(e.target.name === "fechaFin2"){
      setFechaFin2(value)
    }
    if(e.target.name === "notaMedia2"){
      setNotaMedia2(value)
    }
    if(e.target.name === "notaMedia3"){
      setNotaMedia3(value)
    }
    if(e.target.name === "notaMedia4"){
      setNotaMedia4(value)
    }
    if(e.target.name === "notaMedia5"){
      setNotaMedia5(value)
    }
    if(e.target.name === "notaMedia6"){
      setNotaMedia6(value)
    }
    if(e.target.name === "notaMedia7"){
      setNotaMedia7(value)
    }
    if(e.target.name === "notaMedia8"){
      setNotaMedia8(value)
    }
    if(e.target.name === "carrera3"){
      setCarrera3(value)
    }
    if(e.target.name === "uni3"){
      setUni3(value)
    }
    if(e.target.name === "fechaInicio3"){
      setFechaInicio3(value)
    }
    if(e.target.name === "fechaFin3"){
      setFechaFin3(value)
    }
    if(e.target.name === "carrera4"){
      setCarrera4(value)
    }
    if(e.target.name === "uni4"){
      setUni4(value)
    }
    if(e.target.name === "fechaInicio4"){
      setFechaInicio4(value)
    }
    if(e.target.name === "fechaFin4"){
      setFechaFin4(value)
    }
    if(e.target.name === "carrera5"){
      setCarrera5(value)
    }
    if(e.target.name === "uni5"){
      setUni5(value)
    }
    if(e.target.name === "fechaInicio5"){
      setFechaInicio5(value)
    }
    if(e.target.name === "fechaFin5"){
      setFechaFin5(value)
    }
    if(e.target.name === "carrera6"){
      setCarrera6(value)
    }
    if(e.target.name === "uni6"){
      setUni6(value)
    }
    if(e.target.name === "fechaInicio6"){
      setFechaInicio6(value)
    }
    if(e.target.name === "fechaFin6"){
      setFechaFin6(value)
    }
    if(e.target.name === "carrera7"){
      setCarrera7(value)
    }
    if(e.target.name === "uni7"){
      setUni7(value)
    }
    if(e.target.name === "fechaInicio7"){
      setFechaInicio7(value)
    }
    if(e.target.name === "fechaFin7"){
      setFechaFin7(value)
    }
    if(e.target.name === "carrera8"){
      setCarrera8(value)
    }
    if(e.target.name === "uni8"){
      setUni8(value)
    }
    if(e.target.name === "fechaInicio8"){
      setFechaInicio8(value)
    }
    if(e.target.name === "fechaFin8"){
      setFechaFin8(value)
    }
    if(e.target.name === "carrera9"){
      setCarrera9(value)
    }
    if(e.target.name === "uni9"){
      setUni9(value)
    }
    if(e.target.name === "fechaInicio9"){
      setFechaInicio9(value)
    }
    if(e.target.name === "fechaFin9"){
      setFechaFin9(value)
    }
    if(e.target.name === "trabajo1"){
      setTrabajo1(value)
    }
    if(e.target.name === "empresa1"){
      setEmpresa1(value)
    }
    if(e.target.name === "fechaInicioT1"){
      setFechaInicioT1(value)
    }
    if(e.target.name === "fechaFinT1"){
      setFechaFinT1(value)
    }
    if(e.target.name === "descripcion1"){
      setDescripcion1(value)
    }
    if(e.target.name === "trabajo2"){
      setTrabajo2(value)
    }
    if(e.target.name === "empresa2"){
      setEmpresa2(value)
    }
    if(e.target.name === "fechaInicioT2"){
      setFechaInicioT2(value)
    }
    if(e.target.name === "fechaFinT2"){
      setFechaFinT2(value)
    }
    if(e.target.name === "descripcion2"){
      setDescripcion2(value)
    }
    if(e.target.name === "trabajo3"){
      setTrabajo3(value)
    }
    if(e.target.name === "empresa3"){
      setEmpresa3(value)
    }
    if(e.target.name === "fechaInicioT3"){
      setFechaInicioT3(value)
    }
    if(e.target.name === "fechaFinT3"){
      setFechaFinT3(value)
    }
    if(e.target.name === "descripcion3"){
      setDescripcion3(value)
    }
    if(e.target.name === "trabajo4"){
      setTrabajo4(value)
    }
    if(e.target.name === "empresa4"){
      setEmpresa4(value)
    }
    if(e.target.name === "fechaInicioT4"){
      setFechaInicioT4(value)
    }
    if(e.target.name === "fechaFinT4"){
      setFechaFinT4(value)
    }
    if(e.target.name === "descripcion4"){
      setDescripcion4(value)
    }
    if(e.target.name === "trabajo5"){
      setTrabajo5(value)
    }
    if(e.target.name === "empresa5"){
      setEmpresa5(value)
    }
    if(e.target.name === "fechaInicioT5"){
      setFechaInicioT5(value)
    }
    if(e.target.name === "fechaFinT5"){
      setFechaFinT5(value)
    }
    if(e.target.name === "descripcion5"){
      setDescripcion5(value)
    }
    if(e.target.name === "trabajo6"){
      setTrabajo6(value)
    }
    if(e.target.name === "empresa6"){
      setEmpresa6(value)
    }
    if(e.target.name === "fechaInicioT6"){
      setFechaInicioT6(value)
    }
    if(e.target.name === "fechaFinT6"){
      setFechaFinT6(value)
    }
    if(e.target.name === "descripcion6"){
      setDescripcion6(value)
    }
    if(e.target.name === "trabajo7"){
      setTrabajo7(value)
    }
    if(e.target.name === "empresa7"){
      setEmpresa7(value)
    }
    if(e.target.name === "fechaInicioT7"){
      setFechaInicioT7(value)
    }
    if(e.target.name === "fechaFinT7"){
      setFechaFinT7(value)
    }
    if(e.target.name === "descripcion7"){
      setDescripcion7(value)
    }
    if(e.target.name === "trabajo8"){
      setTrabajo8(value)
    }
    if(e.target.name === "empresa8"){
      setEmpresa8(value)
    }
    if(e.target.name === "fechaInicioT8"){
      setFechaInicioT8(value)
    }
    if(e.target.name === "fechaFinT8"){
      setFechaFinT8(value)
    }
    if(e.target.name === "descripcion8"){
      setDescripcion8(value)
    }
    if(e.target.name === "presentacion"){
      setPresentacion(value)
    }
    if(e.target.name === "idioma1"){
      setIdioma1(value)
    }
    if(e.target.name === "nivelIdioma1"){
      setNivelIdioma1(value)
    }
    if(e.target.name === "idioma2"){
      setIdioma2(value)
    }
    if(e.target.name === "nivelIdioma2"){
      setNivelIdioma2(value)
    }
   }

   
   const handleChangeCastellano = (event) => {
    setCastellano(event.target.value);
    console.log(event.target.value);
   }

   const handleChangeIngles = (event) => {
    setIngles(event.target.value);
    console.log(event.target.value);
   }

   const handleChangeNivelIdioma1 = (event) => {
    setNivelIdioma1(event.target.value);
 
   }

   const handleChangeNivelIdioma2 = (event) => {
    setNivelIdioma2(event.target.value);
 
   }
   
   function handleButtonAddGrado () {
    const newnumGrados = numGrados + 1;
    setnumGrados(newnumGrados);
    console.log(numGrados);
   }

   function handleButtonRemoveGrado () {
    const newnumGrados = numGrados - 1;
    setnumGrados(newnumGrados);
    console.log(numGrados);
   }

   function handleButtonRemoveGrado2 () {
    const newNumGrados = numGrados - 1;
    setnumGrados(newNumGrados);
    setCarrera2(""); 
      setUni2("");
      setFechaInicio2(""); 
      setFechaFin2("");
      setNotaMedia2("");
   }

   function handleButtonRemoveGrado3 () {
    const newNumGrados = numGrados - 1;
    setnumGrados(newNumGrados);
    setCarrera3(""); 
      setUni3("");
      setFechaInicio3(""); 
      setFechaFin3("");
      setNotaMedia3("");
   }
   function handleButtonRemoveGrado4 () {
    const newNumGrados = numGrados - 1;
    setnumGrados(newNumGrados);
    setCarrera4(""); 
      setUni4("");
      setFechaInicio4(""); 
      setFechaFin4("");
      setNotaMedia4("");
   }

   function handleButtonAddPosGrado () {
    const newnumPosGrados = numPosGrados + 1;
    setnumPosGrados(newnumPosGrados);
    console.log(numGrados);
   }

   function handleButtonRemovePosGrado () {
    const newnumPosGrados = numPosGrados - 1;
    setnumPosGrados(newnumPosGrados);
    console.log(numGrados);
   }

   function handleButtonRemovePosGrado2 () {
    const newNumPosGrados = numPosGrados - 1;
    setnumPosGrados(newNumPosGrados);
    setCarrera6(""); 
      setUni6("");
      setFechaInicio6(""); 
      setFechaFin6("");
      setNotaMedia6("");
   }
   function handleButtonRemovePosGrado3 () {
    const newNumPosGrados = numPosGrados - 1;
    setnumPosGrados(newNumPosGrados);
    setCarrera7(""); 
      setUni7("");
      setFechaInicio7(""); 
      setFechaFin7("");
      setNotaMedia7("");
   }

   function handleButtonRemovePosGrado4 () {
    const newNumPosGrados = numPosGrados - 1;
    setnumPosGrados(newNumPosGrados);
    setCarrera8(""); 
      setUni8("");
      setFechaInicio8(""); 
      setFechaFin8("");
      setNotaMedia8("");
   }

   function handleButtonAddTrabajo () {
    const newnumTrabajos = numTrabajos + 1;
    setnumTrabajos(newnumTrabajos);
    console.log(numTrabajos);
   }

   function handleButtonRemoveTrabajo () {
    const newnumTrabajos = numTrabajos - 1;
    setnumTrabajos(newnumTrabajos);
    console.log(numGrados);
   }

   function handleButtonRemoveTrabajo2 () {
    const newNumTrabajos = numTrabajos - 1;
    setnumTrabajos(newNumTrabajos);
    setTrabajo2(""); 
      setEmpresa2("");
      setFechaInicioT2(""); 
      setFechaFinT2("");
      setDescripcion2("");
   }

   function handleButtonRemoveTrabajo3 () {
    const newNumTrabajos = numTrabajos - 1;
    setnumTrabajos(newNumTrabajos);
    setTrabajo3(""); 
      setEmpresa3("");
      setFechaInicioT3(""); 
      setFechaFinT3("");
      setDescripcion3("");
   }

   function handleButtonRemoveTrabajo4 () {
    const newNumTrabajos = numTrabajos - 1;
    setnumTrabajos(newNumTrabajos);
    setTrabajo4(""); 
      setEmpresa4("");
      setFechaInicioT4(""); 
      setFechaFinT4("");
      setDescripcion4("");
   }
   function handleButtonRemoveTrabajo5 () {
    const newNumTrabajos = numTrabajos - 1;
    setnumTrabajos(newNumTrabajos);
    setTrabajo5(""); 
      setEmpresa5("");
      setFechaInicioT5(""); 
      setFechaFinT5("");
      setDescripcion5("");
   }
   function handleButtonRemoveTrabajo6 () {
    const newNumTrabajos = numTrabajos - 1;
    setnumTrabajos(newNumTrabajos);
    setTrabajo6(""); 
      setEmpresa6("");
      setFechaInicioT6(""); 
      setFechaFinT6("");
      setDescripcion6("");
   }
   function handleButtonRemoveTrabajo7 () {
    const newNumTrabajos = numTrabajos - 1;
    setnumTrabajos(newNumTrabajos);
    setTrabajo7(""); 
      setEmpresa7("");
      setFechaInicioT7(""); 
      setFechaFinT7("");
      setDescripcion7("");
   }
   function handleButtonRemoveTrabajo8 () {
    const newNumTrabajos = numTrabajos - 1;
    setnumTrabajos(newNumTrabajos);
    setTrabajo8(""); 
      setEmpresa8("");
      setFechaInicioT8(""); 
      setFechaFinT8("");
      setDescripcion8("");
   }

   const handleChangeExperiencia = (event, newAnos) => {
    setAnos(newAnos);
  };



   async function handleUpdatePerfil (docId, 
    nombre, 
    telefono,
    ciudad, 
    visibilidad, 
    email, 
    carrera1, 
    uni1, 
    fechaInicio1, 
    fechaFin1,
    carrera2, 
    uni2, 
    fechaInicio2, 
    fechaFin2,
    carrera3, 
    uni3, 
    fechaInicio3, 
    fechaFin3,
    carrera4, 
    uni4, 
    fechaInicio4, 
    fechaFin4,
    carrera5, 
    uni5, 
    fechaInicio5, 
    fechaFin5,
    carrera6, 
    uni6, 
    fechaInicio6, 
    fechaFin6,
    carrera7, 
    uni7, 
    fechaInicio7, 
    fechaFin7,
    carrera8, 
    uni8, 
    fechaInicio8, 
    fechaFin8,
    carrera9, 
    uni9, 
    fechaInicio9, 
    fechaFin9,
    numGrados,
    numPosGrados,
    numTrabajos,
    area,
    castellano,
    ingles,
    notaMedia1,
    notaMedia2,
    notaMedia3,
    notaMedia4,
    notaMedia5,
    notaMedia6,
    notaMedia7,
    notaMedia8,
    trabajo1,
    empresa1,
    fechaInicioT1,
    fechaFinT1,
    descripcion1,
    trabajo2,
    empresa2,
    fechaInicioT2,
    fechaFinT2,
    descripcion2,
    trabajo3,
    empresa3,
    fechaInicioT3,
    fechaFinT3,
    descripcion3,
    trabajo4,
    empresa4,
    fechaInicioT4,
    fechaFinT4,
    descripcion4,
    trabajo5,
    empresa5,
    fechaInicioT5,
    fechaFinT5,
    descripcion5,
    trabajo6,
    empresa6,
    fechaInicioT6,
    fechaFinT6,
    descripcion6,
    trabajo7,
    empresa7,
    fechaInicioT7,
    fechaFinT7,
    descripcion7,
    trabajo8,
    empresa8,
    fechaInicioT8,
    fechaFinT8,
    descripcion8,
    presentacion,
    anos,
    idioma1,
    nivelIdioma1,
    idioma2,
    nivelIdioma2,
    ) {
    const perfil = perfiles.find((item) => item.docId === docId)
    perfil.nombre = nombre;
    perfil.telefono = telefono;
    perfil.ciudad = ciudad;
    perfil.visibilidad = visibilidad;
    perfil.email = email;
    perfil.notaMedia1 = notaMedia1;
    perfil.carrera1 = carrera1;
    perfil.uni1 = uni1;
    perfil.fechaInicio1 = fechaInicio1;
    perfil.fechaFin1 = fechaFin1;
    perfil.carrera2 = carrera2;
    perfil.uni2 = uni2;
    perfil.fechaInicio2 = fechaInicio2;
    perfil.fechaFin2 = fechaFin2;
    perfil.notaMedia2 = notaMedia2;
    perfil.notaMedia3 = notaMedia3;
    perfil.notaMedia4 = notaMedia4;
    perfil.notaMedia5 = notaMedia5;
    perfil.notaMedia6 = notaMedia6;
    perfil.notaMedia7 = notaMedia7;
    perfil.notaMedia8 = notaMedia8;
    perfil.carrera3 = carrera3;
    perfil.uni3 = uni3;
    perfil.fechaInicio3 = fechaInicio3;
    perfil.fechaFin3 = fechaFin3;
    perfil.carrera4 = carrera4;
    perfil.uni4 = uni4;
    perfil.fechaInicio4 = fechaInicio4;
    perfil.fechaFin4 = fechaFin4;
    perfil.carrera5 = carrera5;
    perfil.uni5 = uni5;
    perfil.fechaInicio5 = fechaInicio5;
    perfil.fechaFin5 = fechaFin5;
    perfil.carrera6 = carrera6;
    perfil.uni6 = uni6;
    perfil.fechaInicio6 = fechaInicio6;
    perfil.fechaFin6 = fechaFin6;
    perfil.carrera7 = carrera7;
    perfil.uni7 = uni7;
    perfil.fechaInicio7 = fechaInicio7;
    perfil.fechaFin7 = fechaFin7;
    perfil.carrera8 = carrera8;
    perfil.uni8 = uni8;
    perfil.fechaInicio8 = fechaInicio8;
    perfil.fechaFin8 = fechaFin8;
    perfil.carrera9 = carrera9;
    perfil.uni9 = uni9;
    perfil.fechaInicio9 = fechaInicio9;
    perfil.fechaFin9 = fechaFin9;
    perfil.trabajo1 = trabajo1;
    perfil.empresa1 = empresa1;
    perfil.fechaInicioT1 = fechaInicioT1;
    perfil.fechaFinT1 = fechaFinT1;
    perfil.descripcion1 = descripcion1;
    perfil.trabajo2 = trabajo2;
    perfil.empresa2 = empresa2;
    perfil.fechaInicioT2 = fechaInicioT2;
    perfil.fechaFinT2 = fechaFinT2;
    perfil.descripcion2 = descripcion2;
    perfil.trabajo3 = trabajo3;
    perfil.empresa3 = empresa3;
    perfil.fechaInicioT3 = fechaInicioT3;
    perfil.fechaFinT3 = fechaFinT3;
    perfil.descripcion3 = descripcion3;
    perfil.trabajo4 = trabajo4;
    perfil.empresa4 = empresa4;
    perfil.fechaInicioT4 = fechaInicioT4;
    perfil.fechaFinT4 = fechaFinT4;
    perfil.descripcion4 = descripcion4;
    perfil.trabajo5 = trabajo5;
    perfil.empresa5 = empresa5;
    perfil.fechaInicioT5 = fechaInicioT5;
    perfil.fechaFinT5 = fechaFinT5;
    perfil.descripcion5 = descripcion5;
    perfil.trabajo6 = trabajo6;
    perfil.empresa6 = empresa6;
    perfil.fechaInicioT6 = fechaInicioT6;
    perfil.fechaFinT6 = fechaFinT6;
    perfil.descripcion6 = descripcion6;
    perfil.trabajo7 = trabajo7;
    perfil.empresa7 = empresa7;
    perfil.fechaInicioT7 = fechaInicioT7;
    perfil.fechaFinT7 = fechaFinT7;
    perfil.descripcion7 = descripcion7;
    perfil.trabajo8 = trabajo8;
    perfil.empresa8 = empresa8;
    perfil.fechaInicioT8 = fechaInicioT8;
    perfil.fechaFinT8 = fechaFinT8;
    perfil.descripcion8 = descripcion8;        
    perfil.numGrados = numGrados;
    perfil.numPosGrados = numPosGrados;
    perfil.numTrabajos = numTrabajos;
    perfil.area = area;
    perfil.castellano = castellano;
    perfil.ingles = ingles;
    perfil.presentacion = presentacion;
    perfil.anos = anos;
    perfil.idioma1 = idioma1;
    perfil.nivelIdioma1 = nivelIdioma1;
    perfil.idioma2 = idioma2;
    perfil.nivelIdioma2 = nivelIdioma2;
    await updatePerfil(docId, perfil);
    setNombre(nombre);
   }

    console.log(numGrados);
    console.log(perfiles.length)
    console.log(perfiles)
  
     function addPerfil(){
      const newPerfil = {
        id: uuidv4(),
        nombre: nombre,
        telefono: telefono,
        ciudad: ciudad,
        uid: currentUser.uid,
        visibilidad: visibilidad,
        email: email,
        carrera1: carrera1,
        uni1: uni1,
        fechaInicio1: fechaInicio1,
        fechaFin1: fechaFin1,
        notaMedia1: notaMedia1,
        carrera2: carrera2,
        uni2: uni2,
        fechaInicio2: fechaInicio2,
        fechaFin2: fechaFin2,
        notaMedia2: notaMedia2,
        notaMedia3: notaMedia3,
        notaMedia4: notaMedia4,
        notaMedia5: notaMedia5,
        notaMedia6: notaMedia6,
        notaMedia7: notaMedia7,
        notaMedia8: notaMedia8,
        carrera3: carrera3,
        uni3: uni3,
        fechaInicio3: fechaInicio3,
        fechaFin3: fechaFin3,
        carrera4: carrera4,
        uni4: uni4,
        fechaInicio4: fechaInicio4,
        fechaFin4: fechaFin4,
        carrera5: carrera5,
        uni5: uni5,
        fechaInicio5: fechaInicio5,
        fechaFin5: fechaFin5,
        carrera6: carrera6,
        uni6: uni6,
        fechaInicio6: fechaInicio6,
        fechaFin6: fechaFin6,
        carrera7: carrera7,
        uni7: uni7,
        fechaInicio7: fechaInicio7,
        fechaFin7: fechaFin7,
        carrera8: carrera8,
        uni8: uni8,
        fechaInicio8: fechaInicio8,
        fechaFin8: fechaFin8,
        carrera9: carrera9,
        uni9: uni9,
        fechaInicio9: fechaInicio9,
        fechaFin9: fechaFin9,
        trabajo1: trabajo1,
        empresa1: empresa1,
        fechaInicioT1: fechaInicioT1,
        fechaFinT1: fechaFinT1,
        descripcion1: descripcion1,
        trabajo2: trabajo2,
        empresa2: empresa2,
        fechaInicioT2: fechaInicioT2,
        fechaFinT2: fechaFinT2,
        descripcion2: descripcion2,
        trabajo3: trabajo3,
        empresa3: empresa3,
        fechaInicioT3: fechaInicioT3,
        fechaFinT3: fechaFinT3,
        descripcion3: descripcion3,
        trabajo4: trabajo4,
        empresa4: empresa4,
        fechaInicioT4: fechaInicioT4,
        fechaFinT4: fechaFinT4,
        descripcion4: descripcion4,
        trabajo5: trabajo5,
        empresa5: empresa5,
        fechaInicioT5: fechaInicioT5,
        fechaFinT5: fechaFinT5,
        descripcion5: descripcion5,
        trabajo6: trabajo6,
        empresa6: empresa6,
        fechaInicioT6: fechaInicioT6,
        fechaFinT6: fechaFinT6,
        descripcion6: descripcion6,
        trabajo7: trabajo7,
        empresa7: empresa7,
        fechaInicioT7: fechaInicioT7,
        fechaFinT7: fechaFinT7,
        descripcion7: descripcion7,
        trabajo8: trabajo8,
        empresa8: empresa8,
        fechaInicioT8: fechaInicioT8,
        fechaFinT8: fechaFinT8,
        descripcion8: descripcion8,
        numGrados: numGrados,
        numPosGrados: numPosGrados,
        numTrabajos: numTrabajos, 
        area: area,
        castellano: castellano,
        ingles: ingles,
        presentacion: presentacion,
        anos: anos,
        idioma1: idioma1,
        nivelIdioma1: nivelIdioma1,
        idioma2: idioma2,
        nivelIdioma2: nivelIdioma2, 
  
      };
      const res = insertNewPerfil(newPerfil);
      newPerfil.docId = res.id;
      setPerfiles([...perfiles, newPerfil]);
     }
 
     function handleSubmit (e) {
      e.preventDefault();
      addPerfil();
      navigate("../dashboard");
      setButtonText("¡Guardado!");
      console.log(area)
      console.log(perfiles)
      
     }

  return (


    <AuthProvider 
    onUserLoggedIn={handleUserLoggedIn}
    onUserNotRegistered={handleUserNotRegistered}
    onUserNotLoggedIn={handleUserNotLoggedIn}
    > 
    <CssBaseline>

      
      <Box sx={{display: { xl: 'block', l: "block", m: "none", s:"none", xs: 'none'}}} >
   
      <Grid container component="main" sx={{ height: '100vh'}}>

      <Grid item xs={12} sm={12} md={12} component={Paper} elevation={0} square sx={{paddingTop: "10px",  bgcolor: '#367bb3', backgroundImage: 'url(https://firebasestorage.googleapis.com/v0/b/jobsandlaw-5e0ba.appspot.com/o/images%2FDise%C3%B1o%20sin%20t%C3%ADtulo.png?alt=media&token=232398ba-9873-4c9b-a61e-cd0e280c2be5)',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat' }}>
       
      
        
             
              <Dialog
        open={open}
        
        sx={{
       
          display: {
            xl: "block",
            l: "block",
            m: "block",
            s: "none",
            xs: "none",
          },
        }}>
          <DialogContent sx={{}}>

<div>
<Box sx={{display: { xl: 'block', l: "block", m: "block", s:"none", xs: 'none'}}} >

     <Grid container component="main" sx={{ }}>
      <Grid item xs={12} sm={12} md={12} component={Paper} elevation={0} square sx={{}}>
       
      <Stack sx={{ paddingTop: "20px" }} spacing={2}>
      <Typography variant="h6" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "10px", textAlign: "center"}}
              >Para mostrarte las vacantes que más te interesan, <br></br> necesitamos saber algunas cosas sobre ti.
            </Typography>
      
            <Typography variant="p" color = "rgba(43, 117, 177, 0.9)"
              sx={{textAlign: "center", fontSize: "12px"}}
              >*La confidencialidad está garantizada.
            </Typography>
            
              </Stack>
           
           
             <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: "center",
              alignItems: "center",
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          >
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{mt: 1, marginBottom: "20px", borderRadius: "25px", padding: "20px"}}>
        

      <Typography variant="body1" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", textAlign: "center"}}
              >Indica tus áreas de práctica
            </Typography>

       <Autocomplete
          multiple
          sx={{paddingTop: "20px", paddingBottom: "20px"}}
          id="tags-standard"
          options={listareas}
          getOptionLabel={(option) => option.title}
          onChange={(e, value)=> setArea(value)}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Áreas de práctica"
              placeholder="Áreas de práctica"
            />
          )}
        />
 
      <Typography variant="body1" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "40px", textAlign: "center"}}
              >Indica tus años de experiencia
            </Typography>
<Slider
        getAriaLabel={() => 'Años de experiencia'}
        value={anos}
        onChange={handleChangeExperiencia}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
        step={1}
        min={0}
        max={20}
        marks={anosExperiencia}
      />
      <Typography variant="body1" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "40px", textAlign: "center"}}
              >Indica tu despacho / empresa actual
            </Typography>
<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green", textAlign: "center"}}
                margin="normal"
          
                fullWidth
                id="empresa1"
                label="Despacho / Empresa Actual"
                name="empresa1"
                autoComplete="empresa1"
             
                onChange={handleChange}
              />

<Typography variant="body1" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "40px", textAlign: "center"}}
              >Indica tu ciudad
            </Typography>
<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green", textAlign: "center"}}
                margin="normal"
          
                fullWidth
                id="ciudad"
                label="Ciudad"
                name="ciudad"
                autoComplete="ciudad"
             
                onChange={handleChange}
              />

          
   
        

{(area !== null && anos !== "" && empresa1 !== "" && ciudad !== "") && <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, backgroundColor: "rgba(43, 117, 177, 0.9)", color: "white", marginTop: "50px"}}

              >
                Siguiente
              </Button>}

              {(area === null || anos === "" || empresa1 === "" || ciudad === "") && <Button
                type="submit"
                fullWidth
                variant="contained"
                disabled
                sx={{ mt: 3, mb: 2, backgroundColor: "grey", color: "white", marginTop: "50px"}}

              >
                Completa los campos
              </Button>}

            </Box>
          </Box>
                    
          
          
        
      </Grid>

     
       
 
     </Grid>



</Box>











                
                 </div>
                 </DialogContent>
</Dialog>




<Dialog
        open={open}
        
        sx={{
       
          display: {
            xl: "none",
            l: "none",
            m: "none",
            s: "block",
            xs: "block",
          },
        }}>
          <DialogContent sx={{}}>

<div>
<Box sx={{display: { xl: 'none', l: "none", m: "none", s:"block", xs: 'block'}}} >

     <Grid container component="main" sx={{ }}>
      <Grid item xs={12} sm={12} md={12} component={Paper} elevation={0} square sx={{}}>
       
      <Stack sx={{ paddingTop: "20px" }} spacing={2}>
      <Typography variant="h6" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "10px", textAlign: "center"}}
              >Para mostrarte las vacantes que más te interesan, <br></br> necesitamos saber algunas cosas sobre ti.
            </Typography>
      
            <Typography variant="p" color = "rgba(43, 117, 177, 0.9)"
              sx={{textAlign: "center", fontSize: "12px"}}
              >*La confidencialidad está garantizada.
            </Typography>
            
              </Stack>
           
           
             <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: "center",
              alignItems: "center",
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          >
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{mt: 1, marginBottom: "20px", borderRadius: "25px", padding: "20px"}}>
        

      <Typography variant="body1" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", textAlign: "center"}}
              >Indica tus áreas de práctica
            </Typography>

       <Autocomplete
          multiple
          sx={{paddingTop: "20px", paddingBottom: "20px"}}
          id="tags-standard"
          options={listareas}
          getOptionLabel={(option) => option.title}
          onChange={(e, value)=> setArea(value)}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Áreas de práctica"
              placeholder="Áreas de práctica"
            />
          )}
        />
 
      <Typography variant="body1" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "40px", textAlign: "center"}}
              >Indica tus años de experiencia
            </Typography>
<Slider
        getAriaLabel={() => 'Años de experiencia'}
        value={anos}
        onChange={handleChangeExperiencia}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
        step={1}
        min={0}
        max={20}
        marks={anosExperiencia}
      />
      <Typography variant="body1" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "40px", textAlign: "center"}}
              >Indica tu despacho / empresa actual
            </Typography>
<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green", textAlign: "center"}}
                margin="normal"
          
                fullWidth
                id="empresa1"
                label="Despacho / Empresa Actual"
                name="empresa1"
                autoComplete="empresa1"
             
                onChange={handleChange}
              />

<Typography variant="body1" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "40px", textAlign: "center"}}
              >Indica tu ciudad
            </Typography>
<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green", textAlign: "center"}}
                margin="normal"
          
                fullWidth
                id="ciudad"
                label="Ciudad"
                name="ciudad"
                autoComplete="ciudad"
             
                onChange={handleChange}
              />

          
   
        

{(area !== null && anos !== "" && empresa1 !== "" && ciudad !== "") && <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, backgroundColor: "rgba(43, 117, 177, 0.9)", color: "white", marginTop: "50px"}}

              >
                Siguiente
              </Button>}

              {(area === null || anos === "" || empresa1 === "" || ciudad === "") && <Button
                type="submit"
                fullWidth
                variant="contained"
                disabled
                sx={{ mt: 3, mb: 2, backgroundColor: "grey", color: "white", marginTop: "50px"}}

              >
                Completa los campos
              </Button>}

            </Box>
          </Box>
                    
          
          
        
      </Grid>

     
       
 
     </Grid>



</Box>











                
                 </div>
                 </DialogContent>
</Dialog>



        
      </Grid>
   
 
     </Grid>

     

     

         
         






    
     </Box>



    

   

    </CssBaseline>
    </AuthProvider>
  )
}

export default Welcome2