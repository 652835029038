import React, { useEffect } from 'react';
import TrabajoDataService from '../services/trabajo.services';
import { useState } from 'react';
import Header from "../components/Header";
import { Outlet, Link } from "react-router-dom";
import {createTheme, ThemeProvider} from "@mui/material";
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import JobCard from "../components/JobCard";
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Slider from '@mui/material/Slider';
import JobList from "./JobList";
import { useUserAuth } from "../context/UserAuthContext";
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import FavoriteIcon from '@mui/icons-material/Favorite';
import listareas from './listareas';
import { CardActionArea } from '@mui/material';
import { useParams } from "react-router-dom";
import { getFirestore, collection, onSnapshot, addDoc, deleteDoc, doc, query, where, orderBy, getDoc, getDocs  } from 'firebase/firestore';
import AuthProvider from '../components/authProvider';
import { useNavigate } from 'react-router';
import { db, getPerfil, insertNewPerfil, updatePerfil } from '../firebase-config';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import getPaymentsByUID from '../components/getPaymentsByUID';
import Pagination from '@mui/material/Pagination';
import { Link as RouterLink } from 'react-router-dom';
import ListItemButton from '@mui/material/ListItemButton';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { LoadingButton } from "@mui/lab";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import Image from 'mui-image';
import Fab from '@mui/material/Fab';
import {v4 as uuidv4} from "uuid";
import Paper from '@mui/material/Paper';
import SigninAndMore from '../components/SigninAndMore';


const anosExperiencia = [
    {
      value: 0,
      label: '0',
    },
    {
      value: 1,
      label: '1',
    },
    {
      value: 2,
      label: '2',
    },
    {
      value: 3,
      label: '3',
    },
    {
      value: 4,
      label: '4',
    },
    {
      value: 5,
      label: '5',
    },
    {
      value: 6,
      label: '6',
    },
    {
      value: 7,
      label: '7',
    },
    {
      value: 8,
      label: '8',
    },
    {
      value: 9,
      label: '9',
    },
    {
      value: 10,
      label: '10',
    },
    {
      value: 11,
      label: '11',
    },
    {
      value: 12,
      label: '12',
    },
    {
      value: 13,
      label: '13',
    },
    {
      value: 14,
      label: '14',
    },
    {
      value: 15,
      label: '15',
    },
    {
      value: 16,
      label: '16',
    },
    {
      value: 17,
      label: '17',
    },
    {
      value: 18,
      label: '18',
    },
    {
      value: 19,
      label: '19',
    },
    {
      value: 20,
      label: '+20',
    },
  ];

  const anosExperienciaMovil = [
    {
      value: 0,
      label: '0',
    },
    {
      value: 5,
      label: '5',
    },
    {
      value: 10,
      label: '10',
    },
    {
      value: 15,
      label: '15',
    },
    {
      value: 20,
      label: '+20',
    },
  ];
  
  function valuetext(value: number) {
    return `${value}k`;
  }


const LimitedJobs = ( {getTrabajoId} ) => {
    const [trabajos, setTrabajos] = useState([]);
    useEffect(() => {
    getTrabajos();
  }, [])
  
  const getTrabajos = async () => {
    const data = await TrabajoDataService.getAllTrabajos();
    setTrabajos(data.docs.map((doc) => ({...doc.data(), id: doc.id})))
  }

  const [currentUser, setCurrentUser] = useState({});
  const [perfiles, setPerfiles] = useState([ ]);
  const [payments, setPayments] = useState([]);
  const [payment1, setPayment1] = useState([]);
  const [isPremium, setIsPremium] = useState(false);
  const [isOldPremium, setIsOldPremium] = useState(false);
  const navigate = useNavigate();



  const options = ['Perfil abierto (datos visibles para despachos, empresas y headhunters)', 'Perfil anónimo (datos identificativos ocultos)', "Perfil oculto"];

 
  const [nombre, setNombre] = useState("");
  const [email, setEmail] = useState("");
  const [telefono, setTelefono] = useState("");
  const [ciudad, setCiudad] = useState("");
  const [visibilidad, setVisibilidad] = useState("Perfil abierto (datos visibles para despachos, empresas y headhunters)");
  const [carrera1, setCarrera1] = useState("");
  const [uni1, setUni1] = useState("");
  const [fechaInicio1, setFechaInicio1] = useState("");
  const [fechaFin1, setFechaFin1] = useState("");
  const [notaMedia1, setNotaMedia1] = useState("");
  const [carrera2, setCarrera2] = useState("");
  const [uni2, setUni2] = useState("");
  const [fechaInicio2, setFechaInicio2] = useState("");
  const [fechaFin2, setFechaFin2] = useState("");
  const [notaMedia2, setNotaMedia2] = useState("");
  const [carrera3, setCarrera3] = useState("");
  const [uni3, setUni3] = useState("");
  const [fechaInicio3, setFechaInicio3] = useState("");
  const [fechaFin3, setFechaFin3] = useState("");
  const [notaMedia3, setNotaMedia3] = useState("");
  const [carrera4, setCarrera4] = useState("");
  const [uni4, setUni4] = useState("");
  const [fechaInicio4, setFechaInicio4] = useState("");
  const [fechaFin4, setFechaFin4] = useState("");
  const [notaMedia4, setNotaMedia4] = useState("");
  const [notaMedia5, setNotaMedia5] = useState("");
  const [notaMedia6, setNotaMedia6] = useState("");
  const [notaMedia7, setNotaMedia7] = useState("");
  const [notaMedia8, setNotaMedia8] = useState("");
  const [carrera5, setCarrera5] = useState("");
  const [uni5, setUni5] = useState("");
  const [fechaInicio5, setFechaInicio5] = useState("");
  const [fechaFin5, setFechaFin5] = useState("");
  const [carrera6, setCarrera6] = useState("");
  const [uni6, setUni6] = useState("");
  const [fechaInicio6, setFechaInicio6] = useState("");
  const [fechaFin6, setFechaFin6] = useState("");
  const [carrera7, setCarrera7] = useState("");
  const [uni7, setUni7] = useState("");
  const [fechaInicio7, setFechaInicio7] = useState("");
  const [fechaFin7, setFechaFin7] = useState("");
  const [carrera8, setCarrera8] = useState("");
  const [uni8, setUni8] = useState("");
  const [fechaInicio8, setFechaInicio8] = useState("");
  const [fechaFin8, setFechaFin8] = useState("");
  const [carrera9, setCarrera9] = useState("");
  const [uni9, setUni9] = useState("");
  const [fechaInicio9, setFechaInicio9] = useState("");
  const [fechaFin9, setFechaFin9] = useState("");
  const [numGrados, setnumGrados] = useState(1);
  const [numPosGrados, setnumPosGrados] = useState(1);
  const [trabajo1, setTrabajo1] = useState("");
  const [empresa1, setEmpresa1] = useState("");
  const [fechaInicioT1, setFechaInicioT1] = useState("");
  const [fechaFinT1, setFechaFinT1] = useState("");
  const [trabajo2, setTrabajo2] = useState("");
  const [empresa2, setEmpresa2] = useState("");
  const [fechaInicioT2, setFechaInicioT2] = useState("");
  const [fechaFinT2, setFechaFinT2] = useState("");
  const [trabajo3, setTrabajo3] = useState("");
  const [empresa3, setEmpresa3] = useState("");
  const [fechaInicioT3, setFechaInicioT3] = useState("");
  const [fechaFinT3, setFechaFinT3] = useState("");
  const [trabajo4, setTrabajo4] = useState("");
  const [empresa4, setEmpresa4] = useState("");
  const [fechaInicioT4, setFechaInicioT4] = useState("");
  const [fechaFinT4, setFechaFinT4] = useState("");
  const [trabajo5, setTrabajo5] = useState("");
  const [empresa5, setEmpresa5] = useState("");
  const [fechaInicioT5, setFechaInicioT5] = useState("");
  const [fechaFinT5, setFechaFinT5] = useState("");
  const [trabajo6, setTrabajo6] = useState("");
  const [empresa6, setEmpresa6] = useState("");
  const [fechaInicioT6, setFechaInicioT6] = useState("");
  const [fechaFinT6, setFechaFinT6] = useState("");
  const [trabajo7, setTrabajo7] = useState("");
  const [empresa7, setEmpresa7] = useState("");
  const [fechaInicioT7, setFechaInicioT7] = useState("");
  const [fechaFinT7, setFechaFinT7] = useState("");
  const [trabajo8, setTrabajo8] = useState("");
  const [empresa8, setEmpresa8] = useState("");
  const [fechaInicioT8, setFechaInicioT8] = useState("");
  const [fechaFinT8, setFechaFinT8] = useState("");
  const [descripcion1, setDescripcion1] = useState("");
  const [descripcion2, setDescripcion2] = useState("");
  const [descripcion3, setDescripcion3] = useState("");
  const [descripcion4, setDescripcion4] = useState("");
  const [descripcion5, setDescripcion5] = useState("");
  const [descripcion6, setDescripcion6] = useState("");
  const [descripcion7, setDescripcion7] = useState("");
  const [descripcion8, setDescripcion8] = useState("");
  const [trabajo9, setTrabajo9] = useState("");
  const [empresa9, setEmpresa9] = useState("");
  const [fechaInicioT9, setFechaInicioT9] = useState("");
  const [fechaFinT9, setFechaFinT9] = useState("");
  const [numTrabajos, setnumTrabajos] = useState(1);
 
  const [castellano, setCastellano] = useState("");
  const [ingles, setIngles] = useState("");
  const [presentacion, setPresentacion] = useState("");
  const [anos, setAnos] = useState("");
  const [anos2, setAnos2] = useState("");
  const [idioma1, setIdioma1] = useState("");
  const [nivelIdioma1, setNivelIdioma1] = useState("");
  const [idioma2, setIdioma2] = useState("");
  const [nivelIdioma2, setNivelIdioma2] = useState("");

  const initialText = 'Guardar cambios';
  const [buttonText, setButtonText] = useState(initialText);
 
  const [payment2, setPayment2] = useState([]);


  const anosExperiencia = [
    {
      value: 0,
      label: '0',
    },
    {
      value: 2,
      label: '2',
    },
    {
      value: 4,
      label: '4',
    },
    {
      value: 6,
      label: '6',
    },
    {
      value: 8,
      label: '8',
    },
    {
      value: 10,
      label: '10',
    },
    {
      value: 12,
      label: '12',
    },
    {
      value: 14,
      label: '14',
    },
    {
      value: 16,
      label: '16',
    },
    {
      value: 18,
      label: '18',
    },
    {
      value: 20,
      label: '+20',
    },
  ];

  const anosExperienciaMovil = [
    {
      value: 0,
      label: '0',
    },
    {
      value: 5,
      label: '5',
    },
    {
      value: 10,
      label: '10',
    },
    {
      value: 15,
      label: '15',
    },
    {
      value: 20,
      label: '+20',
    },
  ];


  const anosExperiencia2 = [
    {
      value: 0,
      label: '0',
    },
    {
      value: 2,
      label: '2',
    },
    {
      value: 4,
      label: '4',
    },
    {
      value: 6,
      label: '6',
    },
    {
      value: 8,
      label: '8',
    },
    {
      value: 10,
      label: '10',
    },
    {
      value: 12,
      label: '12',
    },
    {
      value: 14,
      label: '14',
    },
    {
      value: 16,
      label: '16',
    },
    {
      value: 18,
      label: '18',
    },
    {
      value: 20,
      label: '+20',
    },
  ];




  async function handleUserLoggedIn(user) {
    setCurrentUser(user);
    console.log("Estás registrado");
    console.log(user.displayName);
    console.log(perfiles.length);
    const resPerfil = await getPerfil(user.uid);
    setPerfiles([...resPerfil]);
    const payments = await getPaymentsByUID(user.uid);
    setPayments([...payments])
    console.log(perfiles);
    console.log()
    setIsOldPremium(user.premiumUser)
    if (!isOldPremium && currentUser.premiumUser !== true && perfiles.length === 0) {
      setOpen(true)
    }

       
   payments.map((payment) => {
    payment1.push(payment.status)

  })

console.log(payment1)

if (currentUser.premiumUser !== true) { 
const premium = (element) => (element === "active" || element === "trialing")
setIsPremium(payment1.some(premium))
console.log(isPremium)
   }
  
  
  
  }
console.log(currentUser.premiumUser)
   console.log(isOldPremium)

   useEffect(() => {
    async function getPayments() {
      if (!currentUser) return;
      console.log("user por usar", currentUser);
      const payments = await getPaymentsByUID(currentUser);
      setPayments(payments);
    }
    getPayments();
    console.log(payments)
    
  }, []);



    console.log(payments.length)
  

   console.log(perfiles.length)
console.log(currentUser)



   function handleUserNotRegistered(user) {
    

   }
   
   function handleUserNotLoggedIn() {
    setOpen(true)
   }
    
    const areas = ["Administrativo", "Banking&Finance", "Competencia", "Compliance","Concursal", "Consumo","Contract Management","Energía", "Farma","Fiscal","Generalista", "Inmobiliario", "Laboral" , "Medio ambiente" , "Mercantil", "Nuevas tecnologías","Procesal civil", "Propiedad industrial", "Propiedad intelectual", "Protección de datos", "Seguros", "Urbanismo",         ];
  const experiencias = ["1 a 4 años", "5 a 8 años"];
  const ciudades = ["Madrid", 
  "Barcelona",
  "Valencia", 
  "Sevilla",
    "Zaragoza",
    "Málaga",
  "Murcia",
"Palma",
"Las Palmas",
"Bilbao",
"Alicante",
"Córdoba",
"Valladolid",
"Vigo",
"Gijón"];
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [experiencia, setExperiencia] = React.useState(null);
  const [inputExperiencia, setInputExperiencia] = React.useState('');
  const [ciudad2, setCiudad2] = React.useState(null);
  const [inputCiudad, setInputCiudad] = React.useState();
  const [area, setArea] = React.useState(null);
  const [area2, setArea2] = React.useState(null);
  const [inputArea, setInputArea] = React.useState();
  let {user} = useUserAuth();
  const [experiencia1, setExperiencia1] = useState([0, 20]);

  const handleChangeExperiencia = (event, newExperiencia) => {
    setExperiencia1(newExperiencia);
  };

  const [open, setOpen] = React.useState(false);

 
  
  

  const handleClose = () => {
    setOpen(false);
  };



  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [page]);

  const ordered = [...trabajos].sort((a,b) => b.timestamp - a.timestamp);
  const matches = []
  const matchesOpen = []
  const matchesPremium = []
  const totalJobs = ordered.length



  const eachjob = ordered.map((doc, index) => {
 
    const numeroAreasPractica = Object.keys(doc.area);

    
    if (doc.visibilidad === false) {
      matchesOpen.push(doc)
    }

    
    
    
    
    if ((ciudad2 === doc.ciudad || ciudad2 === null) && 
    ((area2 === doc.area[0].title || numeroAreasPractica.length > 1 && area2 === doc.area[1].title || numeroAreasPractica.length > 2 && area2 === doc.area[2].title || area2 === null)) &&
    (doc.experiencia[0] >= experiencia1[0] && doc.experiencia[1] <= experiencia1[1]) && doc.visibilidad === false
    
    ) {
      matches.push(doc)
    }


    if ((ciudad2 === doc.ciudad || ciudad2 === null) && 
    ((area2 === doc.area[0].title || numeroAreasPractica.length > 1 && area2 === doc.area[1].title || numeroAreasPractica.length > 2 && area2 === doc.area[2].title || area2 === null)) &&
    (doc.experiencia[0] >= experiencia1[0] && doc.experiencia[1] <= experiencia1[1]) && doc.visibilidad === true
    
    ) {
      matchesPremium.push(doc)
    }
    
  })
  const [page, setPage] = useState(1);
  const itemsPerPage = 15;
  const noOfPages = Math.ceil(matches.length / itemsPerPage);
  const handleChangePagination = (event, value) => {
    setPage(value);
  };
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [page]);
  const numberLimitedJobs = matches.length
  const numberMatchesOpen = matchesOpen.length
  const numberMatchesPremium = matchesPremium.length
  const eachjob2 = matches.slice((page - 1) * itemsPerPage, page * itemsPerPage).map((doc, index) => {
 
    const numeroAreasPractica = Object.keys(doc.area);
    const date = new Date();

    return (
        <div>
        
        

        {(doc.visibilidad === false && doc.requiereperfil !== true && doc.requiereregistro !== true) && <Box sx={{display: { xl: 'block', l: "block", m: "none", s:"none", xs: 'none' }}} >
        <Card variant="outlined" sx={{display: "flex", width: "800px"}}>
       
        <CardMedia
            component="img"
            sx={{maxWidth: "20%", padding:"20px", objectFit: "contain"}}
            image={doc.logoOferta}
            alt="Logo"
          />
        <Box sx={{ display: 'flex', flexDirection: 'row', width: "600px"}}>
        <CardContent sx={{paddingTop:"20px"}}>
        <Typography variant="subtitle1"  color="text.secondary">{doc.despacho}</Typography>
                <Typography variant="h6" color="text.secondary">
                <Link
            style={{ display: "block", textDecoration: "none", color: "#474643"}}
            to={`/Jobs/${doc.id}`}
            target="_blank"
            key={doc.id} onClick ={(e) => getTrabajoId(doc.id) }
          >
            {doc.titulo}


          </Link>
                     
                </Typography>
                <Typography variant="subtitle2" color="text.secondary">
                     {doc.ciudad}
                </Typography>
        <Box sx={{paddingTop: "15px"}}>
        
        <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px"}}>
            {doc.area[0].title}
        </Typography>
        {numeroAreasPractica.length > 1 && <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px", marginLeft: "5px"}}>
            {doc.area[1].title}
        </Typography>}
        {numeroAreasPractica.length > 2 && <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px", marginLeft: "5px"}}>
            {doc.area[2].title}
        </Typography>}
        
        </Box>
        <Box sx={{paddingTop: "8px"}}>
        
        <Typography variant="caption" sx={{backgroundColor: "#03b1fc", borderRadius: "10px", color:"white", padding: "6px"}}>
            {`${doc.experiencia[0]} a ${doc.experiencia[1]} años`}
        </Typography>
       
        </Box>
        </CardContent>
        </Box>
        
        <Box sx={{ display: 'flex', flexDirection: 'column', width: "400px", alignItems: 'flex-end'}}>
            <Box sx={{display: "flex", alignItems: "flex-end"}}>
            </Box>
            <Box sx={{paddingTop: "62px", paddingRight: "15px"}}>
       
            {(((doc.timestamp.toDate().getMonth() < date.getMonth()) && (doc.timestamp.toDate().getDate() < date.getDate())) || (doc.timestamp.toDate().getMonth() + 1 < date.getMonth())) &&  
            
            <Typography variant="caption" 
            sx={{border: "1px solid #b32217", 
            borderRadius: "10px", 
            color:"white", 
            bgcolor: "#b32217",
            padding: "6px", 
            marginLeft: "5px"}}>
              Podría no estar ya disponible</Typography>}
       
        </Box>
        </Box> 
 
    </Card>
    </Box> }

    {(doc.visibilidad === false && doc.requiereregistro === true && doc.requiereperfil !== true && (user && perfiles.length === 0)) && <Box sx={{display: { xl: 'block', l: "block", m: "none", s:"none", xs: 'none' }}} >
        <Card variant="outlined" sx={{display: "flex", width: "800px"}}>
       
        <CardMedia
            component="img"
            sx={{maxWidth: "20%", padding:"20px", objectFit: "contain"}}
            image={doc.logoOferta}
            alt="Logo"
          />
        <Box sx={{ display: 'flex', flexDirection: 'row', width: "600px"}}>
        <CardContent sx={{paddingTop:"20px"}}>
        <Typography variant="subtitle1"  color="text.secondary">{doc.despacho}</Typography>
                <Typography variant="h6" color="text.secondary">
                <Link
            style={{ display: "block", textDecoration: "none", color: "#474643"}}
            to={`/Jobs/${doc.id}`}
            target="_blank"
            key={doc.id} onClick ={(e) => getTrabajoId(doc.id) }
          >
            {doc.titulo}
          </Link>
                     
                </Typography>
                <Typography variant="subtitle2" color="text.secondary">
                     {doc.ciudad}
                </Typography>
        <Box sx={{paddingTop: "15px"}}>
        
        <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px"}}>
            {doc.area[0].title}
        </Typography>
        {numeroAreasPractica.length > 1 && <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px", marginLeft: "5px"}}>
            {doc.area[1].title}
        </Typography>}
        {numeroAreasPractica.length > 2 && <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px", marginLeft: "5px"}}>
            {doc.area[2].title}
        </Typography>}
        
        </Box>
        <Box sx={{paddingTop: "8px"}}>
        
        <Typography variant="caption" sx={{backgroundColor: "#03b1fc", borderRadius: "10px", color:"white", padding: "6px"}}>
            {`${doc.experiencia[0]} a ${doc.experiencia[1]} años`}
        </Typography>
  
        </Box>
        </CardContent>
        </Box>
        
        <Box sx={{ display: 'flex', flexDirection: 'column', width: "400px", alignItems: 'flex-end'}}>
            <Box sx={{display: "flex", alignItems: "flex-end"}}>
            </Box>
            <Box sx={{paddingTop: "62px", paddingRight: "15px"}}>
       
            {(((doc.timestamp.toDate().getMonth() < date.getMonth()) && (doc.timestamp.toDate().getDate() < date.getDate())) || (doc.timestamp.toDate().getMonth() + 1 < date.getMonth())) &&  
            
            <Typography variant="caption" 
            sx={{border: "1px solid #b32217", 
            borderRadius: "10px", 
            color:"white", 
            bgcolor: "#b32217",
            padding: "6px", 
            marginLeft: "5px"}}>
              Podría no estar ya disponible</Typography>}
       
        </Box>
        </Box> 
 
    </Card>
    </Box> }
        
        
        {(doc.visibilidad === false && (doc.requiereperfil === true || doc.requiereregistro === true) && !user) && <Box sx={{display: { xl: 'block', l: "block", m: "none", s:"none", xs: 'none' }}} >
        <Card variant="outlined" sx={{display: "flex", width: "800px"}}>
       
      
           <CardContent sx={{maxWidth: "20%", padding:"20px", bgcolor: "#7b8cb0", opacity: "0.7", backgroundImage: `url(${"https://firebasestorage.googleapis.com/v0/b/jobsandlaw-5e0ba.appspot.com/o/images%2Ftorres-1670879.jpg?alt=media&token=c0654ab3-441b-4b37-8af3-d60d7101854a"})`  }}>
          <Typography component="div" color= "whitesmoke" sx={{alignContent: "center", fontSize: "16px"}}>
            Regístrate gratis para desbloquear esta vacante
          </Typography>
  
        </CardContent>
        <Box sx={{ display: 'flex', flexDirection: 'row', width: "600px"}}>
        <CardContent sx={{paddingTop:"20px"}}>
        <Typography variant="subtitle1"  color="text.secondary">{doc.tipoDespacho.title} </Typography>
                <Typography variant="h6" color="text.secondary">
                <Link
            style={{ display: "block", textDecoration: "none", color: "#474643"}}
            to={`../`}
            key={doc.id} onClick ={(e) => getTrabajoId(doc.id) }
          >
            {doc.titulo}
          </Link>
                     
                </Typography>
                <Typography variant="subtitle2" color="text.secondary">
                     {doc.ciudad}
                </Typography>
        <Box sx={{paddingTop: "15px"}}>
        
        <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px"}}>
            {doc.area[0].title}
        </Typography>
        {numeroAreasPractica.length > 1 && <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px", marginLeft: "5px"}}>
            {doc.area[1].title}
        </Typography>}
        {numeroAreasPractica.length > 2 && <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px", marginLeft: "5px"}}>
            {doc.area[2].title}
        </Typography>}
        </Box>
        <Box sx={{paddingTop: "8px"}}>
        
        <Typography variant="caption" sx={{backgroundColor: "#03b1fc", borderRadius: "10px", color:"white", padding: "6px"}}>
            {`${doc.experiencia[0]} a ${doc.experiencia[1]} años`}
        </Typography>

        </Box>
        </CardContent>
        </Box>
        
        <Box sx={{ display: 'flex', flexDirection: 'column', width: "400px", alignItems: 'flex-end'}}>
            <Box sx={{display: "flex", alignItems: "flex-end"}}>
            </Box>
            <Box sx={{paddingTop: "62px", paddingRight: "15px"}}>
       
            {doc.recomendado !== false &&  
            
            <Typography variant="caption" 
            sx={{border: "1px solid #037bfc", 
            borderRadius: "10px", 
            color:"white", 
            bgcolor: "#037bfc",
            padding: "6px", 
            marginLeft: "5px"}}>
              Mejores despachos para trabajar 2023</Typography>}
       
        </Box>
        </Box> 
 
    </Card>
    </Box> }
        
       

    {(doc.visibilidad === false && user && (doc.requiereperfil === true || doc.requiereregistro === true) && (perfiles.length !== 0)) && <Box sx={{display: { xl: 'block', l: "block", m: "none", s:"none", xs: 'none' }}} >
        <Card variant="outlined" sx={{display: "flex", width: "800px"}}>
       
        <CardMedia
            component="img"
            sx={{maxWidth: "20%", padding:"20px", objectFit: "contain"}}
            image={doc.logoOferta}
            alt="Logo"
          />
        <Box sx={{ display: 'flex', flexDirection: 'row', width: "600px"}}>
        <CardContent sx={{paddingTop:"20px"}}>
        <Typography variant="subtitle1"  color="text.secondary">{doc.despacho}</Typography>
                <Typography variant="h6" color="text.secondary">
                <Link
            style={{ display: "block", textDecoration: "none", color: "#474643"}}
            to={`/Jobs/${doc.id}`}
            target="_blank"
            key={doc.id} onClick ={(e) => getTrabajoId(doc.id) }
          >
            {doc.titulo}
          </Link>
                     
                </Typography>
                <Typography variant="subtitle2" color="text.secondary">
                     {doc.ciudad}
                </Typography>
        <Box sx={{paddingTop: "15px"}}>
        
        <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px"}}>
            {doc.area[0].title}
        </Typography>
        {numeroAreasPractica.length > 1 && <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px", marginLeft: "5px"}}>
            {doc.area[1].title}
        </Typography>}
        {numeroAreasPractica.length > 2 && <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px", marginLeft: "5px"}}>
            {doc.area[2].title}
        </Typography>}
        </Box>
        <Box sx={{paddingTop: "8px"}}>
        
        <Typography variant="caption" sx={{backgroundColor: "#03b1fc", borderRadius: "10px", color:"white", padding: "6px"}}>
            {`${doc.experiencia[0]} a ${doc.experiencia[1]} años`}
        </Typography>

        </Box>
        </CardContent>
        </Box>
        
        <Box sx={{ display: 'flex', flexDirection: 'column', width: "400px", alignItems: 'flex-end'}}>
            <Box sx={{display: "flex", alignItems: "flex-end"}}>
            </Box>
            <Box sx={{paddingTop: "62px", paddingRight: "15px"}}>
       
            {(((doc.timestamp.toDate().getMonth() < date.getMonth()) && (doc.timestamp.toDate().getDate() < date.getDate())) || (doc.timestamp.toDate().getMonth() + 1 < date.getMonth())) &&  
            
            <Typography variant="caption" 
            sx={{border: "1px solid #b32217", 
            borderRadius: "10px", 
            color:"white", 
            bgcolor: "#b32217",
            padding: "6px", 
            marginLeft: "5px"}}>
              Podría no estar ya disponible</Typography>}
       
        </Box>
        </Box> 
 
    </Card>
    </Box> }

    
    
    {(doc.visibilidad === false && doc.requiereperfil === true && (perfiles.length === 0) && user) && <Box sx={{display: { xl: 'block', l: "block", m: "none", s:"none", xs: 'none' }}} >
        <Card variant="outlined" sx={{display: "flex", width: "800px"}}>
       
      
           <CardContent sx={{maxWidth: "20%", padding:"20px", bgcolor: "#7b8cb0", opacity: "0.7", backgroundImage: `url(${"https://firebasestorage.googleapis.com/v0/b/jobsandlaw-5e0ba.appspot.com/o/images%2Ftorres-1670879.jpg?alt=media&token=c0654ab3-441b-4b37-8af3-d60d7101854a"})`  }}>
          <Typography component="div" color= "whitesmoke" sx={{alignContent: "center", fontSize: "16px"}}>
            Crea un perfil profesional para desbloquear esta vacante
          </Typography>
  
        </CardContent>
        <Box sx={{ display: 'flex', flexDirection: 'row', width: "600px"}}>
        <CardContent sx={{paddingTop:"20px"}}>
        <Typography variant="subtitle1"  color="text.secondary">{doc.tipoDespacho.title} </Typography>
                <Typography variant="h6" color="text.secondary">
                <Link
            style={{ display: "block", textDecoration: "none", color: "#474643"}}
            to={`../perfilprofesional`}
            key={doc.id} onClick ={(e) => getTrabajoId(doc.id) }
          >
            {doc.titulo}
          </Link>
                     
                </Typography>
                <Typography variant="subtitle2" color="text.secondary">
                     {doc.ciudad}
                </Typography>
        <Box sx={{paddingTop: "15px"}}>
        
        <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px"}}>
            {doc.area[0].title}
        </Typography>
        {numeroAreasPractica.length > 1 && <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px", marginLeft: "5px"}}>
            {doc.area[1].title}
        </Typography>}
        {numeroAreasPractica.length > 2 && <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px", marginLeft: "5px"}}>
            {doc.area[2].title}
        </Typography>}
        </Box>
        <Box sx={{paddingTop: "8px"}}>
        
        <Typography variant="caption" sx={{backgroundColor: "#03b1fc", borderRadius: "10px", color:"white", padding: "6px"}}>
            {`${doc.experiencia[0]} a ${doc.experiencia[1]} años`}
        </Typography>
   
        </Box>
        </CardContent>
        </Box>
        
        <Box sx={{ display: 'flex', flexDirection: 'column', width: "400px", alignItems: 'flex-end'}}>
            <Box sx={{display: "flex", alignItems: "flex-end"}}>
            </Box>
            <Box sx={{paddingTop: "62px", paddingRight: "15px"}}>
       
            {doc.recomendado !== false &&  
            
            <Typography variant="caption" 
            sx={{border: "1px solid #037bfc", 
            borderRadius: "10px", 
            color:"white", 
            bgcolor: "#037bfc",
            padding: "6px", 
            marginLeft: "5px"}}>
              Mejores despachos para trabajar 2023</Typography>}
       
        </Box>
        </Box> 
 
    </Card>
    </Box> }


  

    {(doc.visibilidad === false && doc.requiereperfil !== true && doc.requiereregistro !== true) &&  <Box sx={{display: { xl: 'none', l: "none", m: "block", s:"block", xs: 'block', justifyContent: "center", alignItems: "center", marginTop: "20px" }}} >
        <Card variant="outlined" sx={{alignContent: "center", alignItems: "center", width: "300px"}}>
        <CardActionArea component={Link} to={`/Jobs/${doc.id}`} target="_blank">
        <CardMedia
            component="img"
            sx={{paddingLeft: "15px", width: "80px", objectFit: "contain", justifyContent: "center", alignItems: "center"}}
            image={doc.logoOferta}
            alt="Logo"
          />
         <CardContent sx={{paddingTop:"20px", justifyContent: "center", alignItems: "center"}}>
        <Typography variant="subtitle1"  color="text.secondary">{doc.despacho}</Typography>
                <Typography variant="h6" color="text.secondary">
                <Link
            style={{ display: "block", textDecoration: "none", color: "#474643"}}
            to={`/Jobs/${doc.id}`}
            target="_blank"
            key={doc.id} onClick ={(e) => getTrabajoId(doc.id) }
          >
            {doc.titulo}
          </Link>
                     
                </Typography>
                <Typography variant="subtitle2" color="text.secondary">
                     {doc.ciudad}
                </Typography>
        <Box sx={{paddingTop: "15px", display: 'flex', flexDirection: 'row'}}>
        
        <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px"}}>
            {doc.area[0].title}
        </Typography>
        {numeroAreasPractica.length > 1 && <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px", marginLeft: "5px"}}>
            {doc.area[1].title}
        </Typography>}
        </Box>
        {numeroAreasPractica.length > 2 && <Box sx={{paddingTop: "15px", display: 'flex', flexDirection: 'row'}}>
         <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px", marginLeft: "5px"}}>
            {doc.area[2].title}
        </Typography>
        </Box>}
        <Box sx={{paddingTop: "8px"}}>
        
        <Typography variant="caption" sx={{backgroundColor: "#03b1fc", borderRadius: "10px", color:"white", padding: "6px"}}>
            {`${doc.experiencia[0]} a ${doc.experiencia[1]} años`}
        </Typography>

        </Box>
        <Box sx={{paddingTop: "10px"}}>
        {(((doc.timestamp.toDate().getMonth() < date.getMonth()) && (doc.timestamp.toDate().getDate() < date.getDate())) || (doc.timestamp.toDate().getMonth() + 1 < date.getMonth())) &&  
            
            <Typography variant="caption" 
            sx={{border: "1px solid #b32217", 
            borderRadius: "10px", 
            color:"white", 
            bgcolor: "#b32217",
            padding: "6px"}}>
              Podría no estar ya disponible</Typography>}
        </Box>
        </CardContent>
        </CardActionArea>
    </Card>
    </Box> }

    {(doc.visibilidad === false && doc.requiereregistro === true && doc.requiereperfil !== true && (user && perfiles.length === 0)) &&  <Box sx={{display: { xl: 'none', l: "none", m: "block", s:"block", xs: 'block', justifyContent: "center", alignItems: "center", marginTop: "20px" }}} >
        <Card variant="outlined" sx={{alignContent: "center", alignItems: "center", width: "300px"}}>
        <CardActionArea component={Link} to={`/Jobs/${doc.id}`} target="_blank">
        <CardMedia
            component="img"
            sx={{paddingLeft: "15px", width: "80px", objectFit: "contain", justifyContent: "center", alignItems: "center"}}
            image={doc.logoOferta}
            alt="Logo"
          />
         <CardContent sx={{paddingTop:"20px", justifyContent: "center", alignItems: "center"}}>
        <Typography variant="subtitle1"  color="text.secondary">{doc.despacho}</Typography>
                <Typography variant="h6" color="text.secondary">
                <Link
            style={{ display: "block", textDecoration: "none", color: "#474643"}}
            to={`/Jobs/${doc.id}`}
            target="_blank"
            key={doc.id} onClick ={(e) => getTrabajoId(doc.id) }
          >
            {doc.titulo}
          </Link>
                     
                </Typography>
                <Typography variant="subtitle2" color="text.secondary">
                     {doc.ciudad}
                </Typography>
        <Box sx={{paddingTop: "15px", display: 'flex', flexDirection: 'row'}}>
        
        <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px"}}>
            {doc.area[0].title}
        </Typography>
        {numeroAreasPractica.length > 1 && <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px", marginLeft: "5px"}}>
            {doc.area[1].title}
        </Typography>}
        </Box>
        {numeroAreasPractica.length > 2 && <Box sx={{paddingTop: "15px", display: 'flex', flexDirection: 'row'}}>
         <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px", marginLeft: "5px"}}>
            {doc.area[2].title}
        </Typography>
        </Box>}
        <Box sx={{paddingTop: "8px"}}>
        
        <Typography variant="caption" sx={{backgroundColor: "#03b1fc", borderRadius: "10px", color:"white", padding: "6px"}}>
            {`${doc.experiencia[0]} a ${doc.experiencia[1]} años`}
        </Typography>

        </Box>
        <Box sx={{paddingTop: "10px"}}>
        {(((doc.timestamp.toDate().getMonth() < date.getMonth()) && (doc.timestamp.toDate().getDate() < date.getDate())) || (doc.timestamp.toDate().getMonth() + 1 < date.getMonth())) &&  
            
            <Typography variant="caption" 
            sx={{border: "1px solid #b32217", 
            borderRadius: "10px", 
            color:"white", 
            bgcolor: "#b32217",
            padding: "6px"}}>
              Podría no estar ya disponible</Typography>}
        </Box>
        </CardContent>
        </CardActionArea>
    </Card>
    </Box> }

    {(doc.visibilidad === false && (doc.requiereperfil === true || doc.requiereregistro === true) && !user) &&  <Box sx={{display: { xl: 'none', l: "none", m: "block", s:"block", xs: 'block', justifyContent: "center", alignItems: "center", marginTop: "20px" }}} >
        <Card variant="outlined" sx={{alignContent: "center", alignItems: "center", width: "300px"}}>
        <CardActionArea component={Link} to={`../`} target="_blank">
        <CardContent sx={{maxWidth: "100%", textAlign: "center", padding:"20px", bgcolor: "#7b8cb0", opacity: "0.7", backgroundImage: `url(${"https://firebasestorage.googleapis.com/v0/b/jobsandlaw-5e0ba.appspot.com/o/images%2Ftorres-1670879.jpg?alt=media&token=c0654ab3-441b-4b37-8af3-d60d7101854a"})`  }}>
          <Typography component="div" color= "whitesmoke" sx={{alignContent: "center", fontSize: "16px"}}>
            Regístrate gratis para desbloquear esta vacante
          </Typography>
  
        </CardContent>
         <CardContent sx={{paddingTop:"20px", justifyContent: "center", alignItems: "center"}}>
        <Typography variant="subtitle1"  color="text.secondary">{doc.tipoDespacho.title}</Typography>
                <Typography variant="h6" color="text.secondary">
                <Link
            style={{ display: "block", textDecoration: "none", color: "#474643"}}
            to={`/`}
            target="_blank"
            key={doc.id} onClick ={(e) => getTrabajoId(doc.id) }
          >
            {doc.titulo}
          </Link>
                     
                </Typography>
                <Typography variant="subtitle2" color="text.secondary">
                     {doc.ciudad}
                </Typography>
        <Box sx={{paddingTop: "15px", display: 'flex', flexDirection: 'row'}}>
        
        <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px"}}>
            {doc.area[0].title}
        </Typography>
        {numeroAreasPractica.length > 1 && <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px", marginLeft: "5px"}}>
            {doc.area[1].title}
        </Typography>}
        </Box>
        {numeroAreasPractica.length > 2 && <Box sx={{paddingTop: "15px", display: 'flex', flexDirection: 'row'}}>
         <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px", marginLeft: "5px"}}>
            {doc.area[2].title}
        </Typography>
        </Box>}
        <Box sx={{paddingTop: "8px"}}>
        
        <Typography variant="caption" sx={{backgroundColor: "#03b1fc", borderRadius: "10px", color:"white", padding: "6px"}}>
            {`${doc.experiencia[0]} a ${doc.experiencia[1]} años`}
        </Typography>

        </Box>
        </CardContent>
        </CardActionArea>
    </Card>
  
    </Box> }

    {(doc.visibilidad === false && user && (doc.requiereperfil === true || doc.requiereregistro === true) && (perfiles.length !== 0)) &&  <Box sx={{display: { xl: 'none', l: "none", m: "block", s:"block", xs: 'block', justifyContent: "center", alignItems: "center", marginTop: "20px" }}} >
        <Card variant="outlined" sx={{alignContent: "center", alignItems: "center", width: "300px"}}>
        <CardActionArea component={Link} to={`/Jobs/${doc.id}`} target="_blank">
        <CardMedia
            component="img"
            sx={{paddingLeft: "15px", width: "80px", objectFit: "contain", justifyContent: "center", alignItems: "center"}}
            image={doc.logoOferta}
            alt="Logo"
          />
         <CardContent sx={{paddingTop:"20px", justifyContent: "center", alignItems: "center"}}>
        <Typography variant="subtitle1"  color="text.secondary">{doc.despacho}</Typography>
                <Typography variant="h6" color="text.secondary">
                <Link
            style={{ display: "block", textDecoration: "none", color: "#474643"}}
            to={`/Jobs/${doc.id}`}
            target="_blank"
            key={doc.id} onClick ={(e) => getTrabajoId(doc.id) }
          >
            {doc.titulo}
          </Link>
                     
                </Typography>
                <Typography variant="subtitle2" color="text.secondary">
                     {doc.ciudad}
                </Typography>
        <Box sx={{paddingTop: "15px", display: 'flex', flexDirection: 'row'}}>
        
        <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px"}}>
            {doc.area[0].title}
        </Typography>
        {numeroAreasPractica.length > 1 && <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px", marginLeft: "5px"}}>
            {doc.area[1].title}
        </Typography>}
        </Box>
        {numeroAreasPractica.length > 2 && <Box sx={{paddingTop: "15px", display: 'flex', flexDirection: 'row'}}>
         <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px", marginLeft: "5px"}}>
            {doc.area[2].title}
        </Typography>
        </Box>}
        <Box sx={{paddingTop: "8px"}}>
        
        <Typography variant="caption" sx={{backgroundColor: "#03b1fc", borderRadius: "10px", color:"white", padding: "6px"}}>
            {`${doc.experiencia[0]} a ${doc.experiencia[1]} años`}
        </Typography>

        </Box>
        <Box sx={{paddingTop: "10px"}}>
        {(((doc.timestamp.toDate().getMonth() < date.getMonth()) && (doc.timestamp.toDate().getDate() < date.getDate())) || (doc.timestamp.toDate().getMonth() + 1 < date.getMonth())) &&  
            
            <Typography variant="caption" 
            sx={{border: "1px solid #b32217", 
            borderRadius: "10px", 
            color:"white", 
            bgcolor: "#b32217",
            padding: "6px"}}>
              Podría no estar ya disponible</Typography>}
        </Box>
        </CardContent>
        </CardActionArea>
    </Card>
    </Box> }

    {(doc.visibilidad === false && doc.requiereperfil === true && (perfiles.length === 0) && user) &&  <Box sx={{display: { xl: 'none', l: "none", m: "block", s:"block", xs: 'block', justifyContent: "center", alignItems: "center", marginTop: "20px" }}} >
        <Card variant="outlined" sx={{alignContent: "center", alignItems: "center", width: "300px"}}>
        <CardActionArea component={Link} to={`../perfilprofesional`} target="_blank">
        <CardContent sx={{maxWidth: "100%", textAlign: "center", padding:"20px", bgcolor: "#7b8cb0", opacity: "0.7", backgroundImage: `url(${"https://firebasestorage.googleapis.com/v0/b/jobsandlaw-5e0ba.appspot.com/o/images%2Ftorres-1670879.jpg?alt=media&token=c0654ab3-441b-4b37-8af3-d60d7101854a"})`  }}>
          <Typography component="div" color= "whitesmoke" sx={{alignContent: "center", fontSize: "16px"}}>
          Crea un perfil profesional para desbloquear esta vacante 
          </Typography>
  
        </CardContent>
         <CardContent sx={{paddingTop:"20px", justifyContent: "center", alignItems: "center"}}>
        <Typography variant="subtitle1"  color="text.secondary">{doc.tipoDespacho.title}</Typography>
                <Typography variant="h6" color="text.secondary">
                <Link
            style={{ display: "block", textDecoration: "none", color: "#474643"}}
            to={`/perfilprofesional`}
            target="_blank"
            key={doc.id} onClick ={(e) => getTrabajoId(doc.id) }
          >
            {doc.titulo}
         
          </Link>
                     
                </Typography>
                <Typography variant="subtitle2" color="text.secondary">
                     {doc.ciudad}
                </Typography>
        <Box sx={{paddingTop: "15px", display: 'flex', flexDirection: 'row'}}>
        
        <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px"}}>
            {doc.area[0].title}
        </Typography>
        {numeroAreasPractica.length > 1 && <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px", marginLeft: "5px"}}>
            {doc.area[1].title}
        </Typography>}
        </Box>
        {numeroAreasPractica.length > 2 && <Box sx={{paddingTop: "15px", display: 'flex', flexDirection: 'row'}}>
         <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px", marginLeft: "5px"}}>
            {doc.area[2].title}
        </Typography>
        </Box>}
        <Box sx={{paddingTop: "8px"}}>
        
        <Typography variant="caption" sx={{backgroundColor: "#03b1fc", borderRadius: "10px", color:"white", padding: "6px"}}>
            {`${doc.experiencia[0]} a ${doc.experiencia[1]} años`}
        </Typography>
  
        </Box>
        </CardContent>
        </CardActionArea>
    </Card>
  
    </Box> }

    
    </div>
    ) }
    
  )




  console.log(payments.length)
  console.log(isPremium)




  const [open1, setOpen1] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose1 = () => {
    setOpen(false);
  };


  function handleChange(e) {
    const value = e.target.value;
    if(e.target.name === "nombre"){
      setNombre(value)
      console.log(nombre);
    }
    if(e.target.name === "telefono"){
      setTelefono(value)
      console.log(telefono)
    }

    if(e.target.name === "ciudad"){
      setCiudad(value)
      console.log(ciudad)
    }

    if(e.target.name === "email"){
      setEmail(value)
    }
    if(e.target.name === "carrera1"){
      setCarrera1(value)
    }
    if(e.target.name === "uni1"){
      setUni1(value)
    }
    if(e.target.name === "fechaInicio1"){
      setFechaInicio1(value)
    }
    if(e.target.name === "fechaFin1"){
      setFechaFin1(value)
    }
    if(e.target.name === "notaMedia1"){
      setNotaMedia1(value)
    }
    if(e.target.name === "carrera2"){
      setCarrera2(value)
    }
    if(e.target.name === "uni2"){
      setUni2(value)
    }
    if(e.target.name === "fechaInicio2"){
      setFechaInicio2(value)
    }
    if(e.target.name === "fechaFin2"){
      setFechaFin2(value)
    }
    if(e.target.name === "notaMedia2"){
      setNotaMedia2(value)
    }
    if(e.target.name === "notaMedia3"){
      setNotaMedia3(value)
    }
    if(e.target.name === "notaMedia4"){
      setNotaMedia4(value)
    }
    if(e.target.name === "notaMedia5"){
      setNotaMedia5(value)
    }
    if(e.target.name === "notaMedia6"){
      setNotaMedia6(value)
    }
    if(e.target.name === "notaMedia7"){
      setNotaMedia7(value)
    }
    if(e.target.name === "notaMedia8"){
      setNotaMedia8(value)
    }
    if(e.target.name === "carrera3"){
      setCarrera3(value)
    }
    if(e.target.name === "uni3"){
      setUni3(value)
    }
    if(e.target.name === "fechaInicio3"){
      setFechaInicio3(value)
    }
    if(e.target.name === "fechaFin3"){
      setFechaFin3(value)
    }
    if(e.target.name === "carrera4"){
      setCarrera4(value)
    }
    if(e.target.name === "uni4"){
      setUni4(value)
    }
    if(e.target.name === "fechaInicio4"){
      setFechaInicio4(value)
    }
    if(e.target.name === "fechaFin4"){
      setFechaFin4(value)
    }
    if(e.target.name === "carrera5"){
      setCarrera5(value)
    }
    if(e.target.name === "uni5"){
      setUni5(value)
    }
    if(e.target.name === "fechaInicio5"){
      setFechaInicio5(value)
    }
    if(e.target.name === "fechaFin5"){
      setFechaFin5(value)
    }
    if(e.target.name === "carrera6"){
      setCarrera6(value)
    }
    if(e.target.name === "uni6"){
      setUni6(value)
    }
    if(e.target.name === "fechaInicio6"){
      setFechaInicio6(value)
    }
    if(e.target.name === "fechaFin6"){
      setFechaFin6(value)
    }
    if(e.target.name === "carrera7"){
      setCarrera7(value)
    }
    if(e.target.name === "uni7"){
      setUni7(value)
    }
    if(e.target.name === "fechaInicio7"){
      setFechaInicio7(value)
    }
    if(e.target.name === "fechaFin7"){
      setFechaFin7(value)
    }
    if(e.target.name === "carrera8"){
      setCarrera8(value)
    }
    if(e.target.name === "uni8"){
      setUni8(value)
    }
    if(e.target.name === "fechaInicio8"){
      setFechaInicio8(value)
    }
    if(e.target.name === "fechaFin8"){
      setFechaFin8(value)
    }
    if(e.target.name === "carrera9"){
      setCarrera9(value)
    }
    if(e.target.name === "uni9"){
      setUni9(value)
    }
    if(e.target.name === "fechaInicio9"){
      setFechaInicio9(value)
    }
    if(e.target.name === "fechaFin9"){
      setFechaFin9(value)
    }
    if(e.target.name === "trabajo1"){
      setTrabajo1(value)
    }
    if(e.target.name === "empresa1"){
      setEmpresa1(value)
    }
    if(e.target.name === "fechaInicioT1"){
      setFechaInicioT1(value)
    }
    if(e.target.name === "fechaFinT1"){
      setFechaFinT1(value)
    }
    if(e.target.name === "descripcion1"){
      setDescripcion1(value)
    }
    if(e.target.name === "trabajo2"){
      setTrabajo2(value)
    }
    if(e.target.name === "empresa2"){
      setEmpresa2(value)
    }
    if(e.target.name === "fechaInicioT2"){
      setFechaInicioT2(value)
    }
    if(e.target.name === "fechaFinT2"){
      setFechaFinT2(value)
    }
    if(e.target.name === "descripcion2"){
      setDescripcion2(value)
    }
    if(e.target.name === "trabajo3"){
      setTrabajo3(value)
    }
    if(e.target.name === "empresa3"){
      setEmpresa3(value)
    }
    if(e.target.name === "fechaInicioT3"){
      setFechaInicioT3(value)
    }
    if(e.target.name === "fechaFinT3"){
      setFechaFinT3(value)
    }
    if(e.target.name === "descripcion3"){
      setDescripcion3(value)
    }
    if(e.target.name === "trabajo4"){
      setTrabajo4(value)
    }
    if(e.target.name === "empresa4"){
      setEmpresa4(value)
    }
    if(e.target.name === "fechaInicioT4"){
      setFechaInicioT4(value)
    }
    if(e.target.name === "fechaFinT4"){
      setFechaFinT4(value)
    }
    if(e.target.name === "descripcion4"){
      setDescripcion4(value)
    }
    if(e.target.name === "trabajo5"){
      setTrabajo5(value)
    }
    if(e.target.name === "empresa5"){
      setEmpresa5(value)
    }
    if(e.target.name === "fechaInicioT5"){
      setFechaInicioT5(value)
    }
    if(e.target.name === "fechaFinT5"){
      setFechaFinT5(value)
    }
    if(e.target.name === "descripcion5"){
      setDescripcion5(value)
    }
    if(e.target.name === "trabajo6"){
      setTrabajo6(value)
    }
    if(e.target.name === "empresa6"){
      setEmpresa6(value)
    }
    if(e.target.name === "fechaInicioT6"){
      setFechaInicioT6(value)
    }
    if(e.target.name === "fechaFinT6"){
      setFechaFinT6(value)
    }
    if(e.target.name === "descripcion6"){
      setDescripcion6(value)
    }
    if(e.target.name === "trabajo7"){
      setTrabajo7(value)
    }
    if(e.target.name === "empresa7"){
      setEmpresa7(value)
    }
    if(e.target.name === "fechaInicioT7"){
      setFechaInicioT7(value)
    }
    if(e.target.name === "fechaFinT7"){
      setFechaFinT7(value)
    }
    if(e.target.name === "descripcion7"){
      setDescripcion7(value)
    }
    if(e.target.name === "trabajo8"){
      setTrabajo8(value)
    }
    if(e.target.name === "empresa8"){
      setEmpresa8(value)
    }
    if(e.target.name === "fechaInicioT8"){
      setFechaInicioT8(value)
    }
    if(e.target.name === "fechaFinT8"){
      setFechaFinT8(value)
    }
    if(e.target.name === "descripcion8"){
      setDescripcion8(value)
    }
    if(e.target.name === "presentacion"){
      setPresentacion(value)
    }
    if(e.target.name === "idioma1"){
      setIdioma1(value)
    }
    if(e.target.name === "nivelIdioma1"){
      setNivelIdioma1(value)
    }
    if(e.target.name === "idioma2"){
      setIdioma2(value)
    }
    if(e.target.name === "nivelIdioma2"){
      setNivelIdioma2(value)
    }
   }

   
   const handleChangeCastellano = (event) => {
    setCastellano(event.target.value);
    console.log(event.target.value);
   }

   const handleChangeIngles = (event) => {
    setIngles(event.target.value);
    console.log(event.target.value);
   }

   const handleChangeNivelIdioma1 = (event) => {
    setNivelIdioma1(event.target.value);
 
   }

   const handleChangeNivelIdioma2 = (event) => {
    setNivelIdioma2(event.target.value);
 
   }
   
   function handleButtonAddGrado () {
    const newnumGrados = numGrados + 1;
    setnumGrados(newnumGrados);
    console.log(numGrados);
   }

   function handleButtonRemoveGrado () {
    const newnumGrados = numGrados - 1;
    setnumGrados(newnumGrados);
    console.log(numGrados);
   }

   function handleButtonRemoveGrado2 () {
    const newNumGrados = numGrados - 1;
    setnumGrados(newNumGrados);
    setCarrera2(""); 
      setUni2("");
      setFechaInicio2(""); 
      setFechaFin2("");
      setNotaMedia2("");
   }

   function handleButtonRemoveGrado3 () {
    const newNumGrados = numGrados - 1;
    setnumGrados(newNumGrados);
    setCarrera3(""); 
      setUni3("");
      setFechaInicio3(""); 
      setFechaFin3("");
      setNotaMedia3("");
   }
   function handleButtonRemoveGrado4 () {
    const newNumGrados = numGrados - 1;
    setnumGrados(newNumGrados);
    setCarrera4(""); 
      setUni4("");
      setFechaInicio4(""); 
      setFechaFin4("");
      setNotaMedia4("");
   }

   function handleButtonAddPosGrado () {
    const newnumPosGrados = numPosGrados + 1;
    setnumPosGrados(newnumPosGrados);
    console.log(numGrados);
   }

   function handleButtonRemovePosGrado () {
    const newnumPosGrados = numPosGrados - 1;
    setnumPosGrados(newnumPosGrados);
    console.log(numGrados);
   }

   function handleButtonRemovePosGrado2 () {
    const newNumPosGrados = numPosGrados - 1;
    setnumPosGrados(newNumPosGrados);
    setCarrera6(""); 
      setUni6("");
      setFechaInicio6(""); 
      setFechaFin6("");
      setNotaMedia6("");
   }
   function handleButtonRemovePosGrado3 () {
    const newNumPosGrados = numPosGrados - 1;
    setnumPosGrados(newNumPosGrados);
    setCarrera7(""); 
      setUni7("");
      setFechaInicio7(""); 
      setFechaFin7("");
      setNotaMedia7("");
   }

   function handleButtonRemovePosGrado4 () {
    const newNumPosGrados = numPosGrados - 1;
    setnumPosGrados(newNumPosGrados);
    setCarrera8(""); 
      setUni8("");
      setFechaInicio8(""); 
      setFechaFin8("");
      setNotaMedia8("");
   }

   function handleButtonAddTrabajo () {
    const newnumTrabajos = numTrabajos + 1;
    setnumTrabajos(newnumTrabajos);
    console.log(numTrabajos);
   }

   function handleButtonRemoveTrabajo () {
    const newnumTrabajos = numTrabajos - 1;
    setnumTrabajos(newnumTrabajos);
    console.log(numGrados);
   }

   function handleButtonRemoveTrabajo2 () {
    const newNumTrabajos = numTrabajos - 1;
    setnumTrabajos(newNumTrabajos);
    setTrabajo2(""); 
      setEmpresa2("");
      setFechaInicioT2(""); 
      setFechaFinT2("");
      setDescripcion2("");
   }

   function handleButtonRemoveTrabajo3 () {
    const newNumTrabajos = numTrabajos - 1;
    setnumTrabajos(newNumTrabajos);
    setTrabajo3(""); 
      setEmpresa3("");
      setFechaInicioT3(""); 
      setFechaFinT3("");
      setDescripcion3("");
   }

   function handleButtonRemoveTrabajo4 () {
    const newNumTrabajos = numTrabajos - 1;
    setnumTrabajos(newNumTrabajos);
    setTrabajo4(""); 
      setEmpresa4("");
      setFechaInicioT4(""); 
      setFechaFinT4("");
      setDescripcion4("");
   }
   function handleButtonRemoveTrabajo5 () {
    const newNumTrabajos = numTrabajos - 1;
    setnumTrabajos(newNumTrabajos);
    setTrabajo5(""); 
      setEmpresa5("");
      setFechaInicioT5(""); 
      setFechaFinT5("");
      setDescripcion5("");
   }
   function handleButtonRemoveTrabajo6 () {
    const newNumTrabajos = numTrabajos - 1;
    setnumTrabajos(newNumTrabajos);
    setTrabajo6(""); 
      setEmpresa6("");
      setFechaInicioT6(""); 
      setFechaFinT6("");
      setDescripcion6("");
   }
   function handleButtonRemoveTrabajo7 () {
    const newNumTrabajos = numTrabajos - 1;
    setnumTrabajos(newNumTrabajos);
    setTrabajo7(""); 
      setEmpresa7("");
      setFechaInicioT7(""); 
      setFechaFinT7("");
      setDescripcion7("");
   }
   function handleButtonRemoveTrabajo8 () {
    const newNumTrabajos = numTrabajos - 1;
    setnumTrabajos(newNumTrabajos);
    setTrabajo8(""); 
      setEmpresa8("");
      setFechaInicioT8(""); 
      setFechaFinT8("");
      setDescripcion8("");
   }

   const handleChangeExperiencia2 = (event, newAnos2) => {
    setAnos(newAnos2);
  };



   async function handleUpdatePerfil (docId, 
    nombre, 
    telefono,
    ciudad, 
    visibilidad, 
    email, 
    carrera1, 
    uni1, 
    fechaInicio1, 
    fechaFin1,
    carrera2, 
    uni2, 
    fechaInicio2, 
    fechaFin2,
    carrera3, 
    uni3, 
    fechaInicio3, 
    fechaFin3,
    carrera4, 
    uni4, 
    fechaInicio4, 
    fechaFin4,
    carrera5, 
    uni5, 
    fechaInicio5, 
    fechaFin5,
    carrera6, 
    uni6, 
    fechaInicio6, 
    fechaFin6,
    carrera7, 
    uni7, 
    fechaInicio7, 
    fechaFin7,
    carrera8, 
    uni8, 
    fechaInicio8, 
    fechaFin8,
    carrera9, 
    uni9, 
    fechaInicio9, 
    fechaFin9,
    numGrados,
    numPosGrados,
    numTrabajos,
    area,
    castellano,
    ingles,
    notaMedia1,
    notaMedia2,
    notaMedia3,
    notaMedia4,
    notaMedia5,
    notaMedia6,
    notaMedia7,
    notaMedia8,
    trabajo1,
    empresa1,
    fechaInicioT1,
    fechaFinT1,
    descripcion1,
    trabajo2,
    empresa2,
    fechaInicioT2,
    fechaFinT2,
    descripcion2,
    trabajo3,
    empresa3,
    fechaInicioT3,
    fechaFinT3,
    descripcion3,
    trabajo4,
    empresa4,
    fechaInicioT4,
    fechaFinT4,
    descripcion4,
    trabajo5,
    empresa5,
    fechaInicioT5,
    fechaFinT5,
    descripcion5,
    trabajo6,
    empresa6,
    fechaInicioT6,
    fechaFinT6,
    descripcion6,
    trabajo7,
    empresa7,
    fechaInicioT7,
    fechaFinT7,
    descripcion7,
    trabajo8,
    empresa8,
    fechaInicioT8,
    fechaFinT8,
    descripcion8,
    presentacion,
    anos,
    idioma1,
    nivelIdioma1,
    idioma2,
    nivelIdioma2,
    ) {
    const perfil = perfiles.find((item) => item.docId === docId)
    perfil.nombre = nombre;
    perfil.telefono = telefono;
    perfil.ciudad = ciudad;
    perfil.visibilidad = visibilidad;
    perfil.email = email;
    perfil.notaMedia1 = notaMedia1;
    perfil.carrera1 = carrera1;
    perfil.uni1 = uni1;
    perfil.fechaInicio1 = fechaInicio1;
    perfil.fechaFin1 = fechaFin1;
    perfil.carrera2 = carrera2;
    perfil.uni2 = uni2;
    perfil.fechaInicio2 = fechaInicio2;
    perfil.fechaFin2 = fechaFin2;
    perfil.notaMedia2 = notaMedia2;
    perfil.notaMedia3 = notaMedia3;
    perfil.notaMedia4 = notaMedia4;
    perfil.notaMedia5 = notaMedia5;
    perfil.notaMedia6 = notaMedia6;
    perfil.notaMedia7 = notaMedia7;
    perfil.notaMedia8 = notaMedia8;
    perfil.carrera3 = carrera3;
    perfil.uni3 = uni3;
    perfil.fechaInicio3 = fechaInicio3;
    perfil.fechaFin3 = fechaFin3;
    perfil.carrera4 = carrera4;
    perfil.uni4 = uni4;
    perfil.fechaInicio4 = fechaInicio4;
    perfil.fechaFin4 = fechaFin4;
    perfil.carrera5 = carrera5;
    perfil.uni5 = uni5;
    perfil.fechaInicio5 = fechaInicio5;
    perfil.fechaFin5 = fechaFin5;
    perfil.carrera6 = carrera6;
    perfil.uni6 = uni6;
    perfil.fechaInicio6 = fechaInicio6;
    perfil.fechaFin6 = fechaFin6;
    perfil.carrera7 = carrera7;
    perfil.uni7 = uni7;
    perfil.fechaInicio7 = fechaInicio7;
    perfil.fechaFin7 = fechaFin7;
    perfil.carrera8 = carrera8;
    perfil.uni8 = uni8;
    perfil.fechaInicio8 = fechaInicio8;
    perfil.fechaFin8 = fechaFin8;
    perfil.carrera9 = carrera9;
    perfil.uni9 = uni9;
    perfil.fechaInicio9 = fechaInicio9;
    perfil.fechaFin9 = fechaFin9;
    perfil.trabajo1 = trabajo1;
    perfil.empresa1 = empresa1;
    perfil.fechaInicioT1 = fechaInicioT1;
    perfil.fechaFinT1 = fechaFinT1;
    perfil.descripcion1 = descripcion1;
    perfil.trabajo2 = trabajo2;
    perfil.empresa2 = empresa2;
    perfil.fechaInicioT2 = fechaInicioT2;
    perfil.fechaFinT2 = fechaFinT2;
    perfil.descripcion2 = descripcion2;
    perfil.trabajo3 = trabajo3;
    perfil.empresa3 = empresa3;
    perfil.fechaInicioT3 = fechaInicioT3;
    perfil.fechaFinT3 = fechaFinT3;
    perfil.descripcion3 = descripcion3;
    perfil.trabajo4 = trabajo4;
    perfil.empresa4 = empresa4;
    perfil.fechaInicioT4 = fechaInicioT4;
    perfil.fechaFinT4 = fechaFinT4;
    perfil.descripcion4 = descripcion4;
    perfil.trabajo5 = trabajo5;
    perfil.empresa5 = empresa5;
    perfil.fechaInicioT5 = fechaInicioT5;
    perfil.fechaFinT5 = fechaFinT5;
    perfil.descripcion5 = descripcion5;
    perfil.trabajo6 = trabajo6;
    perfil.empresa6 = empresa6;
    perfil.fechaInicioT6 = fechaInicioT6;
    perfil.fechaFinT6 = fechaFinT6;
    perfil.descripcion6 = descripcion6;
    perfil.trabajo7 = trabajo7;
    perfil.empresa7 = empresa7;
    perfil.fechaInicioT7 = fechaInicioT7;
    perfil.fechaFinT7 = fechaFinT7;
    perfil.descripcion7 = descripcion7;
    perfil.trabajo8 = trabajo8;
    perfil.empresa8 = empresa8;
    perfil.fechaInicioT8 = fechaInicioT8;
    perfil.fechaFinT8 = fechaFinT8;
    perfil.descripcion8 = descripcion8;        
    perfil.numGrados = numGrados;
    perfil.numPosGrados = numPosGrados;
    perfil.numTrabajos = numTrabajos;
    perfil.area = area;
    perfil.castellano = castellano;
    perfil.ingles = ingles;
    perfil.presentacion = presentacion;
    perfil.anos = anos;
    perfil.idioma1 = idioma1;
    perfil.nivelIdioma1 = nivelIdioma1;
    perfil.idioma2 = idioma2;
    perfil.nivelIdioma2 = nivelIdioma2;
    await updatePerfil(docId, perfil);
    setNombre(nombre);
   }

    console.log(numGrados);
    console.log(perfiles.length)
    console.log(perfiles)
  
     function addPerfil(){
      const newPerfil = {
        id: uuidv4(),
        nombre: nombre,
        telefono: telefono,
        ciudad: ciudad,
        uid: currentUser.uid,
        visibilidad: visibilidad,
        email: email,
        carrera1: carrera1,
        uni1: uni1,
        fechaInicio1: fechaInicio1,
        fechaFin1: fechaFin1,
        notaMedia1: notaMedia1,
        carrera2: carrera2,
        uni2: uni2,
        fechaInicio2: fechaInicio2,
        fechaFin2: fechaFin2,
        notaMedia2: notaMedia2,
        notaMedia3: notaMedia3,
        notaMedia4: notaMedia4,
        notaMedia5: notaMedia5,
        notaMedia6: notaMedia6,
        notaMedia7: notaMedia7,
        notaMedia8: notaMedia8,
        carrera3: carrera3,
        uni3: uni3,
        fechaInicio3: fechaInicio3,
        fechaFin3: fechaFin3,
        carrera4: carrera4,
        uni4: uni4,
        fechaInicio4: fechaInicio4,
        fechaFin4: fechaFin4,
        carrera5: carrera5,
        uni5: uni5,
        fechaInicio5: fechaInicio5,
        fechaFin5: fechaFin5,
        carrera6: carrera6,
        uni6: uni6,
        fechaInicio6: fechaInicio6,
        fechaFin6: fechaFin6,
        carrera7: carrera7,
        uni7: uni7,
        fechaInicio7: fechaInicio7,
        fechaFin7: fechaFin7,
        carrera8: carrera8,
        uni8: uni8,
        fechaInicio8: fechaInicio8,
        fechaFin8: fechaFin8,
        carrera9: carrera9,
        uni9: uni9,
        fechaInicio9: fechaInicio9,
        fechaFin9: fechaFin9,
        trabajo1: trabajo1,
        empresa1: empresa1,
        fechaInicioT1: fechaInicioT1,
        fechaFinT1: fechaFinT1,
        descripcion1: descripcion1,
        trabajo2: trabajo2,
        empresa2: empresa2,
        fechaInicioT2: fechaInicioT2,
        fechaFinT2: fechaFinT2,
        descripcion2: descripcion2,
        trabajo3: trabajo3,
        empresa3: empresa3,
        fechaInicioT3: fechaInicioT3,
        fechaFinT3: fechaFinT3,
        descripcion3: descripcion3,
        trabajo4: trabajo4,
        empresa4: empresa4,
        fechaInicioT4: fechaInicioT4,
        fechaFinT4: fechaFinT4,
        descripcion4: descripcion4,
        trabajo5: trabajo5,
        empresa5: empresa5,
        fechaInicioT5: fechaInicioT5,
        fechaFinT5: fechaFinT5,
        descripcion5: descripcion5,
        trabajo6: trabajo6,
        empresa6: empresa6,
        fechaInicioT6: fechaInicioT6,
        fechaFinT6: fechaFinT6,
        descripcion6: descripcion6,
        trabajo7: trabajo7,
        empresa7: empresa7,
        fechaInicioT7: fechaInicioT7,
        fechaFinT7: fechaFinT7,
        descripcion7: descripcion7,
        trabajo8: trabajo8,
        empresa8: empresa8,
        fechaInicioT8: fechaInicioT8,
        fechaFinT8: fechaFinT8,
        descripcion8: descripcion8,
        numGrados: numGrados,
        numPosGrados: numPosGrados,
        numTrabajos: numTrabajos, 
        area: area,
        castellano: castellano,
        ingles: ingles,
        presentacion: presentacion,
        anos: anos,
        idioma1: idioma1,
        nivelIdioma1: nivelIdioma1,
        idioma2: idioma2,
        nivelIdioma2: nivelIdioma2, 
  
      };
      const res = insertNewPerfil(newPerfil);
      newPerfil.docId = res.id;
      setPerfiles([...perfiles, newPerfil]);
     }
 
     function handleSubmit (e) {
      e.preventDefault();
      addPerfil();
      setOpen(false)
      setButtonText("¡Guardado!");
      console.log(area)
      console.log(perfiles)
      
     }


    return (
    
      <AuthProvider 
      onUserLoggedIn={handleUserLoggedIn}
      onUserNotRegistered={handleUserNotRegistered}
      onUserNotLoggedIn={handleUserNotLoggedIn}
      > 
    <div>

          

    <Header />
    {/*
<Dialog
        open={open}
        onClose={handleClose}
        sx={{
       
          display: {
            xl: "block",
            l: "block",
            m: "none",
            s: "none",
            xs: "none",
          },
        }}>
          <DialogContent sx={{bgcolor: "#1f387d"}}>

<div>
        <Box
    
          sx={{
       
            display: {
              xl: "block",
              l: "block",
              m: "none",
              s: "none",
              xs: "none",
            },
          }}
        >
          <Stack
            display="flex"
            justifyContent="center"
            sx={{
              alignContent: "center",
              alignItems: "center",
              justifyContent: "center",
            }}
          >

        <Stack sx={{paddingTop: "20px", paddingBottom: "20px" }} spacing={2}>
        

         <Stack direction="column" sx={{alignContent: "center", alignItems: "center", paddingTop: "10px"}}>
           <Stack direction="column" sx={{alignContent: "center", alignItems: "center", paddingTop: "10px"}}>
           <Image src="https://firebasestorage.googleapis.com/v0/b/jobsandlaw-5e0ba.appspot.com/o/images%2FRankings%20(1).png?alt=media&token=d8a704e7-9d2d-4b38-ac38-ddd13213c1c6" style={{width: "120px"}} />
           <Typography variant="h5" component="div" color = "whitesmoke" sx={{fontWeight: "bold", textAlign: "center", marginBottom: "0px"}} >
           Oh oh... ¡Todavía no has creado tu perfil profesional!
         </Typography>
         <Typography sx={{ fontSize: 14, textAlign: "center", paddingTop: "10px" }} color="whitesmoke" gutterBottom>
           Los principales despachos, empresas y headhunters están buscando en Jobs&Law candidatos como tú, pero... eres invisible para ellos.
         </Typography>
        
         <Typography variant="h5" component="div" color = "whitesmoke" sx={{fontWeight: "bold", textAlign: "center", marginBottom: "0px", paddingTop: "30px"}} >
           Crea tu perfil completamente gratis y empieza a recibir ofertas de manera discreta y confidencial.
         </Typography>
         <Typography variant="h6" component="div" color = "whitesmoke" sx={{fontWeight: "bold", textAlign: "center", marginBottom: "0px", paddingTop: "30px"}} >
           Además, si creas tu perfil profesional hoy...
         </Typography>
         <Typography variant="h5" component="div" color = "whitesmoke" sx={{fontWeight: "bold", textAlign: "center", marginBottom: "0px"}} >
           ¡Te damos acceso gratis a Premium durante 30 días!
         </Typography>
         <List sx={{color: "white", padding: "20px", lineHeight: '10px'}}>
          <ListItem disablePadding sx={{lineHeight: '10px'}}>
            <ListItemButton>
              <ListItemIcon>
                <ArrowForwardIosIcon sx={{color: "white"}} />
              </ListItemIcon>
              <ListItemText disableTypography
        primary={<Typography >Acceso a <u>todas</u> las vacantes del mercado</Typography>} sx={{color: "white", lineHeight: 0, margin: 0 }} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <ArrowForwardIosIcon sx={{color: "white"}} />
              </ListItemIcon>
              <ListItemText primary="Información interna sobre los principales despachos" sx={{color: "white"}} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <ArrowForwardIosIcon sx={{color: "white"}} />
              </ListItemIcon>
              <ListItemText primary="Perfil profesional preferente" sx={{color: "white"}} />
            </ListItemButton>
          </ListItem>
        </List>
         </Stack>
         <Button variant="outlined" sx={{width: "200px", marginTop: "20px", alignContent: "center", textAlign: "center", marginBottom: "20px", paddingTop: "10px", paddingLeft: "10px", paddingRight: "10px", paddingBottom: "10px", color: "white", bgcolor: "#1f387d", borderColor: "white", fontSize: "15px", ':hover': {
      bgcolor: 'white', // theme.palette.primary.main
      color: '#1f387d',
    }}}

     color="inherit"
     href="./perfilprofesional"
   >
     <span>Crear perfil profesional</span>
   </Button>
         </Stack>
         <Button component="button" onClick={handleClose1} >
         <Typography sx={{color: "white", textAlign: "center", fontSize: "14px", paddingTop: "10px"}}>
            
            Prefiero no disfrutar de mi prueba gratuita</Typography></Button>

   
        
                 </Stack> 
                 </Stack>
                 </Box>



                
                 </div>
                 </DialogContent>
</Dialog>


<Dialog
        open={open}
        onClose={handleClose}
        sx={{
       
          display: {
            xl: "none",
            l: "none",
            m: "block",
            s: "block",
            xs: "block",
          },
        }}>
          <DialogContent sx={{bgcolor: "#1f387d"}}>

<div>
        <Box
    
       
        >
          <Stack
            display="flex"
            justifyContent="center"
            sx={{
              alignContent: "center",
              alignItems: "center",
              justifyContent: "center",
            }}
          >

        <Stack sx={{paddingTop: "0px", paddingBottom: "20px" }} spacing={2}>
        

         <Stack direction="column" sx={{alignContent: "center", alignItems: "center"}}>
           <Stack direction="column" sx={{alignContent: "center", alignItems: "center"}}>
           <Image src="https://firebasestorage.googleapis.com/v0/b/jobsandlaw-5e0ba.appspot.com/o/images%2FRankings%20(1).png?alt=media&token=d8a704e7-9d2d-4b38-ac38-ddd13213c1c6" style={{width: "120px"}} />
           <Typography component="div" color = "whitesmoke" sx={{fontSize: 18, fontWeight: "bold", textAlign: "center", marginBottom: "0px"}} >
           Oh oh... ¡Todavía no has creado tu perfil profesional!
         </Typography>
         <Typography sx={{ fontSize: 14, textAlign: "center", paddingTop: "10px" }} color="whitesmoke" gutterBottom>
           Los principales despachos, empresas y headhunters están buscando en Jobs&Law candidatos como tú, pero... eres invisible para ellos.
         </Typography>
        
         <Typography component="div" color = "whitesmoke" sx={{fontSize: 16, fontWeight: "bold", textAlign: "center", marginBottom: "0px", paddingTop: "30px"}} >
           Crea tu perfil completamente gratis y empieza a recibir ofertas de manera discreta y confidencial.
         </Typography>
         <Typography component="div" color = "whitesmoke" sx={{fontSize: 16, fontWeight: "bold", textAlign: "center", marginBottom: "0px", paddingTop: "30px"}} >
           Además, si creas tu perfil profesional hoy...
         </Typography>
         <Typography component="div" color = "whitesmoke" sx={{fontSize: 16, fontWeight: "bold", textAlign: "center", marginBottom: "0px"}} >
           ¡Te damos acceso gratis a Premium durante 30 días!
         </Typography>
         
         </Stack>
         <Button variant="outlined" sx={{width: "200px", marginTop: "20px", alignContent: "center", textAlign: "center", marginBottom: "20px", paddingTop: "10px", paddingLeft: "10px", paddingRight: "10px", paddingBottom: "10px", color: "white", bgcolor: "#1f387d", borderColor: "white", fontSize: "15px", ':hover': {
      bgcolor: 'white', // theme.palette.primary.main
      color: '#1f387d',
    }}}

     color="inherit"
     href="./perfilprofesional"
   >
     <span>Crear perfil profesional</span>
   </Button>
         </Stack>
         <Button component="button" onClick={handleClose1} >
         <Typography sx={{color: "white", textAlign: "center", fontSize: "14px", paddingTop: "10px"}}>
            
            Prefiero no disfrutar de mi prueba gratuita</Typography></Button>

   
        
                 </Stack> 
                 </Stack>
                 </Box>



                
                 </div>
                 </DialogContent>
</Dialog> */}

{/*<Fab variant="extended" style={{justifyContent: "center",
      alignItems: "center"}} sx={{width: "500px", "& .MuiDialog-container": {
      justifyContent: "center",
      alignItems: "center",
      color: "green",
      bgcolor: "blue",
      marginTop: "400px"
    }
  }}
  PaperProps={{
    sx: {
      m: 0,
      top: 10,
      left: 10
    }}}>
     
     <Typography component="div" color = "whitesmoke" sx={{fontSize: 16, fontWeight: "bold", textAlign: "center", marginBottom: "0px"}} >
           ¡Te damos acceso gratis a Premium durante 30 días!
         </Typography>
  </Fab>*/}

{(user && perfiles.length === 0 && !isPremium && !isOldPremium) &&  
<div>
<Dialog
        open={open}
        
        sx={{
       
          display: {
            xl: "block",
            l: "block",
            m: "none",
            s: "none",
            xs: "none",
          },
        }}>
          <DialogContent sx={{}}>

<div>
<Box sx={{display: { xl: 'block', l: "block", m: "block", s:"none", xs: 'none'}}} >

     <Grid container component="main" sx={{ }}>
      <Grid item xs={12} sm={12} md={12} component={Paper} elevation={0} square sx={{}}>
       
      <Stack sx={{ paddingTop: "20px" }} spacing={2}>
      <Typography variant="h6" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", textAlign: "center"}}
              >Para mostrarte las vacantes que más te interesan, <br></br> necesitamos saber algunas cosas sobre ti.
            </Typography>
      
            <Typography variant="p" color = "rgba(43, 117, 177, 0.9)"
              sx={{textAlign: "center", fontSize: "12px"}}
              >*La confidencialidad está garantizada.
            </Typography>
            
              </Stack>
           
           
             <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: "center",
              alignItems: "center",
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          >
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{mt: 1, marginBottom: "20px", borderRadius: "25px", padding: "20px"}}>
        

      <Typography variant="body1" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", textAlign: "center"}}
              >Indica tus áreas de práctica
            </Typography>

       <Autocomplete
          multiple
          sx={{paddingTop: "20px", paddingBottom: "20px"}}
          id="tags-standard"
          options={listareas}
          getOptionLabel={(option) => option.title}
          onChange={(e, value)=> setArea(value)}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Áreas de práctica"
              placeholder="Áreas de práctica"
            />
          )}
        />
 
      <Typography variant="body1" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "40px", textAlign: "center"}}
              >Indica tus años de experiencia
            </Typography>
<Slider
        getAriaLabel={() => 'Años de experiencia'}
        value={anos}
        onChange={handleChangeExperiencia2}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
        step={1}
        min={0}
        max={20}
        marks={anosExperiencia2}
      />
      <Typography variant="body1" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "40px", textAlign: "center"}}
              >Indica tu despacho / empresa actual
            </Typography>
<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green", textAlign: "center"}}
                margin="normal"
          
                fullWidth
                id="empresa1"
                label="Despacho / Empresa Actual"
                name="empresa1"
                autoComplete="empresa1"
             
                onChange={handleChange}
              />

<Typography variant="body1" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "40px", textAlign: "center"}}
              >Indica tu ciudad
            </Typography>
<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green", textAlign: "center"}}
                margin="normal"
          
                fullWidth
                id="ciudad"
                label="Ciudad"
                name="ciudad"
                autoComplete="ciudad"
             
                onChange={handleChange}
              />
          
   
        

          {(area !== null && anos !== "" && empresa1 !== "" && ciudad !== "") && <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, backgroundColor: "rgba(43, 117, 177, 0.9)", color: "white", marginTop: "50px"}}

              >
                Guardar cambios
              </Button>}

              {(area === null || anos === "" || empresa1 === "" || ciudad === "") && <Button
                type="submit"
                fullWidth
                variant="contained"
                disabled
                sx={{ mt: 3, mb: 2, backgroundColor: "grey", color: "white", marginTop: "50px"}}

              >
                Completa los campos
              </Button>}
              <Link to={"/dashboard"}><Typography sx={{color: "rgba(43, 117, 177, 0.9)", textAlign: "center", fontSize: "14px", paddingTop: "10px"}}>
            <u>Volver al escritorio</u></Typography></Link>
            </Box>
          </Box>
                    
          
          
        
      </Grid>

     
       
 
     </Grid>



</Box>











                
                 </div>
                 </DialogContent>
</Dialog>


<Dialog
        open={open}
        
        sx={{
       
          display: {
            xl: "none",
            l: "none",
            m: "block",
            s: "block",
            xs: "block",
          },
        }}>
          <DialogContent sx={{}}>

<div>
<Box sx={{}} >

     <Grid container component="main" sx={{ }}>
      <Grid item xs={12} sm={12} md={12} component={Paper} elevation={0} square sx={{}}>
       
      <Stack sx={{ paddingTop: "20px" }} spacing={2}>
      <Typography color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "10px", textAlign: "center", fontSize: "16px", fontWeight: "600"}}
              >Para mostrarte las vacantes que más te interesan, necesitamos saber algunas cosas sobre ti.
            </Typography>
      
            <Typography variant="p" color = "rgba(43, 117, 177, 0.9)"
              sx={{textAlign: "center", fontSize: "12px"}}
              >*La confidencialidad está garantizada.
            </Typography>
            
              </Stack>
           
           
             <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: "center",
              alignItems: "center",
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          >
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{mt: 1, marginBottom: "20px", borderRadius: "25px", padding: "20px"}}>
        

      <Typography variant="body1" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", textAlign: "center"}}
              >Indica tus áreas de práctica
            </Typography>

       <Autocomplete
          multiple
          sx={{paddingTop: "20px", paddingBottom: "20px"}}
          id="tags-standard"
          options={listareas}
          getOptionLabel={(option) => option.title}
          onChange={(e, value)=> setArea(value)}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Áreas de práctica"
              placeholder="Áreas de práctica"
            />
          )}
        />
 
      <Typography variant="body1" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "40px", textAlign: "center"}}
              >Indica tus años de experiencia
            </Typography>
<Slider
        getAriaLabel={() => 'Años de experiencia'}
        value={anos}
        onChange={handleChangeExperiencia2}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
        step={1}
        min={0}
        max={20}
        marks={anosExperienciaMovil}
      />
      <Typography variant="body1" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "40px", textAlign: "center"}}
              >Indica tu despacho / empresa actual
            </Typography>
<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green", textAlign: "center"}}
                margin="normal"
          
                fullWidth
                id="empresa1"
                label="Despacho / Empresa Actual"
                name="empresa1"
                autoComplete="empresa1"
             
                onChange={handleChange}
              />

<Typography variant="body1" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "40px", textAlign: "center"}}
              >Indica tu ciudad
            </Typography>
<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green", textAlign: "center"}}
                margin="normal"
          
                fullWidth
                id="ciudad"
                label="Ciudad"
                name="ciudad"
                autoComplete="ciudad"
             
                onChange={handleChange}
              />
          
   
        

          {(area !== null && anos !== "" && empresa1 !== "" && ciudad !== "") && <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, backgroundColor: "rgba(43, 117, 177, 0.9)", color: "white", marginTop: "50px"}}

              >
                Guardar cambios
              </Button>}

              {(area === null || anos === "" || empresa1 === "" || ciudad === "") && <Button
                type="submit"
                fullWidth
                variant="contained"
                disabled
                sx={{ mt: 3, mb: 2, backgroundColor: "grey", color: "white", marginTop: "50px"}}

              >
                Completa los campos
              </Button>}
              <Link to={"/dashboard"}><Typography sx={{color: "rgba(43, 117, 177, 0.9)", textAlign: "center", fontSize: "14px", paddingTop: "10px"}}>
            <u>Volver al escritorio</u></Typography></Link>
            </Box>
          </Box>
                    
          
          
        
      </Grid>

     
       
 
     </Grid>



</Box>











                
                 </div>
                 </DialogContent>
</Dialog>
</div>
}


{!user &&  
<Dialog
        open={open}
        
        sx={{
       
 
          
        }}>
          <DialogContent sx={{bgcolor: '#5c90bd'}}>
            <SigninAndMore/>                
          </DialogContent>
</Dialog>
}



    <Grid container componente="main" spacing={0} sx={{justifyContent: "center", alignItems: "center", paddingTop: "40px"}}>
      <Grid item xs={0} sm={1} md={1} square sx={{display: { xl: 'inline', l: "inline", m: "none", s:"none", xs: 'none' }}}>
      </Grid>
      <Grid item xs={12} sm={3} md={3} square sx={{display: { xl: 'inline', l: "inline", m: "none", s:"none", xs: 'none' }}}>
      <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: "flex-end",
                justifyContent: "center",
                marginTop: "20px",

             }}
            >
       
       <Autocomplete
        value={area2}
        onChange={(event, newArea) => {
          setArea2(newArea);
        }}
        inputValue={inputArea}
        onInputChange={(event, newInputArea) => {
          setInputArea(newInputArea);
          if(page !== 1) {
            setPage(1)
          }
        }}
        id="controllable-states-demo"
        options={areas}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label="Área de práctica" />}
      />


      </Box>
      </Grid>
      
      <Grid item xs={1} sm={1} md={1} square sx={{display: { xl: 'inline', l: "inline", m: "none", s:"none", xs: 'none' }}}>
      <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: "center",
                justifyContent: "center",
                marginTop: "10px",

             }}
            >
      
      </Box>
      </Grid>

      

      <Grid item xs={12} sm={3} md={3} square sx={{display: { xl: 'inline', l: "inline", m: "none", s:"none", xs: 'none' }}}>
      <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: "flex-start",
                justifyContent: "center",
                marginTop: "20px",

             }}
            >
              
      <Autocomplete
        value={ciudad2}
        onChange={(event, newCiudad) => {
          setCiudad2(newCiudad);
        }}
        inputValue={inputCiudad}
        onInputChange={(event, newInputCiudad) => {
          setInputCiudad(newInputCiudad);
          if(page !== 1) {
            setPage(1)
          }
        }}
        id="controllable-states-demo"
        options={ciudades}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label="Ciudad" />}
      />
      
      </Box>
      </Grid>

      <Grid item xs={1} sm={1} md={1} square sx={{display: { xl: 'inline', l: "inline", m: "none", s:"none", xs: 'none' }}}>
      </Grid>

    </Grid>


    <Grid container componente="main" spacing={0} sx={{justifyContent: "center", paddingTop: "20px",  display: { xl: 'none', l: "none", m: "inline", s:"inline", xs: 'inline' }}}>
      <Grid item xs={0} sm={1} md={1} square>
      </Grid>
      <Grid item xs={12} sm={12} md={12} square>
      <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: "center",
                justifyContent: "center",
                marginTop: "0px",

             }}
            >
       
       <Autocomplete
        value={area2}
        onChange={(event, newArea) => {
          setArea2(newArea);
        }}
        inputValue={inputArea}
        onInputChange={(event, newInputArea) => {
          setInputArea(newInputArea);
          if(page !== 1) {
            setPage(1)
          }
        }}
        id="controllable-states-demo"
        options={areas}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label="Área de práctica" />}
      />


      </Box>
      </Grid>
      
      <Grid item xs={1} sm={1} md={1} square>
      <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: "center",
                justifyContent: "center",
                marginTop: "10px",

             }}
            >
      
      </Box>
      </Grid>

      

      <Grid item xs={12} sm={12} md={12} square>
      <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: "center",
                justifyContent: "center",
                marginTop: "20px",

             }}
            >
              
      <Autocomplete
        value={ciudad2}
        onChange={(event, newCiudad) => {
          setCiudad2(newCiudad);
        }}
        inputValue={inputCiudad}
        onInputChange={(event, newInputCiudad) => {
          setInputCiudad(newInputCiudad);
          if(page !== 1) {
            setPage(1)
          }
        }}
        id="controllable-states-demo"
        options={ciudades}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label="Ciudad" />}
      />
      
      </Box>
      </Grid>

      <Grid item xs={1} sm={1} md={1} square>
      </Grid>

    </Grid>




    <Grid container component="main" 
        sx={{ 
          marginTop: "20px",
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat', }}>
          <Grid item xs={12} sm={12} md={12} square>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: "center",
                justifyContent: "center",
                bgcolor: 'white',
                backgroundSize: 'cover',
                backgroundPosition: 'right',
                marginTop: "20px",

             }}
             
            >
            <Typography gutterBottom sx={{paddingBottom: "10px"}}>Años de experiencia</Typography>
            <Slider
        sx={{maxWidth: "90%", width: "750px", display: { xl: 'block', l: "block", m: "block", s:"none", xs: 'none' }}}
        getAriaLabel={() => 'Años de experiencia'}
        value={experiencia1}
        onChange={handleChangeExperiencia}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
        step={1}
        min={0}
        max={20}
        marks={anosExperiencia}
      />
      <Slider
        sx={{maxWidth: "90%", width: "750px", display: { xl: 'none', l: "none", m: "none", s:"block", xs: 'block' }}}
        getAriaLabel={() => 'Años de experiencia'}
        value={experiencia1}
        onChange={handleChangeExperiencia}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
        step={1}
        min={0}
        max={20}
        marks={anosExperienciaMovil}
      />
            </Box>
          </Grid>
      </Grid>

      {user && <Grid container component="main" 
            sx={{ 
              marginTop: "40px",
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat', }}>
              <Grid item xs={12} sm={12} md={12} square>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: "center",
                    justifyContent: "center",
                    bgcolor: 'white',
                    backgroundSize: 'cover',
                    backgroundPosition: 'right',
                    marginTop: "20px"
    
                 }}
                 
                >
        <Card elevation={10} sx={{ marginRight: "20px", width: "600px", display: { xl: 'block', l: "block", m: "none", s:"none", xs: 'none'}}}>
        <CardActionArea component={RouterLink} to={"/alljobs"} >
        <CardContent>
        <Typography sx={{textAlign: "center", fontSize: "16px", fontWeight: "500", paddingBottom: "10px" }} color="text.secondary" gutterBottom>
        Hoy hay {totalJobs} vacantes para abogados aceptando candidaturas. En el Buscador Básico puedes acceder a {numberMatchesOpen} de manera gratuita.
        </Typography>
        <Typography sx={{ fontSize: 14,textAlign: "center" }} color="text.secondary" gutterBottom>
          Estás en el Buscador Básico de Empleo para Abogados. Tienes acceso gratuito a una selección de empleos realizada por Jobs&Law que representa aproximadamente el 20% de las vacantes de calidad para abogados que están aceptando candidaturas hoy.
        </Typography>
        <Typography sx={{ fontSize: 14,textAlign: "center",paddingTop: "20px", paddingBottom: "20px" }} color="text.secondary" gutterBottom>
          Recuerda que, si no quieres perderte ninguna oportunidad, puedes ver el 100% de las vacantes abiertas en el Buscador Premium de Empleo para Abogados.
        </Typography>
        <Stack sx={{alignContent: "center"}}>
        <Button href="/alljobs" sx={{alignContent: "center"}}>Ir al Buscador Premium</Button>
        </Stack>
        </CardContent>
        </CardActionArea>
        </Card>
        <Card elevation={10} sx={{ marginRight: "20px", width: "300px", display: {xl: 'none', l: "none", m: "block", s:"block", xs: 'block'}}}>
        <CardActionArea component={RouterLink} to={"/alljobs"} >
        <CardContent>
        <Typography sx={{textAlign: "center", fontSize: "16px", fontWeight: "500", paddingBottom: "10px" }} color="text.secondary" gutterBottom>
        Hoy hay {totalJobs} vacantes para abogados aceptando candidaturas. En el Buscador Básico puedes acceder a {numberMatchesOpen} de manera gratuita.
        </Typography>
        <Typography sx={{ fontSize: 14,textAlign: "center" }} color="text.secondary" gutterBottom>
          Estás en el Buscador Básico de Empleo para Abogados. Tienes acceso gratuito a una selección de empleos realizada por Jobs&Law que representa aproximadamente el 20% de las vacantes de calidad para abogados que están aceptando candidaturas hoy.
        </Typography>
        <Typography sx={{ fontSize: 14,textAlign: "center",paddingTop: "20px", paddingBottom: "20px" }} color="text.secondary" gutterBottom>
          Recuerda que, si no quieres perderte ninguna oportunidad, puedes ver el 100% de las vacantes abiertas en el Buscador Premium de Empleo para Abogados.
        </Typography>
        <Stack sx={{alignContent: "center"}}>
        <Button href="/alljobs" sx={{alignContent: "center"}}>Ir al Buscador Premium</Button>
        </Stack>
        </CardContent>
        </CardActionArea>
        </Card>
                </Box>
                </Grid>
                </Grid>}


                {!user && <Grid container component="main" 
            sx={{ 
              marginTop: "40px",
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat', }}>
              <Grid item xs={12} sm={12} md={12} square>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: "center",
                    justifyContent: "center",
                    bgcolor: 'white',
                    backgroundSize: 'cover',
                    backgroundPosition: 'right',
                    marginTop: "20px"
    
                 }}
                 
                >
        <Card elevation={10} sx={{ marginRight: "20px", width: "600px", display: { xl: 'block', l: "block", m: "none", s:"none", xs: 'none'}}}>
        <CardActionArea component={RouterLink} to={"/"} >
        <CardContent>
        <Typography sx={{ fontSize: 14,textAlign: "center" }} color="text.secondary" gutterBottom>
        Como usuario no registrado solo tienes acceso a una selección limitada de empleos. Puedes acceder a más empleos regístrandote gratis en Jobs&Law.
        </Typography>
        <Stack sx={{alignContent: "center"}}>
        <Button href="/" sx={{alignContent: "center"}}>Registrarse</Button>
        </Stack>
        </CardContent>
        </CardActionArea>
        </Card>
        <Card elevation={10} sx={{ marginRight: "20px", width: "300px", display: {xl: 'none', l: "none", m: "block", s:"block", xs: 'block'}}}>
        <CardActionArea component={RouterLink} to={"/"} >
        <CardContent>
        <Typography sx={{ fontSize: 14,textAlign: "center" }} color="text.secondary" gutterBottom>
        Como usuario no registrado solo tienes acceso a una selección limitada de empleos. Puedes acceder a más empleos regístrandote gratis en Jobs&Law.
        </Typography>
        <Stack sx={{alignContent: "center"}}>
        <Button href="/" sx={{alignContent: "center"}}>Registrarse</Button>
        </Stack>
        </CardContent>
        </CardActionArea>
        </Card>
                </Box>
                </Grid>
                </Grid>}

                 

            <Grid container component="main" 
            sx={{ 
              marginTop: "40px",
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat', }}>
              <Grid item xs={12} sm={12} md={12} square>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: "center",
                    justifyContent: "center",
                    bgcolor: 'white',
                    backgroundSize: 'cover',
                    backgroundPosition: 'right',
                    marginTop: "20px"
    
                 }}
                 
                >
                {eachjob2}
                
                {(numberLimitedJobs <= 5 && numberLimitedJobs !== 0 && numberLimitedJobs !== 1) && 
                 
                 <Grid container component="main" 
            sx={{ 
              marginTop: "40px",
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat', }}>
              <Grid item xs={12} sm={12} md={12} square>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: "center",
                    justifyContent: "center",
                    bgcolor: 'white',
                    backgroundSize: 'cover',
                    backgroundPosition: 'right',
                    marginTop: "20px",
                    marginBottom: "80px"
                 }}
                 
                >
        <Card elevation={10} sx={{ marginRight: "20px", width: "600px", bgcolor: "#367bb3", display: { xl: 'block', l: "block", m: "none", s:"none", xs: 'none'}}}>
        <CardActionArea component={RouterLink} to={"/alljobs"} >
        <CardContent>
        <Typography sx={{textAlign: "center", fontSize: "16px", fontWeight: "500", paddingBottom: "10px" }} color="white" gutterBottom>
        En el Buscador Básico hay solo {numberLimitedJobs} empleos que cumplen tus criterios de búsqueda. En el Buscador Premium tienes acceso a {numberMatchesPremium - 1} empleos adicionales que cumplen esos criterios.
        </Typography>
        <Stack sx={{alignContent: "center"}}>
        <Button variant="outlined" href="/alljobs" sx={{alignContent: "center", color: "white"}}>Ir al Buscador Premium</Button>
        </Stack>
        </CardContent>
        </CardActionArea>
        </Card>
        <Card elevation={10} sx={{ marginRight: "20px", width: "300px", bgcolor: "#367bb3", display: {xl: 'none', l: "none", m: "block", s:"block", xs: 'block'}}}>
        <CardActionArea component={RouterLink} to={"/alljobs"} >
        <CardContent>
        <Typography sx={{textAlign: "center", fontSize: "16px", fontWeight: "500", paddingBottom: "10px" }} color="white" gutterBottom>
        En el Buscador Básico hay solo {numberLimitedJobs} empleos que cumplen tus criterios de búsqueda. En el Buscador Premium tienes acceso a {numberMatchesPremium - 1} empleos adicionales que cumplen esos criterios.
        </Typography>
        <Stack sx={{alignContent: "center"}}>
        <Button variant="outlined" href="/alljobs" sx={{alignContent: "center", color: "white"}}>Ir al Buscador Premium</Button>
        </Stack>
        </CardContent>
        </CardActionArea>
        </Card>
                </Box>
                </Grid>
                </Grid>
                 
                 }

{(numberLimitedJobs === 1) && 
                 
                 <Grid container component="main" 
            sx={{ 
              marginTop: "40px",
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat', }}>
              <Grid item xs={12} sm={12} md={12} square>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: "center",
                    justifyContent: "center",
                    bgcolor: 'white',
                    backgroundSize: 'cover',
                    backgroundPosition: 'right',
                    marginTop: "20px",
                    marginBottom: "80px"
                 }}
                 
                >
        <Card elevation={10} sx={{ marginRight: "20px", width: "600px", bgcolor: "#367bb3", display: { xl: 'block', l: "block", m: "none", s:"none", xs: 'none'}}}>
        <CardActionArea component={RouterLink} to={"/alljobs"} >
        <CardContent>
        <Typography sx={{textAlign: "center", fontSize: "16px", fontWeight: "500", paddingBottom: "10px" }} color="white" gutterBottom>
        En el Buscador Básico hay solo {numberLimitedJobs} empleo que cumple tus criterios de búsqueda. En el Buscador Premium tienes acceso a {numberMatchesPremium - 1} empleos adicionales que cumplen esos criterios.
        </Typography>
        <Stack sx={{alignContent: "center"}}>
        <Button variant="outlined" href="/alljobs" sx={{alignContent: "center", color: "white"}}>Ir al Buscador Premium</Button>
        </Stack>
        </CardContent>
        </CardActionArea>
        </Card>
        <Card elevation={10} sx={{ marginRight: "20px", width: "300px", bgcolor: "#367bb3", display: {xl: 'none', l: "none", m: "block", s:"block", xs: 'block'}}}>
        <CardActionArea component={RouterLink} to={"/alljobs"} >
        <CardContent>
        <Typography sx={{textAlign: "center", fontSize: "16px", fontWeight: "500", paddingBottom: "10px" }} color="white" gutterBottom>
        En el Buscador Básico hay solo {numberLimitedJobs} empleo que cumple tus criterios de búsqueda. En el Buscador Premium tienes acceso a {numberMatchesPremium - 1} empleos adicionales que cumplen esos criterios.
        </Typography>
        <Stack sx={{alignContent: "center"}}>
        <Button variant="outlined" href="/alljobs" sx={{alignContent: "center", color: "white"}}>Ir al Buscador Premium</Button>
        </Stack>
        </CardContent>
        </CardActionArea>
        </Card>
                </Box>
                </Grid>
                </Grid>
                 
                 }


{(numberLimitedJobs === 0 && numberMatchesPremium !== 0) && 
                 
                 <Grid container component="main" 
            sx={{ 
              marginTop: "40px",
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat', }}>
              <Grid item xs={12} sm={12} md={12} square>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: "center",
                    justifyContent: "center",
                    bgcolor: 'white',
                    backgroundSize: 'cover',
                    backgroundPosition: 'right',
                    marginTop: "20px",
                    marginBottom: "80px"
                 }}
                 
                >
        <Card elevation={10} sx={{ marginRight: "20px", width: "600px", bgcolor: "#367bb3", display: { xl: 'block', l: "block", m: "none", s:"none", xs: 'none'}}}>
        <CardActionArea component={RouterLink} to={"/alljobs"} >
        <CardContent>
        <Typography sx={{textAlign: "center", fontSize: "16px", fontWeight: "500", paddingBottom: "10px" }} color="white" gutterBottom>
        En el Buscador Básico no hay empleos que cumplan tus criterios de búsqueda. En el Buscador Premium tienes acceso a {numberMatchesPremium } empleos que cumplen tus criterios de búsqueda.
        </Typography>
        <Stack sx={{alignContent: "center"}}>
        <Button variant="outlined" href="/alljobs" sx={{alignContent: "center", color: "white"}}>Ir al Buscador Premium</Button>
        </Stack>
        </CardContent>
        </CardActionArea>
        </Card>
        <Card elevation={10} sx={{ marginRight: "20px", width: "300px", bgcolor: "#367bb3", display: {xl: 'none', l: "none", m: "block", s:"block", xs: 'block'}}}>
        <CardActionArea component={RouterLink} to={"/alljobs"} >
        <CardContent>
        <Typography sx={{textAlign: "center", fontSize: "16px", fontWeight: "500", paddingBottom: "10px" }} color="white" gutterBottom>
        En el Buscador Básico hay solo {numberLimitedJobs} empleos que cumplen tus criterios de búsqueda. En el Buscador Premium tienes acceso a {numberMatchesPremium} empleos que cumplen tus criterios de búsqueda.
        </Typography>
        <Stack sx={{alignContent: "center"}}>
        <Button variant="outlined" href="/alljobs" sx={{alignContent: "center", color: "white"}}>Ir al Buscador Premium</Button>
        </Stack>
        </CardContent>
        </CardActionArea>
        </Card>
                </Box>
                </Grid>
                </Grid>
                 
                 }

{(numberLimitedJobs === 0 && numberMatchesPremium === 0) && 
                 
                 <Grid container component="main" 
            sx={{ 
              marginTop: "40px",
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat', }}>
              <Grid item xs={12} sm={12} md={12} square>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: "center",
                    justifyContent: "center",
                    bgcolor: 'white',
                    backgroundSize: 'cover',
                    backgroundPosition: 'right',
                    marginTop: "20px",
                    marginBottom: "80px"
                 }}
                 
                >
        <Card elevation={10} sx={{ marginRight: "20px", width: "600px", bgcolor: "#367bb3", display: { xl: 'block', l: "block", m: "none", s:"none", xs: 'none'}}}>
        <CardActionArea component={RouterLink} to={"/alljobs"} >
        <CardContent>
        <Typography sx={{textAlign: "center", fontSize: "16px", fontWeight: "500", paddingBottom: "10px" }} color="white" gutterBottom>
        No hay empleos que cumplan tus criterios de búsqueda. Prueba con una búsqueda más amplia.
        </Typography>
        <Stack sx={{alignContent: "center"}}>
        
        </Stack>
        </CardContent>
        </CardActionArea>
        </Card>
        <Card elevation={10} sx={{ marginRight: "20px", width: "300px", bgcolor: "#367bb3", display: {xl: 'none', l: "none", m: "block", s:"block", xs: 'block'}}}>
        <CardActionArea component={RouterLink} to={"/alljobs"} >
        <CardContent>
        <Typography sx={{textAlign: "center", fontSize: "16px", fontWeight: "500", paddingBottom: "10px" }} color="white" gutterBottom>
        No hay empleos que cumplan tus criterios de búsqueda. Prueba con una búsqueda más amplia.
        </Typography>
        <Stack sx={{alignContent: "center"}}>
        
        </Stack>
        </CardContent>
        </CardActionArea>
        </Card>
                </Box>
                </Grid>
                </Grid>
                 
                 }

                <Pagination count={noOfPages} page={page} defaultPage={1} onChange={handleChangePagination} variant="outlined" shape="rounded" sx={{paddingTop:"20px", paddingBottom: "20px"}} />
                
                


                {(numberLimitedJobs > 5) && 
                 
                 <Grid container component="main" 
            sx={{ 
              marginTop: "40px",
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat', }}>
              <Grid item xs={12} sm={12} md={12} square>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: "center",
                    justifyContent: "center",
                    bgcolor: 'white',
                    backgroundSize: 'cover',
                    backgroundPosition: 'right',
                    marginTop: "20px",
                    marginBottom: "80px"
                 }}
                 
                >
        <Card elevation={10} sx={{ marginRight: "20px", width: "600px", bgcolor: "#367bb3", display: { xl: 'block', l: "block", m: "none", s:"none", xs: 'none'}}}>
        <CardActionArea component={RouterLink} to={"/alljobs"} >
        <CardContent>
        <Typography sx={{textAlign: "center", fontSize: "16px", fontWeight: "500", paddingBottom: "10px" }} color="white" gutterBottom>
        En el Buscador Básico hay {numberLimitedJobs} empleos que cumplen tus criterios de búsqueda. En el Buscador Premium tienes acceso a {numberMatchesPremium - 1} empleos adicionales que cumplen esos criterios.
        </Typography>
        <Stack sx={{alignContent: "center"}}>
        <Button variant="outlined" href="/alljobs" sx={{alignContent: "center", color: "white"}}>Ir al Buscador Premium</Button>
        </Stack>
        </CardContent>
        </CardActionArea>
        </Card>
        <Card elevation={10} sx={{ marginRight: "20px", width: "300px", bgcolor: "#367bb3", display: {xl: 'none', l: "none", m: "block", s:"block", xs: 'block'}}}>
        <CardActionArea component={RouterLink} to={"/alljobs"} >
        <CardContent>
        <Typography sx={{textAlign: "center", fontSize: "16px", fontWeight: "500", paddingBottom: "10px" }} color="white" gutterBottom>
        En el Buscador Básico hay {numberLimitedJobs} empleos que cumplen tus criterios de búsqueda. En el Buscador Premium tienes acceso a {numberMatchesPremium - 1} empleos adicionales que cumplen esos criterios.
        </Typography>
        <Stack sx={{alignContent: "center"}}>
        <Button variant="outlined" href="/alljobs" sx={{alignContent: "center", color: "white"}}>Ir al Buscador Premium</Button>
        </Stack>
        </CardContent>
        </CardActionArea>
        </Card>
                </Box>
                </Grid>
                </Grid>
                 
                 }
               
    <div>
 
    </div>
                </Box>
              </Grid>
          </Grid>
      <Outlet/>
        </div>
      </AuthProvider>

  )
}

export default LimitedJobs