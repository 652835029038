import React, { useEffect, useState } from 'react';
import AuthProvider from '../components/authProvider';
import { useNavigate } from 'react-router';
import getPaymentsByUID from '../components/getPaymentsByUID';
import { getPerfil, insertNewPerfil, updatePerfil } from '../firebase-config';
import Header from '../components/Header';
import CssBaseline from '@mui/material/CssBaseline';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { Button, CardActionArea, Stack } from '@mui/material';
import { Typography } from '@mui/material';
import {v4 as uuidv4} from "uuid";
import { cancelarPremium } from '../firebase-config';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { sendCancellationEmail } from '../firebase-config';


function MiCuentaPremium() {
    const [currentUser, setCurrentUser] = useState({});
  const [perfiles, setPerfiles] = useState([ ]);
    const [isPremium, setIsPremium] = useState(false);
      const [payments, setPayments] = useState([]);
  const [payment1, setPayment1] = useState([]);
  const [payment2, setPayment2] = useState([]);
  const [payment3, setPayment3] = useState([]);
  const [cancelar, setCancelar] = useState([]);
  const [isOldPremium, setIsOldPremium] = useState(false);
  const [cancelacionSolicitada, setCancelacionSolicitada] = useState(false);
      const navigate = useNavigate();

      async function handleUserLoggedIn(user) {
    setCurrentUser(user);
    console.log("Estás registrado");
    console.log(user.displayName);
    const resPerfil = await getPerfil(user.uid);
      setPerfiles([...resPerfil]);
      const payments = await getPaymentsByUID(user.uid);
      setPayments([...payments])
      console.log(perfiles);
      console.log()
      setIsOldPremium(user.premiumUser)

      console.log(perfiles.nombre);
      payments.map((payment) => {
        payment1.push(payment.status)
        payment2.push(payment.role)
        payment3.push(payment.created)
    
      })
    
    console.log(payment1)
    const premium = (element) => (element === "active" || element === "trialing")
    setIsPremium(payment1.some(premium))
    console.log(isPremium)
   }

   useEffect(() => {
    async function getPayments() {
      if (!currentUser) return;
      console.log("user por usar", currentUser);
      const payments = await getPaymentsByUID(currentUser);
      setPayments(payments);
    }
    getPayments();
    console.log(payments)
    
  }, []);

   function handleUserNotRegistered(user) {
    navigate("../signin");

   }
   
   function handleUserNotLoggedIn() {
    navigate("../signin");
   }
   console.log(payment2)
   console.log(payment3)


   function handleClick (e) {
    cancel();
    console.log(cancelar);
   }


   function cancel(){
    const newCancelacion = {
      id: uuidv4(),
      uid: currentUser.uid,
      cancelar: cancelar,


    }
    const res = cancelarPremium(newCancelacion);
    newCancelacion.docId = res.id;
    setCancelar([...cancelar, newCancelacion]);
   }



   function handleSubmit (e) {
    e.preventDefault();
    let email = e.target.email.value;
    sendCancellationEmail(email);
    e.target.email.value = "";
    console.log("ok");
    setCancelacionSolicitada(true);
   
   }

  return (
    <AuthProvider 
    onUserLoggedIn={handleUserLoggedIn}
    onUserNotRegistered={handleUserNotRegistered}
    onUserNotLoggedIn={handleUserNotLoggedIn}
    > 
   
      <Header />
      <Stack sx={{alignItems: "center", justifyContent: "center", paddingTop: "60px"}}>
      {isPremium && 
      <Card elevation={10} sx={{ width: "300px"}}>
      <CardContent>
        <Typography sx={{ fontSize: 16, textAlign: "center" }} color="text.secondary" gutterBottom>
        Tienes una suscripción {payment2[0]}
        </Typography>
        <Typography sx={{ fontSize: 14, textAlign: "center", paddingTop: "10px" }} color="text.secondary" gutterBottom>
        Tu suscripción se renovará automáticamente, por el mismo periodo, cuando llegue la fecha de vencimiento. Si quieres cancelar la 
        renovación, introduce tu correo electronico en el formulario y haz clic en "Cancelar renovación".
        </Typography>
        
       { /*   <Stack sx={{alignItems: "center", justifyContent: "center", paddingTop: "20px"}}>
          
        <Button onClick={handleClick}>Cancelar renovación</Button>
      </Stack> */ }
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{mt: 1, color: "white", bgcolor: "rgba(228, 237, 246, 0.17)", marginTop: "60px", marginBottom: "60px", borderRadius: "25px", padding: "20px", maxWidth: "850px" }}>
    <TextField id="email" label="E-mail" variant="outlined" name="email" />
    {!cancelacionSolicitada && <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, backgroundColor: "rgba(43, 117, 177, 0.9)", color: "white", marginTop: "70px"}}

              >
                Cancelar renovación
              </Button>}
              {cancelacionSolicitada && <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, backgroundColor: "rgba(43, 117, 177, 0.9)", color: "white", marginTop: "70px"}}

              >
                ¡Hecho!
              </Button>}
    </Box>
      </CardContent>

    </Card>}
    {isOldPremium && 
    <div>
      <Card elevation={10} sx={{ width: "300px"}}>
      <CardContent>
        <Typography sx={{ fontSize: 16, textAlign: "center" }} color="text.secondary" gutterBottom>
        Tienes una suscripción premium.
        </Typography>
        <Typography sx={{ fontSize: 14, textAlign: "center", paddingTop: "10px" }} color="text.secondary" gutterBottom>
        Tu suscripción se renovará automáticamente, por el mismo periodo, cuando llegue la fecha de vencimiento. Si quieres cancelar la 
        renovación, introduce tu correo electronico en el formulario y haz clic en "Cancelar renovación".
        </Typography>
        {/*<Stack sx={{alignItems: "center", justifyContent: "center", paddingTop: "20px"}}>
          
        <Button onClick={handleClick}>Cancelar renovación</Button>
    </Stack>*/}
      <Box component="form" noValidate onSubmit={handleSubmit} sx={{mt: 1, color: "white", bgcolor: "rgba(228, 237, 246, 0.17)", marginTop: "60px", marginBottom: "60px", borderRadius: "25px", padding: "20px", maxWidth: "850px" }}>
    <TextField id="email" label="E-mail" variant="outlined" name="email" />
    {!cancelacionSolicitada && <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, backgroundColor: "rgba(43, 117, 177, 0.9)", color: "white", marginTop: "70px"}}

              >
                Cancelar renovación
              </Button>}
              {cancelacionSolicitada && <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, backgroundColor: "rgba(43, 117, 177, 0.9)", color: "white", marginTop: "70px"}}

              >
                ¡Hecho!
              </Button>}
    </Box>
      </CardContent>

    </Card>
    
    </div>
    }

    {(!isPremium && !isOldPremium) && 
      <Card elevation={10} sx={{ width: "300px"}}>
      <CardContent>
        <Typography sx={{ fontSize: 16, textAlign: "center" }} color="text.secondary" gutterBottom>
        Tienes una suscripción básica
        </Typography>
        <Typography sx={{ fontSize: 14, textAlign: "center", paddingTop: "10px" }} color="text.secondary" gutterBottom>
        Tu suscripción te permite acceder a empleos limitados, y a información sobre despachos y empresas limitada. Para beneficiarte 
        de todas las ventajas que ofrece Jobs&Law, hazte Usuario Premium.
        </Typography>
        <Stack sx={{alignItems: "center", justifyContent: "center", paddingTop: "20px"}}>
        <Button href="./checkout">Hazte Premium</Button>
        </Stack>
      </CardContent>

    </Card>}
    </Stack>
   
  
        </AuthProvider>
  )
}

export default MiCuentaPremium
