import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { useUserAuth } from '../context/UserAuthContext'
import "../App.css"

export default function ForgotPassword() {

  const emailRef = useRef()
  const { resetPassword } = useUserAuth()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState('')

  async function handleSubmit(e) {
    e.preventDefault();
    
    try {
      setMessage('')
      setError('')
      setLoading(true)
      await resetPassword(emailRef.current.value)
      setMessage('Checa tu bandeja de entrada y sigue las instrucciones')
      console.log(message)
      alert("Correo enviado, revisa tu inbox")
    } catch {
      setError('Fallo al restaurar tu password')
    }

    setLoading(false)
  }

  return (
    
    <div>
      <section >
        <div id="feedback-form">
          <h1>Recupera tu contraseña</h1>
          { error && <h1>{error}</h1> }
          <form onSubmit={handleSubmit}>
          <h5>Introduce tu correo electrónico</h5>           
            <input
              type='email'
              autoFocus
              required
              ref={emailRef}
            />
            <div >
              <button type='submit' className = "submitBtn" disabled={loading}>Recuperar contraseña</button>
            </div>
          </form>
        </div>
      </section>


    
    </div>
  )
}
