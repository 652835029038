import { db } from "../firebase-config";
import { collection, getDocs, getDoc, addDoc, updateDoc, deleteDoc, doc } from "firebase/firestore";


const trabajoCollectionRef = collection(db, "trabajos");
class TrabajoDataService {
    addTrabajos = (newTrabajo) => {
        return addDoc(trabajoCollectionRef, newTrabajo);
    }

    updateTrabajo = (id, updatedTrabajo) => {
        const trabajoDoc = doc(db,"trabajos", id);
        return updateDoc(trabajoDoc, updatedTrabajo)
    };

    deleteTrabajo = (id) => {
        const trabajoDoc = doc(db,"trabajos", id);
        return deleteDoc(trabajoDoc);
    };

    getAllTrabajos = () => {
        return getDocs(trabajoCollectionRef);
    }

    getTrabajo = (id) => {
        const trabajoDoc = doc(db, "trabajos, id");
        return getDoc(trabajoDoc);
    };
}

export default new TrabajoDataService();