import React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import FavoriteIcon from '@mui/icons-material/Favorite';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { borderColor, borderRight } from '@mui/system';
import JobList from "./JobList";
import { Link, Outlet } from "react-router-dom";
import { useParams } from "react-router-dom";
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import Stack from '@mui/material/Stack';
import { useState, useEffect } from 'react';
import TrabajoDataService from '../services/trabajo.services';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, onSnapshot, addDoc, deleteDoc, doc, query, where, orderBy, getDoc, getDocs  } from 'firebase/firestore';
import parse from "html-react-parser";
import CssBaseline from '@mui/material/CssBaseline';
import { getNotas } from '../firebase-config';
import Rating from '@mui/material/Rating';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import Brightness1OutlinedIcon from '@mui/icons-material/Brightness1Outlined';
import Pie from '../components/Pie';
import Pagination from '@mui/material/Pagination';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Headerbis from '../components/Headerbis';
import AuthProvider from '../components/authProvider';
import { useNavigate } from 'react-router';
import { getPerfil, insertNewPerfil, updatePerfil } from '../firebase-config';
import InboxIcon from '@mui/icons-material/Inbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';
import TaskAltIcon from '@mui/icons-material/TaskAlt';



export default function SingleFirm ( {id, setTrabajoId} ) {
  
    let params = useParams();
    const db= getFirestore()
  
  
    const Item = styled(Paper)(({ theme }) => ({
      backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
      ...theme.typography.body2,
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    }));
  
  
  
    const [firms, setFirms] = useState([]);
    useEffect(() => {
    getNotas();
    console.log(params.firmId);
  }, [])
  

  

  const [despacho, setDespacho] = useState("");
  const [tipoDespacho, setTipoDespacho] = useState("");
  const [tamanoDespacho, setTamanoDespacho] = useState("");
  const [tipoDerecho, setTipoDerecho] = useState("");
  const [comentarios, setComentarios] = useState([]);
  const [tipoDeTrabajo, setTipoDeTrabajo] = useState();
  const [ambiente, setAmbiente] = useState();
  const [positivo, setPositivo] = useState([]);
  const [negativo, setNegativo] = useState([]);
  const [general, setGeneral] = useState();
  const [conciliacion, setConciliacion] = useState(); 
  const [trabajoAtractivo, setTrabajoAtractivo] = useState();
  const [trabajoVariado, setTrabajoVariado] = useState();
  const [carrera, setCarrera] = useState();
  const [formacionTotal, setFormacionTotal] = useState();
  const [diversidadTotal, setDiversidadTotal] = useState();
  const [valores, setValores] = useState();
  const [proBono, setProBono] = useState();
  const [igualdadTotal, setIgualdadTotal] = useState();
  const [techos, setTechos] = useState();
  const [cincoAnos, setCincoAnos] = useState();
  const [teletrabajo, setTeletrabajo] = useState();
  const [currentUser, setCurrentUser] = useState({});
  const [perfiles, setPerfiles] = useState([ ]);
  const [registrado, setRegistrado] = useState("si")
  const navigate = useNavigate();

  async function handleUserLoggedIn(user) {
    setCurrentUser(user);
    console.log("Estás registrado");
    console.log(user.displayName);
    console.log(perfiles.length);
    const resPerfil = await getPerfil(user.uid);
    setPerfiles([...resPerfil]);
    console.log(perfiles);
   }

   console.log(perfiles.length)

   function handleUserNotRegistered(user) {
    setRegistrado("no");

   }
   
   function handleUserNotLoggedIn() {
    setRegistrado("no");
   }

  const [page, setPage] = useState(1);
  const itemsPerPage = 3;
  const noOfPages = Math.ceil(comentarios && comentarios.length / itemsPerPage);
  const handleChange = (event, value) => {
    setPage(value);
  };

  const [page2, setPage2] = useState(1);
  const itemsPerPage2 = 3;

  const noOfPages2 = Math.ceil(positivo && positivo.length / itemsPerPage2);



  const handleChange2 = (event, value) => {
    setPage2(value);
  };

  const [page3, setPage3] = useState(1);
  const itemsPerPage3 = 3;

  const noOfPages3 = Math.ceil(negativo && negativo.length / itemsPerPage3);
 
  const handleChange3 = (event, value) => {
    setPage3(value);
  };


  const fetchPost = async () => {
       
    await getDocs(collection(db, "notas"))
        .then((querySnapshot)=>{              
            const newData = querySnapshot.docs
                .map((doc) => ({...doc.data(), id:doc.id }));
            setFirms(newData);                
            console.log(firms, newData);
        })
   
}

useEffect(()=>{
    fetchPost();
}, [])
  
  const selectedTrabajo = firms.filter((doc => doc.id===params.firmId));
  
    const docRef = doc(db, "notas", params.firmId)
  
  
      
    useEffect(() => {
        getDoc(docRef)
        .then((doc) => {
        
        const objeto = doc.data();
        setDespacho(objeto.inputValue);
        setTipoDespacho(objeto.tipodespacho);
        setTamanoDespacho(objeto.tamanodespacho);
        setTipoDerecho(objeto.tipoderecho);
        setTipoDeTrabajo(objeto.tipodetrabajo);
        setComentarios(objeto.comentarios);
        setPositivo(objeto.positivo);
        setNegativo(objeto.negativo);
        setTipoDeTrabajo(objeto.tipodetrabajo);
        setAmbiente(objeto.ambiente);
        setGeneral(objeto.general);
        setConciliacion(objeto.conciliacion);
        setTrabajoAtractivo(objeto.trabajoatractivo);
        setTrabajoVariado(objeto.trabajovariado);
        setCarrera(objeto.carrera);
        setFormacionTotal(objeto.formacion);
        setDiversidadTotal(objeto.diversidadtotal);
        setValores(objeto.valores);
        setProBono(objeto.probono);
        setIgualdadTotal(objeto.igualdadtotal);
        setTechos(objeto.techoscristal);
        setCincoAnos(objeto.cincoanos);
        setTeletrabajo(objeto.teletrabajo);

   




        })
      }, [firms])
  
  console.log(selectedTrabajo);

  const StyledRating = styled(Rating)({
    '& .MuiRating-iconFilled': {
      color: '#037bfc',
    },
    '& .MuiRating-iconHover': {
      color: '#037bfc',
    },
  });

 
  console.log(currentUser);


  const Listas = () => (
    <div>
{comentarios &&
<Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                      sx={{paddingTop: "20px", paddingBottom: "30px"}}>
<List sx={{ width: '100%', maxWidth: 800, bgcolor: 'background.paper' }}>
        {comentarios
          .slice((page - 1) * itemsPerPage, page * itemsPerPage)
          .map(comentario => {
            return (
            <div>
        <ListItem alignItems="flex-start">
        <ListItemAvatar>
        <Avatar>
            <AccountCircleIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={comentario}
          
        />
        </ListItem>
        <Divider variant="inset" component="li" />
        </div>
            
            )})}
      </List>
      <Pagination count={noOfPages} page={page} defaultPage={1} onChange={handleChange} variant="outlined" shape="rounded" />
      </Stack>
 }
    </div>


  
  )

  const Positivo = () => (
    <div>
     
      {positivo &&
      <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                      sx={{paddingTop: "20px", paddingBottom: "30px"}}>
      <List sx={{ width: '100%', maxWidth: 800, bgcolor: 'background.paper' }}>
      {positivo
          .slice((page2 - 1) * itemsPerPage2, page2 * itemsPerPage2)
          .map(posi => {
            return (
        <div>
        <ListItem alignItems="flex-start">
        
        <ListItemText
          primary={posi}
         
        />
        </ListItem>
        <Divider component="li" />
        </div>
          )})}
      </List>
      <Pagination count={noOfPages2} page={page2} defaultPage={1} onChange={handleChange2} variant="outlined" shape="rounded" />
      </Stack>
      }
    </div>


  
  )

  const Negativo = () => (
    <div>
  
      { negativo &&
            <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                      sx={{paddingTop: "20px", paddingBottom: "30px"}}>
      <List sx={{ width: '100%', maxWidth: 800, bgcolor: 'background.paper' }}>
      {negativo
          .slice((page3 - 1) * itemsPerPage3, page3 * itemsPerPage3)
          .map(nega => {
            return (
        <div>
        <ListItem alignItems="flex-start">
  
        <ListItemText
          primary={nega}
          
        />
        </ListItem>
        <Divider component="li" />
        </div>
               )})}
      </List>
      </Stack>
      }
    </div>


  
  )
  
  

  
    return (
  
      <div>
        <AuthProvider 
    onUserLoggedIn={handleUserLoggedIn}
    onUserNotRegistered={handleUserNotRegistered}
    onUserNotLoggedIn={handleUserNotLoggedIn}
    > 
      <Headerbis />
      <Box sx={{ flexGrow: 1, display: { xl: 'inline', l: "inline", m: "none", s:"none", xs: 'none' }}}>
    <Grid container spacing={2} paddingTop="30px" sx={{justifyContent: "center"}}>
      <Grid item xs={8} sm={8} md={8} lg={8} xl={5} sx={{justifyContent: "center"}}>
     
        <Card sx={{display: "flex", width: "1000px", border: "1px 1px 1px 0px", borderColor: "blue", borderRadius:"10px 10px 0px 0px"}}>
          <Box sx={{width: "550px", border: "10px", borderColor: "blue"}}>
            <Box sx={{width: "550px", border: "10px", borderColor: "blue",  display: "flex"}}>
              <Box sx={{width: "500px", paddingRight: "50px"}}>
              <CardMedia
                  component="img"
                  sx={{padding:"20px", justifyContent:"center"}}
                  image="https://firebasestorage.googleapis.com/v0/b/jobsandlaw-5e0ba.appspot.com/o/images%2Flogoand.png?alt=media&token=5e0c4931-173b-4f10-a8ac-4d31305f46ce"
                  alt="logo"
                /></Box>
              
                <Box sx={{ display: 'flex', flexDirection: 'row', width: "90%", border: "2px"}}>
                    <CardContent sx={{paddingTop:"20px"}}>
                     
                              <Typography sx={{paddingTop: "20px"}} variant="h3" color="text.secondary">
                              {despacho} 
                              </Typography>
                             
                      <Box sx={{paddingTop: "15px"}}>
                          <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px"}}>
                              {tipoDespacho}
                          </Typography>
                      </Box>
                      <Box sx={{paddingTop: "15px"}}>
                          <Typography variant="caption" sx={{backgroundColor: "#03b1fc", borderRadius: "10px", color:"white", padding: "6px"}}>
                          {tamanoDespacho}
                          </Typography>
                      </Box>

                      <Box sx={{paddingTop: "15px"}}>
                      <Typography variant="caption" sx={{backgroundColor: "#037bfc", borderRadius: "10px", color:"white", padding: "6px"}}>
                          {tipoDerecho}
                          </Typography>
                      </Box>



        
        <Box sx={{display: "block", alignItems: "flex-start", paddingTop: "20px"}} >
        </Box>
        </CardContent>
        </Box>
   
        </Box>
        <Divider variant="middle" />
        
        <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                      sx={{paddingTop: "25px", paddingBottom: "0px", width: "100%"}}>
                             <Typography variant="h6" color="text.secondary">
                                  {`¿Por qué ${despacho}?`}
                              </Typography>

                      </Stack>
                      <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                      sx={{paddingTop: "5px", paddingBottom: "0px", width: "100%"}}>
        <Typography variant="paragraph" sx={{paddingBottom: "0px"}} >Así lo valoran sus abogad@s
        </Typography>
        </Stack>
        
        <Stack direction="column"
                      justifyContent="flex-end"
                      alignItems="center"
                      sx={{width: "100%"}}>
        <Typography sx={{paddingTop: "30px", paddingRight: "20px", fontSize: "20px", fontWeight: "500"}} >Valoración general:
        </Typography>
        </Stack>
      <Grid container paddingTop="0px" paddingBottom="30px">
      <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
        <Stack direction="column"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      sx={{width: "100%"}}>

        <StyledRating
        sx={{paddingTop: "3px", paddingRight: "0px"}}
        name="customized-color"
        defaultValue={(general*5)/100}
        readOnly
        getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
        precision={0.1}
        size="medium"
        icon={<Brightness1Icon fontSize="inherit" />}
        emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
      />
        </Stack>
        
        </Grid>
       
        
        
      
        <Grid item xs={5} sm={5} md={5} lg={5} xl={5} >
        <Stack direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      sx={{width: "100%"}}>
                <Typography sx={{paddingTop: "1px", paddingLeft: "15px", fontSize: "20px", fontWeight: "700", color: "#037bfc"}} >{general}
        </Typography> 
        </Stack>

        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        </Grid>

        {currentUser.premiumUser && <div>

        <Grid container paddingTop="10px">
      <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
        <Stack direction="column"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      sx={{width: "100%"}}>
        <Typography sx={{paddingBottom: "0px", paddingRight: "20px", fontSize: "16px", fontWeight: "400"}} >Ambiente laboral:
        </Typography>
        </Stack>
        
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
        <StyledRating
        sx={{paddingTop: "3px", paddingLeft: "5px"}}
        name="customized-color"
        defaultValue={ambiente*5/100}
        readOnly
        getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
        precision={0.1}
        size="small"
        icon={<Brightness1Icon fontSize="inherit" />}
        emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
      />
        
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3} >
        <Stack direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      sx={{width: "100%"}}>
                <Typography sx={{paddingTop: "1px", fontSize: "16px", fontWeight: "700", color: "#037bfc"}} >{ambiente}
        </Typography> 
        </Stack>

        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        </Grid>
        <Grid container paddingTop="10px">
      <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
        <Stack direction="column"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      sx={{width: "100%"}}>
        <Typography sx={{paddingBottom: "0px", paddingRight: "20px", fontSize: "16px", fontWeight: "400"}} >Conciliación:
        </Typography>
        </Stack>
        
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
        <StyledRating
        sx={{paddingTop: "3px", paddingLeft: "5px"}}
        name="customized-color"
        defaultValue={(conciliacion*5)/100}
        readOnly
        getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
        precision={0.1}
        size="small"
        icon={<Brightness1Icon fontSize="inherit" />}
        emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
      />
        
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3} >
        <Stack direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      sx={{width: "100%"}}>
                <Typography sx={{paddingTop: "1px", fontSize: "16px", fontWeight: "700", color: "#037bfc"}} >{conciliacion}
        </Typography> 
        </Stack>

        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        </Grid>
        <Grid container paddingTop="10px">
      <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
        <Stack direction="column"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      sx={{width: "100%"}}>
        <Typography sx={{paddingBottom: "0px", paddingRight: "20px", fontSize: "16px", fontWeight: "400"}} >Tipo de trabajo:
        </Typography>
        </Stack>
        
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
        <StyledRating
        sx={{paddingTop: "3px", paddingLeft: "5px"}}
        name="customized-color"
        defaultValue={(tipoDeTrabajo*5)/100}
        readOnly
        getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
        precision={0.1}
        size="small"
        icon={<Brightness1Icon fontSize="inherit" />}
        emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
      />
        
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3} >
        <Stack direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      sx={{width: "100%"}}>
                <Typography sx={{paddingTop: "1px", fontSize: "16px", fontWeight: "700", color: "#037bfc"}} > {tipoDeTrabajo}
        </Typography> 
        </Stack>

        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        </Grid>
        <Grid container paddingTop="10px">
      <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
        <Stack direction="column"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      sx={{width: "100%"}}>
        <Typography sx={{paddingBottom: "0px", paddingRight: "20px", fontSize: "16px", fontWeight: "400"}} >Carrera:
        </Typography>
        </Stack>
        
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
        <StyledRating
        sx={{paddingTop: "3px", paddingLeft: "5px"}}
        name="customized-color"
        defaultValue={(carrera*5)/100}
        readOnly
        getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
        precision={0.1}
        size="small"
        icon={<Brightness1Icon fontSize="inherit" />}
        emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
      />
        
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3} >
        <Stack direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      sx={{width: "100%"}}>
                <Typography sx={{paddingTop: "1px", fontSize: "16px", fontWeight: "700", color: "#037bfc"}} >{carrera}
        </Typography> 
        </Stack>

        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        </Grid>
        <Grid container paddingTop="10px">
      <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
        <Stack direction="column"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      sx={{width: "100%"}}>
        <Typography sx={{paddingBottom: "0px", paddingRight: "20px", fontSize: "16px", fontWeight: "400"}} >Formación:
        </Typography>
        </Stack>
        
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
        <StyledRating
        sx={{paddingTop: "3px", paddingLeft: "5px"}}
        name="customized-color"
        defaultValue={(formacionTotal*5)/100}
        readOnly
        getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
        precision={0.1}
        size="small"
        icon={<Brightness1Icon fontSize="inherit" />}
        emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
      />
        
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3} >
        <Stack direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      sx={{width: "100%"}}>
                <Typography sx={{paddingTop: "1px", fontSize: "16px", fontWeight: "700", color: "#037bfc"}} >{formacionTotal}
        </Typography> 
        </Stack>

        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        </Grid>
        <Grid container paddingTop="10px">
      <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
        <Stack direction="column"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      sx={{width: "100%"}}>
        <Typography sx={{paddingBottom: "0px", paddingRight: "20px", fontSize: "16px", fontWeight: "400"}} >Diversidad:
        </Typography>
        </Stack>
        
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
        <StyledRating
        sx={{paddingTop: "3px", paddingLeft: "5px"}}
        name="customized-color"
        defaultValue={(diversidadTotal*5)/100}
        readOnly
        getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
        precision={0.1}
        size="small"
        icon={<Brightness1Icon fontSize="inherit" />}
        emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
      />
        
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3} >
        <Stack direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      sx={{width: "100%"}}>
                <Typography sx={{paddingTop: "1px", fontSize: "16px", fontWeight: "700", color: "#037bfc"}} >{diversidadTotal}
        </Typography> 
        </Stack>

        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        </Grid>
        <Grid container paddingTop="10px">
      <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
        <Stack direction="column"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      sx={{width: "100%"}}>
        <Typography sx={{paddingBottom: "0px", paddingRight: "20px", fontSize: "16px", fontWeight: "400"}} >Cultura de firma:
        </Typography>
        </Stack>
        
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
        <StyledRating
        sx={{paddingTop: "3px", paddingLeft: "5px"}}
        name="customized-color"
        defaultValue={(valores*5)/100}
        readOnly
        getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
        precision={0.1}
        size="small"
        icon={<Brightness1Icon fontSize="inherit" />}
        emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
      />
        
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3} >
        <Stack direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      sx={{width: "100%"}}>
                <Typography sx={{paddingTop: "1px", fontSize: "16px", fontWeight: "700", color: "#037bfc"}} >{valores}
        </Typography> 
        </Stack>

        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        </Grid>
        <Grid container paddingTop="10px">
      <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
        <Stack direction="column"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      sx={{width: "100%"}}>
        <Typography sx={{paddingBottom: "0px", paddingRight: "20px", fontSize: "16px", fontWeight: "400"}} >Igualdad:
        </Typography>
        </Stack>
        
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
        <StyledRating
        sx={{paddingTop: "3px", paddingLeft: "5px"}}
        name="customized-color"
        defaultValue={(igualdadTotal*5)/100}
        readOnly
        getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
        precision={0.1}
        size="small"
        icon={<Brightness1Icon fontSize="inherit" />}
        emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
      />
        
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3} >
        <Stack direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      sx={{width: "100%"}}>
                <Typography sx={{paddingTop: "1px", fontSize: "16px", fontWeight: "700", color: "#037bfc"}} >{igualdadTotal}
        </Typography> 
        </Stack>

        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        </Grid>
        <Grid container paddingTop="10px">
      <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
        <Stack direction="column"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      sx={{width: "100%"}}>
        <Typography sx={{paddingBottom: "0px", paddingRight: "20px", fontSize: "16px", fontWeight: "400"}} >Pro Bono:
        </Typography>
        </Stack>
        
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
        <StyledRating
        sx={{paddingTop: "3px", paddingLeft: "5px"}}
        name="customized-color"
        defaultValue={(proBono*5)/100}
        readOnly
        getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
        precision={0.1}
        size="small"
        icon={<Brightness1Icon fontSize="inherit" />}
        emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
      />
        
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3} >
        <Stack direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      sx={{width: "100%"}}>
                <Typography sx={{paddingTop: "1px", fontSize: "16px", fontWeight: "700", color: "#037bfc"}} >{proBono}
        </Typography> 
        </Stack>

        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        </Grid>
        </div> }
      
      

        {!currentUser.premiumUser && <div>

    
<Grid container paddingTop="10px">
<Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
</Grid>
<Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
<Stack direction="column"
              justifyContent="flex-end"
              alignItems="flex-end"
              sx={{width: "100%"}}>
<Typography sx={{paddingBottom: "0px", paddingRight: "20px", fontSize: "16px", fontWeight: "400"}} >Tipo de trabajo:
</Typography>
</Stack>

</Grid>
<Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
<StyledRating
sx={{paddingTop: "3px", paddingLeft: "5px"}}
name="customized-color"
defaultValue={(tipoDeTrabajo*5)/100}
readOnly
getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
precision={0.1}
size="small"
icon={<Brightness1Icon fontSize="inherit" />}
emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
/>

</Grid>
<Grid item xs={3} sm={3} md={3} lg={3} xl={3} >
<Stack direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              sx={{width: "100%"}}>
        <Typography sx={{paddingTop: "1px", fontSize: "16px", fontWeight: "700", color: "#037bfc"}} > {tipoDeTrabajo}
</Typography> 
</Stack>

</Grid>
<Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
</Grid>
</Grid>

<Grid container paddingTop="10px">
<Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
</Grid>
<Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
<Stack direction="column"
              justifyContent="flex-end"
              alignItems="flex-end"
              sx={{width: "100%"}}>
<Typography sx={{paddingBottom: "0px", paddingRight: "20px", fontSize: "16px", fontWeight: "400"}} >Formación:
</Typography>
</Stack>

</Grid>
<Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
<StyledRating
sx={{paddingTop: "3px", paddingLeft: "5px"}}
name="customized-color"
defaultValue={(formacionTotal*5)/100}
readOnly
getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
precision={0.1}
size="small"
icon={<Brightness1Icon fontSize="inherit" />}
emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
/>

</Grid>
<Grid item xs={3} sm={3} md={3} lg={3} xl={3} >
<Stack direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              sx={{width: "100%"}}>
        <Typography sx={{paddingTop: "1px", fontSize: "16px", fontWeight: "700", color: "#037bfc"}} >{formacionTotal}
</Typography> 
</Stack>

</Grid>
<Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
</Grid>
</Grid>
<Stack direction="column"
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              sx={{bgcolor: "#f0f4f5", opacity: "0.7", borderRadius: "25px", marginTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "30px", marginLeft: "15px", marginRight: "15px"}}>
        <Typography sx={{paddingTop: "30px", paddingBottom: "10px", paddingLeft: "20px", paddingRight: "20px", fontSize: "16px", fontWeight: "700"}} >Este despacho no ha hecho públicos el resto de los resultados.
</Typography>
<Typography sx={{paddingBottom: "15px", paddingLeft: "20px", paddingRight: "20px", fontSize: "14px", fontWeight: "700"}} >¿Quieres conocerlos? Hazte Usuario Premium para tener acceso completo a Jobs&Law.
</Typography>
<nav aria-label="main mailbox folders">
<List sx={{paddingBottom: "20px"}} >
  <ListItem disablePadding>

      <ListItemIcon>
        <TaskAltIcon />
      </ListItemIcon>
      <ListItemText primary="Todas las vacantes en despachos"
          primaryTypographyProps={{
            fontWeight: '700',
            fontSize: "14px",
          }} />

  </ListItem>
  <ListItem disablePadding>

      <ListItemIcon>
        <TaskAltIcon />
      </ListItemIcon>
      <ListItemText primary="Todas las vacantes in-house"
          primaryTypographyProps={{
            fontWeight: '700',
            fontSize: "14px",
          }} />

  </ListItem>
  <ListItem disablePadding>

      <ListItemIcon>
        <TaskAltIcon />
      </ListItemIcon>
      <ListItemText primary="Todas las vacantes de headhunters"
          primaryTypographyProps={{
            fontWeight: '700',
            fontSize: "14px",
          }} />

  </ListItem>
  <ListItem disablePadding>

      <ListItemIcon>
        <TaskAltIcon />
      </ListItemIcon>
      <ListItemText primary="Información completa sobre los despachos"
          primaryTypographyProps={{
            fontWeight: '700',
            fontSize: "14px",
          }} />

  </ListItem>
  <ListItem disablePadding>

<ListItemIcon>
<TaskAltIcon />
</ListItemIcon>
<ListItemText primary="Información salarial"
primaryTypographyProps={{
fontWeight: '700',
fontSize: "14px",
}} />

</ListItem>
</List>
</nav>
{registrado !== "no" && <Button variant="contained" href="/checkout">
Activar Premium
</Button>}
{registrado === "no" && <Button variant="contained" href="/">
Registrarse
</Button>}
</Stack>
</div>}
        
       

        
   
    
                      <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                      sx={{paddingTop: "5px", paddingBottom: "30px", width: "100%"}}>
   
        </Stack>
        
        
        </Box>
        <Divider orientation="vertical" variant="middle" flexItem />
        
       
    
        <Box sx={{ display: 'flex', flexDirection: 'row', width: "4000px", border: "2px"}}>
                    <CardContent sx={{paddingTop:"20px", width:"450px", }}>
                    {currentUser.premiumUser && <div>
                    <Stack direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                    sx={{paddingTop: "0px", paddingBottom: "20px"}}>
                     <Stack direction="row"
                 
                   
                    spacing={2}
                    sx={{paddingTop: "0px", paddingBottom: "20px"}}>
                      <Box sx={{textAlign: "center"}} >

                     <Pie percentage={cincoAnos} colour="blue" /> 
                     <Typography sx={{paddingBottom: "0px", fontSize:"14px", paddingRight: "15px", paddingLeft:"15px" }} >Se ve trabajando en este despacho dentro de cinco años
        </Typography>
                     </Box> 
                     <Box sx={{textAlign: "center"}} >

<Pie percentage={teletrabajo} colour="blue" /> 
<Typography sx={{paddingBottom: "0px", fontSize:"14px", paddingRight: "15px", paddingLeft:"15px" }} >Valora positivamente la política de teletrabajo
</Typography>
</Box> 
                    </Stack>
                    
                   
                 
                    </Stack>
                     
                      <Divider variant="middle" />
                      <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                      sx={{paddingTop: "25px"}}>
                             <Typography variant="h6" color="text.secondary" sx={{paddingBottom: "0px"}}>
                                  Reconocimientos
                              </Typography>
                              </Stack>
                              <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                      sx={{paddingTop: "10px"}}>
                              <Typography variant="subtitle2"  color="text.secondary" sx={{textAlign: "center", paddingBottom: "34px"}}>¡Esta información estará <br></br> disponible muy pronto!</Typography>
                      </Stack>
                      <Divider variant="middle" />
                      <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                      sx={{paddingTop: "25px"}}>
                             <Typography variant="h6" color="text.secondary" sx={{paddingBottom: "0px"}}>
                                  Remuneración
                              </Typography>
                              </Stack>
                              <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                      sx={{paddingTop: "10px"}}>
                              <Typography variant="subtitle2"  color="text.secondary" sx={{textAlign: "center", paddingBottom: "34px"}}>¡Esta información estará <br></br> disponible muy pronto!</Typography>
                      </Stack>


                      
                           
                  
           </div> }              
           {!currentUser.premiumUser && <div>
            <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      textAlign="center"
                      sx={{width: "100%", bgcolor: "#f0f4f5", opacity: "0.7", borderRadius: "25px", marginTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "30px", height: "100vh"}}>
                <Typography sx={{paddingTop: "30px", paddingBottom: "10px", paddingLeft: "20px", paddingRight: "20px", fontSize: "16px", fontWeight: "700"}} >Este despacho no ha hecho públicos el resto de los resultados.
        </Typography>
        <Typography sx={{paddingBottom: "15px", paddingLeft: "20px", paddingRight: "20px", fontSize: "14px", fontWeight: "700"}} >¿Quieres conocerlos? Hazte Usuario Premium para tener acceso completo a Jobs&Law.
        </Typography>
      <nav aria-label="main mailbox folders">
        <List sx={{paddingBottom: "20px"}} >
          <ListItem disablePadding>
     
              <ListItemIcon>
                <TaskAltIcon />
              </ListItemIcon>
              <ListItemText primary="Todas las vacantes en despachos"
                  primaryTypographyProps={{
                    fontWeight: '700',
                    fontSize: "14px",
                  }} />
    
          </ListItem>
          <ListItem disablePadding>

              <ListItemIcon>
                <TaskAltIcon />
              </ListItemIcon>
              <ListItemText primary="Todas las vacantes in-house"
                  primaryTypographyProps={{
                    fontWeight: '700',
                    fontSize: "14px",
                  }} />
 
          </ListItem>
          <ListItem disablePadding>

              <ListItemIcon>
                <TaskAltIcon />
              </ListItemIcon>
              <ListItemText primary="Todas las vacantes de headhunters"
                  primaryTypographyProps={{
                    fontWeight: '700',
                    fontSize: "14px",
                  }} />

          </ListItem>
          <ListItem disablePadding>

              <ListItemIcon>
                <TaskAltIcon />
              </ListItemIcon>
              <ListItemText primary="Información completa sobre los despachos"
                  primaryTypographyProps={{
                    fontWeight: '700',
                    fontSize: "14px",
                  }} />

          </ListItem>
          <ListItem disablePadding>

<ListItemIcon>
  <TaskAltIcon />
</ListItemIcon>
<ListItemText primary="Información salarial"
    primaryTypographyProps={{
      fontWeight: '700',
      fontSize: "14px",
    }} />

</ListItem>
        </List>
      </nav>
      {registrado !== "no" && <Button variant="contained" href="/checkout">
Activar Premium
</Button>}
{registrado === "no" && <Button variant="contained" href="/">
Registrarse
</Button>}
        </Stack>

                      
                           
                  
           </div> }              
        </CardContent>
        
        </Box>

      
 
    </Card>
    {currentUser.premiumUser &&
    <div>
    <Card sx={{width: "1000px", border: "0px 1px 1px 1px", borderRadius:"0px 0px 0px 0px"}}>
    <Divider />
    <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                      sx={{paddingTop: "45px"}}>
                             <Typography variant="h6" color="text.secondary" sx={{paddingBottom: "0px"}}>
                                  Opiniones de los abogados de {despacho} 
                              </Typography>
                              </Stack>
                             

                      <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                      sx={{paddingTop: "20px", paddingBottom: "30px"}}>
                       
                       
                       

               
                       <Listas/>

    </Stack>


    </Card>
    <Card sx={{width: "1000px", display:"flex", border: "0px 1px 1px 1px", borderRadius:"0px 0px 0px 0px", marginBottom: "30px"}}>
    <Divider />

    <Box sx={{width: "550px", border: "10px", borderColor: "blue"}}>
    <Divider />
    <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                      sx={{paddingTop: "45px"}}>
                             <Typography variant="p" color="text.secondary" sx={{backgroundColor: "#35b50e", borderRadius: "10px", color:"white", padding: "6px"}}>
                                 Lo positivo
                              </Typography>
                              </Stack>
                             

                      <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                      sx={{paddingTop: "20px", paddingBottom: "30px", paddingLeft:"20px", paddingRight: "20px"}}>
                       
                       
                       

               
                       <Positivo/>
    </Stack>
    </Box>
    <Divider orientation="vertical" variant="middle" flexItem />
    <Box sx={{width: "550px", border: "10px", borderColor: "blue"}}>
    <Divider />

    {currentUser.premiumUser && 
    <div>
    
    <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                      sx={{paddingTop: "45px"}}>
                             <Typography variant="p" color="text.secondary" sx={{backgroundColor: "#d60f16", borderRadius: "10px", color:"white", padding: "6px"}}>
                                  Lo negativo 
                              </Typography>
                              </Stack>
                             

                      <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                      sx={{paddingTop: "20px", paddingBottom: "30px", paddingLeft:"20px", paddingRight: "20px"}}>
                       
                       
                       

               
                       <Negativo/>
    <Pagination count={noOfPages} page={page} onChange={handleChange} variant="outlined" shape="rounded" />
    </Stack>
    </div> }
    </Box>


    </Card>
    </div> }



      </Grid>

    </Grid>

    
  </Box>


  <Box sx={{ flexGrow: 1, display: { xl: 'none', l: "none", m: "block", s:"block", xs: 'block' }}}>

  <Card variant="outlined" sx={{width: "100%", border: "0px 1px 1px 1px", borderRadius:"0px 0px 0px 0px"}}>
          <Box sx={{border: "10px", borderColor: "blue", backgroundColor: "#FCFCFC"}}>
            <Box sx={{border: "10px", borderColor: "blue", backgroundColor: "#FCFCFC"}}>
              <div style={{ display:'flex', justifyContent:'center' }}>
              <CardMedia
                  component="img"
                  sx={{width: "80px", textAlign: "center", marginTop: "20px"}}
                  image="https://firebasestorage.googleapis.com/v0/b/jobsandlaw-5e0ba.appspot.com/o/images%2Flogoand.png?alt=media&token=5e0c4931-173b-4f10-a8ac-4d31305f46ce"
                  alt="logo"
                />
              </div>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: "100%", border: "2px", alignItems: "center", alignContent: "center", textAlign: "center"}}>
                    <CardContent sx={{paddingTop:"20px", width: "90%"}}>

                              <Typography variant="h3" color="text.secondary">
                                  {despacho}
                              </Typography>
                      
                      <Box sx={{paddingTop: "15px"}}>
                          <Typography variant="caption" sx={{backgroundColor: "#03b1fc", borderRadius: "10px", color:"white", padding: "6px"}}>
                              {tamanoDespacho}
                          </Typography>
                          <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px", marginLeft: "5px"}}>
                          {tipoDespacho}
        </Typography>

       
                      </Box>
                      <Box sx={{paddingTop: "15px"}}>
       
        <Typography variant="caption" sx={{backgroundColor: "#037bfc", borderRadius: "10px", color:"white", padding: "6px", marginLeft: "5px"}}>
                          {tipoDerecho}
        </Typography>
       
                      </Box>
                     
        </CardContent>
        </Box>
   
        </Box>
 
        </Box>
        
        <Box sx={{ display: 'flex', flexDirection: 'row', border: "2px"}}>
                    <CardContent sx={{width:"100%" }}>
                    <Stack direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                    sx={{paddingTop: "0px", paddingBottom: "20px"}}>
                    
                    </Stack>
                      <Typography variant="subtitle2"  color="text.secondary" sx={{textAlign: "center", paddingBottom: "34px"}}></Typography>
                      <Divider variant="middle" />
                     

                      <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                      sx={{paddingTop: "25px", paddingBottom: "0px", width: "100%"}}>
                             <Typography variant="h6" color="text.secondary">
                                  {`¿Por qué ${despacho}?`}
                              </Typography>

                      </Stack>
                      <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                      sx={{paddingTop: "5px", paddingBottom: "0px", width: "100%"}}>
        <Typography variant="paragraph" sx={{paddingBottom: "0px"}} >Así lo valoran sus abogad@s
        </Typography>
        </Stack>
                      
                      
                      
                      <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{width: "100%"}}>
        <Typography sx={{paddingTop: "30px", paddingRight: "20px", fontSize: "20px", fontWeight: "500"}} >Valoración general:
        </Typography>
        </Stack>
      <Grid container paddingTop="0px" paddingBottom="30px">
      <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
        <Stack direction="column"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      sx={{width: "100%"}}>

        <StyledRating
        sx={{paddingTop: "3px", paddingLeft: "0px"}}
        name="customized-color"
        defaultValue={(general*5)/100}
        readOnly
        getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
        precision={0.1}
        size="medium"
        icon={<Brightness1Icon fontSize="inherit" />}
        emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
      />
        </Stack>
        
        </Grid>
       
        
        
      
        <Grid item xs={4} sm={4} md={4} lg={4} xl={4} >
        <Stack direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      sx={{width: "100%"}}>
                <Typography sx={{paddingTop: "1px", paddingLeft: "15px", fontSize: "20px", fontWeight: "700", color: "#037bfc"}} >{general}
        </Typography> 
        </Stack>

        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        </Grid>

{!currentUser.premiumUser && <div>

    
  <Grid container paddingTop="10px">

<Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
<Stack direction="column"
              justifyContent="flex-end"
              alignItems="flex-end"
              sx={{width: "100%"}}>
<Typography sx={{paddingBottom: "0px", paddingLeft: "0px", paddingRight: "15px", fontSize: "16px", fontWeight: "400"}} >Tipo de trabajo:
</Typography>
</Stack>

</Grid>
<Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
<StyledRating
sx={{paddingTop: "3px", paddingLeft: "0px"}}
name="customized-color"
defaultValue={(tipoDeTrabajo*5)/100}
readOnly
getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
precision={0.1}
size="small"
icon={<Brightness1Icon fontSize="inherit" />}
emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
/>

</Grid>
<Grid item xs={3} sm={3} md={3} lg={3} xl={3} >
<Stack direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              sx={{width: "100%"}}>
        <Typography sx={{paddingTop: "1px", fontSize: "16px", fontWeight: "700", color: "#037bfc"}} > {tipoDeTrabajo}
</Typography> 
</Stack>

</Grid>

</Grid>
        
        <Grid container paddingTop="10px">

        <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
        <Stack direction="column"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      sx={{width: "100%"}}>
        <Typography sx={{paddingBottom: "0px", paddingRight: "15px", fontSize: "16px", fontWeight: "400"}} >Formación:
        </Typography>
        </Stack>
        
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
        <StyledRating
        sx={{paddingTop: "3px", paddingLeft: "0px"}}
        name="customized-color"
        defaultValue={(formacionTotal*5)/100}
        readOnly
        getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
        precision={0.1}
        size="small"
        icon={<Brightness1Icon fontSize="inherit" />}
        emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
      />
        
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3} >
        <Stack direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      sx={{width: "100%"}}>
                <Typography sx={{paddingTop: "1px", fontSize: "16px", fontWeight: "700", color: "#037bfc"}} >{formacionTotal}
        </Typography> 
        </Stack>

        </Grid>

        </Grid>
        <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      textAlign="center"
                      sx={{width: "100%", bgcolor: "#f0f4f5", opacity: "0.7", borderRadius: "25px", marginTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "30px"}}>
                <Typography sx={{paddingTop: "30px", paddingBottom: "10px", paddingLeft: "20px", paddingRight: "20px", fontSize: "16px", fontWeight: "700"}} >Este despacho no ha hecho públicos el resto de los resultados.
        </Typography>
        <Typography sx={{paddingBottom: "15px", paddingLeft: "20px", paddingRight: "20px", fontSize: "14px", fontWeight: "700"}} >¿Quieres conocerlos? Hazte Usuario Premium para tener acceso completo a Jobs&Law.
        </Typography>
      <nav aria-label="main mailbox folders">
        <List sx={{paddingBottom: "20px"}} >
          <ListItem disablePadding>
     
              <ListItemIcon>
                <TaskAltIcon />
              </ListItemIcon>
              <ListItemText primary="Todas las vacantes en despachos"
                  primaryTypographyProps={{
                    fontWeight: '700',
                    fontSize: "14px",
                  }} />
    
          </ListItem>
          <ListItem disablePadding>

              <ListItemIcon>
                <TaskAltIcon />
              </ListItemIcon>
              <ListItemText primary="Todas las vacantes in-house"
                  primaryTypographyProps={{
                    fontWeight: '700',
                    fontSize: "14px",
                  }} />
 
          </ListItem>
          <ListItem disablePadding>

              <ListItemIcon>
                <TaskAltIcon />
              </ListItemIcon>
              <ListItemText primary="Todas las vacantes de headhunters"
                  primaryTypographyProps={{
                    fontWeight: '700',
                    fontSize: "14px",
                  }} />

          </ListItem>
          <ListItem disablePadding>

              <ListItemIcon>
                <TaskAltIcon />
              </ListItemIcon>
              <ListItemText primary="Información completa sobre los despachos"
                  primaryTypographyProps={{
                    fontWeight: '700',
                    fontSize: "14px",
                  }} />

          </ListItem>
          <ListItem disablePadding>

<ListItemIcon>
  <TaskAltIcon />
</ListItemIcon>
<ListItemText primary="Información salarial"
    primaryTypographyProps={{
      fontWeight: '700',
      fontSize: "14px",
    }} />

</ListItem>
        </List>
      </nav>
      {registrado !== "no" && <Button variant="contained" href="/checkout">
Activar Premium
</Button>}
{registrado === "no" && <Button variant="contained" href="/">
Registrarse
</Button>}
        </Stack>
        </div>}



        {currentUser.premiumUser && <Stack sx={{paddingLeft: "10px"}} >
<Grid container paddingTop="10px">

        <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
        <Stack direction="column"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      sx={{width: "100%"}}>
        <Typography sx={{paddingBottom: "0px", paddingRight: "15px", fontSize: "16px", fontWeight: "400", textAlign: "end"}} >Ambiente laboral:
        </Typography>
        </Stack>
        
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
        <StyledRating
        sx={{paddingTop: "3px", paddingLeft: "0px"}}
        name="customized-color"
        defaultValue={(ambiente*5)/100}
        readOnly
        getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
        precision={0.1}
        size="small"
        icon={<Brightness1Icon fontSize="inherit" />}
        emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
      />
        
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3} >
        <Stack direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      sx={{width: "100%"}}>
                <Typography sx={{paddingTop: "1px", fontSize: "16px", fontWeight: "700", color: "#037bfc"}} >{ambiente}
        </Typography> 
        </Stack>

        </Grid>

        </Grid>
        <Grid container paddingTop="10px">
        <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
        <Stack direction="column"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      sx={{width: "100%"}}>
        <Typography sx={{paddingBottom: "0px", paddingLeft: "15px", paddingRight: "15px", fontSize: "16px", fontWeight: "400"}} >Conciliación:
        </Typography>
        </Stack>
        
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
        <StyledRating
        sx={{paddingTop: "3px", paddingLeft: "0px"}}
        name="customized-color"
        defaultValue={(conciliacion*5)/100}
        readOnly
        getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
        precision={0.1}
        size="small"
        icon={<Brightness1Icon fontSize="inherit" />}
        emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
      />
        
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3} >
        <Stack direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      sx={{width: "100%"}}>
                <Typography sx={{paddingTop: "1px", fontSize: "16px", fontWeight: "700", color: "#037bfc"}} >{conciliacion}
        </Typography> 
        </Stack>

        </Grid>
        </Grid>
        <Grid container paddingTop="10px">

        <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
        <Stack direction="column"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      sx={{width: "100%"}}>
        <Typography sx={{paddingBottom: "0px", paddingLeft: "0px", paddingRight: "15px", fontSize: "16px", fontWeight: "400"}} >Tipo de trabajo:
        </Typography>
        </Stack>
        
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
        <StyledRating
        sx={{paddingTop: "3px", paddingLeft: "0px"}}
        name="customized-color"
        defaultValue={(tipoDeTrabajo*5)/100}
        readOnly
        getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
        precision={0.1}
        size="small"
        icon={<Brightness1Icon fontSize="inherit" />}
        emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
      />
        
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3} >
        <Stack direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      sx={{width: "100%"}}>
                <Typography sx={{paddingTop: "1px", fontSize: "16px", fontWeight: "700", color: "#037bfc"}} > {tipoDeTrabajo}
        </Typography> 
        </Stack>

        </Grid>

        </Grid>

        <Grid container paddingTop="10px">

<Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
<Stack direction="column"
              justifyContent="flex-end"
              alignItems="flex-end"
              sx={{width: "100%"}}>
<Typography sx={{paddingBottom: "0px", paddingRight: "15px", fontSize: "16px", fontWeight: "400"}} >Carrera:
</Typography>
</Stack>

</Grid>
<Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
<StyledRating
sx={{paddingTop: "3px", paddingLeft: "0px"}}
name="customized-color"
defaultValue={(carrera*5)/100}
readOnly
getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
precision={0.1}
size="small"
icon={<Brightness1Icon fontSize="inherit" />}
emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
/>

</Grid>
<Grid item xs={3} sm={3} md={3} lg={3} xl={3} >
<Stack direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              sx={{width: "100%"}}>
        <Typography sx={{paddingTop: "1px", fontSize: "16px", fontWeight: "700", color: "#037bfc"}} >{carrera}
</Typography> 
</Stack>

</Grid>

</Grid>

<Grid container paddingTop="10px">

        <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
        <Stack direction="column"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      sx={{width: "100%"}}>
        <Typography sx={{paddingBottom: "0px", paddingRight: "15px", fontSize: "16px", fontWeight: "400"}} >Formación:
        </Typography>
        </Stack>
        
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
        <StyledRating
        sx={{paddingTop: "3px", paddingLeft: "0px"}}
        name="customized-color"
        defaultValue={(formacionTotal*5)/100}
        readOnly
        getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
        precision={0.1}
        size="small"
        icon={<Brightness1Icon fontSize="inherit" />}
        emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
      />
        
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3} >
        <Stack direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      sx={{width: "100%"}}>
                <Typography sx={{paddingTop: "1px", fontSize: "16px", fontWeight: "700", color: "#037bfc"}} >{formacionTotal}
        </Typography> 
        </Stack>

        </Grid>

        </Grid>

        
        <Grid container paddingTop="10px">

<Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
<Stack direction="column"
              justifyContent="flex-end"
              alignItems="flex-end"
              sx={{width: "100%"}}>
<Typography sx={{paddingBottom: "0px", paddingRight: "15px", fontSize: "16px", fontWeight: "400"}} >Diversidad:
</Typography>
</Stack>

</Grid>
<Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
<StyledRating
sx={{paddingTop: "3px", paddingLeft: "0px"}}
name="customized-color"
defaultValue={(diversidadTotal*5)/100}
readOnly
getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
precision={0.1}
size="small"
icon={<Brightness1Icon fontSize="inherit" />}
emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
/>

</Grid>
<Grid item xs={3} sm={3} md={3} lg={3} xl={3} >
<Stack direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              sx={{width: "100%"}}>
        <Typography sx={{paddingTop: "1px", fontSize: "16px", fontWeight: "700", color: "#037bfc"}} >{diversidadTotal}
</Typography> 
</Stack>

</Grid>

</Grid> 
       
       
<Grid container paddingTop="10px">

<Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
<Stack direction="column"
              justifyContent="flex-end"
              alignItems="flex-end"
              sx={{width: "100%"}}>
<Typography sx={{paddingBottom: "0px", paddingRight: "15px", fontSize: "16px", fontWeight: "400", textAlign: "end"}} >Cultura de Firma:
</Typography>
</Stack>

</Grid>
<Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
<StyledRating
sx={{paddingTop: "3px", paddingLeft: "0px"}}
name="customized-color"
defaultValue={(valores*5)/100}
readOnly
getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
precision={0.1}
size="small"
icon={<Brightness1Icon fontSize="inherit" />}
emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
/>

</Grid>
<Grid item xs={3} sm={3} md={3} lg={3} xl={3} >
<Stack direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              sx={{width: "100%"}}>
        <Typography sx={{paddingTop: "1px", fontSize: "16px", fontWeight: "700", color: "#037bfc"}} >{valores}
</Typography> 
</Stack>

</Grid>

</Grid> 
      
       
<Grid container paddingTop="10px">

        <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
        <Stack direction="column"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      sx={{width: "100%"}}>
        <Typography sx={{paddingBottom: "0px", paddingRight: "15px", fontSize: "16px", fontWeight: "400"}} >Igualdad:
        </Typography>
        </Stack>
        
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
        <StyledRating
        sx={{paddingTop: "3px", paddingLeft: "0px"}}
        name="customized-color"
        defaultValue={(igualdadTotal*5)/100}
        readOnly
        getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
        precision={0.1}
        size="small"
        icon={<Brightness1Icon fontSize="inherit" />}
        emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
      />
        
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3} >
        <Stack direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      sx={{width: "100%"}}>
                <Typography sx={{paddingTop: "1px", fontSize: "16px", fontWeight: "700", color: "#037bfc"}} >{igualdadTotal}
        </Typography> 
        </Stack>

        </Grid>

        </Grid>


        <Grid container paddingTop="10px">

<Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
<Stack direction="column"
              justifyContent="flex-end"
              alignItems="flex-end"
              sx={{width: "100%"}}>
<Typography sx={{paddingBottom: "0px", paddingRight: "15px", fontSize: "16px", fontWeight: "400"}} >Pro Bono:
</Typography>
</Stack>

</Grid>
<Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
<StyledRating
sx={{paddingTop: "3px", paddingLeft: "0px"}}
name="customized-color"
defaultValue={(proBono*5)/100}
readOnly
getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
precision={0.1}
size="small"
icon={<Brightness1Icon fontSize="inherit" />}
emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
/>

</Grid>
<Grid item xs={3} sm={3} md={3} lg={3} xl={3} >
<Stack direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              sx={{width: "100%"}}>
        <Typography sx={{paddingTop: "1px", fontSize: "16px", fontWeight: "700", color: "#037bfc"}} >{proBono}
</Typography> 
</Stack>

</Grid>

</Grid>

       
</Stack>}

        <Divider variant="middle" sx={{paddingTop: "40px"}} />
        {currentUser.premiumUser &&
        <div>


                      <Box sx={{textAlign: "center"}} >

                     <Pie percentage={cincoAnos} colour="blue" /> 
                     <Typography sx={{paddingBottom: "0px", fontSize:"14px", paddingRight: "15px", paddingLeft:"15px" }} >Se ve trabajando en este despacho dentro de cinco años
        </Typography>
                     </Box> 
                     <Box sx={{textAlign: "center"}} >

<Pie percentage={teletrabajo} colour="blue" /> 
<Typography sx={{paddingBottom: "0px", fontSize:"14px", paddingRight: "15px", paddingLeft:"15px", paddingBottom: "40px" }} >Valora positivamente la política de teletrabajo
</Typography>
</Box> 

                    
                   
   
                     
                      <Divider variant="middle" />
                      <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                      sx={{paddingTop: "25px"}}>
                             <Typography variant="h6" color="text.secondary" sx={{paddingBottom: "0px"}}>
                                  Reconocimientos
                              </Typography>
                              </Stack>
                              <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                      sx={{paddingTop: "10px"}}>
                              <Typography variant="subtitle2"  color="text.secondary" sx={{textAlign: "center", paddingBottom: "34px"}}>¡Esta información estará <br></br> disponible muy pronto!</Typography>
                      </Stack>
                      <Divider variant="middle" />
                      <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                      sx={{paddingTop: "25px"}}>
                             <Typography variant="h6" color="text.secondary" sx={{paddingBottom: "0px"}}>
                                  Remuneración
                              </Typography>
                              </Stack>
                                          <Typography variant="subtitle2" color="text.secondary" sx={{paddingBottom: "0px", textAlign: "center"}}>
                                  ¡Esta información estará <br></br> disponible muy pronto!
                              </Typography>
                      </div> }

                      

                  
                            
        </CardContent>
        </Box>
       
    
       

    </Card>


   {currentUser.premiumUser && 
   <div>

    <Card sx={{border: "0px 1px 1px 1px", borderRadius:"0px 0px 0px 0px"}}>
    <Divider />
    <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      textAlign= "center"
                      spacing={2}
                      sx={{paddingTop: "45px", paddingLeft: "15px", paddingRight: "15px"}}>
                             <Typography variant="h6" color="text.secondary" sx={{paddingBottom: "0px"}}>
                                  Opiniones de los abogados de {despacho} 
                              </Typography>
                              </Stack>
                             

                      <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                      sx={{paddingTop: "20px", paddingBottom: "30px"}}>
                       
                       
                       

               
                       <Listas/>

    </Stack>


    </Card>
    <Card sx={{border: "0px 1px 1px 1px", borderRadius:"0px 0px 0px 0px", marginBottom: "30px"}}>
    <Divider />

  
    <Divider />
    <Stack 
                      alignItems="center"
                      spacing={2}
                      sx={{paddingTop: "45px"}}>
                             <Typography variant="p" color="text.secondary" sx={{backgroundColor: "#35b50e", borderRadius: "10px", color:"white", padding: "6px"}}>
                                 Lo positivo
                              </Typography>
                              </Stack>
                             

                      <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                      sx={{paddingTop: "20px", paddingBottom: "30px", paddingLeft:"20px", paddingRight: "20px"}}>
                       
                       
                       

               
                       <Positivo/>
    </Stack>

    <Divider orientation="vertical" variant="middle" flexItem />

    <Divider />
    <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                      sx={{paddingTop: "45px"}}>
                             <Typography variant="p" color="text.secondary" sx={{backgroundColor: "#d60f16", borderRadius: "10px", color:"white", padding: "6px"}}>
                                  Lo negativo 
                              </Typography>
                              </Stack>
                             

                      <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                      sx={{paddingTop: "20px", paddingBottom: "30px", paddingLeft:"20px", paddingRight: "20px"}}>
                       
                       
                       

               
                       <Negativo/>
    <Pagination count={noOfPages} page={page} onChange={handleChange} variant="outlined" shape="rounded" />
    </Stack>


    </Card>
    </div> }
    
   {/*<Grid container spacing={2} paddingTop="30px" sx={{justifyContent: "center"}}>
      <Grid item xs={8} sm={8} md={8} lg={8} xl={5} sx={{justifyContent: "center"}}>
     
        <Card sx={{display: "flex", width: "1000px", border: "1px 1px 1px 0px", borderColor: "blue", borderRadius:"10px 10px 0px 0px"}}>
          <Box sx={{width: "550px", border: "10px", borderColor: "blue"}}>
            <Box sx={{width: "550px", border: "10px", borderColor: "blue",  display: "flex"}}>
              <Box sx={{width: "500px", paddingRight: "50px"}}>
              <CardMedia
                  component="img"
                  sx={{padding:"20px", justifyContent:"center"}}
                  image="https://distritodigitalcv.es/wp-content/uploads/2020/07/logo-garrigues.jpg"
                  alt="Garrigues"
                /></Box>
              
                <Box sx={{ display: 'flex', flexDirection: 'row', width: "90%", border: "2px"}}>
                    <CardContent sx={{paddingTop:"20px"}}>
                     
                              <Typography sx={{paddingTop: "20px"}} variant="h5" color="text.secondary">
                              {despacho} 
                              </Typography>
                             
                      <Box sx={{paddingTop: "15px"}}>
                          <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px"}}>
                              {tipoDespacho}
                          </Typography>
                      </Box>
                      <Box sx={{paddingTop: "15px"}}>
                          <Typography variant="caption" sx={{backgroundColor: "#03b1fc", borderRadius: "10px", color:"white", padding: "6px"}}>
                          {tamanoDespacho}
                          </Typography>
                      </Box>

                      <Box sx={{paddingTop: "15px"}}>
                      <Typography variant="caption" sx={{backgroundColor: "#037bfc", borderRadius: "10px", color:"white", padding: "6px"}}>
                          {tipoDerecho}
                          </Typography>
                      </Box>



        
        <Box sx={{display: "block", alignItems: "flex-start", paddingTop: "20px"}} >
        </Box>
        </CardContent>
        </Box>
   
        </Box>
        <Divider variant="middle" />
        
        <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                      sx={{paddingTop: "25px", paddingBottom: "0px", width: "100%"}}>
                             <Typography variant="h6" color="text.secondary">
                                  {`¿Por qué ${despacho}?`}
                              </Typography>

                      </Stack>
                      <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                      sx={{paddingTop: "5px", paddingBottom: "0px", width: "100%"}}>
        <Typography variant="paragraph" sx={{paddingBottom: "0px"}} >Así lo valoran sus abogad@s
        </Typography>
        </Stack>
        
        <Stack direction="column"
                      justifyContent="flex-end"
                      alignItems="center"
                      sx={{width: "100%"}}>
        <Typography sx={{paddingTop: "30px", paddingRight: "20px", fontSize: "20px", fontWeight: "500"}} >Valoración general:
        </Typography>
        </Stack>
      <Grid container paddingTop="0px" paddingBottom="30px">
      <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
        <Stack direction="column"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      sx={{width: "100%"}}>

        <StyledRating
        sx={{paddingTop: "3px", paddingRight: "0px"}}
        name="customized-color"
        defaultValue={(general*5)/100}
        readOnly
        getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
        precision={0.1}
        size="medium"
        icon={<Brightness1Icon fontSize="inherit" />}
        emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
      />
        </Stack>
        
        </Grid>
       
        
        
      
        <Grid item xs={5} sm={5} md={5} lg={5} xl={5} >
        <Stack direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      sx={{width: "100%"}}>
                <Typography sx={{paddingTop: "1px", paddingLeft: "15px", fontSize: "20px", fontWeight: "700", color: "#037bfc"}} >{general}
        </Typography> 
        </Stack>

        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        </Grid>

        {currentUser.premiumUser && <div>

        <Grid container paddingTop="10px">
      <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
        <Stack direction="column"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      sx={{width: "100%"}}>
        <Typography sx={{paddingBottom: "0px", paddingRight: "20px", fontSize: "16px", fontWeight: "400"}} >Ambiente laboral:
        </Typography>
        </Stack>
        
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
        <StyledRating
        sx={{paddingTop: "3px", paddingLeft: "5px"}}
        name="customized-color"
        defaultValue={ambiente*5/100}
        readOnly
        getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
        precision={0.1}
        size="small"
        icon={<Brightness1Icon fontSize="inherit" />}
        emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
      />
        
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3} >
        <Stack direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      sx={{width: "100%"}}>
                <Typography sx={{paddingTop: "1px", fontSize: "16px", fontWeight: "700", color: "#037bfc"}} >{ambiente}
        </Typography> 
        </Stack>

        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        </Grid>
        <Grid container paddingTop="10px">
      <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
        <Stack direction="column"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      sx={{width: "100%"}}>
        <Typography sx={{paddingBottom: "0px", paddingRight: "20px", fontSize: "16px", fontWeight: "400"}} >Conciliación:
        </Typography>
        </Stack>
        
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
        <StyledRating
        sx={{paddingTop: "3px", paddingLeft: "5px"}}
        name="customized-color"
        defaultValue={(conciliacion*5)/100}
        readOnly
        getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
        precision={0.1}
        size="small"
        icon={<Brightness1Icon fontSize="inherit" />}
        emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
      />
        
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3} >
        <Stack direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      sx={{width: "100%"}}>
                <Typography sx={{paddingTop: "1px", fontSize: "16px", fontWeight: "700", color: "#037bfc"}} >{conciliacion}
        </Typography> 
        </Stack>

        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        </Grid>
        <Grid container paddingTop="10px">
      <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
        <Stack direction="column"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      sx={{width: "100%"}}>
        <Typography sx={{paddingBottom: "0px", paddingRight: "20px", fontSize: "16px", fontWeight: "400"}} >Tipo de trabajo:
        </Typography>
        </Stack>
        
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
        <StyledRating
        sx={{paddingTop: "3px", paddingLeft: "5px"}}
        name="customized-color"
        defaultValue={(tipoDeTrabajo*5)/100}
        readOnly
        getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
        precision={0.1}
        size="small"
        icon={<Brightness1Icon fontSize="inherit" />}
        emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
      />
        
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3} >
        <Stack direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      sx={{width: "100%"}}>
                <Typography sx={{paddingTop: "1px", fontSize: "16px", fontWeight: "700", color: "#037bfc"}} > {tipoDeTrabajo}
        </Typography> 
        </Stack>

        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        </Grid>
        <Grid container paddingTop="10px">
      <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
        <Stack direction="column"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      sx={{width: "100%"}}>
        <Typography sx={{paddingBottom: "0px", paddingRight: "20px", fontSize: "16px", fontWeight: "400"}} >Carrera:
        </Typography>
        </Stack>
        
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
        <StyledRating
        sx={{paddingTop: "3px", paddingLeft: "5px"}}
        name="customized-color"
        defaultValue={(carrera*5)/100}
        readOnly
        getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
        precision={0.1}
        size="small"
        icon={<Brightness1Icon fontSize="inherit" />}
        emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
      />
        
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3} >
        <Stack direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      sx={{width: "100%"}}>
                <Typography sx={{paddingTop: "1px", fontSize: "16px", fontWeight: "700", color: "#037bfc"}} >{carrera}
        </Typography> 
        </Stack>

        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        </Grid>
        <Grid container paddingTop="10px">
      <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
        <Stack direction="column"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      sx={{width: "100%"}}>
        <Typography sx={{paddingBottom: "0px", paddingRight: "20px", fontSize: "16px", fontWeight: "400"}} >Formación:
        </Typography>
        </Stack>
        
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
        <StyledRating
        sx={{paddingTop: "3px", paddingLeft: "5px"}}
        name="customized-color"
        defaultValue={(formacionTotal*5)/100}
        readOnly
        getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
        precision={0.1}
        size="small"
        icon={<Brightness1Icon fontSize="inherit" />}
        emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
      />
        
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3} >
        <Stack direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      sx={{width: "100%"}}>
                <Typography sx={{paddingTop: "1px", fontSize: "16px", fontWeight: "700", color: "#037bfc"}} >{formacionTotal}
        </Typography> 
        </Stack>

        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        </Grid>
        <Grid container paddingTop="10px">
      <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
        <Stack direction="column"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      sx={{width: "100%"}}>
        <Typography sx={{paddingBottom: "0px", paddingRight: "20px", fontSize: "16px", fontWeight: "400"}} >Diversidad:
        </Typography>
        </Stack>
        
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
        <StyledRating
        sx={{paddingTop: "3px", paddingLeft: "5px"}}
        name="customized-color"
        defaultValue={(diversidadTotal*5)/100}
        readOnly
        getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
        precision={0.1}
        size="small"
        icon={<Brightness1Icon fontSize="inherit" />}
        emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
      />
        
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3} >
        <Stack direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      sx={{width: "100%"}}>
                <Typography sx={{paddingTop: "1px", fontSize: "16px", fontWeight: "700", color: "#037bfc"}} >{diversidadTotal}
        </Typography> 
        </Stack>

        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        </Grid>
        <Grid container paddingTop="10px">
      <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
        <Stack direction="column"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      sx={{width: "100%"}}>
        <Typography sx={{paddingBottom: "0px", paddingRight: "20px", fontSize: "16px", fontWeight: "400"}} >Cultura de firma:
        </Typography>
        </Stack>
        
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
        <StyledRating
        sx={{paddingTop: "3px", paddingLeft: "5px"}}
        name="customized-color"
        defaultValue={(valores*5)/100}
        readOnly
        getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
        precision={0.1}
        size="small"
        icon={<Brightness1Icon fontSize="inherit" />}
        emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
      />
        
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3} >
        <Stack direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      sx={{width: "100%"}}>
                <Typography sx={{paddingTop: "1px", fontSize: "16px", fontWeight: "700", color: "#037bfc"}} >{valores}
        </Typography> 
        </Stack>

        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        </Grid>
        <Grid container paddingTop="10px">
      <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
        <Stack direction="column"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      sx={{width: "100%"}}>
        <Typography sx={{paddingBottom: "0px", paddingRight: "20px", fontSize: "16px", fontWeight: "400"}} >Igualdad:
        </Typography>
        </Stack>
        
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
        <StyledRating
        sx={{paddingTop: "3px", paddingLeft: "5px"}}
        name="customized-color"
        defaultValue={(igualdadTotal*5)/100}
        readOnly
        getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
        precision={0.1}
        size="small"
        icon={<Brightness1Icon fontSize="inherit" />}
        emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
      />
        
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3} >
        <Stack direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      sx={{width: "100%"}}>
                <Typography sx={{paddingTop: "1px", fontSize: "16px", fontWeight: "700", color: "#037bfc"}} >{igualdadTotal}
        </Typography> 
        </Stack>

        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        </Grid>
        <Grid container paddingTop="10px">
      <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
        <Stack direction="column"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      sx={{width: "100%"}}>
        <Typography sx={{paddingBottom: "0px", paddingRight: "20px", fontSize: "16px", fontWeight: "400"}} >Pro Bono:
        </Typography>
        </Stack>
        
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
        <StyledRating
        sx={{paddingTop: "3px", paddingLeft: "5px"}}
        name="customized-color"
        defaultValue={(proBono*5)/100}
        readOnly
        getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
        precision={0.1}
        size="small"
        icon={<Brightness1Icon fontSize="inherit" />}
        emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
      />
        
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3} >
        <Stack direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      sx={{width: "100%"}}>
                <Typography sx={{paddingTop: "1px", fontSize: "16px", fontWeight: "700", color: "#037bfc"}} >{proBono}
        </Typography> 
        </Stack>

        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        </Grid>
        </div> }
      
      

        {!currentUser.premiumUser && <div>

    
<Grid container paddingTop="10px">
<Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
</Grid>
<Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
<Stack direction="column"
              justifyContent="flex-end"
              alignItems="flex-end"
              sx={{width: "100%"}}>
<Typography sx={{paddingBottom: "0px", paddingRight: "20px", fontSize: "16px", fontWeight: "400"}} >Tipo de trabajo:
</Typography>
</Stack>

</Grid>
<Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
<StyledRating
sx={{paddingTop: "3px", paddingLeft: "5px"}}
name="customized-color"
defaultValue={(tipoDeTrabajo*5)/100}
readOnly
getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
precision={0.1}
size="small"
icon={<Brightness1Icon fontSize="inherit" />}
emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
/>

</Grid>
<Grid item xs={3} sm={3} md={3} lg={3} xl={3} >
<Stack direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              sx={{width: "100%"}}>
        <Typography sx={{paddingTop: "1px", fontSize: "16px", fontWeight: "700", color: "#037bfc"}} > {tipoDeTrabajo}
</Typography> 
</Stack>

</Grid>
<Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
</Grid>
</Grid>

<Grid container paddingTop="10px">
<Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
</Grid>
<Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
<Stack direction="column"
              justifyContent="flex-end"
              alignItems="flex-end"
              sx={{width: "100%"}}>
<Typography sx={{paddingBottom: "0px", paddingRight: "20px", fontSize: "16px", fontWeight: "400"}} >Formación:
</Typography>
</Stack>

</Grid>
<Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
<StyledRating
sx={{paddingTop: "3px", paddingLeft: "5px"}}
name="customized-color"
defaultValue={(formacionTotal*5)/100}
readOnly
getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
precision={0.1}
size="small"
icon={<Brightness1Icon fontSize="inherit" />}
emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
/>

</Grid>
<Grid item xs={3} sm={3} md={3} lg={3} xl={3} >
<Stack direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              sx={{width: "100%"}}>
        <Typography sx={{paddingTop: "1px", fontSize: "16px", fontWeight: "700", color: "#037bfc"}} >{formacionTotal}
</Typography> 
</Stack>

</Grid>
<Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
</Grid>
</Grid>
<Stack direction="column"
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              sx={{bgcolor: "#f0f4f5", opacity: "0.7", borderRadius: "25px", marginTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "30px", marginLeft: "15px", marginRight: "15px"}}>
        <Typography sx={{paddingTop: "30px", paddingBottom: "10px", paddingLeft: "20px", paddingRight: "20px", fontSize: "16px", fontWeight: "700"}} >Este despacho no ha hecho públicos el resto de los resultados.
</Typography>
<Typography sx={{paddingBottom: "15px", paddingLeft: "20px", paddingRight: "20px", fontSize: "14px", fontWeight: "700"}} >¿Quieres conocerlos? Hazte Usuario Premium para tener acceso completo a Jobs&Law.
</Typography>
<nav aria-label="main mailbox folders">
<List sx={{paddingBottom: "20px"}} >
  <ListItem disablePadding>

      <ListItemIcon>
        <TaskAltIcon />
      </ListItemIcon>
      <ListItemText primary="Todas las vacantes en despachos"
          primaryTypographyProps={{
            fontWeight: '700',
            fontSize: "14px",
          }} />

  </ListItem>
  <ListItem disablePadding>

      <ListItemIcon>
        <TaskAltIcon />
      </ListItemIcon>
      <ListItemText primary="Todas las vacantes in-house"
          primaryTypographyProps={{
            fontWeight: '700',
            fontSize: "14px",
          }} />

  </ListItem>
  <ListItem disablePadding>

      <ListItemIcon>
        <TaskAltIcon />
      </ListItemIcon>
      <ListItemText primary="Todas las vacantes de headhunters"
          primaryTypographyProps={{
            fontWeight: '700',
            fontSize: "14px",
          }} />

  </ListItem>
  <ListItem disablePadding>

      <ListItemIcon>
        <TaskAltIcon />
      </ListItemIcon>
      <ListItemText primary="Información completa sobre los despachos"
          primaryTypographyProps={{
            fontWeight: '700',
            fontSize: "14px",
          }} />

  </ListItem>
  <ListItem disablePadding>

<ListItemIcon>
<TaskAltIcon />
</ListItemIcon>
<ListItemText primary="Información salarial"
primaryTypographyProps={{
fontWeight: '700',
fontSize: "14px",
}} />

</ListItem>
</List>
</nav>
<Button variant="contained" href="#contained-buttons">
Activar Premium
</Button>
</Stack>
</div>}
        
       

        
   
    
                      <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                      sx={{paddingTop: "5px", paddingBottom: "30px", width: "100%"}}>
   
        </Stack>
        
        
        </Box>
        <Divider orientation="vertical" variant="middle" flexItem />
        
       
    
        <Box sx={{ display: 'flex', flexDirection: 'row', width: "4000px", border: "2px"}}>
                    <CardContent sx={{paddingTop:"20px", width:"450px", }}>
                    {currentUser.premiumUser && <div>
                    <Stack direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                    sx={{paddingTop: "0px", paddingBottom: "20px"}}>
                     <Stack direction="row"
                 
                   
                    spacing={2}
                    sx={{paddingTop: "0px", paddingBottom: "20px"}}>
                      <Box sx={{textAlign: "center"}} >

                     <Pie percentage={cincoAnos} colour="blue" /> 
                     <Typography sx={{paddingBottom: "0px", fontSize:"14px", paddingRight: "15px", paddingLeft:"15px" }} >Se ve trabajando en este despacho dentro de cinco años
        </Typography>
                     </Box> 
                     <Box sx={{textAlign: "center"}} >

<Pie percentage={teletrabajo} colour="blue" /> 
<Typography sx={{paddingBottom: "0px", fontSize:"14px", paddingRight: "15px", paddingLeft:"15px" }} >Valora positivamente la política de teletrabajo
</Typography>
</Box> 
                    </Stack>
                    
                   
                 
                    </Stack>
                     
                      <Divider variant="middle" />
                      <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                      sx={{paddingTop: "25px"}}>
                             <Typography variant="h6" color="text.secondary" sx={{paddingBottom: "0px"}}>
                                  Reconocimientos
                              </Typography>
                              </Stack>
                              <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                      sx={{paddingTop: "10px"}}>
                              <Typography variant="subtitle2"  color="text.secondary" sx={{textAlign: "center", paddingBottom: "34px"}}>¡Esta información estará <br></br> disponible muy pronto!</Typography>
                      </Stack>
                      <Divider variant="middle" />
                      <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                      sx={{paddingTop: "25px"}}>
                             <Typography variant="h6" color="text.secondary" sx={{paddingBottom: "0px"}}>
                                  Remuneración
                              </Typography>
                              </Stack>
                              <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                      sx={{paddingTop: "10px"}}>
                              <Typography variant="subtitle2"  color="text.secondary" sx={{textAlign: "center", paddingBottom: "34px"}}>¡Esta información estará <br></br> disponible muy pronto!</Typography>
                      </Stack>


                      
                           
                  
           </div> }              
           {!currentUser.premiumUser && <div>
            <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      textAlign="center"
                      sx={{width: "100%", bgcolor: "#f0f4f5", opacity: "0.7", borderRadius: "25px", marginTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "30px", height: "100vh"}}>
                <Typography sx={{paddingTop: "30px", paddingBottom: "10px", paddingLeft: "20px", paddingRight: "20px", fontSize: "16px", fontWeight: "700"}} >Este despacho no ha hecho públicos el resto de los resultados.
        </Typography>
        <Typography sx={{paddingBottom: "15px", paddingLeft: "20px", paddingRight: "20px", fontSize: "14px", fontWeight: "700"}} >¿Quieres conocerlos? Hazte Usuario Premium para tener acceso completo a Jobs&Law.
        </Typography>
      <nav aria-label="main mailbox folders">
        <List sx={{paddingBottom: "20px"}} >
          <ListItem disablePadding>
     
              <ListItemIcon>
                <TaskAltIcon />
              </ListItemIcon>
              <ListItemText primary="Todas las vacantes en despachos"
                  primaryTypographyProps={{
                    fontWeight: '700',
                    fontSize: "14px",
                  }} />
    
          </ListItem>
          <ListItem disablePadding>

              <ListItemIcon>
                <TaskAltIcon />
              </ListItemIcon>
              <ListItemText primary="Todas las vacantes in-house"
                  primaryTypographyProps={{
                    fontWeight: '700',
                    fontSize: "14px",
                  }} />
 
          </ListItem>
          <ListItem disablePadding>

              <ListItemIcon>
                <TaskAltIcon />
              </ListItemIcon>
              <ListItemText primary="Todas las vacantes de headhunters"
                  primaryTypographyProps={{
                    fontWeight: '700',
                    fontSize: "14px",
                  }} />

          </ListItem>
          <ListItem disablePadding>

              <ListItemIcon>
                <TaskAltIcon />
              </ListItemIcon>
              <ListItemText primary="Información completa sobre los despachos"
                  primaryTypographyProps={{
                    fontWeight: '700',
                    fontSize: "14px",
                  }} />

          </ListItem>
          <ListItem disablePadding>

<ListItemIcon>
  <TaskAltIcon />
</ListItemIcon>
<ListItemText primary="Información salarial"
    primaryTypographyProps={{
      fontWeight: '700',
      fontSize: "14px",
    }} />

</ListItem>
        </List>
      </nav>
      <Button variant="contained" href="#contained-buttons">
        Activar Premium
      </Button>
        </Stack>

                      
                           
                  
           </div> }              
        </CardContent>
        
        </Box>

      
 
    </Card>
    {currentUser.premiumUser &&
    <div>
    <Card sx={{width: "1000px", border: "0px 1px 1px 1px", borderRadius:"0px 0px 0px 0px"}}>
    <Divider />
    <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                      sx={{paddingTop: "45px"}}>
                             <Typography variant="h6" color="text.secondary" sx={{paddingBottom: "0px"}}>
                                  Opiniones de los abogados de {despacho} 
                              </Typography>
                              </Stack>
                             

                      <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                      sx={{paddingTop: "20px", paddingBottom: "30px"}}>
                       
                       
                       

               
                       <Listas/>

    </Stack>


    </Card>
    <Card sx={{width: "1000px", display:"flex", border: "0px 1px 1px 1px", borderRadius:"0px 0px 0px 0px", marginBottom: "30px"}}>
    <Divider />

    <Box sx={{width: "550px", border: "10px", borderColor: "blue"}}>
    <Divider />
    <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                      sx={{paddingTop: "45px"}}>
                             <Typography variant="p" color="text.secondary" sx={{backgroundColor: "#35b50e", borderRadius: "10px", color:"white", padding: "6px"}}>
                                 Lo positivo
                              </Typography>
                              </Stack>
                             

                      <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                      sx={{paddingTop: "20px", paddingBottom: "30px", paddingLeft:"20px", paddingRight: "20px"}}>
                       
                       
                       

               
                       <Positivo/>
    </Stack>
    </Box>
    <Divider orientation="vertical" variant="middle" flexItem />
    <Box sx={{width: "550px", border: "10px", borderColor: "blue"}}>
    <Divider />

    {currentUser.premiumUser && 
    <div>
    
    <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                      sx={{paddingTop: "45px"}}>
                             <Typography variant="p" color="text.secondary" sx={{backgroundColor: "#d60f16", borderRadius: "10px", color:"white", padding: "6px"}}>
                                  Lo negativo 
                              </Typography>
                              </Stack>
                             

                      <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                      sx={{paddingTop: "20px", paddingBottom: "30px", paddingLeft:"20px", paddingRight: "20px"}}>
                       
                       
                       

               
                       <Negativo/>
    <Pagination count={noOfPages} page={page} onChange={handleChange} variant="outlined" shape="rounded" />
    </Stack>
    </div> }
    </Box>


    </Card>
    </div> }



      </Grid>

    </Grid>

    */}
  </Box>



  </AuthProvider>

  
      </div>
    );
}
