import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import { Link } from '@mui/material';
import { useUserAuth } from "../context/UserAuthContext";
import { useNavigate } from 'react-router';
import Image from 'mui-image';
import Grid from '@mui/material/Grid';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';


const pages = ['Home', 'Jobs', 'SingleJob'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const Header = ({isAuth}) => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [state, setState] = React.useState({
    top: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
      
          <ListItem  disablePadding>
            <ListItemButton  href="./" >
          
              <ListItemText primary="Escritorio" />
            </ListItemButton>
          </ListItem>
          <ListItem  disablePadding>
            <ListItemButton  href="./limitedjobs" >
          
              <ListItemText primary="Buscador Básico" />
            </ListItemButton>
          </ListItem>
          <ListItem  disablePadding>
            <ListItemButton  href="./alljobs" >
          
              <ListItemText primary="Buscador Premium" />
            </ListItemButton>
          </ListItem>
          <ListItem  disablePadding>
            <ListItemButton  href="./perfilprofesional" >
          
              <ListItemText primary="Perfil Profesional" />
            </ListItemButton>
          </ListItem>
          <ListItem  disablePadding>
            <ListItemButton  href="./rankings" >
          
              <ListItemText primary="Rankings" />
            </ListItemButton>
          </ListItem>
          {user && <ListItem  disablePadding>
            <ListItemButton  href="./micuentapremium" >
          
              <ListItemText primary="Mi Cuenta" />
            </ListItemButton>
          </ListItem>}
          {user && <ListItem  disablePadding>
            <ListItemButton onClick={handleLogOut} >
          
              <ListItemText primary="Cerrar sesión" />
            </ListItemButton>
          </ListItem>}
      </List>
      <Divider />
      
    </Box>
  );

  let {user, logOut} = useUserAuth();
  const navigate = useNavigate();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleClickMenu = () => {

  };

  const handleLogOut = async () => {
    try {
      await logOut();
      navigate("/");
  } catch (err) {
    console.log(err.message);
  }  
  }

  return (
    <AppBar position="static" style={{ background: 'white'}}>
      <Container maxWidth="xl" sx={{display: { xl: 'block', l: "block", m: "none", s:"none", xs: 'none' }}} >
        
        <Toolbar disableGutters >



        <Grid container componente="main" spacing={0} sx={{justifyContent: "center"}}>
        <Grid item xs={1} sm={1} md={1} square>
      
      </Grid>
      <Grid item xs={2} sm={2} md={2} square>
      <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: "flex-end",
                justifyContent: "center",

             }}
            >
       
       <Button href="./dashboard" variant="text" size="small" style={{display: "flex", flexDirection: "column"}}>
       <Image src="/images/logo.png" style={{width: "130px"}} sx={{marginLeft: "60px", marginRight: "60px"}} />

    </Button>



      </Box>
      </Grid>
      
      

      <Grid item xs={9} sm={9} md={9} square>
      <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: "flex-start",
                justifyContent: "center",
                paddingTop: "4px"

             }}
            >
      
      <Button
                onClick={handleCloseNavMenu}
                href="./limitedjobs"
                sx={{ my: 2, color: '#004AAD', display: 'block', margingRight: "10px"  }}
              >
                Buscador básico
              </Button>

              <Button
                variant="contained"
                onClick={handleCloseNavMenu}
                href="./alljobs"
                sx={{ my: 2, color: 'white', display: 'block', marginRight: "10px", marginLeft: "5px"  }}
              >
                Buscador premium
              </Button>
    
              <Button
                onClick={handleCloseNavMenu}
                href="./perfilprofesional"
                sx={{ my: 2, color: '#004AAD', display: 'block', marginLeft: "5px", marginRight: "10px"  }}
              >
                Perfil Profesional
              </Button>

              <Button
                onClick={handleCloseNavMenu}
                href="./rankings"
                sx={{ my: 2, color: '#004AAD', display: 'block', marginLeft: "5px"  }}
              >
                Rankings
              </Button>
              
              {user &&
          <Box sx={{ flexGrow: 0, paddingTop: "15px", paddingLeft: "20px" }}>
            <Tooltip title="Opciones">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src="https://firebasestorage.googleapis.com/v0/b/jobsandlaw-5e0ba.appspot.com/o/images%2FSin%20t%C3%ADtulo%20(600%C2%A0%C3%97%C2%A0600%C2%A0px)%20(1).png?alt=media&token=b95ac56a-f570-4c9a-8fcf-11ba42a74b93" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
             
             <MenuItem onClick={handleLogOut} sx={{paddingTop: "10px"}} >
                  <Link href="./">
                  <Typography textAlign="center">Cerrar sesión</Typography>
                  </Link>
             </MenuItem>      
            
            </Menu>
          </Box> }       
      </Box>
      </Grid>

      <Grid item xs={1} sm={1} md={1} square>
      </Grid>

    </Grid>







  
          
        </Toolbar>
      </Container>

      <Container width="100%" sx={{display: { xl: 'none', l: "none", m: "block", s:"block", xs: 'block' }}} >
        
        <Toolbar disableGutters >



        <Grid container componente="main" spacing={0} sx={{justifyContent: "center"}}>
   
        <Grid item xs={3} sm={3} md={3} square>
      <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: "center",
                justifyContent: "center",

             }}
            >

      </Box>
      
      </Grid>
      <Grid item xs={6} sm={6} md={6} square>
      <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: "center",
                justifyContent: "center",

             }}
            >
       

       <Button href="./dashboard" variant="text" size="small" style={{display: "flex", flexDirection: "column"}}>
       <Image src="/images/logo.png" style={{width: "130px"}}  />

    </Button>


      </Box>
      
      </Grid>
      <Grid item xs={3} sm={3} md={3} square>
      <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: "flex-end",
                justifyContent: "flex-end",
                alignContent: "center",
                marginTop: "10px",

             }}
            >
            
          {['top'].map((anchor) => (
        <React.Fragment key={anchor}>
            <IconButton
            size="large"
            edge="start"
           
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={toggleDrawer(anchor, true)}
          >
            <MenuIcon />
          </IconButton>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}

      </Box>
      
      </Grid>

 
      
      
      

    </Grid>

             






  
          
        </Toolbar>
      </Container>

    </AppBar>
  );
};
export default Header;
