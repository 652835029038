import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import StarIcon from '@mui/icons-material/Star';
import { Hidden } from '@mui/material';
import { makeStyles } from '@mui/material';
import { blue } from '@mui/material/colors';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import { useState, useEffect } from 'react';
import { useUserAuth } from '../context/UserAuthContext';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import RichTextEditor from '../components/RichTextEditor';
import trabajoServices from '../services/trabajo.services';
import TrabajoDataService from '../services/trabajo.services';
import Autocomplete from '@mui/material/Autocomplete';
import listareas from './listareas';
import Slider from '@mui/material/Slider';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import parse from "html-react-parser";
import "./custom.css";
import { serverTimestamp } from 'firebase/firestore';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { setLogo } from '../firebase-config';
import Image from 'mui-image';
import tiposdespacho from './tiposdespacho';
import tamanosdespacho from './tamanosdespacho';
import tiposderecho from './tiposderecho';
import FormGroup from '@mui/material/FormGroup';
import AuthProvider from '../components/authProvider';
import { useParams } from "react-router-dom";
import { getFirestore, collection, onSnapshot, addDoc, deleteDoc, doc, query, where, orderBy, getDoc, getDocs, updateDoc  } from 'firebase/firestore';
import { styled } from '@mui/material/styles';
import { getJob } from '../firebase-config';
import { useNavigate } from 'react-router';
import JobList from "./JobList";
import Rating from '@mui/material/Rating';



const anosExperiencia = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 1,
    label: '1',
  },
  {
    value: 2,
    label: '2',
  },
  {
    value: 3,
    label: '3',
  },
  {
    value: 4,
    label: '4',
  },
  {
    value: 5,
    label: '5',
  },
  {
    value: 6,
    label: '6',
  },
  {
    value: 7,
    label: '7',
  },
  {
    value: 8,
    label: '8',
  },
  {
    value: 9,
    label: '9',
  },
  {
    value: 10,
    label: '10',
  },
  {
    value: 11,
    label: '11',
  },
  {
    value: 12,
    label: '12',
  },
  {
    value: 13,
    label: '13',
  },
  {
    value: 14,
    label: '14',
  },
  {
    value: 15,
    label: '15',
  },
  {
    value: 16,
    label: '16',
  },
  {
    value: 17,
    label: '17',
  },
  {
    value: 18,
    label: '18',
  },
  {
    value: 19,
    label: '19',
  },
  {
    value: 20,
    label: '+20',
  },
];

const salarios = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 20,
    label: '20k',
  },
  {
    value: 40,
    label: '40k',
  },
  {
    value: 60,
    label: '60k',
  },
  {
    value: 80,
    label: '80k',
  },
  {
    value: 100,
    label: '100k',
  },
  {
    value: 120,
    label: '120k',
  },
  {
    value: 140,
    label: '140+',
  },
];

function valuetext(value: number) {
  return `${value}k`;
}

function EditJobLawFirm({id, setTrabajoId}) { 

  let params = useParams();
  const db= getFirestore()

  const navigate = useNavigate();

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));


  const [titulo, setTitulo] = useState("");
    const [despacho, setDespacho] = useState("");
    const [texto, setTexto] = useState("");
    const [ciudad, setCiudad] = useState("");
    const [salario, setSalario] = useState("");
    const [area, setArea] = useState([]);
    const [experiencia, setExperiencia] = useState([2, 4]);
    const [nota, setNota] = useState("");
    const [enlaceOferta, setEnlaceOferta] = useState("");
    const [logoOferta, setLogoOferta] = useState("");
    const [hayLogo, setHayLogo] = useState(true);
    const [recomendado, setRecomendado] = useState(false);
    const [tipoDespacho, setTipoDespacho] = useState("");
    const [tamanoDespacho, setTamanoDespacho] = useState("");
    const [tipoDerecho, setTipoDerecho] = useState("");
    const [visibilidad, setVisibilidad] = useState(true)
    const [tieneValoraciones, setTieneValoraciones] = useState(false)
    const [rangoSalarial, setRangoSalarial] = useState([20, 40])
    const [requiereregistro, setRequiereRegistro] = useState(true)
    const [requiereperfil, setRequierePerfil] = useState(true)
    const [currentUser, setCurrentUser] = useState({});



const [area1, setArea1] = useState("");
const [area2, setArea2] = useState("");
const [area3, setArea3] = useState("");
const [experiencia1, setExperiencia1] = useState("");
const [experiencia2, setExperiencia2] = useState("");
const [enlace, setEnlace] = useState("");

const [firms, setFirms] = useState([]);

  const [comentarios, setComentarios] = useState([]);
  const [tipoDeTrabajo, setTipoDeTrabajo] = useState();
  const [ambiente, setAmbiente] = useState();
  const [positivo, setPositivo] = useState([]);
  const [negativo, setNegativo] = useState([]);
  const [general, setGeneral] = useState();
  const [conciliacion, setConciliacion] = useState(); 
  const [trabajoAtractivo, setTrabajoAtractivo] = useState();
  const [trabajoVariado, setTrabajoVariado] = useState();
  const [carrera, setCarrera] = useState();
  const [formacionTotal, setFormacionTotal] = useState();
  const [diversidadTotal, setDiversidadTotal] = useState();
  const [valores, setValores] = useState();
  const [proBono, setProBono] = useState();
  const [igualdadTotal, setIgualdadTotal] = useState();
  const [techos, setTechos] = useState();
  const [cincoAnos, setCincoAnos] = useState();

  const [rangoSalarial1, setRangoSalarial1] = useState([]);
  const [rangoSalarial2, setRangoSalarial2] = useState([]);
  const [notas, setNotas] = useState();
  const [teletrabajo, setTeletrabajo] = useState();
  const [jobId, setJobId] = useState("");
    
    const [message, setMessage] = useState({error: false, msg: ""});
    
    
    async function handleUserLoggedIn(user) {
      setCurrentUser(user);
      console.log("Estás registrado");
      


      
     }

  
     function handleUserNotRegistered(user) {
      navigate("../signin");
  
     }
     
     function handleUserNotLoggedIn() {
      navigate("../");
     }


    const handleChangeExperiencia = (event, newExperiencia) => {
      setExperiencia(newExperiencia);
    };

    const handleChangeRangoSalarial = (event, newRangoSalarial) => {
      setRangoSalarial(newRangoSalarial);
    };


    const selectedJob = JobList.filter(object => {
      const id = object.id;
      const correct = params.jobId;
      return id === correct;
    });
  
    const [trabajos, setTrabajos] = useState([]);
    useEffect(() => {
    getTrabajos();
    console.log(params.jobId);
  }, [])
  
  const getTrabajos = async () => {
    const data = await TrabajoDataService.getAllTrabajos();
    console.log(data.docs);
    setTrabajos(data.docs.map((doc) => ({...doc.data(), id: doc.id})))
  
  }
  
  const selectedTrabajo = trabajos.filter((doc => doc.id===params.jobId));

  const docRef = doc(db, "trabajos", params.jobId)

console.log(selectedTrabajo);
const jobmy = selectedTrabajo[0]


  useEffect(() => {
    getDoc(docRef)
    .then((doc) => {
    
    const objeto = doc.data();
    setTitulo(objeto.titulo);
    setDespacho(objeto.despacho);
    setArea(objeto.area);
    setCiudad(objeto.ciudad);
    setTexto(objeto.texto);
    const numeroAreasPractica = objeto.area.length;
    setArea1(objeto.area[0].title);
    if (numeroAreasPractica > 1)
    {setArea2(objeto.area[1].title);}
    if (numeroAreasPractica > 2)
    {setArea3(objeto.area[2].title);}

    setExperiencia1(objeto.experiencia[0]);
    setExperiencia2(objeto.experiencia[1]);
    setExperiencia(objeto.experiencia);
    setEnlaceOferta(objeto.enlaceOferta);
    setTieneValoraciones(objeto.tieneValoraciones);
    setTipoDespacho(objeto.tipoDespacho.title);
    setTamanoDespacho(objeto.tamanoDespacho.title);
    setTipoDerecho(objeto.tipoDerecho.title);
    setRangoSalarial(objeto.rangoSalarial)
    setRangoSalarial1(objeto.rangoSalarial[0]);
    setRangoSalarial2(objeto.rangoSalarial[1]);
    setLogoOferta(objeto.logoOferta)
    setJobId(objeto.id);
    })
    console.log(despacho);
    console.log(rangoSalarial);
  }, [])

  const numeroAreasPractica = area.length;

  console.log(notas)
  console.log(tieneValoraciones)
  console.log(jobId)
  console.log(params.jobId);

const StyledRating = styled(Rating)({
  '& .MuiRating-iconFilled': {
    color: '#037bfc',
  },
  '& .MuiRating-iconHover': {
    color: '#037bfc',
  },
});



const handleSubmit = async (e) => {
  e.preventDefault();
  const newTrabajo = {
      titulo,
      despacho,
      texto,
      ciudad,
      salario,
      area,
      experiencia,
      nota,
      enlaceOferta,
      timestamp: serverTimestamp(),
      logoOferta,
      recomendado,
      tipoDespacho,
      tamanoDespacho,
      tipoDerecho,
      visibilidad,
      tieneValoraciones,
      rangoSalarial,
      requiereregistro,
      requiereperfil,
  }
  console.log(newTrabajo);
  
  try {
      await TrabajoDataService.updateTrabajo(params.jobId, newTrabajo);
      setMessage({error: false, msg: "¡Oferta añadida!"});
      alert("¡Oferta publicada!");
  } catch (err) {
      setMessage({error: true, msg: err.message});
  }
  setTitulo(" ");
  setDespacho(" ");
  setTexto(" ");
  setCiudad(" ");
  setSalario(" ");
  setArea(" ");
  setExperiencia([2,4]);
  setNota(" ");
  setEnlaceOferta(" ");
  setLogoOferta("");
  setRecomendado("");
  setTipoDespacho("");
  setTamanoDespacho("");
  setTipoDerecho("");
  setVisibilidad(true);
  setTieneValoraciones(false);
  setRangoSalarial([20, 40]);
  setRequiereRegistro(true);
  setRequierePerfil(true);
}

    function handleLogo(e) {
      const files = e.target.files;
      const fileReader = new FileReader();

      if(fileReader && files && files.length > 0) {
        fileReader.readAsArrayBuffer(files[0]);
        fileReader.onload = async function(){
          const imageData = fileReader.result;
          const res = await setLogo(despacho, imageData);
          console.log(res);
          
          if (res){
           setLogoOferta(res);
           setHayLogo(true);
          }
        }
      }
    }
console.log(logoOferta);
console.log(tamanoDespacho)

const handleChangeVisibilidad = (event) => {
  setVisibilidad(!visibilidad);
};

const handleChangeRequiereRegistro = (event) => {
  setRequiereRegistro(!requiereregistro);
};

const handleChangeRequierePerfil = (event) => {
  setRequierePerfil(!requiereperfil);
};

const handleChangeTieneValoraciones = (event) => {
  setTieneValoraciones(!tieneValoraciones);
};

console.log(enlaceOferta)
console.log(tamanoDespacho)
console.log(tipoDespacho);
    return (
    <div>
            <AuthProvider 
      onUserLoggedIn={handleUserLoggedIn}
      onUserNotRegistered={handleUserNotRegistered}
      onUserNotLoggedIn={handleUserNotLoggedIn}
      > 
    <Grid container component="main">

        <Grid item xs={12} sm={12} md={12} square>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: "center",
              alignItems: "center",
              bgcolor: 'white',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          >
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{mt: 1, color: "white", bgcolor: "rgba(228, 237, 246, 0.17)", marginTop: "60px", marginBottom: "60px", borderRadius: "25px", padding: "20px", maxWidth: "850px" }}>
            <Image href="./" src="/images/logo.png" style={{width: "130px"}} sx={{marginLeft: "60px", marginRight: "60px"}} />
            <Typography variant="h5" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "0px", textAlign: "center", fontWeight: "bold"}}
              >Edita tu vacante
            </Typography>
            <Typography variant="h6" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "10px", textAlign: "center"}}
              >Si tienes cualquier duda, contacta con nosotros en empresas@jobsandlaw.com.
            </Typography>
            <Divider sx={{paddingTop: "40px"}} >
            <Chip label="Sobre la vacante" sx={{color: "white", bgcolor: "rgba(43, 117, 177, 0.9)"}} />
            </Divider>
            <TextField   
                sx={{input: {backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
                required
                fullWidth
                id="titulo"
                label="Título de la vacante"
                name="titulo"
                autoComplete="titulo"
                value={titulo}
                onChange={(e) => setTitulo(e.target.value)}
              />
              <Autocomplete
        multiple
        sx={{paddingTop: "20px"}}
        id="tags-standard"
        value={area}
        options={listareas}
        getOptionLabel={(option) => {
          if (option.hasOwnProperty('title')) {
            return option.title;
          }
          return option;
        }}
        onChange={(e, value)=> setArea(value)}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label="Áreas de práctica"
            placeholder="Áreas de práctica"
          />
        )}
      />
      <Typography gutterBottom sx={{paddingTop: "40px", paddingBottom: "10px", color: "black"}}>Rango de experiencia</Typography>
      <Slider
        getAriaLabel={() => 'Años de experiencia'}
        value={experiencia}
        onChange={handleChangeExperiencia}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
        step={1}
        min={0}
        max={20}
        marks={anosExperiencia}
      />
  <Stack sx={{paddingBottom: "40px"}}>
</Stack>
                      <TextField   
                sx={{input: {backgroundColor: "whitesmoke", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
                required
                fullWidth
                id="enlace"
                label="Enlace a la oferta"
                name="enlace"
                autoComplete="ciudad"
                value={enlaceOferta}
                onChange={(e) => setEnlaceOferta(e.target.value)}
              />
                   <Typography gutterBottom sx={{paddingTop: "40px", paddingBottom: "10px", color: "black"}}>Texto de la oferta</Typography>
             <CKEditor editor={ClassicEditor} data={texto} onChange={(event, editor) => {
  const data = editor.getData()
  setTexto(data)
}}
/> 
            <Divider sx={{paddingTop: "40px"}} >
            <Chip label="Sobre el despacho" sx={{color: "white", bgcolor: "rgba(43, 117, 177, 0.9)"}} />
            </Divider>
              <TextField   
                sx={{input: {color: "black", backgroundColor: "whitesmoke", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
                required
                fullWidth
                id="despacho"
                label="Nombre del despacho / empresa"
                name="despacho"
                autoComplete="despacho"
                value={despacho}
                onChange={(e) => setDespacho(e.target.value)}
              />
              <Stack direction="row" alignItems="center" spacing={2} sx={{paddingTop: "10px", paddingBottom: "10px"}}>
      {hayLogo ?
      <div>
      <Button variant="contained" 
              component="label" 
              value={logoOferta}
              sx={{ mt: 3, mb: 2, backgroundColor: "rgba(43, 117, 177, 0.9)", color: "white"}}
              
              >
        Cambiar logo
        <input hidden accept="image/*" multiple type="file" onChange={handleLogo}/>
      </Button> 
       <img src= {logoOferta} width="100" height="100"></img></div>:
       <div>
       <Button variant="contained" 
               component="label" 
               sx={{ mt: 3, mb: 2, backgroundColor: "rgba(43, 117, 177, 0.9)", color: "white"}}
               
               >
         Subir logo
         <input hidden accept="image/*" multiple type="file" onChange={handleLogo}/>
       </Button> 
        </div>
      }
    </Stack>
    <Autocomplete
        
        value={tipoDespacho}
        sx={{paddingTop: "20px", paddingBottom: "40px"}}
        id="tags-standard"
        options={tiposdespacho}
        getOptionLabel={(option) => {
          if (option.hasOwnProperty('title')) {
            return option.title;
          }
          return option;
        }}
        onChange={(e, value)=> setTipoDespacho(value)}
        renderInput={(params) => (
          <TextField
            {...params}
            
            variant="standard"
            label="Despacho o empresa"
            placeholder="Despacho o empresa"
            
          />
          )}
          />
          <Autocomplete
        value={tamanoDespacho}
          sx={{paddingTop: "20px", paddingBottom: "40px"}}
          id="tags-standard"
          options={tamanosdespacho}
          getOptionLabel={(option) => {
            if (option.hasOwnProperty('title')) {
              return option.title;
            }
            return option;
          }}
          onChange={(e, value)=> setTamanoDespacho(value)}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Tamaño del despacho"
              placeholder="Tamaño del despacho"
              
            />
            )}
            />
    
              <TextField   
                sx={{input: {color: "black", backgroundColor: "whitesmoke", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
                required
                fullWidth
                id="ciudad"
                label="Ciudad (de la vacante)"
                name="ciudad"
                autoComplete="ciudad"
                value={ciudad}
                onChange={(e) => setCiudad(e.target.value)}
              />
  <Typography gutterBottom sx={{paddingTop: "40px", paddingBottom: "10px", color: "black"}}>Rango salarial</Typography>
<Slider
        getAriaLabel={() => 'Rango salarial'}
        value={rangoSalarial}
        onChange={handleChangeRangoSalarial}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
        step={20}
        min={0}
        max={140}
        marks={salarios}
      />

       

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, backgroundColor: "rgba(43, 117, 177, 0.9)", color: "white"}}

              >
                Guardar cambios
              </Button>
              
            </Box>
          </Box>
        </Grid>
      </Grid>
      </AuthProvider>
    </div>
    
  )
}

export default EditJobLawFirm