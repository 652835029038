import { Container } from '@mui/system'
import React from 'react'
import CssBaseline from '@mui/material/CssBaseline';
import { Typography } from '@mui/material';
import SignInSide from '../components/Signin';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import StarIcon from '@mui/icons-material/Star';
import { Hidden } from '@mui/material';
import { makeStyles } from '@mui/material';
import { blue } from '@mui/material/colors';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import Header from "../components/Header";
import Footer from '../components/Footer';


const theme = createTheme();

function Home() {
  return (
    <div>
    <ThemeProvider theme={theme}>
    <CssBaseline>
    <Header />

    <SignInSide />
    <Box sx={{ display: { xl: 'block', l: "block", m: "none", s:"none", xs: 'none' }}}>
    <Grid container component="main" sx={{ height: '90vh', display: { xl: 'block', l: "block", m: "none", s:"none", xs: 'none' }}}>
      <Grid item xs={12} sm={12} md={12} component={Paper} elevation={0} square>
        <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              bgcolor: 'white',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              height: '90vh',
              justifyContent: "center",
            }}
          >
           <Typography variant="h2" 
              sx={{paddingTop: "10px", color: "rgba(43, 117, 177, 0.9)", textAlign: "center", fontWeight: "bold"}}
              >Líderes en empleo para abogados.
            </Typography>
            <Typography variant="h5" 
              sx={{paddingTop: "10px", color: "rgba(43, 117, 177, 0.9)", textAlign: "center", fontWeight: "bold"}}
              >Único portal de empleo especializado en el sector legal en España.
            </Typography>
        </Box>
      </Grid>
    </Grid>
    
    <Grid container component="main" sx={{ height: '90vh'}}>
      <Grid item xs={12} sm={12} md={12} component={Paper} elevation={0} square>
        <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              bgcolor: 'rgba(43, 117, 177, 0.9)',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              height: '90vh',
              justifyContent: "center",
            }}
          >
           <Typography variant="h2" 
              sx={{paddingTop: "10px", color: "white", textAlign: "center", fontWeight: "bold"}}
              >Por abogados. Para abogados.
            </Typography>
            <Typography variant="h5" 
              sx={{paddingTop: "10px", color: "white", textAlign: "center", fontWeight: "bold"}}
              >Un portal de empleo especializado diseñado por abogados de los principales despachos.
            </Typography>
        </Box>
      </Grid>
    </Grid>  
    <Grid container component="main" sx={{ height: '90vh'}}>
      <Grid item xs={12} sm={12} md={12} component={Paper} elevation={0} square>
        <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              bgcolor: 'white',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              height: '90vh',
              justifyContent: "center",
            }}
          >
           <Typography variant="h2" 
              sx={{paddingTop: "10px", color: "rgba(43, 117, 177, 0.9)", textAlign: "center", fontWeight: "bold"}}
              >Toma decisiones informadas.
            </Typography>
            <Typography variant="h5" 
              sx={{paddingTop: "10px", color: "rgba(43, 117, 177, 0.9)", textAlign: "center", fontWeight: "bold"}}
              >¿Es un buen sitio para trabajar? ¿El despacho se preocupa por la conciliación? ¿El salario es competitivo?
            </Typography>
            <Typography variant="h5" 
              sx={{paddingTop: "10px", color: "rgba(43, 117, 177, 0.9)", textAlign: "center", fontWeight: "bold"}}
              >Te damos toda la información que necesitas para que decidas si aplicar o no a una vacante.
            </Typography>
        </Box>
      </Grid>
    </Grid>
    
    <Grid container component="main" sx={{ height: '90vh'}}>
      <Grid item xs={12} sm={12} md={12} component={Paper} elevation={0} square>
        <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              bgcolor: 'rgba(43, 117, 177, 0.9)',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              height: '90vh',
              justifyContent: "center",
            }}
          >
           <Typography variant="h2" 
              sx={{paddingTop: "10px", color: "white", textAlign: "center", fontWeight: "bold"}}
              >La importancia de dónde empezar.
            </Typography>
            <Typography variant="h5" 
              sx={{paddingTop: "10px", color: "white", textAlign: "center", fontWeight: "bold"}}
              >¿Eres estudiante y quieres empezar tu carrera como abogado? Aplica a los mejores programas de prácticas.
            </Typography>
        </Box>
      </Grid>
    </Grid>
    </Box>
    
    <Box sx={{ display: { xl: 'none', l: "none", m: "block", s:"block", xs: 'block' }}}>
    <Grid container component="main" sx={{ height: '90vh', display: { xl: 'none', l: "none", m: "block", s:"block", xs: 'block' }}}>
      <Grid item xs={12} sm={12} md={12} component={Paper} elevation={0} square>
        <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              bgcolor: 'white',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              height: '90vh',
              justifyContent: "center",
            }}
          >
           <Typography variant="h2" 
              sx={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", color: "rgba(43, 117, 177, 0.9)", textAlign: "center", fontWeight: "bold"}}
              >Líderes en empleo para abogados.
            </Typography>
            <Typography variant="h5" 
              sx={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", color: "rgba(43, 117, 177, 0.9)", textAlign: "center", fontWeight: "bold"}}
              >Único portal de empleo especializado en el sector legal en España.
            </Typography>
        </Box>
      </Grid>
    </Grid>
    
    <Grid container component="main" sx={{ height: '90vh'}}>
      <Grid item xs={12} sm={12} md={12} component={Paper} elevation={0} square>
        <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              bgcolor: 'rgba(43, 117, 177, 0.9)',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              height: '90vh',
              justifyContent: "center",
            }}
          >
           <Typography variant="h2" 
              sx={{paddingTop: "10px",paddingLeft: "25px", paddingRight: "25px", color: "white", textAlign: "center", fontWeight: "bold"}}
              >Por abogados. Para abogados.
            </Typography>
            <Typography variant="h5" 
              sx={{paddingTop: "10px",paddingLeft: "25px", paddingRight: "25px", color: "white", textAlign: "center", fontWeight: "bold"}}
              >Un portal de empleo especializado diseñado por abogados de los principales despachos.
            </Typography>
        </Box>
      </Grid>
    </Grid>  
    <Grid container component="main" sx={{ height: '90vh'}}>
      <Grid item xs={12} sm={12} md={12} component={Paper} elevation={0} square>
        <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              bgcolor: 'white',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              height: '90vh',
              justifyContent: "center",
            }}
          >
           <Typography variant="h2" 
              sx={{paddingTop: "10px",paddingLeft: "25px", paddingRight: "25px", color: "rgba(43, 117, 177, 0.9)", textAlign: "center", fontWeight: "bold"}}
              >Toma decisiones informadas.
            </Typography>
            <Typography variant="h5" 
              sx={{paddingTop: "10px",paddingLeft: "25px", paddingRight: "25px", color: "rgba(43, 117, 177, 0.9)", textAlign: "center", fontWeight: "bold"}}
              >¿Es un buen sitio para trabajar? ¿El despacho se preocupa por la conciliación? ¿El salario es competitivo?
            </Typography>
            <Typography variant="h5" 
              sx={{paddingTop: "10px",paddingLeft: "25px", paddingRight: "25px", color: "rgba(43, 117, 177, 0.9)", textAlign: "center", fontWeight: "bold"}}
              >Te damos toda la información que necesitas para que decidas si aplicar o no a una vacante.
            </Typography>
        </Box>
      </Grid>
    </Grid>
    
    <Grid container component="main" sx={{ height: '90vh'}}>
      <Grid item xs={12} sm={12} md={12} component={Paper} elevation={0} square>
        <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              bgcolor: 'rgba(43, 117, 177, 0.9)',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              height: '90vh',
              justifyContent: "center",
            }}
          >
           <Typography variant="h2" 
              sx={{paddingTop: "10px",paddingLeft: "25px", paddingRight: "25px", color: "white", textAlign: "center", fontWeight: "bold"}}
              >La importancia de dónde empezar.
            </Typography>
            <Typography variant="h5" 
              sx={{paddingTop: "10px",paddingLeft: "25px", paddingRight: "25px", color: "white", textAlign: "center", fontWeight: "bold"}}
              >¿Eres estudiante y quieres empezar tu carrera como abogado? Aplica a los mejores programas de prácticas.
            </Typography>
        </Box>
      </Grid>
    </Grid>
    </Box>  
    <Footer />
      </CssBaseline>
    </ThemeProvider>
    </div>
  )
}

export default Home