import React, { useEffect} from 'react';
import Header from '../components/Header'
import CssBaseline from '@mui/material/CssBaseline';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { CardActionArea } from '@mui/material';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import { Link as RouterLink } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import AuthProvider from '../components/authProvider';
import { useNavigate } from 'react-router';
import { useState } from 'react';
import Button from '@mui/material/Button';
import {v4 as uuidv4} from "uuid";
import { getPerfil, insertNewPerfil, updatePerfil } from '../firebase-config';
import Perfil from '../components/perfil';
import IconButton from '@mui/material/IconButton';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import listareas from './listareas';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Slider from '@mui/material/Slider';
import parse from "html-react-parser";
import getPaymentsByUID from '../components/getPaymentsByUID';


function PerfilProfesional() {
  
    const options = ['Perfil abierto (datos visibles para despachos, empresas y headhunters)', 'Perfil anónimo (datos identificativos ocultos)', "Perfil oculto"];
    const [value, setValue] = React.useState(options[0]);
    const [inputValue, setInputValue] = React.useState('');
    const [currentUser, setCurrentUser] = useState({});
    const [nombre, setNombre] = useState(currentUser.displayName);
    const [email, setEmail] = useState("");
    const [telefono, setTelefono] = useState("");
    const [ciudad, setCiudad] = useState("");
    const [perfiles, setPerfiles] = useState([ ]);
    const [visibilidad, setVisibilidad] = useState("Perfil abierto (datos visibles para despachos, empresas y headhunters)");
    const [carrera1, setCarrera1] = useState("");
    const [uni1, setUni1] = useState("");
    const [fechaInicio1, setFechaInicio1] = useState("");
    const [fechaFin1, setFechaFin1] = useState("");
    const [notaMedia1, setNotaMedia1] = useState("");
    const [carrera2, setCarrera2] = useState("");
    const [uni2, setUni2] = useState("");
    const [fechaInicio2, setFechaInicio2] = useState("");
    const [fechaFin2, setFechaFin2] = useState("");
    const [notaMedia2, setNotaMedia2] = useState("");
    const [carrera3, setCarrera3] = useState("");
    const [uni3, setUni3] = useState("");
    const [fechaInicio3, setFechaInicio3] = useState("");
    const [fechaFin3, setFechaFin3] = useState("");
    const [notaMedia3, setNotaMedia3] = useState("");
    const [carrera4, setCarrera4] = useState("");
    const [uni4, setUni4] = useState("");
    const [fechaInicio4, setFechaInicio4] = useState("");
    const [fechaFin4, setFechaFin4] = useState("");
    const [notaMedia4, setNotaMedia4] = useState("");
    const [notaMedia5, setNotaMedia5] = useState("");
    const [notaMedia6, setNotaMedia6] = useState("");
    const [notaMedia7, setNotaMedia7] = useState("");
    const [notaMedia8, setNotaMedia8] = useState("");
    const [carrera5, setCarrera5] = useState("");
    const [uni5, setUni5] = useState("");
    const [fechaInicio5, setFechaInicio5] = useState("");
    const [fechaFin5, setFechaFin5] = useState("");
    const [carrera6, setCarrera6] = useState("");
    const [uni6, setUni6] = useState("");
    const [fechaInicio6, setFechaInicio6] = useState("");
    const [fechaFin6, setFechaFin6] = useState("");
    const [carrera7, setCarrera7] = useState("");
    const [uni7, setUni7] = useState("");
    const [fechaInicio7, setFechaInicio7] = useState("");
    const [fechaFin7, setFechaFin7] = useState("");
    const [carrera8, setCarrera8] = useState("");
    const [uni8, setUni8] = useState("");
    const [fechaInicio8, setFechaInicio8] = useState("");
    const [fechaFin8, setFechaFin8] = useState("");
    const [carrera9, setCarrera9] = useState("");
    const [uni9, setUni9] = useState("");
    const [fechaInicio9, setFechaInicio9] = useState("");
    const [fechaFin9, setFechaFin9] = useState("");
    const [numGrados, setnumGrados] = useState(1);
    const [numPosGrados, setnumPosGrados] = useState(1);
    const [trabajo1, setTrabajo1] = useState("");
    const [empresa1, setEmpresa1] = useState("");
    const [fechaInicioT1, setFechaInicioT1] = useState("");
    const [fechaFinT1, setFechaFinT1] = useState("");
    const [trabajo2, setTrabajo2] = useState("");
    const [empresa2, setEmpresa2] = useState("");
    const [fechaInicioT2, setFechaInicioT2] = useState("");
    const [fechaFinT2, setFechaFinT2] = useState("");
    const [trabajo3, setTrabajo3] = useState("");
    const [empresa3, setEmpresa3] = useState("");
    const [fechaInicioT3, setFechaInicioT3] = useState("");
    const [fechaFinT3, setFechaFinT3] = useState("");
    const [trabajo4, setTrabajo4] = useState("");
    const [empresa4, setEmpresa4] = useState("");
    const [fechaInicioT4, setFechaInicioT4] = useState("");
    const [fechaFinT4, setFechaFinT4] = useState("");
    const [trabajo5, setTrabajo5] = useState("");
    const [empresa5, setEmpresa5] = useState("");
    const [fechaInicioT5, setFechaInicioT5] = useState("");
    const [fechaFinT5, setFechaFinT5] = useState("");
    const [trabajo6, setTrabajo6] = useState("");
    const [empresa6, setEmpresa6] = useState("");
    const [fechaInicioT6, setFechaInicioT6] = useState("");
    const [fechaFinT6, setFechaFinT6] = useState("");
    const [trabajo7, setTrabajo7] = useState("");
    const [empresa7, setEmpresa7] = useState("");
    const [fechaInicioT7, setFechaInicioT7] = useState("");
    const [fechaFinT7, setFechaFinT7] = useState("");
    const [trabajo8, setTrabajo8] = useState("");
    const [empresa8, setEmpresa8] = useState("");
    const [fechaInicioT8, setFechaInicioT8] = useState("");
    const [fechaFinT8, setFechaFinT8] = useState("");
    const [descripcion1, setDescripcion1] = useState("");
    const [descripcion2, setDescripcion2] = useState("");
    const [descripcion3, setDescripcion3] = useState("");
    const [descripcion4, setDescripcion4] = useState("");
    const [descripcion5, setDescripcion5] = useState("");
    const [descripcion6, setDescripcion6] = useState("");
    const [descripcion7, setDescripcion7] = useState("");
    const [descripcion8, setDescripcion8] = useState("");
    const [trabajo9, setTrabajo9] = useState("");
    const [empresa9, setEmpresa9] = useState("");
    const [fechaInicioT9, setFechaInicioT9] = useState("");
    const [fechaFinT9, setFechaFinT9] = useState("");
    const [numTrabajos, setnumTrabajos] = useState(1);
    const [area, setArea] = useState("");
    const [castellano, setCastellano] = useState("");
    const [ingles, setIngles] = useState("");
    const [presentacion, setPresentacion] = useState("");
    const [anos, setAnos] = useState("");
    const [idioma1, setIdioma1] = useState("");
    const [nivelIdioma1, setNivelIdioma1] = useState("");
    const [idioma2, setIdioma2] = useState("");
    const [nivelIdioma2, setNivelIdioma2] = useState("");
    const navigate = useNavigate();
    const initialText = 'Guardar cambios';
    const [buttonText, setButtonText] = useState(initialText);
    const [payments, setPayments] = useState([]);
    const [payment1, setPayment1] = useState([]);
    const [payment2, setPayment2] = useState([]);
    const [isPremium, setIsPremium] = useState(false);
    const [isOldPremium, setIsOldPremium] = useState(false);

    const anosExperiencia = [
      {
        value: 0,
        label: '0',
      },
      {
        value: 1,
        label: '1',
      },
      {
        value: 2,
        label: '2',
      },
      {
        value: 3,
        label: '3',
      },
      {
        value: 4,
        label: '4',
      },
      {
        value: 5,
        label: '5',
      },
      {
        value: 6,
        label: '6',
      },
      {
        value: 7,
        label: '7',
      },
      {
        value: 8,
        label: '8',
      },
      {
        value: 9,
        label: '9',
      },
      {
        value: 10,
        label: '10',
      },
      {
        value: 11,
        label: '11',
      },
      {
        value: 12,
        label: '12',
      },
      {
        value: 13,
        label: '13',
      },
      {
        value: 14,
        label: '14',
      },
      {
        value: 15,
        label: '15',
      },
      {
        value: 16,
        label: '16',
      },
      {
        value: 17,
        label: '17',
      },
      {
        value: 18,
        label: '18',
      },
      {
        value: 19,
        label: '19',
      },
      {
        value: 20,
        label: '+20',
      },
    ];

    const anosExperienciaMovil = [
      {
        value: 0,
        label: '0',
      },
      {
        value: 5,
        label: '5',
      },
      {
        value: 10,
        label: '10',
      },
      {
        value: 15,
        label: '15',
      },
      {
        value: 20,
        label: '+20',
      },
    ];

    function valuetext(value: number) {
      return `${value}k`;
    }

    async function handleUserLoggedIn(user) {
      setCurrentUser(user);
      console.log("Estás registrado");
      console.log(user.displayName);
      console.log(perfiles.length);
      const resPerfil = await getPerfil(user.uid);
      setPerfiles([...resPerfil]);
      console.log(perfiles); 
      console.log(perfiles[0]);
      const payments = await getPaymentsByUID(user.uid);
      setPayments([...payments])
      console.log(perfiles);
      console.log()
      setIsOldPremium(user.premiumUser)


      console.log(perfiles.nombre);
      payments.map((payment) => {
        payment1.push(payment.status)
        payment2.push(payment.current_period_end)
    
      })
    
    console.log(payment1)
    const premium = (element) => (element === "active" || element === "trialing")
    setIsPremium(payment1.some(premium))
    console.log(isPremium)
     }

     useEffect(() => {
      async function getPayments() {
        if (!currentUser) return;
        console.log("user por usar", currentUser);
        const payments = await getPaymentsByUID(currentUser);
        setPayments(payments);
      }
      getPayments();
      console.log(payments)
      
    }, []);
  
     function handleUserNotRegistered(user) {
      navigate("../signin");
  
     }
     
     function handleUserNotLoggedIn() {
      navigate("../");
     }

     function handleSubmit (e) {
      e.preventDefault();
      addPerfil();
      setButtonText("¡Guardado!");
      if (!isOldPremium && !isPremium)
      {
        navigate("../pruebagratis");
      } else {
        navigate("../dashboard");
      }
      
     }

     function addPerfil(){
      const newPerfil = {
        id: uuidv4(),
        nombre: nombre,
        telefono: telefono,
        ciudad: ciudad,
        uid: currentUser.uid,
        visibilidad: visibilidad,
        email: email,
        carrera1: carrera1,
        uni1: uni1,
        fechaInicio1: fechaInicio1,
        fechaFin1: fechaFin1,
        notaMedia1: notaMedia1,
        carrera2: carrera2,
        uni2: uni2,
        fechaInicio2: fechaInicio2,
        fechaFin2: fechaFin2,
        notaMedia2: notaMedia2,
        notaMedia3: notaMedia3,
        notaMedia4: notaMedia4,
        notaMedia5: notaMedia5,
        notaMedia6: notaMedia6,
        notaMedia7: notaMedia7,
        notaMedia8: notaMedia8,
        carrera3: carrera3,
        uni3: uni3,
        fechaInicio3: fechaInicio3,
        fechaFin3: fechaFin3,
        carrera4: carrera4,
        uni4: uni4,
        fechaInicio4: fechaInicio4,
        fechaFin4: fechaFin4,
        carrera5: carrera5,
        uni5: uni5,
        fechaInicio5: fechaInicio5,
        fechaFin5: fechaFin5,
        carrera6: carrera6,
        uni6: uni6,
        fechaInicio6: fechaInicio6,
        fechaFin6: fechaFin6,
        carrera7: carrera7,
        uni7: uni7,
        fechaInicio7: fechaInicio7,
        fechaFin7: fechaFin7,
        carrera8: carrera8,
        uni8: uni8,
        fechaInicio8: fechaInicio8,
        fechaFin8: fechaFin8,
        carrera9: carrera9,
        uni9: uni9,
        fechaInicio9: fechaInicio9,
        fechaFin9: fechaFin9,
        trabajo1: trabajo1,
        empresa1: empresa1,
        fechaInicioT1: fechaInicioT1,
        fechaFinT1: fechaFinT1,
        descripcion1: descripcion1,
        trabajo2: trabajo2,
        empresa2: empresa2,
        fechaInicioT2: fechaInicioT2,
        fechaFinT2: fechaFinT2,
        descripcion2: descripcion2,
        trabajo3: trabajo3,
        empresa3: empresa3,
        fechaInicioT3: fechaInicioT3,
        fechaFinT3: fechaFinT3,
        descripcion3: descripcion3,
        trabajo4: trabajo4,
        empresa4: empresa4,
        fechaInicioT4: fechaInicioT4,
        fechaFinT4: fechaFinT4,
        descripcion4: descripcion4,
        trabajo5: trabajo5,
        empresa5: empresa5,
        fechaInicioT5: fechaInicioT5,
        fechaFinT5: fechaFinT5,
        descripcion5: descripcion5,
        trabajo6: trabajo6,
        empresa6: empresa6,
        fechaInicioT6: fechaInicioT6,
        fechaFinT6: fechaFinT6,
        descripcion6: descripcion6,
        trabajo7: trabajo7,
        empresa7: empresa7,
        fechaInicioT7: fechaInicioT7,
        fechaFinT7: fechaFinT7,
        descripcion7: descripcion7,
        trabajo8: trabajo8,
        empresa8: empresa8,
        fechaInicioT8: fechaInicioT8,
        fechaFinT8: fechaFinT8,
        descripcion8: descripcion8,
        numGrados: numGrados,
        numPosGrados: numPosGrados,
        numTrabajos: numTrabajos, 
        area: area,
        castellano: castellano,
        ingles: ingles,
        presentacion: presentacion,
        anos: anos,
        idioma1: idioma1,
        nivelIdioma1: nivelIdioma1,
        idioma2: idioma2,
        nivelIdioma2: nivelIdioma2, 
 
      };
      const res = insertNewPerfil(newPerfil);
      newPerfil.docId = res.id;
      setPerfiles([...perfiles, newPerfil]);
     }

     function handleChange(e) {
      const value = e.target.value;
      if(e.target.name === "nombre"){
        setNombre(value)
        console.log(nombre);
      }
      if(e.target.name === "telefono"){
        setTelefono(value)
        console.log(telefono)
      }
      if(e.target.name === "ciudad"){
        setCiudad(value)
        console.log(ciudad)
      }
      if(e.target.name === "email"){
        setEmail(value)
      }
      if(e.target.name === "carrera1"){
        setCarrera1(value)
      }
      if(e.target.name === "uni1"){
        setUni1(value)
      }
      if(e.target.name === "fechaInicio1"){
        setFechaInicio1(value)
      }
      if(e.target.name === "fechaFin1"){
        setFechaFin1(value)
      }
      if(e.target.name === "notaMedia1"){
        setNotaMedia1(value)
      }
      if(e.target.name === "carrera2"){
        setCarrera2(value)
      }
      if(e.target.name === "uni2"){
        setUni2(value)
      }
      if(e.target.name === "fechaInicio2"){
        setFechaInicio2(value)
      }
      if(e.target.name === "fechaFin2"){
        setFechaFin2(value)
      }
      if(e.target.name === "notaMedia2"){
        setNotaMedia2(value)
      }
      if(e.target.name === "notaMedia3"){
        setNotaMedia3(value)
      }
      if(e.target.name === "notaMedia4"){
        setNotaMedia4(value)
      }
      if(e.target.name === "notaMedia5"){
        setNotaMedia5(value)
      }
      if(e.target.name === "notaMedia6"){
        setNotaMedia6(value)
      }
      if(e.target.name === "notaMedia7"){
        setNotaMedia7(value)
      }
      if(e.target.name === "notaMedia8"){
        setNotaMedia8(value)
      }
      if(e.target.name === "carrera3"){
        setCarrera3(value)
      }
      if(e.target.name === "uni3"){
        setUni3(value)
      }
      if(e.target.name === "fechaInicio3"){
        setFechaInicio3(value)
      }
      if(e.target.name === "fechaFin3"){
        setFechaFin3(value)
      }
      if(e.target.name === "carrera4"){
        setCarrera4(value)
      }
      if(e.target.name === "uni4"){
        setUni4(value)
      }
      if(e.target.name === "fechaInicio4"){
        setFechaInicio4(value)
      }
      if(e.target.name === "fechaFin4"){
        setFechaFin4(value)
      }
      if(e.target.name === "carrera5"){
        setCarrera5(value)
      }
      if(e.target.name === "uni5"){
        setUni5(value)
      }
      if(e.target.name === "fechaInicio5"){
        setFechaInicio5(value)
      }
      if(e.target.name === "fechaFin5"){
        setFechaFin5(value)
      }
      if(e.target.name === "carrera6"){
        setCarrera6(value)
      }
      if(e.target.name === "uni6"){
        setUni6(value)
      }
      if(e.target.name === "fechaInicio6"){
        setFechaInicio6(value)
      }
      if(e.target.name === "fechaFin6"){
        setFechaFin6(value)
      }
      if(e.target.name === "carrera7"){
        setCarrera7(value)
      }
      if(e.target.name === "uni7"){
        setUni7(value)
      }
      if(e.target.name === "fechaInicio7"){
        setFechaInicio7(value)
      }
      if(e.target.name === "fechaFin7"){
        setFechaFin7(value)
      }
      if(e.target.name === "carrera8"){
        setCarrera8(value)
      }
      if(e.target.name === "uni8"){
        setUni8(value)
      }
      if(e.target.name === "fechaInicio8"){
        setFechaInicio8(value)
      }
      if(e.target.name === "fechaFin8"){
        setFechaFin8(value)
      }
      if(e.target.name === "carrera9"){
        setCarrera9(value)
      }
      if(e.target.name === "uni9"){
        setUni9(value)
      }
      if(e.target.name === "fechaInicio9"){
        setFechaInicio9(value)
      }
      if(e.target.name === "fechaFin9"){
        setFechaFin9(value)
      }
      if(e.target.name === "trabajo1"){
        setTrabajo1(value)
      }
      if(e.target.name === "empresa1"){
        setEmpresa1(value)
      }
      if(e.target.name === "fechaInicioT1"){
        setFechaInicioT1(value)
      }
      if(e.target.name === "fechaFinT1"){
        setFechaFinT1(value)
      }
      if(e.target.name === "descripcion1"){
        setDescripcion1(value)
      }
      if(e.target.name === "trabajo2"){
        setTrabajo2(value)
      }
      if(e.target.name === "empresa2"){
        setEmpresa2(value)
      }
      if(e.target.name === "fechaInicioT2"){
        setFechaInicioT2(value)
      }
      if(e.target.name === "fechaFinT2"){
        setFechaFinT2(value)
      }
      if(e.target.name === "descripcion2"){
        setDescripcion2(value)
      }
      if(e.target.name === "trabajo3"){
        setTrabajo3(value)
      }
      if(e.target.name === "empresa3"){
        setEmpresa3(value)
      }
      if(e.target.name === "fechaInicioT3"){
        setFechaInicioT3(value)
      }
      if(e.target.name === "fechaFinT3"){
        setFechaFinT3(value)
      }
      if(e.target.name === "descripcion3"){
        setDescripcion3(value)
      }
      if(e.target.name === "trabajo4"){
        setTrabajo4(value)
      }
      if(e.target.name === "empresa4"){
        setEmpresa4(value)
      }
      if(e.target.name === "fechaInicioT4"){
        setFechaInicioT4(value)
      }
      if(e.target.name === "fechaFinT4"){
        setFechaFinT4(value)
      }
      if(e.target.name === "descripcion4"){
        setDescripcion4(value)
      }
      if(e.target.name === "trabajo5"){
        setTrabajo5(value)
      }
      if(e.target.name === "empresa5"){
        setEmpresa5(value)
      }
      if(e.target.name === "fechaInicioT5"){
        setFechaInicioT5(value)
      }
      if(e.target.name === "fechaFinT5"){
        setFechaFinT5(value)
      }
      if(e.target.name === "descripcion5"){
        setDescripcion5(value)
      }
      if(e.target.name === "trabajo6"){
        setTrabajo6(value)
      }
      if(e.target.name === "empresa6"){
        setEmpresa6(value)
      }
      if(e.target.name === "fechaInicioT6"){
        setFechaInicioT6(value)
      }
      if(e.target.name === "fechaFinT6"){
        setFechaFinT6(value)
      }
      if(e.target.name === "descripcion6"){
        setDescripcion6(value)
      }
      if(e.target.name === "trabajo7"){
        setTrabajo7(value)
      }
      if(e.target.name === "empresa7"){
        setEmpresa7(value)
      }
      if(e.target.name === "fechaInicioT7"){
        setFechaInicioT7(value)
      }
      if(e.target.name === "fechaFinT7"){
        setFechaFinT7(value)
      }
      if(e.target.name === "descripcion7"){
        setDescripcion7(value)
      }
      if(e.target.name === "trabajo8"){
        setTrabajo8(value)
      }
      if(e.target.name === "empresa8"){
        setEmpresa8(value)
      }
      if(e.target.name === "fechaInicioT8"){
        setFechaInicioT8(value)
      }
      if(e.target.name === "fechaFinT8"){
        setFechaFinT8(value)
      }
      if(e.target.name === "descripcion8"){
        setDescripcion8(value)
      }
      if(e.target.name === "presentacion"){
        setPresentacion(value)
      }
      if(e.target.name === "idioma1"){
        setIdioma1(value)
      }
      if(e.target.name === "nivelIdioma1"){
        setNivelIdioma1(value)
      }
      if(e.target.name === "idioma2"){
        setIdioma2(value)
      }
      if(e.target.name === "nivelIdioma2"){
        setNivelIdioma2(value)
      }
     }

     
     const handleChangeCastellano = (event) => {
      setCastellano(event.target.value);
      console.log(event.target.value);
     }

     const handleChangeIngles = (event) => {
      setIngles(event.target.value);
      console.log(event.target.value);
     }

     const handleChangeNivelIdioma1 = (event) => {
      setNivelIdioma1(event.target.value);
   
     }

     const handleChangeNivelIdioma2 = (event) => {
      setNivelIdioma2(event.target.value);
   
     }
     
     function handleButtonAddGrado () {
      const newnumGrados = numGrados + 1;
      setnumGrados(newnumGrados);
      console.log(numGrados);
     }

     function handleButtonRemoveGrado () {
      const newnumGrados = numGrados - 1;
      setnumGrados(newnumGrados);
      console.log(numGrados);
     }

     function handleButtonRemoveGrado2 () {
      const newNumGrados = numGrados - 1;
      setnumGrados(newNumGrados);
      setCarrera2(""); 
        setUni2("");
        setFechaInicio2(""); 
        setFechaFin2("");
        setNotaMedia2("");
     }

     function handleButtonRemoveGrado3 () {
      const newNumGrados = numGrados - 1;
      setnumGrados(newNumGrados);
      setCarrera3(""); 
        setUni3("");
        setFechaInicio3(""); 
        setFechaFin3("");
        setNotaMedia3("");
     }
     function handleButtonRemoveGrado4 () {
      const newNumGrados = numGrados - 1;
      setnumGrados(newNumGrados);
      setCarrera4(""); 
        setUni4("");
        setFechaInicio4(""); 
        setFechaFin4("");
        setNotaMedia4("");
     }

     function handleButtonAddPosGrado () {
      const newnumPosGrados = numPosGrados + 1;
      setnumPosGrados(newnumPosGrados);
      console.log(numGrados);
     }

     function handleButtonRemovePosGrado () {
      const newnumPosGrados = numPosGrados - 1;
      setnumPosGrados(newnumPosGrados);
      console.log(numGrados);
     }

     function handleButtonRemovePosGrado2 () {
      const newNumPosGrados = numPosGrados - 1;
      setnumPosGrados(newNumPosGrados);
      setCarrera6(""); 
        setUni6("");
        setFechaInicio6(""); 
        setFechaFin6("");
        setNotaMedia6("");
     }
     function handleButtonRemovePosGrado3 () {
      const newNumPosGrados = numPosGrados - 1;
      setnumPosGrados(newNumPosGrados);
      setCarrera7(""); 
        setUni7("");
        setFechaInicio7(""); 
        setFechaFin7("");
        setNotaMedia7("");
     }

     function handleButtonRemovePosGrado4 () {
      const newNumPosGrados = numPosGrados - 1;
      setnumPosGrados(newNumPosGrados);
      setCarrera8(""); 
        setUni8("");
        setFechaInicio8(""); 
        setFechaFin8("");
        setNotaMedia8("");
     }

     function handleButtonAddTrabajo () {
      const newnumTrabajos = numTrabajos + 1;
      setnumTrabajos(newnumTrabajos);
      console.log(numTrabajos);
     }

     function handleButtonRemoveTrabajo () {
      const newnumTrabajos = numTrabajos - 1;
      setnumTrabajos(newnumTrabajos);
      console.log(numGrados);
     }

     function handleButtonRemoveTrabajo2 () {
      const newNumTrabajos = numTrabajos - 1;
      setnumTrabajos(newNumTrabajos);
      setTrabajo2(""); 
        setEmpresa2("");
        setFechaInicioT2(""); 
        setFechaFinT2("");
        setDescripcion2("");
     }

     function handleButtonRemoveTrabajo3 () {
      const newNumTrabajos = numTrabajos - 1;
      setnumTrabajos(newNumTrabajos);
      setTrabajo3(""); 
        setEmpresa3("");
        setFechaInicioT3(""); 
        setFechaFinT3("");
        setDescripcion3("");
     }

     function handleButtonRemoveTrabajo4 () {
      const newNumTrabajos = numTrabajos - 1;
      setnumTrabajos(newNumTrabajos);
      setTrabajo4(""); 
        setEmpresa4("");
        setFechaInicioT4(""); 
        setFechaFinT4("");
        setDescripcion4("");
     }
     function handleButtonRemoveTrabajo5 () {
      const newNumTrabajos = numTrabajos - 1;
      setnumTrabajos(newNumTrabajos);
      setTrabajo5(""); 
        setEmpresa5("");
        setFechaInicioT5(""); 
        setFechaFinT5("");
        setDescripcion5("");
     }
     function handleButtonRemoveTrabajo6 () {
      const newNumTrabajos = numTrabajos - 1;
      setnumTrabajos(newNumTrabajos);
      setTrabajo6(""); 
        setEmpresa6("");
        setFechaInicioT6(""); 
        setFechaFinT6("");
        setDescripcion6("");
     }
     function handleButtonRemoveTrabajo7 () {
      const newNumTrabajos = numTrabajos - 1;
      setnumTrabajos(newNumTrabajos);
      setTrabajo7(""); 
        setEmpresa7("");
        setFechaInicioT7(""); 
        setFechaFinT7("");
        setDescripcion7("");
     }
     function handleButtonRemoveTrabajo8 () {
      const newNumTrabajos = numTrabajos - 1;
      setnumTrabajos(newNumTrabajos);
      setTrabajo8(""); 
        setEmpresa8("");
        setFechaInicioT8(""); 
        setFechaFinT8("");
        setDescripcion8("");
     }

     const handleChangeExperiencia = (event, newAnos) => {
      setAnos(newAnos);
    };



     async function handleUpdatePerfil (docId, 
      nombre, 
      telefono,
      ciudad, 
      visibilidad, 
      email, 
      carrera1, 
      uni1, 
      fechaInicio1, 
      fechaFin1,
      carrera2, 
      uni2, 
      fechaInicio2, 
      fechaFin2,
      carrera3, 
      uni3, 
      fechaInicio3, 
      fechaFin3,
      carrera4, 
      uni4, 
      fechaInicio4, 
      fechaFin4,
      carrera5, 
      uni5, 
      fechaInicio5, 
      fechaFin5,
      carrera6, 
      uni6, 
      fechaInicio6, 
      fechaFin6,
      carrera7, 
      uni7, 
      fechaInicio7, 
      fechaFin7,
      carrera8, 
      uni8, 
      fechaInicio8, 
      fechaFin8,
      carrera9, 
      uni9, 
      fechaInicio9, 
      fechaFin9,
      numGrados,
      numPosGrados,
      numTrabajos,
      area,
      castellano,
      ingles,
      notaMedia1,
      notaMedia2,
      notaMedia3,
      notaMedia4,
      notaMedia5,
      notaMedia6,
      notaMedia7,
      notaMedia8,
      trabajo1,
      empresa1,
      fechaInicioT1,
      fechaFinT1,
      descripcion1,
      trabajo2,
      empresa2,
      fechaInicioT2,
      fechaFinT2,
      descripcion2,
      trabajo3,
      empresa3,
      fechaInicioT3,
      fechaFinT3,
      descripcion3,
      trabajo4,
      empresa4,
      fechaInicioT4,
      fechaFinT4,
      descripcion4,
      trabajo5,
      empresa5,
      fechaInicioT5,
      fechaFinT5,
      descripcion5,
      trabajo6,
      empresa6,
      fechaInicioT6,
      fechaFinT6,
      descripcion6,
      trabajo7,
      empresa7,
      fechaInicioT7,
      fechaFinT7,
      descripcion7,
      trabajo8,
      empresa8,
      fechaInicioT8,
      fechaFinT8,
      descripcion8,
      presentacion,
      anos,
      idioma1,
      nivelIdioma1,
      idioma2,
      nivelIdioma2,
      ) {
      const perfil = perfiles.find((item) => item.docId === docId)
      perfil.nombre = nombre;
      perfil.telefono = telefono;
      perfil.ciudad= ciudad;
      perfil.visibilidad = visibilidad;
      perfil.email = email;
      perfil.notaMedia1 = notaMedia1;
      perfil.carrera1 = carrera1;
      perfil.uni1 = uni1;
      perfil.fechaInicio1 = fechaInicio1;
      perfil.fechaFin1 = fechaFin1;
      perfil.carrera2 = carrera2;
      perfil.uni2 = uni2;
      perfil.fechaInicio2 = fechaInicio2;
      perfil.fechaFin2 = fechaFin2;
      perfil.notaMedia2 = notaMedia2;
      perfil.notaMedia3 = notaMedia3;
      perfil.notaMedia4 = notaMedia4;
      perfil.notaMedia5 = notaMedia5;
      perfil.notaMedia6 = notaMedia6;
      perfil.notaMedia7 = notaMedia7;
      perfil.notaMedia8 = notaMedia8;
      perfil.carrera3 = carrera3;
      perfil.uni3 = uni3;
      perfil.fechaInicio3 = fechaInicio3;
      perfil.fechaFin3 = fechaFin3;
      perfil.carrera4 = carrera4;
      perfil.uni4 = uni4;
      perfil.fechaInicio4 = fechaInicio4;
      perfil.fechaFin4 = fechaFin4;
      perfil.carrera5 = carrera5;
      perfil.uni5 = uni5;
      perfil.fechaInicio5 = fechaInicio5;
      perfil.fechaFin5 = fechaFin5;
      perfil.carrera6 = carrera6;
      perfil.uni6 = uni6;
      perfil.fechaInicio6 = fechaInicio6;
      perfil.fechaFin6 = fechaFin6;
      perfil.carrera7 = carrera7;
      perfil.uni7 = uni7;
      perfil.fechaInicio7 = fechaInicio7;
      perfil.fechaFin7 = fechaFin7;
      perfil.carrera8 = carrera8;
      perfil.uni8 = uni8;
      perfil.fechaInicio8 = fechaInicio8;
      perfil.fechaFin8 = fechaFin8;
      perfil.carrera9 = carrera9;
      perfil.uni9 = uni9;
      perfil.fechaInicio9 = fechaInicio9;
      perfil.fechaFin9 = fechaFin9;
      perfil.trabajo1 = trabajo1;
      perfil.empresa1 = empresa1;
      perfil.fechaInicioT1 = fechaInicioT1;
      perfil.fechaFinT1 = fechaFinT1;
      perfil.descripcion1 = descripcion1;
      perfil.trabajo2 = trabajo2;
      perfil.empresa2 = empresa2;
      perfil.fechaInicioT2 = fechaInicioT2;
      perfil.fechaFinT2 = fechaFinT2;
      perfil.descripcion2 = descripcion2;
      perfil.trabajo3 = trabajo3;
      perfil.empresa3 = empresa3;
      perfil.fechaInicioT3 = fechaInicioT3;
      perfil.fechaFinT3 = fechaFinT3;
      perfil.descripcion3 = descripcion3;
      perfil.trabajo4 = trabajo4;
      perfil.empresa4 = empresa4;
      perfil.fechaInicioT4 = fechaInicioT4;
      perfil.fechaFinT4 = fechaFinT4;
      perfil.descripcion4 = descripcion4;
      perfil.trabajo5 = trabajo5;
      perfil.empresa5 = empresa5;
      perfil.fechaInicioT5 = fechaInicioT5;
      perfil.fechaFinT5 = fechaFinT5;
      perfil.descripcion5 = descripcion5;
      perfil.trabajo6 = trabajo6;
      perfil.empresa6 = empresa6;
      perfil.fechaInicioT6 = fechaInicioT6;
      perfil.fechaFinT6 = fechaFinT6;
      perfil.descripcion6 = descripcion6;
      perfil.trabajo7 = trabajo7;
      perfil.empresa7 = empresa7;
      perfil.fechaInicioT7 = fechaInicioT7;
      perfil.fechaFinT7 = fechaFinT7;
      perfil.descripcion7 = descripcion7;
      perfil.trabajo8 = trabajo8;
      perfil.empresa8 = empresa8;
      perfil.fechaInicioT8 = fechaInicioT8;
      perfil.fechaFinT8 = fechaFinT8;
      perfil.descripcion8 = descripcion8;        
      perfil.numGrados = numGrados;
      perfil.numPosGrados = numPosGrados;
      perfil.numTrabajos = numTrabajos;
      perfil.area = area;
      perfil.castellano = castellano;
      perfil.ingles = ingles;
      perfil.presentacion = presentacion;
      perfil.anos = anos;
      perfil.idioma1 = idioma1;
      perfil.nivelIdioma1 = nivelIdioma1;
      perfil.idioma2 = idioma2;
      perfil.nivelIdioma2 = nivelIdioma2;
      await updatePerfil(docId, perfil);
      setNombre(nombre);
     }

      console.log(numGrados);


    return (
      <AuthProvider 
      onUserLoggedIn={handleUserLoggedIn}
      onUserNotRegistered={handleUserNotRegistered}
      onUserNotLoggedIn={handleUserNotLoggedIn}
      > 
    <CssBaseline>
      
      
      
      <Header />
      <Grid container component="main">
      <Grid item xs={12} sm={12} md={12} component={Paper} elevation={0} square sx={{paddingTop: "10px",  bgcolor: 'rgba(43, 117, 177, 0.9)' }}>
       
      <Stack sx={{ width: '100%', paddingTop: "20px", paddingLeft: "25px", paddingRight: "25px" }} spacing={2}>
      <Typography variant="h4" 
              sx={{paddingTop: "10px", color: "white", textAlign: "center", fontWeight: "bold"}}
              >Tu Perfil Profesional
            </Typography>
            <Typography variant="h6" 
              sx={{paddingBottom: "40px", color: "white", textAlign: "center", fontWeight: "bold"}}
              >Recibe ofertas con total confidencialidad
            </Typography>
              </Stack>
        
             
        
      </Grid>

 
     </Grid>
     <Box sx={{display: { xl: 'block', l: "block", m: "block", s:"none", xs: 'none'}}} >
    {perfiles.length === 0 ? 
     <Grid container component="main" sx={{ }}>
      <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
       
      <Stack sx={{ paddingTop: "20px" }} spacing={2}>
      <Typography variant="h5" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center"}}
              >Introduce tus datos profesionales
            </Typography>
            
              </Stack>
           
           
             <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: "center",
              alignItems: "center",
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          >
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{mt: 1, marginBottom: "60px", borderRadius: "25px", padding: "20px", width: "650px" }}>
        
            <Divider sx={{marginBottom: "20px"}} >
            <Chip label="Visibilidad de tu perfil profesional" sx={{color: "white", bgcolor: "rgba(43, 117, 177, 0.9)"}} />
            </Divider>
              <Autocomplete
        sx={{paddingTop: "20px"}}
        id="tags-standard"
        options={options}
            name="visibilidad"
        onChange={(e, value)=> setVisibilidad(value)}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Visibilidad de tu perfil"
            placeholder="Visibilidad de tu perfil"
          />
        )}
      />
                          <Divider sx={{marginTop: "30px"}} >
            <Chip label="Información general" sx={{color: "white", bgcolor: "rgba(43, 117, 177, 0.9)"}} />
            </Divider>
            
            

            <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="Nombre completo"
                label="Nombre completo"
                name="nombre"
                autoComplete="nombre"
             
                onChange={handleChange}
              />

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="Número de teléfono"
                label="Número de teléfono"
                name="telefono"
                autoComplete="telefono"
            
                onChange={handleChange}
              />
            

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
  
                fullWidth
                id="email"
                label="email"
                name="email"
                autoComplete="email"
             
                onChange={handleChange}
              />

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="Ciudad"
                label="Ciudad"
                name="ciudad"
                autoComplete="ciudad"
            
                onChange={handleChange}
              />

<Divider sx={{marginTop: "30px"}} >
            <Chip label="Formación" sx={{color: "white", bgcolor: "rgba(43, 117, 177, 0.9)"}} />
            </Divider>

            <Stack sx={{ }} spacing={2}>
      <Typography variant="body1" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "10px", textAlign: "center"}}
              >Introduce tus estudios de grado o licenciatura
            </Typography>
            
              </Stack>
            
            <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
       
                fullWidth
                id="carrera1"
                label="Estudios"
                name="carrera1"
                autoComplete="carrera1"
             
                onChange={handleChange}
              />

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
         
                fullWidth
                id="uni1"
                label="Universidad"
                name="uni1"
                autoComplete="uni1"
             
                onChange={handleChange}
              />

<Grid container component="main" columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
         
                fullWidth
                id="fechaInicio1"
                label="Fecha de inicio"
                name="fechaInicio1"
                autoComplete="fechaInicio1"
              
                onChange={handleChange}
              />
        </Grid>

        <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
        
                fullWidth
                id="fechaFin1"
                label="Fecha de fin"
                name="fechaFin1"
                autoComplete="fechaFin1"
              
                onChange={handleChange}
              />
        </Grid>
        </Grid>
        <TextField   
                sx={{width: '28ch', input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
          
                id="notaMedia1"
                label="Nota media en la carrera"
                name="notaMedia1"
                autoComplete="notaMedia1"
             
                onChange={handleChange}
              />
        
            {numGrados === 1 ? <Stack direction="row" spacing={0}>
          <IconButton aria-label="delete" color="primary"  onClick={handleButtonAddGrado}>
          <AddCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center", paddingRight: "40px"}}
              >Añadir nueva formación
            </Typography>
            <IconButton aria-label="delete" color="primary"  onClick={handleButtonRemoveGrado}>
          <RemoveCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center"}}
              >Eliminar formación
            </Typography>
            
        </Stack>:
        <Divider sx={{marginTop: "20px", marginBottom: "10px"}} ></Divider>}


{numGrados > 1 &&
          <div>
          <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
            
                fullWidth
                id="carrera2"
                label="Estudios"
                name="carrera2"
                autoComplete="carrera2"
           
                onChange={handleChange}
              />

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
         
                fullWidth
                id="uni2"
                label="Universidad"
                name="uni2"
                autoComplete="uni2"
             
                onChange={handleChange}
              />

<Grid container component="main" columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
          
                fullWidth
                id="fechaInicio2"
                label="Fecha de inicio"
                name="fechaInicio2"
                autoComplete="fechaInicio2"
             
                onChange={handleChange}
              />
        </Grid>

        <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
          
                fullWidth
                id="fechaFin2"
                label="Fecha de fin"
                name="fechaFin2"
                autoComplete="fechaFin2"
               
                onChange={handleChange}
              />
        </Grid>
        </Grid>
        <TextField   
                sx={{width: '28ch', input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
           
                id="notaMedia2"
                label="Nota media en la carrera"
                name="notaMedia2"
                autoComplete="notaMedia2"
             
                onChange={handleChange}
              />
        
            {numGrados === 2 ? <Stack direction="row" spacing={0}>
          <IconButton aria-label="delete" color="primary"  onClick={handleButtonAddGrado}>
          <AddCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center", paddingRight: "40px"}}
              >Añadir nueva formación
            </Typography>
            <IconButton aria-label="delete" color="primary"  onClick={handleButtonRemoveGrado2}>
          <RemoveCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center"}}
              >Eliminar formación
            </Typography>
            
        </Stack>:
        <Divider sx={{marginTop: "20px", marginBottom: "10px"}} ></Divider>}
       </div>
          
          }

{numGrados > 2 &&
          <div>
          <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
       
                fullWidth
                id="carrera3"
                label="Estudios"
                name="carrera3"
                autoComplete="carrera3"
             
                onChange={handleChange}
              />

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
           
                fullWidth
                id="uni3"
                label="Universidad"
                name="uni3"
                autoComplete="uni3"
        
                onChange={handleChange}
              />

<Grid container component="main" columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
           
                fullWidth
                id="fechaInicio3"
                label="Fecha de inicio"
                name="fechaInicio3"
                autoComplete="fechaInicio3"
             
                onChange={handleChange}
              />
        </Grid>

        <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
          
                fullWidth
                id="fechaFin3"
                label="Fecha de fin"
                name="fechaFin3"
                autoComplete="fechaFin3"
              
                onChange={handleChange}
              />
        </Grid>
        </Grid>
        <TextField   
                sx={{width: '28ch', input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
           
                id="notaMedia3"
                label="Nota media en la carrera"
                name="notaMedia3"
                autoComplete="notaMedia3"
              
                onChange={handleChange}
              />
        
            {numGrados === 3 ? <Stack direction="row" spacing={0}>
          <IconButton aria-label="delete" color="primary"  onClick={handleButtonAddGrado}>
          <AddCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center", paddingRight: "40px"}}
              >Añadir nueva formación
            </Typography>
            <IconButton aria-label="delete" color="primary"  onClick={handleButtonRemoveGrado3}>
          <RemoveCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center"}}
              >Eliminar formación
            </Typography>
            
        </Stack>:
        <Divider sx={{marginTop: "20px", marginBottom: "10px"}} ></Divider>}
        </div>
          }

{numGrados >= 4 &&
          <div>
          <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
         
                fullWidth
                id="carrera4"
                label="Estudios"
                name="carrera4"
                autoComplete="carrera4"
            
                onChange={handleChange}
              />

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
              
                fullWidth
                id="uni4"
                label="Universidad"
                name="uni4"
                autoComplete="uni4"
             
                onChange={handleChange}
              />

<Grid container component="main" columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
           
                fullWidth
                id="fechaInicio4"
                label="Fecha de inicio"
                name="fechaInicio4"
                autoComplete="fechaInicio4"
            
                onChange={handleChange}
              />
        </Grid>

        <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
         
                fullWidth
                id="fechaFin4"
                label="Fecha de fin"
                name="fechaFin4"
                autoComplete="fechaFin4"
             
                onChange={handleChange}
              />
        </Grid>
        </Grid>
        <TextField   
                sx={{width: '28ch', input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
         
                id="notaMedia4"
                label="Nota media en la carrera"
                name="notaMedia4"
                autoComplete="notaMedia4"
             
                onChange={handleChange}
              />
        
            {numGrados === 4 ? <Stack direction="row" spacing={0}>
          <IconButton aria-label="delete" color="primary"  onClick={handleButtonAddGrado}>
          <AddCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center", paddingRight: "40px"}}
              >Añadir nueva formación
            </Typography>
            <IconButton aria-label="delete" color="primary"  onClick={handleButtonRemoveGrado4}>
          <RemoveCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center"}}
              >Eliminar formación
            </Typography>
            
        </Stack>:
        <Divider sx={{marginTop: "20px", marginBottom: "10px"}} ></Divider>}
       </div>
          
          }


<Divider sx={{marginTop: "20px", marginBottom: "10px"}} ></Divider>
<Stack sx={{ }} spacing={2}>
      <Typography variant="body1" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "30px", textAlign: "center"}}
              >Introduce tus estudios de posgrado
            </Typography>
            
              </Stack>
            
            <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
            
                fullWidth
                id="carrera5"
                label="Estudios"
                name="carrera5"
                autoComplete="carrera5"
             
                onChange={handleChange}
              />

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
        
                fullWidth
                id="uni5"
                label="Universidad"
                name="uni5"
                autoComplete="uni5"
             
                onChange={handleChange}
              />

<Grid container component="main" columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
          
                fullWidth
                id="fechaInicio5"
                label="Fecha de inicio"
                name="fechaInicio5"
                autoComplete="fechaInicio5"
              
                onChange={handleChange}
              />
        </Grid>

        <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
      
                fullWidth
                id="fechaFin5"
                label="Fecha de fin"
                name="fechaFin5"
                autoComplete="fechaFin5"
              
                onChange={handleChange}
              />
        </Grid>
        </Grid>
        <TextField   
                sx={{width: '28ch', input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
       
                id="notaMedia5"
                label="Nota media"
                name="notaMedia5"
                autoComplete="notaMedia5"
             
                onChange={handleChange}
              />
        
            {numPosGrados === 1 ? <Stack direction="row" spacing={0}>
          <IconButton aria-label="delete" color="primary"  onClick={handleButtonAddPosGrado}>
          <AddCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center", paddingRight: "40px"}}
              >Añadir nueva formación
            </Typography>
            <IconButton aria-label="delete" color="primary"  onClick={handleButtonRemovePosGrado}>
          <RemoveCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center"}}
              >Eliminar formación
            </Typography>
            
        </Stack>:
        <Divider sx={{marginTop: "20px", marginBottom: "10px"}} ></Divider>}


{numPosGrados > 1 &&
          <div>
          <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
        
                fullWidth
                id="carrera6"
                label="Estudios"
                name="carrera6"
                autoComplete="carrera6"
           
                onChange={handleChange}
              />

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
         
                fullWidth
                id="uni6"
                label="Universidad"
                name="uni6"
                autoComplete="uni6"
             
                onChange={handleChange}
              />

<Grid container component="main" columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
           
                fullWidth
                id="fechaInicio6"
                label="Fecha de inicio"
                name="fechaInicio6"
                autoComplete="fechaInicio6"
             
                onChange={handleChange}
              />
        </Grid>

        <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
           
                fullWidth
                id="fechaFin6"
                label="Fecha de fin"
                name="fechaFin6"
                autoComplete="fechaFin6"
               
                onChange={handleChange}
              />
        </Grid>
        </Grid>
        <TextField   
                sx={{width: '28ch', input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
           
                id="notaMedia6"
                label="Nota media"
                name="notaMedia6"
                autoComplete="notaMedia6"
             
                onChange={handleChange}
              />
        
            {numPosGrados === 2 ? <Stack direction="row" spacing={0}>
          <IconButton aria-label="delete" color="primary"  onClick={handleButtonAddPosGrado}>
          <AddCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center", paddingRight: "40px"}}
              >Añadir nueva formación
            </Typography>
            <IconButton aria-label="delete" color="primary"  onClick={handleButtonRemovePosGrado2}>
          <RemoveCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center"}}
              >Eliminar formación
            </Typography>
            
        </Stack>:
        <Divider sx={{marginTop: "20px", marginBottom: "10px"}} ></Divider>}
       </div>
          
          }

{numPosGrados > 2 &&
          <div>
          <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
          
                fullWidth
                id="carrera7"
                label="Estudios"
                name="carrera7"
                autoComplete="carrera7"
             
                onChange={handleChange}
              />

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
          
                fullWidth
                id="uni7"
                label="Universidad"
                name="uni7"
                autoComplete="uni7"
        
                onChange={handleChange}
              />

<Grid container component="main" columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
         
                fullWidth
                id="fechaInicio7"
                label="Fecha de inicio"
                name="fechaInicio7"
                autoComplete="fechaInicio7"
             
                onChange={handleChange}
              />
        </Grid>

        <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
          
                fullWidth
                id="fechaFin7"
                label="Fecha de fin"
                name="fechaFin7"
                autoComplete="fechaFin7"
              
                onChange={handleChange}
              />
        </Grid>
        </Grid>
        <TextField   
                sx={{width: '28ch', input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
         
                id="notaMedia7"
                label="Nota media"
                name="notaMedia7"
                autoComplete="notaMedia7"
              
                onChange={handleChange}
              />
        
            {numGrados === 3 ? <Stack direction="row" spacing={0}>
          <IconButton aria-label="delete" color="primary"  onClick={handleButtonAddPosGrado}>
          <AddCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center", paddingRight: "40px"}}
              >Añadir nueva formación
            </Typography>
            <IconButton aria-label="delete" color="primary"  onClick={handleButtonRemovePosGrado3}>
          <RemoveCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center"}}
              >Eliminar formación
            </Typography>
            
        </Stack>:
        <Divider sx={{marginTop: "20px", marginBottom: "10px"}} ></Divider>}
        </div>
          }

{numPosGrados >= 4 &&
          <div>
          <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
          
                fullWidth
                id="carrera8"
                label="Estudios"
                name="carrera8"
                autoComplete="carrera8"
            
                onChange={handleChange}
              />

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
        
                fullWidth
                id="uni8"
                label="Universidad"
                name="uni8"
                autoComplete="uni8"
             
                onChange={handleChange}
              />

<Grid container component="main" columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
       
                fullWidth
                id="fechaInicio8"
                label="Fecha de inicio"
                name="fechaInicio8"
                autoComplete="fechaInicio8"
            
                onChange={handleChange}
              />
        </Grid>

        <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
           
                fullWidth
                id="fechaFin8"
                label="Fecha de fin"
                name="fechaFin8"
                autoComplete="fechaFin8"
             
                onChange={handleChange}
              />
        </Grid>
        </Grid>
        <TextField   
                sx={{width: '28ch', input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
        
                id="notaMedia8"
                label="Nota media"
                name="notaMedia8"
                autoComplete="notaMedia8"
             
                onChange={handleChange}
              />
        
            {numPosGrados === 4 ? <Stack direction="row" spacing={0}>
          <IconButton aria-label="delete" color="primary"  onClick={handleButtonAddPosGrado}>
          <AddCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center", paddingRight: "40px"}}
              >Añadir nueva formación
            </Typography>
            <IconButton aria-label="delete" color="primary"  onClick={handleButtonRemovePosGrado4}>
          <RemoveCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center"}}
              >Eliminar formación
            </Typography>
            
        </Stack>:
        <Divider sx={{marginTop: "20px", marginBottom: "10px"}} ></Divider>}
       </div>
          
          }
       
       <Divider sx={{marginTop: "20px"}} >
            <Chip label="Experiencia" sx={{color: "white", bgcolor: "rgba(43, 117, 177, 0.9)"}} />
            </Divider>

            <Stack sx={{ }} spacing={2}>
      <Typography variant="1" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "30px", textAlign: "center", lineHeight: "0px"}}
              >Introduce tus experiencias laborales
            </Typography>
            <Typography variant="subtitle1" color = "rgba(43, 117, 177, 0.9)"
              sx={{textAlign: "center"}}
              >(comenzando por la más reciente / actual)
            </Typography>
            
              </Stack>
           
            <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
         
                fullWidth
                id="trabajo1"
                label="Posición"
                name="trabajo1"
                autoComplete="trabajo1"
             
                onChange={handleChange}
              />

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
          
                fullWidth
                id="empresa1"
                label="Despacho / Empresa"
                name="empresa1"
                autoComplete="empresa1"
             
                onChange={handleChange}
              />

<Grid container component="main" columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
          
                fullWidth
                id="fechaInicioT1"
                label="Fecha de inicio"
                name="fechaInicioT1"
                autoComplete="fechaInicioT1"
              
                onChange={handleChange}
              />
        </Grid>

        <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{marginBottom: "20px"}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
         
                fullWidth
                id="fechaFinT1"
                label="Fecha de fin"
                name="fechaFinT1"
                autoComplete="fechaFinT1"
              
                onChange={handleChange}
              />
        </Grid>
        </Grid>
              <CKEditor  editor={ClassicEditor} data={descripcion1} onChange={(event, editor) => {
  const data = editor.getData()
  setDescripcion1(data)
}}
/> 
        
            {numTrabajos === 1 ? <Stack direction="row" spacing={0}>
          <IconButton aria-label="delete" color="primary"  onClick={handleButtonAddTrabajo}>
          <AddCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center", paddingRight: "40px"}}
              >Añadir nuevo trabajo
            </Typography>
            <IconButton aria-label="delete" color="primary"  onClick={handleButtonRemoveTrabajo}>
          <RemoveCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center"}}
              >Eliminar trabajo
            </Typography>
            
        </Stack>:
        <Divider sx={{marginTop: "20px", marginBottom: "10px"}} ></Divider>}


{numTrabajos > 1 &&
          <div>
          <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
        
                fullWidth
                id="trabajo2"
                label="Posición"
                name="trabajo2"
                autoComplete="trabajo2"
           
                onChange={handleChange}
              />

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
           
                fullWidth
                id="empresa2"
                label="Despacho / Empresa"
                name="empresa2"
                autoComplete="empresa2"
             
                onChange={handleChange}
              />

<Grid container component="main" columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
         
                fullWidth
                id="fechaInicioT2"
                label="Fecha de inicio"
                name="fechaInicioT2"
                autoComplete="fechaInicioT2"
             
                onChange={handleChange}
              />
        </Grid>

        <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{marginBottom: "20px"}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
         
                fullWidth
                id="fechaFinT2"
                label="Fecha de fin"
                name="fechaFinT2"
                autoComplete="fechaFinT2"
               
                onChange={handleChange}
              />
        </Grid>
        </Grid>
        <CKEditor  editor={ClassicEditor} data={descripcion2} onChange={(event, editor) => {
  const data = editor.getData()
  setDescripcion2(data)
}}
/> 
        
            {numTrabajos === 2 ? <Stack direction="row" spacing={0}>
          <IconButton aria-label="delete" color="primary"  onClick={handleButtonAddTrabajo}>
          <AddCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center", paddingRight: "40px"}}
              >Añadir nuevo trabajo
            </Typography>
            <IconButton aria-label="delete" color="primary"  onClick={handleButtonRemoveTrabajo2}>
          <RemoveCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center"}}
              >Eliminar trabajo
            </Typography>
            
        </Stack>:
        <Divider sx={{marginTop: "20px", marginBottom: "10px"}} ></Divider>}
       </div>
          
          }

{numTrabajos > 2 &&
          <div>
          <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
             
                fullWidth
                id="trabajo3"
                label="Posición"
                name="trabajo3"
                autoComplete="trabajo3"
             
                onChange={handleChange}
              />

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
          
                fullWidth
                id="empresa3"
                label="Despacho / Empresa"
                name="empresa3"
                autoComplete="empresa3"
        
                onChange={handleChange}
              />

<Grid container component="main" columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
          
                fullWidth
                id="fechaInicioT3"
                label="Fecha de inicio"
                name="fechaInicioT3"
                autoComplete="fechaInicioT3"
             
                onChange={handleChange}
              />
        </Grid>

        <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{marginBottom: "20px"}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
             
                fullWidth
                id="fechaFinT3"
                label="Fecha de fin"
                name="fechaFinT3"
                autoComplete="fechaFinT3"
              
                onChange={handleChange}
              />
        </Grid>
        </Grid>
        <CKEditor  editor={ClassicEditor} data={descripcion3} onChange={(event, editor) => {
  const data = editor.getData()
  setDescripcion3(data)
}}
/> 
        
            {numTrabajos === 3 ? <Stack direction="row" spacing={0}>
          <IconButton aria-label="delete" color="primary"  onClick={handleButtonAddTrabajo}>
          <AddCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center", paddingRight: "40px"}}
              >Añadir nuevo trabajo
            </Typography>
            <IconButton aria-label="delete" color="primary"  onClick={handleButtonRemoveTrabajo3}>
          <RemoveCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center"}}
              >Eliminar trabajo
            </Typography>
            
        </Stack>:
        <Divider sx={{marginTop: "20px", marginBottom: "10px"}} ></Divider>}
        </div>
          }

{numTrabajos > 3 &&
          <div>
          <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
          
                fullWidth
                id="trabajo4"
                label="Posición"
                name="trabajo4"
                autoComplete="trabajo4"
             
                onChange={handleChange}
              />

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
         
                fullWidth
                id="empresa4"
                label="Despacho / Empresa"
                name="empresa4"
                autoComplete="empresa4"
        
                onChange={handleChange}
              />

<Grid container component="main" columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
         
                fullWidth
                id="fechaInicioT4"
                label="Fecha de inicio"
                name="fechaInicioT4"
                autoComplete="fechaInicioT4"
             
                onChange={handleChange}
              />
        </Grid>

        <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{marginBottom: "20px"}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
        
                fullWidth
                id="fechaFinT4"
                label="Fecha de fin"
                name="fechaFinT4"
                autoComplete="fechaFinT4"
              
                onChange={handleChange}
              />
        </Grid>
        </Grid>
        <CKEditor  editor={ClassicEditor} data={descripcion4} onChange={(event, editor) => {
  const data = editor.getData()
  setDescripcion4(data)
}}
/> 
        
            {numTrabajos === 4 ? <Stack direction="row" spacing={0}>
          <IconButton aria-label="delete" color="primary"  onClick={handleButtonAddTrabajo}>
          <AddCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center", paddingRight: "40px"}}
              >Añadir nuevo trabajo
            </Typography>
            <IconButton aria-label="delete" color="primary"  onClick={handleButtonRemoveTrabajo4}>
          <RemoveCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center"}}
              >Eliminar trabajo
            </Typography>
            
        </Stack>:
        <Divider sx={{marginTop: "20px", marginBottom: "10px"}} ></Divider>}
        </div>
          }


{numTrabajos > 4 &&
          <div>
          <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
        
                fullWidth
                id="trabajo5"
                label="Posición"
                name="trabajo5"
                autoComplete="trabajo5"
             
                onChange={handleChange}
              />

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
          
                fullWidth
                id="empresa5"
                label="Despacho / Empresa"
                name="empresa5"
                autoComplete="empresa5"
        
                onChange={handleChange}
              />

<Grid container component="main" columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
       
                fullWidth
                id="fechaInicioT5"
                label="Fecha de inicio"
                name="fechaInicioT5"
                autoComplete="fechaInicioT5"
             
                onChange={handleChange}
              />
        </Grid>

        <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{marginBottom: "20px"}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
       
                fullWidth
                id="fechaFinT5"
                label="Fecha de fin"
                name="fechaFinT5"
                autoComplete="fechaFinT5"
              
                onChange={handleChange}
              />
        </Grid>
        </Grid>
        <CKEditor  editor={ClassicEditor} data={descripcion5} onChange={(event, editor) => {
  const data = editor.getData()
  setDescripcion5(data)
}}
/> 
        
            {numTrabajos === 5 ? <Stack direction="row" spacing={0}>
          <IconButton aria-label="delete" color="primary"  onClick={handleButtonAddTrabajo}>
          <AddCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center", paddingRight: "40px"}}
              >Añadir nuevo trabajo
            </Typography>
            <IconButton aria-label="delete" color="primary"  onClick={handleButtonRemoveTrabajo5}>
          <RemoveCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center"}}
              >Eliminar trabajo
            </Typography>
            
        </Stack>:
        <Divider sx={{marginTop: "20px", marginBottom: "10px"}} ></Divider>}
        </div>
          }

{numTrabajos > 5 &&
          <div>
          <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
        
                fullWidth
                id="trabajo6"
                label="Posición"
                name="trabajo6"
                autoComplete="trabajo6"
             
                onChange={handleChange}
              />

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
         
                fullWidth
                id="empresa6"
                label="Despacho / Empresa"
                name="empresa6"
                autoComplete="empresa6"
        
                onChange={handleChange}
              />

<Grid container component="main" columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
           
                fullWidth
                id="fechaInicioT6"
                label="Fecha de inicio"
                name="fechaInicioT6"
                autoComplete="fechaInicioT6"
             
                onChange={handleChange}
              />
        </Grid>

        <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{marginBottom: "20px"}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
         
                fullWidth
                id="fechaFinT6"
                label="Fecha de fin"
                name="fechaFinT6"
                autoComplete="fechaFinT6"
              
                onChange={handleChange}
              />
        </Grid>
        </Grid>
        <CKEditor  editor={ClassicEditor} data={descripcion6} onChange={(event, editor) => {
  const data = editor.getData()
  setDescripcion6(data)
}}
/> 
        
            {numTrabajos === 6 ? <Stack direction="row" spacing={0}>
          <IconButton aria-label="delete" color="primary"  onClick={handleButtonAddTrabajo}>
          <AddCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center", paddingRight: "40px"}}
              >Añadir nuevo trabajo
            </Typography>
            <IconButton aria-label="delete" color="primary"  onClick={handleButtonRemoveTrabajo6}>
          <RemoveCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center"}}
              >Eliminar trabajo
            </Typography>
            
        </Stack>:
        <Divider sx={{marginTop: "20px", marginBottom: "10px"}} ></Divider>}
        </div>
          }

{numTrabajos > 6 &&
          <div>
          <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
            
                fullWidth
                id="trabajo7"
                label="Posición"
                name="trabajo7"
                autoComplete="trabajo7"
             
                onChange={handleChange}
              />

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
         
                fullWidth
                id="empresa7"
                label="Despacho / Empresa"
                name="empresa7"
                autoComplete="empresa7"
        
                onChange={handleChange}
              />

<Grid container component="main" columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
          
                fullWidth
                id="fechaInicioT7"
                label="Fecha de inicio"
                name="fechaInicioT7"
                autoComplete="fechaInicioT7"
             
                onChange={handleChange}
              />
        </Grid>

        <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{marginBottom: "20px"}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
        
                fullWidth
                id="fechaFinT7"
                label="Fecha de fin"
                name="fechaFinT7"
                autoComplete="fechaFinT7"
              
                onChange={handleChange}
              />
        </Grid>
        </Grid>
        <CKEditor  editor={ClassicEditor} data={descripcion7} onChange={(event, editor) => {
  const data = editor.getData()
  setDescripcion7(data)
}}
/> 
        
            {numTrabajos === 7 ? <Stack direction="row" spacing={0}>
          <IconButton aria-label="delete" color="primary"  onClick={handleButtonAddTrabajo}>
          <AddCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center", paddingRight: "40px"}}
              >Añadir nuevo trabajo
            </Typography>
            <IconButton aria-label="delete" color="primary"  onClick={handleButtonRemoveTrabajo7}>
          <RemoveCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center"}}
              >Eliminar trabajo
            </Typography>
            
        </Stack>:
        <Divider sx={{marginTop: "20px", marginBottom: "10px"}} ></Divider>}
        </div>
          }

{numTrabajos > 7 &&
          <div>
          <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
           
                fullWidth
                id="trabajo8"
                label="Posición"
                name="trabajo8"
                autoComplete="trabajo8"
             
                onChange={handleChange}
              />

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
         
                fullWidth
                id="empresa8"
                label="Despacho / Empresa"
                name="empresa8"
                autoComplete="empresa8"
        
                onChange={handleChange}
              />

<Grid container component="main" columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
           
                fullWidth
                id="fechaInicioT8"
                label="Fecha de inicio"
                name="fechaInicioT8"
                autoComplete="fechaInicioT8"
             
                onChange={handleChange}
              />
        </Grid>

        <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{marginBottom: "20px"}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
         
                fullWidth
                id="fechaFinT8"
                label="Fecha de fin"
                name="fechaFinT8"
                autoComplete="fechaFinT8"
              
                onChange={handleChange}
              />
        </Grid>
        </Grid>
        <CKEditor  editor={ClassicEditor} data={descripcion8} onChange={(event, editor) => {
  const data = editor.getData()
  setDescripcion8(data)
}}
/> 
        
            {numTrabajos === 8 ? <Stack direction="row" spacing={0}>
          <IconButton aria-label="delete" color="primary"  onClick={handleButtonAddTrabajo}>
          <AddCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center", paddingRight: "40px"}}
              >Añadir nuevo trabajo
            </Typography>
            <IconButton aria-label="delete" color="primary"  onClick={handleButtonRemoveTrabajo8}>
          <RemoveCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center"}}
              >Eliminar trabajo
            </Typography>
            
        </Stack>:
        <Divider sx={{marginTop: "30px", marginBottom: "30px"}} ></Divider>}
        </div>
          }

      <Typography variant="body1" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "50px", textAlign: "center"}}
              >Indica tus áreas de práctica
            </Typography>

       <Autocomplete
          multiple
          sx={{paddingTop: "20px", paddingBottom: "50px"}}
          id="tags-standard"
          options={listareas}
          getOptionLabel={(option) => option.title}
          onChange={(e, value)=> setArea(value)}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Áreas de práctica"
              placeholder="Áreas de práctica"
            />
          )}
        />
      <Typography variant="body1" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "30px", textAlign: "center"}}
              >Indica tus años de experiencia
            </Typography>
<Slider
        getAriaLabel={() => 'Años de experiencia'}
        value={anos}
        onChange={handleChangeExperiencia}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
        step={1}
        min={0}
        max={20}
        marks={anosExperiencia}
      />

<Divider sx={{marginTop: "40px", margintBottom: "15px"}} >
            <Chip label="Idiomas" sx={{color: "white", bgcolor: "rgba(43, 117, 177, 0.9)"}} />
            </Divider>

            <FormControl>
      <FormLabel id="demo-row-radio-buttons-group-label">Castellano</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={castellano}
        onChange={handleChangeCastellano}
      >
        <FormControlLabel value="NA" control={<Radio />} label="N/A" />
        <FormControlLabel value="Básico" control={<Radio />} label="Básico" />
        <FormControlLabel value="Intermedio" control={<Radio />} label="Intermedio" />
        <FormControlLabel value="Avanzado" control={<Radio />} label="Avanzado" />
        <FormControlLabel value="Nativo" control={<Radio />} label="Nativo" />
      </RadioGroup>
    </FormControl>

    <FormControl>
      <FormLabel id="demo-row-radio-buttons-group-label">Inglés</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={ingles}
        onChange={handleChangeIngles}
      >
        <FormControlLabel value="NA" control={<Radio />} label="N/A" />
        <FormControlLabel value="Básico" control={<Radio />} label="Básico" />
        <FormControlLabel value="Intermedio" control={<Radio />} label="Intermedio" />
        <FormControlLabel value="Avanzado" control={<Radio />} label="Avanzado" />
        <FormControlLabel value="Nativo" control={<Radio />} label="Nativo" />
      </RadioGroup>
    </FormControl>
    <Stack sx={{marginTop: "20px"}}></Stack>
    <TextField   
                sx={{width: '28ch', input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
          
                id="idioma1"
                label="Idioma adicional"
                name="idioma1"
                autoComplete="idioma1"
             
                onChange={handleChange}
              />
       <FormControl>
      <FormLabel id="demo-row-radio-buttons-group-label"></FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={nivelIdioma1}
        onChange={handleChangeNivelIdioma1}
      >
        <FormControlLabel value="NA" control={<Radio />} label="N/A" />
        <FormControlLabel value="Básico" control={<Radio />} label="Básico" />
        <FormControlLabel value="Intermedio" control={<Radio />} label="Intermedio" />
        <FormControlLabel value="Avanzado" control={<Radio />} label="Avanzado" />
        <FormControlLabel value="Nativo" control={<Radio />} label="Nativo" />
      </RadioGroup>
    </FormControl>

    <Stack sx={{marginTop: "20px"}}></Stack>
    <TextField   
                sx={{width: '28ch', input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
          
                id="idioma2"
                label="Idioma adicional"
                name="idioma2"
                autoComplete="idioma2"
             
                onChange={handleChange}
              />
        <FormControl>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={nivelIdioma2}
        onChange={handleChangeNivelIdioma2}
      >
        <FormControlLabel value="NA" control={<Radio />} label="N/A" />
        <FormControlLabel value="Básico" control={<Radio />} label="Básico" />
        <FormControlLabel value="Intermedio" control={<Radio />} label="Intermedio" />
        <FormControlLabel value="Avanzado" control={<Radio />} label="Avanzado" />
        <FormControlLabel value="Nativo" control={<Radio />} label="Nativo" />
      </RadioGroup>
    </FormControl>

    <Divider sx={{marginTop: "40px"}} >
            <Chip label="Extras" sx={{color: "white", bgcolor: "rgba(43, 117, 177, 0.9)"}} />
            </Divider>
            <Stack sx={{ paddingTop: "20px" }} spacing={2}>
      <Typography variant="body1" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center"}}
              >Incluye una breve presentación
            </Typography>
            
              </Stack>
    <CKEditor  editor={ClassicEditor} data={presentacion} onChange={(event, editor) => {
  const data = editor.getData()
  setPresentacion(data)
}}
/> 
        

<Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, backgroundColor: "rgba(43, 117, 177, 0.9)", color: "white"}}

              >
                {buttonText}
              </Button>

            </Box>
          </Box>
                    
          
          
        
      </Grid>

      {visibilidad === "Perfil oculto" && 
   <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{paddingTop: "30px", paddingLeft: "5%", paddingRight: "5%"}}>
   <Stack sx={{ paddingTop: "20px" }} spacing={2}>
   <Typography variant="h5" color = "rgba(43, 117, 177, 0.9)"
               sx={{paddingTop: "20px", textAlign: "center"}}
               >Tu perfil está oculto y no pueden verlo ni empresas ni headhunters. Usa este modo solo si no quieres recibir ofertas.
             </Typography>
    </Stack>
    </Grid>
 }
       {visibilidad !== "Perfil oculto" &&
      <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{borderLeft: "1px solid rgba(43, 117, 177, 0.9)"}}>
      <Stack sx={{ paddingTop: "20px" }} spacing={2}>
        
       <Typography variant="h5" color = "rgba(43, 117, 177, 0.9)"
               sx={{paddingTop: "20px", textAlign: "center"}}
               >Así te ven empresas, despachos y headhunters
             </Typography>
             <Typography variant="p" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingBottom: "40px", textAlign: "center"}}
              >¡Tranquil@! Tu actual empleador no podrá ver tu perfil
            </Typography>
               </Stack>
               <Divider >
            
            </Divider>
               <Stack sx={{ paddingTop: "20px" }} spacing={2}>
        
       {visibilidad === "Perfil abierto (datos visibles para despachos, empresas y headhunters)" && <div> <Typography variant="h5" color = "grey"
                sx={{paddingTop: "20px", textAlign: "center"}}
                >{nombre}
              </Typography>
              <Grid container component="main">
      <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
       
      <Stack sx={{ width: '100%'}} spacing={2}>
      {telefono && <Typography variant="subtitle1" 
              sx={{paddingTop: "10px", paddingRight: "50px", color: "grey", textAlign: "right"}}
              >Teléfono: {telefono}
            </Typography>}
              </Stack>
      </Grid>
      <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
       
       <Stack sx={{ width: '100%', paddingBottom: "20px"}} spacing={2}>
       {email && <Typography variant="subtitle1" 
               sx={{paddingTop: "10px", paddingLeft: "10px", color: "grey", textAlign: "left"}}
               >E-mail: {email}
             </Typography>}
               </Stack>
       </Grid>
     </Grid></div>
              }
              {visibilidad === "Perfil anónimo (datos identificativos ocultos)" && <div><Typography variant="h5" color = "grey"
                sx={{paddingTop: "20px", textAlign: "center"}}
                >Perfil anónimo
              </Typography>
              <Typography variant="subtitle1" color = "grey"
              sx={{paddingTop: "20px", textAlign: "center"}}
              >Contacta de forma privada con el candidato a través de Jobs&Law
            </Typography>
              
              </div>}

     {presentacion && 
     <Stack sx={{alignItems: "center", textAlign: "center", paddingBottom: "30px", paddingLeft: "40px", paddingRight:"40px"}} spacing={2}>
      <Typography variant="paragraph" color = "rgba(43, 117, 177, 0.9)"
              sx={{ textAlign: "center", lineHeight:"22px", fontSize: "14px"}}>
                {parse(presentacion)}
            </Typography>
              </Stack>}

     <Divider sx={{marginTop: "30px"}} >
            <Chip label="Formación" sx={{color: "white", bgcolor: "rgba(43, 117, 177, 0.9)"}} />
            </Divider>
            <Grid container component="main">
      
      <Grid item xs={1} sm={1} md={1} component={Paper} elevation={0} square sx={{}}>
      </Grid>
      <Grid item xs={10} sm={10} md={10} component={Paper} elevation={0} square sx={{}}>
       
      <Stack sx={{ width: '100%'}} spacing={2}>
      {carrera1 && <Typography variant="p" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", textAlign: "center", fontWeight: "bold"}}
              >Estudios de grado/licenciatura:
            </Typography>}
              </Stack>
      </Grid>
     </Grid>
          

     <Grid container component="main">
      
      <Grid item xs={1} sm={1} md={1} component={Paper} elevation={0} square sx={{}}>
      </Grid>
      <Grid item xs={10} sm={10} md={10} component={Paper} elevation={0} square sx={{marginBottom: "20px"}}>
       
      <Stack sx={{textAlign: "center", width: '100%'}} spacing={2}>
      {fechaInicio1 && <Typography variant="paragraph" color = "grey"
              sx={{textAlign: "center", lineHeight:"5px", fontSize: "14px"}}
              >{fechaInicio1} / {fechaFin1}
            </Typography>}
     {carrera1 && <Typography variant="paragraph" color = "grey"
              sx={{textAlign: "center", lineHeight:"10px", fontWeight: "bold"}}
              >{carrera1}
            </Typography>}
      {uni1 && <Typography variant="paragraph" color = "grey"
              sx={{ textAlign: "center", lineHeight:"5px"}}
              >{uni1}
            </Typography>}
            {notaMedia1 && <Typography variant="paragraph" color = "grey"
              sx={{ textAlign: "center", lineHeight:"5px", fontSize: "14px"}}
              >Nota media: {notaMedia1}
            </Typography>}
              </Stack>

              {carrera2 && <Stack sx={{textAlign: "center", width: '100%', paddingTop: "30px"}} spacing={2}>
      {fechaInicio2 && <Typography variant="paragraph" color = "grey"
              sx={{paddingTop: "0px", textAlign: "center", lineHeight:"5px", fontSize: "14px"}}
              >{fechaInicio2} / {fechaFin2}
            </Typography>}
     {carrera2 && <Typography variant="paragraph" color = "grey"
              sx={{textAlign: "center", lineHeight:"10px", fontWeight: "bold"}}
              >{carrera2}
            </Typography>}
      {uni2 && <Typography variant="paragraph" color = "grey"
              sx={{ textAlign: "center", lineHeight:"5px"}}
              >{uni2}
            </Typography>}
            {notaMedia2 && <Typography variant="paragraph" color = "grey"
              sx={{ textAlign: "center", lineHeight:"5px", fontSize: "14px"}}
              >Nota media: {notaMedia2}
            </Typography>}
              </Stack>}

              {carrera3 &&<Stack sx={{textAlign: "center", width: '100%', paddingTop: "30px"}} spacing={2}>
      {fechaInicio3 && <Typography variant="paragraph" color = "grey"
              sx={{paddingTop: "0px", textAlign: "center", lineHeight:"5px", fontSize: "14px"}}
              >{fechaInicio3} / {fechaFin3}
            </Typography>}
     {carrera3 && <Typography variant="paragraph" color = "grey"
              sx={{textAlign: "center", lineHeight:"10px", fontWeight: "bold"}}
              >{carrera3}
            </Typography>}
      {uni3 && <Typography variant="paragraph" color = "grey"
              sx={{ textAlign: "center", lineHeight:"5px"}}
              >{uni3}
            </Typography>}
            {notaMedia3 && <Typography variant="paragraph" color = "grey"
              sx={{ textAlign: "center", lineHeight:"5px", fontSize: "14px"}}
              >Nota media: {notaMedia3}
            </Typography>}
              </Stack>}

              {carrera4 && <Stack sx={{textAlign: "center", width: '100%', paddingTop: "30px", paddingBottom: "30px"}} spacing={2}>
      {fechaInicio4 && <Typography variant="paragraph" color = "grey"
              sx={{paddingTop: "0px", textAlign: "center", lineHeight:"5px", fontSize: "14px"}}
              >{fechaInicio4} / {fechaFin4}
            </Typography>}
     {carrera4 && <Typography variant="paragraph" color = "grey"
              sx={{textAlign: "center", lineHeight:"10px", fontWeight: "bold"}}
              >{carrera4}
            </Typography>}
      {uni4 && <Typography variant="paragraph" color = "grey"
              sx={{ textAlign: "center", lineHeight:"5px"}}
              >{uni4}
            </Typography>}
            {notaMedia4 && <Typography variant="paragraph" color = "grey"
              sx={{ textAlign: "center", lineHeight:"5px", fontSize: "14px"}}
              >Nota media: {notaMedia4}
            </Typography>}
              </Stack>}
      </Grid>

      
     </Grid>
     {carrera5 && <div> <Grid container component="main">
      
      <Grid item xs={1} sm={1} md={1} component={Paper} elevation={0} square sx={{}}>
      </Grid>
      <Grid item xs={10} sm={10} md={10} component={Paper} elevation={0} square sx={{}}>
       
     <Stack sx={{ width: '100%', paddingTop: "30px"}} spacing={2}>
      <Typography variant="p" color = "rgba(43, 117, 177, 0.9)"
              sx={{textAlign: "center", fontWeight: "bold"}}
              >Estudios de posgrado:
            </Typography>
              </Stack>
      </Grid>
     </Grid>
          

     <Grid container component="main">
      
      <Grid item xs={1} sm={1} md={1} component={Paper} elevation={0} square sx={{}}>
      </Grid>
      <Grid item xs={10} sm={10} md={10} component={Paper} elevation={0} square sx={{}}>
       
      {carrera5 && <Stack sx={{textAlign: "center", width: '100%', paddingTop: "30px", paddingBottom: "30px"}} spacing={2}>
      {fechaInicio5 && <Typography variant="paragraph" color = "grey"
              sx={{paddingTop: "0px", textAlign: "center", lineHeight:"5px", fontSize: "14px"}}
              >{fechaInicio5} / {fechaFin5}
            </Typography>}
     {carrera5 && <Typography variant="paragraph" color = "grey"
              sx={{textAlign: "center", lineHeight:"10px", fontWeight: "bold"}}
              >{carrera5}
            </Typography>}
      {uni5 && <Typography variant="paragraph" color = "grey"
              sx={{ textAlign: "center", lineHeight:"5px"}}
              >{uni5}
            </Typography>}
            {notaMedia5 && <Typography variant="paragraph" color = "grey"
              sx={{ textAlign: "center", lineHeight:"5px", fontSize: "14px"}}
              >Nota media: {notaMedia5}
            </Typography>}
              </Stack>}

              {carrera6 && <Stack sx={{textAlign: "center", width: '100%', paddingTop: "30px", paddingBottom: "30px"}} spacing={2}>
      {fechaInicio6 && <Typography variant="paragraph" color = "grey"
              sx={{paddingTop: "0px", textAlign: "center", lineHeight:"5px", fontSize: "14px"}}
              >{fechaInicio6} / {fechaFin6}
            </Typography>}
     {carrera6 && <Typography variant="paragraph" color = "grey"
              sx={{textAlign: "center", lineHeight:"10px", fontWeight: "bold"}}
              >{carrera6}
            </Typography>}
      {uni6 && <Typography variant="paragraph" color = "grey"
              sx={{ textAlign: "center", lineHeight:"5px"}}
              >{uni6}
            </Typography>}
            {notaMedia6 && <Typography variant="paragraph" color = "grey"
              sx={{ textAlign: "center", lineHeight:"5px", fontSize: "14px"}}
              >Nota media: {notaMedia6}
            </Typography>}
              </Stack>}

              {carrera7 && <Stack sx={{ textAlign: "center", width: '100%', paddingTop: "30px", paddingBottom: "30px"}} spacing={2}>
      {fechaInicio7 && <Typography variant="paragraph" color = "grey"
              sx={{paddingTop: "0px", textAlign: "center", lineHeight:"5px", fontSize: "14px"}}
              >{fechaInicio7} / {fechaFin7}
            </Typography>}
     {carrera7 && <Typography variant="paragraph" color = "grey"
              sx={{textAlign: "center", lineHeight:"10px", fontWeight: "bold"}}
              >{carrera7}
            </Typography>}
      {uni7 && <Typography variant="paragraph" color = "grey"
              sx={{ textAlign: "center", lineHeight:"5px"}}
              >{uni7}
            </Typography>}
            {notaMedia7 && <Typography variant="paragraph" color = "grey"
              sx={{ textAlign: "center", lineHeight:"5px", fontSize: "14px"}}
              >Nota media: {notaMedia7}
            </Typography>}
              </Stack>}

             {carrera8 && <Stack sx={{textAlign: "center", width: '100%', paddingTop: "30px", paddingBottom: "30px"}} spacing={2}>
      {fechaInicio8 && <Typography variant="paragraph" color = "grey"
              sx={{paddingTop: "0px", textAlign: "center", lineHeight:"5px", fontSize: "14px"}}
              >{fechaInicio8} / {fechaFin8}
            </Typography>}
     {carrera8 && <Typography variant="paragraph" color = "grey"
              sx={{textAlign: "center", lineHeight:"10px", fontWeight: "bold"}}
              >{carrera8}
            </Typography>}
      {uni8 && <Typography variant="paragraph" color = "grey"
              sx={{ textAlign: "center", lineHeight:"5px"}}
              >{uni8}
            </Typography>}
            {notaMedia8 && <Typography variant="paragraph" color = "grey"
              sx={{ textAlign: "center", lineHeight:"5px", fontSize: "14px"}}
              >Nota media: {notaMedia8}
            </Typography>}
              </Stack>}
      </Grid>

      
     </Grid></div>}

     <Divider sx={{marginTop: "50px"}} >
            <Chip label="Experiencia profesional" sx={{color: "white", bgcolor: "rgba(43, 117, 177, 0.9)"}} />
            </Divider>
            
          

            {trabajo1 && <div> <Grid container component="main">
      
      <Grid item xs={1} sm={1} md={1} component={Paper} elevation={0} square sx={{}}>
      </Grid>
      <Grid item xs={10} sm={10} md={10} component={Paper} elevation={0} square sx={{}}>
       
      {trabajo1 && <Stack sx={{textAlign: "center", width: '100%', paddingTop: "30px", paddingBottom: "30px"}} spacing={2}>
      {fechaInicioT1 && <Typography variant="h1" color = "grey"
              sx={{paddingTop: "0px", textAlign: "center", lineHeight:"5px", fontSize: "14px"}}
              >{fechaInicioT1} / {fechaFinT1}
            </Typography>}
     {trabajo1 && <Typography variant="paragraph" color = "grey"
              sx={{textAlign: "center", lineHeight:"10px", fontWeight: "bold"}}
              >{trabajo1}
            </Typography>}
      {empresa1 && <Typography variant="paragraph" color = "grey"
              sx={{ textAlign: "center", lineHeight:"5px"}}
              >{visibilidad === "Perfil anónimo (datos identificativos ocultos)" && "Despacho/Empresa confidencial"}
              {visibilidad === "Perfil abierto (datos visibles para despachos, empresas y headhunters)" && empresa1}
            </Typography>}
            {descripcion1 && <Typography variant="paragraph" color = "grey"
              sx={{ textAlign: "center", lineHeight:"22px", fontSize: "14px"}}
              >{parse(descripcion1)}
            </Typography>}
              </Stack>}

              {trabajo2 && <Stack sx={{textAlign: "center", width: '100%', paddingBottom: "30px"}} spacing={2}>
      {fechaInicioT2 && <Typography variant="paragraph" color = "grey"
              sx={{paddingTop: "0px", textAlign: "center", lineHeight:"5px", fontSize: "14px"}}
              >{fechaInicioT2} / {fechaFinT2}
            </Typography>}
     {trabajo2 && <Typography variant="paragraph" color = "grey"
              sx={{textAlign: "center", lineHeight:"10px", fontWeight: "bold"}}
              >{trabajo2}
            </Typography>}
      {empresa2 && <Typography variant="paragraph" color = "grey"
              sx={{ textAlign: "center", lineHeight:"5px"}}
              >{empresa2}
            </Typography>}
            {descripcion2 && <Typography variant="paragraph" color = "grey"
              sx={{ textAlign: "center", lineHeight:"22px", fontSize: "14px"}}
              >{parse(descripcion2)}
            </Typography>}
              </Stack>}

              {trabajo3 && <Stack sx={{textAlign: "center", width: '100%', paddingBottom: "30px"}} spacing={2}>
      {fechaInicioT3 && <Typography variant="paragraph" color = "grey"
              sx={{paddingTop: "0px", textAlign: "center", lineHeight:"5px", fontSize: "14px"}}
              >{fechaInicioT3} / {fechaFinT3}
            </Typography>}
     {trabajo3 && <Typography variant="paragraph" color = "grey"
              sx={{textAlign: "center", lineHeight:"10px", fontWeight: "bold"}}
              >{trabajo3}
            </Typography>}
      {empresa3 && <Typography variant="paragraph" color = "grey"
              sx={{ textAlign: "center", lineHeight:"5px"}}
              >{empresa3}
            </Typography>}
            {descripcion3 && <Typography variant="paragraph" color = "grey"
              sx={{ textAlign: "center", lineHeight:"22px", fontSize: "14px"}}
              >{parse(descripcion3)}
            </Typography>}
              </Stack>}

              {trabajo4 && <Stack sx={{textAlign: "center", width: '100%', paddingBottom: "30px"}} spacing={2}>
      {fechaInicioT4 && <Typography variant="paragraph" color = "grey"
              sx={{paddingTop: "0px", textAlign: "center", lineHeight:"5px", fontSize: "14px"}}
              >{fechaInicioT4} / {fechaFinT4}
            </Typography>}
     {trabajo4 && <Typography variant="paragraph" color = "grey"
              sx={{textAlign: "center", lineHeight:"10px", fontWeight: "bold"}}
              >{trabajo4}
            </Typography>}
      {empresa4 && <Typography variant="paragraph" color = "grey"
              sx={{ textAlign: "center", lineHeight:"5px"}}
              >{empresa4}
            </Typography>}
            {descripcion4 && <Typography variant="paragraph" color = "grey"
              sx={{ textAlign: "center", lineHeight:"22px", fontSize: "14px"}}
              >{parse(descripcion4)}
            </Typography>}
              </Stack>}

              {trabajo5 && <Stack sx={{textAlign: "center", width: '100%', paddingBottom: "30px"}} spacing={2}>
      {fechaInicioT5 && <Typography variant="paragraph" color = "grey"
              sx={{paddingTop: "0px", textAlign: "center", lineHeight:"5px", fontSize: "14px"}}
              >{fechaInicioT5} / {fechaFinT5}
            </Typography>}
     {trabajo5 && <Typography variant="paragraph" color = "grey"
              sx={{textAlign: "center", lineHeight:"10px", fontWeight: "bold"}}
              >{trabajo5}
            </Typography>}
      {empresa5 && <Typography variant="paragraph" color = "grey"
              sx={{ textAlign: "center", lineHeight:"5px"}}
              >{empresa5}
            </Typography>}
            {descripcion5 && <Typography variant="paragraph" color = "grey"
              sx={{ textAlign: "center", lineHeight:"22px", fontSize: "14px"}}
              >{parse(descripcion5)}
            </Typography>}
              </Stack>}

              {trabajo6 && <Stack sx={{textAlign: "center", width: '100%', paddingBottom: "30px"}} spacing={2}>
      {fechaInicioT6 && <Typography variant="paragraph" color = "grey"
              sx={{paddingTop: "0px", textAlign: "center", lineHeight:"5px", fontSize: "14px"}}
              >{fechaInicioT6} / {fechaFinT6}
            </Typography>}
     {trabajo6 && <Typography variant="paragraph" color = "grey"
              sx={{textAlign: "center", lineHeight:"10px", fontWeight: "bold"}}
              >{trabajo6}
            </Typography>}
      {empresa6 && <Typography variant="paragraph" color = "grey"
              sx={{ textAlign: "center", lineHeight:"5px"}}
              >{empresa6}
            </Typography>}
            {descripcion6 && <Typography variant="paragraph" color = "grey"
              sx={{ textAlign: "center", lineHeight:"22px", fontSize: "14px"}}
              >{parse(descripcion6)}
            </Typography>}
              </Stack>}

              {trabajo7 && <Stack sx={{textAlign: "center", width: '100%', paddingBottom: "30px"}} spacing={2}>
      {fechaInicioT7 && <Typography variant="paragraph" color = "grey"
              sx={{textAlign: "center", lineHeight:"5px", fontSize: "14px"}}
              >{fechaInicioT7} / {fechaFinT7}
            </Typography>}
     {trabajo7 && <Typography variant="paragraph" color = "grey"
              sx={{textAlign: "center", lineHeight:"10px", fontWeight: "bold"}}
              >{trabajo7}
            </Typography>}
      {empresa7 && <Typography variant="paragraph" color = "grey"
              sx={{ textAlign: "center", lineHeight:"5px"}}
              >{empresa7}
            </Typography>}
            {descripcion7 && <Typography variant="paragraph" color = "grey"
              sx={{ textAlign: "center", lineHeight:"22px", fontSize: "14px"}}
              >{parse(descripcion7)}
            </Typography>}
              </Stack>}

              {trabajo8 && <Stack sx={{textAlign: "center", width: '100%', paddingBottom: "30px"}} spacing={2}>
      {fechaInicioT8 && <Typography variant="paragraph" color = "grey"
              sx={{paddingTop: "0px", textAlign: "center", lineHeight:"5px", fontSize: "14px"}}
              >{fechaInicioT8} / {fechaFinT8}
            </Typography>}
     {trabajo8 && <Typography variant="paragraph" color = "grey"
              sx={{textAlign: "center", lineHeight:"10px", fontWeight: "bold"}}
              >{trabajo8}
            </Typography>}
      {empresa8 && <Typography variant="paragraph" color = "grey"
              sx={{ textAlign: "center", lineHeight:"5px"}}
              >{empresa8}
            </Typography>}
            {descripcion8 && <Typography variant="paragraph" color = "grey"
              sx={{ textAlign: "center", lineHeight:"22px", fontSize: "14px"}}
              >{parse(descripcion8)}
            </Typography>}
              </Stack>}

             
      </Grid>

      
     </Grid></div>}
          



     <Stack sx={{ width: '100%'}} spacing={1} direction="row" justifyContent="center">  
     <Box sx={{maxWidth: "70%", display: "flex", justifyContent: "center", gap: "10px", flexWrap: "wrap", paddingBottom: "30px"}}> 

      {/*eachArea*/} 

    
            </Box>
            </Stack>
            <Divider sx={{marginTop: "50px"}} >
            <Chip label="Idiomas" sx={{color: "white", bgcolor: "rgba(43, 117, 177, 0.9)"}} />
            </Divider>
            <Grid container component="main">
      
      <Grid item xs={1} sm={1} md={1} component={Paper} elevation={0} square sx={{}}>
      </Grid>
      <Grid item xs={10} sm={10} md={10} component={Paper} elevation={0} square sx={{}}>
       
     {castellano && <Stack sx={{ width: '100%'}} spacing={1} direction="row" justifyContent="center">
      <Typography variant="p" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", textAlign: "center", fontWeight: "bold"}}
              >Castellano:
            </Typography>
            <Typography variant="p" color = "grey"
              sx={{paddingTop: "20px", textAlign: "center"}}
              >{castellano}
            </Typography>
              </Stack>}
            {ingles && <Stack sx={{ width: '100%'}} spacing={1} direction="row" justifyContent="center">
      <Typography variant="p" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", textAlign: "center", fontWeight: "bold"}}
              >Inglés:
            </Typography>
            <Typography variant="p" color = "grey"
              sx={{paddingTop: "20px", textAlign: "center"}}
              >{ingles}
            </Typography>
              </Stack>}
              {idioma1 && <Stack sx={{ width: '100%'}} spacing={1} direction="row" justifyContent="center">
      <Typography variant="p" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", textAlign: "center", fontWeight: "bold"}}
              >{idioma1}:
            </Typography>
            <Typography variant="p" color = "grey"
              sx={{paddingTop: "20px", textAlign: "center"}}
              >{nivelIdioma1}
            </Typography>
              </Stack>}
             {idioma2 && <Stack sx={{ width: '100%'}} spacing={1} direction="row" justifyContent="center">
      <Typography variant="p" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", textAlign: "center", fontWeight: "bold"}}
              >{idioma2}:
            </Typography>
            <Typography variant="p" color = "grey"
              sx={{paddingTop: "20px", textAlign: "center"}}
              >{nivelIdioma2}
            </Typography>
              </Stack>}
      </Grid>
     </Grid>
          







                </Stack>

             
         
       </Grid>
   }
 
     </Grid>


:  perfiles.map((perfil) => (
  <Perfil 
  key={perfil.docId} 
  docId={perfil.docId} 
  nombre={perfil.nombre} 
  telefono={perfil.telefono}
  ciudad={perfil.ciudad} 
  visibilidad={perfil.visibilidad} 
  email={perfil.email} 
  carrera1={perfil.carrera1}
  uni1={perfil.uni1}
  fechaInicio1={perfil.fechaInicio1}
  fechaFin1={perfil.fechaFin1}
  notaMedia1={perfil.notaMedia1}
  carrera2={perfil.carrera2}
  uni2={perfil.uni2}
  fechaInicio2={perfil.fechaInicio2}
  fechaFin2={perfil.fechaFin2}
  notaMedia2={perfil.notaMedia2}
  notaMedia3={perfil.notaMedia3}
  notaMedia4={perfil.notaMedia4}
  notaMedia5={perfil.notaMedia5}
  notaMedia6={perfil.notaMedia6}
  notaMedia7={perfil.notaMedia7}
  notaMedia8={perfil.notaMedia8}
  carrera3={perfil.carrera3}
  uni3={perfil.uni3}
  fechaInicio3={perfil.fechaInicio3}
  fechaFin3={perfil.fechaFin3}
  carrera4={perfil.carrera4}
  uni4={perfil.uni4}
  fechaInicio4={perfil.fechaInicio4}
  fechaFin4={perfil.fechaFin4}
  carrera5={perfil.carrera5}
  uni5={perfil.uni5}
  fechaInicio5={perfil.fechaInicio5}
  fechaFin5={perfil.fechaFin5}
  carrera6={perfil.carrera6}
  uni6={perfil.uni6}
  fechaInicio6={perfil.fechaInicio6}
  fechaFin6={perfil.fechaFin6}
  carrera7={perfil.carrera7}
  uni7={perfil.uni7}
  fechaInicio7={perfil.fechaInicio7}
  fechaFin7={perfil.fechaFin7}
  carrera8={perfil.carrera8}
  uni8={perfil.uni8}
  fechaInicio8={perfil.fechaInicio8}
  fechaFin8={perfil.fechaFin8}
  carrera9={perfil.carrera9}
  uni9={perfil.uni9}
  fechaInicio9={perfil.fechaInicio9}
  fechaFin9={perfil.fechaFin9}
  trabajo1={perfil.trabajo1}
  empresa1={perfil.empresa1}
  fechaInicioT1={perfil.fechaInicioT1}
  fechaFinT1={perfil.fechaFinT1}
  descripcion1={perfil.descripcion1}
  trabajo2={perfil.trabajo2}
  empresa2={perfil.empresa2}
  fechaInicioT2={perfil.fechaInicioT2}
  fechaFinT2={perfil.fechaFinT2}
  descripcion2={perfil.descripcion2}
  trabajo3={perfil.trabajo3}
  empresa3={perfil.empresa3}
  fechaInicioT3={perfil.fechaInicioT3}
  fechaFinT3={perfil.fechaFinT3}
  descripcion3={perfil.descripcion3}
  trabajo4={perfil.trabajo4}
  empresa4={perfil.empresa4}
  fechaInicioT4={perfil.fechaInicioT4}
  fechaFinT4={perfil.fechaFinT4}
  descripcion4={perfil.descripcion4}
  trabajo5={perfil.trabajo5}
  empresa5={perfil.empresa5}
  fechaInicioT5={perfil.fechaInicioT5}
  fechaFinT5={perfil.fechaFinT5}
  descripcion5={perfil.descripcion5}
  trabajo6={perfil.trabajo6}
  empresa6={perfil.empresa6}
  fechaInicioT6={perfil.fechaInicioT6}
  fechaFinT6={perfil.fechaFinT6}
  descripcion6={perfil.descripcion6}
  trabajo7={perfil.trabajo7}
  empresa7={perfil.empresa7}
  fechaInicioT7={perfil.fechaInicioT7}
  fechaFinT7={perfil.fechaFinT7}
  descripcion7={perfil.descripcion7}
  trabajo8={perfil.trabajo8}
  empresa8={perfil.empresa8}
  fechaInicioT8={perfil.fechaInicioT8}
  fechaFinT8={perfil.fechaFinT8}
  descripcion8={perfil.descripcion8}
  numGrados={perfil.numGrados}
  numPosGrados={perfil.numPosGrados}
  numTrabajos={perfil.numTrabajos}
  area={perfil.area}
  castellano={perfil.castellano}
  ingles={perfil.ingles}
  presentacion={perfil.presentacion}
  anos={perfil.anos}
  idioma1={perfil.idioma1}
  nivelIdioma1={perfil.nivelIdioma1}
  idioma2={perfil.idioma2}
  nivelIdioma2={perfil.nivelIdioma2}
  onUpdate={handleUpdatePerfil}   />
 ) )

}
</Box>







<Box sx={{display: { xl: 'none', l: "none", m: "none", s:"inline", xs: 'inline'}}} >
    {perfiles.length === 0 ? 
     <Grid container component="main" sx={{ }}>
      <Grid item xs={12} sm={12} md={12} component={Paper} elevation={0} square sx={{}}>
       
      <Stack sx={{ paddingTop: "20px" }} spacing={2}>
      <Typography variant="h5" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center"}}
              >Introduce tus datos profesionales
            </Typography>
            
              </Stack>
           
           
             <Box
            sx={{
           
              flexDirection: 'column',
              justifyContent: "center",
              alignItems: "center",
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          >
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{mt: 1, marginBottom: "60px", borderRadius: "25px", padding: "20px"}}>
        
            <Divider sx={{marginBottom: "20px"}} >
            <Chip label="Visibilidad de tu perfil profesional" sx={{color: "white", bgcolor: "rgba(43, 117, 177, 0.9)"}} />
            </Divider>
              <Autocomplete
        sx={{paddingTop: "20px"}}
        id="tags-standard"
        options={options}
            name="visibilidad"
        onChange={(e, value)=> setVisibilidad(value)}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Visibilidad de tu perfil"
            placeholder="Visibilidad de tu perfil"
          />
        )}
      />
                          <Divider sx={{marginTop: "30px"}} >
            <Chip label="Información general" sx={{color: "white", bgcolor: "rgba(43, 117, 177, 0.9)"}} />
            </Divider>
            
            

            <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="Nombre completo"
                label="Nombre completo"
                name="nombre"
                autoComplete="nombre"
             
                onChange={handleChange}
              />

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="Número de teléfono"
                label="Número de teléfono"
                name="telefono"
                autoComplete="telefono"
            
                onChange={handleChange}
              />

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
  
                fullWidth
                id="email"
                label="email"
                name="email"
                autoComplete="email"
             
                onChange={handleChange}
              />

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="Ciudad"
                label="Ciudad"
                name="ciudad"
                autoComplete="ciudad"
            
                onChange={handleChange}
              />

<Divider sx={{marginTop: "30px"}} >
            <Chip label="Formación" sx={{color: "white", bgcolor: "rgba(43, 117, 177, 0.9)"}} />
            </Divider>

            <Stack sx={{ }} spacing={2}>
      <Typography variant="body1" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "10px", textAlign: "center"}}
              >Introduce tus estudios de grado o licenciatura
            </Typography>
            
              </Stack>
            
            <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
       
                fullWidth
                id="carrera1"
                label="Estudios"
                name="carrera1"
                autoComplete="carrera1"
             
                onChange={handleChange}
              />

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
         
                fullWidth
                id="uni1"
                label="Universidad"
                name="uni1"
                autoComplete="uni1"
             
                onChange={handleChange}
              />

<Grid container component="main" columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
         
                fullWidth
                id="fechaInicio1"
                label="Fecha de inicio"
                name="fechaInicio1"
                autoComplete="fechaInicio1"
              
                onChange={handleChange}
              />
        </Grid>

        <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
        
                fullWidth
                id="fechaFin1"
                label="Fecha de fin"
                name="fechaFin1"
                autoComplete="fechaFin1"
              
                onChange={handleChange}
              />
        </Grid>
        </Grid>
        <TextField   
                sx={{width: '28ch', input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
          
                id="notaMedia1"
                label="Nota media en la carrera"
                name="notaMedia1"
                autoComplete="notaMedia1"
             
                onChange={handleChange}
              />
        
            {numGrados === 1 ? <Stack direction="row" spacing={0}>
          <IconButton aria-label="delete" color="primary"  onClick={handleButtonAddGrado}>
          <AddCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center", paddingRight: "40px"}}
              >Añadir nueva formación
            </Typography>
            <IconButton aria-label="delete" color="primary"  onClick={handleButtonRemoveGrado}>
          <RemoveCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center"}}
              >Eliminar formación
            </Typography>
            
        </Stack>:
        <Divider sx={{marginTop: "20px", marginBottom: "10px"}} ></Divider>}


{numGrados > 1 &&
          <div>
          <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
            
                fullWidth
                id="carrera2"
                label="Estudios"
                name="carrera2"
                autoComplete="carrera2"
           
                onChange={handleChange}
              />

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
         
                fullWidth
                id="uni2"
                label="Universidad"
                name="uni2"
                autoComplete="uni2"
             
                onChange={handleChange}
              />

<Grid container component="main" columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
          
                fullWidth
                id="fechaInicio2"
                label="Fecha de inicio"
                name="fechaInicio2"
                autoComplete="fechaInicio2"
             
                onChange={handleChange}
              />
        </Grid>

        <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
          
                fullWidth
                id="fechaFin2"
                label="Fecha de fin"
                name="fechaFin2"
                autoComplete="fechaFin2"
               
                onChange={handleChange}
              />
        </Grid>
        </Grid>
        <TextField   
                sx={{width: '28ch', input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
           
                id="notaMedia2"
                label="Nota media en la carrera"
                name="notaMedia2"
                autoComplete="notaMedia2"
             
                onChange={handleChange}
              />
        
            {numGrados === 2 ? <Stack direction="row" spacing={0}>
          <IconButton aria-label="delete" color="primary"  onClick={handleButtonAddGrado}>
          <AddCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center", paddingRight: "40px"}}
              >Añadir nueva formación
            </Typography>
            <IconButton aria-label="delete" color="primary"  onClick={handleButtonRemoveGrado2}>
          <RemoveCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center"}}
              >Eliminar formación
            </Typography>
            
        </Stack>:
        <Divider sx={{marginTop: "20px", marginBottom: "10px"}} ></Divider>}
       </div>
          
          }

{numGrados > 2 &&
          <div>
          <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
       
                fullWidth
                id="carrera3"
                label="Estudios"
                name="carrera3"
                autoComplete="carrera3"
             
                onChange={handleChange}
              />

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
           
                fullWidth
                id="uni3"
                label="Universidad"
                name="uni3"
                autoComplete="uni3"
        
                onChange={handleChange}
              />

<Grid container component="main" columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
           
                fullWidth
                id="fechaInicio3"
                label="Fecha de inicio"
                name="fechaInicio3"
                autoComplete="fechaInicio3"
             
                onChange={handleChange}
              />
        </Grid>

        <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
          
                fullWidth
                id="fechaFin3"
                label="Fecha de fin"
                name="fechaFin3"
                autoComplete="fechaFin3"
              
                onChange={handleChange}
              />
        </Grid>
        </Grid>
        <TextField   
                sx={{width: '28ch', input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
           
                id="notaMedia3"
                label="Nota media en la carrera"
                name="notaMedia3"
                autoComplete="notaMedia3"
              
                onChange={handleChange}
              />
        
            {numGrados === 3 ? <Stack direction="row" spacing={0}>
          <IconButton aria-label="delete" color="primary"  onClick={handleButtonAddGrado}>
          <AddCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center", paddingRight: "40px"}}
              >Añadir nueva formación
            </Typography>
            <IconButton aria-label="delete" color="primary"  onClick={handleButtonRemoveGrado3}>
          <RemoveCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center"}}
              >Eliminar formación
            </Typography>
            
        </Stack>:
        <Divider sx={{marginTop: "20px", marginBottom: "10px"}} ></Divider>}
        </div>
          }

{numGrados >= 4 &&
          <div>
          <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
         
                fullWidth
                id="carrera4"
                label="Estudios"
                name="carrera4"
                autoComplete="carrera4"
            
                onChange={handleChange}
              />

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
              
                fullWidth
                id="uni4"
                label="Universidad"
                name="uni4"
                autoComplete="uni4"
             
                onChange={handleChange}
              />

<Grid container component="main" columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
           
                fullWidth
                id="fechaInicio4"
                label="Fecha de inicio"
                name="fechaInicio4"
                autoComplete="fechaInicio4"
            
                onChange={handleChange}
              />
        </Grid>

        <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
         
                fullWidth
                id="fechaFin4"
                label="Fecha de fin"
                name="fechaFin4"
                autoComplete="fechaFin4"
             
                onChange={handleChange}
              />
        </Grid>
        </Grid>
        <TextField   
                sx={{width: '28ch', input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
         
                id="notaMedia4"
                label="Nota media en la carrera"
                name="notaMedia4"
                autoComplete="notaMedia4"
             
                onChange={handleChange}
              />
        
            {numGrados === 4 ? <Stack direction="row" spacing={0}>
          <IconButton aria-label="delete" color="primary"  onClick={handleButtonAddGrado}>
          <AddCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center", paddingRight: "40px"}}
              >Añadir nueva formación
            </Typography>
            <IconButton aria-label="delete" color="primary"  onClick={handleButtonRemoveGrado4}>
          <RemoveCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center"}}
              >Eliminar formación
            </Typography>
            
        </Stack>:
        <Divider sx={{marginTop: "20px", marginBottom: "10px"}} ></Divider>}
       </div>
          
          }


<Divider sx={{marginTop: "20px", marginBottom: "10px"}} ></Divider>
<Stack sx={{ }} spacing={2}>
      <Typography variant="body1" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "30px", textAlign: "center"}}
              >Introduce tus estudios de posgrado
            </Typography>
            
              </Stack>
            
            <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
            
                fullWidth
                id="carrera5"
                label="Estudios"
                name="carrera5"
                autoComplete="carrera5"
             
                onChange={handleChange}
              />

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
        
                fullWidth
                id="uni5"
                label="Universidad"
                name="uni5"
                autoComplete="uni5"
             
                onChange={handleChange}
              />

<Grid container component="main" columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
          
                fullWidth
                id="fechaInicio5"
                label="Fecha de inicio"
                name="fechaInicio5"
                autoComplete="fechaInicio5"
              
                onChange={handleChange}
              />
        </Grid>

        <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
      
                fullWidth
                id="fechaFin5"
                label="Fecha de fin"
                name="fechaFin5"
                autoComplete="fechaFin5"
              
                onChange={handleChange}
              />
        </Grid>
        </Grid>
        <TextField   
                sx={{width: '28ch', input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
       
                id="notaMedia5"
                label="Nota media"
                name="notaMedia5"
                autoComplete="notaMedia5"
             
                onChange={handleChange}
              />
        
            {numPosGrados === 1 ? <Stack direction="row" spacing={0}>
          <IconButton aria-label="delete" color="primary"  onClick={handleButtonAddPosGrado}>
          <AddCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center", paddingRight: "40px"}}
              >Añadir nueva formación
            </Typography>
            <IconButton aria-label="delete" color="primary"  onClick={handleButtonRemovePosGrado}>
          <RemoveCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center"}}
              >Eliminar formación
            </Typography>
            
        </Stack>:
        <Divider sx={{marginTop: "20px", marginBottom: "10px"}} ></Divider>}


{numPosGrados > 1 &&
          <div>
          <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
        
                fullWidth
                id="carrera6"
                label="Estudios"
                name="carrera6"
                autoComplete="carrera6"
           
                onChange={handleChange}
              />

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
         
                fullWidth
                id="uni6"
                label="Universidad"
                name="uni6"
                autoComplete="uni6"
             
                onChange={handleChange}
              />

<Grid container component="main" columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
           
                fullWidth
                id="fechaInicio6"
                label="Fecha de inicio"
                name="fechaInicio6"
                autoComplete="fechaInicio6"
             
                onChange={handleChange}
              />
        </Grid>

        <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
           
                fullWidth
                id="fechaFin6"
                label="Fecha de fin"
                name="fechaFin6"
                autoComplete="fechaFin6"
               
                onChange={handleChange}
              />
        </Grid>
        </Grid>
        <TextField   
                sx={{width: '28ch', input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
           
                id="notaMedia6"
                label="Nota media"
                name="notaMedia6"
                autoComplete="notaMedia6"
             
                onChange={handleChange}
              />
        
            {numPosGrados === 2 ? <Stack direction="row" spacing={0}>
          <IconButton aria-label="delete" color="primary"  onClick={handleButtonAddPosGrado}>
          <AddCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center", paddingRight: "40px"}}
              >Añadir nueva formación
            </Typography>
            <IconButton aria-label="delete" color="primary"  onClick={handleButtonRemovePosGrado2}>
          <RemoveCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center"}}
              >Eliminar formación
            </Typography>
            
        </Stack>:
        <Divider sx={{marginTop: "20px", marginBottom: "10px"}} ></Divider>}
       </div>
          
          }

{numPosGrados > 2 &&
          <div>
          <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
          
                fullWidth
                id="carrera7"
                label="Estudios"
                name="carrera7"
                autoComplete="carrera7"
             
                onChange={handleChange}
              />

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
          
                fullWidth
                id="uni7"
                label="Universidad"
                name="uni7"
                autoComplete="uni7"
        
                onChange={handleChange}
              />

<Grid container component="main" columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
         
                fullWidth
                id="fechaInicio7"
                label="Fecha de inicio"
                name="fechaInicio7"
                autoComplete="fechaInicio7"
             
                onChange={handleChange}
              />
        </Grid>

        <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
          
                fullWidth
                id="fechaFin7"
                label="Fecha de fin"
                name="fechaFin7"
                autoComplete="fechaFin7"
              
                onChange={handleChange}
              />
        </Grid>
        </Grid>
        <TextField   
                sx={{width: '28ch', input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
         
                id="notaMedia7"
                label="Nota media"
                name="notaMedia7"
                autoComplete="notaMedia7"
              
                onChange={handleChange}
              />
        
            {numGrados === 3 ? <Stack direction="row" spacing={0}>
          <IconButton aria-label="delete" color="primary"  onClick={handleButtonAddPosGrado}>
          <AddCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center", paddingRight: "40px"}}
              >Añadir nueva formación
            </Typography>
            <IconButton aria-label="delete" color="primary"  onClick={handleButtonRemovePosGrado3}>
          <RemoveCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center"}}
              >Eliminar formación
            </Typography>
            
        </Stack>:
        <Divider sx={{marginTop: "20px", marginBottom: "10px"}} ></Divider>}
        </div>
          }

{numPosGrados >= 4 &&
          <div>
          <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
          
                fullWidth
                id="carrera8"
                label="Estudios"
                name="carrera8"
                autoComplete="carrera8"
            
                onChange={handleChange}
              />

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
        
                fullWidth
                id="uni8"
                label="Universidad"
                name="uni8"
                autoComplete="uni8"
             
                onChange={handleChange}
              />

<Grid container component="main" columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
       
                fullWidth
                id="fechaInicio8"
                label="Fecha de inicio"
                name="fechaInicio8"
                autoComplete="fechaInicio8"
            
                onChange={handleChange}
              />
        </Grid>

        <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
           
                fullWidth
                id="fechaFin8"
                label="Fecha de fin"
                name="fechaFin8"
                autoComplete="fechaFin8"
             
                onChange={handleChange}
              />
        </Grid>
        </Grid>
        <TextField   
                sx={{width: '28ch', input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
        
                id="notaMedia8"
                label="Nota media"
                name="notaMedia8"
                autoComplete="notaMedia8"
             
                onChange={handleChange}
              />
        
            {numPosGrados === 4 ? <Stack direction="row" spacing={0}>
          <IconButton aria-label="delete" color="primary"  onClick={handleButtonAddPosGrado}>
          <AddCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center", paddingRight: "40px"}}
              >Añadir nueva formación
            </Typography>
            <IconButton aria-label="delete" color="primary"  onClick={handleButtonRemovePosGrado4}>
          <RemoveCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center"}}
              >Eliminar formación
            </Typography>
            
        </Stack>:
        <Divider sx={{marginTop: "20px", marginBottom: "10px"}} ></Divider>}
       </div>
          
          }
       
       <Divider sx={{marginTop: "20px"}} >
            <Chip label="Experiencia" sx={{color: "white", bgcolor: "rgba(43, 117, 177, 0.9)"}} />
            </Divider>

            <Stack sx={{ }} spacing={2}>
      <Typography variant="1" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "30px", textAlign: "center", lineHeight: "0px"}}
              >Introduce tus experiencias laborales
            </Typography>
            <Typography variant="subtitle1" color = "rgba(43, 117, 177, 0.9)"
              sx={{textAlign: "center"}}
              >(comenzando por la más reciente / actual)
            </Typography>
            
              </Stack>
           
            <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
         
                fullWidth
                id="trabajo1"
                label="Posición"
                name="trabajo1"
                autoComplete="trabajo1"
             
                onChange={handleChange}
              />

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
          
                fullWidth
                id="empresa1"
                label="Despacho / Empresa"
                name="empresa1"
                autoComplete="empresa1"
             
                onChange={handleChange}
              />

<Grid container component="main" columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
          
                fullWidth
                id="fechaInicioT1"
                label="Fecha de inicio"
                name="fechaInicioT1"
                autoComplete="fechaInicioT1"
              
                onChange={handleChange}
              />
        </Grid>

        <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{marginBottom: "20px"}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
         
                fullWidth
                id="fechaFinT1"
                label="Fecha de fin"
                name="fechaFinT1"
                autoComplete="fechaFinT1"
              
                onChange={handleChange}
              />
        </Grid>
        </Grid>
              <CKEditor  editor={ClassicEditor} data={descripcion1} onChange={(event, editor) => {
  const data = editor.getData()
  setDescripcion1(data)
}}
/> 
        
            {numTrabajos === 1 ? <Stack direction="row" spacing={0}>
          <IconButton aria-label="delete" color="primary"  onClick={handleButtonAddTrabajo}>
          <AddCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center", paddingRight: "40px"}}
              >Añadir nuevo trabajo
            </Typography>
            <IconButton aria-label="delete" color="primary"  onClick={handleButtonRemoveTrabajo}>
          <RemoveCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center"}}
              >Eliminar trabajo
            </Typography>
            
        </Stack>:
        <Divider sx={{marginTop: "20px", marginBottom: "10px"}} ></Divider>}


{numTrabajos > 1 &&
          <div>
          <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
        
                fullWidth
                id="trabajo2"
                label="Posición"
                name="trabajo2"
                autoComplete="trabajo2"
           
                onChange={handleChange}
              />

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
           
                fullWidth
                id="empresa2"
                label="Despacho / Empresa"
                name="empresa2"
                autoComplete="empresa2"
             
                onChange={handleChange}
              />

<Grid container component="main" columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
         
                fullWidth
                id="fechaInicioT2"
                label="Fecha de inicio"
                name="fechaInicioT2"
                autoComplete="fechaInicioT2"
             
                onChange={handleChange}
              />
        </Grid>

        <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{marginBottom: "20px"}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
         
                fullWidth
                id="fechaFinT2"
                label="Fecha de fin"
                name="fechaFinT2"
                autoComplete="fechaFinT2"
               
                onChange={handleChange}
              />
        </Grid>
        </Grid>
        <CKEditor  editor={ClassicEditor} data={descripcion2} onChange={(event, editor) => {
  const data = editor.getData()
  setDescripcion2(data)
}}
/> 
        
            {numTrabajos === 2 ? <Stack direction="row" spacing={0}>
          <IconButton aria-label="delete" color="primary"  onClick={handleButtonAddTrabajo}>
          <AddCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center", paddingRight: "40px"}}
              >Añadir nuevo trabajo
            </Typography>
            <IconButton aria-label="delete" color="primary"  onClick={handleButtonRemoveTrabajo2}>
          <RemoveCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center"}}
              >Eliminar trabajo
            </Typography>
            
        </Stack>:
        <Divider sx={{marginTop: "20px", marginBottom: "10px"}} ></Divider>}
       </div>
          
          }

{numTrabajos > 2 &&
          <div>
          <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
             
                fullWidth
                id="trabajo3"
                label="Posición"
                name="trabajo3"
                autoComplete="trabajo3"
             
                onChange={handleChange}
              />

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
          
                fullWidth
                id="empresa3"
                label="Despacho / Empresa"
                name="empresa3"
                autoComplete="empresa3"
        
                onChange={handleChange}
              />

<Grid container component="main" columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
          
                fullWidth
                id="fechaInicioT3"
                label="Fecha de inicio"
                name="fechaInicioT3"
                autoComplete="fechaInicioT3"
             
                onChange={handleChange}
              />
        </Grid>

        <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{marginBottom: "20px"}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
             
                fullWidth
                id="fechaFinT3"
                label="Fecha de fin"
                name="fechaFinT3"
                autoComplete="fechaFinT3"
              
                onChange={handleChange}
              />
        </Grid>
        </Grid>
        <CKEditor  editor={ClassicEditor} data={descripcion3} onChange={(event, editor) => {
  const data = editor.getData()
  setDescripcion3(data)
}}
/> 
        
            {numTrabajos === 3 ? <Stack direction="row" spacing={0}>
          <IconButton aria-label="delete" color="primary"  onClick={handleButtonAddTrabajo}>
          <AddCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center", paddingRight: "40px"}}
              >Añadir nuevo trabajo
            </Typography>
            <IconButton aria-label="delete" color="primary"  onClick={handleButtonRemoveTrabajo3}>
          <RemoveCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center"}}
              >Eliminar trabajo
            </Typography>
            
        </Stack>:
        <Divider sx={{marginTop: "20px", marginBottom: "10px"}} ></Divider>}
        </div>
          }

{numTrabajos > 3 &&
          <div>
          <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
          
                fullWidth
                id="trabajo4"
                label="Posición"
                name="trabajo4"
                autoComplete="trabajo4"
             
                onChange={handleChange}
              />

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
         
                fullWidth
                id="empresa4"
                label="Despacho / Empresa"
                name="empresa4"
                autoComplete="empresa4"
        
                onChange={handleChange}
              />

<Grid container component="main" columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
         
                fullWidth
                id="fechaInicioT4"
                label="Fecha de inicio"
                name="fechaInicioT4"
                autoComplete="fechaInicioT4"
             
                onChange={handleChange}
              />
        </Grid>

        <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{marginBottom: "20px"}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
        
                fullWidth
                id="fechaFinT4"
                label="Fecha de fin"
                name="fechaFinT4"
                autoComplete="fechaFinT4"
              
                onChange={handleChange}
              />
        </Grid>
        </Grid>
        <CKEditor  editor={ClassicEditor} data={descripcion4} onChange={(event, editor) => {
  const data = editor.getData()
  setDescripcion4(data)
}}
/> 
        
            {numTrabajos === 4 ? <Stack direction="row" spacing={0}>
          <IconButton aria-label="delete" color="primary"  onClick={handleButtonAddTrabajo}>
          <AddCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center", paddingRight: "40px"}}
              >Añadir nuevo trabajo
            </Typography>
            <IconButton aria-label="delete" color="primary"  onClick={handleButtonRemoveTrabajo4}>
          <RemoveCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center"}}
              >Eliminar trabajo
            </Typography>
            
        </Stack>:
        <Divider sx={{marginTop: "20px", marginBottom: "10px"}} ></Divider>}
        </div>
          }


{numTrabajos > 4 &&
          <div>
          <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
        
                fullWidth
                id="trabajo5"
                label="Posición"
                name="trabajo5"
                autoComplete="trabajo5"
             
                onChange={handleChange}
              />

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
          
                fullWidth
                id="empresa5"
                label="Despacho / Empresa"
                name="empresa5"
                autoComplete="empresa5"
        
                onChange={handleChange}
              />

<Grid container component="main" columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
       
                fullWidth
                id="fechaInicioT5"
                label="Fecha de inicio"
                name="fechaInicioT5"
                autoComplete="fechaInicioT5"
             
                onChange={handleChange}
              />
        </Grid>

        <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{marginBottom: "20px"}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
       
                fullWidth
                id="fechaFinT5"
                label="Fecha de fin"
                name="fechaFinT5"
                autoComplete="fechaFinT5"
              
                onChange={handleChange}
              />
        </Grid>
        </Grid>
        <CKEditor  editor={ClassicEditor} data={descripcion5} onChange={(event, editor) => {
  const data = editor.getData()
  setDescripcion5(data)
}}
/> 
        
            {numTrabajos === 5 ? <Stack direction="row" spacing={0}>
          <IconButton aria-label="delete" color="primary"  onClick={handleButtonAddTrabajo}>
          <AddCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center", paddingRight: "40px"}}
              >Añadir nuevo trabajo
            </Typography>
            <IconButton aria-label="delete" color="primary"  onClick={handleButtonRemoveTrabajo5}>
          <RemoveCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center"}}
              >Eliminar trabajo
            </Typography>
            
        </Stack>:
        <Divider sx={{marginTop: "20px", marginBottom: "10px"}} ></Divider>}
        </div>
          }

{numTrabajos > 5 &&
          <div>
          <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
        
                fullWidth
                id="trabajo6"
                label="Posición"
                name="trabajo6"
                autoComplete="trabajo6"
             
                onChange={handleChange}
              />

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
         
                fullWidth
                id="empresa6"
                label="Despacho / Empresa"
                name="empresa6"
                autoComplete="empresa6"
        
                onChange={handleChange}
              />

<Grid container component="main" columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
           
                fullWidth
                id="fechaInicioT6"
                label="Fecha de inicio"
                name="fechaInicioT6"
                autoComplete="fechaInicioT6"
             
                onChange={handleChange}
              />
        </Grid>

        <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{marginBottom: "20px"}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
         
                fullWidth
                id="fechaFinT6"
                label="Fecha de fin"
                name="fechaFinT6"
                autoComplete="fechaFinT6"
              
                onChange={handleChange}
              />
        </Grid>
        </Grid>
        <CKEditor  editor={ClassicEditor} data={descripcion6} onChange={(event, editor) => {
  const data = editor.getData()
  setDescripcion6(data)
}}
/> 
        
            {numTrabajos === 6 ? <Stack direction="row" spacing={0}>
          <IconButton aria-label="delete" color="primary"  onClick={handleButtonAddTrabajo}>
          <AddCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center", paddingRight: "40px"}}
              >Añadir nuevo trabajo
            </Typography>
            <IconButton aria-label="delete" color="primary"  onClick={handleButtonRemoveTrabajo6}>
          <RemoveCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center"}}
              >Eliminar trabajo
            </Typography>
            
        </Stack>:
        <Divider sx={{marginTop: "20px", marginBottom: "10px"}} ></Divider>}
        </div>
          }

{numTrabajos > 6 &&
          <div>
          <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
            
                fullWidth
                id="trabajo7"
                label="Posición"
                name="trabajo7"
                autoComplete="trabajo7"
             
                onChange={handleChange}
              />

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
         
                fullWidth
                id="empresa7"
                label="Despacho / Empresa"
                name="empresa7"
                autoComplete="empresa7"
        
                onChange={handleChange}
              />

<Grid container component="main" columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
          
                fullWidth
                id="fechaInicioT7"
                label="Fecha de inicio"
                name="fechaInicioT7"
                autoComplete="fechaInicioT7"
             
                onChange={handleChange}
              />
        </Grid>

        <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{marginBottom: "20px"}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
        
                fullWidth
                id="fechaFinT7"
                label="Fecha de fin"
                name="fechaFinT7"
                autoComplete="fechaFinT7"
              
                onChange={handleChange}
              />
        </Grid>
        </Grid>
        <CKEditor  editor={ClassicEditor} data={descripcion7} onChange={(event, editor) => {
  const data = editor.getData()
  setDescripcion7(data)
}}
/> 
        
            {numTrabajos === 7 ? <Stack direction="row" spacing={0}>
          <IconButton aria-label="delete" color="primary"  onClick={handleButtonAddTrabajo}>
          <AddCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center", paddingRight: "40px"}}
              >Añadir nuevo trabajo
            </Typography>
            <IconButton aria-label="delete" color="primary"  onClick={handleButtonRemoveTrabajo7}>
          <RemoveCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center"}}
              >Eliminar trabajo
            </Typography>
            
        </Stack>:
        <Divider sx={{marginTop: "20px", marginBottom: "10px"}} ></Divider>}
        </div>
          }

{numTrabajos > 7 &&
          <div>
          <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
           
                fullWidth
                id="trabajo8"
                label="Posición"
                name="trabajo8"
                autoComplete="trabajo8"
             
                onChange={handleChange}
              />

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
         
                fullWidth
                id="empresa8"
                label="Despacho / Empresa"
                name="empresa8"
                autoComplete="empresa8"
        
                onChange={handleChange}
              />

<Grid container component="main" columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
           
                fullWidth
                id="fechaInicioT8"
                label="Fecha de inicio"
                name="fechaInicioT8"
                autoComplete="fechaInicioT8"
             
                onChange={handleChange}
              />
        </Grid>

        <Grid item xs={6} sm={6} md={6} component={Paper} elevation={0} square sx={{marginBottom: "20px"}}>
      <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
         
                fullWidth
                id="fechaFinT8"
                label="Fecha de fin"
                name="fechaFinT8"
                autoComplete="fechaFinT8"
              
                onChange={handleChange}
              />
        </Grid>
        </Grid>
        <CKEditor  editor={ClassicEditor} data={descripcion8} onChange={(event, editor) => {
  const data = editor.getData()
  setDescripcion8(data)
}}
/> 
        
            {numTrabajos === 8 ? <Stack direction="row" spacing={0}>
          <IconButton aria-label="delete" color="primary"  onClick={handleButtonAddTrabajo}>
          <AddCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center", paddingRight: "40px"}}
              >Añadir nuevo trabajo
            </Typography>
            <IconButton aria-label="delete" color="primary"  onClick={handleButtonRemoveTrabajo8}>
          <RemoveCircleOutlineIcon  fontSize="large" color="primary"/>
          </IconButton>
          <Typography variant="buttontext" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center"}}
              >Eliminar trabajo
            </Typography>
            
        </Stack>:
        <Divider sx={{marginTop: "30px", marginBottom: "30px"}} ></Divider>}
        </div>
          }

      <Typography variant="body1" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "50px", textAlign: "center"}}
              >Indica tus áreas de práctica
            </Typography>

       <Autocomplete
          multiple
          sx={{paddingTop: "20px", paddingBottom: "50px"}}
          id="tags-standard"
          options={listareas}
          getOptionLabel={(option) => option.title}
          onChange={(e, value)=> setArea(value)}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Áreas de práctica"
              placeholder="Áreas de práctica"
            />
          )}
        />
      <Typography variant="body1" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "30px", textAlign: "center"}}
              >Indica tus años de experiencia
            </Typography>
<Slider
        getAriaLabel={() => 'Años de experiencia'}
        value={anos}
        onChange={handleChangeExperiencia}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
        step={1}
        min={0}
        max={20}
        marks={anosExperienciaMovil}
      />

<Divider sx={{marginTop: "40px", margintBottom: "15px"}} >
            <Chip label="Idiomas" sx={{color: "white", bgcolor: "rgba(43, 117, 177, 0.9)"}} />
            </Divider>

            <Stack>
            <FormControl>
      <FormLabel id="demo-row-radio-buttons-group-label">Castellano</FormLabel>
      <RadioGroup
        column
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={castellano}
        onChange={handleChangeCastellano}
      >
        <FormControlLabel value="NA" control={<Radio />} label="N/A" />
        <FormControlLabel value="Básico" control={<Radio />} label="Básico" />
        <FormControlLabel value="Intermedio" control={<Radio />} label="Intermedio" />
        <FormControlLabel value="Avanzado" control={<Radio />} label="Avanzado" />
        <FormControlLabel value="Nativo" control={<Radio />} label="Nativo" />
      </RadioGroup>
    </FormControl>
    </Stack>

    <Stack sx={{paddingTop: "20px"}}>
    <FormControl>
      <FormLabel id="demo-row-radio-buttons-group-label">Inglés</FormLabel>
      <RadioGroup
        column
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={ingles}
        onChange={handleChangeIngles}
      >
        <FormControlLabel value="NA" control={<Radio />} label="N/A" />
        <FormControlLabel value="Básico" control={<Radio />} label="Básico" />
        <FormControlLabel value="Intermedio" control={<Radio />} label="Intermedio" />
        <FormControlLabel value="Avanzado" control={<Radio />} label="Avanzado" />
        <FormControlLabel value="Nativo" control={<Radio />} label="Nativo" />
      </RadioGroup>
    </FormControl>
    </Stack>

    <Stack sx={{marginTop: "20px"}}>
    <TextField   
                sx={{width: '28ch', input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
          
                id="idioma1"
                label="Idioma adicional"
                name="idioma1"
                autoComplete="idioma1"
             
                onChange={handleChange}
              />
              </Stack>
        <FormControl>
      <FormLabel id="demo-row-radio-buttons-group-label"></FormLabel>
      <RadioGroup
        column
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={nivelIdioma1}
        onChange={handleChangeNivelIdioma1}
      >
        <FormControlLabel value="NA" control={<Radio />} label="N/A" />
        <FormControlLabel value="Básico" control={<Radio />} label="Básico" />
        <FormControlLabel value="Intermedio" control={<Radio />} label="Intermedio" />
        <FormControlLabel value="Avanzado" control={<Radio />} label="Avanzado" />
        <FormControlLabel value="Nativo" control={<Radio />} label="Nativo" />
      </RadioGroup>
    </FormControl>

    <Stack sx={{marginTop: "20px"}}>
    <TextField   
                sx={{width: '28ch', input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
          
                id="idioma2"
                label="Idioma adicional"
                name="idioma2"
                autoComplete="idioma2"
             
                onChange={handleChange}
              />
              </Stack>
        <FormControl>
      <RadioGroup
        column
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={nivelIdioma2}
        onChange={handleChangeNivelIdioma2}
      >
        <FormControlLabel value="NA" control={<Radio />} label="N/A" />
        <FormControlLabel value="Básico" control={<Radio />} label="Básico" />
        <FormControlLabel value="Intermedio" control={<Radio />} label="Intermedio" />
        <FormControlLabel value="Avanzado" control={<Radio />} label="Avanzado" />
        <FormControlLabel value="Nativo" control={<Radio />} label="Nativo" />
      </RadioGroup>
    </FormControl>

    <Divider sx={{marginTop: "40px"}} >
            <Chip label="Extras" sx={{color: "white", bgcolor: "rgba(43, 117, 177, 0.9)"}} />
            </Divider>
            <Stack sx={{ paddingTop: "20px" }} spacing={2}>
      <Typography variant="body1" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "20px", textAlign: "center"}}
              >Incluye una breve presentación
            </Typography>
            
              </Stack>
    <CKEditor  editor={ClassicEditor} data={presentacion} onChange={(event, editor) => {
  const data = editor.getData()
  setPresentacion(data)
}}
/> 
        

<Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, backgroundColor: "rgba(43, 117, 177, 0.9)", color: "white"}}

              >
                {buttonText}
              </Button>

            </Box>
          </Box>
                    
          
          
        
      </Grid>

    
 
     </Grid>


:  perfiles.map((perfil) => (
  <Perfil 
  key={perfil.docId} 
  docId={perfil.docId} 
  nombre={perfil.nombre} 
  telefono={perfil.telefono}
  ciudad={perfil.ciudad} 
  visibilidad={perfil.visibilidad} 
  email={perfil.email} 
  carrera1={perfil.carrera1}
  uni1={perfil.uni1}
  fechaInicio1={perfil.fechaInicio1}
  fechaFin1={perfil.fechaFin1}
  notaMedia1={perfil.notaMedia1}
  carrera2={perfil.carrera2}
  uni2={perfil.uni2}
  fechaInicio2={perfil.fechaInicio2}
  fechaFin2={perfil.fechaFin2}
  notaMedia2={perfil.notaMedia2}
  notaMedia3={perfil.notaMedia3}
  notaMedia4={perfil.notaMedia4}
  notaMedia5={perfil.notaMedia5}
  notaMedia6={perfil.notaMedia6}
  notaMedia7={perfil.notaMedia7}
  notaMedia8={perfil.notaMedia8}
  carrera3={perfil.carrera3}
  uni3={perfil.uni3}
  fechaInicio3={perfil.fechaInicio3}
  fechaFin3={perfil.fechaFin3}
  carrera4={perfil.carrera4}
  uni4={perfil.uni4}
  fechaInicio4={perfil.fechaInicio4}
  fechaFin4={perfil.fechaFin4}
  carrera5={perfil.carrera5}
  uni5={perfil.uni5}
  fechaInicio5={perfil.fechaInicio5}
  fechaFin5={perfil.fechaFin5}
  carrera6={perfil.carrera6}
  uni6={perfil.uni6}
  fechaInicio6={perfil.fechaInicio6}
  fechaFin6={perfil.fechaFin6}
  carrera7={perfil.carrera7}
  uni7={perfil.uni7}
  fechaInicio7={perfil.fechaInicio7}
  fechaFin7={perfil.fechaFin7}
  carrera8={perfil.carrera8}
  uni8={perfil.uni8}
  fechaInicio8={perfil.fechaInicio8}
  fechaFin8={perfil.fechaFin8}
  carrera9={perfil.carrera9}
  uni9={perfil.uni9}
  fechaInicio9={perfil.fechaInicio9}
  fechaFin9={perfil.fechaFin9}
  trabajo1={perfil.trabajo1}
  empresa1={perfil.empresa1}
  fechaInicioT1={perfil.fechaInicioT1}
  fechaFinT1={perfil.fechaFinT1}
  descripcion1={perfil.descripcion1}
  trabajo2={perfil.trabajo2}
  empresa2={perfil.empresa2}
  fechaInicioT2={perfil.fechaInicioT2}
  fechaFinT2={perfil.fechaFinT2}
  descripcion2={perfil.descripcion2}
  trabajo3={perfil.trabajo3}
  empresa3={perfil.empresa3}
  fechaInicioT3={perfil.fechaInicioT3}
  fechaFinT3={perfil.fechaFinT3}
  descripcion3={perfil.descripcion3}
  trabajo4={perfil.trabajo4}
  empresa4={perfil.empresa4}
  fechaInicioT4={perfil.fechaInicioT4}
  fechaFinT4={perfil.fechaFinT4}
  descripcion4={perfil.descripcion4}
  trabajo5={perfil.trabajo5}
  empresa5={perfil.empresa5}
  fechaInicioT5={perfil.fechaInicioT5}
  fechaFinT5={perfil.fechaFinT5}
  descripcion5={perfil.descripcion5}
  trabajo6={perfil.trabajo6}
  empresa6={perfil.empresa6}
  fechaInicioT6={perfil.fechaInicioT6}
  fechaFinT6={perfil.fechaFinT6}
  descripcion6={perfil.descripcion6}
  trabajo7={perfil.trabajo7}
  empresa7={perfil.empresa7}
  fechaInicioT7={perfil.fechaInicioT7}
  fechaFinT7={perfil.fechaFinT7}
  descripcion7={perfil.descripcion7}
  trabajo8={perfil.trabajo8}
  empresa8={perfil.empresa8}
  fechaInicioT8={perfil.fechaInicioT8}
  fechaFinT8={perfil.fechaFinT8}
  descripcion8={perfil.descripcion8}
  numGrados={perfil.numGrados}
  numPosGrados={perfil.numPosGrados}
  numTrabajos={perfil.numTrabajos}
  area={perfil.area}
  castellano={perfil.castellano}
  ingles={perfil.ingles}
  presentacion={perfil.presentacion}
  anos={perfil.anos}
  idioma1={perfil.idioma1}
  nivelIdioma1={perfil.nivelIdioma1}
  idioma2={perfil.idioma2}
  nivelIdioma2={perfil.nivelIdioma2}
  onUpdate={handleUpdatePerfil}   />
 ) )

}
</Box>





    </CssBaseline>
    </AuthProvider>
  )
}

export default PerfilProfesional
