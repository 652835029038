import React, { useState } from "react";
import { Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import ListItemIcon from "@mui/material/ListItemIcon";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/material/styles";
import getStripe from "../lib/getStripe";
import { loadStripe } from "@stripe/stripe-js";
import AuthProvider from "../components/authProvider";
import { useNavigate } from "react-router";
import { getPerfil, insertNewPerfil, updatePerfil, db} from "../firebase-config";
import { doc, addDoc, onSnapshot, collection } from 'firebase/firestore';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import SendIcon from '@mui/icons-material/Send';
import { LoadingButton } from "@mui/lab";

function Checkout() {
  const [currentUser, setCurrentUser] = useState({});
  const [perfiles, setPerfiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingAnual, setLoadingAnual] = useState(false);
  const [loadingMensual, setLoadingMensual] = useState(false);

  const navigate = useNavigate();

  async function stripePaymentHandler(priceId, uid){
    setLoading(true)
    const stripe = await getStripe();
    
      const docRef = await addDoc(collection(db, 'customers',uid, 'checkout_sessions'), {
        price: priceId,
        success_url: window.location.origin,
        cancel_url: window.location.origin
      });
      
      onSnapshot(doc(db, 'customers',uid, 'checkout_sessions', docRef.id), (snap) => {
        const { error, sessionId } = snap.data();
      
        if (error) {
          console.log(error)
          setLoading(false);
          alert('An error occurred', error.message);
        }
      
        if (sessionId) {
          stripe.redirectToCheckout({ sessionId });
          setLoading(false)
        }
      });
  }

  async function handleUserLoggedIn(user) {
    setCurrentUser(user);
    console.log("Estás registrado");
    console.log(user.displayName);
    const resPerfil = await getPerfil(user.uid);
    setPerfiles([...resPerfil]);
    console.log(perfiles.nombre);
    console.log(perfiles);
  }

  function handleUserNotRegistered(user) {
    navigate("../signin");
  }

  function handleUserNotLoggedIn() {
    navigate("../signin");
  }

  // Monthly subscription handler and priceId
    function handleCheckout() {
    stripePaymentHandler('price_1Mtp2zEkjW0mzz5H3Pqj57zi', currentUser.uid);
    setLoadingMensual(true);
  }

  // Annual subscription handler and PriceId
   function handleCheckoutAnual() {
    stripePaymentHandler('price_1Mtp2PEkjW0mzz5HSLY61NyO', currentUser.uid);
    setLoadingAnual(true);
  }
  
  return (
    <AuthProvider
      onUserLoggedIn={handleUserLoggedIn}
      onUserNotRegistered={handleUserNotRegistered}
      onUserNotLoggedIn={handleUserNotLoggedIn}
    >
      <div>
        <Box
          height="100vh"
          sx={{
            paddingTop: "30vh",
            display: {
              xl: "block",
              l: "block",
              m: "none",
              s: "none",
              xs: "none",
            },
          }}
        >
          <Stack
            display="flex"
            justifyContent="center"
            sx={{
              alignContent: "center",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid
              container
              spacing={2}
              sx={{
                alignContent: "center",
                alignItems: "center",
                justifyContent: "center",
                bgcolor: "#f0f4f5",
                borderRadius: "25px",
                marginTop: "20px",
                paddingRight: "20px",
                paddingBottom: "30px",
                marginRight: "15px",
                maxWidth: "800px",
              }}
            >
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Stack
                  sx={{
                    alignContent: "center",
                    alignItems: "center",
                    justifyContent: "center",
                    bgcolor: "#f0f4f5",
                    opacity: "0.7",
                    borderRadius: "25px",
                    marginTop: "20px",
                    paddingLeft: "20px",
                    paddingBottom: "30px",
                    marginLeft: "15px",
                  }}
                >
                  <Typography
                    sx={{
                      paddingBottom: "10px",
                      paddingLeft: "20px",
                      paddingRight: "20px",
                      fontSize: "20px",
                      fontWeight: "700",
                    }}
                  >
                    Mensual
                  </Typography>
                  <Typography
                    sx={{
                      paddingBottom: "15px",
                      paddingLeft: "20px",
                      paddingRight: "20px",
                      fontSize: "14px",
                      fontWeight: "700",
                      textAlign: "center",
                    }}
                  >
                    Renueva mes a mes y <br /> cancela cuando quieras.
                  </Typography>
                  <nav aria-label="main mailbox folders">
                    <Grid container>
                      <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                        <Typography
                          sx={{
                            paddingBottom: "15px",
                            paddingLeft: "20px",
                            fontSize: "30px",
                            fontWeight: "700",
                          }}
                        >
                          24,95
                        </Typography>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <Typography
                          sx={{
                            paddingTop: "15px",
                            paddingRight: "20px",
                            fontSize: "15px",
                            fontWeight: "700",
                          }}
                        >
                          /mes
                        </Typography>
                      </Grid>
                    </Grid>
                  </nav>
                  
                  <LoadingButton
          onClick={handleCheckout}
       
          loading={loading}
        
          variant="contained"
          loadingIndicator="Cargando…"
        >
          <span>ACTIVAR PREMIUM MENSUAL</span>
        </LoadingButton>
                </Stack>
              </Grid>

              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Stack
                  sx={{
                    alignContent: "center",
                    alignItems: "center",
                    justifyContent: "center",
                    bgcolor: "#f0f4f5",
                    opacity: "0.7",
                    borderRadius: "25px",
                    marginTop: "20px",
                    paddingLeft: "20px",
                    paddingBottom: "30px",
                    marginLeft: "15px",
                  }}
                >
                  <Typography
                    sx={{
                      paddingBottom: "10px",
                      paddingLeft: "20px",
                      paddingRight: "20px",
                      fontSize: "20px",
                      fontWeight: "700",
                    }}
                  >
                    Anual
                  </Typography>
                  <Typography
                    sx={{
                      paddingBottom: "15px",
                      paddingLeft: "20px",
                      paddingRight: "20px",
                      fontSize: "14px",
                      fontWeight: "700",
                      textAlign: "center",
                    }}
                  >
                    Accede durante un año <br /> a un precio excepcional.
                  </Typography>
                  <nav aria-label="main mailbox folders">
                    <Grid container>
                      <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                        <Typography
                          sx={{
                            paddingBottom: "15px",
                            paddingLeft: "20px",
                            fontSize: "30px",
                            fontWeight: "700",
                          }}
                        >
                          12,95
                        </Typography>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <Typography
                          sx={{
                            paddingTop: "15px",
                            paddingRight: "20px",
                            fontSize: "15px",
                            fontWeight: "700",
                          }}
                        >
                          /mes
                        </Typography>
                      </Grid>
                    </Grid>
                  </nav>
  
                  <LoadingButton
          onClick={handleCheckoutAnual}
       
          loading={loading}
        
          variant="contained"
          loadingIndicator="Cargando…"
        >
          <span>ACTIVAR PREMIUM ANUAL</span>
        </LoadingButton>
                </Stack>
              </Grid>
              <Typography
                color="#7b7c7d"
                sx={{
                  paddingBottom: "15px",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                  paddingTop: "10px",
                  fontSize: "13px",
                  fontWeight: "700",
                  textAlign: "center",
                }}
              >
                Todos los precios incluyen el IVA. Tu suscripción se renovará
                automáticamente <br /> en su fecha de vencimiento. Puedes
                cancelar la renovación de tu suscripción <br /> de forma muy
                sencilla en la sección "Mi cuenta Premium".
              </Typography>
            </Grid>
          </Stack>
        </Box>

        <Box
          height="100vh"
          sx={{
            display: {
              xl: "none",
              l: "none",
              m: "inline",
              s: "inline",
              xs: "inline",
            },
          }}
        >
          <Stack
            display="flex"
            justifyContent="center"
            sx={{
              alignContent: "center",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Stack
              sx={{
                alignContent: "center",
                alignItems: "center",
                justifyContent: "center",
                bgcolor: "#f0f4f5",
                opacity: "0.7",
                borderRadius: "25px",
                marginTop: "20px",
                paddingLeft: "20px",
                paddingBottom: "30px",
                paddingRight: "20px",
                paddingTop: "20px",
                marginLeft: "15px",
              }}
            >
              <Typography
                sx={{
                  paddingBottom: "10px",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                  fontSize: "20px",
                  fontWeight: "700",
                }}
              >
                Mensual
              </Typography>
              <Typography
                sx={{
                  paddingBottom: "15px",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                  fontSize: "14px",
                  fontWeight: "700",
                  textAlign: "center",
                }}
              >
                Renueva mes a mes y <br /> cancela cuando quieras.
              </Typography>
              <nav aria-label="main mailbox folders">
                <Grid container>
                  <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                    <Typography
                      sx={{
                        paddingBottom: "15px",
                        paddingLeft: "20px",
                        fontSize: "30px",
                        fontWeight: "700",
                      }}
                    >
                      24,95
                    </Typography>
                  </Grid>
                  <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                    <Typography
                      sx={{
                        paddingTop: "15px",
                        paddingRight: "20px",
                        fontSize: "15px",
                        fontWeight: "700",
                      }}
                    >
                      /mes
                    </Typography>
                  </Grid>
                </Grid>
              </nav>
              <LoadingButton
          onClick={handleCheckout}
       
          loading={loading}
        
          variant="contained"
          loadingIndicator="Cargando…"
        >
          <span>ACTIVAR PREMIUM MENSUAL</span>
        </LoadingButton>
            </Stack>

            <Stack
              sx={{
                alignContent: "center",
                alignItems: "center",
                justifyContent: "center",
                bgcolor: "#f0f4f5",
                opacity: "0.7",
                borderRadius: "25px",
                marginTop: "20px",
                paddingLeft: "20px",
                paddingBottom: "30px",
                paddingRight: "20px",
                paddingTop: "20px",
                marginLeft: "15px",
              }}
            >
              <Typography
                sx={{
                  paddingBottom: "10px",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                  fontSize: "20px",
                  fontWeight: "700",
                }}
              >
                Anual
              </Typography>
              <Typography
                sx={{
                  paddingBottom: "15px",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                  fontSize: "14px",
                  fontWeight: "700",
                  textAlign: "center",
                }}
              >
                Accede durante un año <br /> a un precio excepcional.
              </Typography>
              <nav aria-label="main mailbox folders">
                <Grid container>
                  <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                    <Typography
                      sx={{
                        paddingBottom: "15px",
                        paddingLeft: "20px",
                        fontSize: "30px",
                        fontWeight: "700",
                      }}
                    >
                      12,95
                    </Typography>
                  </Grid>
                  <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                    <Typography
                      sx={{
                        paddingTop: "15px",
                        paddingRight: "20px",
                        fontSize: "15px",
                        fontWeight: "700",
                      }}
                    >
                      /mes
                    </Typography>
                  </Grid>
                </Grid>
              </nav>
              <LoadingButton
          onClick={handleCheckoutAnual}
       
          loading={loading}
        
          variant="contained"
          loadingIndicator="Cargando…"
        >
          <span>ACTIVAR PREMIUM ANUAL</span>
        </LoadingButton>
            </Stack>

            <Typography
              color="#7b7c7d"
              sx={{
                paddingBottom: "15px",
                paddingLeft: "25px",
                paddingRight: "25px",
                paddingTop: "30px",
                fontSize: "13px",
                fontWeight: "700",
                textAlign: "center",
              }}
            >
              Todos los precios incluyen el IVA. Tu suscripción se renovará
              automáticamente en su fecha de vencimiento. Puedes cancelar la
              renovación de tu suscripción de forma muy sencilla en la sección
              "Mi cuenta Premium".
            </Typography>
          </Stack>
        </Box>
      </div>
    </AuthProvider>
  );
}

export default Checkout;
