import ReactDOM from "react-dom/client";
import * as React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import App from "./App";
import Jobs from "./pages/Jobs";
import Home from "./pages/Home";
import SingleJob from "./pages/SingleJob";
import CreateJob from "./pages/CreateJob";
import Login from "./pages/Login";


const root = ReactDOM.createRoot(
  document.getElementById("root")
);
root.render(
  <BrowserRouter>
    <App/>
  </BrowserRouter>

  );
