export default [
    { title: "Administrativo" },
    { title: "Banking&Finance" },
    { title: "Competencia" },
    { title: "Compliance" },
    { title: "Concursal" },
    { title: "Consumo" },
    { title: "Contract Management" },
    { title: "Energía" },
    { title: "Farma" },
    { title: "Fiscal" },
    { title: "Generalista" },
    { title: "Inmobiliario" },
    { title: "Laboral" },
    { title: "Medio ambiente" },
    { title: "Mercantil" },
    { title: "Nuevas tecnologías" },
    { title: "Procesal civil" },
    { title: "Propiedad industrial" },
    { title: "Propiedad intelectual" },
    { title: "Protección de datos" },
    { title: "Regulatorio Financiero" },
    { title: "Regulatorio" },
    { title: "Seguros" },
    { title: "Urbanismo" },

    
  ];