export default [
    { id: 1,
      title: 'Abogado procesalista', 
      employer: "Garrigues",
      city: "Madrid",
      area: "Procesal",
      experience: "1 a 4 años",
      salary: 20,
      isBestLawFirm: "true",
      ranking: "8,98"   
    },
    { id: 2,
      title: 'Abogado fiscalista', 
    employer: "Cuatrecasas",
    city: "Barcelona",
    area: "Fiscal",
    experience: "5 a 8 años",
    salary: 40,
    isBestLawFirm: "false",
    ranking: "8,78"   
  },
  { id: 3,
    title: 'Abogado procesalista', 
  employer: "Garrigues",
  city: "Madrid",
  area: "Procesal",
  experience: "5 a 8 años",
  salary: 20,
  isBestLawFirm: "true",
  ranking: "8,98"   
},
{ id: 4,
  title: 'Abogado fiscalista', 
employer: "Cuatrecasas",
city: "Barcelona",
area: "Fiscal",
experience: "1 a 4 años",
salary: 40,
isBestLawFirm: "false",
ranking: "8,78"   
},
  ];
