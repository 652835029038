
import React from "react";
import Header from "./components/Header";
import { Outlet, Link } from "react-router-dom";
import {createTheme, ThemeProvider} from "@mui/material";
import Home from "./pages/Home"
import { BrowserRouter, Routes, Route} from "react-router-dom";
import { useState } from "react";
import Jobs from "./pages/Jobs";
import CreateJob from "./pages/CreateJob";
import Login from "./pages/Login";
import SingleJob from "./pages/SingleJob";
import Signup from "./pages/Signup";
import Signin from "./components/Signin";
import { UserAuthContextProvider } from "./context/UserAuthContext";
import AllJobs from "./pages/AllJobs";
import LimitedJobs from "./pages/LimitedJobs";
import text from "./pages/text";
import Dashboard from "./pages/Dashboard";
import PerfilProfesional from "./pages/PerfilProfesional";
import IniciarSesion from "./pages/IniciarSesion";
import CssBaseline from '@mui/material/CssBaseline';
import OnlySignin from "./components/OnlySignIn";
import Rankings from "./pages/Rankings";
import SingleFirm from "./pages/SingleFirm";
import NuevaNota from "./pages/NuevaNota";
import Checkout from "./pages/Checkout";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import MiCuentaPremium from "./pages/MiCuentaPremium";
import Avisolegal from "./pages/Avisolegal";
import Politicadecookies from "./pages/Politicadecookies";
import MejoresDespachosAmbienteLaboral from "./pages/MejoresDespachosAmbienteLaboral";
import MejoresDespachosTipoDeTrabajo from "./pages/MejoresDespachosTipoDeTrabajo";
import MejoresDespachosCarrera from "./pages/MejoresDespachosCarrera";
import MejoresDespachosConciliacion from "./pages/MejoresDespachosConciliacion";
import MejoresDespachosCulturaDeFirma from "./pages/MejoresDespachosCulturaDeFirma";
import MejoresDespachosDiversidad from "./pages/MejoresDespachosDiversidad";
import MejoresDespachosFormacion from "./pages/MejoresDespachosFormacion";
import MejoresDespachosIgualdad from "./pages/MejoresDespachosIgualdad";
import MejoresDespachosProBono from "./pages/MejoresDespachosProBono";
import PruebaGratis from "./pages/PruebaGratis";
import ReminderPerfil from "./pages/ReminderPerfil";
import Welcome from "./pages/Welcome";
import Welcome2 from "./pages/Welcome2";
import CreateJobLawFirm from "./pages/CreateJobLawFirm";
import DashboardLawFirms from "./pages/DashboardLawFirms";
import MyJobs from "./pages/MyJobs";
import EditJobLawFirm from "./pages/EditJobLawFirm";
import BuscadorCandidatos from "./pages/BuscadorCandidatos";

const theme = createTheme ({
  palette: {
    primary: {
      main: "rgba(43, 117, 177, 0.9)"
    }
  }
})


function App() {
  const [isAuth, setIsAuth] = useState(localStorage.getItem("isAuth"));
  const [trabajoId, setTrabajoId] = useState("");
  const [firmId, setFirmId] = useState("");
  

  const getTrabajoIdHandler = (id) => {
    console.log(id);
    setTrabajoId(id);
  }

  const getFirmIdHandler = (id) => {
    console.log(id);
    setFirmId(id);
  }

  return (

    <UserAuthContextProvider>
    <CssBaseline />  
    <Routes>
    <Route path="/" element={<Home />} />
      <Route path="/Dashboard" element={<Dashboard getTrabajoId={getTrabajoIdHandler} />} />
      <Route path="/DashboardLawFirms" element={<DashboardLawFirms getTrabajoId={getTrabajoIdHandler} />} />
      <Route path="/MyJobs" element={<MyJobs getTrabajoId={getTrabajoIdHandler} />} />
      <Route path="myjobs/:jobId" element={<EditJobLawFirm id={trabajoId} setTrabajoId={setTrabajoId} />} />
      <Route path="/buscadorcandidatos" element={<BuscadorCandidatos getTrabajoId={getTrabajoIdHandler} />} />
      <Route path="/Welcome" element={<Welcome getTrabajoId={getTrabajoIdHandler} />} />
      <Route path="/Welcome2" element={<Welcome2 getTrabajoId={getTrabajoIdHandler} />} />
      <Route path="/PerfilProfesional" element={<PerfilProfesional  />} />
      <Route path="/Checkout" element={<Checkout  />} />
      <Route path="/pruebagratis" element={<PruebaGratis  />} />
      <Route path="/reminderpefil" element={<ReminderPerfil  />} />
      <Route path="Jobs" element={<Jobs />} />
      <Route path="Jobs/:jobId" element={<SingleJob id={trabajoId} setTrabajoId={setTrabajoId}  />} />
      <Route path="Rankings/:firmId" element={<SingleFirm id={firmId} setFirmId={setFirmId}  />} />
 
      <Route path="/createjob" element={<CreateJob />} />
      <Route path="/createjoblawfirm" element={<CreateJobLawFirm />} />
      
      <Route path="/micuentapremium" element={<MiCuentaPremium />} />
      <Route path="/avisolegal" element={<Avisolegal />} />
      <Route path="/politicadecookies" element={<Politicadecookies/>} />

      <Route path="/nuevanota" element={<NuevaNota />} />
      <Route path="/login" element={<Login />} />
      <Route path="/iniciarsesion" element={<IniciarSesion />} />
      <Route path="/Signup" element={<Signup />} />
      <Route path="/Signin" element={<Signin />} />
      <Route path="/Rankings" element={<Rankings getFirmId={getFirmIdHandler}/>} />
      <Route path="/mejoresdespachosambientelaboral" element={<MejoresDespachosAmbienteLaboral getFirmId={getFirmIdHandler}/>} />
      <Route path="/mejoresdespachoscarrera" element={<MejoresDespachosCarrera getFirmId={getFirmIdHandler}/>} />
      <Route path="/mejoresdespachosconciliacion" element={<MejoresDespachosConciliacion getFirmId={getFirmIdHandler}/>} />
      <Route path="/mejoresdespachosculturadefirma" element={<MejoresDespachosCulturaDeFirma getFirmId={getFirmIdHandler}/>} />
      <Route path="/mejoresdespachosDiversidad" element={<MejoresDespachosDiversidad getFirmId={getFirmIdHandler}/>} />
      <Route path="/mejoresdespachosformacion" element={<MejoresDespachosFormacion getFirmId={getFirmIdHandler}/>} />
      <Route path="/mejoresdespachosigualdad" element={<MejoresDespachosIgualdad getFirmId={getFirmIdHandler}/>} />
      <Route path="/mejoresdespachosprobono" element={<MejoresDespachosProBono getFirmId={getFirmIdHandler}/>} />
      <Route path="/mejoresdespachostipodetrabajo" element={<MejoresDespachosTipoDeTrabajo getFirmId={getFirmIdHandler}/>} />
      <Route path="/OnlySignin" element={<OnlySignin />} />
      <Route path="/Header" element={<Header />} />
      <Route path="/AllJobs" element={<AllJobs getTrabajoId={getTrabajoIdHandler} />} />
      <Route path="/LimitedJobs" element={<LimitedJobs getTrabajoId={getTrabajoIdHandler} />} />
      <Route path="/text" element={<text getTrabajoId={getTrabajoIdHandler} />} />
      <Route path="/forgotpasswordpage" element={<ForgotPasswordPage />} />
  </Routes>
  </UserAuthContextProvider>
  );
}

export default App;
