import {React, useState, useEffect } from "react";
import Header from "../components/Header";
import { Outlet, Link } from "react-router-dom";
import {createTheme, ThemeProvider} from "@mui/material";
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import JobCard from "../components/JobCard";
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Slider from '@mui/material/Slider';
import JobList from "./JobList";
import { useUserAuth } from "../context/UserAuthContext";
import TrabajoDataService from '../services/trabajo.services';


function Jobs() {
  const areas = ['Fiscal', 'Procesal'];
  const experiencias = ["1 a 4 años", "5 a 8 años"];
  const ciudades = ["Madrid", "Barcelona"]
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [experiencia, setExperiencia] = React.useState(null);
  const [inputExperiencia, setInputExperiencia] = React.useState('');
  const [ciudad, setCiudad] = React.useState(null);
  const [inputCiudad, setInputCiudad] = React.useState('');
  let {user} = useUserAuth();
  
 
  
  const eachjob = JobList.map((each) => {
    if ((each.area === value || value === null) && (each.experience === experiencia || experiencia === null) && (each.city === ciudad || ciudad === null)) {
    return (
      <JobCard 
      id={each.id}
      title={each.title}
      employer={each.employer}
      city={each.city}
      area={each.area}
      experience={each.experience}
      salary={each.salary}
      isBestLawFirm={each.isBestLawFirm}
      ranking={each.ranking}
      />
    )}
  })

  return (
    <CssBaseline>
    <Header />
  
    <Grid container componente="main" spacing={0} sx={{justifyContent: "center"}}>
      <Grid item xs={1} sm={1} md={1} square>
      </Grid>
      <Grid item xs={3} sm={3} md={3} square>
      <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: "flex-end",
                justifyContent: "center",
                marginTop: "20px",

             }}
            >
      <Autocomplete
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        id="controllable-states-demo"
        options={areas}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label="Área de práctica" />}
      />



      </Box>
      </Grid>
      
      <Grid item xs={3} sm={3} md={3} square>
      <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: "center",
                justifyContent: "center",
                marginTop: "20px",

             }}
            >
      <Autocomplete
        value={experiencia}
        onChange={(event, newExperiencia) => {
          setExperiencia(newExperiencia);
        }}
        inputValue={inputExperiencia}
        onInputChange={(event, newInputExperiencia) => {
          setInputExperiencia(newInputExperiencia);
        }}
        id="controllable-states-demo"
        options={experiencias}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label="Experiencia" />}
      />
      </Box>
      </Grid>

      <Grid item xs={3} sm={3} md={3} square>
      <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: "flex-start",
                justifyContent: "center",
                marginTop: "20px",

             }}
            >
      <Autocomplete
        value={ciudad}
        onChange={(event, newCiudad) => {
          setCiudad(newCiudad);
        }}
        inputValue={inputCiudad}
        onInputChange={(event, newInputCiudad) => {
          setInputCiudad(newInputCiudad);
        }}
        id="controllable-states-demo"
        options={ciudades}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label="Ciudad" />}
      />
      </Box>
      </Grid>

      <Grid item xs={1} sm={1} md={1} square>
      </Grid>

    </Grid>
    <Grid container component="main" 
        sx={{ 
          marginTop: "40px",
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat', }}>
          <Grid item xs={12} sm={12} md={12} square>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: "center",
                justifyContent: "center",
                bgcolor: 'white',
                backgroundSize: 'cover',
                backgroundPosition: 'right',
                marginTop: "20px"

             }}
             
            >
            {eachjob}
            </Box>
          </Grid>
      </Grid>
      <Outlet/>
    </CssBaseline>
  )
}



export default Jobs;