import React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import FavoriteIcon from '@mui/icons-material/Favorite';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { borderColor, borderRight } from '@mui/system';
import JobList from "./JobList";
import { Link, Outlet } from "react-router-dom";
import { useParams } from "react-router-dom";
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import Stack from '@mui/material/Stack';
import { useState, useEffect } from 'react';
import TrabajoDataService from '../services/trabajo.services';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, onSnapshot, addDoc, deleteDoc, doc, query, where, orderBy, getDoc, getDocs,  } from 'firebase/firestore';
import parse from "html-react-parser";
import CssBaseline from '@mui/material/CssBaseline';
import { getNota } from '../firebase-config';
import { getNotas } from '../firebase-config';
import Rating from '@mui/material/Rating';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import Brightness1OutlinedIcon from '@mui/icons-material/Brightness1Outlined';
import Pie from '../components/Pie';
import Pagination from '@mui/material/Pagination';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AuthProvider from '../components/authProvider';
import { useNavigate } from 'react-router';
import { getPerfil, insertNewPerfil, updatePerfil } from '../firebase-config';
import InboxIcon from '@mui/icons-material/Inbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import getPaymentsByUID from '../components/getPaymentsByUID';
import Headerbis from '../components/Headerbis';
import OnlyRegister from '../components/onlyRegister';



export default function SingleJob ( {id, setTrabajoId} ) {
  
  let params = useParams();
  const db= getFirestore()


  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));


  const [currentUser, setCurrentUser] = useState({});
  const [perfiles, setPerfiles] = useState([ ]);
  const [payments, setPayments] = useState([]);
  const [payment1, setPayment1] = useState([]);
  const [isPremium, setIsPremium] = useState(false);
  const [isOldPremium, setIsOldPremium] = useState(false);
  const [idUsuario, setIdUsuario] = useState();
  const navigate = useNavigate();

  async function handleUserLoggedIn(user) {
    setCurrentUser(user);
    console.log("Estás registrado");
    console.log(user.displayName);
    console.log(perfiles.length);
    const resPerfil = await getPerfil(user.uid);
    setPerfiles([...resPerfil]);
    const payments = await getPaymentsByUID(user.uid);
      setPayments([...payments])
      console.log(perfiles);
      console.log()
      setIsOldPremium(user.premiumUser)
      setIdUsuario(user.uid)

    console.log(perfiles.nombre);
    payments.map((payment) => {
      payment1.push(payment.status)
  
    })
  
  console.log(payment1)
  const premium = (element) => (element === "active" || element === "trialing")
  setIsPremium(payment1.some(premium))
  console.log(isPremium)
    
   }

   console.log(perfiles.length)

   useEffect(() => {
    async function getPayments() {
      if (!currentUser) return;
      console.log("user por usar", currentUser);
      const payments = await getPaymentsByUID(currentUser);
      setPayments(payments);
    }
    getPayments();
    console.log(payments)
    
  }, []);

   function handleUserNotRegistered(user) {
    /*navigate("../signin");*/
setIdUsuario(0);
console.log(idUsuario);
console.log("Hola")
   }
   
   function handleUserNotLoggedIn() {
    /*navigate("../alljobs");*/
    setIdUsuario(0);
console.log(idUsuario);
console.log("Hola")
   }
  const selectedJob = JobList.filter(object => {
    const id = object.id;
    const correct = params.jobId;
    return id === correct;
  });

  const [trabajos, setTrabajos] = useState([]);
  useEffect(() => {
  getTrabajos();
  console.log(params.jobId);
}, [])

const getTrabajos = async () => {
  const data = await TrabajoDataService.getAllTrabajos();
  console.log(data.docs);
  setTrabajos(data.docs.map((doc) => ({...doc.data(), id: doc.id})))

}

const [titulo, setTitulo] = useState("");
const [despacho, setDespacho] = useState("");
const [area, setArea] = useState("");
const [ciudad, setCiudad] = useState("");
const [area1, setArea1] = useState("");
const [area2, setArea2] = useState("");
const [area3, setArea3] = useState("");
const [experiencia1, setExperiencia1] = useState("");
const [experiencia2, setExperiencia2] = useState("");
const [enlace, setEnlace] = useState("");
const [texto, setTexto] = useState("");
const [firms, setFirms] = useState([]);
const [teletrabajo, setTeletrabajo] = useState();
const [tipoDespacho, setTipoDespacho] = useState();
const [notas, setNotas] = useState();
const [tamanoDespacho, setTamanoDespacho] = useState("");
  const [tipoDerecho, setTipoDerecho] = useState("");
  const [comentarios, setComentarios] = useState([]);
  const [tipoDeTrabajo, setTipoDeTrabajo] = useState();
  const [ambiente, setAmbiente] = useState();
  const [positivo, setPositivo] = useState([]);
  const [negativo, setNegativo] = useState([]);
  const [general, setGeneral] = useState();
  const [conciliacion, setConciliacion] = useState(); 
  const [trabajoAtractivo, setTrabajoAtractivo] = useState();
  const [trabajoVariado, setTrabajoVariado] = useState();
  const [carrera, setCarrera] = useState();
  const [formacionTotal, setFormacionTotal] = useState();
  const [diversidadTotal, setDiversidadTotal] = useState();
  const [valores, setValores] = useState();
  const [proBono, setProBono] = useState();
  const [igualdadTotal, setIgualdadTotal] = useState();
  const [techos, setTechos] = useState();
  const [cincoAnos, setCincoAnos] = useState();
  const [tieneValoraciones, setTieneValoraciones] = useState(false);
  const [rangoSalarial1, setRangoSalarial1] = useState([]);
  const [rangoSalarial2, setRangoSalarial2] = useState([]);
  const [logoOferta, setLogoOferta] = useState();


const selectedTrabajo = trabajos.filter((doc => doc.id===params.jobId));

console.log(selectedTrabajo)

  const docRef = doc(db, "trabajos", params.jobId)


  const fetchPost = async () => {
       
    await getDocs(collection(db, "notas"))
        .then((querySnapshot)=>{              
            const newData = querySnapshot.docs
                .map((doc) => ({...doc.data(), id:doc.id }));
            setNotas(newData);                
            console.log(notas, newData);
        })

}

useEffect(()=>{
  fetchPost();
}, [])
    console.log(notas)



  useEffect(() => {
      getDoc(docRef)
      .then((doc) => {
      
      const objeto = doc.data();
      setTitulo(objeto.titulo);
      setDespacho(objeto.despacho);
      setArea(objeto.area);
      setCiudad(objeto.ciudad);
      setTexto(objeto.texto);
      const numeroAreasPractica = objeto.area.length;
      setArea1(objeto.area[0].title);
      if (numeroAreasPractica > 1)
      {setArea2(objeto.area[1].title);}
      if (numeroAreasPractica > 2)
      {setArea3(objeto.area[2].title);}
  
      setExperiencia1(objeto.experiencia[0]);
      setExperiencia2(objeto.experiencia[1]);
      setEnlace(objeto.enlaceOferta);
      setTieneValoraciones(objeto.tieneValoraciones);
      setTipoDespacho(objeto.tipoDespacho.title);
      setRangoSalarial1(objeto.rangoSalarial[0]);
      setRangoSalarial2(objeto.rangoSalarial[1]);
      setLogoOferta(objeto.logoOferta)

      })
      console.log(despacho);
      
    }, [])

    const numeroAreasPractica = area.length;

    console.log(notas)
    console.log(tieneValoraciones)

   
    useEffect(()=>{
      if (tieneValoraciones === true) {
        const selectedNota = notas.filter((doc => doc.inputValue===despacho));
        console.log(selectedNota)
        setTeletrabajo(selectedNota[0].teletrabajo)
        setComentarios(selectedNota[0].comentarios)
        setTipoDeTrabajo(selectedNota[0].tipodetrabajo)
        setAmbiente(selectedNota[0].ambiente)
        setPositivo(selectedNota[0].positivo)
        setNegativo(selectedNota[0].negativo)
        setGeneral(selectedNota[0].general)
        setConciliacion(selectedNota[0].conciliacion)
        setCarrera(selectedNota[0].carrera)
        setFormacionTotal(selectedNota[0].formacion)
        setDiversidadTotal(selectedNota[0].diversidadtotal)
        setValores(selectedNota[0].valores)
        setProBono(selectedNota[0].probono)
        setIgualdadTotal(selectedNota[0].igualdadtotal)
        setCincoAnos(selectedNota[0].cincoanos)
        console.log(teletrabajo)
        }
  }, [trabajos])

  const StyledRating = styled(Rating)({
    '& .MuiRating-iconFilled': {
      color: '#037bfc',
    },
    '& .MuiRating-iconHover': {
      color: '#037bfc',
    },
  });

 
  const [page, setPage] = useState(1);
  const itemsPerPage = 3;
  const noOfPages = 1;
  const noOfPages2 = 1;
  const noOfPages3 = 1;
  if (comentarios !== undefined)
  {
  const noOfPages = Math.ceil(comentarios.length / itemsPerPage);
} else {
  const noOfPages = 0;
}
  const handleChange = (event, value) => {
    setPage(value);
  };

  const [page2, setPage2] = useState(1);
  const itemsPerPage2 = 3;
  if (positivo !== undefined)
  {
  const noOfPages2 = Math.ceil(positivo.length / itemsPerPage2);
} 

 if (positivo === undefined) {
  const noOfPages2 = "0";
  console.log("Hola")
}
  const handleChange2 = (event, value) => {
    setPage2(value);
  };

  const [page3, setPage3] = useState(1);
  const itemsPerPage3 = 3;
  if (negativo !== undefined)
  {
  const noOfPages3 = Math.ceil(negativo.length / itemsPerPage3);
} else {
  const noOfPages3 = 0;
}
  const handleChange3 = (event, value) => {
    setPage3(value);
  };


      
  const Listas = () => (
    
      <div>
      <List sx={{ width: '100%', maxWidth: 800, bgcolor: 'background.paper' }}>
 
        {comentarios.map(comentario => 
        <div>
        <ListItem alignItems="flex-start">
        <ListItemAvatar>
        <Avatar>
            <AccountCircleIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={comentario}
          
        />
        </ListItem>
        <Divider variant="inset" component="li" />
        </div>
        )}
      
      </List>

    </div>


  
  )

  const Positivo = () => (
    <div>
      <List sx={{ width: '100%', maxWidth: 800, bgcolor: 'background.paper' }}>
        {positivo.map(comentario => 
        <div>
        <ListItem alignItems="flex-start">
        
        <ListItemText
          primary={comentario}
         
        />
        </ListItem>
        <Divider component="li" />
        </div>
        )}
      </List>
    </div>


  
  )

  const Negativo = () => (
    <div>
      <List sx={{ width: '100%', maxWidth: 800, bgcolor: 'background.paper' }}>
        {negativo.map(comentario => 
        <div>
        <ListItem alignItems="flex-start">
  
        <ListItemText
          primary={comentario}
          
        />
        </ListItem>
        <Divider component="li" />
        </div>
        )}
      </List>
    </div>
  )

  console.log(isPremium)
  console.log(area2)
  console.log(idUsuario)

  return (

       <div> 
        <AuthProvider 
    onUserLoggedIn={handleUserLoggedIn}
    onUserNotRegistered={handleUserNotRegistered}
    onUserNotLoggedIn={handleUserNotLoggedIn}
    > 
    <Headerbis />

    
    <Box sx={{ flexGrow: 1, display: { xl: 'inline', l: "inline", m: "inline", s:"none", xs: 'none' }}}>
      
    <Grid container spacing={2} paddingTop="30px" sx={{justifyContent: "center"}}>
      <Grid item xs={8} sm={8} md={8} lg={8} xl={5} sx={{justifyContent: "center"}}>
     
        <Card sx={{display: "flex", width: "1000px", border: "0px 0px 1px 0px", borderRadius:"0px 0px 0px 0px"}}>
          <Box sx={{width: "550px", border: "10px", borderColor: "blue", backgroundColor: "#FCFCFC"}}>
            <Box sx={{width: "550px", border: "10px", borderColor: "blue", backgroundColor: "white", display: "flex"}}>
              <Box sx={{width: "500px", paddingRight: "50px"}}>
              <CardMedia
                  component="img"
                  sx={{padding:"20px", justifyContent:"center"}}
                  image={logoOferta}
                  alt={despacho}
                /></Box>
              
                <Box sx={{ display: 'flex', flexDirection: 'row', width: "90%", border: "2px"}}>
                    <CardContent sx={{paddingTop:"20px"}}>
                      <Typography variant="subtitle1"  color="text.secondary"> {despacho} </Typography>
                              <Typography variant="h6" color="text.secondary">
                                  {titulo}
                              </Typography>
                              <Typography variant="subtitle2" color="text.secondary">
                                  {ciudad}
                              </Typography>
                      <Box sx={{paddingTop: "15px"}}>
                          
                          <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px"}}>
                              {area1}
                          </Typography>
                      </Box>
                      {numeroAreasPractica > 1 && <Box sx={{paddingTop: "15px"}}>
                          
                          <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px"}}>
                              {area1}
                          </Typography>
                      </Box>}

                      {numeroAreasPractica > 2 && <Box sx={{paddingTop: "15px"}}>
                      <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px"}}>
            {area3}
        </Typography>
        </Box>}

                      <Box sx={{paddingTop: "15px"}}>
                          <Typography variant="caption" sx={{backgroundColor: "#03b1fc", borderRadius: "10px", color:"white", padding: "6px"}}>
                          {`${experiencia1} a ${experiencia2} años`}
                          </Typography>
                          {(isPremium || isOldPremium) && <Typography variant="caption" sx={{backgroundColor: "#037bfc", borderRadius: "10px", color:"white", padding: "6px", marginLeft: "5px"}}>
                          {rangoSalarial1}k a {rangoSalarial2}k
                          </Typography>}
                      </Box>
        
        <Box sx={{display: "block", alignItems: "flex-start", paddingTop: "20px"}} >
        </Box>
        </CardContent>
        </Box>
   
        </Box>
        <Divider variant="middle" />
        <Box sx={{paddingLeft: "20px", paddingRight: "20px", paddingTop: "20px", paddingBottom: "20px", justifyContent: "center", width: "100%", bgcolor: "white"}} >
        <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                      sx={{paddingTop: "5px", paddingBottom: "0px", width: "100%"}}>
                             <Typography variant="h6" color="text.secondary">
                                  Texto de la oferta publicada por {despacho}
                              </Typography>
                      </Stack>
        <Typography variant="paragraph" sx={{paddingBottom: "0px"}} > {parse(texto)} 
        </Typography>
        {(isPremium || isOldPremium) && <Typography variant="paragraph" sx={{paddingBottom: "0px"}} > *El rango salarial indicado no está necesariamente basado en una cifra facilitada por el empleador, sino que puede haber sido determinado por Jobs&Law atendiendo a las concretas características de la oferta y a criterios de mercado. El salario ofrecido puede ser inferior o superior al indicado, y debe confirmarse con el empleador. 
        </Typography>}
        </Box> 
        </Box>
        <Divider orientation="vertical" variant="middle" flexItem />
        
       
    
        <Box sx={{ display: 'flex', flexDirection: 'row', width: "4000px", border: "2px"}}>
                    
        {(tieneValoraciones && idUsuario === 0) && <CardContent sx={{paddingTop:"20px", width:"450px", }}>
                    <Stack direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                    sx={{paddingTop: "40px", paddingBottom: "20px"}}>
                    <Button href= {enlace} size="large" variant="contained" endIcon={<SendIcon />}>
                      Aplicar
                    </Button>
                    </Stack>
                      <Typography variant="subtitle2"  color="text.secondary" sx={{textAlign: "center", paddingBottom: "34px"}}></Typography>
                      <Divider variant="middle" />
                    
        
        <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      textAlign="center"
                      sx={{width: "100%", marginTop: "0px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "30px"}}>
                 <OnlyRegister />
                
        </Stack>
      



       
        </CardContent> }
                    
                    {(idUsuario !==0 && tieneValoraciones) && <CardContent sx={{paddingTop:"20px", width:"450px", }}>
                    <Stack direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                    sx={{paddingTop: "40px", paddingBottom: "20px"}}>
                    <Button href= {enlace} size="large" variant="contained" endIcon={<SendIcon />}>
                      Aplicar
                    </Button>
                    </Stack>
                      <Typography variant="subtitle2"  color="text.secondary" sx={{textAlign: "center", paddingBottom: "34px"}}></Typography>
                      <Divider variant="middle" />
                      
                
                      <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                      sx={{paddingTop: "25px", paddingBottom: "0px", width: "100%"}}>
                             
                             
                             
                             <Typography variant="h6" color="text.secondary">
                                  {`¿Por qué ${despacho}?`}
                              </Typography>

                      </Stack>
                      <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                      sx={{paddingTop: "5px", paddingBottom: "0px", width: "100%"}}>
                       
        <Typography variant="paragraph" sx={{paddingBottom: "0px"}} >Así lo valoran sus abogad@s
        </Typography>
        </Stack>
                      
                      
                      
                     {(idUsuario !== 0 && tieneValoraciones) && <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{width: "100%"}}>
        <Typography sx={{paddingTop: "30px", paddingRight: "20px", fontSize: "20px", fontWeight: "500"}} >Valoración general:
        </Typography>
        </Stack>}
      <Grid container paddingTop="0px" paddingBottom="30px">
      <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
        <Stack direction="column"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      sx={{width: "100%"}}>

        <StyledRating
        sx={{paddingTop: "3px", paddingLeft: "0px"}}
        name="customized-color"
        defaultValue={(general*5)/100}
        readOnly
        getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
        precision={0.1}
        size="medium"
        icon={<Brightness1Icon fontSize="inherit" />}
        emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
      />
        </Stack>
        
        </Grid>
       
        
        
      
        <Grid item xs={4} sm={4} md={4} lg={4} xl={4} >
        <Stack direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      sx={{width: "100%"}}>
                <Typography sx={{paddingTop: "1px", paddingLeft: "15px", fontSize: "20px", fontWeight: "700", color: "#037bfc"}} >{general}
        </Typography> 
        </Stack>

        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        </Grid>

{(!isPremium && !isOldPremium && idUsuario !== 0 && tieneValoraciones) && <div>

    
        <Grid container paddingTop="10px">
      <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
        <Stack direction="column"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      sx={{width: "100%"}}>
        <Typography sx={{paddingBottom: "0px", paddingRight: "20px", fontSize: "16px", fontWeight: "400"}} >Tipo de trabajo:
        </Typography>
        </Stack>
        
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
        <StyledRating
        sx={{paddingTop: "3px", paddingLeft: "5px"}}
        name="customized-color"
        defaultValue={(tipoDeTrabajo*5)/100}
        readOnly
        getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
        precision={0.1}
        size="small"
        icon={<Brightness1Icon fontSize="inherit" />}
        emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
      />
        
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3} >
        <Stack direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      sx={{width: "100%"}}>
                <Typography sx={{paddingTop: "1px", fontSize: "16px", fontWeight: "700", color: "#037bfc"}} > {tipoDeTrabajo}
        </Typography> 
        </Stack>

        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        </Grid>
        
        <Grid container paddingTop="10px">
      <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
        <Stack direction="column"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      sx={{width: "100%"}}>
        <Typography sx={{paddingBottom: "0px", paddingRight: "20px", fontSize: "16px", fontWeight: "400"}} >Formación:
        </Typography>
        </Stack>
        
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
        <StyledRating
        sx={{paddingTop: "3px", paddingLeft: "5px"}}
        name="customized-color"
        defaultValue={(formacionTotal*5)/100}
        readOnly
        getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
        precision={0.1}
        size="small"
        icon={<Brightness1Icon fontSize="inherit" />}
        emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
      />
        
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3} >
        <Stack direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      sx={{width: "100%"}}>
                <Typography sx={{paddingTop: "1px", fontSize: "16px", fontWeight: "700", color: "#037bfc"}} >{formacionTotal}
        </Typography> 
        </Stack>

        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        </Grid>
        <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      textAlign="center"
                      sx={{width: "100%", bgcolor: "#f0f4f5", opacity: "0.7", borderRadius: "25px", marginTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "30px"}}>
                <Typography sx={{paddingTop: "30px", paddingBottom: "10px", paddingLeft: "20px", paddingRight: "20px", fontSize: "16px", fontWeight: "700"}} >Este despacho no ha hecho públicos el resto de los resultados.
        </Typography>
        <Typography sx={{paddingBottom: "15px", paddingLeft: "20px", paddingRight: "20px", fontSize: "14px", fontWeight: "700"}} >¿Quieres conocerlos? Hazte Usuario Premium para tener acceso completo a Jobs&Law.
        </Typography>
      <nav aria-label="main mailbox folders">
        <List sx={{paddingBottom: "20px"}} >
          <ListItem disablePadding>
     
              <ListItemIcon>
                <TaskAltIcon />
              </ListItemIcon>
              <ListItemText primary="Todas las vacantes en despachos"
                  primaryTypographyProps={{
                    fontWeight: '700',
                    fontSize: "14px",
                  }} />
    
          </ListItem>
          <ListItem disablePadding>

              <ListItemIcon>
                <TaskAltIcon />
              </ListItemIcon>
              <ListItemText primary="Todas las vacantes in-house"
                  primaryTypographyProps={{
                    fontWeight: '700',
                    fontSize: "14px",
                  }} />
 
          </ListItem>
          <ListItem disablePadding>

              <ListItemIcon>
                <TaskAltIcon />
              </ListItemIcon>
              <ListItemText primary="Todas las vacantes de headhunters"
                  primaryTypographyProps={{
                    fontWeight: '700',
                    fontSize: "14px",
                  }} />

          </ListItem>
          <ListItem disablePadding>

              <ListItemIcon>
                <TaskAltIcon />
              </ListItemIcon>
              <ListItemText primary="Información completa sobre los despachos"
                  primaryTypographyProps={{
                    fontWeight: '700',
                    fontSize: "14px",
                  }} />

          </ListItem>
          <ListItem disablePadding>

<ListItemIcon>
  <TaskAltIcon />
</ListItemIcon>
<ListItemText primary="Información salarial"
    primaryTypographyProps={{
      fontWeight: '700',
      fontSize: "14px",
    }} />

</ListItem>
        </List>
      </nav>
      <Button variant="contained" href="https://jobsandlaw.com/checkout">
        Activar Premium
      </Button>
        </Stack>
        </div>}







        {(isPremium || isOldPremium) && <div>
<Grid container paddingTop="10px">
      <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
        <Stack direction="column"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      sx={{width: "100%"}}>
        <Typography sx={{paddingBottom: "0px", paddingRight: "12px", fontSize: "16px", fontWeight: "400"}} >Ambiente laboral:
        </Typography>
        </Stack>
        
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
        <StyledRating
        sx={{paddingTop: "3px", paddingLeft: "5px"}}
        name="customized-color"
        defaultValue={(ambiente*5)/100}
        readOnly
        getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
        precision={0.1}
        size="small"
        icon={<Brightness1Icon fontSize="inherit" />}
        emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
      />
        
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3} >
        <Stack direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      sx={{width: "100%"}}>
                <Typography sx={{paddingTop: "1px", fontSize: "16px", fontWeight: "700", color: "#037bfc"}} >{ambiente}
        </Typography> 
        </Stack>

        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        </Grid>
        <Grid container paddingTop="10px">
      <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
        <Stack direction="column"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      sx={{width: "100%"}}>
        <Typography sx={{paddingBottom: "0px", paddingRight: "20px", fontSize: "16px", fontWeight: "400"}} >Conciliación:
        </Typography>
        </Stack>
        
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
        <StyledRating
        sx={{paddingTop: "3px", paddingLeft: "5px"}}
        name="customized-color"
        defaultValue={(conciliacion*5)/100}
        readOnly
        getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
        precision={0.1}
        size="small"
        icon={<Brightness1Icon fontSize="inherit" />}
        emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
      />
        
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3} >
        <Stack direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      sx={{width: "100%"}}>
                <Typography sx={{paddingTop: "1px", fontSize: "16px", fontWeight: "700", color: "#037bfc"}} >{conciliacion}
        </Typography> 
        </Stack>

        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        </Grid>
        <Grid container paddingTop="10px">
      <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
        <Stack direction="column"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      sx={{width: "100%"}}>
        <Typography sx={{paddingBottom: "0px", paddingRight: "20px", fontSize: "16px", fontWeight: "400"}} >Tipo de trabajo:
        </Typography>
        </Stack>
        
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
        <StyledRating
        sx={{paddingTop: "3px", paddingLeft: "5px"}}
        name="customized-color"
        defaultValue={(tipoDeTrabajo*5)/100}
        readOnly
        getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
        precision={0.1}
        size="small"
        icon={<Brightness1Icon fontSize="inherit" />}
        emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
      />
        
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3} >
        <Stack direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      sx={{width: "100%"}}>
                <Typography sx={{paddingTop: "1px", fontSize: "16px", fontWeight: "700", color: "#037bfc"}} > {tipoDeTrabajo}
        </Typography> 
        </Stack>

        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        </Grid>
        <Grid container paddingTop="10px">
      <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
        <Stack direction="column"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      sx={{width: "100%"}}>
        <Typography sx={{paddingBottom: "0px", paddingRight: "20px", fontSize: "16px", fontWeight: "400"}} >Carrera:
        </Typography>
        </Stack>
        
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
        <StyledRating
        sx={{paddingTop: "3px", paddingLeft: "5px"}}
        name="customized-color"
        defaultValue={(carrera*5)/100}
        readOnly
        getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
        precision={0.1}
        size="small"
        icon={<Brightness1Icon fontSize="inherit" />}
        emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
      />
        
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3} >
        <Stack direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      sx={{width: "100%"}}>
                <Typography sx={{paddingTop: "1px", fontSize: "16px", fontWeight: "700", color: "#037bfc"}} >{carrera}
        </Typography> 
        </Stack>

        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        </Grid>
        <Grid container paddingTop="10px">
      <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
        <Stack direction="column"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      sx={{width: "100%"}}>
        <Typography sx={{paddingBottom: "0px", paddingRight: "20px", fontSize: "16px", fontWeight: "400"}} >Formación:
        </Typography>
        </Stack>
        
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
        <StyledRating
        sx={{paddingTop: "3px", paddingLeft: "5px"}}
        name="customized-color"
        defaultValue={(formacionTotal*5)/100}
        readOnly
        getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
        precision={0.1}
        size="small"
        icon={<Brightness1Icon fontSize="inherit" />}
        emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
      />
        
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3} >
        <Stack direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      sx={{width: "100%"}}>
                <Typography sx={{paddingTop: "1px", fontSize: "16px", fontWeight: "700", color: "#037bfc"}} >{formacionTotal}
        </Typography> 
        </Stack>

        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        </Grid>
        <Grid container paddingTop="10px">
      <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
        <Stack direction="column"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      sx={{width: "100%"}}>
        <Typography sx={{paddingBottom: "0px", paddingRight: "20px", fontSize: "16px", fontWeight: "400"}} >Diversidad:
        </Typography>
        </Stack>
        
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
        <StyledRating
        sx={{paddingTop: "3px", paddingLeft: "5px"}}
        name="customized-color"
        defaultValue={(diversidadTotal*5)/100}
        readOnly
        getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
        precision={0.1}
        size="small"
        icon={<Brightness1Icon fontSize="inherit" />}
        emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
      />
        
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3} >
        <Stack direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      sx={{width: "100%"}}>
                <Typography sx={{paddingTop: "1px", fontSize: "16px", fontWeight: "700", color: "#037bfc"}} >{diversidadTotal}
        </Typography> 
        </Stack>

        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        </Grid>
        <Grid container paddingTop="10px">
      <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
        <Stack direction="column"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      sx={{width: "100%"}}>
        <Typography sx={{paddingBottom: "0px", paddingRight: "18px", fontSize: "16px", fontWeight: "400"}} >Cultura de firma:
        </Typography>
        </Stack>
        
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
        <StyledRating
        sx={{paddingTop: "3px", paddingLeft: "5px"}}
        name="customized-color"
        defaultValue={(valores*5)/100}
        readOnly
        getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
        precision={0.1}
        size="small"
        icon={<Brightness1Icon fontSize="inherit" />}
        emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
      />
        
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3} >
        <Stack direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      sx={{width: "100%"}}>
                <Typography sx={{paddingTop: "1px", fontSize: "16px", fontWeight: "700", color: "#037bfc"}} >{valores}
        </Typography> 
        </Stack>

        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        </Grid>
        <Grid container paddingTop="10px">
      <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
        <Stack direction="column"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      sx={{width: "100%"}}>
        <Typography sx={{paddingBottom: "0px", paddingRight: "20px", fontSize: "16px", fontWeight: "400"}} >Igualdad:
        </Typography>
        </Stack>
        
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
        <StyledRating
        sx={{paddingTop: "3px", paddingLeft: "5px"}}
        name="customized-color"
        defaultValue={(igualdadTotal*5)/100}
        readOnly
        getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
        precision={0.1}
        size="small"
        icon={<Brightness1Icon fontSize="inherit" />}
        emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
      />
        
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3} >
        <Stack direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      sx={{width: "100%"}}>
                <Typography sx={{paddingTop: "1px", fontSize: "16px", fontWeight: "700", color: "#037bfc"}} >{igualdadTotal}
        </Typography> 
        </Stack>

        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        </Grid>
        <Grid container paddingTop="10px">
      <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
        <Stack direction="column"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      sx={{width: "100%"}}>
        <Typography sx={{paddingBottom: "0px", paddingRight: "20px", fontSize: "16px", fontWeight: "400"}} >Pro Bono:
        </Typography>
        </Stack>
        
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
        <StyledRating
        sx={{paddingTop: "3px", paddingLeft: "5px"}}
        name="customized-color"
        defaultValue={(proBono*5)/100}
        readOnly
        getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
        precision={0.1}
        size="small"
        icon={<Brightness1Icon fontSize="inherit" />}
        emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
      />
        
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3} >
        <Stack direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      sx={{width: "100%"}}>
                <Typography sx={{paddingTop: "1px", fontSize: "16px", fontWeight: "700", color: "#037bfc"}} >{proBono}
        </Typography> 
        </Stack>

        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        </Grid>
        </div>}


        <Divider variant="middle" sx={{paddingTop: "40px"}} />
        {(isPremium || isOldPremium) &&
        <div>
        <Stack direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                    sx={{paddingTop: "15px", paddingBottom: "20px"}}>
                     <Stack direction="row"
                 
                   
                    spacing={2}
                    sx={{paddingTop: "0px", paddingBottom: "20px"}}>
                      <Box sx={{textAlign: "center"}} >

                     <Pie percentage={cincoAnos} colour="blue" /> 
                     <Typography sx={{paddingBottom: "0px", fontSize:"14px", paddingRight: "15px", paddingLeft:"15px" }} >Se ve trabajando en este despacho dentro de cinco años
        </Typography>
                     </Box> 
                     <Box sx={{textAlign: "center"}} >

<Pie percentage={teletrabajo} colour="blue" /> 
<Typography sx={{paddingBottom: "0px", fontSize:"14px", paddingRight: "15px", paddingLeft:"15px" }} >Valora positivamente la política de teletrabajo
</Typography>
</Box> 
                    </Stack>
                    
                   
                 
                    </Stack>
                     
                      <Divider variant="middle" />
                      <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                      sx={{paddingTop: "25px"}}>
                             <Typography variant="h6" color="text.secondary" sx={{paddingBottom: "0px"}}>
                                  Reconocimientos
                              </Typography>
                              </Stack>
                              <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                      sx={{paddingTop: "10px"}}>
                              <Typography variant="subtitle2"  color="text.secondary" sx={{textAlign: "center", paddingBottom: "34px"}}>¡Esta información estará <br></br> disponible muy pronto!</Typography>
                      </Stack>
                      <Divider variant="middle" />
                      <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                      sx={{paddingTop: "25px"}}>
                             <Typography variant="h6" color="text.secondary" sx={{paddingBottom: "0px"}}>
                                  Remuneración
                              </Typography>
                              </Stack>
                              <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                      sx={{paddingTop: "10px"}}>
                              <Typography variant="subtitle2"  color="text.secondary" sx={{textAlign: "center", paddingBottom: "34px"}}>¡Esta información estará <br></br> disponible muy pronto!</Typography>
                      </Stack>     
                      </div> }
        </CardContent> }


        {(!tieneValoraciones && idUsuario === 0) && <CardContent sx={{paddingTop:"20px", width:"450px", }}>
                    <Stack direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                    sx={{paddingTop: "40px", paddingBottom: "20px"}}>
                    <Button href= {enlace} size="large" variant="contained" endIcon={<SendIcon />}>
                      Aplicar
                    </Button>
                    </Stack>
                      <Typography variant="subtitle2"  color="text.secondary" sx={{textAlign: "center", paddingBottom: "34px"}}></Typography>
                      <Divider variant="middle" />
                    
        
        <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      textAlign="center"
                      sx={{width: "100%", marginTop: "0px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "30px"}}>
                 <OnlyRegister />
                
        </Stack>
      



       
        </CardContent> }


       
       
        {(!tieneValoraciones && idUsuario !==0) && <CardContent sx={{paddingTop:"20px", width:"450px", }}>
<Stack direction="column"
justifyContent="center"
alignItems="center"
spacing={2}
sx={{paddingTop: "40px", paddingBottom: "20px"}}>
<Button href= {enlace} size="large" variant="contained" endIcon={<SendIcon />}>
  Aplicar
</Button>
</Stack>
  <Typography variant="subtitle2"  color="text.secondary" sx={{textAlign: "center", paddingBottom: "34px"}}></Typography>
  <Divider variant="middle" />
  
  
  <Stack direction="column"
  justifyContent="center"
  alignItems="center"
  spacing={2}
  sx={{paddingTop: "25px", paddingBottom: "0px", width: "100%"}}>
         <Typography variant="h6" color="text.secondary">
              {`¿Por qué ${despacho}?`}
          </Typography>

  </Stack>
  <Stack direction="column"
  justifyContent="center"
  alignItems="center"
  spacing={2}
  sx={{paddingTop: "5px", paddingBottom: "0px", width: "100%"}}>
<Typography variant="paragraph" sx={{paddingBottom: "0px"}} >Así lo valoran sus abogad@s
</Typography>
</Stack>
  
  
  
 






<Stack direction="column"
  justifyContent="center"
  alignItems="center"
  textAlign="center"
  sx={{width: "100%", bgcolor: "#f0f4f5", opacity: "0.7", borderRadius: "25px", marginTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "30px"}}>
<Typography sx={{paddingTop: "30px", paddingBottom: "10px", paddingLeft: "20px", paddingRight: "20px", fontSize: "16px", fontWeight: "700"}} >Este despacho/empresa no tiene suficientes valoraciones.
</Typography>
<Typography sx={{paddingBottom: "15px", paddingLeft: "20px", paddingRight: "20px", fontSize: "14px", fontWeight: "700"}} >¿Trabajas o has trabajado aquí? Deja tu opinión y ayuda a otros abogados.
</Typography>

<Button variant="contained" href="#contained-buttons">
Próximamente
</Button>
</Stack>





</CardContent> }




        </Box>

    </Card>

    {((isPremium || isOldPremium) && tieneValoraciones) &&
    <div>
    <Card sx={{width: "1000px", border: "0px 1px 1px 1px", borderRadius:"0px 0px 0px 0px"}}>
    <Divider />
    <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                      sx={{paddingTop: "45px"}}>
                             <Typography variant="h6" color="text.secondary" sx={{paddingBottom: "0px"}}>
                                  Opiniones de los abogados de {despacho} 
                              </Typography>
                              </Stack>
                             

                      <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                      sx={{paddingTop: "20px", paddingBottom: "30px"}}>
                       
                       
                       

               
                       {comentarios !== undefined && <Listas/>}

    </Stack>


    </Card>
    {positivo !== undefined && <Card sx={{width: "1000px", display:"flex", border: "0px 1px 1px 1px", borderRadius:"0px 0px 0px 0px", marginBottom: "30px"}}>
    <Divider />

    <Box sx={{width: "550px", border: "10px", borderColor: "blue"}}>
    <Divider />
    <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                      sx={{paddingTop: "45px"}}>
                             <Typography variant="p" color="text.secondary" sx={{backgroundColor: "#35b50e", borderRadius: "10px", color:"white", padding: "6px"}}>
                                 Lo positivo
                              </Typography>
                              </Stack>
                             

                      <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                      sx={{paddingTop: "20px", paddingBottom: "30px", paddingLeft:"20px", paddingRight: "20px"}}>
                       
                       
                       

               
                       <Positivo/>
                       <Pagination count={noOfPages2} page={page2} onChange={handleChange2} variant="outlined" shape="rounded" />
    </Stack>
    </Box>
    <Divider orientation="vertical" variant="middle" flexItem />
    <Box sx={{width: "550px", border: "10px", borderColor: "blue"}}>
    <Divider />

    {((isPremium || isOldPremium) && tieneValoraciones) && 
    <div>
    
    <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                      sx={{paddingTop: "45px"}}>
                             <Typography variant="p" color="text.secondary" sx={{backgroundColor: "#d60f16", borderRadius: "10px", color:"white", padding: "6px"}}>
                                  Lo negativo 
                              </Typography>
                              </Stack>
                             

                      <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                      sx={{paddingTop: "20px", paddingBottom: "30px", paddingLeft:"20px", paddingRight: "20px"}}>
                       
                       
                       

               
                       <Negativo/>
    <Pagination count={noOfPages3} page={page3} onChange={handleChange3} variant="outlined" shape="rounded" />
    </Stack>
    </div> }
    </Box>


    </Card> }
    </div> }

   



      </Grid>
    </Grid>
  </Box>

  <Box sx={{ flexGrow: 1, display: { xl: 'none', l: "none", m: "block", s:"block", xs: 'block' }}}>
        <Card variant="outlined" sx={{width: "100%", border: "0px 1px 1px 1px", borderRadius:"0px 0px 0px 0px"}}>
          <Box sx={{border: "10px", borderColor: "blue", backgroundColor: "#FCFCFC"}}>
            <Box sx={{border: "10px", borderColor: "blue", backgroundColor: "#FCFCFC"}}>
              <div style={{ display:'flex', justifyContent:'center' }}>
              <CardMedia
                  component="img"
                  sx={{width: "80px", textAlign: "center", marginTop: "20px"}}
                  image={logoOferta}
                  alt={despacho}
                />
              </div>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: "100%", border: "2px", alignItems: "center", alignContent: "center", textAlign: "center"}}>
                    <CardContent sx={{paddingTop:"20px", width: "90%"}}>
                      <Typography variant="subtitle1"  color="text.secondary"> {despacho} </Typography>
                              <Typography variant="h6" color="text.secondary">
                                  {titulo}
                              </Typography>
                              <Typography variant="subtitle2" color="text.secondary">
                                  {ciudad}
                              </Typography>
                      <Box sx={{paddingTop: "15px"}}>
                          <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px"}}>
                              {area1}
                          </Typography>
                          {numeroAreasPractica > 1 && <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px", marginLeft: "5px"}}>
                          {area2}
        </Typography>}
        {numeroAreasPractica > 2 && <Box sx={{paddingTop: "15px"}}> 
        <Typography variant="caption" sx={{backgroundColor: "#03d7fc", borderRadius: "10px", color:"white", padding: "6px", marginLeft: "5px"}}>
            {area3}
        </Typography></Box>}
                      </Box>
                      <Box sx={{paddingTop: "15px"}}>
                          <Typography variant="caption" sx={{backgroundColor: "#03b1fc", borderRadius: "10px", color:"white", padding: "6px"}}>
                          {`${experiencia1} a ${experiencia2} años`}
                          </Typography>
                          <Typography variant="caption" sx={{backgroundColor: "#037bfc", borderRadius: "10px", color:"white", padding: "6px", marginLeft: "5px"}}>
                          40 a 60K
                          </Typography>
                      </Box>
        
        <Box sx={{display: "block", alignItems: "flex-start", paddingTop: "20px"}} >
        </Box>
        </CardContent>
        </Box>
   
        </Box>
        <Divider variant="middle" />
        <Box sx={{paddingLeft: "20px", paddingRight: "20px", paddingTop: "20px", paddingBottom: "20px", justifyContent: "center", width: "100%"}} >
        <Stack 
        direction="column"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        spacing={2}
        sx={{paddingTop: "40px", paddingBottom: "20px", width: "100%"}}>
                             <Typography variant="h6" color="text.secondary">
                                  Texto de la oferta publicada por {despacho}
                              </Typography>
                      </Stack>
        <Typography variant="paragraph" sx={{paddingBottom: "0px"}} > {parse(texto)} 
        </Typography>
        </Box> 
        </Box>
        
        <Box sx={{ display: 'flex', flexDirection: 'row', border: "2px"}}>
        {(tieneValoraciones && idUsuario === 0) && <CardContent sx={{paddingTop:"20px", width:"450px", }}>
                    <Stack direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                    sx={{paddingTop: "40px", paddingBottom: "20px"}}>
                    <Button href= {enlace} size="large" variant="contained" endIcon={<SendIcon />}>
                      Aplicar
                    </Button>
                    </Stack>
                      <Typography variant="subtitle2"  color="text.secondary" sx={{textAlign: "center", paddingBottom: "34px"}}></Typography>
                      <Divider variant="middle" />
                    
        
        <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      textAlign="center"
                      sx={{width: "100%", marginTop: "0px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "30px"}}>
                 <OnlyRegister />
                
        </Stack>
      



       
        </CardContent> }
                   
                    {(tieneValoraciones && idUsuario !== 0) && <CardContent sx={{width:"100%" }}>
                    <Stack direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                    sx={{paddingTop: "0px", paddingBottom: "20px"}}>
                    <Button href= {enlace} size="large" variant="contained" endIcon={<SendIcon />}>
                      Aplicar
                    </Button>
                    </Stack>
                      <Typography variant="subtitle2"  color="text.secondary" sx={{textAlign: "center", paddingBottom: "34px"}}></Typography>
                      <Divider variant="middle" />
                     

                      <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                      sx={{paddingTop: "25px", paddingBottom: "0px", width: "100%"}}>
                             <Typography variant="h6" color="text.secondary">
                                  {`¿Por qué ${despacho}?`}
                              </Typography>

                      </Stack>
                      <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                      sx={{paddingTop: "5px", paddingBottom: "0px", width: "100%"}}>
        <Typography variant="paragraph" sx={{paddingBottom: "0px"}} >Así lo valoran sus abogad@s
        </Typography>
        </Stack>
                      
                      
                      
                      <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{width: "100%"}}>
        <Typography sx={{paddingTop: "30px", paddingRight: "20px", fontSize: "20px", fontWeight: "500"}} >Valoración general:
        </Typography>
        </Stack>
      <Grid container paddingTop="0px" paddingBottom="30px">
      <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
        <Stack direction="column"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      sx={{width: "100%"}}>

        <StyledRating
        sx={{paddingTop: "3px", paddingLeft: "0px"}}
        name="customized-color"
        defaultValue={(general*5)/100}
        readOnly
        getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
        precision={0.1}
        size="medium"
        icon={<Brightness1Icon fontSize="inherit" />}
        emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
      />
        </Stack>
        
        </Grid>
       
        
        
      
        <Grid item xs={4} sm={4} md={4} lg={4} xl={4} >
        <Stack direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      sx={{width: "100%"}}>
                <Typography sx={{paddingTop: "1px", paddingLeft: "15px", fontSize: "20px", fontWeight: "700", color: "#037bfc"}} >{general}
        </Typography> 
        </Stack>

        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        </Grid>

{(!isPremium && !isOldPremium) && <div>

    
  <Grid container paddingTop="10px">

<Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
<Stack direction="column"
              justifyContent="flex-end"
              alignItems="flex-end"
              sx={{width: "100%"}}>
<Typography sx={{paddingBottom: "0px", paddingLeft: "0px", paddingRight: "15px", fontSize: "16px", fontWeight: "400"}} >Tipo de trabajo:
</Typography>
</Stack>

</Grid>
<Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
<StyledRating
sx={{paddingTop: "3px", paddingLeft: "0px"}}
name="customized-color"
defaultValue={(tipoDeTrabajo*5)/100}
readOnly
getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
precision={0.1}
size="small"
icon={<Brightness1Icon fontSize="inherit" />}
emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
/>

</Grid>
<Grid item xs={3} sm={3} md={3} lg={3} xl={3} >
<Stack direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              sx={{width: "100%"}}>
        <Typography sx={{paddingTop: "1px", fontSize: "16px", fontWeight: "700", color: "#037bfc"}} > {tipoDeTrabajo}
</Typography> 
</Stack>

</Grid>

</Grid>
        
        <Grid container paddingTop="10px">

        <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
        <Stack direction="column"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      sx={{width: "100%"}}>
        <Typography sx={{paddingBottom: "0px", paddingRight: "15px", fontSize: "16px", fontWeight: "400"}} >Formación:
        </Typography>
        </Stack>
        
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
        <StyledRating
        sx={{paddingTop: "3px", paddingLeft: "0px"}}
        name="customized-color"
        defaultValue={(formacionTotal*5)/100}
        readOnly
        getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
        precision={0.1}
        size="small"
        icon={<Brightness1Icon fontSize="inherit" />}
        emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
      />
        
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3} >
        <Stack direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      sx={{width: "100%"}}>
                <Typography sx={{paddingTop: "1px", fontSize: "16px", fontWeight: "700", color: "#037bfc"}} >{formacionTotal}
        </Typography> 
        </Stack>

        </Grid>

        </Grid>
        <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      textAlign="center"
                      sx={{width: "100%", bgcolor: "#f0f4f5", opacity: "0.7", borderRadius: "25px", marginTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "30px"}}>
                <Typography sx={{paddingTop: "30px", paddingBottom: "10px", paddingLeft: "20px", paddingRight: "20px", fontSize: "16px", fontWeight: "700"}} >Este despacho no ha hecho públicos el resto de los resultados.
        </Typography>
        <Typography sx={{paddingBottom: "15px", paddingLeft: "20px", paddingRight: "20px", fontSize: "14px", fontWeight: "700"}} >¿Quieres conocerlos? Hazte Usuario Premium para tener acceso completo a Jobs&Law.
        </Typography>
      <nav aria-label="main mailbox folders">
        <List sx={{paddingBottom: "20px"}} >
          <ListItem disablePadding>
     
              <ListItemIcon>
                <TaskAltIcon />
              </ListItemIcon>
              <ListItemText primary="Todas las vacantes en despachos"
                  primaryTypographyProps={{
                    fontWeight: '700',
                    fontSize: "14px",
                  }} />
    
          </ListItem>
          <ListItem disablePadding>

              <ListItemIcon>
                <TaskAltIcon />
              </ListItemIcon>
              <ListItemText primary="Todas las vacantes in-house"
                  primaryTypographyProps={{
                    fontWeight: '700',
                    fontSize: "14px",
                  }} />
 
          </ListItem>
          <ListItem disablePadding>

              <ListItemIcon>
                <TaskAltIcon />
              </ListItemIcon>
              <ListItemText primary="Todas las vacantes de headhunters"
                  primaryTypographyProps={{
                    fontWeight: '700',
                    fontSize: "14px",
                  }} />

          </ListItem>
          <ListItem disablePadding>

              <ListItemIcon>
                <TaskAltIcon />
              </ListItemIcon>
              <ListItemText primary="Información completa sobre los despachos"
                  primaryTypographyProps={{
                    fontWeight: '700',
                    fontSize: "14px",
                  }} />

          </ListItem>
          <ListItem disablePadding>

<ListItemIcon>
  <TaskAltIcon />
</ListItemIcon>
<ListItemText primary="Información salarial"
    primaryTypographyProps={{
      fontWeight: '700',
      fontSize: "14px",
    }} />

</ListItem>
        </List>
      </nav>
      <Button variant="contained" href="https://jobsandlaw.com/checkout">
        Activar Premium
      </Button>
        </Stack>
        </div>}



        {(isPremium || isOldPremium) && <div>
<Grid container paddingTop="10px">

        <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
        <Stack direction="column"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      sx={{width: "100%"}}>
        <Typography sx={{paddingBottom: "0px", paddingRight: "15px", fontSize: "16px", fontWeight: "400"}} >Ambiente laboral:
        </Typography>
        </Stack>
        
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
        <StyledRating
        sx={{paddingTop: "3px", paddingLeft: "0px"}}
        name="customized-color"
        defaultValue={(ambiente*5)/100}
        readOnly
        getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
        precision={0.1}
        size="small"
        icon={<Brightness1Icon fontSize="inherit" />}
        emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
      />
        
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3} >
        <Stack direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      sx={{width: "100%"}}>
                <Typography sx={{paddingTop: "1px", fontSize: "16px", fontWeight: "700", color: "#037bfc"}} >{ambiente}
        </Typography> 
        </Stack>

        </Grid>

        </Grid>
        <Grid container paddingTop="10px">
        <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
        <Stack direction="column"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      sx={{width: "100%"}}>
        <Typography sx={{paddingBottom: "0px", paddingLeft: "15px", paddingRight: "15px", fontSize: "16px", fontWeight: "400"}} >Conciliación:
        </Typography>
        </Stack>
        
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
        <StyledRating
        sx={{paddingTop: "3px", paddingLeft: "0px"}}
        name="customized-color"
        defaultValue={(conciliacion*5)/100}
        readOnly
        getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
        precision={0.1}
        size="small"
        icon={<Brightness1Icon fontSize="inherit" />}
        emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
      />
        
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3} >
        <Stack direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      sx={{width: "100%"}}>
                <Typography sx={{paddingTop: "1px", fontSize: "16px", fontWeight: "700", color: "#037bfc"}} >{conciliacion}
        </Typography> 
        </Stack>

        </Grid>
        </Grid>
        <Grid container paddingTop="10px">

        <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
        <Stack direction="column"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      sx={{width: "100%"}}>
        <Typography sx={{paddingBottom: "0px", paddingLeft: "0px", paddingRight: "15px", fontSize: "16px", fontWeight: "400"}} >Tipo de trabajo:
        </Typography>
        </Stack>
        
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
        <StyledRating
        sx={{paddingTop: "3px", paddingLeft: "0px"}}
        name="customized-color"
        defaultValue={(tipoDeTrabajo*5)/100}
        readOnly
        getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
        precision={0.1}
        size="small"
        icon={<Brightness1Icon fontSize="inherit" />}
        emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
      />
        
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3} >
        <Stack direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      sx={{width: "100%"}}>
                <Typography sx={{paddingTop: "1px", fontSize: "16px", fontWeight: "700", color: "#037bfc"}} > {tipoDeTrabajo}
        </Typography> 
        </Stack>

        </Grid>

        </Grid>

        <Grid container paddingTop="10px">

<Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
<Stack direction="column"
              justifyContent="flex-end"
              alignItems="flex-end"
              sx={{width: "100%"}}>
<Typography sx={{paddingBottom: "0px", paddingRight: "15px", fontSize: "16px", fontWeight: "400"}} >Carrera:
</Typography>
</Stack>

</Grid>
<Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
<StyledRating
sx={{paddingTop: "3px", paddingLeft: "0px"}}
name="customized-color"
defaultValue={(carrera*5)/100}
readOnly
getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
precision={0.1}
size="small"
icon={<Brightness1Icon fontSize="inherit" />}
emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
/>

</Grid>
<Grid item xs={3} sm={3} md={3} lg={3} xl={3} >
<Stack direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              sx={{width: "100%"}}>
        <Typography sx={{paddingTop: "1px", fontSize: "16px", fontWeight: "700", color: "#037bfc"}} >{carrera}
</Typography> 
</Stack>

</Grid>

</Grid>

<Grid container paddingTop="10px">

        <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
        <Stack direction="column"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      sx={{width: "100%"}}>
        <Typography sx={{paddingBottom: "0px", paddingRight: "15px", fontSize: "16px", fontWeight: "400"}} >Formación:
        </Typography>
        </Stack>
        
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
        <StyledRating
        sx={{paddingTop: "3px", paddingLeft: "0px"}}
        name="customized-color"
        defaultValue={(formacionTotal*5)/100}
        readOnly
        getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
        precision={0.1}
        size="small"
        icon={<Brightness1Icon fontSize="inherit" />}
        emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
      />
        
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3} >
        <Stack direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      sx={{width: "100%"}}>
                <Typography sx={{paddingTop: "1px", fontSize: "16px", fontWeight: "700", color: "#037bfc"}} >{formacionTotal}
        </Typography> 
        </Stack>

        </Grid>

        </Grid>

        
        <Grid container paddingTop="10px">

<Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
<Stack direction="column"
              justifyContent="flex-end"
              alignItems="flex-end"
              sx={{width: "100%"}}>
<Typography sx={{paddingBottom: "0px", paddingRight: "15px", fontSize: "16px", fontWeight: "400"}} >Diversidad:
</Typography>
</Stack>

</Grid>
<Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
<StyledRating
sx={{paddingTop: "3px", paddingLeft: "0px"}}
name="customized-color"
defaultValue={(diversidadTotal*5)/100}
readOnly
getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
precision={0.1}
size="small"
icon={<Brightness1Icon fontSize="inherit" />}
emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
/>

</Grid>
<Grid item xs={3} sm={3} md={3} lg={3} xl={3} >
<Stack direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              sx={{width: "100%"}}>
        <Typography sx={{paddingTop: "1px", fontSize: "16px", fontWeight: "700", color: "#037bfc"}} >{diversidadTotal}
</Typography> 
</Stack>

</Grid>

</Grid> 
       
       
<Grid container paddingTop="10px">

<Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
<Stack direction="column"
              justifyContent="flex-end"
              alignItems="flex-end"
              sx={{width: "100%"}}>
<Typography sx={{paddingBottom: "0px", paddingRight: "15px", fontSize: "16px", fontWeight: "400"}} >Cultura de Firma:
</Typography>
</Stack>

</Grid>
<Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
<StyledRating
sx={{paddingTop: "3px", paddingLeft: "0px"}}
name="customized-color"
defaultValue={(valores*5)/100}
readOnly
getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
precision={0.1}
size="small"
icon={<Brightness1Icon fontSize="inherit" />}
emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
/>

</Grid>
<Grid item xs={3} sm={3} md={3} lg={3} xl={3} >
<Stack direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              sx={{width: "100%"}}>
        <Typography sx={{paddingTop: "1px", fontSize: "16px", fontWeight: "700", color: "#037bfc"}} >{valores}
</Typography> 
</Stack>

</Grid>

</Grid> 
      
       
<Grid container paddingTop="10px">

        <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
        <Stack direction="column"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      sx={{width: "100%"}}>
        <Typography sx={{paddingBottom: "0px", paddingRight: "15px", fontSize: "16px", fontWeight: "400"}} >Igualdad:
        </Typography>
        </Stack>
        
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
        <StyledRating
        sx={{paddingTop: "3px", paddingLeft: "0px"}}
        name="customized-color"
        defaultValue={(igualdadTotal*5)/100}
        readOnly
        getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
        precision={0.1}
        size="small"
        icon={<Brightness1Icon fontSize="inherit" />}
        emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
      />
        
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3} >
        <Stack direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      sx={{width: "100%"}}>
                <Typography sx={{paddingTop: "1px", fontSize: "16px", fontWeight: "700", color: "#037bfc"}} >{igualdadTotal}
        </Typography> 
        </Stack>

        </Grid>

        </Grid>


        <Grid container paddingTop="10px">

<Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
<Stack direction="column"
              justifyContent="flex-end"
              alignItems="flex-end"
              sx={{width: "100%"}}>
<Typography sx={{paddingBottom: "0px", paddingRight: "15px", fontSize: "16px", fontWeight: "400"}} >Pro Bono:
</Typography>
</Stack>

</Grid>
<Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
<StyledRating
sx={{paddingTop: "3px", paddingLeft: "0px"}}
name="customized-color"
defaultValue={(proBono*5)/100}
readOnly
getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
precision={0.1}
size="small"
icon={<Brightness1Icon fontSize="inherit" />}
emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
/>

</Grid>
<Grid item xs={3} sm={3} md={3} lg={3} xl={3} >
<Stack direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              sx={{width: "100%"}}>
        <Typography sx={{paddingTop: "1px", fontSize: "16px", fontWeight: "700", color: "#037bfc"}} >{proBono}
</Typography> 
</Stack>

</Grid>

</Grid>

       
        </div>}

        <Divider variant="middle" sx={{paddingTop: "40px"}} />
        {(isPremium || isOldPremium) &&
        <div>


                      <Box sx={{textAlign: "center"}} >

                     <Pie percentage={cincoAnos} colour="blue" /> 
                     <Typography sx={{paddingBottom: "0px", fontSize:"14px", paddingRight: "15px", paddingLeft:"15px" }} >Se ve trabajando en este despacho dentro de cinco años
        </Typography>
                     </Box> 
                     <Box sx={{textAlign: "center"}} >

<Pie percentage={teletrabajo} colour="blue" /> 
<Typography sx={{paddingBottom: "0px", fontSize:"14px", paddingRight: "15px", paddingLeft:"15px", paddingBottom: "40px" }} >Valora positivamente la política de teletrabajo
</Typography>
</Box> 

                    
                   
   
                     
                      <Divider variant="middle" />
                      <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                      sx={{paddingTop: "25px"}}>
                             <Typography variant="h6" color="text.secondary" sx={{paddingBottom: "0px"}}>
                                  Reconocimientos
                              </Typography>
                              </Stack>
                              <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                      sx={{paddingTop: "10px"}}>
                              <Typography variant="subtitle2"  color="text.secondary" sx={{textAlign: "center", paddingBottom: "34px"}}>¡Esta información estará <br></br> disponible muy pronto!</Typography>
                      </Stack>
                      <Divider variant="middle" />
                      <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                      sx={{paddingTop: "25px"}}>
                             <Typography variant="h6" color="text.secondary" sx={{paddingBottom: "0px"}}>
                                  Remuneración
                              </Typography>
                              </Stack>
                                          <Typography variant="subtitle2" color="text.secondary" sx={{paddingBottom: "0px", textAlign: "center"}}>
                                  ¡Esta información estará <br></br> disponible muy pronto!
                              </Typography>
                      </div> }

                      

                  
                            
        </CardContent>}


        {(!tieneValoraciones && idUsuario === 0) && <CardContent sx={{paddingTop:"20px", width:"450px", }}>
                    <Stack direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                    sx={{paddingTop: "40px", paddingBottom: "20px"}}>
                    <Button href= {enlace} size="large" variant="contained" endIcon={<SendIcon />}>
                      Aplicar
                    </Button>
                    </Stack>
                      <Typography variant="subtitle2"  color="text.secondary" sx={{textAlign: "center", paddingBottom: "34px"}}></Typography>
                      <Divider variant="middle" />
                    
        
        <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      textAlign="center"
                      sx={{width: "100%", marginTop: "0px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "30px"}}>
                 <OnlyRegister />
                
        </Stack>
      



       
        </CardContent> }


        {(!tieneValoraciones && idUsuario !==0) && <CardContent sx={{width:"100%" }}>
                    <Stack direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                    sx={{paddingTop: "0px", paddingBottom: "20px"}}>
                    <Button href= {enlace} size="large" variant="contained" endIcon={<SendIcon />}>
                      Aplicar
                    </Button>
                    </Stack>
                      <Typography variant="subtitle2"  color="text.secondary" sx={{textAlign: "center", paddingBottom: "34px"}}></Typography>
                      <Divider variant="middle" />
                     

                      <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                      sx={{paddingTop: "25px", paddingBottom: "0px", width: "100%"}}>
                             <Typography variant="h6" color="text.secondary">
                                  {`¿Por qué ${despacho}?`}
                              </Typography>

                      </Stack>
                      <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                      sx={{paddingTop: "5px", paddingBottom: "0px", width: "100%"}}>
        <Typography variant="paragraph" sx={{paddingBottom: "0px"}} >Así lo valoran sus abogad@s
        </Typography>
        </Stack>
                      
                      
                      
                      
      


        
        <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      textAlign="center"
                      sx={{width: "100%", bgcolor: "#f0f4f5", opacity: "0.7", borderRadius: "25px", marginTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "30px"}}>
                <Typography sx={{paddingTop: "30px", paddingBottom: "10px", paddingLeft: "20px", paddingRight: "20px", fontSize: "16px", fontWeight: "700"}} >Este despacho/empresa no tiene suficientes valoraciones.
        </Typography>
        <Typography sx={{paddingBottom: "15px", paddingLeft: "20px", paddingRight: "20px", fontSize: "14px", fontWeight: "700"}} >¿Trabajas o has trabajado aquí? Deja tu opinión y ayuda a otros abogados.
        </Typography>

      <Button variant="contained" href="#contained-buttons">
        Próximamente
      </Button>
        </Stack>
  



       
                      

                  
                            
        </CardContent>}


        </Box>
       
    
       

    </Card>


   {((isPremium || isOldPremium) && tieneValoraciones) && 
   <div>

    <Card sx={{border: "0px 1px 1px 1px", borderRadius:"0px 0px 0px 0px"}}>
    <Divider />
    <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      textAlign= "center"
                      spacing={2}
                      sx={{paddingTop: "45px", paddingLeft: "15px", paddingRight: "15px"}}>
                             <Typography variant="h6" color="text.secondary" sx={{paddingBottom: "0px"}}>
                                  Opiniones de los abogados de {despacho} 
                              </Typography>
                              </Stack>
                             

                      <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                      sx={{paddingTop: "20px", paddingBottom: "30px"}}>
                       
                       
                       

               
                       {comentarios !== undefined && <Listas/>}

    </Stack>


    </Card>
    {positivo !== undefined &&  <Card sx={{border: "0px 1px 1px 1px", borderRadius:"0px 0px 0px 0px", marginBottom: "30px"}}>
    <Divider />

  
    <Divider />
    <Stack 
                      alignItems="center"
                      spacing={2}
                      sx={{paddingTop: "45px"}}>
                             <Typography variant="p" color="text.secondary" sx={{backgroundColor: "#35b50e", borderRadius: "10px", color:"white", padding: "6px"}}>
                                 Lo positivo
                              </Typography>
                              </Stack>
                             

                      <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                      sx={{paddingTop: "20px", paddingBottom: "30px", paddingLeft:"20px", paddingRight: "20px"}}>
                       
                       
                       

               
                       <Positivo/>
                       <Pagination count={noOfPages2} page={page2} onChange={handleChange2} variant="outlined" shape="rounded" />
    </Stack>

    <Divider orientation="vertical" variant="middle" flexItem />

    <Divider />
    <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                      sx={{paddingTop: "45px"}}>
                             <Typography variant="p" color="text.secondary" sx={{backgroundColor: "#d60f16", borderRadius: "10px", color:"white", padding: "6px"}}>
                                  Lo negativo 
                              </Typography>
                              </Stack>
                             

                      <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                      sx={{paddingTop: "20px", paddingBottom: "30px", paddingLeft:"20px", paddingRight: "20px"}}>
                       
                       
                       

               
                       <Negativo/>
    <Pagination count={noOfPages3} page={page3} onChange={handleChange3} variant="outlined" shape="rounded" />
    </Stack>


    </Card> }
    </div> }
  </Box>
  </AuthProvider>

  


  
    </div>
  );
}