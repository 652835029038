import React from 'react'
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

function Politicadecookies() {
  return (
    <div>
        <Stack sx={{padding: "20px"}}>
        <p>1. Pol&iacute;tica de privacidad y cookies.</p>

<p>Tratamiento de datos de car&aacute;cter personal</p>

<p>Jobs&amp;Law (en adelante la web) es propiedad de Trasluche, S.L. (en adelante el Titular), con NIF B88448899. La administrador &uacute;nica de la compa&ntilde;&iacute;a es Luc&iacute;a MArt&iacute;n Robles, con tel&eacute;fono 619704002 y correo electr&oacute;nico candidatos@jobsandlaw.com.</p>

<p>En cumplimiento con lo dispuesto en la Ley Org&aacute;nica 15/1999, de 13 de diciembre, de Protecci&oacute;n de Datos de Car&aacute;cter Personal (&lsquo;LOPD&rsquo;), le informamos de que los datos personales e informaci&oacute;n que nos facilite al cumplimentar cualquier formulario electr&oacute;nico disponible en la Web, ser&aacute;n incorporados en un fichero de datos de car&aacute;cter personal titularidad del Titular.</p>

<p>Los datos obtenidos ser&aacute;n empleados para el cumplimiento de las finalidades siguientes: establecer una relaci&oacute;n de usuarios de la Web inscritos, con el objeto de mantener contacto comercial, as&iacute; como su gesti&oacute;n, y la realizaci&oacute;n de estad&iacute;sticas y segmentaci&oacute;n. El usuario consiente expresamente la comunicaci&oacute;n o cesi&oacute;n de sus datos personales a los proveedores que intervienen en la oferta y prestaci&oacute;n de nuestros servicios, como por ejemplo proveedores, hosting o mensajer&iacute;a, con el exclusivo fin de la correcta consecuci&oacute;n del servicio solicitado.</p>

<p>El usuario inscrito podr&aacute; recibir autom&aacute;ticamente publicaciones y comunicaciones comerciales de la Web, aceptando expresamente el usuario su consentimiento a tal fin. El usuario podr&aacute; darse de baja de dichas comunicaciones comerciales peri&oacute;dicas en cualquier momento mediante remisi&oacute;n al correo electr&oacute;nico candidatos@jobsandlaw.com indicando dicha intenci&oacute;n.</p>

<p>El Titular es el responsable del fichero de forma individual y se compromete a no destinar los datos a una finalidad distinta para la cual fueron recogidos ni a ceder los mismos de forma ileg&iacute;tima a terceros. Tambi&eacute;n se compromete a tratar sus datos confidencialmente y a aplicar las medidas de seguridad de &iacute;ndole t&eacute;cnica y/u, organizativa necesarias para evitar su tratamiento o acceso no autorizado seg&uacute;n establece la normativa vigente en materia de protecci&oacute;n de datos.</p>

<p>Estos t&eacute;rminos y condiciones pueden ser modificados. 7 d&iacute;as antes de su entrada en vigor, se comunicar&aacute;n al usuario esos cambios mediante correo electr&oacute;nico o aviso en nuestra web.</p>

<p>Derechos ARCO</p>

<p>El usuario podr&aacute; ejercer gratuitamente sus derechos de Acceso, Rectificaci&oacute;n, Cancelaci&oacute;n y Oposici&oacute;n (&lsquo;Derechos ARCO&rsquo;) en los t&eacute;rminos legalmente previstos debiendo para ello dirigir correo electr&oacute;nico a la siguiente direcci&oacute;n: contacto@esderecho.org, indicando en ambos casos su nombre y apellidos y adjuntando fotocopia de su DNI. Tambi&eacute;n puede dirigir carta escrita a nuestro domicilio informado anteriormente.</p>

<p>2. Pol&iacute;tica de uso de Cookies</p>

<p>En cumplimiento de lo dispuesto en el art&iacute;culo 22.2 LSSI, la Web &uacute;nicamente utilizar&aacute; dispositivos de almacenamiento y recuperaci&oacute;n de datos (&lsquo;Cookies&rsquo;) cuando el usuario haya dado su consentimiento para ello, de acuerdo con lo que se indica en la ventana emergente del navegador del usuario cuando accede por primera vez a la Web y en los dem&aacute;s t&eacute;rminos y condiciones siguientes que todo usuario debe conocer.</p>

<p>Nuestra Pol&iacute;tica de Cookies tiene como objetivo ayudar a comprender el uso que hacemos de las cookies, la finalidad de las cookies utilizadas, as&iacute; como las posibles opciones que el usuario dispone para gestionarlas. La pol&iacute;tica publicada est&aacute; sujeta a actualizaciones peri&oacute;dicas.</p>

<p>Los usuarios pueden acceder a esta informaci&oacute;n en todo momento mediante el enlace &ldquo;Pol&iacute;tica de privacidad y Cookies&rdquo; habilitado en la web. Los usuarios pueden modificar las preferencias sobre la aceptaci&oacute;n de cookies a trav&eacute;s de las opciones de su navegador. En el apartado de &ldquo;Modificar la configuraci&oacute;n de las cookies&rdquo; encontrar&aacute; m&aacute;s informaci&oacute;n.</p>

<p>&iquest;Qu&eacute; opciones puedo tomar?</p>

<p>Es posible realizar las siguientes acciones:</p>

<p>Aceptar cookies: No se volver&aacute; a visualizar el aviso al acceder a cualquier p&aacute;gina de la Tienda.</p>

<p>No hacer nada: Se visualizar&aacute; el aviso siempre al acceder a cualquier p&aacute;gina de la Web. Si contin&uacute;a navegando, consideramos que acepta el uso de las cookies.</p>

<p>Modificar la configuraci&oacute;n de las cookies: Podr&aacute; borrar las cookies de la Web y modificar la configuraci&oacute;n de su navegaci&oacute;n. Pero esto no evitar&aacute; que se visualice el aviso de las cookies al acceder de nuevo a la web.</p>

<p>&iquest;Qu&eacute; es una cookie?</p>

<p>Una &ldquo;Cookie&rdquo; es un peque&ntilde;o archivo de texto que un sitio web almacena en el navegador del usuario. Las cookies facilitan el uso y la navegaci&oacute;n por una p&aacute;gina web y son esenciales para el funcionamiento de internet, aportando innumerables ventajas en la prestaci&oacute;n de servicios interactivos.</p>

<p>As&iacute;, por ejemplo, son utilizadas para gestionar la sesi&oacute;n del usuario (reduciendo el n&uacute;mero de veces que se debe introducir la clave), o para adaptar los contenido de la web a los sus preferencias, entre otras facilidades.</p>

<p>M&aacute;s informaci&oacute;n en http://es.wikipedia.org/wiki/Cookie_%28inform%C3%A1tica%29</p>

<p>En la</p>

<p>gu&iacute;a sobre el uso de cookies de la Agencia de Protecci&oacute;n de Datos</p>

<p>se detallan los diferentes tipos de cookies.</p>

<p>&iquest;Qu&eacute; tipos de cookies utilizamos?</p>

<p>1.- En funci&oacute;n de qui&eacute;n las gestiona:</p>

<p>Cookies propias: son las que se env&iacute;an a su navegador desde nuestros equipos o dominios web y desde el que se presta el servicio solicitado.</p>

<p>Cookies de terceros: son las que se env&iacute;an a su navegador desde un equipo o dominio web que no gestionamos nosotros, sino una entidad colaboradora como pueda ser Google Analytics.</p>

<p>2.- En funci&oacute;n del tiempo que est&aacute;n activas:</p>

<p>Cookies de sesi&oacute;n: son las que permanecen en el archivo de cookies de su navegador hasta que abandona la p&aacute;gina web, de modo que ninguna queda en su dispositivo.</p>

<p>Cookies persistentes: son las que permanecen en su dispositivo y nuestra web las lee cada vez que realiza una nueva visita. Estas cookies dejan de funcionar pasada una fecha concreta.</p>

<p>3.- En funci&oacute;n de su finalidad:</p>

<p>Cookies t&eacute;cnicas: son las que mejoran la navegaci&oacute;n y el buen funcionamiento de nuestra web. Cookies de personalizaci&oacute;n: son las que permiten acceder al servicio con unas caracter&iacute;sticas predefinidas en funci&oacute;n de una serie de criterios. Sirven, por ejemplo, para elegir el idioma de la web.</p>

<p>Cookies de an&aacute;lisis: son las que permiten medir y analizar estad&iacute;sticamente el uso que se hace del servicio prestado. Sirven por ejemplo para saber qui&eacute;n visita la web.</p>

<p>Cookies que usamos:</p>

<p></p>

<p>Nombre</p>

<p>Expiraci&oacute;n</p>

<p>Finalidad</p>

<p>uvc</p>

<p>1 dia.</p>

<p>Compartir los contenidos de la web en las redes sociales v&iacute;a Addtoany y realizar la anal&iacute;tica de la web con Google Analitics. Estas cookies recopilan informaci&oacute;n sobre la actividad de los usuarios y no sobre su identificaci&oacute;n personal. Informaci&oacute;n sobre el origen de las visitas, palabras claves, navegador utilizado, etc. _ga se usa para distinguir a los usuarios.</p>

<p>__utma</p>

<p>2 a&ntilde;os.</p>

<p>__utmb</p>

<p>30 minutos.</p>

<p>__utmc</p>

<p>Sesi&oacute;n</p>

<p>__utmz _ga</p>

<p>_gat</p>

<p>6 meses.</p>

<p>2 a&ntilde;os</p>

<p>10 minutos</p>

<p>ipinfo</p>

<p>6 meses.</p>

<p>Anal&iacute;tica de publicidad con Media6degrees.</p>

<p>Las cookies recogen informaci&oacute;n sobre los usuarios al utilizar el bot&oacute;n de redes sociales para compartir los contenidos de la web.</p>

<p>clid</p>

<p>6 meses.</p>

<p>acs</p>

<p>6 meses.</p>

<p></p>

<p>Terceros prestadores de servicios en nuestro dominio:</p>

<p>Addtoany. Es un servicio proporcionado por addtonay.com y static.addtonay.com para que los usuarios pueda compartir informaci&oacute;n de webs de forma sencilla en las redes sociales. M&aacute;s informaci&oacute;n en http://www.addtoany.com/privacy</p>

<p>Google Analitics. Es una aplicaci&oacute;n de software de Google Inc. para la anal&iacute;tica de webs. Las cookies utilizadas recopilan datos an&oacute;nimos sobre la navegaci&oacute;n de los usuarios por la web con la finalidad de conocer el origen de las visitas, el n&uacute;mero de veces que han visitado la web, las palabras claves utilizadas, y otros datos estad&iacute;sticos. M&aacute;s informaci&oacute;n en http://www.google.com/analytics/</p>

<p>Media6degrees.Es una empresa proveedora de an&aacute;lisis de publicidad online mediante las redes sociales. Recopila informaci&oacute;n sobre la actividad de los usuarios en la web con fines publicitarios. M&aacute;s informaci&oacute;n en http://media6degrees.com/privacy.php</p>

<p>Modificar la configuraci&oacute;n de las cookies.</p>

<p>Usted puede restringir, bloquear o borrar las cookies de la Web configurando las preferencias de su navegador. As&iacute; mismo, puede activar la opci&oacute;n de navegaci&oacute;n privada por la que su navegador deja de almacenar el historial de navegaci&oacute;n, sus claves secretas, cookies y dem&aacute;s informaci&oacute;n de las p&aacute;ginas que visite.</p>

<p>En cada navegador la configuraci&oacute;n de las cookies es diferente, la funci&oacute;n de &ldquo;Ayuda&rdquo; le mostrar&aacute; c&oacute;mo hacerlo, sin embargo le indicamos los enlaces directos de los principales navegadores sobre el tema :</p>

<p>InternetExplorer (enlaces)</p>

<p>FireFox</p>

<p>Chrome</p>

<p>Safari</p>

<p>Para terminar, tenga en cuenta que el bloqueo de cookies puede afectar a toda o alguna funcionalidad de la web.</p>
    </Stack>
    </div>
  )
}

export default Politicadecookies