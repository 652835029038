import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import StarIcon from '@mui/icons-material/Star';
import { Hidden } from '@mui/material';
import { makeStyles } from '@mui/material';
import { blue } from '@mui/material/colors';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import { useState } from 'react';
import { useUserAuth } from '../context/UserAuthContext';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { useNavigate } from 'react-router';
import RichTextEditor from '../components/RichTextEditor';
import trabajoServices from '../services/trabajo.services';
import TrabajoDataService from '../services/trabajo.services';
import Autocomplete from '@mui/material/Autocomplete';
import listareas from './listareas';
import Slider from '@mui/material/Slider';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import parse from "html-react-parser";
import "./custom.css";
import { serverTimestamp } from 'firebase/firestore';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { setLogo } from '../firebase-config';
import Image from 'mui-image';
import { getPerfil, insertNewPerfil, updatePerfil } from '../firebase-config';
import AuthProvider from '../components/authProvider';
import { insertNewNota} from '../firebase-config';
import {v4 as uuidv4} from "uuid";
import Rating from '@mui/material/Rating';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import Brightness1OutlinedIcon from '@mui/icons-material/Brightness1Outlined';
import { styled } from '@mui/material/styles';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

const salarios = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 10,
    label: '10k',
  },
  {
    value: 20,
    label: '20k',
  },
  {
    value: 30,
    label: '30k',
  },
  {
    value: 40,
    label: '40k',
  },
  {
    value: 50,
    label: '50k',
  },
  {
    value: 60,
    label: '60k',
  },
  {
    value: 70,
    label: '70k',
  },
  {
    value: 80,
    label: '80k',
  },
  {
    value: 90,
    label: '90k',
  },
  {
    value: 100,
    label: '100k',
  },
  {
    value: 110,
    label: '110k',
  },
  {
    value: 120,
    label: '120k',
  },
  {
    value: 130,
    label: '130k',
  },
  {
    value: 140,
    label: '140k',
  },
  {
    value: 150,
    label: '150k',
  },
  {
    value: 160,
    label: '160k',
  },
  
  {
    value: 170,
    label: '170k',
  },
  {
    value: 180,
    label: '180k+',
  },
];

const salariosMovil = [
  {
    value: 0,
    label: '0',
  },

  {
    value: 20,
    label: '20k',
  },

  {
    value: 40,
    label: '40k',
  },

  {
    value: 60,
    label: '60k',
  },

  {
    value: 80,
    label: '80k',
  },

  {
    value: 100,
    label: '100k',
  },

  {
    value: 120,
    label: '120k',
  },

  {
    value: 140,
    label: '140k',
  },

  {
    value: 160,
    label: '160k',
  },
  
 
  {
    value: 180,
    label: '180k+',
  },
];

function NuevaNota() { 

    const labels = {
        1: 'Totalmente en desacuerdo',
        2: 'En desacuerdo',
        3: 'Neutral',
        4: 'De acuerdo',
        5: 'Totalmente de acuerdo',
      };

      function getLabelText(value) {
        return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
      }
    
    const [currentUser, setCurrentUser] = useState({});
    const [perfiles, setPerfiles] = useState({});
    const [notas, setNotas] = useState([ ]);
    const [despacho, setDespacho] = useState();
    const navigate = useNavigate();
    const [ambiente1, setAmbiente1] = React.useState(0);
    const [hoverAmbiente1, setHoverAmbiente1] = React.useState(-1);
    const [ambiente2, setAmbiente2] = React.useState(0);
    const [hoverAmbiente2, setHoverAmbiente2] = React.useState(-1);
    const [teletrabajo, setTeletrabajo] = React.useState(0);
    const [hoverTeletrabajo, setHoverTeletrabajo] = React.useState(-1);
    const [conciliacion1, setConciliacion1] = React.useState(0);
    const [hoverConciliacion1, setHoverConciliacion1] = React.useState(-1);
    const [conciliacion2, setConciliacion2] = React.useState(0);
    const [hoverConciliacion2, setHoverConciliacion2] = React.useState(-1);
    const [carga, setCarga] = React.useState(0);
    const [hoverCarga, setHoverCarga] = React.useState(-1);
    const [atractivo, setAtractivo] = React.useState(0);
    const [hoverAtractivo, setHoverAtractivo] = React.useState(-1);
    const [variado, setVariado] = React.useState(0);
    const [hoverVariado, setHoverVariado] = React.useState(-1);
    const [formacion1, setFormacion1] = React.useState(0);
    const [hoverFormacion1, setHoverFormacion1] = React.useState(-1);
    const [formacion2, setFormacion2] = React.useState(0);
    const [hoverFormacion2, setHoverFormacion2] = React.useState(-1);
    const [carrera, setCarrera] = React.useState(0);
    const [hoverCarrera, setHoverCarrera] = React.useState(-1);
    const [carrera2, setCarrera2] = React.useState(0);
    const [hoverCarrera2, setHoverCarrera2] = React.useState(-1);
    const [diversidad1, setDiversidad1] = React.useState(0);
    const [hoverDiversidad1, setHoverDiversidad1] = React.useState(-1);
    const [diversidad2, setDiversidad2] = React.useState(0);
    const [hoverDiversidad2, setHoverDiversidad2] = React.useState(-1);
    const [valores, setValores] = React.useState(0);
    const [hoverValores, setHoverValores] = React.useState(-1);
    const [proBono, setProBono] = React.useState(0);
    const [hoverProBono, setHoverProBono] = React.useState(-1);
    const [igualdad, setIgualdad] = React.useState(0);
    const [hoverIgualdad, setHoverIgualdad] = React.useState(-1);
    const [opinion, setOpinion] = useState("");
    const [positivo, setPositivo] = useState("");
    const [negativo, setNegativo] = useState("");
    const [salario, setSalario] = useState();
    const [bonus, setBonus] = useState();
    const [diasTeletrabajo, setDiasTeletrabajo] = useState();
    

    

    const options = [];
    
    const [value, setValue] = React.useState();
    const [inputValue, setInputValue] = React.useState();
    const [nombre, setNombre] = React.useState('');

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      }));

    const StyledRating = styled(Rating)({
        '& .MuiRating-iconFilled': {
          color: '#037bfc',
        },
        '& .MuiRating-iconHover': {
          color: '#037bfc',
        },
      });

    async function handleUserLoggedIn(user) {
        setCurrentUser(user);
        console.log("Estás registrado");
        const resPerfil = await getPerfil(user.uid);
        setPerfiles([...resPerfil]);
        setNombre(perfiles.nombre);
       }
    
       function handleUserNotRegistered(user) {
        navigate("../signin");
    
       }
       
       function handleUserNotLoggedIn() {
        navigate("../alljobs");
       }
     


   

if (perfiles[0]) {
    console.log(perfiles[0].nombre)
    if (perfiles[0].empresa1 !== "") {
        options.push(perfiles[0].empresa1)
    }

    if (perfiles[0].empresa2 !== "") {
        options.push(perfiles[0].empresa2)
    }

    if (perfiles[0].empresa3 !== "") {
        options.push(perfiles[0].empresa3)
    }

    if (perfiles[0].empresa4 !== "") {
        options.push(perfiles[0].empresa4)
    }

    if (perfiles[0].empresa5 !== "") {
        options.push(perfiles[0].empresa5)
    }

    if (perfiles[0].empresa6 !== "") {
        options.push(perfiles[0].empresa6)
    }

    if (perfiles[0].empresa7 !== "") {
        options.push(perfiles[0].empresa7)
    }

    if (perfiles[0].empresa8 !== "") {
        options.push(perfiles[0].empresa8)
    }
    console.log(options)
}

function handleSubmit (e) {
    e.preventDefault();
    addNota();
    console.log("ok")
    navigate("../dashboard");
   }

   function addNota(){
    const newNota = {
      id: uuidv4(),
      uid: currentUser.uid,
      inputValue: inputValue,
      ambiente1: ambiente1,
      teletrabajo: teletrabajo,
      conciliacion1: conciliacion1,
      conciliacion2: conciliacion2,
      carga: carga,
      atractivo: atractivo,
      variado: variado,
      formacion1: formacion1,
      formacion2: formacion2,
      carrera: carrera,
      carrera2: carrera2,
      diversidad1: diversidad1,
      diversidad2: diversidad2,
      valores: valores,
      proBono: proBono,
      igualdad: igualdad,
      opinion: opinion,
      positivo: positivo,
      negativo: negativo,
      salario: salario,
      bonus: bonus,
      diasTeletrabajo: diasTeletrabajo,



    };
    const res = insertNewNota(newNota);
    newNota.docId = res.id;
    setNotas([...notas, newNota]);
   }

   function handleChange(e) {
    const value = e.target.value;
    if(e.target.name === "opinion"){
      setOpinion(value)
      console.log(nombre);
    }

    if(e.target.name === "positivo"){
      setPositivo(value)
      console.log(positivo);
    }

    if(e.target.name === "negativo"){
      setNegativo(value)
      console.log(negativo);
    }

  }

  const handleChangeSalario = (event, newSalario) => {
    setSalario(newSalario);
  };

  const handleChangeBonus = (event, newBonus) => {
    setBonus(newBonus);
  };

  const handleChangeDiasTeletrabajo = (event) => {
    setDiasTeletrabajo(event.target.value);
 
   }

  function valuetext(value: number) {
    return `${value}k`;
  }

    return (
    <div>
    <AuthProvider 
      onUserLoggedIn={handleUserLoggedIn}
      onUserNotRegistered={handleUserNotRegistered}
      onUserNotLoggedIn={handleUserNotLoggedIn}
      > 
           <Box sx={{display: { xl: 'block', l: "block", m: "block", s:"none", xs: 'none'}}} >
    <Grid container component="main">

        <Grid item xs={12} sm={12} md={12} square>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: "center",
              alignItems: "center",
              bgcolor: 'white',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          >
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{mt: 1, color: "white", bgcolor: "rgba(228, 237, 246, 0.17)", marginTop: "60px", marginBottom: "60px", borderRadius: "25px", padding: "20px", maxWidth: "850px" }}>
            <Image href="./" src="/images/logo.png" style={{width: "130px"}} sx={{marginLeft: "60px", marginRight: "60px"}} />
            <Typography variant="h5" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "0px", textAlign: "center", fontWeight: "bold"}}
              >Valora tu despacho o empresa
            </Typography>
            <Typography variant="h6" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "10px", textAlign: "center"}}
              >Da tu opinión de forma anónima y confidencial. Ayudarás a otros abogados a conocer las ventajas e incovenientes de trabajar allí, y obtendrás acceso a las valoraciones de todos los despachos y empresas.
            </Typography>
  
        

            <Stack direction="column" alignItems="center" spacing={2} sx={{paddingTop: "30px", paddingBottom: "10px"}}>
      <Autocomplete
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        id="controllable-states-demo"
        options={options}
        sx={{ width: 600 }}
        renderInput={(params) => <TextField {...params} label="Despacho o empresa" />}
      />
    </Stack>
    <Divider sx={{paddingTop: "40px"}} >
            <Chip label="Conciliación y tipo de trabajo" sx={{color: "white", bgcolor: "rgba(43, 117, 177, 0.9)"}} />
            </Divider>







        


        <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{width: "100%"}}>
        <Typography sx={{paddingTop: "30px", paddingRight: "20px", paddingLeft: "20px", paddingBottom: "10px", fontSize: "16px", fontWeight: "500", color: "rgba(43, 117, 177, 0.9)", textAlign: "center"}} >Creo que el despacho hace todo lo posible para facilitar la conciliación de los abogados:
        </Typography>
        </Stack>
      <Grid container paddingTop="0px" paddingBottom="30px">
      <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
        <Stack direction="column"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      sx={{width: "100%"}}>

        <StyledRating
        sx={{paddingTop: "0px", paddingRight: "0px"}}
        name="customized-color"
        defaultValue={conciliacion1}
        getLabelText={getLabelText}
        onChange={(event, newValue3) => {
            setConciliacion1(newValue3);
            console.log(conciliacion1)
          }}
          onChangeActive={(event, newHover2) => {
            setHoverConciliacion1(newHover2);
       
          }}
        precision={1}
        size="small"
        icon={<Brightness1Icon fontSize="inherit" />}
        emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
      />
        </Stack>
        
        </Grid>
       
        
        
      
        <Grid item xs={5} sm={5} md={5} lg={5} xl={5} >
        <Stack direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      sx={{width: "100%"}}>
                
        <Box sx={{ ml: 2, color: "rgba(43, 117, 177, 0.9)", alignContent: "center", alignItems: "center", fontSize: "16px", marginLeft: "35px" }}>{labels[conciliacion1 === 0 ? hoverConciliacion1 : conciliacion1]}</Box>
      
        </Stack>

        

        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        </Grid>


        <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{width: "100%"}}>
        <Typography sx={{paddingRight: "20px", paddingLeft: "20px", paddingBottom: "10px", fontSize: "16px", fontWeight: "500", color: "rgba(43, 117, 177, 0.9)", textAlign: "center"}} >El despacho me permite conciliar mi vida laboral con mi vida personal:
        </Typography>
        </Stack>
      <Grid container paddingTop="0px" paddingBottom="30px">
      <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
        <Stack direction="column"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      sx={{width: "100%"}}>

        <StyledRating
        sx={{paddingTop: "0px", paddingRight: "0px"}}
        name="customized-color"
        defaultValue={conciliacion2}
        getLabelText={getLabelText}
        onChange={(event, newValue3) => {
            setConciliacion2(newValue3);
            console.log(carga)
          }}
          onChangeActive={(event, newHover2) => {
            setHoverConciliacion2(newHover2);
       
          }}
        precision={1}
        size="small"
        icon={<Brightness1Icon fontSize="inherit" />}
        emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
      />
        </Stack>
        
        </Grid>
       
        
        
      
        <Grid item xs={5} sm={5} md={5} lg={5} xl={5} >
        <Stack direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      sx={{width: "100%"}}>
                
        <Box sx={{ ml: 2, color: "rgba(43, 117, 177, 0.9)", alignContent: "center", alignItems: "center", fontSize: "16px", marginLeft: "35px" }}>{labels[conciliacion2 === 0 ? hoverConciliacion2 : conciliacion2]}</Box>
      
        </Stack>

        

        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        </Grid>



        <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{width: "100%"}}>
        <Typography sx={{paddingTop: "0px", paddingRight: "20px", paddingLeft: "20px", paddingBottom: "10px", fontSize: "16px", fontWeight: "500", color: "rgba(43, 117, 177, 0.9)", textAlign: "center"}} >Hay una carga de trabajo razonable:
        </Typography>
        </Stack>
      <Grid container paddingTop="0px" paddingBottom="30px">
      <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
        <Stack direction="column"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      sx={{width: "100%"}}>

        <StyledRating
        sx={{paddingTop: "0px", paddingRight: "0px"}}
        name="customized-color"
        defaultValue={carga}
        getLabelText={getLabelText}
        onChange={(event, newValue3) => {
            setCarga(newValue3);
            console.log(carga)
          }}
          onChangeActive={(event, newHover2) => {
            setHoverCarga(newHover2);
       
          }}
        precision={1}
        size="small"
        icon={<Brightness1Icon fontSize="inherit" />}
        emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
      />
        </Stack>
        
        </Grid>
       
        
        
      
        <Grid item xs={5} sm={5} md={5} lg={5} xl={5} >
        <Stack direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      sx={{width: "100%"}}>
                
        <Box sx={{ ml: 2, color: "rgba(43, 117, 177, 0.9)", alignContent: "center", alignItems: "center", fontSize: "16px", marginLeft: "35px" }}>{labels[carga === 0 ? hoverCarga : carga]}</Box>
      
        </Stack>

        

        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        </Grid>

        

        <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{width: "100%"}}>
        <Typography sx={{paddingTop: "0px", paddingRight: "20px", paddingLeft: "20px", paddingBottom: "10px", fontSize: "16px", fontWeight: "500", color: "rgba(43, 117, 177, 0.9)", textAlign: "center"}} >La política de teletrabajo es atractiva:
        </Typography>
        </Stack>
      <Grid container paddingTop="0px" paddingBottom="30px">
      <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
        <Stack direction="column"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      sx={{width: "100%"}}>

        <StyledRating
        sx={{paddingTop: "0px", paddingRight: "0px"}}
        name="customized-color"
        defaultValue={teletrabajo}
        getLabelText={getLabelText}
        onChange={(event, newValue3) => {
            setTeletrabajo(newValue3);
       
          }}
          onChangeActive={(event, newHover3) => {
            setHoverTeletrabajo(newHover3);
       
          }}
        precision={1}
        size="small"
        icon={<Brightness1Icon fontSize="inherit" />}
        emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
      />
        </Stack>
        
        </Grid>
       
        
        
      
        <Grid item xs={5} sm={5} md={5} lg={5} xl={5} >
        <Stack direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      sx={{width: "100%"}}>
            
        <Box sx={{ ml: 2, color: "rgba(43, 117, 177, 0.9)", alignContent: "center", alignItems: "center", fontSize: "16px", marginLeft: "35px" }}>{labels[teletrabajo === 0 ? hoverTeletrabajo : teletrabajo]}</Box>
     
        </Stack>

        

        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        </Grid>

          <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{width: "100%"}}>
                <Typography sx={{paddingTop: "0px", paddingRight: "20px", paddingLeft: "20px", paddingBottom: "10px", fontSize: "16px", fontWeight: "500", color: "rgba(43, 117, 177, 0.9)", textAlign: "center"}} >¿Cuántos días a la semana teletrabajas?
        </Typography>
        <FormControl>
      <FormLabel id="demo-row-radio-buttons-group-label"></FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={diasTeletrabajo}
        onChange={handleChangeDiasTeletrabajo}
      >
        <FormControlLabel sx={{color: "rgba(43, 117, 177, 0.9)", marginRight: "3px", marginLeft: "3px"}} labelPlacement="bottom" value="0" control={<Radio size="small" sx={{color: "rgba(43, 117, 177, 0.9)"}} />} label="0" />
        <FormControlLabel sx={{color: "rgba(43, 117, 177, 0.9)", marginRight: "3px", marginLeft: "3px"}} labelPlacement="bottom" value="1" control={<Radio size="small" sx={{color: "rgba(43, 117, 177, 0.9)"}} />} label="1" />
        <FormControlLabel sx={{color: "rgba(43, 117, 177, 0.9)", marginRight: "3px", marginLeft: "3px"}} labelPlacement="bottom" value="2" control={<Radio size="small" sx={{color: "rgba(43, 117, 177, 0.9)"}} />} label="2" />
        <FormControlLabel sx={{color: "rgba(43, 117, 177, 0.9)", marginRight: "3px", marginLeft: "3px"}} labelPlacement="bottom" value="3" control={<Radio size="small" sx={{color: "rgba(43, 117, 177, 0.9)"}} />} label="3" />
        <FormControlLabel sx={{color: "rgba(43, 117, 177, 0.9)", marginRight: "3px", marginLeft: "3px"}} labelPlacement="bottom" value="4" control={<Radio size="small" sx={{color: "rgba(43, 117, 177, 0.9)"}} />} label="4" />
        <FormControlLabel sx={{color: "rgba(43, 117, 177, 0.9)", marginRight: "3px", marginLeft: "3px"}} labelPlacement="bottom" value="5" control={<Radio size="small" sx={{color: "rgba(43, 117, 177, 0.9)"}} />} label="5" />
      </RadioGroup>
    </FormControl>
    </Stack>

        <Divider sx={{paddingTop: "40px"}} >
            <Chip label="Ambiente laboral" sx={{color: "white", bgcolor: "rgba(43, 117, 177, 0.9)"}} />
            </Divider>

        
        <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{width: "100%", marginTop: "40px"}}>
        <Typography sx={{paddingTop: "0px", paddingRight: "20px", paddingLeft: "20px", paddingBottom: "10px", fontSize: "16px", fontWeight: "500", color: "rgba(43, 117, 177, 0.9)", textAlign: "center"}} >En el despacho hay un excelente ambiente laboral:
        </Typography>
        </Stack>
      <Grid container paddingTop="0px" paddingBottom="30px">
      <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
        <Stack direction="column"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      sx={{width: "100%"}}>

        <StyledRating
        sx={{paddingTop: "0px", paddingRight: "0px"}}
        name="customized-color"
        defaultValue={ambiente1}
        getLabelText={getLabelText}
        onChange={(event, newValue3) => {
            setAmbiente1(newValue3);
       
          }}
          onChangeActive={(event, newHover3) => {
            setHoverAmbiente1(newHover3);
       
          }}
        precision={1}
        size="small"
        icon={<Brightness1Icon fontSize="inherit" />}
        emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
      />
        </Stack>
        
        </Grid>
       
        
        
      
        <Grid item xs={5} sm={5} md={5} lg={5} xl={5} >
        <Stack direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      sx={{width: "100%"}}>
            
        <Box sx={{ ml: 2, color: "rgba(43, 117, 177, 0.9)", alignContent: "center", alignItems: "center", fontSize: "16px", marginLeft: "35px" }}>{labels[ambiente1 === 0 ? hoverAmbiente1 : ambiente1]}</Box>
     
        </Stack>

        

        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        </Grid>



        <Divider sx={{paddingTop: "40px"}} >
            <Chip label="Tipo de trabajo" sx={{color: "white", bgcolor: "rgba(43, 117, 177, 0.9)"}} />
            </Divider>

        
        <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{width: "100%", marginTop: "40px"}}>
        <Typography sx={{paddingTop: "0px", paddingRight: "20px", paddingLeft: "20px", paddingBottom: "10px", fontSize: "16px", fontWeight: "500", color: "rgba(43, 117, 177, 0.9)", textAlign: "center"}} >Hago un tipo de trabajo atractivo:
        </Typography>
        </Stack>
      <Grid container paddingTop="0px" paddingBottom="30px">
      <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
        <Stack direction="column"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      sx={{width: "100%"}}>

        <StyledRating
        sx={{paddingTop: "0px", paddingRight: "0px"}}
        name="customized-color"
        defaultValue={atractivo}
        getLabelText={getLabelText}
        onChange={(event, newValue3) => {
            setAtractivo(newValue3);
       
          }}
          onChangeActive={(event, newHover3) => {
            setHoverAtractivo(newHover3);
       
          }}
        precision={1}
        size="small"
        icon={<Brightness1Icon fontSize="inherit" />}
        emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
      />
        </Stack>
        
        </Grid>
       
        
        
      
        <Grid item xs={5} sm={5} md={5} lg={5} xl={5} >
        <Stack direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      sx={{width: "100%"}}>
            
        <Box sx={{ ml: 2, color: "rgba(43, 117, 177, 0.9)", alignContent: "center", alignItems: "center", fontSize: "16px", marginLeft: "35px" }}>{labels[atractivo === 0 ? hoverAtractivo : atractivo]}</Box>
     
        </Stack>

        

        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        </Grid>


        <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{width: "100%"}}>
        <Typography sx={{paddingTop: "0px", paddingRight: "20px", paddingLeft: "20px", paddingBottom: "10px", fontSize: "16px", fontWeight: "500", color: "rgba(43, 117, 177, 0.9)", textAlign: "center"}} >Hago un trabajo variado:
        </Typography>
        </Stack>
      <Grid container paddingTop="0px" paddingBottom="30px">
      <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
        <Stack direction="column"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      sx={{width: "100%"}}>

        <StyledRating
        sx={{paddingTop: "0px", paddingRight: "0px"}}
        name="customized-color"
        defaultValue={variado}
        getLabelText={getLabelText}
        onChange={(event, newValue3) => {
            setVariado(newValue3);
       
          }}
          onChangeActive={(event, newHover3) => {
            setHoverVariado(newHover3);
       
          }}
        precision={1}
        size="small"
        icon={<Brightness1Icon fontSize="inherit" />}
        emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
      />
        </Stack>
        
        </Grid>
       
        
        
      
        <Grid item xs={5} sm={5} md={5} lg={5} xl={5} >
        <Stack direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      sx={{width: "100%"}}>
            
        <Box sx={{ ml: 2, color: "rgba(43, 117, 177, 0.9)", alignContent: "center", alignItems: "center", fontSize: "16px", marginLeft: "35px" }}>{labels[variado === 0 ? hoverVariado : variado]}</Box>
     
        </Stack>

        

        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        </Grid>

           


        <Divider sx={{paddingTop: "40px"}} >
            <Chip label="Formación" sx={{color: "white", bgcolor: "rgba(43, 117, 177, 0.9)"}} />
            </Divider>

        
        <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{width: "100%", marginTop: "40px"}}>
        <Typography sx={{paddingTop: "0px", paddingRight: "20px", paddingLeft: "20px", paddingBottom: "10px", fontSize: "16px", fontWeight: "500", color: "rgba(43, 117, 177, 0.9)", textAlign: "center"}} >El despacho apuesta por la formación de sus abogados:
        </Typography>
        </Stack>
      <Grid container paddingTop="0px" paddingBottom="30px">
      <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
        <Stack direction="column"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      sx={{width: "100%"}}>

        <StyledRating
        sx={{paddingTop: "0px", paddingRight: "0px"}}
        name="customized-color"
        defaultValue={formacion1}
        getLabelText={getLabelText}
        onChange={(event, newValue3) => {
            setFormacion1(newValue3);
       
          }}
          onChangeActive={(event, newHover3) => {
            setHoverFormacion1(newHover3);
       
          }}
        precision={1}
        size="small"
        icon={<Brightness1Icon fontSize="inherit" />}
        emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
      />
        </Stack>
        
        </Grid>
       
        
        
      
        <Grid item xs={5} sm={5} md={5} lg={5} xl={5} >
        <Stack direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      sx={{width: "100%"}}>
            
        <Box sx={{ ml: 2, color: "rgba(43, 117, 177, 0.9)", alignContent: "center", alignItems: "center", fontSize: "16px", marginLeft: "35px" }}>{labels[formacion1 === 0 ? hoverFormacion1 : formacion1]}</Box>
     
        </Stack>

        

        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        </Grid>



        <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{width: "100%"}}>
        <Typography sx={{paddingTop: "0px", paddingRight: "20px", paddingLeft: "20px", paddingBottom: "10px", fontSize: "16px", fontWeight: "500", color: "rgba(43, 117, 177, 0.9)", textAlign: "center"}} >El despacho cuenta con un buen programa de formación:
        </Typography>
        </Stack>
      <Grid container paddingTop="0px" paddingBottom="30px">
      <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
        <Stack direction="column"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      sx={{width: "100%"}}>

        <StyledRating
        sx={{paddingTop: "0px", paddingRight: "0px"}}
        name="customized-color"
        defaultValue={formacion2}
        getLabelText={getLabelText}
        onChange={(event, newValue3) => {
            setFormacion2(newValue3);
       
          }}
          onChangeActive={(event, newHover3) => {
            setHoverFormacion2(newHover3);
       
          }}
        precision={1}
        size="small"
        icon={<Brightness1Icon fontSize="inherit" />}
        emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
      />
        </Stack>
        
        </Grid>
       
        
        
      
        <Grid item xs={5} sm={5} md={5} lg={5} xl={5} >
        <Stack direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      sx={{width: "100%"}}>
            
        <Box sx={{ ml: 2, color: "rgba(43, 117, 177, 0.9)", alignContent: "center", alignItems: "center", fontSize: "16px", marginLeft: "35px" }}>{labels[formacion2 === 0 ? hoverFormacion2 : formacion2]}</Box>
     
        </Stack>

        

        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        </Grid>


        <Divider sx={{paddingTop: "40px"}} >
            <Chip label="Carrera" sx={{color: "white", bgcolor: "rgba(43, 117, 177, 0.9)"}} />
            </Divider>


        <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{width: "100%", paddingTop: "40px"}}>
        <Typography sx={{paddingTop: "0px", paddingRight: "20px", paddingLeft: "20px", paddingBottom: "10px", fontSize: "16px", fontWeight: "500", color: "rgba(43, 117, 177, 0.9)", textAlign: "center"}} >El despacho ofrece la posibilidad real de desarrollar una carrera profesional atractiva:
        </Typography>
        </Stack>
      <Grid container paddingTop="0px" paddingBottom="30px">
      <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
        <Stack direction="column"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      sx={{width: "100%"}}>

        <StyledRating
        sx={{paddingTop: "0px", paddingRight: "0px"}}
        name="customized-color"
        defaultValue={carrera}
        getLabelText={getLabelText}
        onChange={(event, newValue3) => {
            setCarrera(newValue3);
       
          }}
          onChangeActive={(event, newHover3) => {
            setHoverCarrera(newHover3);
       
          }}
        precision={1}
        size="small"
        icon={<Brightness1Icon fontSize="inherit" />}
        emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
      />
        </Stack>
        
        </Grid>
       
        
        
      
        <Grid item xs={5} sm={5} md={5} lg={5} xl={5} >
        <Stack direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      sx={{width: "100%"}}>
            
        <Box sx={{ ml: 2, color: "rgba(43, 117, 177, 0.9)", alignContent: "center", alignItems: "center", fontSize: "16px", marginLeft: "35px" }}>{labels[carrera === 0 ? hoverCarrera : carrera]}</Box>
     
        </Stack>

        

        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        </Grid>

        <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{width: "100%", marginTop: "40px"}}>
        <Typography sx={{paddingTop: "0px", paddingRight: "20px", paddingLeft: "20px", paddingBottom: "10px", fontSize: "16px", fontWeight: "500", color: "rgba(43, 117, 177, 0.9)", textAlign: "center"}} >Me veo trabajando en este despacho dentro de cinco años:
        </Typography>
        </Stack>
      <Grid container paddingTop="0px" paddingBottom="30px">
      <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
        <Stack direction="column"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      sx={{width: "100%"}}>

        <StyledRating
        sx={{paddingTop: "0px", paddingRight: "0px"}}
        name="customized-color"
        defaultValue={carrera2}
        getLabelText={getLabelText}
        onChange={(event, newValue3) => {
            setCarrera2(newValue3);
       
          }}
          onChangeActive={(event, newHover3) => {
            setHoverCarrera2(newHover3);
       
          }}
        precision={1}
        size="small"
        icon={<Brightness1Icon fontSize="inherit" />}
        emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
      />
        </Stack>
        
        </Grid>
       
        
        
      
        <Grid item xs={5} sm={5} md={5} lg={5} xl={5} >
        <Stack direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      sx={{width: "100%"}}>
            
        <Box sx={{ ml: 2, color: "rgba(43, 117, 177, 0.9)", alignContent: "center", alignItems: "center", fontSize: "16px", marginLeft: "35px" }}>{labels[carrera2 === 0 ? hoverCarrera2 : carrera2]}</Box>
     
        </Stack>

        

        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        </Grid>


        <Divider sx={{paddingTop: "40px"}} >
            <Chip label="Diversidad" sx={{color: "white", bgcolor: "rgba(43, 117, 177, 0.9)"}} />
            </Divider>


        <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{width: "100%", marginTop: "40px"}}>
        <Typography sx={{paddingTop: "0px", paddingRight: "20px", paddingLeft: "20px", paddingBottom: "10px", fontSize: "16px", fontWeight: "500", color: "rgba(43, 117, 177, 0.9)", textAlign: "center"}} >El despacho acepta la diversidad:
        </Typography>
        </Stack>
      <Grid container paddingTop="0px" paddingBottom="30px">
      <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
        <Stack direction="column"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      sx={{width: "100%"}}>

        <StyledRating
        sx={{paddingTop: "0px", paddingRight: "0px"}}
        name="customized-color"
        defaultValue={diversidad1}
        getLabelText={getLabelText}
        onChange={(event, newValue3) => {
            setDiversidad1(newValue3);
       
          }}
          onChangeActive={(event, newHover3) => {
            setHoverDiversidad1(newHover3);
       
          }}
        precision={1}
        size="small"
        icon={<Brightness1Icon fontSize="inherit" />}
        emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
      />
        </Stack>
        
        </Grid>
       
        
        
      
        <Grid item xs={5} sm={5} md={5} lg={5} xl={5} >
        <Stack direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      sx={{width: "100%"}}>
            
        <Box sx={{ ml: 2, color: "rgba(43, 117, 177, 0.9)", alignContent: "center", alignItems: "center", fontSize: "16px", marginLeft: "35px" }}>{labels[diversidad1 === 0 ? hoverDiversidad1 : diversidad1]}</Box>
     
        </Stack>

        

        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        </Grid>

        <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{width: "100%"}}>
        <Typography sx={{paddingTop: "0px", paddingRight: "20px", paddingLeft: "20px", paddingBottom: "10px", fontSize: "16px", fontWeight: "500", color: "rgba(43, 117, 177, 0.9)", textAlign: "center"}} >El despacho fomenta la diversidad:
        </Typography>
        </Stack>
      <Grid container paddingTop="0px" paddingBottom="30px">
      <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
        <Stack direction="column"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      sx={{width: "100%"}}>

        <StyledRating
        sx={{paddingTop: "0px", paddingRight: "0px"}}
        name="customized-color"
        defaultValue={diversidad2}
        getLabelText={getLabelText}
        onChange={(event, newValue3) => {
            setDiversidad2(newValue3);
       
          }}
          onChangeActive={(event, newHover3) => {
            setHoverDiversidad2(newHover3);
       
          }}
        precision={1}
        size="small"
        icon={<Brightness1Icon fontSize="inherit" />}
        emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
      />
        </Stack>
        
        </Grid>
       
        
        
      
        <Grid item xs={5} sm={5} md={5} lg={5} xl={5} >
        <Stack direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      sx={{width: "100%"}}>
            
        <Box sx={{ ml: 2, color: "rgba(43, 117, 177, 0.9)", alignContent: "center", alignItems: "center", fontSize: "16px", marginLeft: "35px" }}>{labels[diversidad2 === 0 ? hoverDiversidad2 : diversidad2]}</Box>
     
        </Stack>

        

        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        </Grid>


        <Divider sx={{paddingTop: "40px"}} >
            <Chip label="Cultura de firma" sx={{color: "white", bgcolor: "rgba(43, 117, 177, 0.9)"}} />
            </Divider>


        <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{width: "100%", marginTop: "40px"}}>
        <Typography sx={{paddingTop: "0px", paddingRight: "20px", paddingLeft: "20px", paddingBottom: "10px", fontSize: "16px", fontWeight: "500", color: "rgba(43, 117, 177, 0.9)", textAlign: "center"}} >Me siento identificado con los valores del despacho:
        </Typography>
        </Stack>
      <Grid container paddingTop="0px" paddingBottom="30px">
      <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
        <Stack direction="column"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      sx={{width: "100%"}}>

        <StyledRating
        sx={{paddingTop: "0px", paddingRight: "0px"}}
        name="customized-color"
        defaultValue={valores}
        getLabelText={getLabelText}
        onChange={(event, newValue3) => {
            setValores(newValue3);
       
          }}
          onChangeActive={(event, newHover3) => {
            setHoverValores(newHover3);
       
          }}
        precision={1}
        size="small"
        icon={<Brightness1Icon fontSize="inherit" />}
        emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
      />
        </Stack>
        
        </Grid>
       
        
        
      
        <Grid item xs={5} sm={5} md={5} lg={5} xl={5} >
        <Stack direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      sx={{width: "100%"}}>
            
        <Box sx={{ ml: 2, color: "rgba(43, 117, 177, 0.9)", alignContent: "center", alignItems: "center", fontSize: "16px", marginLeft: "35px" }}>{labels[valores === 0 ? hoverValores : valores]}</Box>
     
        </Stack>

        

        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        </Grid>


        <Divider sx={{paddingTop: "40px"}} >
            <Chip label="Pro Bono" sx={{color: "white", bgcolor: "rgba(43, 117, 177, 0.9)"}} />
            </Divider>


        <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{width: "100%", marginTop: "40px"}}>
        <Typography sx={{paddingTop: "0px", paddingRight: "20px", paddingLeft: "20px", paddingBottom: "10px", fontSize: "16px", fontWeight: "500", color: "rgba(43, 117, 177, 0.9)", textAlign: "center"}} >El despacho apuesta por realizar trabajo Pro Bono:
        </Typography>
        </Stack>
      <Grid container paddingTop="0px" paddingBottom="30px">
      <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
        <Stack direction="column"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      sx={{width: "100%"}}>

        <StyledRating
        sx={{paddingTop: "0px", paddingRight: "0px"}}
        name="customized-color"
        defaultValue={proBono}
        getLabelText={getLabelText}
        onChange={(event, newValue3) => {
            setProBono(newValue3);
       
          }}
          onChangeActive={(event, newHover3) => {
            setHoverProBono(newHover3);
       
          }}
        precision={1}
        size="small"
        icon={<Brightness1Icon fontSize="inherit" />}
        emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
      />
        </Stack>
        
        </Grid>
       
        
        
      
        <Grid item xs={5} sm={5} md={5} lg={5} xl={5} >
        <Stack direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      sx={{width: "100%"}}>
            
        <Box sx={{ ml: 2, color: "rgba(43, 117, 177, 0.9)", alignContent: "center", alignItems: "center", fontSize: "16px", marginLeft: "35px" }}>{labels[proBono === 0 ? hoverProBono : proBono]}</Box>
     
        </Stack>

        

        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        </Grid>


        <Divider sx={{paddingTop: "40px"}} >
            <Chip label="Igualdad" sx={{color: "white", bgcolor: "rgba(43, 117, 177, 0.9)"}} />
            </Divider>


        <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{width: "100%", marginTop: "40px"}}>
        <Typography sx={{paddingTop: "0px", paddingRight: "20px", paddingLeft: "20px", paddingBottom: "10px", fontSize: "16px", fontWeight: "500", color: "rgba(43, 117, 177, 0.9)", textAlign: "center"}} >El despacho garantiza la igualdad entre mujeres y hombres:
        </Typography>
        </Stack>
      <Grid container paddingTop="0px" paddingBottom="30px">
      <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
        <Stack direction="column"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      sx={{width: "100%"}}>

        <StyledRating
        sx={{paddingTop: "0px", paddingRight: "0px"}}
        name="customized-color"
        defaultValue={igualdad}
        getLabelText={getLabelText}
        onChange={(event, newValue3) => {
            setIgualdad(newValue3);
       
          }}
          onChangeActive={(event, newHover3) => {
            setHoverIgualdad(newHover3);
       
          }}
        precision={1}
        size="small"
        icon={<Brightness1Icon fontSize="inherit" />}
        emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
      />
        </Stack>
        
        </Grid>
       
        
        
      
        <Grid item xs={5} sm={5} md={5} lg={5} xl={5} >
        <Stack direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      sx={{width: "100%"}}>
            
        <Box sx={{ ml: 2, color: "rgba(43, 117, 177, 0.9)", alignContent: "center", alignItems: "center", fontSize: "16px", marginLeft: "35px" }}>{labels[igualdad === 0 ? hoverIgualdad : igualdad]}</Box>
     
        </Stack>

        

        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        </Grid>
        </Grid>

        <Divider sx={{paddingTop: "40px"}} >
            <Chip label="Con tus propias palabras" sx={{color: "white", bgcolor: "rgba(43, 117, 177, 0.9)"}} />
            </Divider>

            <TextField   
                sx={{marginTop: "40px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="opinion"
                label="¿Por qué recomendarías trabajar en este despacho?"
                name="opinion"
                autoComplete="opinion"
                multiline
                rows={4}
                onChange={handleChange}
              />

<TextField   
                sx={{marginTop: "40px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="positivo"
                label="Lo mejor de trabajar en este despacho es..."
                name="positivo"
                autoComplete="positivo"
                multiline
                rows={2}
                onChange={handleChange}
              />

<TextField   
                sx={{marginTop: "40px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="negativo"
                label="Lo peor de trabajar en este despacho es..."
                name="negativo"
                autoComplete="negativo"
                multiline
                rows={2}
                onChange={handleChange}
              />


<Divider sx={{paddingTop: "40px"}} >
            <Chip label="Información salarial" sx={{color: "white", bgcolor: "rgba(43, 117, 177, 0.9)"}} />
            </Divider>

            <Typography sx={{paddingTop: "20px", paddingRight: "20px", paddingLeft: "20px", paddingBottom: "10px", fontSize: "12px", fontWeight: "500", color: "rgba(43, 117, 177, 0.9)", textAlign: "center"}} >La información sobre tu salario (como el resto) será tratada con total confidencialidad. Si lo prefieres puedes no incluirla, pero no tendrás acceso a la información salarial de despachos y empresas. 
        </Typography>

<Typography gutterBottom sx={{paddingTop: "40px", paddingBottom: "10px", color: "black"}}>¿Cuál es tu salario fijo? (indica el importe bruto anual)</Typography>
<Slider
        getAriaLabel={() => 'Salario fijo'}
        value={salario}
        onChange={handleChangeSalario}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
        step={5}
        min={0}
        max={180}
        marks={salarios}
      />

<Typography gutterBottom sx={{paddingTop: "40px", paddingBottom: "10px", color: "black"}}>¿A cuánto ascendió tu último bonus en este despacho? (indica el importe bruto)</Typography>
<Slider
        getAriaLabel={() => 'Bonus'}
        value={bonus}
        onChange={handleChangeBonus}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
        step={5}
        min={0}
        max={180}
        marks={salarios}
      />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, backgroundColor: "rgba(43, 117, 177, 0.9)", color: "white", marginTop: "70px"}}

              >
                Enviar opinión
              </Button>
              
            </Box>
          </Box>
        </Grid>
      </Grid>
      </Box>


      <Box sx={{display: { xl: 'none', l: "none", m: "none", s:"block", xs: 'block'}}} >

      
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: "center",
              alignItems: "center",
              bgcolor: 'white',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          >
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{mt: 1, color: "white", bgcolor: "rgba(228, 237, 246, 0.17)", marginTop: "60px", marginBottom: "60px", borderRadius: "25px", padding: "20px", maxWidth: "850px" }}>
            <Image href="./" src="/images/logo.png" style={{width: "130px"}} sx={{marginLeft: "60px", marginRight: "60px"}} />
            <Typography variant="h6" color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "0px", textAlign: "center", fontWeight: "bold"}}
              >Valora tu despacho o empresa
            </Typography>
            <Typography sx={{fontSize: "12px"}} color = "rgba(43, 117, 177, 0.9)"
              sx={{paddingTop: "20px", paddingBottom: "10px", textAlign: "center"}}
              >Da tu opinión de forma anónima y confidencial. Ayudarás a otros abogados a conocer las ventajas e incovenientes de trabajar allí, y obtendrás acceso a las valoraciones de todos los despachos y empresas.
            </Typography>
  
        

            <Stack direction="column" alignItems="center" spacing={2} sx={{paddingTop: "30px", paddingBottom: "10px"}}>
      <Autocomplete
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        id="controllable-states-demo"
        options={options}
        sx={{ width: "80%" }}
        renderInput={(params) => <TextField {...params} label="Despacho o empresa" />}
      />
    </Stack>
    <Divider sx={{paddingTop: "40px"}} >
            <Chip label="Conciliación y tipo de trabajo" sx={{color: "white", bgcolor: "rgba(43, 117, 177, 0.9)"}} />
            </Divider>







        


        <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{width: "100%"}}>
        <Typography sx={{paddingTop: "30px", paddingRight: "20px", paddingLeft: "20px", paddingBottom: "10px", fontSize: "16px", fontWeight: "500", color: "rgba(43, 117, 177, 0.9)", textAlign: "center"}} >Creo que el despacho hace todo lo posible para facilitar la conciliación de los abogados:
        </Typography>
        </Stack>
  
        <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{width: "100%", paddingBottom: "10px"}}>

        <StyledRating
        sx={{paddingTop: "0px", paddingRight: "0px"}}
        name="customized-color"
        defaultValue={conciliacion1}
        getLabelText={getLabelText}
        onChange={(event, newValue3) => {
            setConciliacion1(newValue3);
            console.log(conciliacion1)
          }}
          onChangeActive={(event, newHover2) => {
            setHoverConciliacion1(newHover2);
       
          }}
        precision={1}
        size="small"
        icon={<Brightness1Icon fontSize="inherit" />}
        emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
      />
        </Stack>
        
    
       
        
        
      
    
        <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{width: "100%"}}>
                
        <Box sx={{ ml: 2, color: "rgba(43, 117, 177, 0.9)", alignContent: "center", alignItems: "center", fontSize: "16px"}}>{labels[conciliacion1 === 0 ? hoverConciliacion1 : conciliacion1]}</Box>
      
        </Stack>

        

 


        <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{width: "100%"}}>
        <Typography sx={{paddingRight: "20px", paddingLeft: "20px", paddingBottom: "10px", paddingTop: "20px", fontSize: "16px", fontWeight: "500", color: "rgba(43, 117, 177, 0.9)", textAlign: "center"}} >El despacho me permite conciliar mi vida laboral con mi vida personal:
        </Typography>
        </Stack>
  
        <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{width: "100%", paddingBottom: "10px"}}>

        <StyledRating
        sx={{paddingTop: "0px", paddingRight: "0px"}}
        name="customized-color"
        defaultValue={conciliacion2}
        getLabelText={getLabelText}
        onChange={(event, newValue3) => {
            setConciliacion2(newValue3);
            console.log(carga)
          }}
          onChangeActive={(event, newHover2) => {
            setHoverConciliacion2(newHover2);
       
          }}
        precision={1}
        size="small"
        icon={<Brightness1Icon fontSize="inherit" />}
        emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
      />
        </Stack>
        
 
        <Stack direction="column"
                       justifyContent="center"
                       alignItems="center"
                      sx={{width: "100%"}}>
                
        <Box sx={{ ml: 2, color: "rgba(43, 117, 177, 0.9)", alignContent: "center", alignItems: "center", fontSize: "16px"}}>{labels[conciliacion2 === 0 ? hoverConciliacion2 : conciliacion2]}</Box>
      
        </Stack>

        

   



        <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{width: "100%"}}>
        <Typography sx={{paddingTop: "0px", paddingRight: "20px", paddingLeft: "20px", paddingTop: "20px", paddingBottom: "10px", fontSize: "16px", fontWeight: "500", color: "rgba(43, 117, 177, 0.9)", textAlign: "center"}} >Hay una carga de trabajo razonable:
        </Typography>
        </Stack>
    
        <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{width: "100%", paddingBottom: "10px"}}>

        <StyledRating
        sx={{paddingTop: "0px", paddingRight: "0px"}}
        name="customized-color"
        defaultValue={carga}
        getLabelText={getLabelText}
        onChange={(event, newValue3) => {
            setCarga(newValue3);
            console.log(carga)
          }}
          onChangeActive={(event, newHover2) => {
            setHoverCarga(newHover2);
       
          }}
        precision={1}
        size="small"
        icon={<Brightness1Icon fontSize="inherit" />}
        emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
      />
        </Stack>
        
  
        <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{width: "100%"}}>
                
        <Box sx={{ ml: 2, color: "rgba(43, 117, 177, 0.9)", alignContent: "center", alignItems: "center", fontSize: "16px"}}>{labels[carga === 0 ? hoverCarga : carga]}</Box>
      
        </Stack>

        



        

        <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{width: "100%"}}>
        <Typography sx={{paddingTop: "0px", paddingRight: "20px", paddingLeft: "20px", paddingTop: "20px", paddingBottom: "10px", fontSize: "16px", fontWeight: "500", color: "rgba(43, 117, 177, 0.9)", textAlign: "center"}} >La política de teletrabajo es atractiva:
        </Typography>
        </Stack>

        <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{width: "100%", paddingBottom: "10px"}}>

        <StyledRating
        sx={{paddingTop: "0px", paddingRight: "0px"}}
        name="customized-color"
        defaultValue={teletrabajo}
        getLabelText={getLabelText}
        onChange={(event, newValue3) => {
            setTeletrabajo(newValue3);
       
          }}
          onChangeActive={(event, newHover3) => {
            setHoverTeletrabajo(newHover3);
       
          }}
        precision={1}
        size="small"
        icon={<Brightness1Icon fontSize="inherit" />}
        emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
      />
        </Stack>
        

        <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{width: "100%"}}>
            
        <Box sx={{ ml: 2, color: "rgba(43, 117, 177, 0.9)", alignContent: "center", alignItems: "center", fontSize: "16px"}}>{labels[teletrabajo === 0 ? hoverTeletrabajo : teletrabajo]}</Box>
     
        </Stack>

        


          <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{width: "100%"}}>
                <Typography sx={{paddingTop: "0px", paddingRight: "20px", paddingTop: "20px", paddingLeft: "20px", paddingBottom: "10px", fontSize: "16px", fontWeight: "500", color: "rgba(43, 117, 177, 0.9)", textAlign: "center"}} >¿Cuántos días a la semana teletrabajas?
        </Typography>
        <FormControl>
      <FormLabel id="demo-row-radio-buttons-group-label"></FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={diasTeletrabajo}
        onChange={handleChangeDiasTeletrabajo}
      >
        <FormControlLabel sx={{color: "rgba(43, 117, 177, 0.9)", marginRight: "3px", marginLeft: "3px"}} labelPlacement="bottom" value="0" control={<Radio size="small" sx={{color: "rgba(43, 117, 177, 0.9)"}} />} label="0" />
        <FormControlLabel sx={{color: "rgba(43, 117, 177, 0.9)", marginRight: "3px", marginLeft: "3px"}} labelPlacement="bottom" value="1" control={<Radio size="small" sx={{color: "rgba(43, 117, 177, 0.9)"}} />} label="1" />
        <FormControlLabel sx={{color: "rgba(43, 117, 177, 0.9)", marginRight: "3px", marginLeft: "3px"}} labelPlacement="bottom" value="2" control={<Radio size="small" sx={{color: "rgba(43, 117, 177, 0.9)"}} />} label="2" />
        <FormControlLabel sx={{color: "rgba(43, 117, 177, 0.9)", marginRight: "3px", marginLeft: "3px"}} labelPlacement="bottom" value="3" control={<Radio size="small" sx={{color: "rgba(43, 117, 177, 0.9)"}} />} label="3" />
        <FormControlLabel sx={{color: "rgba(43, 117, 177, 0.9)", marginRight: "3px", marginLeft: "3px"}} labelPlacement="bottom" value="4" control={<Radio size="small" sx={{color: "rgba(43, 117, 177, 0.9)"}} />} label="4" />
        <FormControlLabel sx={{color: "rgba(43, 117, 177, 0.9)", marginRight: "3px", marginLeft: "3px"}} labelPlacement="bottom" value="5" control={<Radio size="small" sx={{color: "rgba(43, 117, 177, 0.9)"}} />} label="5" />
      </RadioGroup>
    </FormControl>
    </Stack>

        <Divider sx={{paddingTop: "40px"}} >
            <Chip label="Ambiente laboral" sx={{color: "white", bgcolor: "rgba(43, 117, 177, 0.9)"}} />
            </Divider>

        
        <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{width: "100%", marginTop: "40px"}}>
        <Typography sx={{paddingTop: "0px", paddingRight: "20px", paddingLeft: "20px", paddingTop: "20px", paddingBottom: "10px", fontSize: "16px", fontWeight: "500", color: "rgba(43, 117, 177, 0.9)", textAlign: "center"}} >En el despacho hay un excelente ambiente laboral:
        </Typography>
        </Stack>

        <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{width: "100%", paddingBottom: "10px"}}>

        <StyledRating
        sx={{paddingTop: "0px", paddingRight: "0px"}}
        name="customized-color"
        defaultValue={ambiente1}
        getLabelText={getLabelText}
        onChange={(event, newValue3) => {
            setAmbiente1(newValue3);
       
          }}
          onChangeActive={(event, newHover3) => {
            setHoverAmbiente1(newHover3);
       
          }}
        precision={1}
        size="small"
        icon={<Brightness1Icon fontSize="inherit" />}
        emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
      />
        </Stack>
        
 
        <Stack direction="column"
                        justifyContent="center"
                        alignItems="center"
                      sx={{width: "100%"}}>
            
        <Box sx={{ ml: 2, color: "rgba(43, 117, 177, 0.9)", alignContent: "center", alignItems: "center", fontSize: "16px"}}>{labels[ambiente1 === 0 ? hoverAmbiente1 : ambiente1]}</Box>
     
        </Stack>





        <Divider sx={{paddingTop: "40px"}} >
            <Chip label="Tipo de trabajo" sx={{color: "white", bgcolor: "rgba(43, 117, 177, 0.9)"}} />
            </Divider>

        
        <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{width: "100%", marginTop: "40px"}}>
        <Typography sx={{paddingTop: "0px", paddingRight: "20px", paddingLeft: "20px", paddingTop: "20px", paddingBottom: "10px", fontSize: "16px", fontWeight: "500", color: "rgba(43, 117, 177, 0.9)", textAlign: "center"}} >Hago un tipo de trabajo atractivo:
        </Typography>
        </Stack>

        <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{width: "100%", paddingBottom: "10px"}}>

        <StyledRating
        sx={{paddingTop: "0px", paddingRight: "0px"}}
        name="customized-color"
        defaultValue={atractivo}
        getLabelText={getLabelText}
        onChange={(event, newValue3) => {
            setAtractivo(newValue3);
       
          }}
          onChangeActive={(event, newHover3) => {
            setHoverAtractivo(newHover3);
       
          }}
        precision={1}
        size="small"
        icon={<Brightness1Icon fontSize="inherit" />}
        emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
      />
        </Stack>
        

        <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{width: "100%"}}>
            
        <Box sx={{ ml: 2, color: "rgba(43, 117, 177, 0.9)", alignContent: "center", alignItems: "center", fontSize: "16px"}}>{labels[atractivo === 0 ? hoverAtractivo : atractivo]}</Box>
     
        </Stack>




        <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{width: "100%"}}>
        <Typography sx={{paddingTop: "0px", paddingRight: "20px", paddingLeft: "20px", paddingTop: "20px", paddingBottom: "10px", fontSize: "16px", fontWeight: "500", color: "rgba(43, 117, 177, 0.9)", textAlign: "center"}} >Hago un trabajo variado:
        </Typography>
        </Stack>

        <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{width: "100%", paddingBottom: "10px"}}>

        <StyledRating
        sx={{paddingTop: "0px", paddingRight: "0px"}}
        name="customized-color"
        defaultValue={variado}
        getLabelText={getLabelText}
        onChange={(event, newValue3) => {
            setVariado(newValue3);
       
          }}
          onChangeActive={(event, newHover3) => {
            setHoverVariado(newHover3);
       
          }}
        precision={1}
        size="small"
        icon={<Brightness1Icon fontSize="inherit" />}
        emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
      />
        </Stack>
        
   
        <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{width: "100%"}}>
            
        <Box sx={{ ml: 2, color: "rgba(43, 117, 177, 0.9)", alignContent: "center", alignItems: "center", fontSize: "16px"}}>{labels[variado === 0 ? hoverVariado : variado]}</Box>
     
        </Stack>



           


        <Divider sx={{paddingTop: "40px"}} >
            <Chip label="Formación" sx={{color: "white", bgcolor: "rgba(43, 117, 177, 0.9)"}} />
            </Divider>

        
        <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{width: "100%", marginTop: "40px"}}>
        <Typography sx={{paddingTop: "0px", paddingRight: "20px", paddingLeft: "20px", paddingTop: "20px", paddingBottom: "10px", fontSize: "16px", fontWeight: "500", color: "rgba(43, 117, 177, 0.9)", textAlign: "center"}} >El despacho apuesta por la formación de sus abogados:
        </Typography>
        </Stack>

        <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{width: "100%", paddingBottom: "10px"}}>

        <StyledRating
        sx={{paddingTop: "0px", paddingRight: "0px"}}
        name="customized-color"
        defaultValue={formacion1}
        getLabelText={getLabelText}
        onChange={(event, newValue3) => {
            setFormacion1(newValue3);
       
          }}
          onChangeActive={(event, newHover3) => {
            setHoverFormacion1(newHover3);
       
          }}
        precision={1}
        size="small"
        icon={<Brightness1Icon fontSize="inherit" />}
        emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
      />
        </Stack>
        

        <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{width: "100%"}}>
            
        <Box sx={{ ml: 2, color: "rgba(43, 117, 177, 0.9)", alignContent: "center", alignItems: "center", fontSize: "16px"}}>{labels[formacion1 === 0 ? hoverFormacion1 : formacion1]}</Box>
     
        </Stack>





        <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{width: "100%"}}>
        <Typography sx={{paddingTop: "0px", paddingRight: "20px", paddingLeft: "20px", paddingTop: "20px", paddingBottom: "10px", fontSize: "16px", fontWeight: "500", color: "rgba(43, 117, 177, 0.9)", textAlign: "center"}} >El despacho cuenta con un buen programa de formación:
        </Typography>
        </Stack>

        <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{width: "100%", paddingBottom: "10px"}}>

        <StyledRating
        sx={{paddingTop: "0px", paddingRight: "0px"}}
        name="customized-color"
        defaultValue={formacion2}
        getLabelText={getLabelText}
        onChange={(event, newValue3) => {
            setFormacion2(newValue3);
       
          }}
          onChangeActive={(event, newHover3) => {
            setHoverFormacion2(newHover3);
       
          }}
        precision={1}
        size="small"
        icon={<Brightness1Icon fontSize="inherit" />}
        emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
      />
        </Stack>
        
     
        <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{width: "100%"}}>
            
        <Box sx={{ ml: 2, color: "rgba(43, 117, 177, 0.9)", alignContent: "center", alignItems: "center", fontSize: "16px"}}>{labels[formacion2 === 0 ? hoverFormacion2 : formacion2]}</Box>
     
        </Stack>

        



        <Divider sx={{paddingTop: "40px"}} >
            <Chip label="Carrera" sx={{color: "white", bgcolor: "rgba(43, 117, 177, 0.9)"}} />
            </Divider>


        <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{width: "100%", paddingTop: "40px"}}>
        <Typography sx={{paddingTop: "0px", paddingRight: "20px", paddingLeft: "20px", paddingTop: "20px", paddingBottom: "10px", fontSize: "16px", fontWeight: "500", color: "rgba(43, 117, 177, 0.9)", textAlign: "center"}} >El despacho ofrece la posibilidad real de desarrollar una carrera profesional atractiva:
        </Typography>
        </Stack>
  
        <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{width: "100%", paddingBottom: "10px"}}>

        <StyledRating
        sx={{paddingTop: "0px", paddingRight: "0px"}}
        name="customized-color"
        defaultValue={carrera}
        getLabelText={getLabelText}
        onChange={(event, newValue3) => {
            setCarrera(newValue3);
       
          }}
          onChangeActive={(event, newHover3) => {
            setHoverCarrera(newHover3);
       
          }}
        precision={1}
        size="small"
        icon={<Brightness1Icon fontSize="inherit" />}
        emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
      />
        </Stack>
        
     
        <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{width: "100%"}}>
            
        <Box sx={{ ml: 2, color: "rgba(43, 117, 177, 0.9)", alignContent: "center", alignItems: "center", fontSize: "16px"}}>{labels[carrera === 0 ? hoverCarrera : carrera]}</Box>
     
        </Stack>

        

   

        <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{width: "100%", marginTop: "40px"}}>
        <Typography sx={{paddingTop: "0px", paddingRight: "20px", paddingLeft: "20px", paddingTop: "20px", paddingBottom: "10px", fontSize: "16px", fontWeight: "500", color: "rgba(43, 117, 177, 0.9)", textAlign: "center"}} >Me veo trabajando en este despacho dentro de cinco años:
        </Typography>
        </Stack>
  
        <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{width: "100%", paddingBottom: "10px"}}>

        <StyledRating
        sx={{paddingTop: "0px", paddingRight: "0px"}}
        name="customized-color"
        defaultValue={carrera2}
        getLabelText={getLabelText}
        onChange={(event, newValue3) => {
            setCarrera2(newValue3);
       
          }}
          onChangeActive={(event, newHover3) => {
            setHoverCarrera2(newHover3);
       
          }}
        precision={1}
        size="small"
        icon={<Brightness1Icon fontSize="inherit" />}
        emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
      />
        </Stack>
        
 
        <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{width: "100%"}}>
            
        <Box sx={{ ml: 2, color: "rgba(43, 117, 177, 0.9)", alignContent: "center", alignItems: "center", fontSize: "16px"}}>{labels[carrera2 === 0 ? hoverCarrera2 : carrera2]}</Box>
     
        </Stack>




        <Divider sx={{paddingTop: "40px"}} >
            <Chip label="Diversidad" sx={{color: "white", bgcolor: "rgba(43, 117, 177, 0.9)"}} />
            </Divider>


        <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{width: "100%", marginTop: "40px"}}>
        <Typography sx={{paddingTop: "0px", paddingRight: "20px", paddingLeft: "20px", paddingTop: "20px", paddingBottom: "10px", fontSize: "16px", fontWeight: "500", color: "rgba(43, 117, 177, 0.9)", textAlign: "center"}} >El despacho acepta la diversidad:
        </Typography>
        </Stack>

        <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{width: "100%", paddingBottom: "10px"}}>

        <StyledRating
        sx={{paddingTop: "0px", paddingRight: "0px"}}
        name="customized-color"
        defaultValue={diversidad1}
        getLabelText={getLabelText}
        onChange={(event, newValue3) => {
            setDiversidad1(newValue3);
       
          }}
          onChangeActive={(event, newHover3) => {
            setHoverDiversidad1(newHover3);
       
          }}
        precision={1}
        size="small"
        icon={<Brightness1Icon fontSize="inherit" />}
        emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
      />
        </Stack>
        

        <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{width: "100%"}}>
            
        <Box sx={{ ml: 2, color: "rgba(43, 117, 177, 0.9)", alignContent: "center", alignItems: "center", fontSize: "16px"}}>{labels[diversidad1 === 0 ? hoverDiversidad1 : diversidad1]}</Box>
     
        </Stack>



        <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{width: "100%"}}>
        <Typography sx={{paddingTop: "0px", paddingRight: "20px", paddingLeft: "20px", paddingTop: "20px", paddingBottom: "10px", fontSize: "16px", fontWeight: "500", color: "rgba(43, 117, 177, 0.9)", textAlign: "center"}} >El despacho fomenta la diversidad:
        </Typography>
        </Stack>

        <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{width: "100%", paddingBottom: "10px"}}>

        <StyledRating
        sx={{paddingTop: "0px", paddingRight: "0px"}}
        name="customized-color"
        defaultValue={diversidad2}
        getLabelText={getLabelText}
        onChange={(event, newValue3) => {
            setDiversidad2(newValue3);
       
          }}
          onChangeActive={(event, newHover3) => {
            setHoverDiversidad2(newHover3);
       
          }}
        precision={1}
        size="small"
        icon={<Brightness1Icon fontSize="inherit" />}
        emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
      />
        </Stack>
        

        <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{width: "100%"}}>
            
        <Box sx={{ ml: 2, color: "rgba(43, 117, 177, 0.9)", alignContent: "center", alignItems: "center", fontSize: "16px"}}>{labels[diversidad2 === 0 ? hoverDiversidad2 : diversidad2]}</Box>
     
        </Stack>




        <Divider sx={{paddingTop: "40px"}} >
            <Chip label="Cultura de firma" sx={{color: "white", bgcolor: "rgba(43, 117, 177, 0.9)"}} />
            </Divider>


        <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{width: "100%", marginTop: "40px"}}>
        <Typography sx={{paddingTop: "0px", paddingRight: "20px", paddingLeft: "20px", paddingTop: "20px", paddingBottom: "10px", fontSize: "16px", fontWeight: "500", color: "rgba(43, 117, 177, 0.9)", textAlign: "center"}} >Me siento identificado con los valores del despacho:
        </Typography>
        </Stack>

        <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{width: "100%", paddingBottom: "10px"}}>

        <StyledRating
        sx={{paddingTop: "0px", paddingRight: "0px"}}
        name="customized-color"
        defaultValue={valores}
        getLabelText={getLabelText}
        onChange={(event, newValue3) => {
            setValores(newValue3);
       
          }}
          onChangeActive={(event, newHover3) => {
            setHoverValores(newHover3);
       
          }}
        precision={1}
        size="small"
        icon={<Brightness1Icon fontSize="inherit" />}
        emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
      />
        </Stack>
        
 
        <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{width: "100%"}}>
            
        <Box sx={{ ml: 2, color: "rgba(43, 117, 177, 0.9)", alignContent: "center", alignItems: "center", fontSize: "16px"}}>{labels[valores === 0 ? hoverValores : valores]}</Box>
     
        </Stack>

        



        <Divider sx={{paddingTop: "40px"}} >
            <Chip label="Pro Bono" sx={{color: "white", bgcolor: "rgba(43, 117, 177, 0.9)"}} />
            </Divider>


        <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{width: "100%", marginTop: "40px"}}>
        <Typography sx={{paddingTop: "0px", paddingRight: "20px", paddingLeft: "20px", paddingTop: "20px", paddingBottom: "10px", fontSize: "16px", fontWeight: "500", color: "rgba(43, 117, 177, 0.9)", textAlign: "center"}} >El despacho apuesta por realizar trabajo Pro Bono:
        </Typography>
        </Stack>

        <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{width: "100%", paddingBottom: "10px"}}>

        <StyledRating
        sx={{paddingTop: "0px", paddingRight: "0px"}}
        name="customized-color"
        defaultValue={proBono}
        getLabelText={getLabelText}
        onChange={(event, newValue3) => {
            setProBono(newValue3);
       
          }}
          onChangeActive={(event, newHover3) => {
            setHoverProBono(newHover3);
       
          }}
        precision={1}
        size="small"
        icon={<Brightness1Icon fontSize="inherit" />}
        emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
      />
        </Stack>
        

        <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{width: "100%"}}>
            
        <Box sx={{ ml: 2, color: "rgba(43, 117, 177, 0.9)", alignContent: "center", alignItems: "center", fontSize: "16px"}}>{labels[proBono === 0 ? hoverProBono : proBono]}</Box>
     
        </Stack>

        



        <Divider sx={{paddingTop: "40px"}} >
            <Chip label="Igualdad" sx={{color: "white", bgcolor: "rgba(43, 117, 177, 0.9)"}} />
            </Divider>


        <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{width: "100%", marginTop: "40px"}}>
        <Typography sx={{paddingTop: "0px", paddingRight: "20px", paddingLeft: "20px", paddingTop: "20px", paddingBottom: "10px", fontSize: "16px", fontWeight: "500", color: "rgba(43, 117, 177, 0.9)", textAlign: "center"}} >El despacho garantiza la igualdad entre mujeres y hombres:
        </Typography>
        </Stack>

        <Stack direction="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{width: "100%", paddingBottom: "10px"}}>

        <StyledRating
        sx={{paddingTop: "0px", paddingRight: "0px"}}
        name="customized-color"
        defaultValue={igualdad}
        getLabelText={getLabelText}
        onChange={(event, newValue3) => {
            setIgualdad(newValue3);
       
          }}
          onChangeActive={(event, newHover3) => {
            setHoverIgualdad(newHover3);
       
          }}
        precision={1}
        size="small"
        icon={<Brightness1Icon fontSize="inherit" />}
        emptyIcon={<Brightness1OutlinedIcon sx={{color: "#c5eaed"}} fontSize="inherit" />}
      />
        </Stack>
        

        <Stack direction="column"
                       justifyContent="center"
                       alignItems="center"
                      sx={{width: "100%"}}>
            
        <Box sx={{ ml: 2, color: "rgba(43, 117, 177, 0.9)", alignContent: "center", alignItems: "center", fontSize: "16px"}}>{labels[igualdad === 0 ? hoverIgualdad : igualdad]}</Box>
     
        </Stack>



        <Divider sx={{paddingTop: "40px"}} >
            <Chip label="Con tus propias palabras" sx={{color: "white", bgcolor: "rgba(43, 117, 177, 0.9)"}} />
            </Divider>

            <TextField   
                sx={{marginTop: "40px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="opinion"
                label="¿Por qué recomendarías trabajar en este despacho?"
                name="opinion"
                autoComplete="opinion"
                multiline
                rows={4}
                onChange={handleChange}
              />

<TextField   
                sx={{marginTop: "40px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="positivo"
                label="Lo mejor de trabajar en este despacho es..."
                name="positivo"
                autoComplete="positivo"
                multiline
                rows={2}
                onChange={handleChange}
              />

<TextField   
                sx={{marginTop: "40px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="negativo"
                label="Lo peor de trabajar en este despacho es..."
                name="negativo"
                autoComplete="negativo"
                multiline
                rows={2}
                onChange={handleChange}
              />


<Divider sx={{paddingTop: "40px"}} >
            <Chip label="Información salarial" sx={{color: "white", bgcolor: "rgba(43, 117, 177, 0.9)"}} />
            </Divider>

            <Typography sx={{paddingTop: "20px", paddingRight: "20px", paddingLeft: "20px", paddingBottom: "10px", fontSize: "12px", fontWeight: "500", color: "rgba(43, 117, 177, 0.9)", textAlign: "center"}} >La información sobre tu salario (como el resto) será tratada con total confidencialidad. Si lo prefieres puedes no incluirla, pero no tendrás acceso a la información salarial de despachos y empresas. 
        </Typography>

<Typography gutterBottom sx={{paddingTop: "40px", paddingBottom: "10px", color: "black"}}>¿Cuál es tu salario fijo? (indica el importe bruto anual)</Typography>
<Slider
        getAriaLabel={() => 'Salario fijo'}
        value={salario}
        onChange={handleChangeSalario}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
        step={1}
        min={0}
        max={180}
        marks={salariosMovil}
      />

<Typography gutterBottom sx={{paddingTop: "40px", paddingBottom: "10px", color: "black"}}>¿A cuánto ascendió tu último bonus en este despacho? (indica el importe bruto)</Typography>
<Slider
        getAriaLabel={() => 'Bonus'}
        value={bonus}
        onChange={handleChangeBonus}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
        step={10}
        min={0}
        max={180}
        marks={salariosMovil}
      />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, backgroundColor: "rgba(43, 117, 177, 0.9)", color: "white", marginTop: "70px"}}

              >
                Enviar opinión
              </Button>
              
            </Box>
          </Box>
   
      </Box>




      </AuthProvider>
    </div>
    
  )
}

export default NuevaNota
