import React from 'react';
import {auth, provider} from "../firebase-config";
import { signInWithPopup } from 'firebase/auth';
import { useNavigate } from 'react-router';

function Login({isAuth, setIsAuth}) {
    
    let navigate = useNavigate();

    const signInWithGoogle = () => {
        signInWithPopup(auth,provider).then((result) => {
            localStorage.setItem("isAuth", true);
            setIsAuth(true)
            navigate("../alljobs");
        })
    console.log(`Hola ${isAuth}`);
    };
    return (
    <div>
      <p>Sign In With Google</p>
      <button onClick={signInWithGoogle}>Sign In</button>
      <p>Logeado: {`Hola ${isAuth}`} </p>
    </div>
  )
}

export default Login

